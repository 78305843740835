import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { environment } from "src/environments/environment";

type Bucket = { name: string; image: string; topics: string };

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  recentContent;
  isProduction = environment.production;
  buckets: Bucket[] = [];

  constructor(private api: ApiService) {

  }

  ngOnInit() {
    this.api.getDrupalTopicsByBucket().subscribe((res: Bucket[]) => {
      this.buckets = res;
    });
  }

  getStyle(image: string) {
    return {
      "background-image": `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${image})`,
      "background-size": "cover",
    };
  }
}
