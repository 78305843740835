import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-bucket",
  templateUrl: "./bucket.component.html",
  styleUrls: ["./bucket.component.scss"],
})
export class BucketComponent implements OnInit {
  bucketName: string;
  bucketImage: string;
  bucketBlurb: string;
  topics: { name: string; url: string; image: string }[] = [];
  newestContentObservable: Observable<any>;
  maxTopicDisplayCount = 5;
  showMoreTopics = false;
  overridden:boolean=false;
  customPageOverrideNodeID:string;

  constructor(private _apiService: ApiService, private router: ActivatedRoute) {
    this.router.params.subscribe(
      (params) => (this.bucketName = params.bucketName)
    );
  }

  ngOnInit() {
    this.getTopicsForBucket(this.bucketName);
    this.newestContentObservable = this._apiService.getDrupalNewestContentByBucket(
      this.bucketName
    );
  }

  getTopicsForBucket(bucketName: string): string[] {
    this._apiService
      .getDrupalTopicsByBucket()
      .subscribe(
        (
          bucketTopics: {
            name: string;
            image: string;
            topics: string;
            description: string;
            overridden: "True" | "False" | "";
            custom_page_node_id: string
          }[]
        ) => {
          const bucketTopic = bucketTopics.filter(
            (bucket) => bucket.name === this.bucketName
          );
          console.log(bucketTopic[0])
          this.bucketImage = bucketTopic[0].image;
          this.bucketBlurb = bucketTopic[0].description;
          this.overridden = bucketTopic[0].overridden == "True";
          this.customPageOverrideNodeID = bucketTopic[0].custom_page_node_id;
          const currentBucketTopics = bucketTopic[0].topics.split(", ");
          for (let i = 0; i < currentBucketTopics.length; i++) {
            currentBucketTopics[i] = currentBucketTopics[i].replace(
              "&amp;",
              "&"
            );
          }

          this._apiService.getDrupalTopics().then((allTopics) => {
            for (const topic of allTopics) {
              if (currentBucketTopics.includes(topic.name)) {
                this.topics.push(topic);
              }
            }
          });
        }
      );
    return ["test"];
  }

  showMore() {
    this.maxTopicDisplayCount = this.topics.length;
    this.showMoreTopics = true;
  }

  showLess() {
    this.maxTopicDisplayCount = 5;
    this.showMoreTopics = false;
  }
}
