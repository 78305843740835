<div class="lp-header">
  <swiper
		class="full-width"
    [pagination]="{ type: 'progressbar' }"
    [navigation]="true"
    [autoplay]="{
      delay: 10000,
      disableOnInteraction: false
    }"
    (swiper)="onSwiper($event)"
    [a11y]="true"
    #swiper
  >
    <!-- [mousewheel]="true" -->
    <ng-template swiperSlide *ngFor="let h of headers | async; index as i">
      <div class="lp-header-slide" [ngStyle]="h.bgStyle">
        <div class="text">
          <h1 [innerHTML]="h.title"></h1>
          <div *ngIf="h.description" [innerHTML]="h.description"></div>
          <a [href]="h.link">
            click here to learn more
            <i class="fas fa-arrow-right"></i>
          </a>
          <app-searchbar
            (click)="searchFocused()"
            (keyup)="searchFocused()"
            (focusout)="searchFocused()"
          ></app-searchbar>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
