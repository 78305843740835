<h2><span class="acpf-light-text">Logged in as:</span> {{ user?.name }}</h2>

<br />

<div *ngIf="user && user.files.length == 0">
  You have not yet uploaded any files.
</div>

<div *ngIf="user && user.files.length > 0">
  <ng-container *ngIf="hasCore()">
    You have uploaded the following core datasets:
    <ul>
      <ng-container *ngFor="let f of user.files">
        <li *ngIf="f.file_type == 'core'">
          <a [href]="f.file_url" target="_parent">{{ f.filename }}</a>
        </li>
      </ng-container>
    </ul>
  </ng-container>
  <ng-container *ngIf="hasResults()">
    You have uploaded the following results datasets:
    <ul>
      <ng-container *ngFor="let f of user.files">
        <li *ngIf="f.file_type == 'result'">
          <a [href]="f.file_url" target="_parent">{{ f.filename }}</a>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <div>
    To update a file you have uploaded, please re-upload it following the same
    naming convention (i.e. acpf070802010901.gdb.zip). This will update the
    master version of the file that will be available to download on our map.
    Previous versions will be preserved on this page.
    <br /><br />
    If you have any questions or would like to provide feedback please email us
    at
    <strong>
      <a href="mailto:kx-admin@osu.edu">kx-admin@osu.edu</a>
    </strong>
  </div>
</div>

<br />

<ng-container *ngIf="user && user.verified; else notVerified">
  <a href="/acpf-file-manager/upload/" target="_parent">Upload data</a><br />
</ng-container>
<ng-template #notVerified>
  <div>
    Your request to upload files has been submitted to the KX team and is
    pending approval. Please <a href="mailto:kx-admin@osu.edu">contact us</a> if
    we have not responded to your request in a timely manner.
  </div>
  <br />
</ng-template>

<a href="/acpf-file-manager/log-out" target="_parent">Log out</a>
