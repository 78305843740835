<h1 class="wide-page-header dark teal" *ngIf="!router.url.includes('embed')">
  ACPF File Upload
</h1>
<div
  [ngClass]="{
    'responsive-page-container-sm': !router.url.includes('embed'),
    'acpf-upload-page': true
  }"
>
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'Init'">
      <app-acpf-fm-login
        (tokenAquired)="status = AcpfFileManagerAppStatus.Manage"
      ></app-acpf-fm-login>
    </ng-container>
    <ng-container *ngSwitchCase="'Manage'">
      <app-acpf-fm-list-files-uploaded></app-acpf-fm-list-files-uploaded>
    </ng-container>
    <ng-container *ngSwitchCase="'Upload'">
      <app-acpf-fm-upload></app-acpf-fm-upload>
    </ng-container>
    <ng-container *ngSwitchCase="'Reset_Password'">
      <app-acpf-fm-reset-password></app-acpf-fm-reset-password>
    </ng-container>
  </ng-container>
</div>
