<div *ngIf="infographic" class="row infographic m-0 p-0">
  <div class="img-wrap col-md-8">
    <img
      [src]="infographic.backgroundImage"
      width="100%"
      id="infographic-image"
    />
    <div
      *ngFor="let hotspot of infographic.hotspots; index as i"
      class="hotspot-button"
      id="hotspot{{ i }}"
      [ngClass]="{ active: isActive(i) }"
      [ngStyle]="getButtonStyle(hotspot)"
      (click)="activateHotspot($event, i)"
    >
      <i class="fas fa-plus"></i>
    </div>
  </div>
  <div
    class="col-md-4 m-0 p-0 hotspots-container"
    [style.height.px]="getImgHeight()"
  >
    <p *ngIf="activeHotspotIndex == null" class="m-3">
      Click on a button to get started.
    </p>
    <ng-container *ngFor="let hotspot of infographic.hotspots; index as i">
      <div [ngClass]="{ active: isActive(i), 'hotspot-popup': true }">
        <img *ngIf="hotspot.image" [src]="hotspot.image" />
        <h3 *ngIf="hotspot.title">{{ hotspot.title }}</h3>
        <p *ngIf="hotspot.description" [innerHTML]="hotspot.description"></p>
        <a
          *ngIf="hotspot.link"
          class="btn btn-dark"
          [href]="hotspot.link"
          target="_blank"
          >Learn More</a
        >
      </div>
    </ng-container>
  </div>
</div>
