<app-full-page-image-header
title="Management Options to Reduce Lake Erie Algal Blooms"
description="Reducing Harmful Algal Blooms (HABs) is challenging. The U.S. and Canada have set a goal of
reducing the nutrients that cause HABs by 40% by 2025. A lot of different strategies have been
proposed and debated for reducing the nutrients that go into Lake Erie. But will these
management options really work? Before spending millions of dollars on nutrient management
strategies, a research team of 5 institutions (Ohio State University, Heidelberg University,
University of Toledo, University of Michigan, and LimnoTech) looked to see how effective they
would be at meeting this 40% reduction goal.
Scroll down to see what researchers have found might work, and what might not."
headerImage="https://kx-assets.s3.amazonaws.com/2020-05/Great_Lakes_from_space%20small.jpg"
></app-full-page-image-header>

<div class="sct s2">
  <div class="row scroll-content">
    <div class="col-5">
      <div class="image-container">
        <div class="nav-dots">
          <div
            class="dot-1"
            (click)="dotClick(1)"
            (keyup)="dotClick(1)"
            (keydown)="dotClick(1)"
          ></div>
          <div
            class="dot-2"
            (click)="dotClick(2)"
            (keyup)="dotClick(2)"
            (keydown)="dotClick(2)"
          ></div>
          <div
            class="dot-3"
            (click)="dotClick(3)"
            (keyup)="dotClick(3)"
            (keydown)="dotClick(3)"
          ></div>
          <div
            class="dot-4"
            (click)="dotClick(4)"
            (keyup)="dotClick(4)"
            (keydown)="dotClick(4)"
          ></div>
          <div
            class="dot-5"
            (click)="dotClick(5)"
            (keyup)="dotClick(5)"
            (keydown)="dotClick(5)"
          ></div>
          <div
            class="dot-6"
            (click)="dotClick(6)"
            (keyup)="dotClick(6)"
            (keydown)="dotClick(6)"
          ></div>
          <div
            class="dot-7"
            (click)="dotClick(7)"
            (keyup)="dotClick(7)"
            (keydown)="dotClick(7)"
          ></div>
          <div
            class="dot-8"
            (click)="dotClick(8)"
            (keyup)="dotClick(8)"
            (keydown)="dotClick(8)"
          ></div>
        </div>

        <img
          src="assets/img/water/nopointsource.png"
          alt="graphic showing farm field and discharge pipe for non-point source pollution"
          class="picture no1"
        />
        <img
          src="assets/img/water/nopointsource+popover.png"
          alt="photo showing example of discharge pipe for non-point source pollution"
          class="picture noptsrc-pop"
        />

        <img
          src="assets/img/water/wintercovercrops.png"
          alt="graphic showing farm field with winter cover crops"
          class="picture no2"
        />
        <img
          src="assets/img/water/wintercovercrops+popover.png"
          alt="photo showing field of winter wheat cover crop"
          class="picture wcc-pop"
        />

        <img
          src="assets/img/water/wetlands.png"
          alt="graphic showing farm field with wetlands at edge"
          class="picture no3"
        />
        <img
          src="assets/img/water/wetlands+popover.png"
          alt="photo of a wetland"
          class="picture wetlands-pop"
        />

        <img class="picture no4" />

        <img
          src="assets/img/water/bundle-drainage-no-till-cover-crop-subsurface-placement.png"
          alt="graphic showing farm field and bundled drainage management, no tillage, cover crops and subsurface placement of fertilizer"
          class="picture no5"
        />
        <img
          src="assets/img/water/1drainage.png"
          alt="graphic showing farm field with subsurface drainage tiles"
          class="picture drainage-pop-ONE"
        />
        <img
          src="assets/img/water/1no-till.png"
          alt="graphic showing farm field that has not been tilled"
          class="picture no-till-pop-ONE"
        />
        <img
          src="assets/img/water/1cover-crops.png"
          alt="graphic showing farm field with representation of winter cover crops"
          class="picture cover-crops-pop-ONE"
        />
        <img
          src="assets/img/water/1subsurface-placement.png"
          alt="graphic showing farm field with shading below surface to represent subsurface placement of fertilizer"
          class="picture subsurface-placement-pop-ONE"
        />

        <img
          src="assets/img/water/bundle-wetlands-cover-crops-subsurface.png"
          alt="graphic showing farm field and bundled management option including wetlands, cover crops, and subsurface placement of fertilizer"
          class="picture no6"
        />
        <img
          src="assets/img/water/2wetlands.png"
          alt="graphic showing farm field with edge-of-field wetlands"
          class="picture wetlands-pop-TWO"
        />
        <img
          src="assets/img/water/2cover-crops.png"
          alt="graphic showing farm field with cover crops"
          class="picture cover-crops-pop-TWO"
        />
        <img
          src="assets/img/water/2subsurface-placement.png"
          alt="graphic showing farm field with shading representing subsurface placement of fertilizer"
          class="picture subsurface-pop-TWO"
        />

        <img
          src="assets/img/water/bundle-buffer-strips-cover-crops-subsurface-placement.png"
          alt="graphic showing farm field and the bundled managment options of buffer strips, cover crops and subsurface placement of fertilizer"
          class="picture no7"
        />
        <img
          src="assets/img/water/3buffer-strips.png"
          alt="graphic showing farm field with shading representing edge-of-field buffer strips"
          class="picture buffer-strips-pop-THREE"
        />
        <img
          src="assets/img/water/3cover-crops.png"
          alt="graphic showing farm field with shading representing cover crops"
          class="picture cover-crops-pop-THREE"
        />
        <img
          src="assets/img/water/3subsurface-placement.png"
          alt="graphic showing farm field with shading representing subsurface placement of fertilizer"
          class="picture subsurface-placement-pop-THREE"
        />

        <img class="picture no8" />

        <img
          src="assets/img/water/pt-src-chart-01.svg"
          alt="horizontal bar graph showing that point source discharge management does not effectively reduce total phosphorous or dissolved reactive phosphorous"
          class="graph no1"
        />
        <img
          src="assets/img/water/cover-crops-chart-01.svg"
          alt="horizontal bar graph showing that cover crops do not effectively reduce total phosphorous or dissolved reactive phosphorous"
          class="graph no2"
        />
        <img
          src="assets/img/water/wetlands-chart-01.svg"
          alt="horizontal bar graph showing that adding wetlands does not effectively reduce total phosphorous or dissolved reactive phosphorous"
          class="graph no3"
        />
        <img class="graph no4" />
        <img
          src="assets/img/water/bundle-with-drainage-water-mgmt-and-in-field-01.svg"
          alt="horizontal bar graph showing that the bundled option using drainage water, subsurface fertilizer, and buffer management does not effectively reduce total phosphorous or dissolved reactive phosphorous"
          class="graph no5"
        />
        <img
          src="assets/img/water/bundle-with-wetlands-01.svg"
          alt="horizontal bar graph showing that combination of wetland installation on 78% of headwater lands, cover crops installed on 50% of acres, and injection of fertilizers directly into the soil, instead of being spread on top of fields on 50% of acres does not effectively reduce total phosphorous or dissolved reactive phosphorous"
          class="graph no6"
        />
        <img
          src="assets/img/water/bundle-with-buffers-and-in-field-01.svg"
          alt="horizontal bar graph showing that the combination of management practices comes close to reducing dissolved reactive and total phosphorous"
          class="graph no7"
        />
        <img class="graph no8" />
      </div>
    </div>
    <div class="col-7 words-container">
      <div class="row combination c1" appScroll (enterScreen)="update(false, 1)">
        <h4>
          Will eliminating nutrients from <strong>all </strong>
          <span
            class="pop"
            (mouseover)="p(noptsrc)"
            (blur)="p(noptsrc)"
            (mouseout)="update(true, 1)"
            (focus)="update(true, 1)"
            >point sources</span
          >
          work?
        </h4>
        <br />
        <p>
          Point sources of nutrients are known places along the water that pollution and nutrients
          come from, and we know exactly where the pollution or nutrients were created. Examples of
          point sources include waste pipes from factories, power plants, wastewater treatment
          plants, and other sources that go directly into the water. Agriculture is not considered a
          point source.
        </p>
        <br />
        <p>
          Eliminating 100% of nutrients from point sources WILL NOT achieve the 40% nutrient
          reduction goal.
        </p>
      </div>

      <div class="row combination c2" appScroll (enterScreen)="update(false, 2)">
        <h4>
          Will planting <strong>all</strong> farmland with
          <span
            class="pop"
            (mouseover)="p(wcc)"
            (blur)="p(wcc)"
            (mouseout)="update(true, 2)"
            (focus)="update(true, 2)"
            >winter cover crops</span
          >
          work?
        </h4>
        <br />
        <p>
          Fertilizers that contain phosphorous help agricultural crops grow. After harvest, and
          before the next seasons’ planting, rains and weather can cause phosphorous that remains in
          the soil to run off into local waterways and eventually into Lake Erie. Planting fall and
          winter cover crops, like cereal rye, can help keep the nutrients from running off.
        </p>
        <br />
        <p>
          Planting cover crops on 100% of the agricultural acres in the Maumee watershed will not
          achieve the 40% nutrient reduction goal, but it does come close.
        </p>
      </div>

      <div class="row combination c3" appScroll (enterScreen)="update(false, 3)">
        <h4>
          Will restoring
          <span
            class="pop"
            (mouseover)="p(wetlands)"
            (blur)="p(wetlands)"
            (mouseout)="update(true, 3)"
            (focus)="update(true, 3)"
            >wetlands</span
          >
          work?
        </h4>
        <br />
        <p>
          Wetlands are land areas covered by water, like marshes, swamps, edge of ponds, and river
          deltas. Wetlands act as a natural filter that absorbs nutrients as nutrient-rich water
          flows through. Restoring wetlands near sources of nutrient runoff can reduce nutrients
          downstream.
        </p>
        <br />
        <p>
          Restoring enough wetlands to receive 50% of water drained from the land WILL NOT achieve
          the 40% reduction goal.
        </p>
      </div>

      <div class="row combination full-width c4" appScroll (enterScreen)="update(false, 4)">
        <h4>
          If there is no single silver-bullet management practice that is both practical and will
          achieve the 40% goal, will <strong>combinations</strong> of management practices reach the
          40% goal?
        </h4>
        <br />
        <p>
          Researchers ran models on a set of practical scenarios to see if combinations of
          management practices can achieve the 40% if adopted on a large scale.
        </p>
        <br />
        <p>
          They found that these ‘bundles’ of management practices can move us closer to the 40%
          goal.
        </p>
        <br />
        <div class="scroll">Scroll down to see what might work.</div>
      </div>

      <div class="row combination c5" appScroll (enterScreen)="update(false, 5)">
        <h4>Will bundling management practices together work?</h4>
        <br />
        <p>
          The first bundle tested is a combination of
          <span
            class="pop"
            (mouseover)="p(a1)"
            (blur)="p(a1)"
            (mouseout)="update(true, 5)"
            (focus)="update(true, 5)"
            >improved drainwater management</span
          >
          on 15% of acres,
          <span class="pop" (mouseover)="p(b1)" (mouseout)="update(true, 5)">no-till</span>
          agricultural adoption on 50% of acres,
          <span class="pop" (mouseover)="p(c1)" (mouseout)="update(true, 5)">cover crops</span>
          installed on 50% of acres, and
          <span class="pop" (mouseover)="p(d1)" (mouseout)="update(true, 5)"
            >injection of fertilizers directly into the soil</span
          >, instead of being spread on top of fields on 60% of acres.
        </p>
        <div class="row bar">
          <div class="col-3"><span>Drainage Water Management</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="15"><div></div></div>
          </div>
        </div>
        <div class="row bar">
          <div class="col-3"><span>No-Tillage</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="50"><div></div></div>
          </div>
        </div>
        <div class="row bar">
          <div class="col-3"><span>Cover Crops</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="50"><div></div></div>
          </div>
        </div>
        <div class="row bar">
          <div class="col-3"><span>Subsurface Placement</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="60"><div></div></div>
          </div>
        </div>
        <h4>Will this combination reach the 40% reduction goal?</h4>
        <br />
        <p>
          This combination moves us closer to the 40% reduction on average across all models but
          does not get us all the way there. A few models predict this combination can achieve the
          40% reduction.
        </p>
      </div>

      <div class="row combination c6" appScroll (enterScreen)="update(false, 6)">
        <h4>Will bundling management practices together work?</h4>
        <br />
        <p>
          This bundle tested a combination of
          <span
            class="pop"
            (mouseover)="p(a2)"
            (blur)="p(a2)"
            (mouseout)="update(true, 6)"
            (focus)="update(true, 6)"
            >wetland installation</span
          >
          on 78% of headwater lands,
          <span class="pop" (mouseover)="p(b2)" (mouseout)="update(true, 6)">cover crops</span>
          installed on 50% of acres, and
          <span class="pop" (mouseover)="p(c2)" (mouseout)="update(true, 6)"
            >injection of fertilizers directly into the soil</span
          >, instead of being spread on top of fields on 50% of acres.
        </p>
        <div class="row bar">
          <div class="col-3"><span>Wetlands</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="78"><div></div></div>
          </div>
        </div>
        <div class="row bar">
          <div class="col-3"><span>Cover Crops</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="58"><div></div></div>
          </div>
        </div>
        <div class="row bar">
          <div class="col-3"><span>Subsurface Placement</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="50"><div></div></div>
          </div>
        </div>
        <h4>Will this combination reach the 40% reduction goal?</h4>
        <br />
        <p>
          Again, this combination moves us closer to the 40% reduction on average across all models
          but does not get us all the way there. A few models predict this combination can achieve
          the 40% reduction.
        </p>
        <br />
        <h4>
          What if we use edge-of-field buffer strips and crank up the adoption of cover crops and
          subsurface placement of fertilizer?
        </h4>
        <br />
        <p>Keep scrolling to find out.</p>
      </div>

      <div class="row combination c7" appScroll (enterScreen)="update(false, 7)">
        <h4>Will bundling management practices together work?</h4>
        <br />
        <p>
          This bundle tested a combination of edge-of-field
          <span
            class="pop"
            (mouseover)="p(a3)"
            (blur)="p(a3)"
            (mouseout)="update(true, 7)"
            (focus)="update(true, 7)"
            >buffer strips</span
          >
          installed on 78% of acres,
          <span class="pop" (mouseover)="p(b3)" (mouseout)="update(true, 7)">cover crops</span>
          installed on 58% of acres, and
          <span class="pop" (mouseover)="p(c3)" (mouseout)="update(true, 7)"
            >subsurface injection of fertilizers</span
          >
          on 50% of acres.
        </p>
        <div class="row bar">
          <div class="col-3"><span>Buffer Strips</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="78"><div></div></div>
          </div>
        </div>
        <div class="row bar">
          <div class="col-3"><span>Cover Crops</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="58"><div></div></div>
          </div>
        </div>
        <div class="row bar">
          <div class="col-3"><span>Subsurface Placement</span></div>
          <div class="col-9">
            <div class="percent-adoption" data-percent="50"><div></div></div>
          </div>
        </div>
        <h4>Will this combination reach the 40% reduction goal?</h4>
        <p>
          Finally some success. This bundle of practices achieves the 40% reduction in Total
          Phosphorous on average across all the models run. It doesn’t quite reach the goal for
          Dissolved Reactive Phosphorous on average, but it comes the closest of all the bundles
          tested.
        </p>
      </div>

      <div class="row combination full-width c8" appScroll (enterScreen)="update(false, 8)">
        <h4>What should I take away from this information?</h4>

        <br />
        <p>
          No single management practice can reduce the amount of phosphorus entering the Western
          Basin of Lake Erie to the target levels.
        </p>
        <br />
        <p>
          Widespread adoption of different bundles of field and land nutrient management practices
          can reduce total phosphorous to taget levels.
        </p>
        <br />
        <p>
          Specifically, our last bundle (Buffer Strips, Cover Crops, and Subsurface Placement)
          achieves the goal of a 40% reduction in Total Phosphorous on average across all the models
          run. It doesn’t quite reach the goal for Dissolved Reactive Phosphorous on average, but it
          comes the closest of all the bundles tested.
        </p>
      </div>
    </div>
  </div>

  <br /><br /><br /><br /><br /><br /><br /><br />

  <div class="row">
    <div class="col-1"></div>
    <div class="col-3">
      <br />
      <p style="border: 1px solid black; padding: 7px; text-align: justify">
        This visual story is based on results from a three year project funded by the Ohio
        Department of Higher Education Harmful Algal Bloom Research Initiatives. The project was
        managed by The Ohio State University and included partners from the University of Toledo;
        LimnoTech; Heidelberg University; the University of Michigan; and United States Geological
        Survey.
      </p>
    </div>
    <div class="col-1"></div>
    <div class="col-7">
      <h4>The research team consists of scientists from:</h4>
      <u
        >The Ohio State University - Department of Food, Agricultural, and Biological Engineering</u
      >
      <ul>
        <li>Jay Martin, Professor, Project Leader</li>
        <li>Margaret Kalcic, Assistant Professor (Project Leader)</li>
        <li>Noel Aloysis, Postdoctoral Fellow (current affiliation: University of Missouri)</li>
        <li>Anna Apostel, Research Associate</li>
        <li>Michael Brooker, Postdoctoral Fellow</li>
        <li>Grey Evenson, Postdoctoral Fellow</li>
        <li>Jeffrey Kast, Research Associate</li>
        <li>Haley, Kujawa, Research Associate</li>
        <li>Asmita Mrumkar, Postdoctoral Fellow</li>
      </ul>

      <u>University of Toledo - Department of Environmental Sciences</u>
      <ul>
        <li>Richard Becker, Associate Professor</li>
      </ul>

      <u>LimnoTech</u>
      <ul>
        <li>Chelsie Boles, Environmental Engineer</li>
        <li>Todd Redder, Senior Environmental Engineer</li>
      </ul>

      <u>Heidelberg University, National Center for Water Quality Research</u>
      <ul>
        <li>Remegio Confesor, Senior Research Scientist</li>
        <li>Tian Guo, Postdoctoral Fellow Research Scientist</li>
      </ul>

      <u>University of Michigan - Graham Sustainability Institute</u>
      <ul>
        <li>
          Awoke Dagnew, Postdoctoral Fellow, (current affiliation: Environmental Consulting and
          Technology, Inc.)
        </li>
        <li>Colleen Long, Research Associate</li>
        <li>
          Rebecca Muenich, Postdoctoral Fellow, (current affiliation: Arizona State University)
        </li>
        <li>Donald Scavia, Professor Emeritus Yu-Chen Wang, Research Associate</li>
      </ul>

      <u>United States Geological Survey - Water Science Center </u>
      <ul>
        <li>Dale Robertson, Research Hydrologist</li>
      </ul>
    </div>
  </div>

  <br /><br /><br /><br /><br /><br /><br /><br />
</div>
