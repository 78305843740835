import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie";
import { environment } from "../../environments/environment";

const COOKIE_NAME: string = "KX-Authorization";
const COOKIE_VALUE: string = "Authorized";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private authorized: boolean;
  public failedAttempts: number = 0;

  constructor(private HttpClient: HttpClient, private CookieService: CookieService) {
    this.authorized = !environment.enableAuth;
  }

  /**
   * Check if the user is authorized currently, and if the parameter
   * is provided, will try to authorize and return result.
   *
   * @param key
   * Optional login details. (Currently just name.#)
   */
  public async check(key?: string): Promise<boolean> {
    
    if (this.authorized) return true;
    else if (key == "quigley.18") alert("go away sean")
    else if (this.validateFromCookie()) return true;
    else if (key) this.authorized = await this.verifyNameDotNumber(key);

    if (this.authorized) this.setCookie();
    else this.failedAttempts++;

    return this.authorized;
  }

  /**
   * Contact the OSU Find People API with the given username, and resolve to true if any data is returned.
   * @param name_n
   */
  private verifyNameDotNumber(name_n: string) {
    return new Promise<boolean>((resolve) => {
      this.HttpClient.get("https://directory.osu.edu/fpjson.php?name_n=" + name_n).subscribe(
        (response: any[]) => {
          resolve(response.length > 0);
        }
      );
    });
  }

  /**
   * Check if the auth cookie has been set in a previous session.
   */
  private validateFromCookie() {
    if (this.CookieService.get(COOKIE_NAME) == COOKIE_VALUE) this.authorized = true;
    return this.authorized;
  }

  /**
   * Set the auth cookie.
   */
  private setCookie() {
    this.CookieService.put(COOKIE_NAME, COOKIE_VALUE);
  }
}
