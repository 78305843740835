<div class="responsive-page-container-sm">
  <div id="thumb-reactions">
    <h4>Was this helpful?</h4>
    <img src="assets/img/content-icons/reactions/thumbs-up-not-selected.svg" class="icon" alt="Yes" id="imageUp"
      (click)="changeState('positive')" (mouseenter)="hover('upHover')" (mouseleave)="unhover('upOut')">
    <img src="assets/img/content-icons/reactions/thumbs-down-not-selected.svg" class="icon" alt="No" id="imageDown"
      (click)="changeState('negative')" (mouseenter)="hover('downHover')" (mouseleave)="unhover('downOut')">


    <div id="feedback">
      We're sorry you didn't find this helpful - please let us know why. <br>
      <br>
      <input type="checkbox" name="availableOption" id="inaccurate"> This information is not accurate <br>
      <input type="checkbox" name="availableOption" id="not looking for"> This isn't the information that I was looking
      for <br>
      <input type="checkbox" name="availableOption" id="other"> Other <br>
      <br>

      <div id="textbox">
        <mat-form-field appearance="outline">
          <textarea 
            id = "innerTextbox"
            [(ngModel)]="reactionForm.feedback" 
            matInput 
            placeholder="Have more feedback? Let us know!"
            rows="6">
          </textarea>
        </mat-form-field>
      </div>
      <button class="button" id="submit" (click)="submitReaction()">Submit</button> 
      <button class="button" id="cancel" (click)="feedbackToggle(false)">Cancel</button>
    </div>
  </div>
</div>