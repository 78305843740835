import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Rx";
import { Person, UserType } from "src/app/shared/models/person";
import { TitleService } from "src/app/services/title.service";
import {
  Department,
  DepartmentURL,
  ExpertFields,
  ExpertSearchService,
} from "src/app/services/expert-search.service";

@Component({
  selector: "app-person-profile",
  templateUrl: "./person-profile.component.html",
  styleUrls: ["./person-profile.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PersonProfileComponent implements OnInit {
  profile: Person;
  content: Observable<any>;
  isContributor: boolean = false;
  isDrupalUser: boolean = false;
  isExpert: boolean = false;
  expertDoc: any = {};
  dept = Department;
  deptURL = DepartmentURL;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private titleService: TitleService,
    private expertService: ExpertSearchService
  ) {
    this.route.params.subscribe((params) => {
      if (params.name_n) {
        this.profile = new Person(UserType.OSUInternal, params.name_n);
        this.profile
          .populateOSUData(
            this.api.getPersonInfoFromOSUDirectory(this.profile.id)
          )
          .then(() => {
            this.profile.populateDrupalUserData(
              this.api.getDrupalPerson(this.profile.id).map((res: any) => {
                this.isDrupalUser = res.length > 0;
                return res;
              })
            );
          });
        this.expertService
          .search({
            query: "",
            filters: { name_dot_number: params.name_n },
            size: 1,
          })
          .subscribe((res) => {
            this.isExpert = res.length > 0;
            if (this.isExpert) {
              this.expertDoc = res[0];
              console.log(this.expertDoc);
            }
          });
      } else if (params.external_id) {
        this.profile = new Person(UserType.External, params.external_id);
        this.profile.populateDrupalUserData(
          this.api.getDrupalPerson(this.profile.id)
        );
      }
      this.titleService.setPageTitle(this.profile.id);
      this.content = this.api.getDrupalContentByContributor(this.profile.id);
      this.content.subscribe((res: any[]) => {
        this.isContributor = res.length > 0;
      });
    });
  }

  ngOnInit() {}

  getExpertDepartmentName(abbr: string): string {
    if (!abbr) return "";
    const department = Object.entries(Department).find(
      ([key, value]) => key.toLowerCase() === abbr.toLowerCase()
    );
    return department ? department[1] : "";
  }
  getExpertDepartmentURL(abbr: string): string {
    if (!abbr) return "";
    const department = Object.entries(DepartmentURL).find(
      ([key, value]) => key.toLowerCase() === abbr.toLowerCase()
    );
    return department ? department[1] : "";
  }
}
