import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-music-tool-page",
  templateUrl: "./music-tool-page.component.html",
  styleUrls: ["./music-tool-page.component.scss"],
})
export class MusicToolPageComponent implements OnInit {
  tags: string[] = [];
  constructor(private _api: ApiService) {}

  ngOnInit(): void {
    this._api
      .getTagsByNodeID(546)
      .subscribe((res: { title: string; tags: string }[]) => {
        if (res[0]) this.tags = res[0].tags.split(", ");

        console.log(res, this.tags);
      });
  }
}
