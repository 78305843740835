<div class="hotspot-popup">
  <h3>{{h.title}}</h3>
  <button class="dialog-close-button" [mat-dialog-close]>
    <span class="material-icons"> close </span>
  </button>
  <div [innerHTML]="h.description"></div>
  <button mat-flat-button *ngIf="h.layerIds" (click)="goToMap()">
    Food System Map
  </button>
  <a mat-flat-button *ngIf="h.link" [href]="h.link" target="_blank" class="learn-more">
    Learn More
  </a>
</div>
