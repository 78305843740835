import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import SwiperCore, { A11y, Autoplay } from "swiper/core";

SwiperCore.use([Autoplay, A11y]);
import { contentListItem } from "../util";

@Component({
  selector: "app-callout",
  templateUrl: "./callout.component.html",
  styleUrls: ["./callout.component.scss"],
})
export class CalloutComponent {
  @Input() items: Observable<contentListItem[]>;
  autoplayOptions = {
    delay: 10000,
    disableOnInteraction: false,
  };

  getColor(i: number) {
    switch (i % 3) {
      case 0:
        return "primary";
      case 1:
        return "secondary";
      case 2:
        return "tertiary";
    }
  }
}
