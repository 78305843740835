<div class="page-background light search-page">
  <div class="responsive-page-container-lg">
    <mat-form-field class="search-bar">
      <mat-label>Search the Knowledge Exchange</mat-label>
      <i matPrefix class="fa fa-search" aria-hidden="true"></i>
      <input matInput type="text" [formControl]="searchTerm" autofocus [matAutocomplete]="auto"/>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredAutocompleteOptions | async" [value]="option" [innerHTML]="option">
        </mat-option>
      </mat-autocomplete>
      <button
        mat-button
        *ngIf="searchTerm.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchTerm.setValue('')"
        (keyup.enter)="searchTerm.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div class="row">
      <div class="filter-wrapper">
        <button
          mat-button
          aria-label="Filters"
          (click)="toggleFilters()"
          (keyup.enter)="toggleFilters()"
          class="toggle-filter hidden-desktop"
        >
          <i class="fas fa-chevron-up" id="toggle-icon"></i>
        </button>
        <div class="filters col-md-3 order-last order-md-first" id="filters">
          <a routerLink="/cfaes-search">Search for content from CFAES.</a>
          <mat-form-field>
            <mat-label>Sort</mat-label>
            <mat-select [formControl]="sortSelect">
              <mat-option [value]="SORT.RELEVANCE">Relevance</mat-option>
              <mat-option [value]="SORT.NEWEST">Newest</mat-option>
              <mat-option [value]="SORT.OLDEST">Oldest</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <div class="filter-group">
            <p class="title">Types</p>
            <div
              *ngFor="let filter of filterOptions.types"
              class="filter"
              (click)="toggleTypeFilter(filter.type)"
              (keyup.enter)="toggleTypeFilter(filter.type)"
              tabindex="0"
              [attr.aria-label]="getContentTypeName(filter.type)"
              [ngClass]="{
                active: searchOptions.filters.types.has(filter.type)
              }"
            >
              <span>{{ getContentTypeName(filter.type) }}</span>
              <span class="count">{{ filter.countObservable | async }}</span>
            </div>
          </div>
          <div class="filter-group">
            <p class="title">Topics</p>
            <div
              *ngFor="let filter of filterOptions.topics"
              class="filter"
              (click)="toggleTopicFilter(filter.topic)"
              (keyup.enter)="toggleTopicFilter(filter.topic)"
              tabindex="0"
              [attr.aria-label]="filter.topic"
              [ngClass]="{
                active: searchOptions.filters.topics.has(filter.topic)
              }"
            >
              <span>{{ filter.topic }}</span>
              <span class="count">{{ filter.countObservable | async }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9" id="results">
        <content-list
          type="grid"
          [content]="results"
          [sortByDate]="false"
          limit="1000"
          limit="1000"
        ></content-list>
        <p id="loading" class="hidden" aria-hidden="true">
          Loading more results...
        </p>
        <p id="end" class="hidden" aria-hidden="true">End of results!</p>
      </div>
    </div>
  </div>
</div>
