import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { map, mergeMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit {
  titletop;
  titlebottom;
  link;

  constructor(private activeRoute: ActivatedRoute, private router: Router) { }

  private setTitleFromRouteData(routeData) {
    if (routeData && routeData['titletop']) {
        this.titletop = routeData['titletop'];
        this.titlebottom = routeData['titlebottom'];
        this.link = '/covid-19'
    } else {
        this.titletop = 'KX';
        this.titlebottom = 'Knowledge Exchange'
        this.link = '/'
    }
}

private getLatestChild(route) {
    while (route.firstChild) {
        route = route.firstChild;
    }
    return route;
}

private subscribeToRouteChangeEvents() {
    // Set initial title
    const latestRoute = this.getLatestChild(this.activeRoute);
    if (latestRoute) {
        this.setTitleFromRouteData(latestRoute.data.getValue());
    }
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activeRoute),
        map((route) => this.getLatestChild(route)),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
    ).subscribe((event) => {
        this.setTitleFromRouteData(event);
    });
}


  ngOnInit() {
    this.subscribeToRouteChangeEvents()
  }


}
