<div class="infographic" *ngIf="infographic">
  <div class="img-wrap">
    <img
      [src]="infographic.backgroundImage"
      width="100%"
      id="infographic-image"
    />
    <div
      *ngFor="let hotspot of infographic.hotspots; index as i"
      class="hotspot-button"
      [ngStyle]="getButtonStyle(hotspot)"
      (click)="toggleHotspot($event, i)"
      (keyup.enter)="toggleHotspot($event, i)"
      tabindex="0"
      [attr.name]="hotspot.title"
      [attr.aria-label]="hotspot.title"
      [matTooltip]="hotspot.title"
    >
      <i class="fas fa-info"></i>
    </div>
  </div>
</div>
