import { viewClassName } from "@angular/compiler";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from "@angular/material/slide-toggle";
import { TitleService } from "src/app/services/title.service";
import { InstructionsDialog } from "./instructions/instructions-dialog";

@Component({
  selector: "app-food-system-visualization",
  templateUrl: "./food-system-visualization.component.html",
  styleUrls: ["./food-system-visualization.component.scss", "./colors.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FoodSystemVisualizationComponent implements OnInit {
  viewMode: "simple" | "default" = "default";
  @ViewChild("viewToggle") viewToggler: MatSlideToggle;

  constructor(public dialog: MatDialog, private _title: TitleService) {}

  ngOnInit(): void {
    this._title.setPageTitle("The Food System");
  }
  updateView(toggleToggler: boolean = false) {
    if (toggleToggler) this.viewToggler.toggle();

    this.viewMode = this.viewToggler.checked ? "simple" : "default";
  }
  toggleFoodChainSection(target: HTMLElement, overlay: HTMLElement) {
    target.classList.toggle("collapsed");
    overlay.classList.remove("active");
    if (!target.classList.contains("collapsed"))
      overlay.classList.add("active");
  }

  openInstructions() {
    this.dialog.open(InstructionsDialog, {
      panelClass: "fs-vis-instructions",
      maxWidth: "640px",
      maxHeight: "99vh",
    });
  }
}
