<div
  class="header-container"
  [ngClass]="pageScrolled ? 'small-header' : 'big-header'"
>
  <div class="header" [ngStyle]="setImageStyle()">
    <h1 class="heading">{{ text }}</h1>
    <h2 class="subhead">{{ subheader }}</h2>
    <app-searchbar> </app-searchbar>
  </div>
</div>
