import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss', '../../search/search.component.scss']
})
export class SearchHeaderComponent implements OnInit {

  @Input() text: string;
  @Input() subheader: string;
  @Input() image: string;
  searchTerm: string;
  pageScrolled = false;

  constructor(private router: Router) {
    // If desktop or tablet display
    if (window.innerWidth >= 600) {
      window.addEventListener('scroll', () => {
        const scrollY = document.documentElement.scrollTop;
        if (this.pageScrolled && scrollY < 1) { this.pageScrolled = false; }
        else if  (!this.pageScrolled && scrollY > 208) { this.pageScrolled = true; }
      });
    } else { // Phone display
      this.pageScrolled = true;
    }
  }

  ngOnInit() {
  }

  setImageStyle() {
    const styles = {
      'background-image': `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${this.image})`,
      'background-size': 'cover'
    };
    return styles
  }

  search() {
    this.router.navigate(["/search/" + this.searchTerm + "/all"]);
  }

  toggleLabel(visible: boolean): void {
    const label = document.getElementById('search-label');
    visible && !this.searchTerm ? label.classList.remove('hidden') : label.classList.add('hidden');
  }

}
