<ng-container *ngIf="ready; else loading">
  <div
    class="clp-wrapper"
    [ngClass]="{ 'pano-clp': panoId && panoId.length > 0 }"
  >
    <div id="clp" [class]="accentColor" class="responsive-page-container-lg">
      <div class="clp-header"
      [style.visibility]="tabNavStuck ? 'hidden' : 'visible'">
        <ng-container *ngIf="!(panoId && panoId.length > 0); else panoHeader">
          <div class="clp-info" [style.paddingBottom]="tabNavHeight">
            <ng-container [ngSwitch]="name">
							<ng-container *ngSwitchCase="'efields'">
              <img
                id="efields-logo"
                src="assets/img/efields 1-01.png"
                alt="efields: connecting science to fields"
              />
							</ng-container>
							<ng-container *ngSwitchCase="'ebarns'">
              <img
                id="ebarns-logo"
                src="assets/img/ebarns-logo.png"
                alt="ebarns: connecting science to farmers"
              />
							</ng-container>
							<ng-container *ngSwitchDefault>
              <img [src]="icon" alt="{{ title }} icon" />
              <h1 [innerHTML]="title"></h1>

							</ng-container>
            </ng-container>
            <div aria-hidden class="separator"></div>
            <div [innerHTML]="description"></div>
          </div>
          <div
            class="clp-img"
            [style.visibility]="tabNavStuck ? 'hidden' : 'visible'"
          >
            <img [src]="headerImage" [alt]="title" />
          </div>
        </ng-container>
        <ng-template #panoHeader>
          <div class="pano-header" [ngClass]="{ 'pano-opened': panoOpened }">
            <div
              class="pano-img"
              [style.visibility]="tabNavStuck ? 'hidden' : 'visible'"
              [style.--featured-image]="panoImg | async"
              (click)="openPano()"
            >
              <div class="animate"></div>
              <!-- <img [src]="pano.entranceImageURL" [alt]="title" /> -->
              <div class="pano-img-overlay">
                <div class="text">
                  <h2>Start the tour</h2>
                  <div class="icon">
                    <svg
                      height="32px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 128 128"
                    >
                      <path
                        d="M64,0a64,64,0,1,0,64,64A64,64,0,0,0,64,0Zm0,115.2A51.2,51.2,0,1,1,115.2,64,51.26,51.26,0,0,1,64,115.2Z"
                      />
                      <polygon
                        points="49.36 38.4 49.36 64 49.36 89.6 71.53 76.8 93.7 64 71.53 51.2 49.36 38.4"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="pano-wrapper" #pano>
              <app-panorama
                [id]="panoId"
                [embedded]="true"
                (fullscreenButtonClick)="openPano()"
              ></app-panorama>
            </div>
            <h1 [innerHTML]="title"></h1>
          </div>
        </ng-template>
      </div>
      <div
        class="tab-nav"
        #tabNav
        [style.marginTop]="'-' + tabNavHeight"
        [class.stuck]="tabNavStuck"
      >
        <img
          *ngIf="icon && icon.length > 0"
          [src]="icon"
          alt="{{ title }} icon"
        />
        <div class="tabs">
          <ng-container *ngFor="let tab of tabs; index as i">
            <a
              *ngIf="
                tab.label != 'Interact' || title != 'eFields';
                else interactTab
              "
              (click)="onTabClick(i)"
              (dblclick)="onTabDblClick(i)"
              (keyup.enter)="onTabClick(i)"
              [ngClass]="{ active: currentTabIndex == i }"
              target="_blank"
              [attr.href]="
                tab.link_override.length > 0 ? tab.link_override : undefined
              "
              tabindex="0"
              [matTooltip]="
                tab.double_click_link
                  ? tab.double_click_link.tooltip
                  : undefined
              "
              [matTooltipShowDelay]="2000"
            >
              {{ tab.label }}
              <span
                class="material-icons"
                *ngIf="tabs[i].link_override.length > 0"
              >
                open_in_new
              </span>
            </a>

            <ng-template #interactTab>
              <a
                (click)="efieldsInteractiveTabClick(tab.link_override)"
                (keyup.enter)="efieldsInteractiveTabClick(tab.link_override)"
                tabindex="0"
              >
                {{ tab.label }}
                <span
                  class="material-icons"
                  *ngIf="tabs[i].link_override.length > 0"
                >
                  open_in_new
                </span>
              </a></ng-template
            >
          </ng-container>
        </div>
      </div>
      <div
        class="clp-banner"
        *ngIf="currentTab?.banner_title.length > 0 || currentTab?.banner_text"
      >
        <div class="content">
          <h2
            [innerHTML]="currentTab?.banner_title"
            *ngIf="currentTab?.banner_title.length > 0"
          ></h2>
          <div
            [innerHTML]="currentTab?.banner_text"
            class="clp-banner-text"
          ></div>
          <a
            mat-raised-button
            *ngFor="let button of currentTab?.banner_buttons"
            [href]="button.url"
            [innerHTML]="button.name"
            class="banner-btn"
            target="_blank"
          >
          </a>
        </div>
      </div>
      <div class="clp-body" *ngIf="currentTab?.sectionObjs">
        <app-custom-page-section
          *ngFor="let t of currentTab?.sectionObjs"
          [sectionData]="t"
        ></app-custom-page-section>
      </div>
      <div
        class="clp-footer"
        *ngIf="currentTab?.footer_title || currentTab?.footer_text"
      >
        <div class="content">
          <h2
            [innerHTML]="currentTab?.footer_title"
            *ngIf="currentTab?.footer_title.length > 0"
          ></h2>
          <div
            [innerHTML]="currentTab?.footer_text"
            class="clp-footer-text"
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="p-5 m-5">
    <app-loading-indicator></app-loading-indicator>
  </div>
</ng-template>
