import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs-compat";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import { contentListItem } from "../util";

@Component({
  selector: "app-basic-list",
  templateUrl: "./basic-list.component.html",
  styleUrls: ["./basic-list.component.scss"],
})
export class BasicListComponent implements OnInit {
  @Input() items: Observable<contentListItem[]>;
  constructor() {}

  ngOnInit(): void {
    this.items.subscribe(r=>console.log(r))
    console.log()
  }
  getImgStyle(url, index) {
    if (url?.length > 0) return getBackgroundImageStyles(0, url);
    else
      switch (index % 3) {
        case 0:
          return { background: "#26686D" };
        case 1:
          return { background: "#E65F33" };
        case 2:
          return { background: "#6EBBAB" };
      }
  }
}
