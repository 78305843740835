import { Component, OnInit, Output } from "@angular/core";
import {
  AcpfAuthenticationService,
  AcpfUser,
} from "src/app/services/acpf-auth.service";
import { ApiService } from "src/app/services/api.service";
import dateFormat from "dateformat";

@Component({
  selector: "app-acpf-fm-upload",
  templateUrl: "./acpf-fm-upload.component.html",
  styleUrls: ["../acpf-file-manager.component.scss"],
})
export class AcpfFmUploadComponent implements OnInit {
  fileHandlers: AcpfFileHandlerList = [];
  user: AcpfUser;

  fileTypeBeingUploaded: "core" | "result" = "core";
  batchWideDetails: boolean = false;
  indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected: number = 0;

  constructor(public auth: AcpfAuthenticationService, public api: ApiService) {}

  ngOnInit(): void {}

  checkboxChange(handlerIndex: number) {
    this.batchWideDetails = !this.batchWideDetails;

    if (this.batchWideDetails)
      this.indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected =
        handlerIndex;

    this.copyHandlerDetailsIfShould();
  }

  copyHandlerDetailsIfShould() {
    if (this.batchWideDetails)
      this.fileHandlers.forEach((h, i) => {
        if (
          i !=
          this.indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected
        )
          Object.assign(
            h.extraInfo,
            this.fileHandlers[
              this
                .indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected
            ].extraInfo
          );
      });
  }

  async updatedInput(fileInput) {
    let files: File[] = fileInput.files;

    this.user = await this.auth.getCurrentUser().toPromise();

    this.fileHandlers = await Promise.all(
      Array.from(files).map((f) => new AcpfFileHandler(f).setup())
    );

    this.fileHandlers.forEach(
      (handler) =>
        (handler.filename = `${dateFormat(
          new Date(),
          "yyyy-mm-dd'T'HHMM"
        )}_${this.user.organization.replace(/[^\w\d]+/g, "-")}_${
          handler.file.name
        }`)
    );

    console.log(this.fileHandlers);
  }

  hasErrors() {
    return this.fileHandlers.some((h) => !h.isValid());
  }

  deleteFileHandler(index: number) {
    this.fileHandlers.splice(index, 1);
  }

  allDone() {
    return this.fileHandlers.every(
      (h) => h.uploadStatus == "uploaded successfully"
    );
  }

  uploadFile(handler: AcpfFileHandler) {
    if (handler.uploadStatus != "ready to submit") return;
    handler.uploadStatus = "uploading...";
    this.api
      .acpfFileUpload({
        token: this.auth.token,
        file: {
          filename: handler.filename,
          base64EncodedFile: <string>handler.base64EncodedFile,
          type: this.fileTypeBeingUploaded,
        },
        extra_info: handler.extraInfo,
      })
      .subscribe((res: any) => {
        console.log(res);
        if (res.uploaded) {
          handler.uploadStatus = "uploaded successfully";
          handler.url = res.url;
        } else handler.uploadStatus = "upload failed";
      });
  }
  upload() {
    this.copyHandlerDetailsIfShould();
    for (let h of this.fileHandlers) if (h.isValid) this.uploadFile(h);
  }
  disableUpload() {
    let uploading = this.fileHandlers.some(
      (h) => h.uploadStatus == "uploading..."
    );
    let uploaded = this.fileHandlers.every(
      (h) =>
        h.uploadStatus == "uploaded successfully" ||
        h.uploadStatus == "upload failed"
    );

    return uploading || uploaded;
  }
}

// const MAX_FILE_SIZE = 6000000;
const MAX_FILE_SIZE = 209715200;

class AcpfFileHandler {
  uploadStatus:
    | "has errors"
    | "ready to submit"
    | "uploading..."
    | "uploaded successfully"
    | "upload failed";
  errors: string[] = [];
  base64EncodedFile: string | ArrayBuffer;
  filename: string = "";
  url: string;
  extraInfo: AcpfFileExtraInfo = {
    contact_email: "",
    contact_name: "",
    contact_org: "",
    description: "",
    data_source: "",
    what_was_done_to_validate_the_results: "",
  };

  constructor(public file: File) {
    if (file.size >= MAX_FILE_SIZE) this.errors.push("exceeds max file size");
    this.filename = this.file.name;
    if (!/acpf\d{12}\.gdb\.zip/g.exec(this.filename))
      this.errors.push("does not match naming convention");
    this.uploadStatus = this.isValid() ? "ready to submit" : "has errors";
  }

  isValid() {
    return this.errors.length == 0;
  }

  setup() {
    return new Promise<AcpfFileHandler>((resolve) => {
      if (!this.isValid()) resolve(this);
      let reader = new FileReader();
      reader.addEventListener("load", (event: ProgressEvent<FileReader>) => {
        this.base64EncodedFile = event.target.result;
        resolve(this);
      });
      reader.readAsDataURL(this.file);
    });
  }
}
type AcpfFileHandlerList = AcpfFileHandler[];
type AcpfFileExtraInfo = {
  contact_email: string;
  contact_name: string;
  contact_org: string;
  description: string;
  data_source: string,
  what_was_done_to_validate_the_results: string,
};
