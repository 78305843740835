<swiper
  class="full-width"
  [slidesPerView]="1"
  [loop]="true"
  [autoplay]="{
    delay: 12000,
    disableOnInteraction: true
  }"
>
  <ng-template swiperSlide *ngFor="let a of announcements | async">
    <a
      class="announcement-header-item"
      [attr.href]="a.link"
      [style.background]="a.color"
    >
      <img *ngIf="a.icon.length > 0" [src]="a.icon" alt="a.title" />
      <span> {{ a.title }}</span>
    </a>
  </ng-template>
</swiper>
