<div class="brand-header">
  <div id="kx-logo">
    <a href="/">
      <img
        src="/assets/img/brand/KXLogo_Stacked_Color.svg"
        alt="Knowledge Exchange"
      />
    </a>
  </div>
  <a href="https://cfaes.osu.edu" id="cfaes-osu">
    <img src="/assets/img/brand/CFAES.png" alt="CFAES" height="80" />
  </a>
  <!-- <a href="/demo/" id="featured-banner" >
        <img src="/assets/img/150_banner.png" alt="150">
    </a> -->
</div>
