import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-contact-us-overlay",
  templateUrl: "./contact-us-overlay.component.html",
  styleUrls: ["./contact-us-overlay.component.scss"],
})
export class ContactUsOverlayComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog(): void {
    const dialogRef = this.dialog.open(ContactPopupComponent, {});
  }
}

@Component({
  styleUrls: ["./contact-us-overlay.component.scss"],
  templateUrl: "./popup.component.html",
})
export class ContactPopupComponent {
  constructor(public dialogRef: MatDialogRef<ContactPopupComponent>) {}

  close() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 7000);
    
  }
}
