<ng-container *ngIf="!overridden; else customPageOverride">
  <app-search-header
    [image]="bucketImage"
    [text]="bucketName"
    [subheader]="bucketBlurb"
  ></app-search-header>
  <h2 class="topics-title">Newest Content</h2>
  <app-content-slider
    class="col-12"
    [contentObservable]="newestContentObservable"
    [joinedObservable]="true"
  ></app-content-slider>

  <h2 class="topics-title">Topics</h2>
  <div class="topics">
    <div
      class="topic-container swing-in-top-fwd"
      *ngFor="let topic of topics | slice: 0:maxTopicDisplayCount"
    >
      <a href="topic/{{ topic.url }}">
        <ng-container *ngIf="topic.image === ''; else elseBlock">
          <img
            src="https://kx-assets.s3.amazonaws.com/red.png"
            [alt]="topic.name"
          />
        </ng-container>
        <ng-template #elseBlock>
          <img src="{{ topic.image }}" [alt]="topic.name" />
        </ng-template>
      </a>
      <div class="topic-name">{{ topic.name }}</div>
    </div>
  </div>
  <div class="show-more-container" *ngIf="topics.length > 5">
    <div *ngIf="showMoreTopics; else elseBlock">
      <button (click)="showLess()" (keyup.enter)="showLess()" mat-raised-button>Show Less</button>
    </div>
    <ng-template #elseBlock>
      <button (click)="showMore()" (keyup.enter)="showMore()" mat-raised-button>Show More</button>
    </ng-template>
  </div>
</ng-container>
<ng-template #customPageOverride> 
  <app-custom-page [nodeID]="customPageOverrideNodeID"></app-custom-page>
</ng-template>
