import { Component, OnInit } from "@angular/core";
import { TitleService } from "src/app/services/title.service";

@Component({
  selector: "app-data-terms-of-use",
  templateUrl: "./data-terms-of-use.component.html",
  styleUrls: ["./data-terms-of-use.component.scss"],
})
export class DataTermsOfUseComponent {
  constructor(private _tit: TitleService) {
    this._tit.setPageTitle("Data Use");
  }
}
