import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import Swiper from "swiper";
import { contentListItem } from "../util";

const BREAKPOINTS = [
  { sliderSize: 5, minWidth: 1200, maxWidth: 99999 },
  { sliderSize: 4, minWidth: 900, maxWidth: 1200 },
  { sliderSize: 3, minWidth: 700, maxWidth: 900 },
  { sliderSize: 2, minWidth: 500, maxWidth: 700 },
  { sliderSize: 1, minWidth: 0, maxWidth: 500 },
];

@Component({
  selector: "app-image-slider",
  templateUrl: "./image-slider.component.html",
  styleUrls: ["./image-slider.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ImageSliderComponent {
  @Input() items: Observable<contentListItem[]>;
  swiper: Swiper;

  constructor(private cdr: ChangeDetectorRef) {}

  slidesPerView() {
    let elWidth = this.swiper?.$el[0].clientWidth ?? 0;

    return BREAKPOINTS.find(
      (bkpt) => bkpt.minWidth <= elWidth && bkpt.maxWidth > elWidth
    ).sliderSize;
  }
  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
    this.swiper.onAny(() => this.cdr.detectChanges());
    console.log(this.swiper)
  }
  navigateTo(i: number) {
    this.swiper.slideTo(i);
  }
  getImgStyle(url) {
    return url?.length > 0
      ? getBackgroundImageStyles(0, url)
      : { background: "#ddd" };
  }
}
