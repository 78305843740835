import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { contentListItem } from "../util";

@Component({
  selector: "app-whats-new-swiper",
  templateUrl: "./whats-new-swiper.component.html",
  styleUrls: ["./whats-new-swiper.component.scss"],
})
export class WhatsNewSwiperComponent implements OnInit {
  @Input() items: Observable<contentListItem[]>;
	newestContent: contentListItem[];
	

  get firstFiveItems(): Observable<contentListItem[]> {
    return this.items.pipe(
      map((contentList: contentListItem[]) => {
        return contentList.slice(0, 5);
      })
    );
  }

  constructor() {}

  ngOnInit(): void {
    this.items.subscribe((res) => console.log(res));
		this.firstFiveItems.subscribe((items) => {
			this.newestContent = items
			console.log(items)
	});
  }
}
