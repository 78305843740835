<div class="page-background light cfaes-search">
  <div class="responsive-page-container-lg">
    <div
      class="search-area"
      [ngClass]="{ fill: resultCount <= 0 || searchTerm.value.length <= 0 }"
    >
      <h1>
        Find resources from the College of Food, Agricultural, and Environmental
        Sciences
      </h1>
      <mat-form-field class="search-bar" (keyup.enter)="search()">
        <mat-label>Enter keywords, e.g. "watersheds"</mat-label>
        <i matPrefix class="fa fa-search" aria-hidden="true"></i>
        <input matInput type="text" [formControl]="searchTerm" autofocus />
        <button
          mat-button
          *ngIf="searchTerm.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchTerm.setValue('')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div class="buttons">
        <button
          mat-stroked-button
          color="primary"
          (click)="searchCfaes()"
          [disabled]="searchTerm.value.length <= 0"
        >
          Search CFAES
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="searchFactSheets()"
          [disabled]="searchTerm.value.length <= 0"
        >
          Search Fact Sheets
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="searchPeople()"
          [disabled]="searchTerm.value.length <= 0"
        >
          Find an Expert
        </button>
        <button mat-stroked-button color="primary" (click)="searchKx()">
          Search KX
        </button>
      </div>
    </div>
    <!-- <a routerLink="/search">Search for content from Knowledge Exchange.</a> -->
    <div class="results">
      <div class="top">
        <h2 *ngIf="this.results">{{ getResultTypeMsg() }}</h2>
        <mat-paginator
          #paginator
          *ngIf="resultCount > 0"
          [length]="resultCount"
          [pageSize]="count"
          [hidePageSize]="true"
          (page)="paginate($event)"
        ></mat-paginator>
      </div>
      <content-list
        *ngIf="this.results"
        [content]="results"
        [sortByDate]="false"
        type="basic-list"
      ></content-list>
    </div>
  </div>
</div>
