import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

	transform(rawNum: string) {
		return `${rawNum.slice(0, 3)}-${rawNum.slice(3,6)}-${rawNum.slice(6, 10)}`
  }

}
