import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { Options } from "selenium-webdriver";
import { DataSetEntry } from "src/app/main/data-sets/data-catalog/data-catalog.component";
import { ApiService } from "src/app/services/api.service";
import { BooleanLiteral, StringLiteral } from "typescript";

export type reactionFormFull = {
  source: string;
  time: string;
  reaction: reactionFormat;
};
export type reactionFormat = {
  options: string[];
  feedback: string;
};
export type thumbFormFull = {
  helpful: boolean;
  source: string;
  time: string;
};

@Component({
  selector: "app-reactions",
  templateUrl: "./app-reactions.html",
  styleUrls: ["./app-reactions.scss"]
})
export class ReactionComponent {
  @Output() completed = new EventEmitter();

  reaction: string;
  constructor(private api: ApiService) {
    this.reaction = 'neutral';
  }

  reactionForm: reactionFormat = {
    options: [],
    feedback: ""
  };

  changeState(newReaction: string) {
    if (this.reaction == 'neutral' || this.reaction != newReaction) {
      this.reaction = newReaction;
      this.submitThumb();
    } else {
      this.reaction = 'neutral';
    }
    this.updateState();
  }

  feedbackToggle(display: boolean) {
    if (display) {
      document.getElementById('feedback').style.display = 'block';
    } else {
      document.getElementById('feedback').style.display = 'none';
    }
  }

  submitThumb() {
    let data: thumbFormFull = {
      source: window.location.href,
      time: (new Date()).toUTCString(),
      helpful: this.reaction == "positive",
    };
    console.log(data);
    this.api.submitThumb(data).subscribe((res) => { });
  }

  submitReaction() {
    var listOfOptions = document.getElementsByName('availableOption');
    for (var i = 0; i < listOfOptions.length; i++) {
      var element = listOfOptions[i] as HTMLInputElement;
      if(element.checked) {
        this.reactionForm.options.push(listOfOptions[i].getAttribute('id'));
      }
    }

    let data: reactionFormFull = {
      source: window.location.href,
      time: (new Date()).toUTCString(),
      reaction: this.reactionForm,
    };
    console.log(data);
    this.api.submitReactionForm(data).subscribe((res) => {
      this.completed.emit(true);
    });

    this.clearFeedback();
  }

  updateState() {
    if (this.reaction == "positive") {
      document.getElementById('imageUp').setAttribute('src', "assets/img/content-icons/reactions/thumbs-up-selected.svg");
      document.getElementById('imageDown').setAttribute('src', "assets/img/content-icons/reactions/thumbs-down-not-selected.svg");
      this.feedbackToggle(false);

    } else if (this.reaction == "negative") {
      document.getElementById('imageUp').setAttribute('src', "assets/img/content-icons/reactions/thumbs-up-not-selected.svg");
      document.getElementById('imageDown').setAttribute('src', "assets/img/content-icons/reactions/thumbs-down-selected.svg");
      this.feedbackToggle(true);

    } else {
      document.getElementById('imageUp').setAttribute('src', "assets/img/content-icons/reactions/thumbs-up-not-selected.svg");
      document.getElementById('imageDown').setAttribute('src', "assets/img/content-icons/reactions/thumbs-down-not-selected.svg");
      this.feedbackToggle(false);

    }
  }

  clearFeedback() {
    this.feedbackToggle(false);
    
    (<HTMLInputElement>document.getElementById('innerTextbox')).value = '';
    var listOfOptions = document.getElementsByName('availableOption');
    for (var i = 0; i < listOfOptions.length; i++) {
      (<HTMLInputElement>listOfOptions[i]).checked = false;
    }
  }

  hover(thumb) {
    if(thumb == "upHover") {
      document.getElementById('imageUp').setAttribute('src', "assets/img/content-icons/reactions/thumbs-up-selected.svg");
    } else {
      document.getElementById('imageDown').setAttribute('src', "assets/img/content-icons/reactions/thumbs-down-selected.svg");
    }
  }

  unhover(thumb) {
    if(thumb == "upOut" && this.reaction != "positive") {
      document.getElementById('imageUp').setAttribute('src', "assets/img/content-icons/reactions/thumbs-up-not-selected.svg");
    } else if (thumb == "downOut" && this.reaction != "negative") {
      document.getElementById('imageDown').setAttribute('src', "assets/img/content-icons/reactions/thumbs-down-not-selected.svg");
    }
  }
}
