import { Component, OnInit } from '@angular/core';
import { MapChart } from 'angular-highcharts';
import ohMap from 'src/assets/ohMap';
import * as  Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { GraphingService } from 'src/app/services/graphing.service';

@Component({
  selector: 'app-farm-sci-map',
  templateUrl: './farm-sci-map.component.html',
  styleUrls: ['./farm-sci-map.component.scss']
})
export class FarmSciMapComponent implements OnInit {
  mapChart: MapChart;
  chart = {};
  chartData = [
    {
      "fips": 39017,
      "link": "https://www.youtube.com/watch?v=4_T7D1DOuWo&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=15",
      "value": 1
    },
    {
      "fips": 39171,
      "link": "https://www.youtube.com/watch?v=72sHEug9z-M&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=41",
      "value": 1
    },
    {
      "fips": 39049,
      "link": "https://www.youtube.com/watch?v=7dl92fFbywI&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=46",
      "value": 1
    },
    {
      "fips": 39147,
      "link": "https://www.youtube.com/watch?v=7Ts7yEgYfcs&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=21",
      "value": 1
    },
    {
      "fips": 39003,
      "link": "https://www.youtube.com/watch?v=aBTUyoyBK8w&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=43",
      "value": 1
    },
    {
      "fips": 39075,
      "link": "https://www.youtube.com/watch?v=aRrzkESD_Is&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=49",
      "value": 1
    },
    {
      "fips": 39081,
      "link": "https://www.youtube.com/watch?v=cLfd_YhWSL8&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=49&t=0s",
      "value": 1
    },
    {
      "fips": 39129,
      "link": "https://www.youtube.com/watch?v=CveVrzcMl6c&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=22",
      "value": 1
    },
    {
      "fips": 39033,
      "link": "https://www.youtube.com/watch?v=DvPGxgyVUic&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=47&t=0s",
      "value": 1
    },
    {
      "fips": 39065,
      "link": "https://www.youtube.com/watch?v=dYi6dcU2GdQ&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=17",
      "value": 1
    },
    {
      "fips": 39165,
      "link": "https://www.youtube.com/watch?v=exmnDbwHQO4&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=4",
      "value": 1
    },
    {
      "fips": 39109,
      "link": "https://www.youtube.com/watch?v=-FMpZCgf7-k&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=11",
      "value": 1
    },
    {
      "fips": 39061,
      "link": "https://www.youtube.com/watch?v=fnO6JU4MmCw&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=39",
      "value": 1
    },
    {
      "fips": 39041,
      "link": "https://www.youtube.com/watch?v=fxiKZVzHxSg&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=18",
      "value": 1
    },
    {
      "fips": 39001,
      "link": "https://www.youtube.com/watch?v=gBfT2f8WLDU&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=14",
      "value": 1
    },
    {
      "fips": 39011,
      "link": "https://www.youtube.com/watch?v=GSlf2knlHn8&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=16",
      "value": 1
    },
    {
      "fips": 39169,
      "link": "https://www.youtube.com/watch?v=Gu8jWh7lNgs&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=48",
      "value": 1
    },
    {
      "fips": 39099,
      "link": "https://www.youtube.com/watch?v=HLVGNwRTZZE&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=9",
      "value": 1
    },
    {
      "fips": 39015,
      "link": "https://www.youtube.com/watch?v=IwawQA_zAYE&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=28",
      "value": 1
    },
    {
      "fips": 39149,
      "link": "https://www.youtube.com/watch?v=jtHPeRqtR7s&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=7",
      "value": 1
    },
    {
      "fips": 39113,
      "link": "https://www.youtube.com/watch?v=-KgpezPIuik&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=48",
      "value": 1
    },
    {
      "fips": 39161,
      "link": "https://www.youtube.com/watch?v=kV8xGvGLkqI&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=45",
      "value": 1
    },
    {
      "fips": 39027,
      "link": "https://www.youtube.com/watch?v=LymIBHWd_dg&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=27",
      "value": 1
    },
    {
      "fips": 39039,
      "link": "https://www.youtube.com/watch?v=mbh3CMe7HkU&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=26",
      "value": 1
    },
    {
      "fips": 39057,
      "link": "https://www.youtube.com/watch?v=Pm6HryycG00&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=33",
      "value": 1
    },
    {
      "fips": 39107,
      "link": "https://www.youtube.com/watch?v=pY0laAvQ7sk&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=47",
      "value": 1
    },
    {
      "fips": 39117,
      "link": "https://www.youtube.com/watch?v=q2s9enhJX1s&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=19",
      "value": 1
    },
    {
      "fips": 39045,
      "link": "https://www.youtube.com/watch?v=qbBvGrfs3b4&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=32",
      "value": 1
    },
    {
      "fips": 39137,
      "link": "https://www.youtube.com/watch?v=qdkX_6tlxok&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=12",
      "value": 1
    },
    {
      "fips": 39141,
      "link": "https://www.youtube.com/watch?v=qiPyAmt-MLU&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=8",
      "value": 1
    },
    {
      "fips": 39021,
      "link": "https://www.youtube.com/watch?v=QS2LyyhqrBY&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=31&t=0s",
      "value": 1
    },
    {
      "fips": 39097,
      "link": "https://www.youtube.com/watch?v=QUm8gbjKfkM&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=20",
      "value": 1
    },
    {
      "fips": 39029,
      "link": "https://www.youtube.com/watch?v=QytKtNjgBcU&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=29",
      "value": 1
    },
    {
      "fips": 39059,
      "link": "https://www.youtube.com/watch?v=R0N1HBPcVhA&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=25",
      "value": 1
    },
    {
      "fips": 39031,
      "link": "https://www.youtube.com/watch?v=r1AJYuLXnhs&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=30",
      "value": 1
    },
    {
      "fips": 39139,
      "link": "https://www.youtube.com/watch?v=RUqOLMVVGQ8&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=2&t=0s",
      "value": 1
    },
    {
      "fips": 39173,
      "link": "https://www.youtube.com/watch?v=RUqOLMVVGQ8&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=2&t=0s",
      "value": 1
    },
    {
      "fips": 39063,
      "link": "https://www.youtube.com/watch?v=sD06BYuM1Ag&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=52",
      "value": 1
    },
    {
      "fips": 39101,
      "link": "https://www.youtube.com/watch?v=STPvVBT2eNE&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=35",
      "value": 1
    },
    {
      "fips": 39125,
      "link": "https://www.youtube.com/watch?v=sY5PIwNlWIc&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=41",
      "value": 1
    },
    {
      "fips": 39119,
      "link": "https://www.youtube.com/watch?v=UGcTW8cYP5w&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=42",
      "value": 1
    },
    {
      "fips": 39159,
      "link": "https://www.youtube.com/watch?v=uvyL2k560Qg&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=45",
      "value": 1
    },
    {
      "fips": 39051,
      "link": "https://www.youtube.com/watch?v=vCF0FRVNmxc&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=34",
      "value": 1
    },
    {
      "fips": 39157,
      "link": "https://www.youtube.com/watch?v=VguDXxQOVWk&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=5",
      "value": 1
    },
    {
      "fips": 39095,
      "link": "https://www.youtube.com/watch?v=VsZAeX_TxY8&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=23",
      "value": 1
    },
    {
      "fips": 39083,
      "link": "https://www.youtube.com/watch?v=WioCjaDOH4Q&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=53",
      "value": 1
    },
    {
      "fips": 39175,
      "link": "https://www.youtube.com/watch?v=-W-mkTydD-Y&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=36",
      "value": 1
    },
    {
      "fips": 39135,
      "link": "https://www.youtube.com/watch?v=wnn90wB5Dik&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=49",
      "value": 1
    },
    {
      "fips": 39155,
      "link": "https://www.youtube.com/watch?v=wn--YJOs_1A&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=6",
      "value": 1
    },
    {
      "fips": 39071,
      "link": "https://www.youtube.com/watch?v=xdUQszcO7Q8&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=51",
      "value": 1
    },
    {
      "fips": 39131,
      "link": "https://www.youtube.com/watch?v=yE5hLKRgvXY&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=47",
      "value": 1
    },
    {
      "fips": 39089,
      "link": "https://www.youtube.com/watch?v=yTwCpKn8yLQ&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=38",
      "value": 1
    },
    {
      "fips": 39067,
      "link": "https://www.youtube.com/watch?v=zVQ8KDHpOu0&list=PLYlh_BdeqniLAGBHnxVguPhOhGDs70btb&index=24",
      "value": 1
    },
    {
      "fips": 39037,
      "link": "https://youtu.be/coLlvSFqdlk",
      "value": 1
    },
    {
      "fips": 39005,
      "link": "",
      "value": 0
    },
    {
      "fips": 39007,
      "link": "",
      "value": 0
    },
    {
      "fips": 39009,
      "link": "",
      "value": 0
    },
    {
      "fips": 39013,
      "link": "",
      "value": 0
    },
    {
      "fips": 39019,
      "link": "",
      "value": 0
    },
    {
      "fips": 39023,
      "link": "",
      "value": 0
    },
    {
      "fips": 39025,
      "link": "",
      "value": 0
    },
    {
      "fips": 39035,
      "link": "",
      "value": 0
    },
    {
      "fips": 39043,
      "link": "",
      "value": 0
    },
    {
      "fips": 39047,
      "link": "",
      "value": 0
    },
    {
      "fips": 39053,
      "link": "",
      "value": 0
    },
    {
      "fips": 39055,
      "link": "",
      "value": 0
    },
    {
      "fips": 39069,
      "link": "",
      "value": 0
    },
    {
      "fips": 39073,
      "link": "",
      "value": 0
    },
    {
      "fips": 39077,
      "link": "",
      "value": 0
    },
    {
      "fips": 39079,
      "link": "",
      "value": 0
    },
    {
      "fips": 39085,
      "link": "",
      "value": 0
    },
    {
      "fips": 39087,
      "link": "",
      "value": 0
    },
    {
      "fips": 39091,
      "link": "",
      "value": 0
    },
    {
      "fips": 39093,
      "link": "",
      "value": 0
    },
    {
      "fips": 39103,
      "link": "",
      "value": 0
    },
    {
      "fips": 39105,
      "link": "",
      "value": 0
    },
    {
      "fips": 39111,
      "link": "",
      "value": 0
    },
    {
      "fips": 39115,
      "link": "",
      "value": 0
    },
    {
      "fips": 39121,
      "link": "",
      "value": 0
    },
    {
      "fips": 39123,
      "link": "",
      "value": 0
    },
    {
      "fips": 39127,
      "link": "",
      "value": 0
    },
    {
      "fips": 39133,
      "link": "",
      "value": 0
    },
    {
      "fips": 39143,
      "link": "",
      "value": 0
    },
    {
      "fips": 39145,
      "link": "",
      "value": 0
    },
    {
      "fips": 39151,
      "link": "",
      "value": 0
    },
    {
      "fips": 39153,
      "link": "",
      "value": 0
    },
    {
      "fips": 39163,
      "link": "",
      "value": 0
    },
    {
      "fips": 39167,
      "link": "",
      "value": 0
    }
   ]

  constructor() { }

  buildMap() {

    
    this.mapChart = new MapChart({
      chart: {
        backgroundColor: '#00000000',
        // width: 550,
        // height: 550,
        // borderWidth: 0,
        // spacingBottom: 25,
        // spacingTop: 0,
        // spacingLeft: 10,
        // spacingRight: 0,
        //@ts-ignore
        map: ohMap
        },
        credits: {
          enabled: false
      },
  
        title: {
          // @ts-ignore
            text: ''
        },
  
        // subtitle: {
        //     text: 'View the Impact of the Agricultural and Food Production Cluster'
        // },
  
        legend: {
          enabled: false
      },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          point: {
            events: {
              click: function () {
                //@ts-ignore
                window.open(this.link);
              }
            }
          }
        }
      },
      colorAxis: {
        min: 0,
        type: 'linear',
        minColor: '#00000000',
        maxColor: '#694f87',
        // stops: [
        //     [0, '#EFEFFF'],
        //     [0.67, '#4444FF'],
        //     [1, '#000022']
        // ]
      },
        series: [{
          animation: {
            duration: 1000
          },
          //@ts-ignore
            type: 'map',
            //@ts-ignore
            name: 'Click to go to yield video for',
            data: this.chartData,
            //@ts-ignore
            mapData: ohMap,
            //@ts-ignore
            joinBy: ['fips', 'fips'],
            //@ts-ignore
            minSize: 0,
            maxSize: '12%',
            cursor: 'pointer',
            states: {
                hover: {
                    color: '#BA0C2F'
                }
            },
            tooltip: {
              pointFormat: '{point.name}'+' County'
          }
            
        }],
        navigation: {
          buttonOptions: {
              enabled: false
          }
      }

    });
    
    // this.chart = this.mapChart;
  }

  ngOnInit() {
    this.buildMap();

    // document.querySelectorAll(".highcharts-series-group").forEach(element => {
    //   element.setAttribute("style","transform: rotate(70deg)");
    // });
  }

}
