<div *ngIf="columnEntries" class="variable-column-container">
  <div *ngFor="let entry of columnEntries" 
		class="variable-column-entry"
		[ngStyle]="{'width': entry.percentWidth + '%'}"

		>
		<app-custom-page-section [sectionData]="entry.section"></app-custom-page-section>

	</div>
</div>
