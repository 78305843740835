<!-- <app-embedded-power-bi-report></app-embedded-power-bi-report> -->

<app-landing-page-header focus></app-landing-page-header>
<div class="landing-page-section light">
  <content-list [content]="iconSlides" type="icon-slider"></content-list>
</div>
<!-- head="Data and Tools" -->
<!-- subhead="Click here to view more <i class='fas fa-arrow-right'></i>"
  link="/page/data-and-tools" -->

<div class="landing-page-section">
  <content-list
    [content]="recentContent"
    [sortByDate]="isProduction"
    type="image-slider"
    head="Recent"
  ></content-list>
</div>

<div class="landing-page-section">
  <content-list
    [content]="featuredContent"
    type="callout-group"
    head="KX Originals"
  ></content-list>
</div>

<div class="landing-page-section light explore">
  <h2 class="centered">Browse by type</h2>

  <!-- <a href="/search?types=Dataset" aria-label="Datasets" matTooltip="Datasets">
    <kx-icon id='explore_dataset' size="lg"></kx-icon>
  </a> -->

  <a href="/search?types=File" aria-label="Files" matTooltip="Files">
    <kx-icon id="explore_files" size="lg"></kx-icon>
  </a>

  <a
    href="/search?types=Image%20Collection"
    aria-label="Galleries"
    matTooltip="Galleries"
  >
    <kx-icon id="explore_photo_gallery" size="lg"></kx-icon>
  </a>

  <a href="/search?types=Link" aria-label="Links" matTooltip="Links">
    <kx-icon id="explore_links" size="lg"></kx-icon>
  </a>

  <a href="/podcast" aria-label="Podcasts" matTooltip="Podcasts">
    <kx-icon id="explore_podcasts" size="lg"></kx-icon>
  </a>

  <a
    href="/search?types=Question"
    aria-label="Questions"
    matTooltip="Questions"
  >
    <kx-icon id="explore_questions" size="lg"></kx-icon>
  </a>

  <a href="/search?types=Story" aria-label="Stories" matTooltip="Stories">
    <kx-icon id="explore_stories" size="lg"></kx-icon>
  </a>

  <a href="/search?types=Video" aria-label="Videos" matTooltip="Videos">
    <kx-icon id="explore_videos" size="lg"></kx-icon>
  </a>

  <a
    href="/search?types=Virtual%20Tour"
    aria-label="Virtual Tours"
    matTooltip="Virtual Tours"
  >
    <kx-icon id="explore_virtual_tours" size="lg"></kx-icon>
  </a>
</div>
