import { Component, Input, OnInit } from '@angular/core';
import {columnEntry} from '../../custom-stuff/etc/types';

@Component({
  selector: 'app-variable-column-container',
  templateUrl: './variable-column-container.component.html',
  styleUrls: ['./variable-column-container.component.scss']
})
export class VariableColumnContainerComponent implements OnInit {
	@Input() columnEntries: columnEntry[];

  constructor() {}
  ngOnInit(): void {}
}
