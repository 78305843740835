import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { contentListItem } from "../util";
import SwiperCore, { A11y, Swiper } from "swiper/core";
import { Observable } from "rxjs";

SwiperCore.use([A11y]);
@Component({
  selector: "app-callout-group",
  templateUrl: "./callout-group.component.html",
  styleUrls: ["./callout-group.component.scss"],
})
export class CalloutGroupComponent implements OnInit {
  @Input() items: Observable<contentListItem[]>;
  swiper: Swiper;
  groupNumbers: boolean[];
  length = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
    this.swiper.onAny(() => this.cdr.detectChanges());
  }
  navigateTo(i: number) {
    this.swiper.slideTo(i);
  }
  ngOnInit(): void {
    this.items.subscribe((items: contentListItem[]) => {
      this.length = items.length;
      let len = items.length;
      this.groupNumbers = items
        .map(() => true)
        .slice(0, (len % 2 == 0 ? len : len + 1) / 2);
    });
  }

  getColor(i: number) {
    switch (i % 3) {
      case 0:
        return "primary";
      case 1:
        return "secondary";
      case 2:
        return "tertiary";
    }
  }
  getSide(i: number) {
    let len = this.length;
    return i >= (len % 2 == 0 ? len : len + 1) / 2 ? "right" : "left";
  }
}
