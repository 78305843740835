import { Component, HostListener } from "@angular/core";

@Component({
  selector: "app-scroll-to-top",
  templateUrl: "./scroll-to-top.component.html",
  styleUrls: ["./scroll-to-top.component.scss"],
})
export class ScrollToTopComponent{
  windowScrolled: boolean;
  constructor() {}

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) > 400) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    })();
  }
}