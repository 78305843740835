<div class="contributors" *ngIf="people && people.length > 0">
  <h3>Contributors</h3>
  <ng-container *ngFor="let person of people">
    <div
      class="contributor"
      routerLink="/directory/{{ person.id }}"
      *ngIf="person.fullname && person.fullname.length > 0"
    >
      <h4>{{ person.fullname }}</h4>
      <h5>{{ person.jobTitle }}</h5>
    </div>
  </ng-container>
</div>
