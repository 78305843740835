import { Injectable } from "@angular/core";
import { MetaTagsService } from "./meta-tags.service";

export enum PageTitles {
  Default = "Knowledge Exchange",
  About = "About",
  Topics = "Topics",
  Directory = "Our People",
  KnowledgeExplorer = "Knowledge Explorer",
  CommunityProfiles = "Community Profile Tool",
  CountyClusters = "Food & Agricultural Production Clusters",
  Covid19 = "Covid-19 Hub",
  Covid19About = "About Covid-19 Hub",
  Covid19NewsAndData = "How to Analyze News and Data",
  Sesquicentennial = "Ohio State 150 years",
  Video = "Video",
  Question = "Question",
  Story = "Story",
  Podcast = "Podcast",
}

@Injectable({ providedIn: "root" })
export class TitleService {
  constructor(private metaTags: MetaTagsService) {}
  
  PageTitles = PageTitles;
  setDefaultTitle = () => this.metaTags.setDefaultTitle();
  setPageTitle = (title) => this.metaTags.updateTitle(title);
}
