

















<div class="dataset" *ngIf="dataSet; else noDataSet">

	<h1 class="field">{{dataSet.Title}}</h1>

	<div class="field">
		<h2>Summary:</h2>
		<p [innerHTML]="dataSet.Summary"></p>
	</div>

	<div class="field">
		<h2>Geographic:</h2>
		<h3>Level: {{dataSet.Geographic_Level}}</h3>
		<h3>Extent: {{dataSet.Geographic_Extent}}</h3>
		</div>	

	<div class="field">
	<h2>Time Period:</h2>
	<h3>Start: {{dataSet.Time_Period_Start}}</h3>
	<h3>End: {{dataSet.Time_Period_End}}</h3>
	</div>


	<div class="field">
	<h2>Source: 
		<a [href]="dataSet.Originator_Source" target="_blank">{{dataSet.Originator_Credit}}</a>
	</h2>
	</div>
	
	<div *ngIf="dataSet.Constraints" class="field">
	<h2>Source Use Constraints:</h2>
	<p [innerHTML]="dataSet.Constraints"></p>
	</div>

	<!--
	<h2>OSU Use Constraints:</h2>
	<p [innerHTML]="dataSet.OSUConstraints"></p>
	-->

	<div class="field">
	<h2 *ngIf="dataSet.Hosted_Source">
      <a
        *ngIf="validURL(dataSet.Hosted_Source); else noHostedSource"
        [href]="dataSet.Hosted_Source_Type"
        target="_blank",
      >
        Download
      </a>
      <ng-template #noHostedSource>No Data Source</ng-template>
	</h2>
	</div>

</div>
<ng-template #noDataSet> Data Set does not Exist </ng-template>

