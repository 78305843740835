import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {IVariable} from 'src/app/community-profile-tool/types';




@Component({
  selector: 'app-send-to-explorer-button',
  templateUrl: './send-to-explorer-button.component.html',
  styleUrls: ['./send-to-explorer-button.component.scss']
})
export class SendToExplorerButtonComponent implements OnInit {

	@Input() icon: string;		
	@Output() sendToCart = new EventEmitter<any>();		

  constructor() { }
  ngOnInit(): void {
  }


}
