import { Component, Input, OnInit } from "@angular/core";
import {
  AcpfAuthenticationService,
  AcpfUser,
} from "src/app/services/acpf-auth.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-acpf-fm-list-files-uploaded",
  templateUrl: "./acpf-fm-list-files-uploaded.component.html",
  styles: [".acpf-light-text { font-weight:300; }"],
})
export class AcpfFmListFilesUploadedComponent implements OnInit {
  user: AcpfUser;

  constructor(public api: ApiService, private auth: AcpfAuthenticationService) {
    this.auth
      .getCurrentUser()
      .subscribe((user) => (this.user = <AcpfUser>user));
  }

  ngOnInit(): void {}

  hasCore() {
    return this.user!.files.some((el) => el.file_type == "core");
  }
  hasResults() {
    return this.user!.files.some((el) => el.file_type == "result");
  }
}
