<div id="chart" #chart>
  <ng-container *ngIf="displayChart; else image">
    <canvas
      #chart="base-chart"
      baseChart
      [datasets]="data"
      [options]="options"
      [labels]="labels"
      [chartType]="type"
    ></canvas>
  </ng-container>
  <ng-template #image>
    <img [src]="displayImage" />
    <div #resizeContainer class="resize-container">
      <canvas
        #chart="base-chart"
        baseChart
        [datasets]="data"
        [options]="options"
        [labels]="labels"
        [chartType]="type"
      ></canvas>
    </div>
  </ng-template>
</div>
