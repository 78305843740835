import { HttpClient } from "@angular/common/http";
import { v4 as uuidv4 } from "uuid";
import { ElementRef, Injectable } from "@angular/core";
import { forkJoin, Observable, Subject, fromEvent, Subscription, BehaviorSubject } from "rxjs";
import {
  allScopes,
  FsmWidgetInput,
  WidgetInput,
  WidgetSelection,
  SelectionMap,
  LegendWidgetInput,
  ExplorerState,
  Page,
  multiPageWidgetInput,
  Layer,
  LayerWithName,
  CptWidgetInput,
  SelectionType,
  WidgetLog,
  eFieldsWidgetSelection,
  eFieldsWidget,
  Variable,
  EfieldsWidgetInput,
  GeneratedEfieldsWidgetInput,
} from "../types/types";
import {
  GridsterConfig,
  DisplayGrid,
  GridType,
  GridsterItemComponentInterface,
  GridsterComponentInterface,
  GridsterItem,
} from "angular-gridster2";
import { ApiService } from "src/app/services/api.service";
import { DataSet } from "src/app/shared/models/dataSet";
import {
  DEFAULT_SIZES,
  DEFAULT_TITLE,
  DEFAULT_SUB_TITLE,
} from "../types/constants";
import { BaseWidgetComponent } from "../components/widgets/base-widget.component";
import { MapWidgetComponent } from "../components/widgets/map-widget.component";
import { LegendWidgetComponent } from "../components/widgets/legend-widget.component";
import html2canvas from "html2canvas";
import { MatSnackBar } from "@angular/material/snack-bar";
import { map, mergeMap } from "rxjs/operators";
import { ChartWidgetComponent } from "../components/widgets/chart-widget.component";
import { VariableGroup } from "src/app/community-profile-tool/types";
import { MultiVarBarWidgetComponent } from "../components/widgets/multi-var-bar-widget.component";
import {environment} from 'src/environments/environment';
import { EFieldsTrial } from 'src/app/shared/models/eFieldTrial';

const EXPRESS_SERVER = "https://d161r0goltoavd.cloudfront.net";
const CHART_WIDGET_COMPONENT_TYPES: SelectionType[] = [
  "bar",
  "line",
  "pie",
  "scatter",
];

const MAX_GRID_HEIGHT = 95;
const MIN_GRID_HEIGHT = 55;

export const COLS = 55;
export const ROWS = 30;

interface ShowIndicatorEvent {
  show: boolean;
  widgetDemensions: {
    w: number;
    h: number;
  };
  startPositon: {
    x: number;
    y: number;
  };
}

interface ShowPageIndicatorEvent {
  left: boolean;
  right: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ExplorerService {
  pages: Page[] = [];
  gridInitalized: Subject<boolean>;
  resize: Subject<any>;
  titleChange: Subject<any>;
  showIndicator: Subject<ShowIndicatorEvent>;
  showPageIndicator: Subject<ShowPageIndicatorEvent>;
  rightRegion: Observable<Event>;
  leftRegion: Observable<Event>;
  rightRegionOut: Observable<Event>;
  leftRegionOut: Observable<Event>;
  logAction: Subject<WidgetLog>;
  selectionsFetched: BehaviorSubject<any>;

  drupalDatasets: DataSet[];
  state: ExplorerState;

  widgetSelections: WidgetSelection[] = [];
  layerWidgetSelection: WidgetSelection[];

  widgetReferences: BaseWidgetComponent[] = [];

  headerElement: ElementRef;
  titleElement: ElementRef;

  constructor(
    private _http: HttpClient,
    private _api: ApiService,
    private _snackbar: MatSnackBar
  ) {
    this.pages = [];
    this.state = this.getDefaultState();
    this.addPage(false);
    this.getDrupalSourceText();
    this.setUpSubjects();
  }

  setUpSubjects(): void {
    this.gridInitalized = new Subject();
    this.resize = new Subject();
    this.showIndicator = new Subject<ShowIndicatorEvent>();
    this.showPageIndicator = new Subject<ShowPageIndicatorEvent>();
    this.logAction = new Subject<WidgetLog>();
    this.selectionsFetched = new BehaviorSubject<boolean>(false);

    this.showIndicator.subscribe((event) => {
      if (event.show) {
        this.showDropIndicator(event.widgetDemensions, event.startPositon);
      } else {
        this.hideDropIndicator();
      }
    });
    this.showPageIndicator.subscribe((event) => {
      if (event.right) this.showPageIndicatorButton("right");
      else this.hidePageIndicatorButton("right");
      if (event.left) this.showPageIndicatorButton("left");
      else this.hidePageIndicatorButton("left");
    });

		this.logAction.subscribe((widgetLog: WidgetLog) => {
			this._api.logExplorerAction(widgetLog).subscribe((res: any) => {
				console.log("Action Logged");
			});

		});
  }

	exportLog(action: 'IMAGE_EXPORT' | 'PDF_EXPORT'): void {
		this.pages.forEach(p => p.widgets.forEach(w => this.logWidget(w, action)));
	}

	logWidget(widgetInput: WidgetInput, action: 'ADD' | 'IMAGE_EXPORT' | 'PDF_EXPORT'): void {
		if (widgetInput.selection.type == 'legend') return;
		const scopes = (<CptWidgetInput>widgetInput).scopes;
		const env = environment.envFilter == 'dev' ? 'DEV' : 'PROD'
		const log: WidgetLog = {
			title: widgetInput.selection.title,
			type: widgetInput.selection.type,
			source: widgetInput.selection.source,
			scopes: scopes ? scopes : [],
			time: new Date().toLocaleString(),
			environment: env,
			action: action
		}
		this.logAction.next(log);
	}

  get titlePageExists(): boolean {
    let i = this.pages.findIndex((p) => p.titlePage);
    return i != -1;
  }

  get activeWidgetList(): { id: string; title: string; children: string[] }[] {
    let widgetNames = [];
    let legendWidgets = [];
    for (let p of this.pages) {
      for (let w of p.widgets) {
        if (w.selection.type == "legend") {
          let parent = (<LegendWidgetInput>w).parentMapId;
          legendWidgets.push({ parentId: parent, title: w.selection.title });
        } else {
          widgetNames.push({
            id: w.id,
            title: w.selection.title,
            children: [],
          });
        }
      }
    }
    for (let w of legendWidgets) {
      let parent = widgetNames.find((p) => p.id == w.parentId);
      if (parent) {
        parent.children.push(w.title);
      }
    }
    return widgetNames;
  }

  // PAGE CONTROL

  createNewPage(titlePage: boolean): Page {
    let page = {
      widgets: [],
      config: this.getDefaultGridConfig(),
      titlePage: titlePage,
      uniqueId: titlePage ? "title-page" : `page-${uuidv4()}`,
      pageHasTitle: true,
      pageTitle: "Enter Title",
    };
    return page;
  }

  addPage(titlePage: boolean, index?: number): number {
    let newPage = this.createNewPage(titlePage);
    this.state.pageCount++;
    if (titlePage) {
      this.pages.unshift(newPage);
      return 0;
    } else {
      if (index && this.pages[index + 1]) {
        this.pages.splice(index + 1, 0, newPage);
        return index + 1;
      } else {
        this.pages.push(newPage);
        return this.state.pageCount - 1;
      }
    }
  }

  deletePage(index: number): void {
    let page = this.pages[index];
    if (!page) return;
    if (!page.titlePage) this.deleteWidgets(page.widgets);
    this.pages.splice(index, 1);
    this.state.pageCount--;

    if (!this.state.pageCount) this.addPage(false);
    else if (index == this.state.pageCount) this.state.activeIndex--;
  }

  movePage(right: boolean, index: number) {
    if (right && index == this.pages.length - 1) return;
    if (!right && index == 0) return;

    let pageToMove = this.pages[index];
    let newIndex = right ? index + 1 : index - 1;
    this.pages[index] = this.pages[newIndex];
    this.pages[newIndex] = pageToMove;
    this.state.activeIndex = newIndex;
  }

  changePage(right: boolean): void {
    let newIndex = this.state.activeIndex + (right ? 1 : -1);
    if (!this.pages[newIndex]) return;
    this.state.activeIndex = newIndex;
  }

  resizePage(increase: boolean): void {
    let currSize = this.state.pageSize;
    if (increase) {
      this.state.pageSize = Math.min(currSize + 10, MAX_GRID_HEIGHT);
    } else {
      this.state.pageSize = Math.max(currSize - 10, MIN_GRID_HEIGHT);
    }
    this.resize.next(true);
  }

  changePageOrientation(orientation: "portrait" | "landscape"): void {
    if (
      (orientation == "portrait" && this.state.portrait) ||
      (orientation == "landscape" && !this.state.portrait)
    )
      return;
    this.rotateGrids(orientation);
    this.state.portrait = !this.state.portrait;
    this.resize.next(true);
  }

  private rotateGrids(toOrientation: "portrait" | "landscape") {
    let r = ROWS;
    let c = COLS;
    if (toOrientation == "portrait") {
      r = c;
      c = ROWS;
    }

    for (let page of this.pages) {
      let conf = page.config;
      conf.r = conf.minRows = conf.maxRows = r;
      conf.c = conf.minCols = conf.maxCols = c;
      for (let w of page.widgets) {
        let x = w.x;
        let cols = w.cols;
        w.x = w.y;
        w.cols = w.rows;
        w.y = x;
        w.rows = cols;
      }
    }
    this.optionsChanged();
  }

  // Widget Controls

  findWidgetComponentById(id: string): BaseWidgetComponent {
    let baseWidget = this.widgetReferences.find((w) => w.widgetInput.id == id);
    return baseWidget;
  }

  findLegendComponentForMap(
    widgetInput: FsmWidgetInput
  ): LegendWidgetComponent {
    let legendId = `legend-${widgetInput.id}`;
    let baseWidget = this.findWidgetComponentById(legendId);
    return baseWidget.childWidgetComponent as LegendWidgetComponent;
  }

  findMapComponentForLegend(
    widgetInput: LegendWidgetInput
  ): MapWidgetComponent {
    let legendId = widgetInput.parentMapId;
    let baseWidget = this.findWidgetComponentById(legendId);
    return baseWidget.childWidgetComponent as MapWidgetComponent;
  }

  moveWidget(
    widgetId: string,
    from: number,
    to: number,
    continueDrag?: boolean
  ): void {
    const targetPage = this.pages[to];
    const widget = this.pages[from].widgets.find((w) => w.id === widgetId);
    const canFit = targetPage.config.api.getNextPossiblePosition(widget);

    if (!widget || !canFit) {
      this.createSnackBar(
        "Not enough space available for widget. Widget move canceled."
      );
      return;
    }

    this.removeWidgetFromPage(from, widgetId);
    this.addWidget(widget, to, continueDrag);
  }

  getSourceText(widgetInput: WidgetInput): string {
    switch (widgetInput.selection.source) {
      case "cpt_census":
        return "United States Census Bureau";
      case "cpt_nass":
        return "United States Department of Agriculture National Agricultural Statistics Service";
      case "fsm":
        let dataNodeId = (<FsmWidgetInput>widgetInput).maps[0].layer.datanodeId;
        let ds = this.drupalDatasets.find((dataset) => {
          return dataset.nid_1 == dataNodeId;
        });
        if (!ds) return "No data source";
        let source = decodeURIComponent(ds.Originator_Credit);
        return source.replace(/&amp;/g, "&");
      case "efields":
        return "College of Food, Agricultural, and Environmental Sciences - Digital Ag Program - eFields On-Farm Research";
      default:
        return "No data source";
    }
  }

  getExcludedScopes(variables: any[]): string[] {
    let excluded = [];

    for (let variable of variables) {
      for (let [key, value] of Object.entries(variable.data)) {
        if (value == undefined || value == "-1") {
          excluded.push(key);
        }
        // Some Variables don't have a total -- why ??
        if (!variable.data.Total) {
          excluded.push("Total");
        }
      }
    }
    return excluded;
  }

  getScopes(variables: any[]) {
    let scopesCopy = allScopes.map((x) => x);

    for (let variable of variables) {
			scopesCopy.forEach((county) => {
				if (!variable.data[county]) variable.data[county] = "-1";
			});
      for (let [key, value] of Object.entries(variable.data)) {
        if (value == undefined || value == "-1") {
          scopesCopy = scopesCopy.filter((scope) => scope != key);
        }
        // Some Variables don't have a total -- why ??
        if (!variable.data.Total) {
          scopesCopy = scopesCopy.filter((scope) => scope != "Total");
        }
      }
    }
    return scopesCopy;
  }

  getWidget(widgetSelection: WidgetSelection): Observable<any> {
    return this._http.get(
      `${EXPRESS_SERVER}/explorer-widget-by-id/${widgetSelection.id}`
    );
  }

  // Widget Fetching
  getCPTWidget(s: WidgetSelection, input: WidgetInput): Observable<CptWidgetInput> {
    console.log(input);
    const obs: Observable<any> = this._http.get(
      `${EXPRESS_SERVER}/explorer-widget-by-id/${s.id}`
    );
    
    return obs.pipe(mergeMap((cptWidget: any): Observable<CptWidgetInput> => {
      if (cptWidget.slices) cptWidget.bars = cptWidget.slices; // they are the same i guess? idk
      const variableObs = this.getCptVariableObservables(s.source, s.type, cptWidget);
      const cptWidgetObs = forkJoin(variableObs).pipe(map((v) => {
        console.log(input);
        let cptInput: CptWidgetInput = {
          ...input,
          cptWidget: cptWidget,
          scopes:['s'],
          variables: v as Variable[]
        }
        return cptInput;
      }));

      return cptWidgetObs;
    }));

  }

  getFSMWidget(s: WidgetSelection, input: WidgetInput): Observable<FsmWidgetInput> {
    const obs = this._http.get<Layer>(
      `${EXPRESS_SERVER}/explorer-widget-by-id/${s.id}`
    );
    return obs.pipe(map((layer: Layer) => {
      let fsmInput: FsmWidgetInput = {
        ...input,
        maps: [{name: s.title, layer: layer}]
      }
      return fsmInput;
    }));
  }

  getLayer(s: WidgetSelection): Observable<Layer> {
    return this._http.get<Layer>(
      `${EXPRESS_SERVER}/explorer-widget-by-id/${s.id}`
    );
  }

  getKxMapWidget(s: WidgetSelection, input: WidgetInput): Observable<FsmWidgetInput> {
    const obs = this._http.get<Layer>(
      `${EXPRESS_SERVER}/maps/${s.id}`
    );
    return obs.pipe(map((layer: Layer) => {
      let fsmInput: FsmWidgetInput = {
        ...input,
        maps: [{name: s.title, layer: layer}]
      }
      return fsmInput;
    }));
  }

  getEfieldsWidget(s: eFieldsWidgetSelection, input: WidgetInput): Observable<GeneratedEfieldsWidgetInput> {
    s.type = "efields"
  const obs = this._http.get<EFieldsTrial[]>(
    `${EXPRESS_SERVER}/trials-by-id?ids=${s.ids.join(',')}`
  );
    return obs.pipe(map((trials: EFieldsTrial[]) => {
      let eFieldsInput: GeneratedEfieldsWidgetInput = {
        ...input,
        trials: trials
      }
      return eFieldsInput;
    }));

  }

  private getCptVariableObservables(
    source: any, 
    type: any,
    widget: any): Observable<any>[] {
      let variableObservables = [];
      if (source == "cpt_census") {
        if (type == "value") {
          variableObservables.push(
            this._http.get(`${EXPRESS_SERVER}/variables/${widget.valueVarID}`)
          );
        } else if (type == "bar") {
          for (let bar of widget.bars) {
            variableObservables.push(
              widget.slices
                ? this._http.get(`${EXPRESS_SERVER}/variables/${bar.valueVarID}`)
                : this._http.get(`${EXPRESS_SERVER}/variables/${bar.valueVarID}`)
            );
          }
        } else if (type == "line") {
          for (let year of widget.years) {
            variableObservables.push(
              this._http.get(`${EXPRESS_SERVER}/variables/${year.valueVarID}`)
            );
          }
        } else if (type == "multi_var_bar") {
          let groups = widget.groups as VariableGroup[];
          for (let group of groups) {
            for (let varId of group.valueVarIDs) {
              variableObservables.push(
                this._http.get(`${EXPRESS_SERVER}/variables/${varId}`)
              );
            }
          }
        }
      } else if (source == "cpt_nass") {
        if (type == "value") {
          variableObservables.push(
            this._http.get(`${EXPRESS_SERVER}/nass/variable/${widget.valueVarID}`)
          );
        } else if (type == "bar") {
          for (let bar of widget.bars) {
            variableObservables.push(
              this._http.get(`${EXPRESS_SERVER}/nass/variable/${bar.valueVarID}`)
            );
          }
        } else if (type == "line") {
          for (let year of widget.years) {
            variableObservables.push(
              this._http.get(`${EXPRESS_SERVER}/nass/variable/${year.valueVarID}`)
            );
          }
        }
      }
      return variableObservables;
    }

  resolveVariables(
    widget: any,
    widgetSelection: WidgetSelection
  ): Promise<any[]> {
    let variableObservables = [];

    if (widgetSelection.source == "cpt_census") {
      if (widgetSelection.type == "value") {
        variableObservables.push(
          this._http.get(`${EXPRESS_SERVER}/variables/${widget.valueVarID}`)
        );
      } else if (widgetSelection.type == "bar") {
        for (let bar of widget.bars) {
          variableObservables.push(
            widget.slices
              ? this._http.get(`${EXPRESS_SERVER}/variables/${bar.valueVarID}`)
              : this._http.get(`${EXPRESS_SERVER}/variables/${bar.valueVarID}`)
          );
        }
      } else if (widgetSelection.type == "line") {
        for (let year of widget.years) {
          variableObservables.push(
            this._http.get(`${EXPRESS_SERVER}/variables/${year.valueVarID}`)
          );
        }
      } else if (widgetSelection.type == "multi_var_bar") {
        let groups = widget.groups as VariableGroup[];
        for (let group of groups) {
          for (let varId of group.valueVarIDs) {
            variableObservables.push(
              this._http.get(`${EXPRESS_SERVER}/variables/${varId}`)
            );
          }
        }
      }
    } else if (widgetSelection.source == "cpt_nass") {
      if (widgetSelection.type == "value") {
        variableObservables.push(
          this._http.get(`${EXPRESS_SERVER}/nass/variable/${widget.valueVarID}`)
        );
      } else if (widgetSelection.type == "bar") {
        for (let bar of widget.bars) {
          variableObservables.push(
            this._http.get(`${EXPRESS_SERVER}/nass/variable/${bar.valueVarID}`)
          );
        }
      } else if (widgetSelection.type == "line") {
        for (let year of widget.years) {
          variableObservables.push(
            this._http.get(`${EXPRESS_SERVER}/nass/variable/${year.valueVarID}`)
          );
        }
      }
    }

    let promises: Promise<any>[] = [];
    for (let variableObservable of variableObservables) {
      promises.push(variableObservable.toPromise());
    }

    return Promise.all(promises);
  }

  selectionMap: SelectionMap;
  toolContainerMap = {
    "Community Profiles Tool": [],
    "Food System Map": [],
  };
  chipMap = {
    "Community Profiles Tool": [],
    "Food System Map": [],
  };

  selectionsReady: boolean = false;
  getSelections(): void {
    const startTime = Date.now();
    const allSelections = forkJoin({
      selections: this._api.getExplorerWidgetSelections(),
      fsmSelections: this._api.getExplorerMapWidgetSelections(),
      kxMapSelections: this._api.getExplorer4hWidgetSelections(),
      efieldSelections: this._api.getEfieldSelections()
    });
    allSelections.subscribe({
      next: (next) => {
        let selections = <Array<WidgetSelection>>next.selections.filter((s) => s.toolContainerRefs[0] != 'None');
        let fsmSelections = <Array<WidgetSelection>>next.fsmSelections;
        let kxMapSelections = <Array<WidgetSelection>>next.kxMapSelections;
        let efieldSelections = <Array<eFieldsWidget>>next.efieldSelections;
        let cptContainers = [];
        let fsmContainers = [];

        for (let selection of [...selections, ...fsmSelections]) {
          selection.title = this.toTitleCase(selection.title);
          if (selection.type == "pie") {
            selection.type = "bar";
          }
          this.checkTitleForYearUpdateIfNotExist(selection);
          for (let container of selection.toolContainerRefs) {
            if (selection.source.includes("cpt")) {
              cptContainers.push(container);
            } else if (selection.source == "fsm") {
              fsmContainers.push(container);
            }
          }
        }

        this.toolContainerMap["Community Profiles Tool"] = [
          ...new Set(cptContainers),
        ];
        this.toolContainerMap["Food System Map"] = [...new Set(fsmContainers)];

        let efieldWidgetSelections = this.mapEfieldsWidgetsToSelections(efieldSelections);

        this.selectionMap = this.getSelectionMap(selections, fsmSelections, kxMapSelections, efieldWidgetSelections);
      },
      complete: () => {
        this.selectionsReady = true;
        this.selectionsFetched.next(true);
      },
    });
  }

  private mapEfieldsWidgetsToSelections(efieldWidgets: eFieldsWidget[]): eFieldsWidgetSelection[] {
    return efieldWidgets.map((w: eFieldsWidget) => {
      return {
        ids: w.ids,
        title: w.title,
        type: "bar",
        years: w.years,
        source: "efields",
        sourceText: "efields",
        toolContainerRefs: ["efields"],
        id: "none"
      }
    });

  }

  private getSelectionMap(
    selections: WidgetSelection[],
    fsmSelections: WidgetSelection[],
    kxMapSelections: WidgetSelection[],
    efieldSelections: eFieldsWidgetSelection[],
  ): SelectionMap {
    const sM: SelectionMap = {
      value: selections.filter((selection) => selection.type == "value"),
      bar: selections.filter(
        (selection) => selection.type == "bar" || selection.type == "pie"
      ),
      line: selections.filter((selection) => selection.type == "line"),
      multi_var_bar: selections.filter(
        (selection) => selection.type == "multi_var_bar"
      ),
      map: [...kxMapSelections, ...fsmSelections],
      efields: [...efieldSelections],
      header: [],
      body: [],
    };
    return sM;
  }

  checkTitleForYearUpdateIfNotExist(selection: WidgetSelection) {
    if (!selection.title) {
      return;
    }
    let match = selection.title.match(/\d{4}/g);
    let parenthesesMatch = selection.title.match(/\(\d{4}\)/);
    let y = selection._years;
    if (!match) {
      if (y) {
        selection.year = parseInt(y[y.length - 1]); // most recent year
      } else {
        selection.year = selection.source == "cpt_census" ? 2018 : 2017;
      }
      selection.title = `${selection.title} (${selection.year})`;
    } else {
      if (!parenthesesMatch) {
        selection.title = selection.title.replace(match[0], `(${match[0]})`);
      }
      selection.year = parseInt(match[match.length - 1]);
    }
  }

  addFromCart(widgetsToAdd: WidgetInput[]): void {
		let widgets = widgetsToAdd;
    this.resetDashboard();
    this.createLegendsForMapWidgets(widgetsToAdd);
    let pagedWidgetInputs = this.addPagesAndUpdatePositions(widgets);
    for (let p of pagedWidgetInputs) {
      this.pages[p.index].widgets.push(p.widget);
    }
		widgets.forEach((w) => this.logWidget(w, "ADD"));
  }

  // helper method for cart input
  private addPagesAndUpdatePositions(
    widgets: WidgetInput[]
  ): multiPageWidgetInput[] {
    let currX = 0;
    let currY = 0;
    let index = 0;
    let pagedWidgetInputs = [];

    for (let i = 0; i < widgets.length; i++) {
      let w = widgets[i];
      if (currX + w.cols > COLS) {
        currX = 0;
        currY += w.rows;
      }
      if (currY + w.rows > ROWS) {
        this.addPage(false);
        currX = 0;
        currY = 0;
        index += 1;
      }

      w.x = currX;
      w.y = currY;

      currX += w.cols;

      let mPWI = {
        index: index,
        widget: w,
      };
      pagedWidgetInputs.push(mPWI);
    }

    return pagedWidgetInputs;
  }

  async updateStartPositions(widgetsToAdd: WidgetInput[]) {
    let currX = 0;
    let currY = 0;
    for (let i = 0; i < widgetsToAdd.length; i++) {
      let nextWidget = widgetsToAdd[i];
      let w = widgetsToAdd[i];

      if (currX + nextWidget.cols > 50) {
        currX = 0;
        currY += 25;
      }
      if (currY >= 50) {
        this.addPage(false);
        this.state.activeIndex += 1;
        currX = 0;
        currY = 0;
      }
      nextWidget.x = currX;
      nextWidget.y = currY;
      currX += nextWidget.cols;
      this.pages[this.state.activeIndex].widgets.push(widgetsToAdd[i]);
    }
  }

  createLegendsForMapWidgets(widgetsToAdd: WidgetInput[]) {
    let mapIds = [];
    for (let widget of widgetsToAdd) {
      if ("maps" in <FsmWidgetInput>widget) {
        mapIds.push(widget.id);
      }
    }

    for (let mapId of mapIds) {
      let w = widgetsToAdd.find((widget) => widget.id == mapId);
      if (w) {
        let index = widgetsToAdd.indexOf(w);
        widgetsToAdd.splice(index + 1, 0, this.createLegendWidget(w));
      }
    }
  }

  createLegendWidget(parentWidget: WidgetInput): LegendWidgetInput {
    let legendWidget: LegendWidgetInput = {
      parentMapId: parentWidget.id,
      id: `legend-${parentWidget.id}`,
      rows: DEFAULT_SIZES.legend.rows,
      cols: DEFAULT_SIZES.legend.cols,
      x: 0,
      y: 0,
      selection: {
        id: `legend-${parentWidget.id}`,
        title: `Legend - ${parentWidget.selection.title}`,
        type: "legend",
        source: "none",
        sourceText: "legend",
        toolContainerRefs: [],
      },
    };
    return legendWidget;
  }

  async resetDashboard(): Promise<any> {
    let cb = async (resolve, reject) => {
      this.widgetReferences = [];
      this.pages = [];
      this.state = this.getDefaultState();
      this.addPage(false);
    };
    return new Promise(cb);
  }

  addWidget(widget: WidgetInput, pageIndex?: number, continueDrag?: boolean) {
    let index = pageIndex ? pageIndex : this.state.activeIndex;
    let page = this.pages[index];
    let pageConfig = page.config.api;
    if (
      page.titlePage ||
      (pageConfig && !pageConfig.getNextPossiblePosition(widget))
    ) {
      let pageIndexWithAvailability = this.pages.findIndex((page, i) => {
        return i > index && page.config.api.getNextPossiblePosition(widget);
      });
      if (pageIndexWithAvailability != -1) {
        index = pageIndexWithAvailability;
        this.createSnackBar("Widget added at first available position.");
      } else {
        this.addPage(false, index);
        index = index + 1;
        this.createSnackBar("Widget added to new page.");
      }
    }
    this.state.activeIndex = index;
    page = this.pages[index];
    page.widgets.push(widget);

    if (widget.selection.type == "map") {
      let legend = this.createLegendWidget(widget);
      this.addWidget(legend);
    }
		this.logWidget(widget, 'ADD')
  }

  // for testing
  add10Random(): void {
  }

  toTitleCase(s: string): string {
    s = s.toLowerCase();
    return s
      .split(" ")
      .map((word) => {
        let letterToCap = word.match(/\w/);
        if (!letterToCap) return word;
        return word.replace(letterToCap[0], letterToCap[0].toUpperCase());
      })
      .join(" ");
  }

  removeWidgetFromPage(pageIndex: number, widgetId: string) {
    let page = this.pages[pageIndex];
    let widget = page.widgets.find((w) => w.id == widgetId);
    let index = page.widgets.findIndex((widget) => widget.id == widgetId);
    if (index == -1) return;
    page.widgets.splice(index, 1);
    let markedForDeath = [widget];
    if (widget.selection.type == "map") {
      let legend = this.findLegendComponentForMap(
        widget as FsmWidgetInput
      ).widgetInput;
      let index = page.widgets.findIndex((widget) => widget.id == legend.id);
      if (index == -1) return;
      page.widgets.splice(index, 1);
      markedForDeath.push(legend);
    }
    this.deleteWidgets(markedForDeath);
  }

  createSnackBar(text: string): void {
    this._snackbar.open(text, undefined, { duration: 2000 });
  }

  optionsChanged(): void {
    for (let page of this.pages) {
      if (page.titlePage) continue;
      page.config.api.optionsChanged();
    }
  }

  getPageThumbnail(pageIndex: number): void {
    let id = this.pages[pageIndex].uniqueId;
    let element = document.getElementById(id) as HTMLElement;
    let canvasChildren = element.querySelectorAll("canvas");
    this.pages[pageIndex].thumbnail = new Promise(async (resolve, reject) => {
      (await html2canvas(element, { scale: 0.1 })).toDataURL();
    });
  }

  // Private Methods

  private deleteWidgets(widgets: WidgetInput[]): void {
    let markedForDeath = widgets.map((w) => w.id);
    this.widgetReferences = this.widgetReferences.filter(
      (ref) => !markedForDeath.includes(ref.widgetInput.id)
    );
  }

  private getDrupalSourceText() {
    this._api.getDataSets().subscribe((dataSets) => {
      this.drupalDatasets = dataSets;
    });
  }

  get date() {
    let d = new Date();
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  }

  private getDefaultState(): ExplorerState {
    let state = {
      title: DEFAULT_TITLE,
      subTitle: DEFAULT_SUB_TITLE,
      firstName: "John",
      lastName: "Doe",
      portrait: false,
      pageSize: 85,
      pageCount: 0,
      activeIndex: 0,
      date: this.date,
      editingTitle: false,
      downloading: false,
      editableTitleState: {
        firstName: "",
        lastName: "",
        title: "",
        subTitle: "",
      },
    };
    return state;
  }

  private doChartResize(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) {
    let element = itemComponent.el as HTMLElement;
    let component = this.findWidgetComponentById(item.id);
    let childComponent = component.childWidgetComponent;
    if (
      childComponent instanceof ChartWidgetComponent ||
      childComponent instanceof MultiVarBarWidgetComponent
    ) {
      let container = childComponent.chartContainer
        ? (childComponent.chartContainer.nativeElement as HTMLElement)
        : element;
      let w = container.offsetWidth;
      let h = container.offsetHeight;
      childComponent.updateChartElement(w, h);
    }
  }

  leftSub: Subscription;
  rightSub: Subscription;
  startDragIndex = -1;
  getDefaultGridConfig(): GridsterConfig {
    let config: GridsterConfig = {
      gridType: GridType.Fit,
      minRows: ROWS,
      minCols: COLS,
      maxRows: ROWS + 20,
      maxCols: COLS,
      r: ROWS,
      c: COLS,
      mobileBreakpoint: 1,
      margin: 0,
      dropOverItems: false,
      disableScrollHorizontal: true,
      disableScrollVertical: true,
      swapWhileDragging: true,
      resizable: {
        enabled: true,
        stop: (item, itemComponent, event) => {
          setTimeout(() => {
            this.doChartResize(item, itemComponent);
          }, 100);
        },
      },
      draggable: {
        enabled: true,

        delayStart: 300,
        start: (item, itemComponent, event) => {
          this.updatePageButtons();
          this.startDragIndex = this.state.activeIndex;
          this.rightSub = this.rightRegion.subscribe((event) => {
            let cancelMove = false;
            this.rightRegionOut.subscribe((event) => {
              cancelMove = true;
            });
            setTimeout(() => {
              if (!cancelMove) this.dragMove(itemComponent, true);
            }, 1000);
          });
          this.leftSub = this.leftRegion.subscribe((event) => {
            let cancelMove = false;
            this.leftRegionOut.subscribe((event) => {
              cancelMove = true;
            });
            setTimeout(() => {
              if (!cancelMove) this.dragMove(itemComponent, false);
            }, 1000);
          });
        },
        stop: (item, itemComponent, event) => {
          this.updatePageButtons(true);
          this.leftSub.unsubscribe();
          this.rightSub.unsubscribe();
          if (this.startDragIndex != this.state.activeIndex) {
            this.dropOnCurrentPage(itemComponent, this.startDragIndex);
          }
          this.startDragIndex = -1;
        },
      },
      displayGrid: DisplayGrid.Always,
      itemInitCallback: (item, itemComponent) => {
        this.doChartResize(item, itemComponent);
      },
      initCallback: () => {
        this.gridInitalized.next(true);
      },
      gridSizeChangedCallback: (g: GridsterComponentInterface) => {
        g.options["r"] = g.rows;
        g.options["c"] = g.columns;
      },
    };

    return config;
  }

  private updatePageButtons(hide?: boolean): void {
    let leftIndex = this.state.activeIndex - 1;
    let rightIndex = this.state.activeIndex + 1;
    let showPageIndicatorEvent: ShowPageIndicatorEvent = {
      left: false,
      right: false,
    };
    if (hide) {
      this.showPageIndicator.next(showPageIndicatorEvent);
      return;
    }
    if (this.pages[leftIndex] && !this.pages[leftIndex].titlePage) {
      showPageIndicatorEvent.left = true;
    }
    if (this.pages[rightIndex]) showPageIndicatorEvent.right = true;

    this.showPageIndicator.next(showPageIndicatorEvent);
  }

  moveParentChildWidgets(
    widget: LegendWidgetInput | FsmWidgetInput,
    from: number,
    to: number,
    type: "legend" | "map"
  ) {
    let map: FsmWidgetInput;
    let legend: LegendWidgetInput;
    if (type == "map") {
      map = widget as FsmWidgetInput;
      legend = this.findLegendComponentForMap(map).widgetInput;
    } else {
      legend = widget as LegendWidgetInput;
      map = this.findMapComponentForLegend(legend).widgetInput;
    }
    const mostRows = Math.max(map.rows, legend.rows);
    const mostCols = Math.max(map.cols, legend.cols);

    let minRect =
      (map.cols + legend.cols) * mostRows < (map.rows + legend.rows) * mostCols
        ? [mostRows, map.cols + legend.cols]
        : [map.rows + legend.rows, mostCols];

    let dummyGridsterItem: GridsterItem = {
      x: 0,
      y: 0,
      rows: minRect[0],
      cols: minRect[1],
    };
    let newPage = this.pages[to];
    let widgetsFitsOnPage =
      newPage.config.api.getNextPossiblePosition(dummyGridsterItem);
    if (widgetsFitsOnPage) {
      this.removeWidgetFromPage(from, map.id);
      this.removeWidgetFromPage(from, legend.id);
      newPage.widgets.push(map);
      newPage.widgets.push(legend);
    } else {
      this.createSnackBar(
        "Not enough space available for Map and Legend widgets. Widget move canceled."
      );
    }
  }

  private dropOnCurrentPage(
    item: GridsterItemComponentInterface,
    startIndex: number
  ) {
    let widgetInput = <WidgetInput>item.item;
    let type = widgetInput.selection.type;
    if (type == "map" || type == "legend") {
      let widget =
        type == "map"
          ? (widgetInput as FsmWidgetInput)
          : (widgetInput as LegendWidgetInput);
      this.moveParentChildWidgets(
        widget,
        startIndex,
        this.state.activeIndex,
        type
      );
    } else {
      let newPage = this.pages[this.state.activeIndex];
      let widgetFitsOnPage =
        newPage.config.api.getNextPossiblePosition(widgetInput);
      if (widgetFitsOnPage) {
        this.removeWidgetFromPage(startIndex, widgetInput.id);
        this.pages[this.state.activeIndex].widgets.push(widgetInput);
        if (widgetInput.selection.type == "legend") {
          setTimeout(() => {
            let base_component = this.findWidgetComponentById(
              (<LegendWidgetInput>widgetInput).parentMapId
            );
            (
              base_component.childWidgetComponent as MapWidgetComponent
            ).refreshLegendComponent();
          }, 1000);
        }
      } else {
        this.createSnackBar(
          "Not enough space available for widget. Widget move canceled."
        );
      }
    }
    this.showIndicator.next({
      show: false,
      widgetDemensions: undefined,
      startPositon: undefined,
    });
  }

  private dragMove(item: GridsterItemComponentInterface, right: boolean): void {
    let widgetInput = <WidgetInput>item.item;
    let newActiveIndex = this.state.activeIndex + (right ? 1 : -1);
    let element = item.el as HTMLElement;
    let rect = element.getBoundingClientRect();
    if (!this.pages[newActiveIndex] || this.pages[newActiveIndex].titlePage)
      return;
    this.changePage(right);
    this.showIndicator.next({
      show: this.startDragIndex != this.state.activeIndex,
      widgetDemensions: { w: rect.width, h: rect.height },
      startPositon: { x: rect.x, y: rect.y },
    });
    this.updatePageButtons();
    //this.pages[newActiveIndex].widgets.push(widgetInput);
  }

  moveEvent: Event;
  private showDropIndicator(
    widgetDemensions: { w: number; h: number },
    startPositon: { x: number; y: number }
  ): void {
    let ind = document.getElementById("drop-indicator");
    ind.style.visibility = "visible";
    ind.style.width = `${widgetDemensions.w}px`;
    ind.style.height = `${widgetDemensions.h}px`;
    ind.style.left = `${startPositon.x}px`;
    ind.style.top = `${startPositon.y}px`;

    document.addEventListener(
      "mousemove",
      this.dropIndicatorMouseEventFunction
    );
  }
  private hideDropIndicator() {
    let ind = document.getElementById("drop-indicator");
    ind.style.visibility = "hidden";
    document.removeEventListener(
      "mousemove",
      this.dropIndicatorMouseEventFunction
    );
  }

  private showPageIndicatorButton(direction: "left" | "right") {
    let ind =
      direction == "left"
        ? document.getElementById("left-region")
        : document.getElementById("right-region");
    ind.style.visibility = "visible";
  }
  private hidePageIndicatorButton(direction: "left" | "right") {
    let ind =
      direction == "left"
        ? document.getElementById("left-region")
        : document.getElementById("right-region");
    ind.style.visibility = "hidden";
  }

  private dropIndicatorMouseEventFunction(event: MouseEvent) {
    let ind = document.getElementById("drop-indicator");
    let left = Number(ind.style.left.slice(0, -2)) + event.movementX;
    let top = Number(ind.style.top.slice(0, -2)) + event.movementY;
    ind.style.left = `${left}px`;
    ind.style.top = `${top}px`;

    //ind.style.transform += "translateX(" + (event.x) + "px)";
  }
}
