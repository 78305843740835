<div class="callout-group">
  <swiper
    [slidesPerColumn]="2"
    slidesPerColumnFill="row"
    [slidesPerView]="1"
    [spaceBetween]="16"
    (swiper)="onSwiper($event)"
  >
    <ng-template swiperSlide *ngFor="let item of items | async; index as i">
      <app-generic-callout
        [head]="item.title"
        [subhead]="item.description"
        [image]="item.imageUrl"
        [link]="item.link"
        color="light"
        [side]="getSide(i)"
      ></app-generic-callout>
    </ng-template>
  </swiper>
  <button
    (click)="navigateTo(swiper.activeIndex - 1)"
    class="prev-btn"
    *ngIf="swiper?.activeIndex != 0"
  >
    <app-slider-arrow-icon direction="left"></app-slider-arrow-icon>
  </button>
  <button
    (click)="navigateTo(swiper.activeIndex + 1)"
    class="next-btn"
    *ngIf="!swiper?.isEnd"
  >
    <app-slider-arrow-icon direction="right"></app-slider-arrow-icon>
  </button>
</div>
<div class="pagination">
  <div
    *ngFor="let x of groupNumbers; index as i"
    class="indicator"
    [ngClass]="{ on: i == swiper?.activeIndex }"
    (click)="navigateTo(i)"
  ></div>
</div>
