import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-list',
  templateUrl: './text-list.component.html',
  styleUrls: ['./text-list.component.scss']
})
export class TextListComponent implements OnInit {
	@Input() listType: 'number' | 'bullet';
	@Input() listItems: string[];
  constructor() { }
  ngOnInit(): void {
		console.log('here')
		console.log(this.listItems)
		console.log(this.listType)
  }
}
