<h2>Uploading Files</h2>

<div>
  This tool allows for for batch upload of file geodatabases. There is a file
  upload size limit of 200mb. If you need to submit a file larger than the
  limit, please
  <a href="mailto:kx-admin@osu.edu">contact us</a> directly. Large files
  (~50MB+) will take some time to upload, dependent on your network speed.
  <br /><br />
  The name of each file geodatabase should follow the same naming structure as
  the Agricultural Conservation Planning Framework ArcGIS® Toolbox User’s
  Manual. Enter “acpf” + HUCID, where HUCID is the 12-digit HUCID number of that
  watershed (i.e. acpf070802010901.gdb).

  <br /><br />
  Your uploads will be in their local UTM projection. Note: if your selection
  crosses a UTM zone boundary, the output database projections may vary across
  the data.

  <br /><br />
  Thank you for submitting results to the ACPF database for Ohio.
  <br /><br />
  If you have any questions or would like to provide feedback please email us at
  <strong>
    <a href="mailto:kx-admin@osu.edu">kx-admin@osu.edu</a>
  </strong>
</div>
<br />

<mat-vertical-stepper [linear]="false" #stepper>
  <mat-step label="Choose the file type">
    ACPF core geodatabases contain layers required to run the ACPF toolboxes,
    ACPF results geodatabases contain outputs from the ACPF tools submitted by
    members of the ACPF modeler community.
    <br />
    <br />
    <mat-radio-group
      aria-label="Select an option"
      [(ngModel)]="fileTypeBeingUploaded"
      color="primary"
    >
      <mat-radio-button value="core">Core geodatabase</mat-radio-button>
      <br />
      <mat-radio-button value="result">Results geodatabase</mat-radio-button>
    </mat-radio-group>
    <br />
    <br />
    <div>
      <button mat-stroked-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step label="Select your files">
    <button
      mat-stroked-button
      (click)="fileInput.click()"
      [disabled]="fileHandlers.length > 0"
    >
      <span>Select Files</span>
      <input
        #fileInput
        type="file"
        (change)="updatedInput(fileInput)"
        (change)="stepper.next()"
        accept="application/zip"
        multiple="true"
        style="display: none"
      />
    </button>
    <span *ngIf="fileHandlers.length == 0"> No Files Selected</span>
  </mat-step>
  <mat-step
    label="Verify that your files are present ({{
      fileHandlers.length
    }} selected)"
  >
    <span *ngIf="hasErrors()">
      Some of your files have errors. Please remove the offending files or
      <a href="/acpf-file-manager/upload" target="_parent">start over</a> with
      another batch.
    </span>
    <mat-list>
      <!-- <mat-divider></mat-divider> -->
      <ng-container *ngFor="let handler of fileHandlers; index as i">
        <mat-list-item>
          <a [attr.href]="handler.url" target="_parent">{{
            handler.url ? handler.url : handler.filename
          }}</a>
          &nbsp;
          <mat-chip-list>
            <mat-chip>
              {{ handler.uploadStatus }}
            </mat-chip>
            <mat-chip color="warn" *ngFor="let err of handler.errors" selected>
              {{ err }}
            </mat-chip>
          </mat-chip-list>
          <button
            mat-icon-button
            (click)="deleteFileHandler(i)"
            aria-label="remove this dataset"
            *ngIf="
              ['ready to submit', 'has errors'].includes(handler.uploadStatus)
            "
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-list-item>
        <!-- <mat-divider></mat-divider> -->
      </ng-container>
    </mat-list>
    <br />
    <div>
      <button mat-stroked-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step label="Add details (optional)">
    If you want to provide alternate contact information for the files you are
    uploading, please click on a file to edit. Empty fields will be replaced
    with your contact information on submitting this form. <br /><br />
    <div>
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let handler of fileHandlers; index as i"
          [expanded]="i == 0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ handler.filename }}
            </mat-panel-title>
            <!-- <mat-panel-description>click to add details </mat-panel-description> -->
          </mat-expansion-panel-header>
          <mat-form-field appearance="fill">
            <mat-label>Contact Name</mat-label>
            <input
              matInput
              [(ngModel)]="handler.extraInfo.contact_name"
              [disabled]="
                batchWideDetails &&
                indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected !=
                  i
              "
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Contact Organization</mat-label>
            <input
              matInput
              [(ngModel)]="handler.extraInfo.contact_org"
              [disabled]="
                batchWideDetails &&
                indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected !=
                  i
              "
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Contact Email</mat-label>
            <input
              matInput
              [(ngModel)]="handler.extraInfo.contact_email"
              [disabled]="
                batchWideDetails &&
                indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected !=
                  i
              "
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              [(ngModel)]="handler.extraInfo.description"
              [disabled]="
                batchWideDetails &&
                indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected !=
                  i
              "
            ></textarea>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>What data source did you use to create these data? Include the year</mat-label>
            <input
              matInput
              [(ngModel)]="handler.extraInfo.data_source"
              [disabled]="
                batchWideDetails &&
                indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected !=
                  i
              "
            />
          </mat-form-field>
          <mat-form-field appearance="fill" *ngIf="fileTypeBeingUploaded  == 'result'">
            <mat-label>What did you do to validate the results?</mat-label>
            <textarea
              matInput
              [(ngModel)]="handler.extraInfo.what_was_done_to_validate_the_results"
              [disabled]="
                batchWideDetails &&
                indexOfTheHandlerWhoseDetailsToFollowIfBatchWideDetailsIsSelected !=
                  i
              "
            ></textarea>
          </mat-form-field>
          <mat-checkbox
            color="primary"
            class="download"
            [checked]="batchWideDetails"
            (click)="checkboxChange(i)"
            (keyup.enter)="checkboxChange(i)"
            aria-label="checkbox"
            *ngIf="fileHandlers.length > 1"
            >Apply these details to all files in this batch</mat-checkbox
          >
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <br />
    <div>
      <button mat-stroked-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step label="Finish and submit files">
    <button
      mat-flat-button
      [color]="hasErrors() ? 'warn' : 'primary'"
      (click)="upload()"
      [disabled]="disableUpload()"
    >
      <span>Submit</span>
    </button>
    <span *ngIf="hasErrors()"> Files with errors will be skipped. </span>
    <br />
    <br />
    <span *ngIf="!disableUpload()">
      Please click the submit button to start uploading your files.</span
    >

    <ng-container *ngFor="let h of fileHandlers">
      <ng-container
        *ngIf="
          ['uploading...', 'uploaded successfully', 'upload failed'].includes(
            h.uploadStatus
          )
        "
        ><div>
          {{ h.filename }} {{ h.uploadStatus }}
          <a *ngIf="h.url" [href]="h.url" target="_parent">(link)</a>
        </div>
        <br
      /></ng-container>
    </ng-container>

    <div *ngIf="allDone()">
      All files uploaded successfully.
      <a href="/acpf-file-manager/" target="_parent"
        >Return to the home page to view all your uploaded files.</a
      >
    </div>
  </mat-step>
</mat-vertical-stepper>

<a
  href="/acpf-file-manager/upload"
  target="_parent"
  *ngIf="fileHandlers.length > 0"
  >Start over</a
>
<br />
<a href="/acpf-file-manager/" target="_parent">Back</a>
