import { Component, OnInit } from "@angular/core";
import { elements, musicalElement } from "../elements";
import WaveSurfer from "wavesurfer.js";
import { ApiService } from "src/app/services/api.service";
import { environment } from "src/environments/environment";
let AudioMaker = require("audiomaker");

const log = (...args: any[]) => {
  if (environment.name == "DEVELOPMENT") console.log(...args);
};

type musicToolState =
  | "initializing"
  | "initialized"
  | "loading"
  | "ready"
  | "playing"
  | "paused";

@Component({
  selector: "app-music-tool",
  templateUrl: "./music-tool.component.html",
  styleUrls: ["./music-tool.component.scss"],
})
export class MusicToolComponent implements OnInit {
  waveSurfer: WaveSurfer;
  mergeUtility: typeof AudioMaker;
  blobs: Blob[];
  elements: musicalElement[] = elements;
  audioQueue: number[] = [];
  playing: boolean = false;
  message: string = "Select some musical elements on the right to get started.";
  private _state: musicToolState = "initializing";
  public get state(): musicToolState {
    return this._state;
  }
  public set state(value: musicToolState) {
    this._state = value;
    this.resetMessage();
    log(this._state);
  }

  get disablePlayBtn(): boolean {
    return (
      this.state == "initializing" ||
      this.state == "initialized" ||
      this.state == "loading" ||
      this.audioQueue.length == 0
    );
  }
  get disableResetBtn(): boolean {
    return this.audioQueue.length == 0;
  }
  get generateable(): boolean {
    return !(
      this.state == "initializing" ||
      this.state == "loading" ||
      this.audioQueue.length == 0
    );
  }

  constructor(private _api: ApiService) {}

  ngOnInit(): void {
    this.audioQueue = this.elements.map((el, index) => index);
    this.mergeUtility = new AudioMaker();
    this._api
      .getAudioBlobs(this.elements.map((el) => el.audioSource))
      .subscribe((blobs) => {
        this.blobs = blobs;
        this.state = "initialized";
      });
  }

  setMessage(msg?: string) {
    this.message = msg || "";
  }
  resetMessage() {
    if (this.state == "initialized" || this.state == "initializing") {
      this.setMessage(
        "Select some musical elements on the right to get started."
      );
      if (this.generateable) this.setMessage("Press play to start listening.");
    } else if (this.state == "ready") {
      this.setMessage("Press play to start listening.");
    } else this.setMessage();
  }

  generateWaveform(): void {
    let wasPaused = this.state == "paused";

    this.state = "loading";

    let blobs = this.audioQueue.map((i) => this.blobs[i]);

    log("Merging...");
    this.mergeUtility.merge(blobs).then((blob: Blob) => {
      log("Merged.");

      let params = {
        container: "#waveform",
        waveColor: "#FFF",
        progressColor: "#60979A",
        barWidth: 4,
        barGap: 2,
        cursorWidth: 0,
        hideScrollbar: true,
        height: 350,
        normalize: true,
        responsive: true,
      };
      var time = 0;
      if (this.waveSurfer) {
        this.waveSurfer.destroy();
        time = this.waveSurfer.getCurrentTime();
      }

      log("Creating WaveSurfer with params: ", params);
      this.waveSurfer = WaveSurfer.create(params);

      log("Loading Blobs into WaveSurfer.");
      this.waveSurfer.loadBlob(blob);

      this.waveSurfer.on("ready", () => {
        log("WaveSurfer ready");
        this.waveSurfer.skip(time);
        if (!wasPaused) this.waveSurfer.play();
        this.state = wasPaused ? "paused" : "playing";
      });
      this.waveSurfer.on("seek", () => {
        if (this.waveSurfer.isPlaying()) this.waveSurfer.play();
      });
    });
  }

  getCurrentTime() {
    if (this.audioQueue.length > 0 && this.waveSurfer) {
      return this.waveSurfer.getCurrentTime();
    } else return 0;
  }

  toggle(i: number) {
    let inQueue = this.audioQueue.includes(i);

    if (!inQueue) this.addToQueue(i);
    else this.removeFromQueue(i);
  }

  addToQueue(index: number) {
    log(`Adding ${this.elements[index].instrument} to queue.`);
    this.audioQueue.push(index);
    if (this.state == "playing" || this.state == "paused") {
      this.generateWaveform();
    }
    // this.generateWaveform();
  }

  removeFromQueue(index: number) {
    log(`Removing ${this.elements[index].instrument} from queue.`);
    let indexOfIndex = this.audioQueue.findIndex((el) => el === index);
    this.audioQueue.splice(indexOfIndex, 1);
    if (this.state == "playing" || this.state == "paused") {
      this.generateWaveform();
    }
    // if (this.audioQueue.length == 0) this.reset();
  }

  play() {
    if (this.audioQueue.length < 1) {
      console.error("No instruments selected.");
    } else {
      switch (this.state) {
        case "loading":
          console.error("Audio blobs not yet loaded.");
          break;
        case "playing":
          console.error("Aleady playing.");
          break;
        case "ready":
        case "paused":
          this.waveSurfer.play();
          this.state = "playing";
          break;
      }
    }
  }

  pause() {
    if (this.state == "playing") {
      this.waveSurfer.pause();
      this.state = "paused";
    }
  }

  reset() {
    this.pause();
    this.audioQueue = [];
    if (this.waveSurfer) {
      this.waveSurfer.destroy();
      this.waveSurfer = null;
    }
    if (this.state != "loading") this.state = "initialized";
  }
}
