import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { assert } from "console";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService, urlify } from "src/app/services/api.service";
import { TitleService } from "src/app/services/title.service";
import { getBackgroundImageStyles } from "../../functions/ng-style-utils";
import {
  AccordionQASection,
  CardsSection,
  CarouselSection,
  ColumnSection,
  ContributorHighlightSection,
  CustomPageNavRef,
  customPageResponse,
  CustomPageSection,
  getSectionObj,
  headerType,
  IframeSection,
  ImageCarouselSection,
  NavigationDestinationSection,
  NavWithImageCarouselSection,
  ParagraphType,
  RelatedContentSection,
  TabsSection,
  VideoSection,
  VisualLinksSection,
  VariableColumnContainerSection,
  ContributorsSection,
} from "../etc/types";
import { MetaTagsService } from "src/app/services/meta-tags.service";
import { Person, UserType } from "../../models/person";

@Component({
  selector: "app-custom-page",
  templateUrl: "./custom-page.component.html",
  styleUrls: [
    "../etc/style.scss",
    "../etc/food-system-campaign-header-colors.scss",
  ],
})
export class CustomPageComponent implements OnInit {
  @Input() nodeID: string;
  @Input() topicOverride: string;
  @Input() displayHeader: boolean = true;
	@Input() hosted: boolean = false;
  @Output() navigationSections = new EventEmitter<CustomPageNavRef>(); // For navigation on custom page navigator


	
  contentObservable: Observable<customPageResponse[]>;
  types = ParagraphType;
  title: string;
	splitTitle: string[];
  headerType: headerType;
  headerImage: string;
  tags: string[];
  topics: { name: string; url: string }[];
  description: string;
  name: string;
  byline: string;
  subtitle: string;
  date: string;
  ready: boolean = false;
  widthClass: "responsive-page-container-lg" | "responsive-page-container" =
    "responsive-page-container";
  contributorIDs: string[];
  contributors: Person[] = [];
	mobile: boolean = false;

  sections: CustomPageSection[] = [];

  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sanitizer: DomSanitizer,
    private _title: TitleService,
    private _metaTags: MetaTagsService
  ) {
    this._route.params.subscribe((p) => {
      if (p.name) {
        this.name = p.name;
        this.contentObservable = this._api.getDrupalCustomPageByName(this.name);
        this.setup();
      }
    });
  }

  ngOnInit(): void {
    if (this.nodeID) {
      this.contentObservable = this._api.getDrupalCustomPageByNodeID(
        this.nodeID
      );
      this.setup();
    }
    if (!this.name && !this.nodeID) window.location.href = "/404";
		this.mobile = window.innerWidth <= 800;
  }

	showHeroCarouselHeader = false;
	heroEntryRegistration: BehaviorSubject<any>;
	heroEntryClicked: BehaviorSubject<any>;

	setupHeroCarouselHeader() {
		if (this.showHeroCarouselHeader) return;
		this.showHeroCarouselHeader = true;
		this.heroEntryRegistration = new BehaviorSubject(0);
		this.heroEntryClicked = new BehaviorSubject(0);
	}

	hostedCustomPageRerouteCheck(nid: string, title: string) {
		console.log("nid");
		console.log(nid)
		this._api.getDrupalNavigatorPages().subscribe((navs) => {
			for (let nav of navs) {
				for (let hostedPage of nav.pages) {
					if (hostedPage.customPageId == nid) {
						console.log("REROUTE TO NAV");
						this._router.navigate([nav.route, title]);
					}
				}

			}

		});


	}

  setup() {
    this.contentObservable.subscribe((content: customPageResponse[]) => {
      if (content[0]) {
        let item = content[0];
        this.title = item.title;
				const titleArr = this.title.split(" ");
				if (titleArr.length > 2) {
					this.splitTitle = [
						titleArr.slice(0, 2).join(" "), 
						titleArr.slice(2, titleArr.length).join(" ")
					]
				}
        this.sections = item.sections.map((s) => getSectionObj(s));
        this.description = item.description;
        this.headerImage = item.headerImage;
        this.headerType = item.headerType;
        this.byline = item.byline;
        this.subtitle = item.subtitle;
        this.date = item.date;
        this.contributorIDs = item.contributors
          .split(",")
          .filter((el) => el.length > 0);
        this.loadContributors();
				if(!this.hosted) this.hostedCustomPageRerouteCheck(item["nid"], item.name);
        if (item.page_width == "Wide")
          this.widthClass = "responsive-page-container-lg";
        this.tags = item.tags.split(", ");
        this.topics = item.topics.split(", ").map((element) => {
          return {
            name: element.replace("&amp;", "&"),
            url: urlify(element),
          };
        });
        console.log(this);
        // Run necessary setups
        this.sections.forEach((s) => {
          if (s.type == ParagraphType.Carousel)
            (s as CarouselSection).setup(this._api);
          else if (s.type == ParagraphType.Video)
            (s as VideoSection).setup(this._sanitizer);
          else if (s.type == ParagraphType.Contributor_Highlight)
            (s as ContributorHighlightSection).setup(this._api);
          else if (s.type == ParagraphType.Visual_Links)
            (s as VisualLinksSection).setup(this._api);
          else if (s.type == ParagraphType.Column)
            (s as ColumnSection).setup(this._api, this._sanitizer);
          else if (s.type == ParagraphType.Tabs)
            (s as TabsSection).setup(
              this._api,
              this._sanitizer,
              this.title,
              this.tags
            );
          else if (s.type == ParagraphType.Cards)
            (s as CardsSection).setup(
              this._api,
              this._sanitizer,
              this.title,
              []
            );
          else if (s.type == ParagraphType.Related_Content)
            (s as RelatedContentSection).setup(this.tags, this.title);
          else if (s.type == ParagraphType.Iframe)
            (s as IframeSection).setup(this._sanitizer);
          else if (s.type == ParagraphType.Accordion_QA) {
						this.setupHeroCarouselHeader();
            (s as AccordionQASection).setup(
							this._api, 
							this._sanitizer,
							this.heroEntryRegistration,
							this.heroEntryClicked

						);
					}
          else if (s.type == ParagraphType.Image_Carousel)
            (s as ImageCarouselSection).setup(this._api);
          else if (s.type == ParagraphType.Nav_With_Image_Carousel)
            (s as NavWithImageCarouselSection).setup(this._api);
          else if (s.type == ParagraphType.Variable_Column_Container)
            (s as VariableColumnContainerSection).setup(this._api, this._sanitizer);
          else if (s.type == ParagraphType.Contributors)
            (s as ContributorsSection).setup(this._api);
        });

        console.log(this);
      } else {
        window.location.href = "/404";
      }

      if(!this.hosted) {
				this._title.setPageTitle(this.title);
				this._metaTags.updateTitle(this.title);
			}
      if (this.description && this.description.length > 0)
        this._metaTags.updateDescription(this.description);
      if (this.headerImage && this.headerImage.length > 0)
        this._metaTags.updateImageTags(this.headerImage);
      if (this.tags && this.tags.length > 0)
        this._metaTags.updateKeywords(this.tags.join(", "));

      this.signalReady();
    });
  }

  signalReady(): void {
    this.ready = true;
    let navSections = this.sections.filter(
      (s) => s.type === ParagraphType.Navigation_Destination
    ) as NavigationDestinationSection[];
    this.navigationSections.emit({
      customPageId: this.nodeID,
      navSections: navSections,
    });
  }

  getHeaderBackground() {
    return getBackgroundImageStyles(0.5, this.headerImage);
  }

  getCampaignTitle() {
    return "The Food System";
  }
  getCampaignLink() {
    return "/food-system-visualization";
  }
  loadContributors() {
    if (!this.contributorIDs) return Promise.all([Promise.resolve(true)]);
    this.contributors = [];
    let promises = [];
    this.contributorIDs.forEach((id, index) => {
      this.contributors[index] = new Person(UserType.OSUInternal, id);
      promises.push(
        this.contributors[index].populateOSUData(
          this._api.getPersonInfoFromOSUDirectory(id)
        )
      );
    });

    return Promise.all(promises);
  }
}
