<button
  mat-flat-button
  class="subscribe-button"
  data-toggle="modal"
  data-target="#subscribe"
>
  Subscribe
</button>
<div class="modal fade text-left" id="subscribe" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Subscribe to content notification emails</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <label>Email Address</label>
          <input
            class="form-control mb-2"
            type="text"
            [(ngModel)]="email_address"
            name="email_address"
          /><button
            class="btn btn-primary"
            (click)="check()"
            [disabled]="!email_address"
            *ngIf="state == 'INITIAL'"
          >
            Continue
          </button>
          <div class="spinner-border m-3 d-block" role="status" *ngIf="state == 'CHECKING'">
            <span class="sr-only">Loading...</span>
          </div>
          <ng-container *ngIf="state == 'READY'">
            <label>Full Name</label>
            <input
              class="form-control mb-2"
              type="text"
              [(ngModel)]="full_name"
              name="full_name"
            />
            <label>Topics you're interested in</label>
            <div class="form-check" *ngFor="let topic of topics">
              <input
                type="checkbox"
                class="form-check-input"
                [(ngModel)]="topic.selected"
                [name]="topic.name"
                [id]="topic.name"
              />
              <label class="form-check-label" [for]="topic.name">{{
                topic.name
              }}</label>
            </div>
            <br /><button
              class="btn btn-primary mb-2"
              (click)="submit()"
              data-toggle="modal"
              data-target="#subscribe"
            >
              Submit
            </button>
            <p>Thank you for subscribing! You will receive an email from KX when new content is published on your selected topic(s), not to exceed one email per day.</p>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</div>
