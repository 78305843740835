import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";

export type contactUsResponseBodyFormat = {
  pageSource: string;
  surveyResponses: surveyResponseFormat;
};
export type surveyResponseFormat = {
  fullName: string;
  email: string;
  department: string;
  jobTitle: string;
  reasonForContacting: string;
  message: string;
};

@Component({
  selector: "app-contact-us-form",
  templateUrl: "./contact-us-form.component.html",
  styleUrls: ["./contact-us-form.component.scss"],
})
export class ContactUsFormComponent {
  @Output() completed = new EventEmitter();

  responses: surveyResponseFormat = {
    fullName: "",
    email: "",
    department: "",
    jobTitle: "",
    reasonForContacting: "",
    message: "",
  };

  recieved = false;

  constructor(private api: ApiService) {}

  submit() {
    let data: contactUsResponseBodyFormat = {
      pageSource: window.location.href,
      surveyResponses: this.responses,
    };
    console.log(data);
    this.api.submitContactUsForm(data).subscribe((res) => {
      this.recieved = true;
      this.completed.emit(true);
    });

    window.scrollTo(0, 0);
  }
}
