import { BrowserTransferStateModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { HttpClientModule } from "@angular/common/http";
import { NgtUniversalModule } from "@ng-toolkit/universal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppModule } from "./app.module";

@NgModule({
  imports: [
    NgbModule,
    AppModule,
    FormsModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
