export enum TYPE {
  // DATASET = "Dataset",
  FILE = "File",
  IMAGE_COLLECTION = "Image Collection",
  LINK = "Link",
  // PAGE = "Custom Page",
  PODCAST = "Podcast",
  QUESTION = "Question",
  STORY = "Story",
  VIDEO = "Video",
  VIRTUAL_TOUR = "Virtual Tour",
  BLOG="Blog",
  TOOL="Tool",
  PAGE = "Page",
}
