import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import {HeroEntry} from '../../custom-stuff/etc/types';

@Component({
  selector: 'app-hero-swiper',
  templateUrl: './hero-swiper.component.html',
  styleUrls: ['./hero-swiper.component.scss']
})
export class HeroSwiperComponent implements OnInit {
	@Input() entryRegistation: BehaviorSubject<any>; 
	@Input() entryClicked: BehaviorSubject<any>; 
  @ViewChild("swiper", { static: false }) swiper: SwiperComponent;

	heroCarousel: HeroEntry[] = [];

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    loop: true,
    autoplay: { delay: 5000, disableOnInteraction: true },
  };

  constructor() { }

  ngOnInit(): void {
		this.entryRegistation.subscribe((res: HeroEntry) => {
			if (!res.image) return;
			this.heroCarousel.push(res)
		});
  }

	toggleEntry(entry: HeroEntry) {
		console.log(entry);
		this.entryClicked.next({accordionName: entry.accordionName, index: entry.index});

	}


  slideNext(event: any): void {
		console.log("slide next")
		event.stopPropagation();
    let ref = this.swiper.swiperRef;
    ref.slideNext();
		console.log(ref);
  }

}
