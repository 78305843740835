import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
	OnInit,
  ViewChild,
} from "@angular/core";
import {
  Chart,
  ChartConfiguration,
	ChartData,
  ChartDataSets,
  ChartOptions,
  ChartType,
} from "chart.js";
import {
	ChartJSComponent,
  CptWidgetInput,
  EfieldsWidgetInput,
  GeneratedEfieldsWidgetInput,
  UserChartWidgetInput,
} from "../../types/types";
import { ExplorerService } from "../../services/explorer.service";
import {
  ChartBackgroundColors,
  ChartBorderColors,
} from "../../types/constants";
import {BaseChartDirective} from 'ng2-charts';
import { EFieldsTrial } from 'src/app/shared/models/eFieldTrial';

const FIXED_WIDTH = 500;

@Component({
  selector: "app-widget",
  templateUrl: "./chart-widget.component.html",
  styleUrls: ["./widget.component.scss"],
})
export class ChartWidgetComponent implements OnInit, AfterViewInit, ChartJSComponent {
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
	@ViewChild("chart") chartContainer: ElementRef
  @ViewChild("resizeContainer") resizeContainer: ElementRef;
  @Input() widgetInput:
    | CptWidgetInput
    | UserChartWidgetInput
    | EfieldsWidgetInput
    | GeneratedEfieldsWidgetInput; 
	data: ChartDataSets[];
  options: ChartOptions;
	labels: string[];
	type: string;
	activeScopes: string[];
  efieldsInput: boolean = false;
	displayChart: boolean = true;
  multi: boolean = false;
	private _displayImage: string;

	get displayImage(): string {
		return this._displayImage
	}

	get myChart():  BaseChartDirective {
		return this.chart;
	}

	setDisplayImage() {
		if (!this.myChart) return;
		this._displayImage = this.myChart.toBase64Image();
	}

	updateChartElement(width: number, height: number): void {
		this.displayChart = width > 250;
		if (this.displayChart) return;
    let sizeRatio = height / width;
		width = 400;
		height = width * sizeRatio;
		this.displayChart = false;
		setTimeout(() => {
			let element = this.resizeContainer.nativeElement;
			if (!element) return;
			element.style.width = `${width}px`;
			element.style.height = `${height}px`;
			this.myChart.update();
		},100)
	}

  constructor(private _explorer: ExplorerService) {}

	ngOnInit(): void {
		let source = this.widgetInput.selection.source
		if (source == 'cpt_census' ||
			source == 'cpt_nass') {
			this.activeScopes = this.cptWidgetInput.scopes.map(s => s);
		}
	}

	ngAfterViewInit(): void {
		this.initalizeChart();
	}

	updateScope(newScope: string) {
		console.log(newScope);
		this.activateScopes[0] = newScope;
		this.initalizeChart();
	}

	activateScopes(scopes: string[]): void {
		this.activeScopes = scopes;
		this.initalizeChart();
	}

	getImage(): string {
		let image = this.myChart.toBase64Image();
		return image;
	}


	get cptWidgetInput(): CptWidgetInput {
		return this.widgetInput as CptWidgetInput;

	}

  private getDefaultConfig(): ChartConfiguration {
    const options: ChartConfiguration = {
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "",
          },
        ],
      },
      options: {
				animation: {
					onComplete: () => {
						this.setDisplayImage();
					}
				},
				devicePixelRatio: 2.5,
				legend: {
					display: true,
					labels: {
						fontFamily: "'Arial', sans-serif",
						fontColor: '#333'
					}
				},
        scales: {
          xAxes: [
            {
              ticks: {
								fontSize: 10,
								fontColor: '#333',
								fontStyle: 'bold'
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
								fontSize: 10,
								fontColor: '#333',
								fontStyle: 'bold'
              },
            },
          ],
          ticks: {
            beginAtZero: false,
          },
        },
        responsive: true,
				maintainAspectRatio: false
      },
    };
    return options;
  }

  private getEfieldsConfig(input: EfieldsWidgetInput): ChartConfiguration {
    let config = {
      data: {
        datasets: input.datasets,
        labels: input.labels,
      },
      options: input.options,
      type: input.type,
    };
			config.options.devicePixelRatio = 2.5;
			config.options.legend.labels.fontFamily = "'Arial', sans-serif";
			config.options.legend.labels.fontColor = "#333"
			config.options.title.fontFamily = "'Georgia', serif",
			config.options.title.fontColor = '#333'
			config.options.scales = 
				{
          xAxes: [
            {
              ticks: {
								fontSize: 10,
								fontColor: '#333',
								fontStyle: 'bold'
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
								fontSize: 10,
								fontColor: '#333',
								fontStyle: 'bold'
              },
            },
          ],
          ticks: {
            beginAtZero: false,
          },
        }

    return config;
  }

  private sortLineData(
    input: CptWidgetInput,
		scope: string
  ): { year: number; value: number }[] {
    let yearValues = [];
    for (let i = 0; i < input.cptWidget.years.length; i++) {
      let year = parseInt(input.cptWidget.years[i]["year"]);
			console.log(input.variables[i]);
			let value = parseFloat(
          input.variables[i].data[scope].replace(",", ""));
			if (!value || value == -1) {
				value = null;
			}
      yearValues.push({
        year: year,
        value: value
			});
      }
		console.log(yearValues);
    return yearValues.sort((a, b) => {
      let val = a.year > b.year ? 1 : b.year > a.year ? -1 : 0;
      return val;
    });
  }

  private getCptConfig(input: CptWidgetInput): ChartConfiguration {
    let config = this.getDefaultConfig();

    config.type = input.selection.type;
    config.options.title = {
      display: true,
      text: `${input.selection.title}${input.scopes.length == 1 ? ': ' + input.scopes[0] : ''}`,
			fontFamily: "'Georgia', serif",
			fontColor: '#333'
    };

    if (config.type == "line") {
			this.setLineData(config.data, this.activeScopes);
    } else {
      for (let bar of input.cptWidget.bars) {
				let v = input.variables.find(n => n._id == bar['valueVarID']);
				let label = bar['label'];
				config.data.labels.push(this.formatLabel(label));
				config.options.legend.display = false;
				if (!v) continue;

        let dataPoint = parseFloat(v.data[input.scopes[0]].replace(",", ""));
        config.data.datasets[0].data.push(dataPoint);
      }
      config.options.scales.yAxes[0].ticks.beginAtZero = true;
      config.type = "horizontalBar";
			this.setChartColors(config);
    }

    return config;
  }

  private  determineChartType(input: GeneratedEfieldsWidgetInput): "scatter" | "bar" {
    let type: "scatter" | "bar" = "bar";
    for (let trial of input.trials) {
      if (!isNaN(trial[input.xAxis])) {
        type = "scatter";
      }
    }
    return type;
  }

  private setEfieldConfigOptions(
    config: ChartConfiguration, 
    input: GeneratedEfieldsWidgetInput,
    type: string): ChartConfiguration {
      console.log(input);
      let options : ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: "None",
			fontSize: 14,
			fontColor: '#000',
    },
    legend: {
      display: true,
      position: "right",
			labels: {
				usePointStyle: true,
				
			}
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "None",
						fontColor: '#000',
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "None",
						fontColor: '#000',
          },
        },
      ],
    },
  };
      options.scales.xAxes[0].scaleLabel.labelString = 'Units';
      options.scales.yAxes[0].scaleLabel.labelString = 'Units';
      let trialGroups = {};
      for (let trial of input.trials) {
        let county = trial.county;
        if (county in trialGroups) trialGroups[county].push(trial);
        else trialGroups[county] = [trial];
      } 

      config.data.datasets = [];
      config.data.labels = [];
      for (const [key, value] of Object.entries(trialGroups)) {

        let data = (value as EFieldsTrial[]).map((trial) => {
          return {x: trial[input.xAxis], y: trial[input.yAxis]}
        });
        let dataset: ChartDataSets = {
          label: key,
          type: this.type,
          data: data
        };
        config.data.datasets.push(dataset);
      }
      console.log(config);
      config.type = type;
      return config;
  }

  getGeneratedEfieldsConfig(input: GeneratedEfieldsWidgetInput): ChartConfiguration {
    let config = this.getDefaultConfig();
    this.type = this.determineChartType(input);
    config = this.setEfieldConfigOptions(config, input, this.type);
    

    return config;
  }

  initalizeChart(): void {
		console.log('init')
    const input = this.widgetInput;
    let config: ChartConfiguration;

    if (input.selection.source == "efields") {
      if (input["xAxis"] && input["yAxis"]) {
        config = this.getGeneratedEfieldsConfig(input as GeneratedEfieldsWidgetInput)

      } else {
        config = this.getEfieldsConfig(input as EfieldsWidgetInput);
      }
    } else {
      config = this.getCptConfig(input as CptWidgetInput);
    }

		this.data = config.data.datasets
		this.labels = config.data.labels as string[]
		this.options = config.options
		this.type = config.type
    console.log(this.data);
    console.log(this.labels);
    console.log(this.options);
    console.log(this.type);
  }



	setLineData(data: ChartData, scopes: string[]): void {
		let labels = []
		let datasets = []
		for (let s of scopes) {
			let sortedData = this.sortLineData(this.cptWidgetInput, s);
			if (!labels.length ) labels = sortedData.map((yearVal) => yearVal.year);
			datasets.push({label: s, data: sortedData.map(yearVal => yearVal.value)})
		}

		data.labels = labels;
		data.datasets = datasets;
		this.setLineChartColors(datasets)
	}

	getLineChartData(scopes: string[]): ChartDataSets[] {
		let dataSets: ChartDataSets[] = [];
		for (let scope of scopes) {
			let sortedDataAtScope = this.sortLineData(this.cptWidgetInput, scope);
			console.log(sortedDataAtScope)
			let dataSet: ChartDataSets = {
				data: sortedDataAtScope.map((yearValue) => yearValue.value),
				label: scope
			}
			dataSets.push(dataSet);
		}
		this.setLineChartColors(dataSets)
		return dataSets;
	}

  setChartColors(options: any) {
    options.data.datasets[0].backgroundColor = ChartBackgroundColors;
    options.data.datasets[0].borderColor = ChartBorderColors;
    options.data.datasets[0].borderWidth = 1;
  }

	setLineChartColors(datasets: ChartDataSets[]) {
		datasets.forEach((dataset, index) => {
			dataset.backgroundColor = ChartBackgroundColors[index];
			dataset.borderColor = ChartBorderColors[index];
			dataset.borderWidth = 1;
		})

	}

  private formatLabel(label: string): string[] {
		label = this._explorer.toTitleCase(label);
    let formattedLabel = [];
    if (label.length > 12) {
      let firstSpaceIndex = label.indexOf(" ", 11);
      if (firstSpaceIndex == -1) {
        formattedLabel.push(label);
      } else {
        let line = label.slice(0, firstSpaceIndex);
        formattedLabel = [
          line,
          ...this.formatLabel(label.slice(firstSpaceIndex + 1, label.length)),
        ];
      }
    } else {
      formattedLabel.push(label);
    }
    return formattedLabel;
  }

  getPosAndSize(): { x: number; y: number; rows: number; cols: number } {
    return {
      x: this.widgetInput.x,
      y: this.widgetInput.y,
      rows: this.widgetInput.rows ? this.widgetInput.rows : 1,
      cols: this.widgetInput.cols ? this.widgetInput.cols : 1,
    };
  }
}
