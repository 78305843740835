import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  AcpfAuthenticationService,
  AcpfSessionToken,
} from "src/app/services/acpf-auth.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-acpf-fm-login",
  templateUrl: "./acpf-fm-login.component.html",
})
export class AcpfFmLoginComponent implements OnInit {
  @Output()
  tokenAquired: EventEmitter<AcpfSessionToken> = new EventEmitter<AcpfSessionToken>();

  name: string = "";
  id: string = "";
  organization: string = "";

  email = new FormControl("", [Validators.required, Validators.email]);
  password = new FormControl("", [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern("^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).+$"),
  ]);

  passwordForLogIn: string = "";
  hidePassword = true;
  step: "init" | "enter-password" | "register" = "init";

  thinking = false;

  constructor(
    private api: ApiService,
    public auth: AcpfAuthenticationService
  ) {}

  ngOnInit() {}

  registrationInputsAreValid() {
    return (
      this.name.length > 0 &&
      this.organization.length > 0 &&
      !this.password.invalid &&
      this.email.value.length > 0
    );
  }
  // emailIsValid() {
  //   return emailAddressRegEx.test(this.email);
  // }
  getEmailErrorMessage() {
    if (this.email.hasError("required")) {
      return "You must enter a value";
    }

    return this.email.hasError("email") ? "Not a valid email" : "";
  }
  getPasswordErrorMessage() {
    console.log(this.password.errors);
    if (this.password.hasError("required")) return "You must enter a value";

    if (this.password.hasError("minlength"))
      return "Your password must contain at least 8 characters";

    if (this.password.hasError("pattern"))
      return "Your password must contain at least one letter, one number, and one special character";

    return "";
  }
  register() {
    this.thinking = true;
    this.api
      .acpfUserRegister({
        name: this.name,
        email: this.email.value,
        organization: this.organization,
        fileUrls: [],
        verified: false,
        password: this.password.value,
      })
      .subscribe((res: any) => {
        if (res && res._id) {
          this.auth.userId = res._id;
          this.auth
            .attemptLogin(this.password.value)
            .then((token) => this.tokenAquired.emit(token))
            .catch((err) => console.log(err))
            .finally(() => (this.thinking = false));
        }
      });
  }

  checkUserEmail() {
    if (this.email.invalid || this.thinking) return;

    this.thinking = true;
    this.api.getAcpfUser(this.email.value).subscribe((user: any) => {
      if (user.msg && user.msg == "nothing found") this.step = "register";
      else {
        this.name = user.name;
        this.organization = user.organization;
        this.id = user._id;
        this.step = "enter-password";
      }
      this.auth.userId = this.id;
      this.thinking = false;
    });
  }
  passwordSubmitted() {
    if (this.passwordForLogIn.length == 0 || this.thinking) return;

    this.thinking = true;
    console.log("trying to log in");
    this.auth
      .attemptLogin(this.passwordForLogIn)
      .then((res) => {
        this.tokenAquired.emit(res);
      })
      .catch((err) => (this.passwordForLogIn = ""))
      .finally(() => (this.thinking = false));
  }
  forgotPassword() {
    this.api.acpfInitPasswordReset(this.id).subscribe((res: any) => {
      if (res.success)
        alert(
          `Thank you for requesting a password reset. \n\nPlease check your email for a message from kx-admin@osu.edu detailing how to proceed with your password change. \n\nFollow the link in the email to continue.`
        );
      else alert("Password reset failed. Please try again.");
    });
  }
}
