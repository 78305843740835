import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  Department,
  ExpertFields,
  ExpertSearchService,
} from "src/app/services/expert-search.service";
import { DepartmentKeyPopupComponent } from "./department-key-popup/department-key-popup.component";
import { TitleService } from "src/app/services/title.service";

@Component({
  selector: "app-expert-directory",
  templateUrl: "./expert-directory.component.html",
  styleUrls: ["./expert-directory.component.scss"],
})
export class ExpertDirectoryComponent implements AfterViewInit {
  departments = Department;

  departmentFiltersActive: (keyof typeof Department)[] = [];

  displayedColumns: string[] = [
    "name",
    "department",
    "position_title",
    "email",
    "short_keywords",
    "new",
    // "keywords",
    // "searchable_blob",
    // "summary",
  ];
  dataSource: MatTableDataSource<ExpertFields>;

  filterValue: string = "";
  deptMenuActive: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  @ViewChild("deptList") deptList: ElementRef;
  @ViewChild("deptMenuTitle") deptMenuTitle: ElementRef;

  @HostListener("document:click", ["$event"])
  clickout(event) {
    let clickedOut =
      !this.deptList.nativeElement.contains(event.target) &&
      !this.deptMenuTitle.nativeElement.contains(event.target) &&
      this.deptMenuActive;
    if (clickedOut) this.deptMenuActive = false;
  }

  constructor(
    private expertSearch: ExpertSearchService,
    public dialog: MatDialog,
    public _titleService: TitleService
  ) {
    this._titleService.setPageTitle("Expert Directory");
    this.dataSource = new MatTableDataSource([]);

    this.expertSearch
      .search({})
      .subscribe((res: { fields: ExpertFields; id: string }[]) => {
        const experts = res.map((el) => el.fields);
        this.dataSource.data = experts;
        this.dataSource.sort.sort({
          id: "name",
          start: "asc",
          disableClear: false,
        });
      });
  }

  departmentCheck(dept: keyof typeof Department) {
    let i = this.departmentFiltersActive.indexOf(dept);
    if (i == -1) this.departmentFiltersActive.push(dept);
    else this.departmentFiltersActive.splice(i, 1);
    this.applyFilter();
  }
  clearDepartmentFilters() {
    this.departmentFiltersActive = [];
    this.applyFilter();
  }
  getDepartmentName(abbr: string): string {
    if (!abbr) return "";
    const department = Object.entries(Department).find(
      ([key, value]) => key.toLowerCase() === abbr.toLowerCase()
    );
    return department ? department[1] : "";
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DepartmentKeyPopupComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event?: Event) {
    if (event) this.filterValue = (event.target as HTMLInputElement).value;

    this.expertSearch
      .search({
        query: this.filterValue,
        departments: this.departmentFiltersActive,
      })
      .subscribe((res: { fields: ExpertFields; id: string }[]) => {
        const experts = res.map((el) => el.fields);
        this.dataSource.data = experts;
      });

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
