import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { Observable } from "rxjs";
import SwiperCore, {
  Mousewheel,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
} from "swiper/core";
SwiperCore.use([A11y, Pagination, Navigation, Mousewheel, Autoplay]);

@Component({
  selector: "app-announcements-header",
  templateUrl: "./announcements-header.component.html",
  styleUrls: ["./announcements-header.component.scss"],
})
export class AnnouncementsHeaderComponent implements OnInit {
  announcements: Observable<any>;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.announcements = this.apiService
      .getDrupalAccouncements()
      .map((allAnnouncements) =>
        allAnnouncements.filter((el) =>
          this.announcementIsActive(el.start_date, el.end_date)
        )
      );
  }

  announcementIsActive(start_date: string, end_date: string): boolean {
    const start = Date.parse(start_date);
    const end = Date.parse(end_date);
    const now = new Date().getTime();

    return now >= start && now <= end;
  }
}
