<div #contentSlider>
  <ng-container *ngIf="!empty">
    <h2
      class="content-slider-header"
      *ngIf="header && header.length > 0"
      [innerHTML]="header"
    ></h2>
    <div class="horizontal-slider">
      <button
        id="left"
        tabindex="0"
        (click)="prev()"
        (keydown.enter)="prev()"
        [disabled]="atStartPosition()"
        alt="previous"
        aria-label="show previous"
      >
        <i class="fas fa-chevron-left"></i>
      </button>
      <a
        *ngFor="let item of items"
        [href]="item.link"
        [ngClass]="item.visible ? 'visible' : 'hidden'"
        [style.width.px]="item.visible ? getWidth() : 0"
        [tabindex]="item.visible ? '' : '-1'"
        [attr.aria-hidden]="!item.visible"
        target="_blank"
      >
        <div class="top" [ngStyle]="item.background">
          <i [class]="item.icon"></i>
        </div>
        <div
          class="bottom"
          [style.width.px]="item.visible ? getWidth() - 8 : 0"
        >
          <span class="content-date"> {{ item.date }} </span>
          <span
            [class]="
              item.description || item.title.length > 70
                ? 'content-title-sm'
                : 'content-title-lg'
            "
          >
            {{ item.title }}
          </span>
          <span class="content-desc" *ngIf="item.description">
            {{ item.description }}</span
          >
        </div>
      </a>
      <button
        id="right"
        tabindex="0"
        (click)="next()"
        (keydown.enter)="next()"
        [disabled]="atEndPosition()"
        alt="next"
        aria-label="show next"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <div class="markers">
      <ng-container *ngFor="let item of items">
        <div [ngClass]="{ on: item.visible }"></div>
      </ng-container>
    </div>
  </ng-container>
</div>
