import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  AcpfAuthenticationService,
  AcpfSessionToken,
} from "src/app/services/acpf-auth.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-acpf-fm-reset-password",
  templateUrl: "./acpf-fm-reset-password.component.html",
})
export class AcpfFmResetPasswordComponent implements OnInit {
  thinking: boolean = false;

  user: {
    _id: string;
    name: string;
    email: string;
  };

  blob: string;

  password = new FormControl("", [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern("^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).+$"),
  ]);

  hidePassword = true;

  constructor(
    private api: ApiService,
    public auth: AcpfAuthenticationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      console.log(params.blob);
      this.blob = params.blob;
      this.api
        .acpfGetUserByPasswordResetBlob(this.blob)
        .subscribe((res: any) => {
          if (res._id) this.user = res;
          else window.location.href = "/404";
        });
    });
  }

  registrationInputsAreValid() {
    return !this.password.invalid;
  }
  getPasswordErrorMessage() {
    console.log(this.password.errors);
    if (this.password.hasError("required")) return "You must enter a value";

    if (this.password.hasError("minlength"))
      return "Your password must contain at least 8 characters";

    if (this.password.hasError("pattern"))
      return "Your password must contain at least one letter, one number, and one special character";

    return "";
  }
  register() {
    this.thinking = true;
    this.api
      .acpfResetPassword({
        _id: this.user?._id,
        newPassword: this.password.value,
        passwordResetBlob: this.blob,
      })
      .subscribe((res: any) => {
        if (res.success) {
          alert("Success! Your password has been changed. You may now log in.");
          window.location.href = "/acpf-file-manager";
        } else {
          alert(
            "Reset failed. Your password has not been changed. Please try again"
          );
        }
      });
  }
}
