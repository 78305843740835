<div class="impacts-page">
  <div class="impact-intro responsive-page-container">
    <h1>Our Impact</h1>
    <hr />
    <h2>Making Connections Throughout the State</h2>
    <div class="intro-body">
      <div class="text">
        <div [innerHTML]="intro | async"></div>
      </div>

      <img
        class="map"
        src="https://contentfilehosting.s3.amazonaws.com/20230303_Impacts%20County%20Map_v2.0.svg"
        alt="map"
      />
    </div>

    <div class="kx-originals">
      <h2>KX Originals</h2>
      <div class="stats responsive-page-container">
        <a
          [href]="stat.link"
          class="stat {{ stat.type }}"
          *ngFor="let stat of stats"
        >
          <img
            class="icon"
            [src]="stat.icon"
            [alt]="'icon representing ' + stat.type"
          />
          <div class="text">
            <div class="featuring" *ngIf="stat.type == 'Datasets'">
              Featuring
            </div>
            <div class="number">{{ stat.count | async }}</div>
            <div class="type">{{ stat.type }}</div>
          </div>
        </a>

        <div class="pageviews">
          {{ pageviews | async }} kx.osu.edu pageviews as of {{ date }}
        </div>
      </div>
    </div>

    <div class="ticker-container">
      <swiper
        [slidesPerView]="1"
        (swiper)="onSwiper($event)"
        class="full-width"
      >
        <ng-template swiperSlide *ngFor="let q of quotes | async">
          <div class="quote">
            <div class="speech-bubble">
              <bubble-top-svg></bubble-top-svg>
              <mobile-top-svg></mobile-top-svg>
              <div class="quote-text">
                <span [innerHTML]="q.quote"></span>
              </div>
              <bubble-bottom-svg></bubble-bottom-svg>
            </div>

            <div class="author-info">
              <img [src]="q.image" alt="a picture of {{ q.author }}" />
              <div class="author">
                <strong><span [innerHTML]="q.author"></span></strong>
                <br />
                <span [innerHTML]="q.author_info"></span>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>

      <button
        (click)="navigateTo(swiper.activeIndex - 1)"
        class="prev-btn"
        *ngIf="!swiper?.isBeginning"
      >
        <app-slider-arrow-icon direction="left"></app-slider-arrow-icon>
      </button>
      <button
        (click)="navigateTo(swiper.activeIndex + 1)"
        class="next-btn"
        *ngIf="!swiper?.isEnd"
      >
        <app-slider-arrow-icon direction="right"></app-slider-arrow-icon>
      </button>
      <div class="pagination">
        <ng-container *ngFor="let x of quotes | async; index as i">
          <div
            *ngIf="i >= swiper?.activeIndex || i <= swiper?.activeIndex"
            class="indicator"
            [ngClass]="{ on: i == swiper?.activeIndex }"
            (click)="navigateTo(i)"
          ></div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="responsive-page-container">
    <h2>Impact Stories</h2>
  </div>
  <div class="responsive-page-container-lg impact-stories">
    <content-list
      [content]="impactStories"
      type="image-slider-with-descriptions"
    ></content-list>
  </div>

  <div class="social responsive-page-container"></div>
</div>
