<div class="explorer-cart">
  <i
    class="fas fa-info-circle info-icon"
    matTooltip="This is the shopping cart for the Knowledge Explorer. Find map and chart visualizations across our tools and send them to the Knowledge Explorer to create custom dashboards."
    matTooltipPosition="right"
    matTooltipHideDelay="300"
  ></i>
  <div class="item-list">
    <div *ngIf="!cart.length" class="no-items">
      Add visualization widgets to your cart via the
      <i class="fas fa-file-export"></i> button.
    </div>
    <div class="cart-item" *ngFor="let item of cart">
			<div class="cart-item-title">{{ item.selection.title }}</div>
			<button (click)="removeFromCart($event, item.selection.title)" class="remove-item-button">X</button>
    </div>
  </div>
  <button
    [disabled]="!cart.length"
    class="checkout-button"
    (click)="checkout()"
  >
    Send to Explorer
  </button>
</div>
