<p>embedded-power-bi-report works!</p>
<ng-container *ngIf="isEmbedded">
  <powerbi-report
    [embedConfig]="reportConfig"
    [cssClassName]="reportClass"
    [phasedEmbedding]="phasedEmbeddingFlag"
    [eventHandlers]="eventHandlersMap"
  >
  </powerbi-report>
</ng-container>
