import { Component, Inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DataSet } from "src/app/shared/models/dataSet";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: "app-data-set",
  templateUrl: "./data-set.component.html",
  styleUrls: ["./data-set.component.scss"],
})
export class DataSetComponent implements OnInit {
  dataSet: DataSet;
  tags: string[];
	@Input() datasetTitle: string;

  constructor(private route: ActivatedRoute, private api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any) {
		this.dataSet = this.data.dataset;
		this.tags = this.dataSet.Tags.split(", ");
	}

  ngOnInit(): void {
  }

  checkAndReturnField(name: string, content: string): string {
    if (content) return content;
    return `No ${name} found for ${this.dataSet.Title} Data Set.`;
  }

  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
}
