<div id="slider" class="life-cycle-slider" *ngIf="items.length">
  <swiper #swiper [config]="config" [slidesPerView]="slidesPerView">
    <ng-template swiperSlide *ngFor="let item of items">
      <div class="slide">
        <div class="image-container">
          <img [src]="item.image" />
        </div>
        <div class="description-container">
          <p class="description">{{ item.image_description }}</p>
        </div>
			</div>
    </ng-template>
  </swiper>
  <button (click)="slidePrev()" class="left-button" *ngIf="!hideLeft">
    <i class="fas fa-chevron-left"></i>
  </button>
	<button (click)="slideNext()" class="right-button" *ngIf="!hideRight">
    <i class="fas fa-chevron-right"></i>
		</button>
</div>
