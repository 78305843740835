<div class="contact-info-form">
  <h1>
    {{ recieved ? "Thanks! We'll be in touch." : "Want more information?" }}
  </h1>

  <ng-container *ngIf="recieved == false; else complete">
    <p>
      We'd love to add you to our mailing list for updates on the
      Ohio ACPF Watershed Data Hub. We'll keep you in the loop when we make
      important updates to our ACPF products.
    </p>
    <div class="user-info-section">
      <mat-form-field  class="user-info">
        <mat-label>Full Name</mat-label>
        <input matInput [(ngModel)]="responses.name" />
      </mat-form-field>

      <mat-form-field  class="user-info">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="responses.email" />
      </mat-form-field>

      <mat-form-field  class="user-info">
        <mat-label>Organization</mat-label>
        <input matInput [(ngModel)]="responses.organization" />
      </mat-form-field>
    </div>

    <button
      mat-flat-button
      color="primary"
      (click)="submit()"
      [disabled]="
        responses.name == '' ||
        responses.email == '' ||
        responses.organization == ''
      "
    >
      Submit
    </button>
    <br /><br />
  </ng-container>
  <ng-template #complete>
    <br />
    <p>Your contact information has been submitted.</p>
    <p>
      We appreciate your interest in the Ohio ACPF Watershed Data Hub. We'll
      keep you in the loop when we make important updates to our ACPF products.
    </p>

    <br />
  </ng-template>
</div>
<br /><br /><br />
