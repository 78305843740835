<footer class="footer" id="footer" data-footer="footer" aria-label="footer">
  <div class="footer-column">
    <img
      src="/assets/img/brand/OSU-Wordmark-REV-Horiz-RGBHEX.png"
      alt="the ohio state univeristy"
    />
    <p>
      <br />
      © 2024, <a href="https://osu.edu">The Ohio State University</a
      ><br /><br />
      <a href="https://www.osu.edu/map/building/003"
        >College of Food, Agricultural, and Environmental Sciences <br />
        250 Agricultural Administration Building <br />
        2120 Fyffe Road, Columbus, OH 43210 <br
      /></a>
      Phone: 614-292-9722
    </p>
  </div>
  <div class="footer-column">
    This site designed and maintained by CFAES Knowledge Exchange. For technical
    support please
    <a href="mailto:kx@osu.edu">contact us</a>. <br /><br />
    <a href="https://cfaes.osu.edu/a11y"> Accessibility Accommodation </a>
    <p>
      If you have a disability and experience difficulty accessing this content,
      <a href="https://cfaes.osu.edu/a11y">request accommodation here</a>.
      <br /><br />
      <a href="https://equityandinclusion.cfaes.ohio-state.edu/"
        >CFAES Diversity</a
      >
      <br />
      <a href="https://it.osu.edu/privacy">Privacy Statement</a>
      <br />
      <a href="https://cfaes.osu.edu/nondiscrimination"
        >Nondiscrimination Notice</a
      >
    </p>
  </div>
  <div class="footer-column">
    <p>
      <a href="https://www.facebook.com/CFAESKX" aria-label="Facebook">
        <i class="fab fa-facebook"></i>
      </a>
      <a href="https://twitter.com/CFAES_KX" aria-label="Twitter">
        <i class="fa-brands fa-x-twitter"></i>
      </a>
      <a href="https://www.instagram.com/cfaes_kx/" aria-label="Instagram">
        <i class="fab fa-instagram"></i>
      </a>
      <a
        href="https://www.youtube.com/channel/UCFoUJ1qRVn0roxPKYQsvJ3w"
        aria-label="Youtube"
      >
        <i class="fab fa-youtube"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/cfaes-knowledge-exchange"
        aria-label="LinkedIn"
      >
        <i class="fab fa-linkedin"></i>
      </a>
      <br /><br />
      <a href="http://www.osu.edu/">The Ohio State University</a>
      <br /><br />
      <a href="http://cfaes.osu.edu/"
        >College of Food, Agricultural, and Environmental Sciences</a
      >
      <br /><br />
      <a href="http://extension.osu.edu/">Ohio State University Extension</a>
      <br /><br />
      <a href="http://oardc.osu.edu/"
        >Ohio Agricultural Research and Development Center</a
      >
      <br /><br />
      <a href="http://ati.osu.edu/">Ohio State ATI</a>
    </p>
  </div>
</footer>
