import { Component, Input, OnInit, ViewChild } from "@angular/core";
import Swiper, { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";

const BREAKPOINTS = [
  { sliderSize: 3, minWidth: 900, maxWidth: 999999 },
  { sliderSize: 2, minWidth: 500, maxWidth: 900 },
  { sliderSize: 1, minWidth: 0, maxWidth: 500 },
];

@Component({
  selector: "app-life-cycle-slider",
  templateUrl: "./life-cycle-slider.component.html",
  styleUrls: ["./life-cycle-slider.component.scss"],
})
export class LifeCycleSliderComponent implements OnInit {
  @ViewChild("swiper", { static: false }) swiper: SwiperComponent;
  @Input() items: { image: string; image_description: string }[];

  config: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 0,
    pagination: true,
  };

  get slidesPerView(): number {
    let slider = document.getElementById("slider");
    if (!slider) return 3;
    let elWidth = document.getElementById("slider").clientWidth;
    return BREAKPOINTS.find(
      (bkpt) => bkpt.minWidth <= elWidth && bkpt.maxWidth > elWidth
    ).sliderSize;
  }

  get swiperActiveIndex(): number {
    if (!this.swiper) return -1;
    return this.swiper.swiperRef.activeIndex;
  }

  get hideLeft(): boolean {
    return this.swiperActiveIndex == 0;
  }

  get hideRight(): boolean {
    return (
      this.swiperActiveIndex ==
      this.items.length - ((this.swiper?.slidesPerView as number) ?? 0)
    );
  }

  constructor() {}

  ngOnInit(): void {}

  slideNext(): void {
    let ref = this.swiper.swiperRef;
    ref.slideNext();
  }

  slidePrev(): void {
    let ref = this.swiper.swiperRef;
    ref.slidePrev();
  }
}
