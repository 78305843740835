import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "floor-plan-dialog",
  templateUrl: "floor-plan-popup.component.html",
  styles: [
    `
      .floor-plan-popup {
        position: relative;
        width: min-content;
        padding-right: 8px;
      }
      .floor-plan-popup .dialog-close-button {
        position: absolute;
        right: -6px;
        top: -6px;
        padding: 0;
        margin: 0;
      }
      ::ng-deep .floor-plan-popup img {
        width: 800px;
        margin-top: 15px !important;
        margin-bottom: 20px !important;
      }
    `,
  ],
})
export class PanoFloorPlanPopupDialog {
  html: SafeHtml;
  constructor(
    public dialogRef: MatDialogRef<PanoFloorPlanPopupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { imageURL: string; title: string },
    public sanitizer: DomSanitizer
  ) {
    // this.html = this.sanitizer.bypassSecurityTrustUrl(data.imageURL);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
