import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { SearchFilters, SearchOptions } from "src/app/main/search/etc/options";
import { ApiService } from "src/app/services/api.service";
import { TYPE } from "../main/search/etc/type";
import { SORT } from "../main/search/etc/sort";

const searchApiUrl =
  "https://7cwbk4gbkl.execute-api.us-east-1.amazonaws.com/beta";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  constructor(private _http: HttpClient, private apiService: ApiService) {}

  public search(s: SearchOptions, log: boolean = true) {
    if (log && environment.production) this.logSearch(s.query);
    let url = this.getFullQueryUrl(s);
    // console.log(url);
    return this._http.get(url);
  }

  public getResultCountForAFilterValue(
    { type, topic }: { type?: TYPE; topic?: string },
    s: SearchOptions
  ) {
    let options = new SearchOptions({
      size: 0,
      query: s.query,
      publishedOnly: s.publishedOnly,
      filters: new SearchFilters({
        types: type ? new Set<TYPE>().add(type) : s.filters.types,
        topics: topic ? new Set<string>().add(topic) : s.filters.topics,
        tags: s.filters.tags,
        date: s.filters.date,
      }),
    });
    let obs = this.search(options, false).map((res: any) => res.hits.found);
    return obs;
  }

  private getFullQueryUrl(s: SearchOptions) {
    // console.log("searchOptions:", s);
    let published = s.publishedOnly ? "published:'True'" : "";
    let topics = getFieldFilterString("topics", s.filters.topics);
    let tags = getFieldFilterString("tags", s.filters.tags);
    let types = getFieldFilterString("display_type", s.filters.types);
    let date = this.getDateRangeFilterString(s.filters.date);

    let fq =
      topics || tags || types || published.length > 0
        ? encodeURIComponent(
            `(and ${published} ${date} ${topics ?? ""} ${types ?? ""} ${
              tags ?? ""
            })`
          )
        : "";

    let sort =
      s.sort == SORT.NEWEST
        ? "date desc"
        : s.sort == SORT.OLDEST
        ? "date asc"
        : "_score desc";

    return (
      searchApiUrl +
      `?q=${s.query}&size=${s.size}&start=${s.start}&sort=${sort}&fq=${fq}`
    );
  }

 
  private getDateRangeFilterString = ({
    start,
    end,
  }: {
    start: Date;
    end: Date;
  }) => {
    if (!start) start = new Date(0, 0);
    if (!end) end = new Date();
    return `(range field=date ['${start.toISOString()}','${end.toISOString()}'])`;
  };

  private logSearch(query: string) {
    let searchTerm = { term: query, time: new Date() };
    this.apiService
      .newSearchTerm(searchTerm)
      .subscribe((res) => console.log(res));
  }
}

export let getFieldFilterString = (fieldName: string, filters: Set<any>) => {
  return filters.size > 0
    ? `(or ${Array.from(filters)
        .map((el) => `${fieldName}:'${el}'`)
        .join(" ")})`
    : null;
};