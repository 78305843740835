<div id="water-quality-composition-page">
  <h1>Life on the Lake</h1>
  <h2>Interact with the impacts of harmful algal blooms on Lake Erie</h2>
  <br />
  <p>
    KX asked Cooper Wood to create an original composition based on
    <a href="/page/cooper-wood-water-quality" target="_blank">a story</a>
    about Mike, a life-long resident who has experienced first-hand how water
    quality issues affect his family. The instruments in this piece are each
    tied to an opportunity or obstacle related to Mike’s enjoyment of the lake,
    as represented in the buttons on the right side. Hover over the buttons to
    learn more about each item.
  </p>
  <p>
    Click each button to add or remove an instrument from the composition.
    Listen to all the instruments at once, remove one or several, and discover
    how the addition or subtraction changes the overall melody. How do you feel
    when only obstacles are selected? What do you hear when opportunities are
    playing? As you create your own masterpiece, learn how the community on Lake
    Erie just wouldn’t be the same without the research conducted to combat
    harmful algal blooms.
  </p>

  <app-music-tool></app-music-tool>
  <br />
  <app-related-content [tags]="tags" parentTitle=""></app-related-content>
</div>
