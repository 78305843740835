import { Component, OnInit } from "@angular/core";
const buckeyeAlert = (<any>window).buckeyeAlert;

@Component({
  selector: "app-buckeye-alert",
  templateUrl: "./buckeye-alert.component.html",
  styleUrls: [
    "./buckeye-alert.component.scss",
    "./src/css/buckeye-alert-resp.css",
  ],
})
export class BuckeyeAlertComponent implements OnInit {
  ngOnInit(): void {
    buckeyeAlert({
      url: "//www.osu.edu/feeds/emergency-alert.rss",
      messageClass: "container",
    });
  }
}
