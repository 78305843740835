import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { SCOPE } from "../../../community-profile-tool/types";

@Component({
  selector: "app-line-graph-chip-list",
  templateUrl: "./line-graph-chip-list.component.html",
  styleUrls: ["./line-graph-chip-list.component.scss"],
})
export class LineGraphChipListComponent implements OnInit, OnChanges {
  @Input() initialScope: string;
  @Input() initalActivatedScopes?: string[]
  @Input() averageAvailable: string[];
  @Input() excludeScope: string[];
  @Output() scopesChanged = new EventEmitter();

  separatorKeysCodes: number[] = [ENTER];
  scopeCtrl = new FormControl();
  filteredScopes: Observable<string[]>;
  selectedScopes: string[] = [];
  // scopes: string[] = ["Apple", "Lemon", "Lime", "Orange", "Strawberry"];

  eligibleScopes: string[] = Object.keys(SCOPE);

  @ViewChild("scopeInput") scopeInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredScopes = this.scopeCtrl.valueChanges.pipe(
      map((scope: string | null) =>
        scope ? this._filter(scope) : this.eligibleScopes.slice()
      )
    );
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.initalActivatedScopes ? 
    this.selectedScopes = this.initalActivatedScopes :
    this.selectedScopes = [this.initialScope];

    if (this.averageAvailable) this.selectedScopes.push("Ohio Average");
    if (this.averageAvailable) this.eligibleScopes.push("Ohio Average");
    this.selectedScopes = [...new Set(this.selectedScopes)];
    this.scopesChanged.emit(this.selectedScopes);
  }

  add(event: MatChipInputEvent): void {
    console.log('add');
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.selectedScopes.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.scopeCtrl.setValue(null);

    this.scopesChanged.emit(this.selectedScopes);
  }

  remove(scope: string): void {
    const index = this.selectedScopes.indexOf(scope);

    if (index >= 0) {
      this.selectedScopes.splice(index, 1);
    }
    this.scopesChanged.emit(this.selectedScopes);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedScopes.push(event.option.viewValue);
    this.scopeInput.nativeElement.value = "";
    this.scopeCtrl.setValue(null);
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.eligibleScopes.filter(
      (scope) => scope.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
