<div class="profile responsive-page-container">
  <div class="profile-head">
    <a mat-flat-button color="primary" href="/expert-directory" *ngIf="isExpert"
      >Expert Directory</a
    >
    <a
      mat-flat-button
      color="primary"
      href="/directory?tab=contributors"
      *ngIf="isContributor"
      >KX Contributors</a
    >
    <a
      mat-flat-button
      color="primary"
      href="/directory?tab=kx-team"
      *ngIf="isDrupalUser"
      >KX Team</a
    >
    <h1>{{ profile.fullname }}</h1>
    <h2
      [innerHTML]="
        expertDoc.fields ? expertDoc.fields.position_title : profile.jobTitle
      "
    ></h2>
  </div>

  <div class="profile-main-info">
    <div class="image">
      <ng-container *ngIf="profile.photoURL; else noPhoto">
        <img
          src="{{ profile.photoURL }}"
          alt="{{ profile.fullname }}\'s Profile Photo"
        /><br />
      </ng-container>
      <ng-template #noPhoto>
        <img
          src="https://opic.osu.edu/{{
            profile.id ? profile.id : 'brutus.1'
          }}?width=1024"
          alt="{{ profile.fullname }}\'s Profile Photo"
        /><br />
      </ng-template>
    </div>

    <div class="contact-items">
      <div *ngIf="profile.email" class="contact-item">
        <div class="icon">
          <img
            src="https://contentfilehosting.s3.amazonaws.com/Asset%2011683546624462.svg"
            alt="mail icon"
          />
        </div>
        <div class="text">
          <div class="label">Email</div>
          <a href="mailto:{{ profile.email }}">{{ profile.email }}</a>
        </div>
      </div>
      <div *ngIf="profile.phone" class="contact-item">
        <div class="icon">
          <img
            src="https://contentfilehosting.s3.amazonaws.com/Group%201735.svg"
            alt="phone icon"
          />
        </div>
        <div class="text">
          <div class="label">Phone</div>
          <a href="tel:{{ profile.phone }}">{{ profile.phone }}</a>
        </div>
      </div>
      <div *ngIf="profile.address" class="contact-item">
        <div class="icon">
          <img
            src="https://contentfilehosting.s3.amazonaws.com/Asset%2031683546620343.svg"
            alt="address icon"
          />
        </div>
        <div class="text">
          <div class="label">Address</div>
          <a href="{{ profile.buildingURL }}"
            >{{ profile.address.street1 }}, {{ profile.address.city }}
            {{ profile.address.state }} {{ profile.address.zip }}</a
          >
        </div>
      </div>
      <div *ngIf="expertDoc.fields" class="contact-item">
        <div class="icon">
          <img
            src="https://contentfilehosting.s3.amazonaws.com/Department.svg"
            alt="department icon"
          />
        </div>
        <div class="text">
          <div class="label">Department</div>
          <a
            [href]="getExpertDepartmentURL(expertDoc.fields.department)"
            [innerHTML]="getExpertDepartmentName(expertDoc.fields.department)"
          ></a>
        </div>
      </div>
      <div *ngIf="profile.link" class="contact-item">
        <div class="icon">
          <img
            src="https://contentfilehosting.s3.amazonaws.com/Group%202307.svg"
            alt="link icon"
          />
        </div>
        <div class="text">
          <div class="label">Links</div>
          <a href="{{ profile.link }}">{{ profile.link }}</a>
        </div>
      </div>
    </div>
  </div>
  <br /><br />
  <div
    *ngIf="profile.description"
    [innerHTML]="profile.description"
    class="desc"
  ></div>
  <div *ngIf="expertDoc.fields && expertDoc.fields.keywords" class="overview">
    <h3>Overview</h3>
    <ul>
      <li
        *ngFor="let keyword of expertDoc.fields.keywords"
        [innerHTML]="keyword"
      ></li>
    </ul>
  </div>
  <h2 *ngIf="isContributor"></h2>
</div>
<content-list
  type="image-slider"
  *ngIf="isContributor"
  [content]="content"
  head="Contributed Content"
></content-list>
<br /><br /><br />
