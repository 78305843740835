import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { environment } from "src/environments/environment";
import { NotFoundComponent } from "./site-organization/not-found/not-found.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./main/main.module").then((m) => m.MainModule),
  },
  {
    path: "embed",
    loadChildren: () =>
      import("./embed/embed.module").then((m) => m.EmbedModule),
  },
  {
    path: "demo",
    loadChildren: () =>
      import("./sesquicentennial/demo.module").then((m) => m.DemoModule),
  },
  {
    path: "profiles",
    loadChildren: () =>
      import("./community-profile-tool/county-profiles-tool.module").then(
        (m) => m.CountyProfilesToolModule
      ),
  },
  // {
  //   path: "county-clusters",
  //   loadChildren: () =>
  //     import("./county-clusters/county-profiles.module").then(
  //       (m) => m.CountyProfilesModule
  //     ),
  // },
  {
    path: "food-system-tool",
    loadChildren: () =>
      import("./food-system-tool/food-system-tool.module").then(
        (m) => m.FoodSystemToolModule
      ),
  },
  {
    path: "explorer",
    loadChildren: () =>
      import("./explorer/explorer.module").then((m) => m.ExplorerModule),
  },
  // {
  //   path: "bite-site",
	// 	redirectTo: "bite"
  // },
  {
    path: "bite",
    loadChildren: () =>
      import("./bite-site/bite-site.module").then((m) => m.BiteSiteModule),
  },
  {
    path: "drain",
    loadChildren: () =>
      import("./drainmod/drainmod.module").then((m) => m.DrainmodModule),
  },
  {
    path: "bite_testing",
		redirectTo: "bite"
  },
  {
    path: "project/environment/habri-multi-model",
    redirectTo: "topic/harmful-algal-blooms",
  },
  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "/404" },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  onSameUrlNavigation: "reload",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
