<ng-container [ngSwitch]="displayType">
  <ng-container *ngSwitchCase="'sidebar'">
    <app-sidebar-display
      [infographic]="infographic | async"
    ></app-sidebar-display>
  </ng-container>
  <ng-container *ngSwitchCase="'mouse-popup'">
    <app-mouse-popup-display
      [infographic]="infographic | async"
    ></app-mouse-popup-display>
  </ng-container>
  <ng-container *ngSwitchCase="'modal'">
    <app-modal-display [infographic]="infographic | async"></app-modal-display>
  </ng-container>
  <p *ngSwitchDefault>invalid display type</p>
</ng-container>
