import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import ActiveLayerInfo from "esri/widgets/Legend/support/ActiveLayerInfo";
import { data } from "jquery";
import { ApiService } from "src/app/services/api.service";
import { TitleService } from "src/app/services/title.service";
import { detect } from "detect-browser";
import {
  CardsSection,
  CarouselSection,
  ColumnSection,
  ContributorHighlightSection,
  customLandingPageResponse,
  CustomPageSection,
  devLabelCheck,
  ExplorerDashboardsSection,
  getSectionObj,
  IframeSection,
  ParagraphType,
  RelatedContentSection,
  sectionResponse,
  TabsSection,
  VideoSection,
  VisualLinksSection,
} from "../etc/types";
import { ReturnStatement } from "@angular/compiler";
import { filter, map, throwIfEmpty } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MetaTagsService } from "src/app/services/meta-tags.service";

@Component({
  selector: "app-custom-landing-page",
  templateUrl: "./custom-landing-page.component.html",
  styleUrls: [
    "./custom-landing-page.component.scss",
    "../etc/style.scss",
    "../etc/food-system-campaign-header-colors.scss",
  ],
})
export class CustomLandingPageComponent implements OnInit {
  @ViewChild("pano") panoElement: ElementRef;
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.tabNavHeight = this.getTabNavHeight() + "px";
  }
  @HostListener("window:scroll", ["$event"])
  onScroll() {
    if (!this.tabNav) return;
    this.tabNavStuck =
      this.tabNav.nativeElement.offsetTop <= 66 + window.scrollY;
  }
  @ViewChild("tabNav") tabNav;
  @Input() name: string;
  tabNavHeight = "32px";
  tabNavStuck = false;
  title: string;
  description: string;
  headerImage: string;
  panoImg: any;
  panoId: string;
  icon: string;
  pano: any;
  panoOpened: boolean = false;
  tabs: {
    body: sectionResponse[];
    banner_buttons: any;
    banner_text: string;
    banner_title: string;
    footer_text: string;
    footer_title: string;
    label: string;
    link_override: string;
    sectionObjs?: CustomPageSection[];
    sectionObj?: CustomPageSection;
    double_click_link: any;
    sectionIds: string;
    sections: sectionResponse[];
  }[];
  currentTabIndex = 0;
  accentColor: string;
  ready: boolean = false;

  get currentTab() {
    return this.tabs ? this.tabs[this.currentTabIndex] : null;
  }

  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sanitizer: DomSanitizer,
    private _titler: TitleService,
    private _metaTags: MetaTagsService
  ) {
    // this._router.onSameUrlNavigation = "reload";
    this._route.data.subscribe((res) => {
      if (res.name) {
        this.name = res.name;
        this.loadContent();
      }
    });
    this._route.params.subscribe((res) => {
      if (res.name) {
        this.name = res.name;
        this.loadContent();
      }
    });
    // this._router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     this.name = this._route.snapshot.paramMap.get("name");
    //   });
    // console.log("name: ", this.name);
    // this._api
    //   .getDrupalCustomLandingPageByName(this.name)
    //   .subscribe((content: any[]) => {
    //     console.log(content);
    //   });
  }

  ngOnInit(): void {
    // console.log(detect());
  }
  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    this.onResize();
    this.onScroll();
  }
  loadContent() {
    this._api
      .getDrupalCustomLandingPageByName(this.name)
      .subscribe((content: any[]) => {
        if (content[0]) {
          let res = content[0];
          this.title = res.title;
          this.description = res.description;
          this.headerImage = res.headerImage;
          this.icon = res.icon;
          this.tabs = res.sections;
          this.accentColor = res.accent_color;
          this.panoId = res.panorama_id;
          if (this.panoId && this.panoId.length > 0) {
            console.log(this.panoId);
            this.panoImg = this._api
              .getPanorama(this.panoId)
              .pipe(map((res: any) => "url(" + res.entranceImageURL + ")"));
          }
          this.tabs = this.tabs.filter((tab) => {
            return devLabelCheck(tab.label);
          });
          for (let tab of this.tabs) {
            if (tab.banner_buttons) {
              tab.banner_buttons = JSON.parse(
                `[${
                  typeof tab.banner_buttons == "string"
                    ? tab.banner_buttons
                    : JSON.stringify(tab.banner_buttons)
                }]`
              );
              tab.banner_buttons.forEach((element) => {
                element.url = element.url.replace("internal:", "");
              });
            } else tab.banner_buttons = [];

            if (tab.double_click_link) {
              if (
                typeof tab.double_click_link == "string" &&
                tab.double_click_link.length > 0
              )
                tab.double_click_link = JSON.stringify(tab.double_click_link);

              tab.double_click_link.url = tab.double_click_link.url.replace(
                "internal:",
                ""
              );
            }

            if (!tab.sections || tab.sections.length == 0) tab.sections = [];

            tab.sectionObjs = [];
            for (let tabData of tab.sections) {
              let obj = getSectionObj(tabData);

              // Run necessary setups
              if (obj.type == ParagraphType.Carousel)
                (obj as CarouselSection).setup(this._api);
              else if (obj.type == ParagraphType.Video)
                (obj as VideoSection).setup(this._sanitizer);
              else if (obj.type == ParagraphType.Contributor_Highlight)
                (obj as ContributorHighlightSection).setup(this._api);
              else if (obj.type == ParagraphType.Visual_Links)
                (obj as VisualLinksSection).setup(this._api);
              else if (obj.type == ParagraphType.Column)
                (obj as ColumnSection).setup(this._api, this._sanitizer);
              else if (obj.type == ParagraphType.Tabs)
                (obj as TabsSection).setup(
                  this._api,
                  this._sanitizer,
                  this.title,
                  []
                );
              else if (obj.type == ParagraphType.Cards)
                (obj as CardsSection).setup(
                  this._api,
                  this._sanitizer,
                  this.title,
                  []
                );
              else if (obj.type == ParagraphType.Related_Content)
                (obj as RelatedContentSection).setup([], this.title);
              else if (obj.type == ParagraphType.Iframe)
                (obj as IframeSection).setup(this._sanitizer);
              else if (obj.type == ParagraphType.Explorer_Dashboards)
                (obj as ExplorerDashboardsSection).setup(this._api);

              tab.sectionObjs.push(obj);
            }
          }
          this._titler.setPageTitle(this.title);
          this._metaTags.updateTitle(this.title);
          if (this.description && this.description.length > 0)
            this._metaTags.updateDescription(this.description);
          if (this.headerImage && this.headerImage.length > 0)
            this._metaTags.updateImageTags(this.headerImage);

          this.ready = true;
        } else {
          window.location.href = "/404";
        }
      });
  }
  onTabClick(index) {
    if (this.tabs[index].link_override.length == 0)
      this.currentTabIndex = index;
  }
  onTabDblClick(index) {
    if (this.tabs[index].double_click_link)
      window.location.href = this.tabs[index].double_click_link.url;
  }
  getTabNavHeight() {
    if (!this.tabNav) return 32;
    return this.tabNav.nativeElement.clientHeight + 32;
  }

  setup() {}

  efieldsInteractiveTabClick(link) {
    if (detect().name == "safari" || detect().name.includes("ios"))
      alert(
        "Error message while using an older version of Safari on your iPad? Go to iPad Settings > Safari > Advanced > Experimental Features. Find the option “Resize Observer” and turn on. This should allow you to view our interactive tool as it was intended."
      );

    window.location.href = link;
  }

  openPano() {
    this.panoOpened = true;
    let element = this.panoElement.nativeElement;
    // Supports most browsers and their versions.
    var requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
      //@ts-ignore
    } else if (typeof window.ActiveXObject !== "undefined") {
      // Older IE.
      //@ts-ignore
      var wscript = new ActiveXObject("WScript.Shell");
      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }
  }
}
