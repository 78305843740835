<div class="hero-slider" *ngIf="heroCarousel.length">
  <swiper #swiper [config]="config" class="full-width">
    <ng-template swiperSlide *ngFor="let entry of heroCarousel">
      <div class="slide" (click)="toggleEntry(entry)">
        <div class="image-container">
          <img [src]="entry.image" />
  <button (click)="slideNext($event)" class="right-button">
    <i class="fas fa-chevron-right"></i>
  </button>
        </div>
        <div class="description-container">
					<h2 class="title">{{entry.title}}</h2>
          <p class="description">{{ entry.description }}</p>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
