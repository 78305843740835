import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
	@Input() quoteText: string;
	@Input() quoteSource: string;
	@Input() quoteTitle: string;

  constructor() { }

  ngOnInit(): void {
  }

}
