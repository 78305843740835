import {
  AfterViewInit,
	ChangeDetectorRef,
  Component,
  Inject,
	OnChanges,
  OnInit,
	SimpleChange,
	SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { DataSet, StructuredDataSet } from "src/app/shared/models/dataSet";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatAccordion } from "@angular/material/expansion";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import {KxLogoComponent} from 'src/app/shared/components/kx-logo/kx-logo.component';
import {KxLoggingService} from 'src/app/services/kx-logging.service';
import {DataSetComponent} from '../data-set/data-set.component';
import { TitleService } from "src/app/services/title.service";

enum SelectionType {
  CAMPAIGN = "CAMPAIGN",
  CAMPAIGN_DOMAIN = "CAMPAIGN_DOMAIN",
  FEATURE_TYPE = "FEATURE_TYPE",
  GEOGRAPHIC_LEVEL = "GEOGRAPHIC_LEVEL",
  GEOGRAPHIC_EXTENT = "GEOGRAPHIC_EXTENT",
  STATUS = "STATUS",
}

export interface DataSetEntry {
  title: string;
  subject: string;
  topic: string;
  timeStart: Date;
  timeEnd: Date;
  hostedUrl: String;
  hostedUrlType: String;
  assignee: string;
  source: String;
  credit: String;
}

interface ITableFilter {
  column: string;
  value: any;
  parent?: string;
}

interface IFilterOption {}
interface IFilterOptionObject {
  Subject: {
    Name: string;
    Topics: string[];
  }[];
  Geographic_Level: {
    Name: string;
    Geographic_Extent: string[];
  }[];
  Status: string[];
}

@Component({
  selector: "app-data-catalog",
  templateUrl: "./data-catalog.component.html",
  styleUrls: ["./data-catalog.component.scss"],
})
export class DataCatalogComponent implements OnInit, AfterViewInit, OnChanges {
  displayedColumns: string[] = [
    "Title",
    "Status",
		"lastUpdated",
    "Campaign",
    "Hosted_Source"
  ];
  constructor(
    public dialog: MatDialog,
    private api: ApiService,
    private router: Router,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private _logger: KxLoggingService
    ,private _titler:TitleService
  ) {}

  openDialog(originatorSource: string, originiatorCredit: string): void {
    const dialogRef = this.dialog.open(SourceDialog, {
      data: { source: originatorSource, credit: originiatorCredit },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  newMatDataSource: MatTableDataSource<DataSet>;
  tableFilters: ITableFilter[] = [];
  tableFilterOptions: IFilterOptionObject;
  searchString: string = "";
	doneLoading: Promise<boolean>;
	subjectFilterParamExists: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

	ngAfterViewInit() {
		console.log("view init!")
    this._titler.setPageTitle("Data Catalog")
  }

	ngOnChanges(change: SimpleChanges): void {
		console.log(change);
	}
  ngOnInit() {
			this.getDataSetsPromise()
			.then(dataSets => {
				this.constructFilterOptions(dataSets);
				this.newMatDataSource = new MatTableDataSource<DataSet>(dataSets);

			this.newMatDataSource.filterPredicate = (data: DataSet, filter: string) => {
				let match = true;
				const tableFilters: ITableFilter[] = JSON.parse(filter);
				tableFilters.forEach((tableFilter) => {
					if (tableFilter.column == "Title") {
						match =
							match &&
							data["Title"]
								.toLowerCase()
								.includes(tableFilter.value.toLowerCase());
					} else {
						match =
							match &&
							data[tableFilter.column]
								.toLowerCase()
								.indexOf(tableFilter.value.toLowerCase()) !== -1;
					}
				});
				return match;
			}
				this.activatedRoute.queryParams
				.subscribe((params) => {
					if (params.subject) {
						this.filterSubjectFromParams(params.subject)
					};
					this.doneLoading = Promise.resolve(true);
					setTimeout(() => {
			this.newMatDataSource.sort = this.sort;
			this.newMatDataSource.paginator = this.paginator;
					}, 2000);
				});

			})
    
  }

	filterSubjectFromParams(subject: string) {
		if (subject != 'cpt' && subject != 'fsm') return;
		this.subjectFilterParamExists = true;

		const filter: ITableFilter = {
			column: 'Campaign',
			value: subject == 'cpt' ? 'Community Profiles Tool' : 'Food Systems'
		}
		
		this.addTableFilter(null, filter, true)
		this.cdr.detectChanges();
	}

  filterTable(tableFilters: ITableFilter[]) {
    this.newMatDataSource.filter = JSON.stringify(tableFilters);
  }

	goToTool(tool: "Food Systems" | "Community Profiles Tool" | "") {
		if(!tool.length) return;
		event.stopPropagation();
		if (tool == "Food Systems") {
			this.router.navigate(["food-system-tool"]);
		} else if(tool == "Community Profiles Tool") {
			this.router.navigate(["profiles"]);
		}
	}

  clearTableFilters(): void {
    this.searchString = "";
    this.tableFilters = [];
    this.newMatDataSource.filter = JSON.stringify(this.tableFilters);
  }

  addTableFilter(
    event,
    newTableFilter: ITableFilter,
    topLevel?: boolean
  ): void {
    const filterExists = this.checkTableFilterExists(newTableFilter);

    if (!topLevel) event.stopPropagation();

    this.removeCurrentFiltersOnColumn(newTableFilter.column);

    if (!filterExists) {
      this.tableFilters.push(newTableFilter);
    }

    this.newMatDataSource.filter = JSON.stringify(this.tableFilters);
  }

  removeCurrentFiltersOnColumn(column: string): void {
    this.tableFilters = this.tableFilters.filter((filter) => {
      return filter.column != column && filter.parent != column;
    });
  }

  checkTableFilterExists(testFilter: ITableFilter): boolean {
    return this.tableFilters.some(
      (filter) =>
        filter.column == testFilter.column && filter.value == testFilter.value
    );
  }

  search() {
    this.tableFilters = this.tableFilters.filter(
      (filter) => filter.column != "Title"
    );

    this.tableFilters.push({ column: "Title", value: this.searchString });
    this.newMatDataSource.filter = JSON.stringify(this.tableFilters);
  }


	goToHostedSource(event: any, url: string) {
		event.stopPropagation();
		this._logger.log(url);
		window.open(url, "_blank");
		window.focus();
	}



	// called from ngInit after datasets are recived
	//
  constructFilterOptions(dataSets: DataSet[]): void {
		


    this.tableFilterOptions = {
      Subject: [],
      Geographic_Level: [],
      Status: [],
    };


		// for every dataset
    for (let dataSet of dataSets) {

      // Subject
      let subjectEntry = this.tableFilterOptions.Subject.find((subject) => {
				return subject.Name == dataSet.Campaign;
			});

      if (!subjectEntry && dataSet.Campaign.length) {
        this.tableFilterOptions.Subject.push({
          Name: dataSet.Campaign,
          Topics: [],
        });
        subjectEntry =
          this.tableFilterOptions.Subject[
            this.tableFilterOptions.Subject.length - 1
          ];
      }
      // Geographic Level
      let geographicLevelEntry = this.tableFilterOptions.Geographic_Level.find(
        (level) => level.Name == dataSet.Geographic_Level
      );
      if (!geographicLevelEntry && dataSet.Geographic_Level.length) {
        this.tableFilterOptions.Geographic_Level.push({
          Name: dataSet.Geographic_Level,
          Geographic_Extent: [],
        });
        geographicLevelEntry =
          this.tableFilterOptions.Geographic_Level[
            this.tableFilterOptions.Geographic_Level.length - 1
          ];
      }
      // Geographic Extent
      if (geographicLevelEntry) {
        let extent = geographicLevelEntry.Geographic_Extent.find(
          (extent) => extent == dataSet.Geographic_Extent
        );
        if (!extent && dataSet.Geographic_Extent.length) {
          geographicLevelEntry.Geographic_Extent.push(
            dataSet.Geographic_Extent
          );
        }
      }

      // Status
      let statusEntry = this.tableFilterOptions.Status.find(
        (status) => status == dataSet.Status
      );
      if (!statusEntry && dataSet.Status.length) {
        this.tableFilterOptions.Status.push(dataSet.Status);
      }
    }

  }

  checkAndAddFilterOption(
    filterOptionArray: IFilterOption[],
    filterOption: IFilterOption
  ): void {}

  getDataSetsPromise() {
    return this.api.getDataSets().toPromise();
  }

  goToMetaData(dataset: DataSet) {
		console.log(dataset);
		let datasetDialogRef = this.dialog.open(
			DataSetComponent, 
			{
				width: "90vw",
				height: "90vh",
				data: {
					dataset: dataset
				}
			}
	)	
		datasetDialogRef.afterOpened().subscribe(() => console.log("opened"));
		datasetDialogRef.afterClosed().subscribe(() => console.log("closed"));
		
  }
}

@Component({
  selector: "source-dialog",
  templateUrl: "source-dialog.html",
})
export class SourceDialog {
  constructor(
    public dialogRef: MatDialogRef<DataCatalogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
