import {
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { contentListItem } from "../util";
import { Observable } from "rxjs-compat";
import SwiperCore, { A11y, Swiper } from "swiper/core";
SwiperCore.use([A11y]);

const BREAKPOINTS = [
  { sliderSize: 5, minWidth: 1200, maxWidth: 99999 },
  { sliderSize: 4, minWidth: 900, maxWidth: 1200 },
  { sliderSize: 3, minWidth: 700, maxWidth: 900 },
  { sliderSize: 2, minWidth: 500, maxWidth: 700 },
  { sliderSize: 1, minWidth: 0, maxWidth: 500 },
];
@Component({
  selector: "app-text-slider",
  templateUrl: "./text-slider.component.html",
  styleUrls: ["./text-slider.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TextSliderComponent {
  @Input() items: Observable<contentListItem[]>;
  swiper: Swiper;

  constructor(private cdr: ChangeDetectorRef) {}

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
    this.swiper.onAny(() => this.cdr.detectChanges());
  }
  navigateTo(i: number) {
    this.swiper.slideTo(i);
  }
  slidesPerView() {
    let elWidth = this.swiper?.$el[0].clientWidth ?? 0;

    return BREAKPOINTS.find(
      (bkpt) => bkpt.minWidth <= elWidth && bkpt.maxWidth > elWidth
    ).sliderSize;
  }
}
