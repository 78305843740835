export const DEFAULT_SIZES = {
	map: {
		rows: 15,
		cols: 15,
	},
	chart: {
		rows: 15,
		cols: 25,
	},
	value: {
		rows: 15,
		cols: 15
	},
	text: {
		rows: 15,
		cols: 15
	},
	legend: {
		rows: 15,
		cols: 10 
	}
}

export const DEFAULT_TITLE = "Enter Title";
export const DEFAULT_SUB_TITLE = "Enter Sub-heading";

export const MULTI_COLORS = ["#737b7e", "#830065", "#6ebbab", "#b04558", "#ffb600", "#0e4b52", "#e65f33", "#80c75b", '#3ba4cf', '#684327'];

export const MULTI_BACKGROUND_COLORS = [
    "#d7dfe2",
    "#e764c9",
    "#d2ffff",
    "#ffa9bc",
    "#ffcf00",
    "#72afb6",
    "#ffc397",
    "#e4c7bf",
    "#9fa4ff",
    "#cca78b"
];

export const ChartBackgroundColors = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(70, 99, 132, 0.2)",
  "rgba(100, 140, 50, 0.2)",
  "rgba(130, 120, 90, 0.2)",
  "rgba(160, 60, 130, 0.2)",
  "rgba(190, 80, 170, 0.2)",
  "rgba(220, 100, 210, 0.2)",
  "rgba(9, 105, 108, 0.3)",
  "rgba(115, 122, 52, 0.3)",
  "rgba(227, 67, 37, 0.3)",
  "rgba(17, 121, 211, 0.3)",
  "rgba(54, 156, 169, 0.3)",
  "rgba(68, 216, 104, 0.3)",
  "rgba(32, 48, 113, 0.3)",
  "rgba(197, 147, 116, 0.3)",
  "rgba(133, 73, 191, 0.3)",
];

export const ChartBorderColors = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(70, 99, 132, 1)",
  "rgba(100, 140, 50, 1)",
  "rgba(130, 120, 90, 1)",
  "rgba(160, 60, 130, 1)",
  "rgba(190, 80, 170, 1)",
  "rgba(220, 100, 210, 1)",
  "rgba(9, 105, 108, 1)",
  "rgba(115, 122, 52, 1)",
  "rgba(227, 67, 37, 1)",
  "rgba(17, 121, 211, 1)",
  "rgba(54, 156, 169, 1)",
  "rgba(68, 216, 104, 1)",
  "rgba(32, 48, 113, 1)",
  "rgba(197, 147, 116, 1)",
  "rgba(133, 73, 191, 1)",
];
