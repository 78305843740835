import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
  Swiper,
} from "swiper/core";
import { SwiperComponent } from "swiper/angular";
SwiperCore.use([
  A11y,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  Keyboard,
  Lazy,
]);
@Component({
  selector: "app-landing-page-header",
  templateUrl: "./landing-page-header.component.html",
  styleUrls: ["./landing-page-header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageHeaderComponent implements OnInit {
  swiper: Swiper;
  headers: Observable<any>;
  constructor(private _api: ApiService) {
    this.headers = this._api
      .getDrupalLandingPageHeaders()
      .map((headers: any[]) => {
        headers.forEach((h) => (h.bgStyle = this.getBackground(h.image)));
        return headers;
      });
  }

  ngOnInit(): void {
    console.log(this.swiper);
  }

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
  }
  getBackground(img) {
    return getBackgroundImageStyles(0.4, img);
  }
  searchFocused() {
    this.swiper.autoplay.stop();
  }
}
