import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatAccordion, MatExpansionPanel } from "@angular/material/expansion";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { first } from "rxjs/operators";
import { QuestionAndAnswers } from "../../custom-stuff/etc/types";

export type SwiperItem = { index: number; image: string; description: string };
const breakpointPx = 800;

@Component({
  selector: "accordion-qa",
  templateUrl: "./accordion-qa.component.html",
  styleUrls: ["./accordion-qa.component.scss"],
})
export class AccordionQaComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren("panel") expansionPanels: QueryList<MatExpansionPanel>;
  @ViewChild("accordion") accordion: MatAccordion;
  @Input() content: Observable<any>;
  @Input() label: string;
  @Input() labelFontSize: string;
  @Input() questionsAndAnswers: QuestionAndAnswers[];
  @Input() heroCarousel: boolean;
  @Input() autoExpandFirstEntry: boolean;
  @Input() entryClicked: BehaviorSubject<any>;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.mobile = event.target.innerWidth <= breakpointPx;
  }
  mobile: boolean = false;
  passedUriFragment: string;
  matchedIndexFromFragment: number = -1;
	disableNextScroll: boolean = false;

  constructor(private _route: ActivatedRoute) {
    this._route.fragment.subscribe((fragment) => {
      this.passedUriFragment = fragment;
    });
  }

  ngOnInit(): void {
    this.mobile = window.innerWidth <= breakpointPx;
    this.entryClicked.subscribe(
      (res: { accordionName: string; index: number }) => {
        if (res.accordionName == this.label) {
          this.toggleAccordion(res.index, 200);
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const qaChanges = changes["questionsAndAnswers"];
    if (this.passedUriFragment && qaChanges && qaChanges.currentValue) {
      const qas = changes["questionsAndAnswers"].currentValue;
      this.matchedIndexFromFragment = qas.findIndex(
        (qa) => qa.qFragment === this.passedUriFragment
      );
    }
  }

  ngAfterViewInit() {
    this.expansionPanels.changes.subscribe(
      (epChanges: QueryList<MatExpansionPanel>) => {
        if (epChanges.length == 0) return;
        if (this.matchedIndexFromFragment >= 0) {
          this.toggleAccordion(this.matchedIndexFromFragment);
        } else if (this.autoExpandFirstEntry) {
					this.disableNextScroll = true;
					this.toggleAccordion(0);
				}
      }
    );
  }

  toggleAccordion(index: number, delay?: number) {
    let expansionPanel = this.expansionPanels.find((panel, i) => index === i);
    if (!expansionPanel) return;
    expansionPanel.open();
  }

  scrollToTop(index: number) {
		if (this.disableNextScroll) {
			this.disableNextScroll = false;
			return;
		}
    let targetPanel = this.expansionPanels.find((panel, i) => index === i);
    if (!targetPanel) return;
    const headerOffset = this.mobile ? 190 : 120;
    const panelElement = document.getElementById(targetPanel.id);
    const elementPosition = panelElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}
