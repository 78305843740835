<div #contentSlider *ngIf="!empty">
  <h2 *ngIf="header">{{ header }}</h2>
  <div class="horizontal-slider">
    <button
      id="left"
      (click)="prev()"
      [disabled]="atStartPosition()"
      alt="previous"
      aria-label="show previous"
    >
      <i class="fas fa-chevron-left"></i>
    </button>
    <button
      id="right"
      (click)="next()"
      [disabled]="atEndPosition()"
      alt="next"
      aria-label="show next"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
    <div
      class="slider-item-container"
      *ngFor="let item of items"
      [ngClass]="item.visible ? 'visible' : 'hidden'"
      [tabindex]="item.visible ? '' : '-1'"
      [ngStyle]="item.background"
    >
      <div class="inner-box" [attr.aria-hidden]="!item.visible">
        <span class="content-title"> {{ item.title }} </span>
        <!-- <span class="content-date"> {{ item.date }} </span> -->
        <div class="content-desc-container" *ngIf="item.description">
          <span class="content-desc"> {{ item.description }}</span>
        </div>
      <!-- Link to more information -->
      <div class="center">
        <a
        class="osu-button"
        target="_blank"
        [href]="item.link"
        [attr.aria-label]="'Read Story :' + item.title"
        [tabindex]="item.visible ? '' : '-1'"
        >
        <i [class]="item.icon"></i>
            Learn More</a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="markers">
    <ng-container *ngFor="let item of items">
      <div [ngClass]="{ on: item.visible }"></div>
    </ng-container>
  </div>
</div>
