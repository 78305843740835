import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML'
})
export class DomSanitizerPipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) {}

  transform(value: string): any {
		if (!value) return '';
		return this.sanitized.bypassSecurityTrustHtml(value)

  }

}
