import { Component, OnInit } from '@angular/core';
import {WidgetInput} from 'src/app/explorer/types/types';
import {ExplorerCartService} from 'src/app/services/explorer-cart.service';

@Component({
  selector: 'app-explorer-cart',
  templateUrl: './explorer-cart.component.html',
  styleUrls: ['./explorer-cart.component.scss']
})
export class ExplorerCartComponent implements OnInit {

  constructor(private _cart: ExplorerCartService) { }

  ngOnInit(): void {
  }

	get cart(): WidgetInput[] {
		return this._cart.cart;
	}

	checkout() {
		this._cart.checkoutToExplorer();
	}

	removeFromCart(event: any, itemName: string) {
		event.stopPropagation();
		this._cart.removeFromCart(itemName);
	}

}
