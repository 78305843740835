import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-searchbar",
  template: ` <div class="searchbar">
    <label class="search-submit-label" for="search-submit"
      >Submit search request</label
    >
    <button
      class="search-submit"
      type="button"
      class="btn"
      (click)="search()"
      aria-label="submit search"
    >
      <i class="fa fa-search" aria-hidden="true"></i>
    </button>
    <label class="search-label" for="search" *ngIf="!hidden"
      >Search the Knowledge Exchange</label
    >
    <input
      class="search"
      type="text"
      [(ngModel)]="searchTerm"
      (change)="setLabel()"
      (keyup.enter)="search()"
      (focus)="setLabel(true)"
      (focusout)="setLabel(false)"
    />
  </div>`,
  styleUrls: ["./searchbar.component.scss"],
})
export class SearchBarComponent {
  searchTerm: string = "";
  hidden: boolean = false;

  constructor(private router: Router) {}

  search() {
    window.location.href = `/search?q=${this.searchTerm}`
  }

  setLabel(hide?: boolean): void {
    this.hidden = this.searchTerm.length > 0 || hide;
  }
}
