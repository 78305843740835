<div class="text-slider">
  <swiper [slidesPerView]="slidesPerView()" (swiper)="onSwiper($event)" [centerInsufficientSlides]="true">
    <ng-template swiperSlide *ngFor="let item of items | async">
      <a
        class="text-slide"
        [href]="item.link"
        [ngClass]="{ 'small-text': item.title.length > 64 }"
        target="_parent"
        [attr.aria-label]="item.title"
      >
        <span>
          {{ item.title }}
        </span>
      </a>
    </ng-template>
  </swiper>

  <button
    (click)="navigateTo(swiper.activeIndex - 1)"
    class="prev-btn"
    *ngIf="swiper?.activeIndex != 0"
  >
    <app-slider-arrow-icon direction="left"></app-slider-arrow-icon>
  </button>
  <button
    (click)="navigateTo(swiper.activeIndex + 1)"
    class="next-btn"
    *ngIf="!swiper?.isEnd"
  >
    <app-slider-arrow-icon direction="right"></app-slider-arrow-icon>
  </button>
</div>
<div class="pagination">
  <ng-container *ngFor="let x of items | async; index as i">
    <div
      *ngIf="
        i >= swiper?.activeIndex + slidesPerView() || i <= swiper?.activeIndex
      "
      class="indicator"
      [ngClass]="{ on: i == swiper?.activeIndex }"
      (click)="navigateTo(i)"
    ></div>
  </ng-container>
</div>
