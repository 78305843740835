<div class="icon-slider">
  <swiper
    [slidesPerView]="slidesPerView()"
    (swiper)="onSwiper($event)"
    [ngClass]="{ 'full-width': swiper?.isBeginning && swiper?.isEnd }"
  >
    <ng-template swiperSlide *ngFor="let item of items | async">
      <a class="icon-slide" [attr.href]="item.link" target="_parent" [attr.aria-label]="item.title">
        <div class="icon" *ngIf="item.kxIcon">
          <kx-icon size="xl" [id]="item.kxIcon"></kx-icon>
        </div>
        <div
          *ngIf="!item.kxIcon"
          class="img"
          [ngStyle]="getImgStyle(item.imageUrl)"
        ></div>
        <div class="text">
          <span
            class="head"
            [ngClass]="{ 'small-text': item.title.length > 64 }"
          >
            {{ item.title }}
          </span>
          <span class="subhead">
            {{ item.description }}
          </span>
        </div>
      </a>
    </ng-template>
  </swiper>

  <button
    (click)="navigateTo(swiper.activeIndex - 1)"
    class="prev-btn"
    *ngIf="!swiper?.isBeginning"
  >
    <app-slider-arrow-icon direction="left"></app-slider-arrow-icon>
  </button>
  <button
    (click)="navigateTo(swiper.activeIndex + 1)"
    class="next-btn"
    *ngIf="!swiper?.isEnd"
  >
    <app-slider-arrow-icon direction="right"></app-slider-arrow-icon>
  </button>
</div>
