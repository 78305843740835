import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs/Rx";
import { sliderItem } from "src/app/shared/models/sliderItem";
import { backgroundStyle } from "src/app/shared/models/backgroundStyle";
import {
  formatDate,
  getBgStyle,
  getDate,
  getIcon,
  getLink,
  sortByDate,
} from "../util";

// Slider Configuration
const STEP_SIZE = 1; //(# of items on button click)
const BREAKPOINTS = [
  { sliderSize: 4, minWidth: 1950, maxWidth: 99999 },
  { sliderSize: 3, minWidth: 1100, maxWidth: 1950 },
  { sliderSize: 2, minWidth: 700, maxWidth: 1100 },
  { sliderSize: 1, minWidth: 0, maxWidth: 700 },
];

@Component({
  selector: "app-content-slider",
  templateUrl: "./content-slider.component.html",
  styleUrls: ["./content-slider.component.scss"],
})
export class ContentSliderComponent implements AfterViewInit, OnChanges {
  @Input() contentObservable: Observable<Object>;
  @Input() joinedObservable: boolean = false;
  @Input() header?: string;
  @ViewChild("contentSlider") DOMElement: ElementRef;

  items: sliderItem[] = [];
  currentSize: number;
  itemLimit: number;
  empty: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.itemLimit = this.joinedObservable == true ? 12 : 100;
    /*                                   ^^^^^^
    I swear I'm not stupid and I know how booleans work, but 
    for some reason (this.joinedObservable) alone always evaluates 
    to true unless I add the " == true" part
    - Ben 
    */
    this.items = [];
    this.loadItems();
    this.ngAfterViewInit();
  }

  ngAfterViewInit(): void {
    if (!this.empty) {
      this.fitSliderToScreen();
      this.addResizeEventListener();
    }
  }

  next = () => (this.items = this.step(this.items, "forward"));
  prev = () => (this.items = this.step(this.items, "backward"));

  atStartPosition() {
    return this.items.length > 0 && this.items[0].visible;
  }
  atEndPosition() {
    return this.items.length > 0 && this.items[this.items.length - 1].visible;
  }

  step(items: sliderItem[], direction: "forward" | "backward"): sliderItem[] {
    if (direction == "backward")
      return this.step(items.reverse(), "forward").reverse();

    let [itemsHidden, itemsShown] = [0, 0];
    items.forEach((el) => {
      // Hide STEP_SIZE number of visible items.
      if (itemsHidden < STEP_SIZE) {
        itemsHidden = el.visible ? itemsHidden + 1 : itemsHidden;
        el.visible = false;
      }
      // Then, un-hide STEP_SIZE number of hidden items.
      else if (itemsShown < STEP_SIZE) {
        itemsShown = el.visible ? itemsShown : itemsShown + 1;
        el.visible = true;
      }
    });

    return items;
  }

  addResizeEventListener() {
    window.addEventListener("resize", this.fitSliderToScreen);
  }

  fitSliderToScreen = () => {
    let sizeWasChanged = this.updateSliderSize();
    if (sizeWasChanged)
      this.items.forEach((el, i) => (el.visible = i < this.currentSize));
  };

  updateSliderSize() {
    if (this.DOMElement) {
      let oldSize = this.currentSize;
      let elWidth = this.DOMElement.nativeElement.offsetWidth;

      this.currentSize = BREAKPOINTS.find(
        (bkpt) => bkpt.minWidth <= elWidth && bkpt.maxWidth > elWidth
      ).sliderSize;

      return this.currentSize != oldSize;
    } else return false;
  }

  addItem(rawItem: any) {
    let description = rawItem.description
      ? this.removeHtmlEntities(rawItem.description)
      : null;

    this.items.push({
      visible: false,
      link: getLink(rawItem),
      background: getBgStyle(rawItem.headerImage),
      title: this.removeHtmlEntities(rawItem.title),
      icon: getIcon(rawItem.type),
      date: getDate(rawItem),
      description: description,
    });
  }

  loadItems() {
    this.contentObservable.subscribe((results: any[]) => {
      this.empty = results.length < 1;

      if (this.empty)
        window.removeEventListener("resize", this.fitSliderToScreen);

      if (this.joinedObservable) results = [].concat(...results);

      results.forEach((el, i) => {
        if (i < this.itemLimit) this.addItem(el);
      });
      this.items = sortByDate(this.items);

      this.items.forEach((el, i) => (el.visible = i < this.currentSize));
    });
  }

  getWidth(inner?: "inner") {
    let elWidth = this.DOMElement.nativeElement.offsetWidth;
    return (0.83 / this.currentSize) * elWidth;
  }

  removeHtmlEntities(str: string) {
    if (str == "") return "";
    var txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }
}
