<div class="callout-{{ color }} {{ side }}">
  <a
    class="text-side"
    [attr.href]="link"
    [ngClass]="{ indicateHover: link || indicateHover }"
    target="_parent"
    [attr.aria-label]="head"
  >
    <div class="text-side-container text" *ngIf="head || subhead; else custom">
      <span
        class="head"
        [innerHTML]="head"
        [ngClass]="{ 'small-text': head && head.length > 64 }"
      ></span>
      <span class="subhead" [innerHTML]="subhead" *ngIf="subhead"></span>
      <span class="material-icons">east</span>
    </div>
    <ng-template #custom>
      <div class="text-side-container custom">
        <ng-content></ng-content>
        <span class="material-icons" *ngIf="link">east</span>
      </div>
    </ng-template>
  </a>
  <div
    class="image-side"
    [ngStyle]="getImageStyle()"
    [attr.aria-label]="head"
  ></div>
</div>
