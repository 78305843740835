<div class="music-tool row">
  <div
    id="waveform-wrapper"
    class="col-md-8"
    [ngClass]="{ loading: state == 'loading' }"
  >
    <app-play-button
      *ngIf="!waveSurfer || state == 'initialized' || state == 'loading'"
      [loading]="state == 'initializing' || state == 'loading'"
      (click)="generateWaveform()"
      [disabled]="!generateable"
    ></app-play-button>
    <div id="message" *ngIf="message">{{ message }}</div>
    <!-- <app-loading-indicator></app-loading-indicator> -->
    <div id="waveform"></div>
    <div id="controls">
      <button
        class="btn btn-light m-1"
        (click)="state == 'playing' ? pause() : state == 'paused'? play() : generateWaveform() "
        [disabled]="!generateable"
      >
      <i class="fas fa-pause" *ngIf="state=='playing'"></i>
      <i class="fas fa-play" *ngIf="state=='paused'"></i>
      <span *ngIf="state != 'playing' && state != 'paused'">Start</span>
      </button>
      <button
        class="btn btn-light m-1"
        (click)="reset()"
        [disabled]="disableResetBtn"
      >
        Reset
      </button>
      <!-- <button
        class="btn btn-dark m-1"
        (click)="generateWaveform()"
        [disabled]="disableGenerateBtn"
      >
        {{ state == "initializing" ? "Loading..." : "Generate" }}
      </button> -->
    </div>
  </div>

  <div id="musical-elements" class="col-md-4">
    <span id="good-label">Opportunities</span>
    <ng-container *ngFor="let el of elements; index as i">
      <button
      *ngIf="el.sentiment == 'good'"
      [class]="el.sentiment"
      (click)="toggle(i)"
      [ngClass]="{ active: audioQueue.includes(i) }"
      [matTooltip]="el.instrument"
      matTooltipPosition="above"
      (mouseenter)="setMessage(el.description)"
      (mouseleave)="resetMessage()"
      [disabled]="state=='loading'"
      >
      {{ el.name }}
    </button>
  </ng-container>

  <span id="bad-label">Obstacles</span>
    <ng-container *ngFor="let el of elements; index as i">
      <button
        *ngIf="el.sentiment == 'bad'"
        [class]="el.sentiment"
        (click)="toggle(i)"
        [ngClass]="{ active: audioQueue.includes(i) }"
        [matTooltip]="el.instrument"
        matTooltipPosition="above"
        (mouseenter)="setMessage(el.description)"
        (mouseleave)="resetMessage()"
        [disabled]="state=='loading'"
      >
        {{ el.name }}
      </button>
    </ng-container>
  </div>
</div>
