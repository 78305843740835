import { Injectable } from '@angular/core';
const OSU_CATEGORICAL_COLORS = ["#737b7e", "#830065", "#6ebbab", "#b04558", "#ffb600", "#0e4b52", "#e65f33", "#80c75b", '#3ba4cf', '#684327'];


@Injectable({
  providedIn: 'root'
})
export class HexColorGeneratorsService {

  constructor() { }

	get osuCategoricalColors() {
		return OSU_CATEGORICAL_COLORS;
	} 

	lightenHexColor(hexColor: string, lightenHexValue: string) {
		const hexre = new RegExp('[0-9a-fA-F]{2}', 'g');
		let colorMatches = hexColor.match(hexre);
		if(colorMatches.length != 3) return hexColor;
		let lightenValue = parseInt(lightenHexValue, 16);
		let newColors = colorMatches.map((c) => {
			let hexValue = parseInt(c, 16)
			let newHexValue = hexValue + lightenValue;
			return newHexValue >= 0 && newHexValue <= 255 ?
				newHexValue.toString(16) :
				hexValue.toString(16);
		});
		let newColor = `#${newColors.join('')}`
		console.log(newColor)
 
		return `#${newColors.join('')}`
	}


	

}
