<ng-container *ngIf="!overridden; else customPageOverride">
  <ng-container *ngIf="contentLoaded; else loading">
    <div class="page-background">
      <div class="theme">
        <div *ngIf="contentLoaded">
          <div class="topic-header row">
            <ng-container *ngIf="topicImage.length > 0; else noImage">
              <div class="col-sm-5 text-right">
                <img [src]="topicImage" [alt]="topicName" />
              </div>
              <div class="col-sm-7">
                <h1>{{ topicName }}</h1>
                <app-subscribe-dialog
                  [selectedTopics]="[topicName]"
                ></app-subscribe-dialog>
              </div>
            </ng-container>
            <ng-template #noImage>
              <div class="col-12 text-center">
                <h1>{{ topicName }}</h1>
                <app-subscribe-dialog
                  [selectedTopics]="[topicName]"
                ></app-subscribe-dialog>
              </div>
            </ng-template>
          </div>
          <content-list
            [content]="mainCarouselObservable"
            type="callout"
          ></content-list
          ><br />
          <content-list
            type="text-slider"
            [content]="questionsObservable"
            head="Questions"
          ></content-list
          ><br /><br />
          <content-list
            type="image-slider"
            [content]="videosObservable"
            head="Videos"
          ></content-list
          ><br /><br />
          <content-list
            type="image-slider"
            [content]="podcastsObservable"
            head="Podcasts"
          ></content-list
          ><br /><br />
          <content-list
            type="image-slider"
            [content]="knowChangeObservable"
            head="Know. Change. Notes"
          ></content-list
          ><br /><br />
          <content-list
            type="image-slider"
            [content]="linksObservable"
            head="Links"
          ></content-list
          ><br /><br />
          <content-list
            type="image-slider"
            [content]="filesObservable"
            head="Reports and PDFs"
          ></content-list
          ><br /><br />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="p-5 m-5">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </ng-template>
</ng-container>
<ng-template #customPageOverride>
  <app-custom-page
    [nodeID]="customPageOverrideNodeID"
    [topicOverride]="topicName"
  ></app-custom-page>
</ng-template>
