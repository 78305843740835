import { Component, Input, OnInit } from "@angular/core";
import { getBackgroundImageStyles } from "../../functions/ng-style-utils";

@Component({
  selector: "app-full-page-image-header",
  templateUrl: "./full-page-image-header.component.html",
  styleUrls: ["./full-page-image-header.component.scss"],
})
export class FullPageImageHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() headerImage: string;
  @Input() topics?: { name: string; url: string }[] = [];
  @Input() description: string;
  @Input() hideArrow: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  getHeaderBackground() {
    return getBackgroundImageStyles(0.5, this.headerImage);
  }
}
