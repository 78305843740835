<div class="responsive-page-container fs-vis">
  <div class="fs-vis-intro">
    <h1 class="fs-vis-heading">The Food System</h1>
    <p>
      Strengthening our food system involves all of us. Each section reveals
      food’s journey from farms to our table and highlights the world-class
      research from the College of Food, Agricultural, and Environmental
      Sciences (CFAES) that is essential to our mission—we sustain life. Click
      <button
        class="link-button"
        (click)="openInstructions()"
        (keyup.enter)="openInstructions()"
        tabindex="0"
      >
        here
      </button>
      for instructions on how to use this graphic.
    </p>
    <div class="simple-view-toggle-section">
      <span
        (click)="updateView(true)"
        (keyup.enter)="updateView(true)"
        tabindex="0"
        aria-label="toggle simple view"
      >
        Been here before? Click here for a simple view of our food system and
        quick access to each topic page.
      </span>
      <mat-slide-toggle
        #viewToggle
        color="warn"
        (change)="updateView()"
        tabindex="0"
      ></mat-slide-toggle>
    </div>
  </div>

  <div class="simple-view" *ngIf="viewMode == 'simple'; else default">
    <app-simple-visualization></app-simple-visualization>
  </div>
  <ng-template #default>
    <div class="default-view">
      <div class="fs-vis-sect domain collapsed community" #community>
        <h2
          class="fs-domain-header"
          (click)="community.classList.toggle('collapsed')"
          (keyup.enter)="community.classList.toggle('collapsed')"
          tabindex="0"
        >
          <i class="fas fa-bars"></i> Community
          <div
            class="sneaky-little-square-to-make-the-background-look-right"
            aria-hidden="true"
          ></div>
        </h2>
        <div class="content">
          <p>
            In this campaign, <strong>community</strong> includes all the people
            involved in food—like farmers growing our food, workers in the
            processing plant, truck drivers hauling cargo, grocers stocking
            shelves, and consumers enjoying the bounty. We all play a role in
            the equitable distribution of food to ensure everyone has access to
            fresh, healthy foods. <br /><br />
            Collaboration often happens at the local level with grassroots
            organizations and community coalitions. What can improve food chain
            worker well-being? How do we define “local food,” and can we
            quantify the value to a community? Who needs to address food
            insecurity? And how do you heal a neighborhood through urban
            farming?<br />
            <button
              routerLink="/page/fs-community"
              mat-flat-button
              color="primary"
            >
              Learn More
            </button>
          </p>
          <app-image-map-infographic
            displayType="modal"
            infographicTitle="fs-community"
          ></app-image-map-infographic>
        </div>
      </div>
      <div class="fs-vis-sect domain collapsed economics" #economics>
        <h2
          class="fs-domain-header"
          (click)="economics.classList.toggle('collapsed')"
          (keyup.enter)="economics.classList.toggle('collapsed')"
          tabindex="0"
        >
          <i class="fas fa-bars"></i> Economics
          <div
            class="sneaky-little-square-to-make-the-background-look-right"
            aria-hidden="true"
          ></div>
        </h2>
        <div class="content">
          <p>
            Policies, politics, and market forces influence the food system at
            all levels, from local to national. The
            <strong>economics</strong> of the growing, preparing, selling, and
            disposing of food for the world influences everything from workers’
            wages to community development, commodity prices to carbon
            sequestration, and marketing tactics to supply shortages.
            <br /><br />
            Our collection of research in food system economics is limited,
            exposing opportunities for collaboration. Do farmers benefit when we
            buy local? Does processing consolidation drive down market pricing?
            What is the true cost of importing and exporting food? We look to
            our researchers for answers. <br />
            <button
              routerLink="/page/fs-economics"
              mat-flat-button
              color="primary"
            >
              Learn More
            </button>
          </p>
          <app-image-map-infographic
            displayType="modal"
            infographicTitle="fs-economics"
          ></app-image-map-infographic>
        </div>
      </div>
      <div class="fs-vis-sect domain collapsed environment" #environment>
        <h2
          class="fs-domain-header"
          (click)="environment.classList.toggle('collapsed')"
          (keyup.enter)="environment.classList.toggle('collapsed')"
          tabindex="0"
        >
          <i class="fas fa-bars"></i> Environment
          <div
            class="sneaky-little-square-to-make-the-background-look-right"
            aria-hidden="true"
          ></div>
        </h2>
        <div class="content">
          <p>
            Our activities impact the <strong>environment</strong>. From food
            production to food waste, environmental concerns weave through every
            aspect of the food system. In CFAES, research focuses on sustainable
            practices to improve the health of our soil, the purity of our
            water, and the quality of our air. <br /><br />
            Promising research seeks a balance between how we farm and keeping
            our water safe. Timing pesticides to protect pollinators. Changing
            food and animal waste disposal processes to minimize impacts. And
            dreaming up new ways to defend farms against future weather
            extremes.<br />
            <button
              routerLink="/page/fs-environment"
              mat-flat-button
              color="primary"
            >
              Learn More
            </button>
          </p>
          <app-image-map-infographic
            displayType="modal"
            infographicTitle="fs-environment"
          ></app-image-map-infographic>
        </div>
      </div>
      <div class="food-chain-container">
        <div class="food-chain">
          <div class="food-chain-overlay" #overlay></div>
          <div class="fs-vis-sect chain collapsed production" #production>
            <h2
              class="fs-sect-header"
              (click)="toggleFoodChainSection(production, overlay)"
              (keyup.enter)="toggleFoodChainSection(production, overlay)"
              tabindex="0"
            >
              <i class="fas fa-bars"></i> Production
            </h2>
            <div class="content">
              Our journey through the food system starts with
              <strong>production</strong>, the first step in the food supply
              chain. Farmers grow plants and raise livestock on farms that range
              from small family farms to large corporate operations, in
              controlled environments such as greenhouses, in community gardens,
              and even using aquaculture. <br /><br />
              Our college researchers examine ways to streamline processes to
              lower production costs and increase efficiency. The statewide
              eFields trials test cutting-edge farming techniques. Improved
              livestock management processes ensure healthy animals and quality
              products. And indoor growing systems could extend the growing
              season for local foods.<br />
              <button
                routerLink="/page/fs-production"
                mat-flat-button
                color="primary"
              >
                Learn More
              </button>
            </div>
            <app-image-map-infographic
              displayType="modal"
              infographicTitle="fs-production"
            ></app-image-map-infographic>
          </div>
          <div class="fs-vis-sect chain collapsed processing" #processing>
            <h2
              class="fs-sect-header"
              (click)="toggleFoodChainSection(processing, overlay)"
              (keyup.enter)="toggleFoodChainSection(processing, overlay)"
              tabindex="0"
            >
              <i class="fas fa-bars"></i> Processing
            </h2>
            <div class="content">
              <strong>Processing</strong> involves the preparation of raw
              products for sale in their original state or for the production of
              other food products. Think: fresh corn for roasting and ground
              corn for tortillas. Disruptions in the supply chain mean serious
              economic consequences for producers and consumers. <br /><br />
              Science plays a part in keeping our food safe, healthy, and
              nutritious. Researchers in our college scrutinize safety in the
              system for both workers and consumers, improve quality assurance,
              and boost the nutritional value of foods.<br />
              <button
                routerLink="/page/fs-processing"
                mat-flat-button
                color="primary"
              >
                Learn More
              </button>
            </div>
            <app-image-map-infographic
              displayType="modal"
              infographicTitle="fs-processing"
            ></app-image-map-infographic>
          </div>
          <div class="fs-vis-sect chain collapsed distribution" #distribution>
            <h2
              class="fs-sect-header"
              (click)="toggleFoodChainSection(distribution, overlay)"
              (keyup.enter)="toggleFoodChainSection(distribution, overlay)"
              tabindex="0"
            >
              <i class="fas fa-bars"></i> Distribution
            </h2>
            <div class="content">
              At this step, food travels from processors to the end user—whether
              that’s another producer, consumers, or most likely a retailer.
              <strong>Distribution</strong> includes the storage and
              transportation of food products to retailers, schools,
              restaurants, and hospitals. <br /><br />
              From basic ingredients to packaged foods, every item has
              requirements for freshness and timing is key. There’s a lot
              wrapped up in this step, including the infrastructure needed for
              trucks, planes, and trains to deliver across the United States.
              Check out our
              <a href="/food-system-tool/map?layers=ohio_exports_770"
                >Food Flow layer</a
              >
              on our Ohio Food System Map to see where agricultural products
              travel across the state and our nation. <br />
              <button
                routerLink="/page/fs-distribution"
                mat-flat-button
                color="primary"
              >
                Learn More
              </button>
            </div>
            <app-image-map-infographic
              displayType="modal"
              infographicTitle="fs-distribution"
            ></app-image-map-infographic>
          </div>
          <div class="fs-vis-sect chain collapsed retail" #retail>
            <h2
              class="fs-sect-header"
              (click)="toggleFoodChainSection(retail, overlay)"
              (keyup.enter)="toggleFoodChainSection(retail, overlay)"
              tabindex="0"
            >
              <i class="fas fa-bars"></i> Retail
            </h2>
            <div class="content">
              We’ve landed in <strong>retail/consumption</strong>, the last step
              on the food supply chain. Restaurants, bars, grocery stores,
              vending machines, and farmers markets all cater to consumers. Food
              pantries provide food for those in need. And some farmers skip a
              few steps with direct-to-consumer farmers markets. <br /><br />
              In CFAES, we train farmers to capture data that will improve
              direct marketing for farm products. And explore how to reduce food
              waste through composting or recycling, with projects to understand
              purchasing behaviors and educate consumers. <br />
              <button
                routerLink="/page/fs-retail"
                mat-flat-button
                color="primary"
              >
                Learn More
              </button>
            </div>
            <app-image-map-infographic
              displayType="modal"
              infographicTitle="fs-retail"
            ></app-image-map-infographic>
          </div>
        </div>
        <div class="spacer-bar l"></div>
        <div class="spacer-bar c"></div>
        <div class="spacer-bar r"></div>
      </div>
    </div>
  </ng-template>

  <div id="fs-bottom-copy">
    Our researchers ask big questions. Their work addresses the challenging
    issues we face to feed a growing population. You’ll find the latest research
    from the College of Food, Agricultural, and Environmental Sciences on each
    topic page.
  </div>
</div>
