import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Hotspot, Infographic } from "../util";

@Component({
  selector: "app-sidebar-display",
  templateUrl: "./sidebar-display.component.html",
  styleUrls: ["./sidebar-display.component.scss"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class SidebarDisplayComponent implements OnInit {
  @Input() infographic: Infographic;
  activeHotspotIndex: number;
  imageHeight: number = 0;

  constructor() {}

  onResize(event) {
    this.getImgHeight();
  }

  ngOnInit() {}
  getImgHeight() {
    this.imageHeight = document.getElementById(
      "infographic-image"
    ).offsetHeight;
    return this.imageHeight > 0 ? this.imageHeight : 64;
  }
  getButtonStyle(hotspot: Hotspot) {
    return {
      top: hotspot.topPercent.toString() + "%",
      left: hotspot.leftPercent.toString() + "%",
    };
  }
  isActive(index: number) {
    return this.activeHotspotIndex == index;
  }
  toggleHotspot(event: any, index: number) {
    this.activeHotspotIndex == index
      ? this.deactivateHotspot()
      : this.activateHotspot(event, index);
  }
  activateHotspot(event: any, index: number) {
    this.activeHotspotIndex = index;
  }
  deactivateHotspot() {
    this.activeHotspotIndex = undefined;
  }
  goTo(link: string) {
    window.open(link, "_blank");
  }
}
