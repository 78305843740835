import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ExplorerService } from "../explorer/services/explorer.service";
import { parse, stringify } from "flatted";
import {
  WidgetSelection,
  WidgetInput,
  FsmWidgetInput,
  CptWidgetInput,
  EfieldsWidgetInput,
} from "../explorer/types/types";
import { INamedLayer, MapLayer } from "../food-system-tool/types";
import { Router } from "@angular/router";
import { DEFAULT_SIZES } from "../explorer/types/constants";

@Injectable({
  providedIn: "root",
})
export class ExplorerCartService {
  cart: WidgetInput[];
  constructor(
    private _explorer: ExplorerService,
    private _snackBar: MatSnackBar,
    private _router: Router
  ) {
    let cachedCart = localStorage.getItem("explorer");
    cachedCart ? (this.cart = JSON.parse(cachedCart)) : (this.cart = []);
		// console.log('cart initalized');
		// console.log(localStorage);
  }

  clearCart(): void {
    this.cart = [];
    localStorage.removeItem("explorer");
  }

  removeFromCart(itemName: string): void {
    let removeIndex = this.cart.findIndex(
      (item) => item.selection.title == itemName
    );
    let deleted = this.cart.splice(removeIndex, 1);
    if (deleted && deleted.length)
      this._snackBar.open("Item deleted from cart.", "", { duration: 1200 });
  }

  checkoutToExplorer(): void {
    this._router.navigate(["explorer"]).then(() => {
      this._explorer.addFromCart(this.cart)
			setTimeout(() => {
				this.clearCart();

			}, 3000);
    });
  }

  cptAddToCart(cptWidgetInput: CptWidgetInput): void {
    cptWidgetInput.rows = DEFAULT_SIZES.chart.rows;
    cptWidgetInput.cols = DEFAULT_SIZES.chart.cols;
    this.cart.push(cptWidgetInput);
    localStorage.clear();
    localStorage.setItem("explorer", JSON.stringify(this.cart));
    this._snackBar.open("Item added to cart.", "", { duration: 1200 });
  }

  fsmAddToCart(layer: INamedLayer, sourceText: string): void {
		console.log(layer);
    let s: WidgetSelection = {
      id: Math.random().toString(),
      title: layer.name,
      type: "map",
      source: "fsm",
      sourceText: sourceText,
      toolContainerRefs: [],
    };
    let widgetInput: FsmWidgetInput = {
      id: Math.random().toString(),
      selection: s,
      rows: DEFAULT_SIZES.map.rows,
      cols: DEFAULT_SIZES.map.cols,
      x: 0,
      y: 0,
      maps: [{name: layer.name, layer: layer}],
    };
		console.log(widgetInput);
    this.cart.push(widgetInput);
    localStorage.clear();
    localStorage.setItem("explorer", JSON.stringify(this.cart));
    this._snackBar.open("Item added to cart.", "", { duration: 1200 });
  }

  efieldsAddToCart(efieldsWidgetInput: EfieldsWidgetInput): void {
    efieldsWidgetInput.rows = DEFAULT_SIZES.chart.rows;
    efieldsWidgetInput.cols = DEFAULT_SIZES.chart.cols;
    this.cart.push(efieldsWidgetInput);
    // remove circular reference so we can serialize
    efieldsWidgetInput.datasets = efieldsWidgetInput.datasets.map((dataset) => {
      let cleanedDataset = {};
      for (let [key, value] of Object.entries(dataset)) {
        if (key == "_meta") continue;
        cleanedDataset[key] = value;
      }
			console.log(cleanedDataset)
      return cleanedDataset;
    });
    localStorage.clear();
    localStorage.setItem("explorer", JSON.stringify(this.cart));
    this._snackBar.open("Item added to cart.", "", { duration: 1200 });
  }
}
