import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import { Person, UserType } from "src/app/shared/models/person";
import { ParagraphType, VisualLink, VisualLinksSection } from "../types";
import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
  Swiper,
} from "swiper/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
SwiperCore.use([A11y, Pagination, Navigation, Mousewheel]);

@Component({
  selector: "app-custom-page-section",
  templateUrl: "./custom-page-section.component.html",
  styleUrls: ["../style.scss"],
  styles: [":host { display:block; }"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPageSectionComponent implements OnInit, OnChanges {
  @HostListener("window:click", ["$event"])
  onClick(event) {
    if (this.swiper) this.swiper.update();
  }
  @Input() sectionData: any; // CustomPageSection;
  @Input() parentNodeId: any; // CustomPageSection;
  types = ParagraphType;
  swiper: Swiper;
  bodySectCollapseToggle: boolean = true;
  audioTranscriptToggle: boolean = true;
  @ViewChild("tabGroup") tabGroup: ElementRef;
  tabindex = 0;
  activeCard = null;
  cleanIframeUrl: SafeResourceUrl = "";

  constructor(private cdr: ChangeDetectorRef, private _sani: DomSanitizer) {}

  ngOnInit(): void {
    console.log(this.sectionData);
    console.log(this.parentNodeId);
    if (this.sectionData.type == this.types.Iframe) {
      this.cleanIframeUrl = this._sani.bypassSecurityTrustResourceUrl(
        this.sectionData.rawSrc
      );
      console.log(this.sectionData, this.cleanIframeUrl);
    }
  }
  getIframeUrl() {
    this._sani.bypassSecurityTrustResourceUrl(this.sectionData.rawSrc);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.tabindex = 0;
  }

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
    this.swiper.onAny(() => this.cdr.detectChanges());
    this.cdr.detectChanges();
  }
  navigateTo(i: number) {
    this.swiper.slideTo(i);
  }
  getVisualLinkStyle(link: VisualLink) {
    return link.imageUrl && link.imageUrl.length > 0
      ? getBackgroundImageStyles(0.3, link.imageUrl)
      : { "background-color": "#" + link.colorHex };
  }

  getColumnStyle(
    isLeftCol: boolean,
    leftColWidth: number,
    leftOffset: number,
    rightOffset: number
  ) {
    return {
      width: (isLeftCol ? leftColWidth : 100 - leftColWidth) + "%",
      "padding-top": (isLeftCol ? leftOffset : rightOffset) + "px",
    };
  }

  getPersonLink(person: Person) {
    return (
      "directory/" +
      (person.type == UserType.OSUInternal ? "" : "external/") +
      person.id
    );
  }
  toggleBodySectionCollapse() {
    this.bodySectCollapseToggle = !this.bodySectCollapseToggle;
  }
  toggleAudioTranscriptCollapse() {
    this.audioTranscriptToggle = !this.audioTranscriptToggle;
  }
  getCssUrlVar(url) {
    return `url('${url}')`;
  }
}
