<mat-toolbar id="kx-navbar">
  <a id="cfaes" href="https://cfaes.osu.edu" target="_blank">CFAES</a>
  <a id="kx-title-full" href="/">
    <span class="highlighted-letter">K</span>NOWLEDGE E<span
      class="highlighted-letter"
      >X</span
    >CHANGE
  </a>

  <span class="example-spacer"></span>
  <div id="fullNav">
    <ng-container *ngFor="let link of links">
      <ng-container *ngIf="link.customNavItem; else standardNavLink">
        <ng-container *ngIf="link.customNavItemId == 'explorer-cart'">
          <button
            class="nav-button dropdown-button cart"
            [matMenuTriggerFor]="cartMenu"
          >
            <div class="size-indicator" *ngIf="cartLength">
              {{ cartLength }}
            </div>
            <i class="fas fa-shopping-cart fa-lg"></i>
          </button>
          <mat-menu #cartMenu="matMenu"
            ><app-explorer-cart></app-explorer-cart
          ></mat-menu>
          <!-- <app-explorer-cart></app-explorer-cart> -->
        </ng-container>
        <ng-container *ngIf="link.customNavItemId == 'explore'">
          <button
            class="nav-button dropdown-button"
            [matMenuTriggerFor]="exploreMenu"
          >
            Explore <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #exploreMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="typesMenu">Types</button>
            <button mat-menu-item [matMenuTriggerFor]="topicsMenu">
              Topics
            </button>
            <button mat-menu-item [matMenuTriggerFor]="campaignsMenu">
              Hubs
            </button>
            <a
              class="nav-dropdown-item"
              mat-menu-item
              routerLink="/topic/student-spotlight"
            >
              Student Spotlight
            </a>
          </mat-menu>
          <mat-menu #typesMenu="matMenu">
            <!-- <a
              mat-menu-item
              href="/search?types=Dataset"
              aria-label="Dataset"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_dataset"></kx-icon> Datasets
            </a> -->
            <a
              mat-menu-item
              href="/search?types=File"
              aria-label="Files"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_files"></kx-icon> Files
            </a>
            <a
              mat-menu-item
              href="/search?types=Image%20Collection"
              aria-label="Galleries"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_photo_gallery"></kx-icon> Galleries
            </a>
            <a
              mat-menu-item
              href="/search?types=Link"
              aria-label="Links"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_links"></kx-icon> Links
            </a>
            <a
              mat-menu-item
              href="/podcast"
              aria-label="Podcasts"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_podcasts"></kx-icon> Podcasts
            </a>
            <a
              mat-menu-item
              href="/search?types=Question"
              aria-label="Questions"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_questions"></kx-icon> Questions
            </a>
            <a
              mat-menu-item
              href="/search?types=Story"
              aria-label="Stories"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_stories"></kx-icon> Stories
            </a>
            <a
              mat-menu-item
              href="/search?types=Video"
              aria-label="Videos"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_videos"></kx-icon> Videos
            </a>
            <a
              mat-menu-item
              href="/search?types=Virtual%20Tour"
              aria-label="Videos"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_virtual_tours"></kx-icon> Virtual Tours
            </a>
          </mat-menu>
          <mat-menu #topicsMenu="matMenu">
            <a
              mat-menu-item
              *ngFor="let route of topicRoutes"
              class="exploreLinkTopic"
              [routerLink]="route.route"
              [attr.aria-label]="route.name"
              [innerHTML]="route.name"
            ></a>
          </mat-menu>
          <mat-menu #campaignsMenu="matMenu">
            <a mat-menu-item class="exploreLinkCampaign" href="/bite"
              >Bite Site</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/food-system-visualization"
              >The Food System</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/page/early-drought-response"
              >Drought Conditions and Resources</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/page/carbon-central"
              >Carbon Central</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/page/stream-practices-for-road-crossings"
              >Stream Practices for Road Crossings</a
            >
          </mat-menu>
        </ng-container>
      </ng-container>
      <ng-template #standardNavLink>
        <ng-container *ngIf="link.dropdownMenu == true; else elseBlock">
          <button
            class="nav-button dropdown-button"
            [matMenuTriggerFor]="navItemDropdown"
          >
            {{ link.name }} <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #navItemDropdown="matMenu">
            <ng-container *ngFor="let route of link.routes">
              <ng-container
                *ngIf="route.dropdownMenu == true; else regularRoute"
              >
                <button
                  mat-menu-item
                  [matMenuTriggerFor]="secondLevelMenu"
                  [innerHTML]="route.name"
                ></button>
                <mat-menu #secondLevelMenu="matMenu">
                  <a
                    *ngFor="let subRoute of route.routes"
                    class="nav-dropdown-item"
                    mat-menu-item
                    [routerLink]="subRoute.route"
                    [attr.aria-label]="link.label"
                    [innerHTML]="subRoute.name"
                  ></a>
                </mat-menu>
              </ng-container>
              <ng-template #regularRoute>
                <a
                  class="nav-dropdown-item"
                  mat-menu-item
                  [routerLink]="route.route"
                  [attr.aria-label]="link.label"
                  [innerHTML]="route.name"
                ></a>
              </ng-template>
            </ng-container>
          </mat-menu>
        </ng-container>
        <ng-template #elseBlock>
          <a
            class="nav-button"
            [attr.aria-label]="link.label"
            [routerLink]="link.route"
            routerLinkActive="nav-active"
            [routerLinkActiveOptions]="{ exact: link.route == '/' }"
            [innerHTML]="link.name"
          ></a>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>

  <button
    mat-icon-button
    [matMenuTriggerFor]="fullNavMenu"
    id="fullNavMenuIcon"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <ng-container *ngFor="let link of links">
    <ng-container *ngIf="link.customNavItemId == 'explorer-cart'">
      <button
        id="mobile-explorer-menu"
        class="nav-button dropdown-button cart"
        [matMenuTriggerFor]="cartMenu"
      >
        <div class="size-indicator" *ngIf="cartLength">{{ cartLength }}</div>
        <i class="fas fa-shopping-cart fa-lg"></i>
      </button>
      <mat-menu #cartMenu="matMenu"
        ><app-explorer-cart></app-explorer-cart
      ></mat-menu>
      <!-- <app-explorer-cart></app-explorer-cart> -->
    </ng-container>
  </ng-container>
  <mat-menu #fullNavMenu="matMenu">
    <ng-container *ngFor="let link of links">
      <ng-container *ngIf="link.customNavItem; else standardNavLink">
        <ng-container *ngIf="link.customNavItemId == 'explore'">
          <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
            Explore
          </button>
          <mat-menu #exploreMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="typesMenu">Types</button>
            <button mat-menu-item [matMenuTriggerFor]="topicsMenu">
              Topics
            </button>
            <button mat-menu-item [matMenuTriggerFor]="campaignsMenu">
              Hubs
            </button>
            <a
              mat-menu-item
              routerLink="/topic/student-spotlight"
              class="nav-dropdown-item"
            >
              Student Spotlight
            </a>
          </mat-menu>
          <mat-menu #typesMenu="matMenu">
            <!-- <a
              mat-menu-item
              href="/search?types=Dataset"
              aria-label="Dataset"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_dataset"></kx-icon> Datasets
            </a> -->
            <a
              mat-menu-item
              href="/search?types=File"
              aria-label="Files"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_files"></kx-icon> Files
            </a>
            <a
              mat-menu-item
              href="/search?types=Image%20Collection"
              aria-label="Galleries"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_photo_gallery"></kx-icon> Galleries
            </a>
            <a
              mat-menu-item
              href="/search?types=Link"
              aria-label="Links"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_links"></kx-icon> Links
            </a>
            <a
              mat-menu-item
              href="/podcast"
              aria-label="Podcasts"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_podcasts"></kx-icon> Podcasts
            </a>
            <a
              mat-menu-item
              href="/search?types=Question"
              aria-label="Questions"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_questions"></kx-icon> Questions
            </a>
            <a
              mat-menu-item
              href="/search?types=Story"
              aria-label="Stories"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_stories"></kx-icon> Stories
            </a>
            <a
              mat-menu-item
              href="/search?types=Video"
              aria-label="Videos"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_videos"></kx-icon> Videos
            </a>
            <a
              mat-menu-item
              href="/search?types=Virtual%20Tours"
              aria-label="Virtual Tours"
              class="exploreLinkContentType"
            >
              <kx-icon id="explore_virtual_tours"></kx-icon> Virtual Tours
            </a>
          </mat-menu>
          <mat-menu #topicsMenu="matMenu">
            <a
              mat-menu-item
              *ngFor="let route of topicRoutes"
              class="exploreLinkTopic"
              [routerLink]="route.route"
              [attr.aria-label]="route.name"
              [innerHTML]="route.name"
            ></a>
          </mat-menu>
          <mat-menu #campaignsMenu="matMenu">
            <a mat-menu-item class="exploreLinkCampaign" href="/bite"
              >Bite Site</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/food-system-visualization"
              >The Food System</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/page/early-drought-response"
              >Drought Conditions and Resources</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/page/carbon-central"
              >Carbon Central</a
            >
            <a
              mat-menu-item
              class="exploreLinkCampaign"
              href="/page/stream-practices-for-road-crossings"
              >Stream Practices for Road Crossings</a
            >
          </mat-menu>
        </ng-container>
      </ng-container>
      <ng-template #standardNavLink>
        <ng-container *ngIf="link.dropdownMenu == true; else elseBlock">
          <button mat-menu-item [matMenuTriggerFor]="navSubMenu">
            {{ link.name }}
          </button>
          <mat-menu #navSubMenu="matMenu">
            <ng-container *ngFor="let route of link.routes">
              <ng-container
                *ngIf="route.dropdownMenu == true; else regularRoute"
              >
                <button
                  mat-menu-item
                  [matMenuTriggerFor]="secondLevelMenu"
                  [innerHTML]="route.name"
                ></button>
                <mat-menu #secondLevelMenu="matMenu">
                  <a
                    *ngFor="let subRoute of route.routes"
                    class="nav-dropdown-item"
                    mat-menu-item
                    [routerLink]="subRoute.route"
                    [attr.aria-label]="link.label"
                    [innerHTML]="subRoute.name"
                  ></a>
                </mat-menu>
              </ng-container>
              <ng-template #regularRoute>
                <a
                  class="nav-dropdown-item"
                  mat-menu-item
                  [routerLink]="route.route"
                  [attr.aria-label]="link.label"
                  [innerHTML]="route.name"
                ></a>
              </ng-template>
            </ng-container>
          </mat-menu>
        </ng-container>
        <ng-template #elseBlock>
          <a
            class="nav-dropdown-item"
            mat-menu-item
            [attr.aria-label]="link.label"
            [routerLink]="link.route"
            routerLinkActive="nav-active"
            [routerLinkActiveOptions]="{ exact: link.route == '/' }"
            [innerHTML]="link.name"
          ></a>
        </ng-template>
      </ng-template>
    </ng-container>
  </mat-menu>
</mat-toolbar>
