import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";
import { NavEntry } from "../../custom-stuff/etc/types";

type imageWithInfo = { image: string; info: string, relative_url?: string, question_fragment?: string, showInfo: boolean};


@Component({
  selector: "app-nav-with-image-carousel",
  templateUrl: "./nav-with-image-carousel.component.html",
	styleUrls: ["./nav-with-image-carousel.component.scss"],
})
export class NavWithImageCarouselComponent implements OnInit, OnChanges {
  @Input() imagesWithInfo: imageWithInfo[][];
  @Input() navLinks: NavEntry[];
	activeNavIndex: number;
  @ViewChild("swiper", { static: false }) swiper: SwiperComponent;
	showMobileView: boolean;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.showMobileView = event.target.innerWidth <= 800;
	}

	mobileNavLink: NavEntry;


  config: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 20,
		navigation: false,
  };

	mobileConfig: SwiperOptions = {
    slidesPerView: 1,
		navigation: false
	}

	get flattenedImageEntries(): imageWithInfo[] {
		let flattened = [];
		for (let imageEntry of this.navLinks[this.activeNavIndex].image_entries) {
			for (let image of imageEntry.images) {
				flattened.push(image);
			}
		}
		return flattened;

	}

	test(event) {
		this.activeNavIndex = this.navLinks.indexOf(event.value)

	}

	changeActiveNavIndex(i: number) {
		this.activeNavIndex = i;
		console.log(this.activeNavIndex);
		console.log(this.navLinks[this.activeNavIndex])

	}

  constructor() {}

  ngOnInit(): void {
		this.showMobileView = window.innerWidth <= 800;
		this.activeNavIndex = 0
		console.log(this.navLinks);
	}

	ngOnChanges(changes: SimpleChanges) {
		let info = changes["imagesWithInfo"]
		let navLinks = changes["navLinks"]
		if (info && info.currentValue) {
		console.log("nav with image carousel")
			console.log(info.currentValue);
		}
		if (navLinks && navLinks.currentValue) {
			this.mobileNavLink = navLinks.currentValue[0];
		}

	}


  slideNext(): void {
    this.swiper.swiperRef.slideNext();
  }
  slidePrev(): void {
    this.swiper.swiperRef.slidePrev();
  }
}
