<ng-container [ngSwitch]="step">
  <ng-container *ngSwitchCase="'init'">
    <h2>Log in/Register to upload data</h2>
    <mat-form-field appearance="standard">
      <mat-label>Email Address</mat-label>
      <input
        autofocus
        matInput
        [formControl]="email"
        placeholder="you@yourorganization.gov"
        (keyup.enter)="checkUserEmail()"
      />
      <mat-error *ngIf="email.invalid">{{ getEmailErrorMessage() }}</mat-error>
    </mat-form-field>
    <br />
    <button
      mat-stroked-button
      (click)="checkUserEmail()"
      [disabled]="email.invalid || thinking"
    >
      Next
      <span *ngIf="thinking">...</span>
    </button>
    <br />
    <div>
      If this is your first time, please enter your email address and click next
      to complete your account registration. If you already have an account,
      enter the email address you used to create your account and press next to
      enter your password.
    </div>
    <br>
    <div>
      Forgot your password? Please enter your email and click "forgot my password" on the next screen.
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'enter-password'">
    <h2>Welcome back, {{ name }}</h2>
    <h3 *ngIf="auth.failedAttempts > 0">
      Invalid username or password. Please try again.
    </h3>
    <br />
    <mat-form-field appearance="standard" (click)="step = 'init'">
      <mat-label>Email Address</mat-label>
      <input matInput [ngModel]="email.value" disabled />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Enter your password</mat-label>
      <input
        autofocus
        matInput
        [(ngModel)]="passwordForLogIn"
        [type]="hidePassword ? 'password' : 'text'"
        (keyup.enter)="passwordSubmitted()"
        [disabled]="thinking"
      />

      <button
        mat-icon-button
        matSuffix
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword"
      >
        <mat-icon>{{
          hidePassword ? "visibility_off" : "visibility"
        }}</mat-icon>
      </button>
    </mat-form-field>
    <br />
    <button
      mat-stroked-button
      (click)="passwordSubmitted()"
      [disabled]="passwordForLogIn.length == 0 || thinking"
    >
      Sign In
      <span *ngIf="thinking">...</span>
    </button>
    <br>
    <button (click)="forgotPassword()" class="link-button">I forgot my password</button>
  </ng-container>

  <ng-container *ngSwitchCase="'register'">
    <h2>Register</h2>
    <mat-form-field appearance="standard">
      <mat-label>Email Address</mat-label>
      <input matInput [ngModel]="email.value" disabled /> </mat-form-field
    ><br />
    <mat-form-field appearance="standard">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="name" required /> </mat-form-field
    ><br />
    <mat-form-field appearance="standard">
      <mat-label>Organization</mat-label>
      <input matInput [(ngModel)]="organization" required /> </mat-form-field
    ><br />
    <mat-form-field appearance="standard">
      <mat-label>Create a password</mat-label>
      <input
        matInput
        [formControl]="password"
        [type]="hidePassword ? 'password' : 'text'"
      />
      <mat-error *ngIf="password.invalid">{{
        getPasswordErrorMessage()
      }}</mat-error>
      <button
        mat-icon-button
        matSuffix
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword"
      >
        <mat-icon>{{
          hidePassword ? "visibility_off" : "visibility"
        }}</mat-icon>
      </button>
    </mat-form-field>
    <button
      mat-stroked-button
      [disabled]="!registrationInputsAreValid() || thinking"
      (click)="register()"
    >
      Register
      <span *ngIf="thinking">...</span>
    </button>
  </ng-container>
</ng-container>
