<div
  class="custom-page-navigator-container"
  *ngIf="hostedCustomPages && activePageName"
>
  <div class="navigation-accordion-container" [ngClass]="showSidebar ? 'show' : 'hide'">
		<div class="mobile-header" *ngIf="mobile">
		<h2>Bite Site</h2>
		<div class="collapse-button" (click)="showSidebar = !showSidebar">
			<i _ngcontent-xqp-c464="" class="fas fa-chevron-up" aria-hidden="true"></i>
	</div>

		</div>
    <div class="navigation-accordion-inner-container">
      <h2 class="navigator-header" *ngIf="!mobile">Bite Site</h2>
      <mat-accordion>
        <mat-expansion-panel
					*ngIf="homePagePath"
          hideToggle="true"
          class="navigation-panel"
          expanded="false"
          (click)="goToHomePage()"
        >
          <mat-expansion-panel-header>
            <mat-panel-title><div>Home</div></mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
				
				<ng-template *ngTemplateOutlet="mobile ? mobileExpansion: desktopExpansion"></ng-template>


      </mat-accordion>
    </div>
  </div>
  <ng-container *ngIf="activePageName">
    <ng-container *ngFor="let hostedCustomPage of hostedCustomPages">
      <app-custom-page
        [nodeID]="hostedCustomPage.customPageId"
        (navigationSections)="updateSections($event)"
        [displayHeader]="false"
        [hosted]="true"
        [ngClass]="
          hostedCustomPage.name === activePageName ? 'active' : 'inactive'
        "
      ></app-custom-page>

    </ng-container>
  </ng-container>
</div>

<ng-template #mobileExpansion>
        <mat-expansion-panel
          hideToggle="true"
          class="navigation-panel"
          *ngFor="let hostedCustomPage of hostedCustomPages; index as i"
					[expanded]="i === mobileSidebarExpandIndex"
        >
          <mat-expansion-panel-header (click)="updateActiveCustomPage(hostedCustomPage); mobileSidebarExpandIndex = i">
            <mat-panel-title
              ><div [innerHTML]="hostedCustomPage.title"></div
            ></mat-panel-title>
            <div class="indicator"
							*ngIf="hostedCustomPage.navSections && hostedCustomPage.navSections.length"
							(click)="$event.stopPropagation(); mobileSidebarExpandIndex = i; indicClick($event)">
              <i class="fas fa-chevron-right"></i>
            </div>
          </mat-expansion-panel-header>
          <div *ngIf="hostedCustomPage.navSections" class="navigation-sections">
            <div
              *ngFor="let navSection of hostedCustomPage.navSections"
              (click)="updateActiveCustomPage(hostedCustomPage, navSection.elementId); mobileSidebarExpandIndex = i"              
							class="navigation-section"
            >
              {{ navSection.name }}
            </div>
          </div>
        </mat-expansion-panel>

</ng-template>
<ng-template #desktopExpansion>
        <mat-expansion-panel
          hideToggle="true"
          class="navigation-panel"
          *ngFor="let hostedCustomPage of hostedCustomPages"
          (afterExpand)="updateActiveCustomPage(hostedCustomPage)"
          [expanded]="hostedCustomPage.name == activePageName"
        >
          <mat-expansion-panel-header>
            <mat-panel-title
              ><div [innerHTML]="hostedCustomPage.title"></div
            ></mat-panel-title>
            <div class="indicator"
							*ngIf="hostedCustomPage.navSections && hostedCustomPage.navSections.length"
							>
              <i class="fas fa-chevron-right"></i>
            </div>
          </mat-expansion-panel-header>
          <div *ngIf="hostedCustomPage.navSections" class="navigation-sections">
            <div
              *ngFor="let navSection of hostedCustomPage.navSections"
              (click)="navigateToElement(navSection.elementId)"
              class="navigation-section"
            >
              {{ navSection.name }}
            </div>
          </div>
        </mat-expansion-panel>

</ng-template>
