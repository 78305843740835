import { TYPE } from "./type";
import { SORT } from "./sort";
import { environment } from "src/environments/environment";

export const MATCH_ALL = encodeURIComponent("x|-x");

export class SearchFilters {
  types: Set<TYPE> = new Set<TYPE>();
  topics: Set<string> = new Set<string>();
  tags: Set<string> = new Set<string>();
  date: { start: Date; end: Date } = { start: new Date(0, 0), end: new Date() };

  constructor(params?: Partial<SearchFilters>) {
    if (params) Object.assign(this, params);
  }
}

export class SearchOptions {
  private _query: string = MATCH_ALL;
  size: number = 12;
  start: number = 0;
  sort: SORT = SORT.RELEVANCE;
  filters: SearchFilters = new SearchFilters();
  publishedOnly: boolean = environment.production;

  public get query(): string {
    return this._query;
  }
  public set query(value: string) {
    this._query = value.length > 0 ? value : MATCH_ALL;
  }

  constructor(params?: Partial<SearchOptions>) {
    if (params) Object.assign(this, params);
  }
}
