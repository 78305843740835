import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ViewEncapsulation,
  ViewChild,
  NgZone,
  OnDestroy,
} from "@angular/core";
import { SearchService } from "../../services/search.service";
import { hit, Terms } from "src/app/shared/models/terms";
import { TYPE } from "src/app/main/search/etc/type";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import { ActivatedRoute, Router } from "@angular/router";
import {
  SearchOptions,
  SearchFilters,
  MATCH_ALL,
} from "src/app/main/search/etc/options";
import { SORT } from "src/app/main/search/etc/sort";
import { TitleService } from "src/app/services/title.service";
import { from, fromEvent, Observable, Subject } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import {
  auditTime,
  distinct,
  distinctUntilChanged,
  map,
  startWith,
  mergeMap,
  takeUntil,
} from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { getTypeName } from "src/app/shared/components/content-list/util";
import {
  MatAutocomplete,
  MatAutocompleteModule,
} from "@angular/material/autocomplete";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MainSearchComponent implements AfterViewInit, OnDestroy {
  SORT = SORT;
  pageSize = 12;
  resultCount: number;
  results: Observable<any>;
  searchActive: boolean = false;
  searchOptions: SearchOptions = new SearchOptions();
  keywordRecommedations: string[] = [];
  searchTerm = new FormControl("");
  sortSelect = new FormControl("");
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  filterOptions: {
    types: { type: TYPE; countObservable: Observable<any> }[];
    topics: { topic: string; countObservable: Observable<any> }[];
  } = { types: [], topics: [] };
  pageIndex: number = 0;
  searchInputUpdated: Observable<any>;
  destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  scrollEvent: Observable<Event>;
  autocompleteOptions: string[];
  filteredAutocompleteOptions: Observable<string[]>;

  constructor(
    private _searchService: SearchService,
    private titleService: TitleService,
    private _api: ApiService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private _router: Router
  ) {
    this._router.onSameUrlNavigation = "ignore";
    this.scrollEvent = fromEvent(window, "scroll").pipe(
      takeUntil(this.destroy$)
    );
    this.searchOptions.sort = SORT.NEWEST;
    this.titleService.setPageTitle("Search");
    this.navigateBasedOnParams();
    this.sortSelect.valueChanges.subscribe((e) => {
      this.sort(e);
      this.refreshResults();
    });
    this.populateTopicFilterOptions();
    this.populateTypeFilterOptions();
    this.populateAutocompleteOptions();
    this.searchTerm.valueChanges.subscribe(
      (val) => (this.searchOptions.query = val)
    );

    this.searchInputUpdated = this.searchTerm.valueChanges.pipe(
      auditTime(1000)
    );

    this.searchInputUpdated.subscribe((res) =>
      console.log("search term changed to: ", res)
    );

    this.results = this.searchInputUpdated.pipe(mergeMap(() => this.search()));

    this.results.subscribe((res) => console.log("results: ", res));

    this.range.valueChanges.subscribe((val) => {
      this.searchOptions.filters.date.start = val.start;
      this.searchOptions.filters.date.end = val.end;
      this.refreshResults();
    });

    this.scrollEvent.pipe(auditTime(2500)).subscribe((e) => {
      let tracker = (<any>e).target.documentElement;
      const footer = document.getElementById("footer");
      const footerHeight = footer.offsetHeight;
      let threshold = 200;
      let limit =
        tracker.scrollHeight - tracker.clientHeight - footerHeight * 2;

      console.log(
        tracker.scrollTop > limit - threshold &&
          tracker.scrollTop < limit + threshold
      );
      console.log(tracker.scrollTop);
      console.log(limit);

      if (tracker.scrollTop > limit - threshold) {
        let loading = document.getElementById("loading");
        let end = document.getElementById("end");
        if (this.resultCount > this.pageIndex * this.pageSize) {
          this.loadMoreResults();
          this.pageIndex++;
          loading.classList.remove("hidden");
          console.log("loading next page...");
        } else {
          loading.classList.add("hidden");
          end.classList.remove("hidden");
          console.log("end of results!!!");
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.searchTerm.setValue(
      this.searchOptions.query == MATCH_ALL ? "" : this.searchOptions.query
    );

    this.filterAutocompleteOptions();
  }

  getContentTypeName(type) {
    return getTypeName(type);
  }

  toggleFilters() {
    let filters = document.getElementById("filters");
    filters.classList.toggle("hidden-mobile");
    filters.classList.toggle("active");
    let icon = document.getElementById("toggle-icon");
    icon.classList.toggle("hide");
  }

  sort(method: SORT) {
    console.log(method);
    this.searchOptions.sort = method;
  }

  clearFilters() {
    this.searchOptions.filters = new SearchFilters();
    this.range.reset();
    this.refreshResults();
  }

  search(): Observable<any> {
    if (!this.sortSelect.touched)
      this.sort(
        this.searchTerm.value.length > 0 ? SORT.RELEVANCE : SORT.NEWEST
      );
    this.updateQueryParams();
    console.log("searching: ", this.searchOptions);
    return this._searchService.search(this.searchOptions).map((result: any) => {
      this.resultCount = result.hits?.found;
      return (
        result.hits?.hit.map((el) => {
          el.fields.headerImage = el.fields.header_image;
          return el.fields;
        }) ?? []
      );
    });
  }
  loadMoreResults() {
    this.searchOptions.size += this.pageSize;
    this.refreshResults();
  }
  toggleTypeFilter(filter: TYPE) {
    let on = !this.searchOptions.filters.types.has(filter);
    if (on) this.searchOptions.filters.types.add(filter);
    else this.searchOptions.filters.types.delete(filter);
    this.refreshResults();
  }

  toggleTopicFilter(filter: TYPE) {
    let on = !this.searchOptions.filters.topics.has(filter);
    if (on) this.searchOptions.filters.topics.add(filter);
    else this.searchOptions.filters.topics.delete(filter);
    this.refreshResults();
  }

  updateTitle() {
    this.titleService.setPageTitle(`Search Results: ${this.searchTerm}`);
  }

  populateTypeFilterOptions() {
    this.filterOptions.types = Object.keys(TYPE).map((type) => {
      return {
        type: TYPE[type],
        countObservable: this.searchTerm.valueChanges.pipe(
          mergeMap(() =>
            this._searchService.getResultCountForAFilterValue(
              { type: TYPE[type] },
              this.searchOptions
            )
          )
        ),
      };
    });
  }

  populateTopicFilterOptions() {
    this._api.getDrupalTopics().then((topics) => {
      this.filterOptions.topics = topics
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((el) => {
          return {
            topic: el.name,
            countObservable: this.searchTerm.valueChanges.pipe(
              mergeMap(() =>
                this._searchService.getResultCountForAFilterValue(
                  { topic: el.name },
                  this.searchOptions
                )
              )
            ),
          };
        });
    });
  }

  populateAutocompleteOptions() {
    this.autocompleteOptions = [];
    this._api.getDrupalTopics(true).then((topics) => {
      topics.sort((a, b) => a.name.localeCompare(b.name));
      topics.forEach((topic) => {
        this.autocompleteOptions.push(topic.name.toLowerCase());
      });
    });
  }

  filterAutocompleteOptions() {
    this.filteredAutocompleteOptions = this.searchTerm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }

  refreshResults() {
    this.searchTerm.enable();
  }

  private updateQueryParams() {
    let queryParams: any = {};
    if (this.searchOptions.query != MATCH_ALL)
      queryParams.q = this.searchOptions.query;
    if (this.searchOptions.filters.types.size > 0)
      queryParams.types = Array.from(this.searchOptions.filters.types).join(
        ","
      );
    if (this.searchOptions.filters.topics.size > 0)
      queryParams.topics = Array.from(this.searchOptions.filters.topics).join(
        ","
      );
    this.ngZone.run(() =>
      this._router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParams,
      })
    );
  }
  navigateBasedOnParams() {
    this.route.queryParams.subscribe((params) => {
      if (params.types)
        params.types.split(",").forEach((t: TYPE) => {
          this.searchOptions.filters.types.add(t);
        });
      if (params.topics)
        params.topics.split(",").forEach((t) => {
          this.searchOptions.filters.topics.add(t);
        });
      if (params.q) this.searchOptions.query = params.q;
      console.log(params);
      console.log(this.searchOptions);
      this.search();
    });
  }
  ngOnDestroy(): void {
    this.destroy.next();
    this._router.onSameUrlNavigation = "reload";
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.searchTerm.value != "") {
      let allOptions: string[];
      allOptions = this.autocompleteOptions.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
      let relevantOptions: string[];
      relevantOptions = allOptions.filter((option) =>
        option.toLowerCase().startsWith(filterValue)
      );
      allOptions.forEach((option) => {
        if (!relevantOptions.includes(option)) relevantOptions.push(option);
      });
      return relevantOptions;
    } else return;
  }
}
