import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ApiService } from "./../services/api.service";
import { ChartModule } from "angular-highcharts";
import { LandingPageComponent } from "./landing-page.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PersonProfileComponent } from "./directory/person-profile/person-profile.component";
import { PeopleDirectoryComponent } from "./directory/people-directory/people-directory.component";
import { MainSearchComponent } from "./search/search.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { FarmSciReviewComponent } from "./fsr/farm-sci-review/farm-sci-review.component";
import { CommonModule } from "@angular/common";
import { FarmSciMapComponent } from "./fsr/farm-sci-map/farm-sci-map.component";
import { SharedModule } from "../shared/shared.module";
import { BucketComponent } from "./structure/bucket/bucket.component";
import { SearchHeaderComponent } from "./structure/search-header/search-header.component";
import { TopicsPageComponent } from "./structure/topic-list/topic-list.component";
import { TopicComponent } from "./structure/topic/topic.component";
import { ScrollStoryComponent } from "./habri/scroll-story.component";
import { AboutNewComponent } from "./about/about.component";
import { AnimateModule, AnimateService } from "@wizdm/animate";
import { MusicToolComponent } from "./music-tool/tool/music-tool.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MusicToolPageComponent } from "./music-tool/page/music-tool-page.component";
import { PlayButtonComponent } from "./music-tool/play-button/play-button.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatChipsModule } from "@angular/material/chips";
import { MatSortModule } from "@angular/material/sort";
import { SearchCfaesComponent } from "./search-cfaes/search-cfaes.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { PanoramaComponent } from "../shared/components/panorama/panorama.component";
import { EfieldsComponent } from "./efields/efields.component";
import { SwiperModule } from "swiper/angular";
import { LandingPageHeaderComponent } from "./structure/landing-page-header/landing-page-header.component";
import { DataSetComponent } from "./data-sets/data-set/data-set.component";
import { DataCatalogComponent } from "./data-sets/data-catalog/data-catalog.component";
import { MatTabsModule } from "@angular/material/tabs";
import { FoodSystemVisualizationComponent } from "./food-system-visualization/food-system-visualization.component";
import { SimpleVisualizationComponent } from "./food-system-visualization/simple-visualization/simple-visualization.component";
import { InstructionsDialog } from "./food-system-visualization/instructions/instructions-dialog";
import { AcpfFileManagerComponent } from "../shared/components/acpf-file-manager/acpf-file-manager.component";
import { AcpfFmLoginComponent } from "../shared/components/acpf-file-manager/login/acpf-fm-login.component";
import { AcpfFmListFilesUploadedComponent } from "../shared/components/acpf-file-manager/list-files-uploaded/acpf-fm-list-files-uploaded.component";
import { AcpfFmUploadComponent } from "../shared/components/acpf-file-manager/upload/acpf-fm-upload.component";
import { AcpfAuthenticationService } from "../services/acpf-auth.service";
import { MatDividerModule } from "@angular/material/divider";
import { MatStepperModule } from "@angular/material/stepper";
import { MatRadioModule } from "@angular/material/radio";
import { AcpfFmResetPasswordComponent } from "../shared/components/acpf-file-manager/reset-password/acpf-fm-reset-password.component";
import { DataItemComponent } from "./data-sets/data-item/data-item.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { CustomLandingPageComponent } from "../shared/custom-stuff/custom-landing-page/custom-landing-page.component";
import { DataTermsOfUseComponent } from "./data-terms-of-use/data-terms-of-use.component";
import { PanoHotspotPopupDialog } from "../shared/components/panorama/hotspot-popup.component";
import { SafeStylePipe } from "../pipes/safe-style.pipe";
import { ImpactsComponent } from "./impacts/impacts.component";
import { ExternalRedirectComponent } from "./external-redirect/external-redirect.component";
import { ExpertDirectoryComponent } from "./expert-directory/expert-directory.component";
import { DepartmentKeyPopupComponent } from "./expert-directory/department-key-popup/department-key-popup.component";
import { MobileTopComponent } from "./impacts/quote-svg-pieces/mobile-top.component";
import { BubbleBottomComponent } from "./impacts/quote-svg-pieces/bubble-bottom.component";
import { BubbleTopComponent } from "./impacts/quote-svg-pieces/bubble-top.component";

const routes: Routes = [
  { path: "", component: LandingPageComponent },
  {
    path: "",
    loadChildren: () =>
      import("./content/content.module").then((m) => m.ContentModule),
  },
  {
    path: "covid-19",
    loadChildren: () =>
      import("./covid19/covid19.module").then((m) => m.Covid19Module),
  },
  {
    path: "efields",
    loadChildren: () =>
      import("./efields/efields.module").then((m) => m.EfieldsModule),
  },
  {
    path: "ebarns",
    loadChildren: () =>
      import("./ebarns/ebarns.module").then((m) => m.EbarnsModule),
  },
  {
    path: "ffmpi",
    loadChildren: () =>
      import("./ffmpi/ffmpi.module").then((m) => m.FfmpiModule),
  },
  { path: "about", component: AboutNewComponent },
  { path: "impacts", component: ImpactsComponent },
  { path: "water-quality-composition", component: MusicToolPageComponent },
  { path: "habri-scrolling-visualization", component: ScrollStoryComponent },
  { path: "farm-sci-review", component: FarmSciReviewComponent },
  { path: "state-yield-tour", component: FarmSciMapComponent },
  { path: "search", component: MainSearchComponent },
  { path: "search/:term/:filter", component: MainSearchComponent },
  { path: "cfaes-search", component: SearchCfaesComponent },
  { path: "topics", component: TopicsPageComponent },
  { path: "topic/:topic", component: TopicComponent },
  { path: "bucket/:bucketName", component: BucketComponent },
  { path: "directory", component: PeopleDirectoryComponent },
  { path: "directory/:name_n", component: PersonProfileComponent },
  {
    path: "directory/external/:external_id",
    component: PersonProfileComponent,
  },
  { path: "data-set/:title", component: DataSetComponent },
  { path: "data-catalog", component: DataCatalogComponent },
  { path: "pano/:id", component: PanoramaComponent },
  {
    path: "food-system-visualization",
    component: FoodSystemVisualizationComponent,
  },
  { path: "acpf-file-manager", component: AcpfFileManagerComponent },
  { path: "acpf-file-manager/upload", component: AcpfFileManagerComponent },
  {
    path: "acpf-file-manager/reset-password/:blob",
    component: AcpfFileManagerComponent,
  },
  { path: "acpf-file-manager/log-out", component: AcpfFileManagerComponent },
  { path: "contact-us", component: ContactUsComponent },

  { path: "landing-page/:name", component: CustomLandingPageComponent },
  { path: "data-terms-of-use", component: DataTermsOfUseComponent },
  { path: "external/:external_url", component: ExternalRedirectComponent },
  { path: "expert-directory", component: ExpertDirectoryComponent },
];

@NgModule({
  declarations: [
    AboutNewComponent,
    LandingPageComponent,
    TopicComponent,
    ScrollStoryComponent,
    TopicsPageComponent,
    PersonProfileComponent,
    PeopleDirectoryComponent,
    MainSearchComponent,
    BucketComponent,
    SearchHeaderComponent,
    FarmSciReviewComponent,
    FarmSciMapComponent,
    MusicToolComponent,
    MusicToolPageComponent,
    PlayButtonComponent,
    DataCatalogComponent,
    SearchCfaesComponent,
    LandingPageHeaderComponent,
    DataSetComponent,
    FoodSystemVisualizationComponent,
    SimpleVisualizationComponent,
    InstructionsDialog,
    DataItemComponent,
    ContactUsComponent,
    DataTermsOfUseComponent,
    ImpactsComponent,
    ExternalRedirectComponent,
    ExpertDirectoryComponent,
    DepartmentKeyPopupComponent,
    MobileTopComponent,
    BubbleBottomComponent,
    BubbleTopComponent,
  ],
  imports: [
    SwiperModule,
    RouterModule.forChild(routes),
    NgbModule,
    CommonModule,
    ChartModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    DragDropModule,
    MatButtonModule,
    SharedModule,
    FormsModule,
    AnimateModule,
    MatTooltipModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatChipsModule,
    MatListModule,
    MatRadioModule,
    MatDividerModule,

    MatStepperModule,
  ],
  providers: [
    ApiService,
    AnimateService,
    AcpfAuthenticationService,
    SafeStylePipe,
  ],
  bootstrap: [LandingPageComponent],
  exports: [],
})
export class MainModule {}
