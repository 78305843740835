import { Component, OnChanges, OnInit, ViewEncapsulation } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ApiService } from "src/app/services/api.service";
import { DEV_LINKS, PROD_LINKS, ROUTE_CONDITIONAL_LINKS } from "./nav-links";
import { CustomNavItem, NavbarRouteList } from "./types";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { ExplorerCartService } from "src/app/services/explorer-cart.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  // encapsulation:ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
  links: NavbarRouteList = [];
  topicRoutes: NavbarRouteList = [];
  constructor(
    private _api: ApiService,
    private _route: Router,
    private _activatedRoute: ActivatedRoute,
    private _cart: ExplorerCartService
  ) {}

  ngOnInit() {
    this.setLinks();
    this.loadTopics();
    this._route.events.subscribe((event: RouterEvent) => {
      this.updateConditionalLinks(event);
    });
    console.log(this.links);
  }

  setLinks(): void {
    // console.log("set");
    if (environment.name == "PRODUCTION") this.links = PROD_LINKS;
    else if (environment.name == "STAGING") this.links = PROD_LINKS;
    else if (environment.name == "DEVELOPMENT") this.links = DEV_LINKS;
  }

  loadTopics() {
    this._api.getDrupalTopics(false).then((topics) => {
      this.topicRoutes = topics
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((topic) => {
          return { name: topic.name, route: "topic/" + topic.url };
        });
      // console.log(this.links);
    });
  }

  updateConditionalLinks(routeEvent: RouterEvent): void {
    if (
      routeEvent instanceof NavigationEnd ||
      routeEvent instanceof NavigationStart
    ) {
      this.setLinks();
      if (
        routeEvent.url.includes("/food-system-tool") ||
        routeEvent.url.includes("/profiles") ||
        routeEvent.url.includes("/efields/charting")
      ) {
        this.links = this.links.concat(ROUTE_CONDITIONAL_LINKS);
      }
    }
  }

  get cartLength(): number {
    return this._cart.cart.length;
  }
}
