import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ExecFileOptionsWithStringEncoding } from "child_process";
import { ApiService } from "src/app/services/api.service";
import { Person, UserType } from "../../models/person";
import { ListTypes } from "../../components/content-list/util";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map, mergeMap } from "rxjs/operators";

export type headerType = "full" | "title_only" | "short" | "campaign";

export type customLandingPageResponse = {
  title: string;
  name: string;
  description: string;
  headerImage: string;
  sections: {
    body: [sectionResponse];
    banner_buttons: string;
    banner_text: string;
    banner_title: string;
    label: string;
    link_override: string;
  };
};

export type customPageResponse = {
  description: string;
  name: string;
  headerImage: string;
  headerType: headerType;
  sectionIds: string[];
  sections: sectionResponse[];
  page_width: "Normal" | "Wide";
  tags: string;
  title: string;
  topics: string;
  date: string;
  byline: string;
  subtitle: string;
  contributors: string;
};

export type sectionResponse = {
  id: string;
  section_type: ParagraphType;
  carousel_label: string;
  carousel_tags: string;
  carousel_topics: string;
  carousel_content_types: string;
  carousel_url: string;
  carousel_content_list_type: ListTypes | "";
  body_title: string;
  body_html: string;
  body_collapsed_section: "True" | "False";
  body_highlighted_section: "True" | "False";
  video_yt_id: string;
  highlight_name_n: string;
  highlight_text: string;
  column_sections: string[];
  column_left_width: string;
  column_left_offset: number;
  column_right_offset: number;
  link_background_image: string;
  link_color_hex_code: string;
  link_link: string;
  links_link_paragraph_ids: string[];
  image_slider_images: string;
  image_slider_items: string;
  related_content_source: "KX" | "CFAES" | "Both";
  tabs_tabs: string[];
  tabs_title: string;
  iframe_src: string;
  iframe_title: string;
  iframe_width: string;
  iframe_height: string;
  cards_cards: string[];
  cards_title: string;
  infographic_title: string;
  infographic_id: string;
  audio_clip_file: string;
  quote_author: string;
  quote_quote: string;
  audio_clip_title: string;
  audio_clip_transcript: string;
  qa_ids: string[];
  list_entries: string;
  list_bullets: string;
  quote_text: string;
  quote_source: string;
  quote_title: string;
  visual_link_image: string;
  callout_header: string;
  callout_body: string;
  hero_carousel: string;
	auto_expand_first_entry: string;
  hero_image: string;
  hero_description: string;
  image_description_ids: string[];
  destination_name: string;
  nav_entry_ids: string[];
  image_grid_ids: string[];
  label_font_size: string;
  variable_column_container_entry_ids: string[];
  contributor_ids: string[];
};

export type questionAnswerResponse = {
  id: string;
  field_question: string;
  font_size: string;
  field_answer: string[];
  hero_image: string;
	hero_title: string;
  hero_description: string;
	qFragment?: string
};


export enum ParagraphType {
  Explorer_Dashboards = "Explorer Dashboard Carousel",
  Video = "Video",
  Body = "body section",
  Topics = "Topic List",
  Carousel = "Content Carousel",
  Visual_Links = "Visual Links",
  Contributor_Highlight = "Contributor Highlight",
  Searchbar = "Search Bar",
  Column = "Split Column Section",
  Image_Slider = "Image slider",
  Related_Content = "Related Content",
  Tabs = "Tabs",
  Iframe = "iframe",
  Cards = "Cards",
  Infographic = "Infographic Reference",
  Quote = "Block Quote",
  Audio_Clip = "Audio Clip",
  Accordion_QA = "accordion_qa",
  Numbered_List = "Numbered List",
  Bullet_List = "Bullet List",
  Callout = "Callout",
  Tick_Quote = "Quote",
  Visual_Link = "Visual Link",
  Reactions = "Reactions",
  Image_Carousel = "Image Carousel",
  Navigation_Destination = "Navigation Destination",
  Nav_With_Image_Carousel = "Nav with image carousel",
  Variable_Column_Container = "Variable Column Container",
  Contributors = "Contributors",
}

export type VisualLink = {
  text: string;
  url: string;
  imageUrl?: string;
  colorHex?: string;
};

export type CustomPageNavRef = {
  customPageId: string;
  navSections: NavigationDestinationSection[];
};

export interface CustomPageSection {
  type: ParagraphType;
}

export class ReactionSection implements CustomPageSection {
  type = ParagraphType.Reactions;
  constructor() {}
}
export class BodySection implements CustomPageSection {
  type = ParagraphType.Body;
  constructor(
    public title: string,
    public html: string,
    public collapsed: boolean,
    public highlighted: boolean
  ) {}
}
export class AudioClipSection implements CustomPageSection {
  type = ParagraphType.Audio_Clip;

  constructor(
    public title: string,
    public src: string,
    public transcript: string
  ) {}
}
export class QuoteSection implements CustomPageSection {
  type = ParagraphType.Quote;
  constructor(public body: string, public author: string) {}
}

export class IframeSection implements CustomPageSection {
  type = ParagraphType.Iframe;
  src: SafeUrl;
  constructor(
    public title: string,
    public rawSrc: string,
    public height: string,
    public width: string
  ) {}
  setup(sanitizer: DomSanitizer) {
    this.src = sanitizer.bypassSecurityTrustResourceUrl(this.rawSrc);
  }
}
export class InfographicSection implements CustomPageSection {
  type = ParagraphType.Infographic;
  constructor(public title: string, public id: string) {}
}

export class ColumnSection implements CustomPageSection {
  type = ParagraphType.Column;
  sections: CustomPageSection[];
  public leftWidth: number;

  constructor(
    public paragraphIds: string[],
    leftWidth: string,
    public leftOffset: number,
    public rightOffset: number
  ) {
    this.leftWidth = Number(leftWidth);
    console.assert(
      paragraphIds && paragraphIds.length == 2,
      "Invalid subparagraph input for column section"
    );
  }
  setup(api: ApiService, sani: DomSanitizer) {
    api
      .getDrupalCustomPageParagraphs(this.paragraphIds)
      .subscribe((items: sectionResponse[]) => {
        this.sections = items.map((s) => getSectionObj(s));

        // Run necessary setups
        this.sections.forEach((s) => {
          if (s.type == ParagraphType.Carousel)
            (s as CarouselSection).setup(api);
          else if (s.type == ParagraphType.Video)
            (s as VideoSection).setup(sani);
          else if (s.type == ParagraphType.Contributor_Highlight)
            (s as ContributorHighlightSection).setup(api);
        });
        // console.log(this);
      });
  }
}

export class ContributorHighlightSection implements CustomPageSection {
  type = ParagraphType.Contributor_Highlight;
  person: Person;

  constructor(public nameDotNum: string, public text: string) {
    this.person = new Person(UserType.OSUInternal, nameDotNum);
  }

  setup(api: ApiService) {
    this.person.populateOSUData(
      api.getPersonInfoFromOSUDirectory(this.person.id)
    );
  }
}

export class ImageSliderSection implements CustomPageSection {
  type = ParagraphType.Image_Slider;

  constructor(
    public items: { src: string; caption: string; type?: "video" | "image" }[]
  ) {
    const possibleFileTypes = [".mp4", ".png", ".gif", ".jpg", ".jpeg"];
    const videoFileTypes = [".mp4"];
    this.items = this.items.map((el) => {
      el.type = videoFileTypes.includes(/\.\w*$/g.exec(el.src)[0])
        ? "video"
        : "image";
      return el;
    });
    // console.log(this.items);
  }
}

export class SearchbarSection implements CustomPageSection {
  type = ParagraphType.Searchbar;
}

export class TabsSection implements CustomPageSection {
  type = ParagraphType.Tabs;
  tabs: { name: string; icon: string; sections: CustomPageSection[] }[] = [];

  constructor(public title: string, public tabIds: string[]) {}

  setup(api: ApiService, sani: DomSanitizer, title: string, tags: string[]) {
    // console.log(this.tabIds);

    api
      .getDrupalCustomPageTabs(this.tabIds)
      .subscribe((allTabsResponse: any[]) => {
        console.log(allTabsResponse);
        allTabsResponse = allTabsResponse.filter((tabResponse) => {
          return devLabelCheck(tabResponse.name);
        });
        allTabsResponse.forEach((tabResponse, index) => {
          api
            .getDrupalCustomPageParagraphs(tabResponse.paragraphIds)
            .subscribe((sects: sectionResponse[]) => {
              sects = sects.filter((section) => {
                return section;
              });
              let tabToAdd = {
                name: tabResponse.name,
                icon: tabResponse.icon,
                sections: sects.map((s) => getSectionObj(s)),
              };

              // Run necessary setups
              tabToAdd.sections.forEach((s) => {
                if (s.type == ParagraphType.Carousel)
                  (s as CarouselSection).setup(api);
                else if (s.type == ParagraphType.Video)
                  (s as VideoSection).setup(sani);
                else if (s.type == ParagraphType.Contributor_Highlight)
                  (s as ContributorHighlightSection).setup(api);
                else if (s.type == ParagraphType.Visual_Links)
                  (s as VisualLinksSection).setup(api);
                else if (s.type == ParagraphType.Column)
                  (s as ColumnSection).setup(api, sani);
                else if (s.type == ParagraphType.Tabs)
                  (s as TabsSection).setup(api, sani, title, tags);
                else if (s.type == ParagraphType.Related_Content)
                  (s as RelatedContentSection).setup(tags, title);
                else if (s.type == ParagraphType.Explorer_Dashboards)
                  (s as ExplorerDashboardsSection).setup(api);
              });
              this.tabs[index] = tabToAdd;
            });
        });
      });
  }
}

export class CardsSection implements CustomPageSection {
  type = ParagraphType.Cards;
  cards: {
    title: string;
    subtitle: string;
    image: string;
    sections: CustomPageSection[];
  }[] = [];

  constructor(public title: string, public cardIds: string[]) {}

  setup(api: ApiService, sani: DomSanitizer, title: string, tags: string[]) {
    // console.log(this.tabIds);

    api
      .getDrupalCustomPageCards(this.cardIds)
      .subscribe((allCardsResponse: any[]) => {
        console.log(allCardsResponse);
        allCardsResponse.forEach((cardResponse, index) => {
          api
            .getDrupalCustomPageParagraphs(cardResponse.paragraphIds)
            .subscribe((sects: sectionResponse[]) => {
              let cardToAdd = {
                title: cardResponse.title,
                subtitle: cardResponse.subtitle,
                image: cardResponse.image,
                sections: sects.map((s) => getSectionObj(s)),
              };

              // Run necessary setups
              cardToAdd.sections.forEach((s) => {
                if (s.type == ParagraphType.Carousel)
                  (s as CarouselSection).setup(api);
                else if (s.type == ParagraphType.Video)
                  (s as VideoSection).setup(sani);
                else if (s.type == ParagraphType.Contributor_Highlight)
                  (s as ContributorHighlightSection).setup(api);
                else if (s.type == ParagraphType.Visual_Links)
                  (s as VisualLinksSection).setup(api);
                else if (s.type == ParagraphType.Column)
                  (s as ColumnSection).setup(api, sani);
                else if (s.type == ParagraphType.Tabs)
                  (s as TabsSection).setup(api, sani, title, tags);
                else if (s.type == ParagraphType.Cards)
                  (s as CardsSection).setup(api, sani, title, tags);
                else if (s.type == ParagraphType.Related_Content)
                  (s as RelatedContentSection).setup(tags, title);
                else if (s.type == ParagraphType.Iframe)
                  (s as IframeSection).setup(sani);
              });
              this.cards[index] = cardToAdd;
            });
        });
      });
  }
}
export type QuestionAndAnswers = {
  index?: number;
	qFragment?: string;
  question: string;
  questionFontSize: string;
  answers: CustomPageSection[];
};

export type HeroEntry = {
	accordionName: string;
	index: number;
	title: string;
	description: string;
	image: string;
}

export type HeroEntryClick = {
	accordionName: string;
	index: number;

}
export class AccordionQASection implements CustomPageSection {
  type = ParagraphType.Accordion_QA;
  contentListType: ListTypes;
  qaIds: string[];
  questionsAndAnswers: QuestionAndAnswers[];
  label: string;
  entryClicked: BehaviorSubject<any>;
  labelFontSize: string;
  heroCarousel: boolean;
	autoExpandFirstEntry: boolean;
  constructor(
    qaIds: string[],
    label: string,
    heroCarousel: string = "false",
		autoExpandFirstEntry: string = "false",
    label_font_size: string
  ) {
    this.qaIds = qaIds;
    this.label = label;
    this.labelFontSize = label_font_size;
    this.heroCarousel = heroCarousel === "True";
    this.autoExpandFirstEntry = autoExpandFirstEntry === "True";
		console.log(this.label);
		console.log(`auto exp: ${this.autoExpandFirstEntry}`);
  }
  setup(api: ApiService, sani: DomSanitizer, entryRegistation: BehaviorSubject<any>, entryClicked: BehaviorSubject<any>) {
		this.entryClicked = entryClicked;
    const qaObservable = api.getDrupalCustomPageQaEntries(this.qaIds);
    const testqaObservable = qaObservable.pipe(
      mergeMap((qaEntries: questionAnswerResponse[]) => {
				this.registerHeroEntries(qaEntries, entryRegistation);
        const answerObservables = qaEntries.map((qa) =>
          api.getDrupalCustomPageParagraphs(qa.field_answer).pipe(
            map((answers: sectionResponse[]) => {
              return {
								index: this.qaIds.indexOf(qa.id),
                question: qa.field_question,
								qFragment: qa.qFragment,
                questionFontSize: qa.font_size,
                answers: answers.map((s: sectionResponse) => getSectionObj(s)),
              };
            })
          )
				);
        return forkJoin(answerObservables);
      })
    );

    testqaObservable.subscribe((qas: QuestionAndAnswers[]) => {
			qas = qas.sort((q1, q2) => q1.index < q2.index ? -1 : q1.index > q2.index ? 1 : 0);
      this.questionsAndAnswers = qas;
      this.questionsAndAnswers.forEach((qa) =>
        qa.answers.forEach((s: CustomPageSection) => sectionSetupHelper(s, api, sani))
      );
      return this;
    });
  }

	registerHeroEntries(
		qas: questionAnswerResponse[], 
		entryRegistation: BehaviorSubject<any>) {
			const heroEntries = qas.filter(qa => qa.hero_image).map((qa, index) => {
				return {
					accordionName: this.label,
					index: index,
					title: qa.hero_title,
					description: qa.hero_description,
					image: qa.hero_image
				}
			});
			heroEntries.forEach(entry => entryRegistation.next(entry));
	}
}

export class ImageCarouselSection implements CustomPageSection {
  type = ParagraphType.Image_Carousel;
  imageDescriptionIds: string[];
  imageDescriptions: { id: string; image: string; image_description: string }[];

  constructor(imageDescriptionIds: string[]) {
    this.imageDescriptionIds = imageDescriptionIds;
    this.imageDescriptions = [];
  }
  setup(api: ApiService) {
    api
      .getDrupalImageDescriptions(this.imageDescriptionIds)
      .subscribe((imageDescriptions) => {
        this.imageDescriptions = imageDescriptions as any;
      });
  }
}

export class TextListSection implements CustomPageSection {
  type: ParagraphType;
  entries: string[];
  constructor(type: string, entries: string) {
    this.type =
      type == "number"
        ? ParagraphType.Numbered_List
        : ParagraphType.Bullet_List;
    this.entries = entries.split(",");
  }
  setup() {}
}

export type columnEntry = { section: CustomPageSection; percentWidth: string };
export class VariableColumnContainerSection implements CustomPageSection {
  type: ParagraphType;
  entryIds: string[];
  columnEntries: columnEntry[];
  constructor(entryIds: string[]) {
    this.type = ParagraphType.Variable_Column_Container;
    this.entryIds = entryIds;
  }
  setup(api: ApiService, sani: DomSanitizer) {
    const resolvedEntryObservable = this.getResolvedEntriesObservable(
      api,
      this.entryIds
    ) as Observable<columnEntry[]>;
    resolvedEntryObservable.subscribe((entries: columnEntry[]) => {
      this.columnEntries = entries;
      this.columnEntries.forEach((entry: columnEntry) => {
        sectionSetupHelper(entry.section, api, sani);
      });
    });
  }

  getResolvedEntriesObservable(
    api: ApiService,
    entryIds: string[]
  ): Observable<any> {
    type entry = { paragraph_id: string; percent_width: string };
    const entriesObservable = api.getDrupalVariableColumnContainerEntries(
      this.entryIds
    ) as Observable<entry[]>;
    const resolvedEntriesObservable = entriesObservable.pipe(
      mergeMap((entries: entry[]) => {
        const paragraphObservables = entries.map((e: entry) => {
          return api.getDrupalCustomPageParagraphs([e.paragraph_id]).pipe(
            map((s: sectionResponse[]) => {
              return {
                section: s.length ? getSectionObj(s[0]) : undefined,
                percentWidth: e.percent_width,
              };
            })
          );
        });
        return forkJoin(paragraphObservables);
      })
    );
    return resolvedEntriesObservable;
  }
}

export class ContributorsSection implements CustomPageSection {
  type: ParagraphType;
  contributorIds: string[];
  contributors: {
    name: string;
    titles: string[];
    email: string;
    number: string;
    unitNames: string[];
    image: string;
    sections: CustomPageSection[];
  }[] = [];

  constructor(contributorIds: string[]) {
    this.type = ParagraphType.Contributors;
    this.contributorIds = contributorIds;
  }

  setup(api: ApiService) {
    api
      .getDrupalCustomPageContributors(this.contributorIds)
      .subscribe((cards: any[]) => {
        cards.forEach((contributor) => {
          api
            .getDrupalCustomPageParagraphs(contributor.content)
            .subscribe((sects: any[]) => {
              this.contributors.push({
                name: contributor.name,
                titles: contributor.title.split("+"),
                email: contributor.email,
                number: contributor.number,
                unitNames: contributor.unit_name.split("+"),
                image: contributor.image,
                sections: sects.map((sect) => getSectionObj(sect)),
              });
            });
        });
      });
  }
}

export class NavigationDestinationSection implements CustomPageSection {
  type: ParagraphType;
  name: string;
  elementId: string;
  constructor(name: string) {
    this.type = ParagraphType.Navigation_Destination;
    this.name = name;
    this.elementId = name.split(" ").join("-");
  }
  setup() {}
}

type ImageWithInfo = {
	image: string; 
	info: string; 
	showInfo: boolean;
	relative_url?: string; 
	question_fragment?: string; 
}
type ImageEntry = {
	id: string;
	order: number;
	images: ImageWithInfo[]
} 

export type NavEntry = {
  id: string;
  label: string;
  description: string;
  link: { title: string; url: string };
	image_entries: ImageEntry[]
};

export class NavWithImageCarouselSection implements CustomPageSection {
  type: ParagraphType;
  imageIds: string[];
  navIds: string[];
  images: string[];
  imagesWithInfo: { image: string; info: string; relative_url?: string; question_fragment?: string; showInfo: boolean}[][];
  navEntries: NavEntry[];

  constructor(label: string, navIds: string[], imageIds: string[]) {
    this.type = ParagraphType.Nav_With_Image_Carousel;
    this.imageIds = imageIds;
    this.navIds = navIds;
  }

	getImageEntriesObservable(imageEntryIds: string[], api: ApiService): Observable<ImageEntry[]> {
		type ImageEntryRes = {id: string, image_with_info_ids: string[], order: number};
		type ImageInfoRes = {image: string, info: string, relative_url?: string, question_fragment?: string};
		return api.getDrupalImageGridEnties(imageEntryIds).pipe(mergeMap((imageEntries: ImageEntryRes[]) => {
			console.log(imageEntries);
			const resolveImageInfoObservables = imageEntries.map((imageEntry: ImageEntryRes) => {
				return api.getDrupalImageInfos(imageEntry.image_with_info_ids).pipe(map((imageInfos: ImageInfoRes[]) => {
					const iInfos = imageInfos.map((imageInfo: ImageInfoRes) => {
						return {...imageInfo, showInfo: false};
					});
					return {id: imageEntry.id, order: imageEntry.order, images: iInfos}
				}));

			});	
			return forkJoin(resolveImageInfoObservables);
		}));
	}

  setup(api: ApiService) {
		type NavEntryRes = {id: string; label: string; description: string; link: string; image_ids: string[]};

		const navEntryObservable = api.getDrupalNavigationEntries(this.navIds) as Observable<NavEntryRes[]>;
		const resolvedNavEntries: Observable<NavEntry[]> = navEntryObservable.pipe(mergeMap((navEntries: NavEntryRes[]) => {
			console.log(navEntries);
			const resolvedImagesObservable = navEntries.map((navEntry: NavEntryRes) => {
				return this.getImageEntriesObservable(navEntry.image_ids, api).pipe(map((imageEntries: ImageEntry[]) => {
					let link = JSON.parse(navEntry.link)
					link.url = link.url.replace("internal:/", "")
					return { 
						id: navEntry.id,
						label: navEntry.label, 
						description: navEntry.description, 
						link: link, 
						image_entries: navEntry.image_ids.length ? imageEntries: []
					}
				}));
				})
			return forkJoin(resolvedImagesObservable);
		}));
		resolvedNavEntries.subscribe(navEntries => {
			this.navEntries = navEntries;
		});
  }
}

export class VisualLinkSection implements CustomPageSection {
  type = ParagraphType.Visual_Link;
  imageUrl: string;
  linkUrl: string;
  linkText: string;
  constructor(imageUrl: string, link: string) {
    const parsed_link = JSON.parse(link);
    this.linkUrl = parsed_link.url;
    this.linkText = parsed_link.title;
    this.imageUrl = imageUrl;
  }
  setup() {}
}
export class TickQuoteSection implements CustomPageSection {
  type = ParagraphType.Tick_Quote;
  quoteText: string;
  quoteSource: string;
  quoteTitle: string;
  constructor(quoteText: string, quoteSource: string, quoteTitle: string) {
    this.quoteText = quoteText;
    this.quoteSource = quoteSource;
    this.quoteTitle = quoteTitle;
  }
  setup() {}
}
export class CalloutSection implements CustomPageSection {
  type = ParagraphType.Callout;
  body: string;
  header: string;
  constructor(body: string, header: string) {
    this.body = body;
    this.header = header;
  }
  setup() {}
}

function sectionSetupHelper(s: CustomPageSection, api: ApiService, sani?: DomSanitizer): void {
  const type = s.type;
  switch (type) {
    case ParagraphType.Image_Carousel:
      (s as ImageCarouselSection).setup(api);
      break;
    case ParagraphType.Variable_Column_Container:
      (s as VariableColumnContainerSection).setup(api, sani);
      break;
    case ParagraphType.Nav_With_Image_Carousel:
      (s as NavWithImageCarouselSection).setup(api);
      break;
    case ParagraphType.Column:
      (s as ColumnSection).setup(api, sani);
      break;
    case ParagraphType.Video:
      (s as VideoSection).setup(sani);
      break;
    default:
      break;
  }
}

export class ExplorerDashboardsSection implements CustomPageSection {
  type = ParagraphType.Explorer_Dashboards;
  observable: any;
  contentListType: ListTypes;
  constructor() {}
  setup(api: ApiService) {
    this.observable = api.getExplorerDashboards().pipe(
      map((dashboards) =>
        dashboards.map((d) => ({
          ...d,
          headerImage: d.thumbnail,
          description: d.subtitle,
        }))
      )
    );
    this.contentListType = ListTypes.ImageSliderWithDescriptions;
    return this;
  }
}

export class VideoSection implements CustomPageSection {
  type = ParagraphType.Video;
  url: SafeUrl;
  constructor(public videoID: string) {}
  setup(sanitizer: DomSanitizer) {
    this.url = sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${this.videoID}`
    );
  }
}

export class RelatedContentSection implements CustomPageSection {
  type = ParagraphType.Related_Content;
  public tags: string[];
  public parentTitle: string;
  constructor(public source: "KX" | "CFAES" | "Both") {}
  setup(tags: string[], parentTitle: string) {
    this.tags = tags;
    this.parentTitle = parentTitle;
  }
}

export class VisualLinksSection implements CustomPageSection {
  type = ParagraphType.Visual_Links;
  links: VisualLink[];

  constructor(public paragraphIDs: string[]) {}

  setup(api: ApiService) {
    api
      .getDrupalCustomPageParagraphs(this.paragraphIDs)
      .subscribe((res: sectionResponse[]) => {
        this.links = res.map((el) => {
          let link: { title: string; url: string } = JSON.parse(el.link_link);
          return {
            text: link.title,
            url: link.url.replace("internal:", ""),
            colorHex: el.link_color_hex_code,
            imageUrl: el.link_background_image,
          };
        });
      });
  }
}

export class CarouselSection implements CustomPageSection {
  type = ParagraphType.Carousel;
  title: string;
  queryParamString: string;
  contentListType: ListTypes;
  observable;
  constructor(params: sectionResponse) {
    if (params.carousel_label.length > 0) this.title = params.carousel_label;
    this.contentListType =
      params.carousel_content_list_type.length > 1
        ? <ListTypes>params.carousel_content_list_type
        : ListTypes.ImageSlider;
    if (params.carousel_url.length > 0)
      this.queryParamString =
        params.carousel_url
          .replace(/&amp;/g, "&")
          .substr(params.carousel_url.indexOf("?")) + "&_format=json";
    else {
      this.queryParamString = "?_format=json";
      if (params.carousel_topics.length > 0)
        this.queryParamString += params.carousel_topics
          .split(",")
          .map((el) => `&topics%5B%5D=${el}`)
          .join("");
      if (params.carousel_content_types.length > 0)
        this.queryParamString += params.carousel_content_types
          .split(",")
          .map((el) => `&types%5B%5D=${el.toLowerCase()}`)
          .join("");
      if (params.carousel_tags.length > 0)
        this.queryParamString += params.carousel_tags
          .split(",")
          .map((el) => `&tags%5B%5D=${el}`)
          .join("");
    }
  }

  setup(api: ApiService) {
		console.log(this.queryParamString);
    this.observable = api.getDrupalContent(this.queryParamString);

    return this;
  }
}
export const getSectionObj = (section: sectionResponse) => {
  switch (section.section_type) {
    case ParagraphType.Reactions:
      return new ReactionSection();
    case ParagraphType.Carousel:
      return new CarouselSection(section);
    case ParagraphType.Body:
      return new BodySection(
        section.body_title,
        section.body_html,
        section.body_collapsed_section == "True",
        section.body_highlighted_section == "True"
      );
    case ParagraphType.Iframe:
      return new IframeSection(
        section.iframe_title,
        section.iframe_src,
        section.iframe_height,
        section.iframe_width
      );
    case ParagraphType.Searchbar:
      return new SearchbarSection();
    case ParagraphType.Infographic:
      return new InfographicSection(
        section.infographic_title,
        section.infographic_id
      );
    case ParagraphType.Tabs:
      return new TabsSection(section.tabs_title, section.tabs_tabs);
    case ParagraphType.Cards:
      return new CardsSection(section.cards_title, section.cards_cards);
    case ParagraphType.Column:
      return new ColumnSection(
        section.column_sections,
        section.column_left_width,
        section.column_left_offset ?? 0,
        section.column_right_offset ?? 0
      );
    case ParagraphType.Contributor_Highlight:
      return new ContributorHighlightSection(
        section.highlight_name_n,
        section.highlight_text
      );
    case ParagraphType.Video:
      return new VideoSection(section.video_yt_id);
    case ParagraphType.Related_Content:
      return new RelatedContentSection(section.related_content_source);
    case ParagraphType.Visual_Links:
      return new VisualLinksSection(section.links_link_paragraph_ids);
    case ParagraphType.Audio_Clip:
      return new AudioClipSection(
        section.audio_clip_title,
        section.audio_clip_file,
        section.audio_clip_transcript
      );
    case ParagraphType.Quote:
      return new QuoteSection(section.quote_quote, section.quote_author);
    case ParagraphType.Image_Slider:
      return new ImageSliderSection(
        JSON.parse(`[${section.image_slider_items}]`)
      );
    case ParagraphType.Explorer_Dashboards:
      return new ExplorerDashboardsSection();
    case ParagraphType.Accordion_QA:
      return new AccordionQASection(
        section.qa_ids,
        section.carousel_label,
        section.hero_carousel,
        section.auto_expand_first_entry,
        section.label_font_size
      );
    case ParagraphType.Numbered_List:
      return new TextListSection("number", section.list_entries);
    case ParagraphType.Bullet_List:
      return new TextListSection("bullet", section.list_bullets);
    case ParagraphType.Callout:
      return new CalloutSection(section.callout_body, section.callout_header);
    case ParagraphType.Tick_Quote:
      return new TickQuoteSection(
        section.quote_text,
        section.quote_source,
        section.quote_title
      );
    case ParagraphType.Visual_Link:
      return new VisualLinkSection(
        section.visual_link_image,
        section.link_link
      );
    case ParagraphType.Image_Carousel:
      return new ImageCarouselSection(section.image_description_ids);
    case ParagraphType.Navigation_Destination:
      return new NavigationDestinationSection(section.destination_name);
    case ParagraphType.Nav_With_Image_Carousel:
      return new NavWithImageCarouselSection(
        section.carousel_label,
        section.nav_entry_ids,
        section.image_grid_ids
      );
    case ParagraphType.Variable_Column_Container:
      return new VariableColumnContainerSection(
        section.variable_column_container_entry_ids
      );
    case ParagraphType.Contributors:
      return new ContributorsSection(section.contributor_ids);
    default:
      return null;
  }
};

export function devLabelCheck(label: string): boolean {
  let devOnly = label.match(/^dev/g);
  return !devOnly || environment.envFilter == "dev";
}
