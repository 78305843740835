import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { ApiService } from "../services/api.service";

@Injectable({
  providedIn: "root",
})
export class RelatedContentService {
  constructor(private _apiService: ApiService) {}

  getRelatedContentObservable(tags: string[]) {
    let observables = [];
    for (let tag of tags) observables.push(this._apiService.getDrupalTaggedContent(tag));

    forkJoin(observables).subscribe((res) => {
      res = [].concat.apply([], res);
      let frequency = {};
      res.forEach((value: any) => {
        frequency[value.title] = 0;
      });
      let uniques = res.filter((value: any) => {
        return ++frequency[value.title] == 1;
      });
      return uniques.sort((a: any, b: any) => {
        return frequency[b.title] - frequency[a.title];
      });
    });
  }

  sortContent(content: any) {
    let frequency = {};
    content.forEach((value: any) => {
      frequency[value.title] = 0;
    });
    let uniques = content.filter((value: any) => {
      return ++frequency[value.title] == 1;
    });
    return uniques.sort((a: any, b: any) => {
      return frequency[b.title] - frequency[a.title];
    });
  }
}
