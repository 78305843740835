import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(public _titleService:TitleService) { 
    this._titleService.setPageTitle("Contact Us")
  }

  ngOnInit(): void {
  }

}
