import { Component } from "@angular/core";
import { from } from "rxjs";
import { TitleService } from "src/app/services/title.service";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutNewComponent {
  iconSlides = from([
    [
      {
        title: "Extension Partners",
        description:
          "KX provides data and translational tools for understanding and communicating CFAES research to diverse audiences. We value our Extension partners and look for ways to support their important work. ",
        kxIcon: "about_extension_partners",
      },
      {
        title: "Researchers",
        description:
          "KX provides a space to amplify research findings and generate collaborative partnerships via Extension and outreach. We offer a suite of tools to support proposal preparation, record podcasts and videos, develop data visualization, and create content.",
        kxIcon: "about_researchers",
      },
      {
        title: "Partners",
        description:
          "KX provides a centralized resource to exchange ideas, interact with data and researchers, access and use world-class research, and build mutually beneficial partnerships with the university.",
        kxIcon: "about_partners",
      },
      {
        title: "Decision-makers",
        description:
          "KX provides access to data and maps that can inform your decision-making. We offer one-pagers with key takeaways from CFAES research in clear, concise language. We seek collaborations between researchers and other entities in Ohio to help those who are solving the challenging issues affecting communities across our state. ",
        kxIcon: "about_decision_makers",
      },
      {
        title: "KX Team",
        description:
          "Our three teams of creative and innovative professionals work together on our site, a regularly updated resource for research data and knowledge to meet the needs of our varied audiences. Click here to learn more.",
        kxIcon: "about_kx",
        link: "/directory",
      },
    ],
  ]);
  showIcons = false;

  constructor(private titleService: TitleService) {
    this.titleService.setPageTitle(this.titleService.PageTitles.About);
  }

  toggleShowIcons() {
    this.showIcons = !this.showIcons;
  }
}
