import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "kx-icon",
  template: `<img
    class="kx-icon {{ size }} {{ id }}"
    [src]="url"
    [alt]="id + 'icon'"
  />`,
  styleUrls: ["./kx-icon.component.scss"],
})
export class KxIconComponent {
  @Input() id: string;
  @Input() size: "xxsm" | "xsm" | "sm" | "smp" | "md" | "xmd" | "lg" | "xl" = "md";

  public get url(): string {
    return `https://kx-icon-library.s3.amazonaws.com/icons/${this.id}`;
  }
}
