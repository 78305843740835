import { Component, NgZone, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, map, mergeMap } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-search-cfaes",
  templateUrl: "./search-cfaes.component.html",
  styleUrls: ["./search-cfaes.component.scss"],
})
export class SearchCfaesComponent implements OnInit {
  searchTerm = new FormControl("");
  results;
  offset: number = 0;
  count: number = 12;
  resultCount: number = 0;
  resultsType: "cfaes" | "factsheets" | "experts" = "cfaes";
  endpointFn: ({
    q,
    count,
    start,
    includeImages,
  }: {
    q: string;
    count?: number;
    start?: number;
    includeImages?: boolean;
  }) => Observable<any>;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private _router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.type) this.resultsType = params.type;
      if (params.q) {
        this.searchTerm.setValue(params.q);
        this.search();
      }
    });
  }
  paginate(event: PageEvent) {
    // this.pageIndex = event.pageIndex;
    this.offset = event.pageSize * event.pageIndex;
    this.searchTerm.enable();
  }
  ngOnInit(): void {
    // this.endpointFn = this.api.searchCfaesContent;
    // this.results = this.searchTerm.valueChanges.pipe(
    //   mergeMap((value) => this.search(value))
    // );
  }
  searchCfaes() {
    this.resultsType = "cfaes";
    this.updateQueryParams();
    let query = this.searchTerm.value;
    this.results = this.api
      .searchCfaesContent({
        q: query,
        start: this.offset,
        count: this.count,
      })
      .map((results: any) => {
        console.log(query);
        this.resultCount = results.found;
        return results.results;
      });
  }
  searchPeople() {
    this.resultsType = "experts";
    this.updateQueryParams();
    let query = this.searchTerm.value;
    this.results = this.api
      .searchCfaesPeople({
        q: query,
        start: this.offset,
        count: this.count,
      })
      .map((results: any) => {
        console.log(query);
        this.resultCount = results.found;
        return results.results.map((el) => {
          el.title = el.name;
          return el;
        });
      });
  }
  searchFactSheets() {
    this.resultsType = "factsheets";
    this.updateQueryParams();
    let query = this.searchTerm.value;
    this.results = this.api
      .searchFactSheets({
        q: query,
        start: this.offset,
        count: this.count,
      })
      .map((results: any) => {
        console.log(query);
        this.resultCount = results.found;
        return results.results;
      });
  }
  searchKx() {
    let query = this.searchTerm.value;
    window.location.href = "/search?q=" + query;
  }
  search() {
    switch (this.resultsType) {
      case "cfaes":
        this.searchCfaes();
        break;
      case "factsheets":
        this.searchFactSheets();
        break;
      case "experts":
        this.searchPeople();
        break;
      default:
        break;
    }
  }
  getResultTypeMsg() {
    switch (this.resultsType) {
      case "cfaes":
        return "Content from the College of Food, Agricultural, and Environmental Sciences";
      case "factsheets":
        return "Fact Sheets";
      case "experts":
        return "Experts";
      default:
        return "";
    }
  }
  private updateQueryParams() {
    let queryParams: any = {
      type: this.resultsType,
      q: this.searchTerm.value,
    };

    this.ngZone.run(() =>
      this._router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParams,
      })
    );
  }
  // search(query: string): Observable<any> {
  //     return this.endpointFn({
  //       q: query,
  //       start: this.offset,
  //       count: this.count,
  //     }).map((results: any) => {
  //       console.log(query);
  //       this.resultCount = results.found;
  //       if (query.length == 0) this.resultCount = 0;
  //       return results.results;
  //     });
  // }
}
