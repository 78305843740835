<content-list
  *ngIf="KxRelatedContentObservable && (source === 'kx' || source === 'both')"
  [content]="KxRelatedContentObservable"
  type="image-slider"
  head="{{ source === 'both' ? 'Similar items from KX' : 'You may also be interested in:' }}"
></content-list>

<content-list
  *ngIf="
    CfaesRelatedContentObservable && (source == 'cfaes' || source === 'both') && !router.url.includes('profiles')
  "
  [content]="CfaesRelatedContentObservable"
  type="text-slider"
  head="Similar items from around the college"
></content-list>

<content-list
  *ngIf="
    CfaesPeopleObservable && (source == 'cfaes' || source === 'both')
  "
  [content]="CfaesPeopleObservable"
  type="text-slider"
  head="People who may be working in this field"
></content-list>
<br>