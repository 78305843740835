import { Component, Inject, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Hotspot, Infographic } from "../util";

@Component({
  selector: "app-modal-display",
  templateUrl: "./modal-display.component.html",
  styleUrls: ["./modal-display.component.scss"],
  host: {
    "(window:resize)": "onResize($event)",
  },
  encapsulation:ViewEncapsulation.None
})
export class ModalDisplayComponent implements OnInit {
  @Input() infographic: Infographic;
  activeHotspotIndex: number;
  clientCursorPosition: { x: number; y: number } = { x: 0, y: 0 };
  imageHeight: number = 0;
  constructor(public dialog: MatDialog) {}

  onResize(event) {
    this.getImgHeight();
  }

  ngOnInit() {}
  getImgHeight() {
    this.imageHeight = document.getElementById(
      "infographic-image"
    ).offsetHeight;
    return this.imageHeight > 0 ? this.imageHeight : 64;
  }
  getButtonStyle(hotspot: Hotspot) {
    return {
      top: hotspot.topPercent.toString() + "%",
      left: hotspot.leftPercent.toString() + "%",
    };
  }
  isActive(index: number) {
    return this.activeHotspotIndex == index;
  }
  toggleHotspot(event: any, index: number) {
    this.activeHotspotIndex == index
      ? this.deactivateHotspot()
      : this.activateHotspot(event, index);
  }
  activateHotspot(event: any, index: number) {
    this.activeHotspotIndex = index;
    this.openDialog();
  }
  deactivateHotspot() {
    this.activeHotspotIndex = undefined;
  }
  goTo(link: string) {
    window.open(link, "_blank");
  }
  openDialog() {
    this.dialog.open(HotspotDialog, {
      data: this.infographic.hotspots[this.activeHotspotIndex],
      maxWidth: "512px",
      panelClass: "infographic-modal-popup",
    });
  }
}

@Component({
  selector: "hotspot-dialog",
  templateUrl: "hotspot-dialog.html",
})
export class HotspotDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public h: Hotspot,public dialogRef: MatDialogRef<HotspotDialog>,) {}
  goToMap() {
    let route = `/food-system-tool/map?layers=${this.h.layerIds.join(
      "&layers="
    )}`;
    window.location.href = route;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
