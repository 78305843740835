<ng-container *ngIf="!router.url.startsWith('/embed'); else embedMode">
  <ng-container *ngIf="authorized; else authOverlay">
    <header>
      <app-buckeye-alert></app-buckeye-alert>
      <app-osu-navbar></app-osu-navbar>
    </header>

    <app-navbar></app-navbar>
    <main>
      <app-announcements-header *ngIf="isHomePage"></app-announcements-header>
      <router-outlet></router-outlet>
      <app-scroll-to-top></app-scroll-to-top>
      <app-contact-us-overlay *ngIf="!hideContactButton"></app-contact-us-overlay>
    </main>
    <footer>
      <app-footer></app-footer>
    </footer>
  </ng-container>
  <ng-template #authOverlay>
    <div id="auth-overlay">
      <mat-form-field appearance="standard">
        <mat-label>Enter your OSU name.# to continue</mat-label>
        <input
          matInput
          [(ngModel)]="username"
          placeholder="buckeye.1"
          (keyup.enter)="authorize()"
        />
        <mat-hint *ngIf="failedAttempts > 0">
          {{ failedAttempts - 1 }} failed attempt{{
            failedAttempts > 2 ? "s" : ""
          }}
        </mat-hint>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="authorize()">
        Submit
      </button>
    </div>
  </ng-template>
</ng-container>
<ng-template #embedMode>
  <div class="m-2">
    <router-outlet></router-outlet>
  </div>
</ng-template>
