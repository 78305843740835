import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { getBackgroundImageStyles } from "../../functions/ng-style-utils";

@Component({
  selector: "app-generic-callout",
  templateUrl: "./generic-callout.component.html",
  styleUrls: ["./generic-callout.component.scss"],
})
export class GenericCalloutComponent implements OnChanges {
  @Input() head: string;
  @Input() subhead: string;
  @Input() image: string;
  @Input() link: string = null;
  @Input() indicateHover: boolean = false;
  @Input() side: "left" | "right";
  @Input() color: "primary" | "secondary" | "tertiary" | "light";

  constructor() {}

  ngOnChanges(): void {
    this.indicateHover = this.link && this.link.length > 0;
    if (this.link == "./") {
      this.link = null;
      this.indicateHover = true;
    }
  }

  getImageStyle() {
    return this.image?.length > 0
      ? getBackgroundImageStyles(0, this.image)
      : { background: "#ddd" };
  }
}
