import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
} from "@angular/core";
const path_links = {
  ".st0": "/page/fs-community",
  ".st1": "/page/fs-economics",
  ".st2": "/page/fs-environment",
  ".st12": "/page/fs-processing",
  ".st13": "/page/fs-production",
  ".st14": "/page/fs-retail",
  ".st15": "/page/fs-distribution",
};
@Component({
  selector: "app-simple-visualization",
  templateUrl: "./simple-visualization.component.html",
  styleUrls: ["./simple-visualization.component.scss"],
})
export class SimpleVisualizationComponent implements AfterViewInit {
  constructor(private elem: ElementRef) {}

  ngAfterViewInit() {
    for (let elementClass in path_links) {
      let el = <HTMLElement>this.elem.nativeElement.querySelector(elementClass);

      let navigateFn = () => (window.location.href = path_links[elementClass]);
      el.tabIndex = 0;
      el.onclick = navigateFn;
      el.onkeyup = (event) => {
        if (event.key == "Enter") navigateFn();
      };
    }
  }
}
