<h3 *ngIf="user">Hi {{ user.name }}, let's change your password.</h3>

<mat-form-field appearance="standard">
  <mat-label>Enter a new password</mat-label>
  <input
    matInput
    [formControl]="password"
    [type]="hidePassword ? 'password' : 'text'"
  />
  <mat-error *ngIf="password.invalid">
    {{ getPasswordErrorMessage() }}
  </mat-error>
  <button
    mat-icon-button
    matSuffix
    (click)="hidePassword = !hidePassword"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hidePassword"
  >
    <mat-icon>{{ hidePassword ? "visibility_off" : "visibility" }}</mat-icon>
  </button>
</mat-form-field>
<br><br>
<button
  mat-stroked-button
  [disabled]="!registrationInputsAreValid() || thinking"
  (click)="register()"
>
  Submit
  <span *ngIf="thinking">...</span>
</button>
