import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Dictionary } from 'highcharts';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.scss']
})
export class TopicsPageComponent implements OnInit {
  public topics;
  public buckets:Object={};
  public letters: string[]=[];
  dataReceiptPromise: Promise<boolean>;

  constructor(private _api: ApiService, private titleService: TitleService) { }

  async load(){
    this.topics = await this._api.getDrupalTopics()
    Array.from('abcdefghijklmnopqrstuvwxyz').forEach((letter)=>{
      this.topics.forEach(topic => {
        if (topic.url.charAt(0) == letter){
          if (!this.buckets.hasOwnProperty(letter)){
            Object.defineProperty(this.buckets,letter,{value:[]})
          } 
          this.buckets[letter].push(topic)
        }
      });
    })
    this.letters= Object.getOwnPropertyNames(this.buckets)
  }
  ngOnInit() {
    this.load()
    this.titleService.setPageTitle(this.titleService.PageTitles.Topics);
  }

}
