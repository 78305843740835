import { Component, OnInit } from "@angular/core";
import { ApiService } from "./../services/api.service";
import { TitleService } from "../services/title.service";
import { ListTypes } from "../shared/components/content-list/util";
import { from } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  iconSlideList = [
    {
      title: "eFields",
      description:
      "Search all on-farm trial reports on new in-field practices and explore trial data to improve farm efficiency and profitability. ",
      kxIcon: "eFields_Orange_v2",
      link: "/efields",
    },
    {
      title: "Food System Map",
      description:
      "Interact with data from all aspects of the Ohio food system to reveal connections and opportunities for improvement. ",
      kxIcon: "food_system_map_orange",
      link: "/food-system-tool",
    },
    {
      title: "Expert Directory",
      description:
      "Get connected to the world-class experts in the College of Food, Agricultural, and Environmental Sciences. ",
      kxIcon: "expert_directory_teal",
      link: "/expert-directory",
    },
    {
      title: "Community Profiles",
      description:
      "Get easy access to data about your local community. View a collection of maps, charts, and data widgets of vital census information.  ",
      kxIcon: "community_profiles_blue_v3",
      link: "/landing-page/profiles",
    },
    {
      title: "Knowledge Explorer",
      description:
      "Select data widgets, add captions, and build a custom report you can export to PDF with this tool. ",
      kxIcon: "knowledge_explorer_green",
      link: "/landing-page/knowledge-explorer",
    },
    {
      title: "eBarns",
      description:
      "Search and save on-farm reports evaluating improved methods and management practices to advance livestock production. ",
      kxIcon: "eBarns_Teal",
      link: "/ebarns",
    },
    {
      title: "4-H Data Dashboard",
      description:
      "Discover the makeup of 4-H membership in Ohio, their communities, and number of volunteers by congressional district.",
      kxIcon: "4_h_dashboard_blue",
      link: "https://experience.arcgis.com/experience/452a09a2c314415fa1bc9d8ec71b05d1/",
    },
    {
      title: "RECIPES",
      description:
      "Explore waves of data from the National Household Food Waste Tracking Survey",
      kxIcon: "recipes_orange",
      link: "/landing-page/recipes",
    },
    {
      title: "Corn Silage Pricing Tool",
      description:
        "Negotiate corn silage pricing using this Ohio-specific tool with local rates and costs. ",
      kxIcon: "corn_silage_blue",
      link: "/ffmpi/corn-silage",
    },
  ];
  
  devOnlyIconSlideList = [];
  
  get iconList(): any[] {
    return environment.name == "DEVELOPMENT"
      ? [...this.iconSlideList, this.devOnlyIconSlideList]
      : this.iconSlideList;
  }

  iconSlides = from([this.iconList]);

  buckets: string[] = [];
  recentContent;
  featuredContent;
  isProduction = environment.production;

  constructor(
    private _apiService: ApiService,
    private titleService: TitleService
  ) {
    this.titleService.setDefaultTitle();
    this.recentContent = this._apiService.getDrupalFilteredContent({
      types: [
        "structured_story",
        "image_collection",
        "link",
        "file",
        "custom_page",
        "video",
        "virtual_tour",
        "podcast",
      ],
    });
    this.featuredContent = this._apiService.getDrupalContent(
      "?_format=json&tags%5B%5D=566"
    );
  }

  ngOnInit() {
    this._apiService
      .getDrupalTopicsByBucket()
      .subscribe(
        (bucks: any[]) => (this.buckets = bucks.map((buck) => buck.name))
      );
  }
}
