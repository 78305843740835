import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-kx-logo",
  templateUrl: "./kx-logo.component.html",
  styleUrls: ["./kx-logo.component.scss"],
})
export class KxLogoComponent implements OnInit {
  @Input() size: "sm" | "lg" = "sm";
  @Input() orientation: "stacked" | "horizontal" = "horizontal";
  @Input() light: boolean = true;
  constructor() {}

  ngOnInit(): void {}
}
