import { Component, Input, OnChanges } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { forkJoin, Observable, of } from "rxjs";
import { SearchService } from "src/app/services/search.service";
import { SearchFilters, SearchOptions } from "src/app/main/search/etc/options";
import { SORT } from "src/app/main/search/etc/sort";
import { Router } from "@angular/router";

@Component({
  selector: "app-related-content",
  templateUrl: "./related-content.component.html",
  styleUrls: ["./related-content.component.scss"],
})
export class RelatedContentComponent implements OnChanges {
  @Input() tags: string[] = [];
  @Input() keyword: string = "";
  @Input() parentTitle?: string;
  @Input() source: "kx" | "cfaes" | "both" = "kx";

  public content: any;
  public KxRelatedContentObservable: Observable<Object>;
  public CfaesRelatedContentObservable: Observable<Object>;
  public CfaesPeopleObservable: Observable<Object>;

  constructor(
    private _apiService: ApiService,
    private _searchService: SearchService,
    public router: Router
  ) {}

  ngOnChanges() {
    this.searchKX();
    this.searchCFAES();
  }
  searchCFAES() {
    let searchTerm =
      this.keyword.length > 0 ? this.keyword : this.tags.join(" ");
    this.CfaesRelatedContentObservable = this._apiService
      .searchCfaesContent({
        q: searchTerm,
        count: 10,
      })
      .map((results: any) => {
        // console.log(searchTerm, results);
        return results.results;
      });
    this.CfaesPeopleObservable = this._apiService
      .searchCfaesPeople({
        q: searchTerm,
        count: 10,
      })
      .map((results: any) => {
        // console.log(searchTerm, results);
        return results.results.map((el) => {
          el.title = el.name;
          return el;
        });
      });
  }
  searchKX() {
    let options = new SearchOptions({
      size: 10,
      sort: SORT.RELEVANCE,
      start: 0,
      query: this.keyword,
      filters: new SearchFilters({ tags: new Set(this.tags) }),
    });
    // console.log(options);
    this.KxRelatedContentObservable = this._searchService
      .search(options, false)
      .map((result: any) => {
        return (
          result.hits?.hit.map((el) => {
            el.fields.headerImage = el.fields.header_image;
            return el.fields;
          }) ?? []
        );
      });
  }
}
