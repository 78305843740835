export function getBackgroundImageStyles(
  darkness: number,
  imageSource: string
) {
  return {
    "background-image": `linear-gradient(rgba(0,0,0,${darkness}), rgba(0,0,0,${darkness})), url(${imageSource})`,
    "background-position": "center center",
    "background-size": "cover",
    "background-repeat": "no-repeat"
  };
}
