<div class="bucket-preview row">
  <div class=" col-12 col-md-3 information">
    <h3>{{ name }}</h3>
    <p class="desc">{{ description }}</p>
    <a class="osu-button lg" href="/bucket/{{ name }}">View All</a>
  </div>
  <app-content-slider
    class="col-12 col-md-9"
    [contentObservable]="observable"
    [joinedObservable]="true"
  ></app-content-slider>
</div>