import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { Observable } from "rxjs";
import { Content, ContentType } from "src/app/shared/models/content";
import { TitleService } from "src/app/services/title.service";

@Component({
  selector: "app-topic",
  templateUrl: "./topic.component.html",
  styleUrls: ["./topic.component.scss"],
  animations: [],
})
export class TopicComponent {
  carousels: { title?: string; observable: Observable<object> }[];

  questionsObservable: Observable<object>;
  storiesObservable: Observable<object>;
  videosObservable: Observable<object>;
  podcastsObservable: Observable<object>;
  filesObservable: Observable<object>;
  linksObservable: Observable<object>;
  knowChangeObservable: Observable<object>;
  infographicsObservable: Observable<object>;
  mainCarouselObservable: Observable<object>;

  contentLoaded: boolean;

  overridden: boolean = false;
  customPageOverrideNodeID: string;

  public topicUrl: string;
  public topicName: string;
  public topicImage: string = "";

  public teasers: Content[] = [];

  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private titleService: TitleService
  ) {
    this.route.params.subscribe((params) => {
      this.topicUrl = params.topic;
      this.loadData();
    });
  }

  async loadData() {
    let thisTopic: any = (await this._apiService.getDrupalTopics()).find(
      (el) => el.url == this.topicUrl
    );

    this.topicName = thisTopic.name;
    this.topicImage = thisTopic.image;
    this.overridden = thisTopic.overridden == "True";
    this.customPageOverrideNodeID = thisTopic.custom_page_node_id;
    this.titleService.setPageTitle(this.topicName);

    this.mainCarouselObservable = this._apiService.getDrupalFilteredContent({
      types: ["image_collection", "structured_story", "custom_page"],
      topics: [this.topicName],
    });
    this.questionsObservable = this._apiService.getDrupalQuestions(
      this.topicName
    );
    this.storiesObservable = this._apiService.getDrupalFilteredContent({
      types: ["structured_story"],
      topics: [this.topicName],
    });
    this.videosObservable = this._apiService.getDrupalFilteredContent({
      types: ["video"],
      topics: [this.topicName],
    });
    this.podcastsObservable = this._apiService.getDrupalFilteredContent({
      types: ["podcast"],
      topics: [this.topicName],
    });
    this.filesObservable = this._apiService.getDrupalFilteredContent({
      types: ["file"],
      topics: [this.topicName],
    });
    this.linksObservable = this._apiService.getDrupalFilteredContent({
      types: ["link"],
      topics: [this.topicName],
    });
    this.knowChangeObservable = this._apiService.getDrupalKnowChangeNotes(
      encodeURIComponent(this.topicName)
    );
    // this.infographicsObservable = this._apiService.getInfographics(
    //   encodeURIComponent(this.topicName)
    // );

    this.storiesObservable.subscribe((result: any[]) => {
      result.forEach((st) =>
        this.teasers.push(new Content(ContentType.STORY, null, st))
      );
      let first = -1;
      let second = 1;
      this.teasers.sort((item1, item2) => {
        let date1 = item1.date;
        let date2 = item2.date;
        if (date1 === undefined) {
          return first;
        }
        if (date2 === undefined) {
          return second;
        }
        return date1 >= date2 ? second : first;
      });
      this.teasers = this.teasers.slice(0, 3);
    });

    if (!this.overridden)
      window.location.href =
        "/search?topics=" + encodeURIComponent(this.topicName);

    // this.contentLoaded = true;
  }
}
