import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { CookieService } from "ngx-cookie";
import { Observable } from "rxjs";

const sixMonths = 15778800000;

type loginSuccessRes = { verified: true; sessionToken: string };
type loginFailRes = { verified: false; err: any; msg: string };
type loginAttemptRes = loginSuccessRes | loginFailRes;

export interface AcpfSessionToken {
  _id: string;
  sessionToken: string;
}

@Injectable({
  providedIn: "root",
})
export class AcpfAuthenticationService {
  public userId: string = null;
  public failedAttempts: number = 0;
  public token: AcpfSessionToken = null;
  public authorized: boolean = false;

  constructor(private api: ApiService, private CookieService: CookieService) {}

  attemptLogin(password: string) {
    return new Promise<AcpfSessionToken>((resolve, reject) => {
      if (this.userId) {
        this.api
          .acpfLoginAttempt({ _id: this.userId, password: password })
          .subscribe((res: loginAttemptRes) => {
            if (res.verified) {
              this.setToken(res.sessionToken);
              this.setCookies();
              resolve(this.token);
            } else {
              this.failedAttempts++;
              reject("Invalid username or password");
            }
          });
      }
    });
  }

  setToken(sessionTokenValue: string) {
    this.authorized = true;
    this.token = { _id: this.userId, sessionToken: sessionTokenValue };
  }

  validateFromCookies() {
    return new Promise<AcpfSessionToken>((resolve, reject) => {
      let allCookies = this.CookieService.getAll();

      // make sure all cookies exist
      if (
        !(
          allCookies.KX_ACPF_USER_LAST_AUTH_DATE &&
          allCookies.KX_ACPF_USER_ID &&
          allCookies.KX_ACPF_USER_SESSION_TOKEN
        )
      )
        reject("Cookies don't exist.");

      // make sure they've logged in during last 6 months
      if (
        new Date().getTime() -
          new Date(allCookies.KX_ACPF_USER_LAST_AUTH_DATE).getTime() >
        sixMonths
      )
        reject("Token expired.");

      let token = {
        _id: allCookies.KX_ACPF_USER_ID,
        sessionToken: allCookies.KX_ACPF_USER_SESSION_TOKEN,
      };
      this.api
        .acpfCheckToken(token)
        .subscribe((res: { valid: string; err?: any }) => {
          if (res.valid) {
            this.userId = token._id;
            this.setToken(token.sessionToken);
            this.setCookies();
            resolve(this.token);
          } else {
            this.clearCookies();
            reject("Invalid or old Token stored. Was cleared from cookies.");
          }
        });
    });
  }

  private setCookies() {
    if (!this.token) return;
    this.CookieService.put(
      "KX_ACPF_USER_LAST_AUTH_DATE",
      new Date().toISOString()
    );
    this.CookieService.put("KX_ACPF_USER_ID", this.token._id);
    this.CookieService.put(
      "KX_ACPF_USER_SESSION_TOKEN",
      this.token.sessionToken
    );
  }
  private clearCookies() {
    this.CookieService.remove("KX_ACPF_USER_LAST_AUTH_DATE");
    this.CookieService.remove("KX_ACPF_USER_ID");
    this.CookieService.remove("KX_ACPF_USER_SESSION_TOKEN");
  }
  logOut() {
    if (this.authorized) {
      this.authorized = false;
      this.clearCookies();
      this.reset();
    }
  }

  reset() {
    this.token = null;
    this.userId = null;
    this.failedAttempts = 0;
  }

  getCurrentUser() {
    return <Observable<AcpfUser>>this.api.getAcpfUserById(this.userId);
  }
}

export type AcpfUser = {
  name: string;
  email: string;
  organization: string;
  fileUrls: string[];
  files: any[];
  verified: boolean;
  _id: string;
};
