import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AcpfAuthenticationService,
  AcpfSessionToken,
} from "src/app/services/acpf-auth.service";

enum AcpfFileManagerAppStatus {
  Init = "Init",
  Manage = "Manage",
  Upload = "Upload",
  Reset_Password = "Reset_Password",
}

@Component({
  selector: "app-acpf-file-manager",
  templateUrl: "./acpf-file-manager.component.html",
  styleUrls: ["./acpf-file-manager.component.scss"],
})
export class AcpfFileManagerComponent implements OnInit {
  status: AcpfFileManagerAppStatus = AcpfFileManagerAppStatus.Init;

  AcpfFileManagerAppStatus = AcpfFileManagerAppStatus;

  token: AcpfSessionToken;

  url:string=""
  constructor(
    private route: ActivatedRoute,
    private auth: AcpfAuthenticationService,
    public router: Router
  ) {
    this.auth
      .validateFromCookies()
      .then((res) => {
        this.status = AcpfFileManagerAppStatus.Manage;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.route.url.subscribe((segments) => {
          console.log(segments);
          
          if (segments[1]?.toString() == "reset-password")
            this.status = AcpfFileManagerAppStatus.Reset_Password;
          else if (segments[1]?.toString() == "upload" && this.auth.authorized)
          this.status = AcpfFileManagerAppStatus.Upload;
          else if (
            segments[1]?.toString() == "log-out" &&
            this.auth.authorized
          ) {
            this.auth.logOut();
            this.status = AcpfFileManagerAppStatus.Init;
            this.router.navigate(["/acpf-file-manager"], {
              skipLocationChange: false,
            });
          }
        });
      });
  }

  ngOnInit(): void {}
}
