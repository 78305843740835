import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VideoPopupComponent } from "./components/video-popup/video-popup.component";
import { LargeContentSliderComponent } from "./components/content-list/content-slider-lg/content-slider-lg.component";
import { ContentSliderComponent } from "./components/content-list/content-slider/content-slider.component";
import { ScrollPositionDirective } from "./directives/scroll-position.directive";
import { SearchBarComponent } from "./components/searchbar/searchbar.component";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RelatedContentComponent } from "./components/related-content/related-content.component";
import { CustomPageComponent } from "./custom-stuff/custom-page/custom-page.component";
import { LoadingIndicatorComponent } from "./components/loading-indicator/loading-indicator.component";
import { CustomPageSectionComponent } from "./custom-stuff/etc/section/custom-page-section.component";
import { BucketPreviewComponent } from "./components/bucket-preview/bucket-preview.component";
import { FullPageImageHeaderComponent } from "./components/full-page-image-header/full-page-image-header.component";
import { GenericCalloutComponent } from "./components/generic-callout/generic-callout.component";
import { ContentListComponent } from "./components/content-list/content-list.component";
import { TextSliderComponent } from "./components/content-list/text-slider/text-slider.component";
import { ImageSliderComponent } from "./components/content-list/image-slider/image-slider.component";
import { IconSliderComponent } from "./components/content-list/icon-slider/icon-slider.component";
import { CalloutComponent } from "./components/content-list/callout/callout.component";
import { CalloutGroupComponent } from "./components/content-list/callout-group/callout-group.component";
import { GridComponent } from "./components/content-list/grid/grid.component";
import { SwiperModule } from "swiper/angular";
import { KxIconComponent } from "./components/kx-icon/kx-icon.component";
import { KxLogoComponent } from "./components/kx-logo/kx-logo.component";
import { ImageMapInfographicComponent } from "./components/image-map-infographic/image-map-infographic.component";
import { VerticalScrollComponent } from "./components/content-list/vertical-scroll/vertical-scroll.component";
import { BasicListComponent } from "./components/content-list/basic-list/basic-list.component";
import { MiniSliderComponent } from "./components/content-list/mini-slider/mini-slider.component";
import { Grid2Component } from "./components/content-list/grid2/grid2.component";
import {
  HotspotDialog,
  ModalDisplayComponent,
} from "./components/image-map-infographic/modal-display/modal-display.component";
import { MousePopupDisplayComponent } from "./components/image-map-infographic/mouse-popup-display/mouse-popup-display.component";
import { SidebarDisplayComponent } from "./components/image-map-infographic/sidebar-display/sidebar-display.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { SubscribeDialogComponent } from "./components/subscribe-dialog/subscribe-dialog.component";
import { SliderArrowIconComponent } from "./components/slider-arrow-icon/slider-arrow-icon.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SocialLinksComponent } from "./components/social-links/social-links.component";
import { StopEventPropagationDirective } from "./directives/stop-event-propagation.directive";
import { ContactUsFormComponent } from "./components/contact-us/contact-us-form/contact-us-form.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import {
  ContactPopupComponent,
  ContactUsOverlayComponent,
} from "./components/contact-us/contact-us-overlay/contact-us-overlay.component";
import { CamelCaseToSpacePipe } from "./pipes/camel-case-to-space.pipe";
import { MatTabsModule } from "@angular/material/tabs";
import { AcpfFileManagerComponent } from "./components/acpf-file-manager/acpf-file-manager.component";
import { AcpfFmListFilesUploadedComponent } from "./components/acpf-file-manager/list-files-uploaded/acpf-fm-list-files-uploaded.component";
import { AcpfFmLoginComponent } from "./components/acpf-file-manager/login/acpf-fm-login.component";
import { AcpfFmResetPasswordComponent } from "./components/acpf-file-manager/reset-password/acpf-fm-reset-password.component";
import { AcpfFmUploadComponent } from "./components/acpf-file-manager/upload/acpf-fm-upload.component";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule } from "@angular/material/stepper";
import { MatListModule } from "@angular/material/list";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AnimateModule } from "@wizdm/animate";
import { ChartModule } from "angular-highcharts";
import { AcpfContactInfoFormComponent } from "./components/contact-info-solicitation/acpf/acpf-form.component";
import { CustomLandingPageComponent } from "./custom-stuff/custom-landing-page/custom-landing-page.component";
import { ExplorerCartComponent } from "./components/explorer-cart/explorer-cart.component";
import { MatMenuModule } from "@angular/material/menu";
import { NewslettersComponent } from "./components/newsletters/newsletters.component";
import { PanoramaComponent } from "./components/panorama/panorama.component";
import { PanoHotspotPopupDialog } from "./components/panorama/hotspot-popup.component";
import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from "@angular/cdk/overlay";
import { PanoFloorPlanPopupDialog } from "./components/panorama/floor-plan-popup.component";
import { DomSanitizerPipe } from "./pipes/dom-sanitizer.pipe";
import { LineGraphChipListComponent } from "./components/line-graph-chip-list/line-graph-chip-list.component";
import { ImageSliderWithDescriptionsComponent } from "./components/content-list/image-slider-with-descriptions/image-slider-with-descriptions.component";
import { SendToExplorerButtonComponent } from "./components/send-to-explorer-button/send-to-explorer-button.component";
import { ReactionComponent } from "./components/reactions/app-reactions";
import { CustomPageNavigatorComponent } from "./custom-stuff/custom-page-navigator/custom-page-navigator.component";
import { AccordionQaComponent } from "./components/accordion-qa/accordion-qa.component";
import { TextListComponent } from "./components/text-list/text-list.component";
import { QuoteComponent } from "./components/quote/quote.component";
import { VisualLinkComponent } from "./components/visual-link/visual-link.component";
import { TickCalloutComponent } from "./components/tick-callout/tick-callout.component";
import { LifeCycleSliderComponent } from "./components/life-cycle-slider/life-cycle-slider.component";
import { NavWithImageCarouselComponent } from "./components/nav-with-image-carousel/nav-with-image-carousel.component";
import { WhatsNewSwiperComponent } from "./components/content-list/whats-new-swiper/whats-new-swiper.component";
import { VariableColumnContainerComponent } from "./components/variable-column-container/variable-column-container.component";
import { RouterModule } from "@angular/router";
import { ContributorsComponent } from "./components/contributors/contributors.component";
import { ContributorListComponent } from "./components/contributor-list/contributor-list.component";
import { HeroSwiperComponent } from "./components/hero-swiper/hero-swiper.component";
import { HighlightComponent } from "./components/content-list/highlight/highlight.component";
import { PhoneNumberPipe } from "./pipes/phone-number.pipe";
import { HomeButtonComponent } from "./components/home-button/home-button.component";
import { EmbeddedPowerBiReportComponent } from "./components/embedded-power-bi-report/embedded-power-bi-report.component";
import { PowerBIEmbedModule } from "powerbi-client-angular";

@NgModule({
  declarations: [
    AcpfFileManagerComponent,
    AcpfFmLoginComponent,
    AcpfFmListFilesUploadedComponent,
    AcpfFmUploadComponent,
    AcpfFmResetPasswordComponent,
    CustomPageComponent,
    VideoPopupComponent,
    ContentSliderComponent,
    LargeContentSliderComponent,
    ScrollPositionDirective,
    SearchBarComponent,
    RelatedContentComponent,
    LoadingIndicatorComponent,
    CustomPageSectionComponent,
    BucketPreviewComponent,
    FullPageImageHeaderComponent,
    GenericCalloutComponent,
    ContentListComponent,
    TextSliderComponent,
    ImageSliderComponent,
    IconSliderComponent,
    CalloutComponent,
    CalloutGroupComponent,
    GridComponent,
    Grid2Component,
    BasicListComponent,
    MiniSliderComponent,
    VerticalScrollComponent,
    HighlightComponent,
    KxIconComponent,
    KxLogoComponent,
    ImageMapInfographicComponent,
    ModalDisplayComponent,
    MousePopupDisplayComponent,
    SidebarDisplayComponent,
    HotspotDialog,
    SubscribeDialogComponent,
    SliderArrowIconComponent,
    SocialLinksComponent,
    StopEventPropagationDirective,
    ContactUsFormComponent,
    ContactUsOverlayComponent,
    ContactPopupComponent,
    CamelCaseToSpacePipe,
    CustomLandingPageComponent,
    AcpfContactInfoFormComponent,
    ExplorerCartComponent,
    NewslettersComponent,
    PanoramaComponent,
    PanoHotspotPopupDialog,
    PanoFloorPlanPopupDialog,
    DomSanitizerPipe,
    LineGraphChipListComponent,
    ImageSliderWithDescriptionsComponent,
    SendToExplorerButtonComponent,
    ReactionComponent,
    CustomPageNavigatorComponent,
    AccordionQaComponent,
    TextListComponent,
    QuoteComponent,
    VisualLinkComponent,
    TickCalloutComponent,
    LifeCycleSliderComponent,
    NavWithImageCarouselComponent,
    WhatsNewSwiperComponent,
    VariableColumnContainerComponent,
    ContributorsComponent,
    ContributorListComponent,
    HeroSwiperComponent,
    PhoneNumberPipe,
    HomeButtonComponent,
    EmbeddedPowerBiReportComponent,
  ],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  imports: [
    CommonModule,
    FormsModule,
    SwiperModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatStepperModule,
    MatListModule,
    NgbModule,
    ChartModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    DragDropModule,
    AnimateModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatTableModule,
    MatChipsModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatRadioModule,
    MatDividerModule,
    RouterModule,
    PowerBIEmbedModule,
  ],
  exports: [
    CustomPageComponent,
    VideoPopupComponent,
    ContentSliderComponent,
    LargeContentSliderComponent,
    ScrollPositionDirective,
    SearchBarComponent,
    RelatedContentComponent,
    LoadingIndicatorComponent,
    BucketPreviewComponent,
    FullPageImageHeaderComponent,
    GenericCalloutComponent,
    ContentListComponent,
    KxIconComponent,
    KxLogoComponent,
    ImageMapInfographicComponent,
    SubscribeDialogComponent,
    SocialLinksComponent,
    ContactUsFormComponent,
    ContactUsOverlayComponent,
    CamelCaseToSpacePipe,
    DomSanitizerPipe,
    CustomLandingPageComponent,
    AcpfFileManagerComponent,
    AcpfContactInfoFormComponent,
    ExplorerCartComponent,
    PanoramaComponent,
    LineGraphChipListComponent,
    SendToExplorerButtonComponent,
    ReactionComponent,
    CustomPageNavigatorComponent,
    SliderArrowIconComponent,
    ContributorListComponent,
    HomeButtonComponent,
    EmbeddedPowerBiReportComponent,
  ],
})
export class SharedModule {}
