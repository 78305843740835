import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  PLATFORM_ID,
  AfterViewChecked,
  AfterViewInit,
} from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { Location, isPlatformBrowser } from "@angular/common";
import { LandingPageComponent } from "./main/landing-page.component";
import { environment } from "../environments/environment";
import { AuthService } from "./services/auth.service";
import { Environment } from "src/environments/env";
import { E } from "@angular/cdk/keycodes";

const CONTACT_US_EXCLUDED_URL_SEGMENTS = [
  "pano",
  "landing-page/watershed-project",
];

declare let gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  hideContactButton = false;
  environment: Environment = environment;
  authorized: boolean = false;
  isHomePage: boolean = false;
  failedAttempts: number = 0;
  username: string = "";

  @ViewChild(LandingPageComponent)
  landingPage: LandingPageComponent;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    public router: Router,
    private _auth: AuthService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isHomePage = this.router.url == "/";
        if (environment.production) {
          gtag("config", "G-XLV0LECF0F", { page_path: this.router.url });
          gtag("config", "UA-163129211-2", { page_path: this.router.url });
        }

        this.hideContactButton = CONTACT_US_EXCLUDED_URL_SEGMENTS.some((v) =>
          this.router.url.includes(v)
        );
      }
    });
  }

  authorize() {
    this._auth.check(this.username).then((result) => {
      this.authorized = result;
      if (!this.authorized) {
        this.username = "";
        this.failedAttempts = this._auth.failedAttempts;
      }
    });
  }

  ngOnInit() {
    this._auth.check().then((result) => (this.authorized = result));

    if (!isPlatformBrowser(this.platformId)) {
      let bases = this.document.getElementsByTagName("base");

      if (bases.length > 0) {
        bases[0].setAttribute("href", environment.baseHref);
      }
    }

    if (!isPlatformBrowser(this.platformId)) {
      let bases = this.document.getElementsByTagName("base");

      if (bases.length > 0) {
        bases[0].setAttribute("href", environment.baseHref);
      }
    }
  }
}
