<div id="pano-page-container" #page>
  <img
    src="/assets/img/CFAES_Logo_Stack_RGB_Standard_Rev-Scarlet.svg"
    alt="college of food, agricultural, and environmental sciences logo"
    class="pano-cfaes-logo"
  />
  <!-- <div
    id="entry-overlay"
    *ngIf="entranceImageURL && entranceImageURL.length > 0"
    [style.--entry-image]="'url(' + entranceImageURL + ')'"
    [class.hidden]="hideEntryOverlay"
  >
    <div class="responsive-page-container">
      <h1>{{ title }}</h1>
      <div [innerHTML]="description" class="desc"></div>
      <button (click)="getStarted()" mat-flat-button>
        Get Started
      </button>
    </div>
  </div> -->
  <div id="pano-sidebar" *ngIf="pannellumConfig" [class.holdOpen]="holdNavOpen">
    <div class="overlay">
      <div class="text">
        <h1 [innerHTML]="title"></h1>
        <h2
          (click)="holdNavOpen = !holdNavOpen"
          (keyup.enter)="holdNavOpen = !holdNavOpen"
          tabindex="0"
          aria-label="open naviation menu"
        >
          {{ getCurrentScene()?.sceneId }}
        </h2>
        <button
          (click)="goToPreviousScene()"
          (keyup.enter)="goToPreviousScene()"
          aria-label="go to previous scene"
          tabindex="0"
          *ngIf="sceneHistory.length > 0"
        >
          <i class="fas fa-undo"></i> <span> Return to previous scene</span>
        </button>
      </div>
      <!-- <div
        class="scene-desc"
        *ngIf="
          getCurrentScene().description &&
          getCurrentScene().description.length > 0
        "
      >
        <img
          src="https://contentfilehosting.s3.amazonaws.com/Asset%201.svg"
          alt="door"
        />
        <span>
          {{ getCurrentScene().description }}
        </span>
      </div> -->
    </div>
    <div id="nav" (mouseover)="holdNavOpen = true" #nav>
      <ng-container *ngFor="let scene of scenes">
        <div class="scene" *ngIf="scene.showInSidebar" [id]="scene.sceneId">
          <button
            (click)="viewerRef.loadScene(scene.sceneId)"
            (keyup.enter)="viewerRef.loadScene(scene.sceneId)"
            tabindex="0"
            [attr.aria-label]="scene.sceneId"
            [style.--featured-image]="getSceneBtnBg(scene.sceneId)"
            [class.active]="viewerRef?.getScene() == scene.sceneId"
            class="scene-button"
          >
            {{ scene.sceneId }}
            <svg
              class="checkbox"
              [class.checked]="visitedScenes.includes(scene.sceneId)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15.5 15.5"
            >
              <defs>
                <style>
                  .checkfill {
                    fill: #fff;
                  }
                </style>
              </defs>
              <path
                class="checkfill"
                d="M7.75,0A7.75,7.75,0,1,0,15.5,7.75,7.76,7.76,0,0,0,7.75,0Zm0,14A6.25,6.25,0,1,1,14,7.75,6.25,6.25,0,0,1,7.75,14Z"
                transform="translate(0 0)"
              />
              <polygon
                class="checkfill check"
                points="11.37 5.01 6.7 9.51 4.41 7.31 3.37 8.39 6.7 11.59 12.41 6.09 11.37 5.01"
              />
            </svg>
          </button>
          <div
            *ngIf="viewerRef?.getScene() == scene.sceneId"
            class="scene-description"
          >
            <div>
              {{ getCurrentScene().description }}
            </div>
            <button
              *ngIf="
                getCurrentScene().floorPlanImageURL &&
                getCurrentScene().floorPlanImageURL.length > 0
              "
              class="floor-plan-button"
              (click)="viewFloorPlan()"
            >
              View Floor Plan <i class="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div id="popout">
      <button
        aria-label="open navigation menu"
        (click)="holdNavOpen = !holdNavOpen"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 132">
          <defs>
            <style>
              .cls-1 {
                fill: #70071c;
              }
              .cls-2 {
                fill: #ba0c2f;
              }
              .cls-3 {
                fill: #dfe3e5;
              }
              .cls-4 {
                fill: #fff;
              }
              .cls-5 {
                fill: #a7b1b7;
              }
            </style>
          </defs>
          <polygon class="cls-1" points="0 132 64 100 0 100 0 132" />
          <rect class="cls-2" width="64" height="100" />
          <polyline
            class="cls-1"
            points="53.96 71.17 53.96 29.28 44.92 29.28 44.92 70.41"
          />
          <polygon
            class="cls-3"
            points="54.81 71.17 53.12 71.17 53.12 30.13 45.77 30.13 45.77 70.41 44.08 70.41 44.08 28.44 54.81 28.44 54.81 71.17"
          />
          <polygon
            class="cls-4"
            points="24.88 70.91 47.69 74.65 47.69 25.35 24.88 29.59 24.88 70.91"
          />
          <ellipse class="cls-5" cx="43.57" cy="51.51" rx="1.81" ry="2.19" />
          <polygon
            class="cls-4 arrow"
            points="9.19 39.66 19.5 50 9.19 60.34 7.07 58.22 15.26 50 7.07 41.78 9.19 39.66"
          />
        </svg>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
          <defs>
            <style>
              .cls-1 {
                fill: #ba0c2f;
              }
              .cls-2 {
                fill: #70071c;
              }
              .cls-3 {
                fill: #dfe3e5;
              }
              .cls-4 {
                fill: #fff;
              }
              .cls-5 {
                fill: #a7b1b7;
              }
            </style>
          </defs>
          <g id="Graphics">
            <rect class="cls-1" width="128" height="96.58" />
            <polygon class="cls-2" points="0 128 128 96.58 0 96.52 0 128" />
            <polyline
              class="cls-2"
              points="102.34 80.9 102.34 17.59 88.63 17.59 88.63 79.76"
            />
            <polygon
              class="cls-3"
              points="103.62 80.9 101.06 80.9 101.06 18.87 89.91 18.87 89.91 79.76 87.35 79.76 87.35 16.31 103.62 16.31 103.62 80.9"
            />
            <polygon
              class="cls-4"
              points="58.23 80.5 92.83 86.16 92.83 11.65 58.23 18.05 58.23 80.5"
            />
            <ellipse class="cls-5" cx="86.57" cy="51.19" rx="2.74" ry="3.31" />
            <polygon
              class="cls-4 arrow"
              points="17.29 73 15.48 71.19 35.06 51.61 15.48 32.04 17.29 30.23 38.68 51.61 17.29 73"
            />
          </g>
        </svg> -->
      </button>
    </div>
  </div>
  <div #panorama>
    <div
      class="pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control custom-pano-sprite"
      (click)="requestFullScreen()"
    ></div>
    <button
      class="help-button"
      (click)="openHelpDialog()"
      (keyup.enter)="openHelpDialog()"
      tabindex="1"
    >
      Help
    </button>
  </div>
</div>
