<div
  class="
    knowledge-exchange-logo
    {{ orientation }} 
    {{ size }} 
    {{ light ? 'light' : '' }}
    "
  aria-label="Knowledge Exchange Logo"
>
  <span class="knowledge"><span class="highlighted-letter">K</span>NOWLEDGE</span>
  <span class="exchange">E<span class="highlighted-letter">X</span>CHANGE</span>
</div>
