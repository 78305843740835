<h1 class="wide-page-header light">Topics</h1>
<div class="responsive-page-container">
  <ng-container *ngFor="let letter of letters">
    <div class="letter-section">
      <h2>{{ letter }}</h2>
      <ng-container *ngFor="let topic of buckets[letter]">
        <a href="topic/{{ topic.url }}">
          {{ topic.name }}
        </a>
      </ng-container>
    </div>
  </ng-container>
</div>
