<div class="vertical-scroll">
  <div class="slide" *ngFor="let item of items | async">
    <a
      class="image-slide"
      [href]="item.link"
      target="_parent"
      [attr.aria-label]="item.title"
    >
      <div class="img" [ngStyle]="getImgStyle(item.imageUrl)">
        <i class="fas fa-play" *ngIf="item.type == 'Video'"></i>
      </div>
      <span [ngClass]="{ 'small-text': item.title.length > 64 }">
        {{ item.title }}
      </span>
    </a>
  </div>
</div>
