<swiper
  [slidesPerView]="1"
  [autoplay]="autoplayOptions"
  [loop]="true"
  class="callout"
>
  <ng-template swiperSlide *ngFor="let item of items | async; index as i">
    <app-generic-callout
      [head]="item.title"
      [subhead]="item.description"
      [image]="item.imageUrl"
      [link]="item.link"
      [color]="getColor(i)"
      side="left"
    ></app-generic-callout>
  </ng-template>
</swiper>
