<div class="mini-slider">
  <swiper
    [slidesPerView]="1"
    (swiper)="onSwiper($event)"
    [navigation]="true"
    [pagination]="{ type: 'progressbar' }"
  >
    <ng-template swiperSlide *ngFor="let item of items | async">
      <a class="image-slide" [href]="item.link" target="_parent">
        <div class="img" [ngStyle]="getImgStyle(item.imageUrl)"></div>
        <span [ngClass]="{ 'small-text': item.title.length > 64 }">
          {{ item.title }}
        </span>
      </a>
    </ng-template>
  </swiper>

  <!-- <button
    (click)="navigateTo(swiper.activeIndex - 1)"
    class="prev-btn"
    *ngIf="swiper?.activeIndex != 0"
  >
    <span class="material-icons">arrow_back_ios_new</span>
  </button>
  <button
    (click)="navigateTo(swiper.activeIndex + 1)"
    class="next-btn"
    *ngIf="!swiper?.isEnd"
  >
    <span class="material-icons">arrow_forward_ios</span>
  </button> -->
</div>
