export type musicalElement = {
    name: string;
    instrument: string;
    sentiment: "good" | "bad";
    description: string;
    audioSource: string;
  }

export const elements: musicalElement[] = [
  {
    name: "Appearance",
    instrument: "Low Brass",
    sentiment: "bad",
    description: "Harmful algal blooms are well known in parts of Lake Erie, forming a green, slimy scum on the surface. While sometimes dangerous for pets and people, HABs make swimming, boating, and simply viewing the scenery unpleasant.",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Appearance+(Low+Brass).mp3",
  },
  {
    name: "Community",
    instrument: "French Horns",
    sentiment: "good",
    description: "Residents feel a sense of community on Lake Erie unique to the area. The beauty and power of this Great Lake brings together both those who live lakeside and those who marvel from afar.",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Community+(French+Horns).mp3",
  },
  {
    name: "Complicated",
    instrument: "Harp and Celesta",
    sentiment: "bad",
    description: "This is an involved problem that will take time and effort to sort out. Everyone—farmers, scientists, and residents—are working together to restore Lake Erie to its rightful beauty. ",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Complicated+(Harp+and+Celesta).mp3",
  },
  {
    name: "Family",
    instrument: "Celli and Contrabassi",
    sentiment: "good",
    description: "Whether they’ve been there for generations, just moved to the shore, or simply vacationing, Lake Erie supports so many families and gives them so much joy and happy memories.",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Family+(Celli+and+Contrabassi).mp3",
  },
  {
    name: "Local Economy",
    instrument: "Clarinets and Oboes",
    sentiment: "good",
    description: "With so many activities surrounding the lake—fishing, boating, recreation, shopping—it provides both jobs for the local residents and revenue for the whole state’s economy. ",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Local+Economy+(Clarinets+and+Oboes).mp3",
  },
  {
    name: "Loss of Biodiversity",
    instrument: "Percussion",
    sentiment: "bad",
    description: "As harmful algal blooms get larger and happen more often, Lake Erie fishes and plants living in the lake start to change or die off. Over time, a smaller variety and amount of flora and fauna are able to survive.",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Loss+of+Biodiversity+(Percussion).mp3",
  },
  {
    name: "Nature",
    instrument: "Piano",
    sentiment: "good",
    description: "With its stunning water, sunrises and sunsets, beaches, and surrounding forests, Lake Erie is a uniquely beautiful place to immerse yourself in the natural world.",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Nature+(Piano).mp3",
  },
  {
    name: "Peaceful",
    instrument: "Violins",
    sentiment: "good",
    description: "Lake Erie offers a serene, quiet place to take a step back from the hustle and bustle and enjoy some much-needed relaxation.",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Peaceful+(Violins).mp3",
  },
  {
    name: "Recreation",
    instrument: "Trumpets",
    sentiment: "good",
    description: "Visitors to the lake enjoy activities like swimming, watersports, hiking, shopping, fishing, and boating. From state parks to amusement parks, there’s something for everybody at the lake!",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Recreation+(Trumpets).mp3",
  },
  {
    name: "Tension",
    instrument: "Bassoons",
    sentiment: "bad",
    description: "It’s easy to place blame for the HABs affecting Lake Erie, even though it’s much more complicated. Once strained relationships between residents and farmers are changing as they work together to make the lake healthier.",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Tension+(Bassoons).mp3",
  },
  {
    name: "Tourism",
    instrument: "Flutes and Piccolo",
    sentiment: "bad",
    description: "If the water is toxic or covered in unattractive algae, less people want to visit Lake Erie. This hurts all the businesses that depend on tourism, and consequently the people who depend on those businesses. ",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Tourism+(Flutes+and+Piccolo).mp3",
  },
  {
    name: "Toxicity",
    instrument: "Violas",
    sentiment: "bad",
    description: "When harmful algal blooms reach toxic levels, no pets or people should swim in the water. Drinking water treatment plans take extra precautions to make the water safe to drink, which is very expensive. ",
    audioSource:
      "https://kx-assets.s3.amazonaws.com/water-quality-music-files/Toxicity+(Violas).mp3",
  },
];
