<ng-container *ngIf="infographic">
  <div class="row infographic noSidebar m-0 p-0">
    <div class="img-wrap">
      <img
        [src]="infographic.backgroundImage"
        width="100%"
        id="infographic-image"
      />
      <div
        *ngFor="let hotspot of infographic.hotspots; index as i"
        class="hotspot-button"
        id="hotspot{{ i }}"
        [ngClass]="{ active: isActive(i) }"
        [ngStyle]="getButtonStyle(hotspot)"
        (click)="toggleHotspot($event, i)"
      >
        <i class="fas fa-plus"></i>
      </div>
    </div>

    <ng-container *ngFor="let hotspot of infographic.hotspots; index as i">
      <div
        [ngClass]="{
          active: isActive(i),
          'hotspot-popup': true,
          'true-popup': true
        }"
        [ngStyle]="positionRelativeToCursor()"
      >
        <img *ngIf="hotspot.image" [src]="hotspot.image" />
        <h3 *ngIf="hotspot.title">{{ hotspot.title }}</h3>
        <p *ngIf="hotspot.description" [innerHTML]="hotspot.description"></p>
        <a
          *ngIf="hotspot.link"
          class="btn btn-dark"
          [href]="hotspot.link"
          target="_blank"
          >Learn More</a
        >
      </div>
    </ng-container>
  </div>
</ng-container>
