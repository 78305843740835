<div class="content-list-grid">
  <a
    *ngFor="let item of items | async; index as i"
    class="grid-item"
    [href]="item.link"
    target="_parent"
    [attr.aria-label]="item.title"
  >
    <div class="img" [ngStyle]="getImgStyle(item.imageUrl, i)">
      <!-- Uncomment this for a funky word pattern thing I made to fill the empty space on items with no image -->
      <!-- <div
        *ngIf="!(item.imageUrl && item.imageUrl.length > 0)"
        class="word-pattern"
      >
        <span *ngFor="let x of [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]">{{
          item.description && item.description.length > 0
            ? item.description
            : item.title
        }} </span>
      </div> -->
      <div class="kx-original-tag" *ngIf="item.kxOriginal">KX</div>
    </div>
    <div class="text">
      <span class="type">
        {{ !!item.display_type && item.display_type.length>0 ? item.display_type : item.type }}
      </span>
      <span class="title" [ngClass]="{ 'small-text': item.title.length > 64 }">
        {{ item.title }}
      </span>
      <span class="date">
        {{ item.date }}
      </span>
    </div>
  </a>
</div>
