import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-external-redirect",
  template: `
    <br /><br /><br /><br />
    <div class="responsive-page-container-sm">
      <h1 class="centered">Redirecting...</h1>
    </div>
    <br /><br /><br /><br />
  `,
})
export class ExternalRedirectComponent {
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((p) => {
      if (p.external_url && p.external_url != "") {
        let url = decodeURIComponent(p.external_url);
        window.location.href =
          url.slice(0, 7) == "http://"
            ? url.replace("http://", "https://")
            : url;
      }
      else window.location.href = "/404";
    });
  }
}
