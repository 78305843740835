<div class="responsive-page-container">
  <div class="expert-dir-header">
    <img
      src="https://contentfilehosting.s3.amazonaws.com/20230524_Expert%20Directory%20Hero%20Graphic_v1.0_JSP.png"
      alt="science"
    />
    <div class="header-card">
      <div class="inner-card">
        <h1>Expert Directory</h1>
        <p>
          Get connected to the world-class faculty in the College of Food,
          Agricultural, and Environmental Sciences. Search by name, title,
          college department, or interest area keywords to find knowledgeable
          people developing solutions for today's biggest challenges.
        </p>
      </div>
    </div>
  </div>
  <mat-form-field class="search-the-directory">
    <mat-label>Search the Directory</mat-label>
    <i matPrefix class="fa fa-search" aria-hidden="true"></i>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Search for a topic or person"
      #input
    />
  </mat-form-field>

  <div class="checky-list">
    <button
      class="title"
      (click)="deptMenuActive = !deptMenuActive"
      #deptMenuTitle
    >
      <ng-container
        *ngIf="departmentFiltersActive.length == 0; else departmentsActive"
      >
        Departments
      </ng-container>
      <ng-template #departmentsActive>
        <span>{{ departmentFiltersActive.join(", ") }}</span>
      </ng-template>

      <i class="fas fa-caret-down" aria-hidden="true"></i>
    </button>
    <div [ngClass]="{ items: true, activated: deptMenuActive }" #deptList>
      <div *ngIf="departmentFiltersActive.length > 0">Departments</div>
      <mat-checkbox
        class="example-margin"
        color="primary"
        [checked]="departmentFiltersActive.includes(d.key)"
        *ngFor="let d of departments | keyvalue"
        (change)="departmentCheck(d.key)"
        (click)="departmentCheck(d.key)"
        >{{ d.key }}</mat-checkbox
      >
      <button class="link-button" (click)="clearDepartmentFilters()">
        Deselect All
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- "new" Column -->
      <ng-container matColumnDef="new">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <span class="new-icon" *ngIf="row.new == 'yes'">NEW</span>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          {{ row.legal_first_name }}<br />{{ row.legal_last_name }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
        <td
          mat-cell
          *matCellDef="let row"
          matTooltipPosition="left"
          [matTooltip]="getDepartmentName(row.department)"
        >
          {{ row.department }}
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="position_title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let row">{{ row.position_title }}</td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">
          <a href="mailto:{{ row.email }}">{{ row.email }}</a>
        </td>
      </ng-container>
      <!-- Fruit Column -->

      <!-- <ng-container matColumnDef="keywords">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Keywords</th>
        <td mat-cell *matCellDef="let row">{{ row.keywords }}</td>
      </ng-container> -->

      <ng-container matColumnDef="short_keywords">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Short Keywords
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.short_keywords?.sort().join(", ") }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="summary">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Summary</th>
        <td mat-cell *matCellDef="let row">{{ row.summary }}</td>
      </ng-container>
      <ng-container matColumnDef="searchable_blob">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Blob</th>
        <td mat-cell *matCellDef="let row">{{ row.searchable_blob }}</td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [routerLink]="'/directory/' + row.name_dot_number"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No results matching the current filters.
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[8, 16, 32, 64, 128, 256]"
      aria-label="Select page"
    ></mat-paginator>
  </div>
</div>
