import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Observable } from "rxjs/Rx";
import { sliderItem } from "src/app/shared/models/sliderItem";
import { backgroundStyle } from "src/app/shared/models/backgroundStyle";
import { formatDate, getBgStyle, getDate, getIcon, getLink, sortByDate } from "../util";

// Slider Configuration
const STEP_SIZE = 1; //(# of items on button click)
const BREAKPOINTS = [{ sliderSize: 1, minWidth: 0, maxWidth: 99999 }];

@Component({
  selector: "app-content-slider-lg",
  templateUrl: "./content-slider-lg.component.html",
  styleUrls: ["./content-slider-lg.component.scss"],
})
export class LargeContentSliderComponent implements AfterViewInit, OnInit {
  @Input() contentObservable: Observable<Object>;
  @Input() joinedObservable: boolean;
  @Input() header?: string;
  @ViewChild("contentSlider") DOMElement: ElementRef;

  items: sliderItem[] = [];
  currentSize: number = 1;
  itemLimit: number;
  empty: boolean;

  constructor() {}

  ngOnInit(): void {
    this.itemLimit = this.joinedObservable ? 12 : 100;
    this.loadItems();
  }

  ngAfterViewInit(): void {
  }

  next = () => (this.items = this.step(this.items, "forward"));
  prev = () => (this.items = this.step(this.items, "backward"));

  atStartPosition() {
    return this.items.length > 0 && this.items[0].visible;
  }
  atEndPosition() {
    return this.items.length > 0 && this.items[this.items.length - 1].visible;
  }

  step(items: sliderItem[], direction: "forward" | "backward"): sliderItem[] {
    if (direction == "backward")
      return this.step(items.reverse(), "forward").reverse();

    let [itemsHidden, itemsShown] = [0, 0];
    items.forEach((el) => {
      // Hide STEP_SIZE number of visible items.
      if (itemsHidden < STEP_SIZE) {
        itemsHidden = el.visible ? itemsHidden + 1 : itemsHidden;
        el.visible = false;
      }
      // Then, un-hide STEP_SIZE number of hidden items.
      else if (itemsShown < STEP_SIZE) {
        itemsShown = el.visible ? itemsShown : itemsShown + 1;
        el.visible = true;
      }
    });

    return items;
  }

  addItem(rawItem: any) {
    let description = rawItem.description
      ? this.removeHtmlEntities(rawItem.description)
      : null;

    this.items.push({
      visible: false,
      link: getLink(rawItem),
      background: getBgStyle(rawItem.headerImage),
      title: this.removeHtmlEntities(rawItem.title),
      icon: getIcon(rawItem.type),
      date: getDate(rawItem),
      description: description,
    });
  }

  loadItems() {
    this.contentObservable.subscribe((results: any[]) => {
      if (results.length < 1) this.empty = true;
      if (this.joinedObservable) results = [].concat(...results);
      results.forEach((el, i) => {
        if (i < this.itemLimit) this.addItem(el);
      });
      this.items = sortByDate(this.items);
      if (this.items.length>0) this.items[0].visible = true;
    });
  }

  removeHtmlEntities(str: string) {
    if (str == "") return "";
    var txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }
}
