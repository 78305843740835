import { Injectable } from "@angular/core";
import { MapChart } from "angular-highcharts";
import ohMap from "../../assets/ohMap";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class GraphingService {
  chartData;
  chartTrace;
  constructor(private _apiService: ApiService) {}

  build2YearDonut(
    data1: number,
    data2: number,
    data3: number,
    data4: number,
    percent2015: number,
    percent2017: number,
    years: any,
    labels: any,
    text1: any,
    text2: any
  ) {
    let trace = {
      values: [data1, data3],
      labels: labels,
      domain: { column: 0 },
      name: years[0],
      hoverinfo: "label+value+percent",
      text: text1,
      textinfo: "text",
      direction: "clockwise",
      type: "pie",
      hole: 0.6,
      scalegroup: "one",
      marker: {
        colors: ["#BA0C2F", "#666666"],
        line: {
          width: [2, 2],
          color: "#EFEFEF",
        },
      },
    };

    let trace1 = {
      values: [data2, data4],
      labels: labels,
      domain: { column: 1 },
      name: years[1],
      hoverinfo: "label+value+percent",
      text: text2,
      textinfo: "text",
      direction: "clockwise",
      type: "pie",
      hole: 0.6,
      scalegroup: "one",
      marker: {
        colors: ["#BA0C2F", "#666666"],
        line: {
          width: [2, 2],
          color: "#FFFFFF",
        },
      },
    };

    let graph = {
      data: [trace, trace1],
      layout: {
        title: years,
        autosize: true,
        width: 500,
        height: 320,
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 50,
          pad: 50,
        },
        grid: { rows: 1, columns: 2 },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        showlegend: true,
        legend: {
          x: 0.3,
          y: -0.2,
          font: {
            family: "'proximanova', sans-serif",
            size: 12,
          },
        },
        annotations: [
          {
            font: {
              family: "'proximanova', sans-serif",
              size: 32,
              color: "#BA0C2F",
            },
            showarrow: false,
            text: "<b>" + percent2015 + "%</b><br>(2015)",
            x: 0.13,
            y: 0.5,
          },
          {
            font: {
              family: "'proximanova', sans-serif",
              size: 32,
              color: "#BA0C2F",
            },
            showarrow: false,
            text: "<b>" + percent2017 + "%</b><br>(2017)",
            x: 0.87,
            y: 0.5,
          },
        ],
      },
    };

    return graph;
  }

  build2YearPie(data1: any, data2: any, years: any, labels: any) {
    let trace = {
      values: data1,
      labels: labels,
      domain: { column: 0 },
      name: years[0],
      title: {
        text: years[0],
        font: {
          family: "'proximanova', sans-serif",
          size: 16,
          color: "#000000",
        },
      },
      hoverinfo: "label+value+percent",
      textinfo: "percent",
      direction: "counterclockwise",
      type: "pie",
      hole: 0,
      scalegroup: "one",
      marker: {
        colors: ["#4b0000", "#BA0C2F", "#c2c2c2"],
        line: {
          width: [2, 2, 2],
          color: "#EFEFEF",
        },
      },
    };

    let trace1 = {
      values: data2,
      labels: labels,
      domain: { column: 1 },
      name: years[1],
      title: {
        text: years[1],
        font: {
          family: "'proximanova', sans-serif",
          size: 16,
          color: "#000000",
        },
      },
      hoverinfo: "label+value+percent",
      textinfo: "percent",
      direction: "counterclockwise",
      type: "pie",
      hole: 0,
      scalegroup: "one",
      marker: {
        colors: ["#4b0000", "#BA0C2F", "#c2c2c2"],
        line: {
          width: [2, 2, 2],
          color: "#EFEFEF",
        },
      },
    };

    let graph = {
      data: [trace, trace1],
      layout: {
        // title: {
        //   text: '<b>Contributions by Sector</b>',
        //   font: {
        //     family: "'proximanova', sans-serif",
        //     size: 18,
        //     color: '#000000'
        //   },
        //   xref: 'paper',
        //   x: -.5,
        //   y: .95

        // },
        autosize: true,
        width: 450,
        height: 320,
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 50,
          pad: 50,
        },
        grid: { rows: 1, columns: 2 },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        showlegend: true,
        legend: {
          x: 0.3,
          y: -0.2,
          font: {
            family: "'proximanova', sans-serif",
            size: 12,
          },
        },
      },
    };

    return graph;
  }

  build2YearDonutSmall(
    data1: number,
    data2: number,
    data3: number,
    data4: number,
    percent2015: number,
    percent2017: number,
    years: any,
    labels: any,
    text1: any,
    text2: any
  ) {
    let trace = {
      values: [data1, data3],
      labels: labels,
      domain: { column: 0 },
      name: years[0],
      hoverinfo: "label+value+percent",
      text: text1,
      textinfo: "text",
      direction: "clockwise",
      type: "pie",
      hole: 0.2,
      scalegroup: "one",
      marker: {
        colors: ["#BA0C2F", "#666666"],
        line: {
          width: [3, 3],
          color: "#EFEFEF",
        },
      },
    };

    let trace1 = {
      values: [data2, data4],
      labels: labels,
      domain: { column: 1 },
      name: years[1],
      hoverinfo: "label+value+percent",
      text: text2,
      textinfo: "text",
      direction: "clockwise",
      type: "pie",
      hole: 0.6,
      scalegroup: "one",
      marker: {
        colors: ["#BA0C2F", "#666666"],
        line: {
          width: [3, 3],
          color: "#FFFFFF",
        },
      },
    };

    let graph = {
      data: [trace, trace1],
      layout: {
        title: years,
        autosize: false,
        width: 300,
        height: 200,
        margin: {
          l: 20,
          r: 20,
          b: 0,
          t: 0,
          pad: 0,
        },
        grid: { rows: 1, columns: 2 },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        showlegend: true,
        legend: {
          x: 0.5,
          y: 0,
          font: {
            family: "'proximanova', sans-serif",
            size: 12,
          },
        },
        annotations: [
          {
            font: {
              family: "'proximanova', sans-serif",
              size: 16,
              color: "#BA0C2F",
            },
            showarrow: false,
            text: "<b>" + percent2015 + "%</b><br>(2015)",
            x: 0.13,
            y: 0.5,
          },
          {
            font: {
              family: "'proximanova', sans-serif",
              size: 16,
              color: "#BA0C2F",
            },
            showarrow: false,
            text: "<b>" + percent2017 + "%</b><br>(2017)",
            x: 0.87,
            y: 0.5,
          },
        ],
      },
    };

    return graph;
  }

  build2YearPieSmall(data1: any, data2: any, years: any, labels: any) {
    let trace = {
      values: data1,
      labels: labels,
      domain: { column: 0 },
      name: years[0],
      title: {
        text: years[0],
        font: {
          family: "'proximanova', sans-serif",
          size: 12,
          color: "#000000",
        },
      },
      hoverinfo: "label+value+percent",
      textinfo: "percent",
      direction: "counterclockwise",
      type: "pie",
      hole: 0,
      scalegroup: "one",
      marker: {
        colors: ["#4b0000", "#BA0C2F", "#c2c2c2"],
        line: {
          width: [2, 2, 2],
          color: "#EFEFEF",
        },
      },
    };

    let trace1 = {
      values: data2,
      labels: labels,
      domain: { column: 1 },
      name: years[1],
      title: {
        text: years[1],
        font: {
          family: "'proximanova', sans-serif",
          size: 16,
          color: "#000000",
        },
      },
      hoverinfo: "label+value+percent",
      textinfo: "percent",
      direction: "counterclockwise",
      type: "pie",
      hole: 0,
      scalegroup: "one",
      marker: {
        colors: ["#4b0000", "#BA0C2F", "#c2c2c2"],
        line: {
          width: [2, 2, 2],
          color: "#EFEFEF",
        },
      },
    };

    let graph = {
      data: [trace, trace1],
      layout: {
        // title: {
        //   text: '<b>Contributions by Sector</b>',
        //   font: {
        //     family: "'proximanova', sans-serif",
        //     size: 18,
        //     color: '#000000'
        //   },
        //   xref: 'paper',
        //   x: -.5,
        //   y: .95

        // },
        autosize: false,
        width: 300,
        height: 200,
        margin: {
          l: 50,
          r: 50,
          b: 0,
          t: 0,
          pad: 0,
        },
        grid: { rows: 1, columns: 2 },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        showlegend: true,
        legend: {
          x: 0.5,
          y: -0.5,
          font: {
            family: "'proximanova', sans-serif",
            size: 12,
          },
        },
      },
    };

    return graph;
  }

  buildBullet(data: any) {
    let trace = {
      type: "indicator",
      mode: "number+gauge+delta",
      value: data.value,
      domain: { x: [0, 1], y: [0, 1] },
      delta: { reference: 0.23, position: "top" },
      title: {
        text: data.title,
        font: { size: 14 },
      },
      gauge: {
        shape: "bullet",
        axis: { range: [null, 0.5] },
        threshold: {
          line: { color: "red", width: 2, gradient: { yanchor: "vertical" } },
          thickness: 0.75,
          value: 0.23,
        },
        bgcolor: "white",
        steps: [{ range: [0, 150], color: "cyan" }],
        bar: { color: "darkblue" },
      },
    };
  }

  getData(data: string) {
    this.chartData = [];
    this._apiService.getChartData(data).subscribe((res) => {
      this.chartData = res[0];
    });

    return this.chartData;
  }

  buildLine(data: any) {
    //this.chartTrace = {trace1:{}, trace2:{}, trace3:{}, trace4: {}, trace5: {}}
    this.chartTrace = [];
    this._apiService.getChartData(data.data).subscribe((res) => {
      this.chartData = JSON.parse(res[0]);

      let trace1 = {
        x: this.chartData.Date,
        y: this.chartData.superior,
        type: "scatter",
        mode: "lines",
        name: "Superior",
        visible: true,
        line: {
          width: 2,
          color: "rgb(120, 159, 239)",
          shape: "linear",
        },
      };

      let trace2 = {
        x: this.chartData.Date,
        y: this.chartData.michigan,
        type: "scatter",
        mode: "lines",
        name: "Michigan",
        visible: true,
        line: {
          width: 2,
          color: "rgb(237, 131, 131)",
          shape: "linear",
        },
      };

      let trace3 = {
        x: this.chartData.Date,
        y: this.chartData.Huron,
        type: "scatter",
        mode: "lines",
        name: "Huron",
        visible: true,
        line: {
          width: 2,
          color: "rgb(198, 196, 172)",
          shape: "linear",
        },
      };

      let trace4 = {
        x: this.chartData.Date,
        y: this.chartData.erie,
        type: "scatter",
        mode: "lines",
        name: "Erie",
        visible: true,
        line: {
          width: 4,
          color: "rgb(245, 218, 95)",
          shape: "linear",
        },
      };

      let trace5 = {
        x: this.chartData.Date,
        y: this.chartData.ontario,
        type: "scatter",
        mode: "lines",
        name: "Ontario",
        visible: true,
        line: {
          width: 2,
          color: "rgb(46, 197, 224)",
          shape: "linear",
        },
      };

      this.chartTrace.push(trace1, trace2, trace3, trace4, trace5);
    });
    let graph = {
      data: this.chartTrace,
      layout: {
        title: {
          text: data.title,
          x: 0.5,
          y: 1,
        },
        autosize: true,
        // width: 1200,
        // height: 700,
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        showlegend: true,
        legend: {
          orientation: "h",
          x: 0,
          y: 1.1,
        },
        xaxis: {
          title: data.xtitle,
          showline: false,
          showgrid: false,
          //rangeselector: selectorOptions,
          rangeslider: {
            visible: true,
            bgcolor: "rgba(0,0,0,0)",
            bordercolor: "#444",
            borderwidth: 0,
            thickness: 0.2,
          },
          //nticks: 12,
          ticks: "outside",
          tickvals: [
            "1-Jan",
            "1-Feb",
            "1-Mar",
            "1-Apr",
            "1-May",
            "1-Jun",
            "1-Jul",
            "1-Aug",
            "1-Sep",
            "1-Oct",
            "1-Nov",
            "1-Dec",
          ],
          ticktext: [
            "1-Jan",
            "1-Feb",
            "1-Mar",
            "1-Apr",
            "1-May",
            "1-Jun",
            "1-Jul",
            "1-Aug",
            "1-Sep",
            "1-Oct",
            "1-Nov",
            "1-Dec",
          ],
        },
        yaxis: {
          title: data.ytitle,
          showline: false,
          showgrid: true,
          gridcolor: "#d1d1d1",
        },
        annotations: [
          {
            xref: "paper",
            yref: "paper",
            x: 0.85,
            y: -0.6,
            xanchor: "center",
            yanchor: "top",
            text: data.source,
            showarrow: false,
            font: {
              family: "Arial",
              size: 12,
              color: "rgb(150,150,150)",
            },
          },
          {
            xref: "paper",
            yref: "paper",
            x: 0.15,
            y: 1.1,
            xanchor: "center",
            yanchor: "top",
            text: "Click on legend items to toggle on/off.",
            showarrow: false,
            font: {
              family: "Arial",
              size: 12,
              color: "rgb(150,150,150)",
            },
          },
          {
            xref: "paper",
            yref: "paper",
            x: 0.25,
            y: -0.6,
            xanchor: "center",
            yanchor: "top",
            text: "Slide the vertical bars above to zoom in on a range.",
            showarrow: false,
            font: {
              family: "Arial",
              size: 12,
              color: "rgb(150,150,150)",
            },
          },
        ],
      },
    };
    return graph;
  }

  getKeys(obj) {
    return Object.keys(obj);
  }

  buildBasicLine(data: any) {
    let traces = [];
    data.data.forEach((item) => {
      let title = this.getKeys(item);
      Object.values(item).forEach((element: any, i = 0) => {
        let traceX = [];
        let traceY = [];

        for (let obj of element) {
          traceX.push(obj.year);
          traceY.push(obj.value);
        }
        let trace = {
          x: traceX,
          y: traceY,
          type: "scatter",
          mode: "lines",
          name: title[i],
          hoverinfo: "label+value+percent",
          visible: true,
        };
        traces.push(trace);

        i = i + 1;
      });
    });

    let selectorOptions = {
      buttons: [
        {
          step: "year",
          stepmode: "todate",
          count: 1,
          label: "YTD",
        },
        {
          step: "year",
          stepmode: "backward",
          count: 1,
          label: "1y",
        },
        {
          step: "all",
        },
      ],
    };

    let graph = {
      data: traces,
      layout: {
        title: {
          text: data.title,
          x: 0.5,
          y: 1,
        },
        autosize: true,
        //  width: 800,
        //  height: 700,
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        showlegend: true,
        legend: {
          orientation: "h",
          x: 0,
          y: 1.1,
        },
        xaxis: {
          title: data.xtitle,
          showline: false,
          showgrid: false,
          rangeselector: selectorOptions,
          rangeslider: {
            visible: true,
            bgcolor: "rgba(0,0,0,0)",
            bordercolor: "#444",
            borderwidth: 0,
            thickness: 0.2,
          },
        },
        yaxis: {
          title: data.ytitle,
          showline: false,
          showgrid: true,
          gridcolor: "#d1d1d1",
          zeroline: true,
        },
      },
    };
    return graph;
  }

  buildBasicLine2(data: any) {
    let xdata = [];
    let ydata = [];
    data.forEach((c) => {
      xdata.push(c.year);
      ydata.push(c.value);
    });

    let trace = {
      x: xdata,
      y: ydata,
      type: "scatter",
      mode: "lines",
      line: {
        color: "rgb(55, 128, 191)",
        width: 3,
      },
      visible: true,
    };

    let graph = {
      data: [trace],
      layout: {
        title: {
          text: "Primary Operator Age by Year",
          x: 0.5,
          y: 1,
        },
        autosize: true,
        //  width: 400,
        //  height: 300,
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: "year",
          showline: false,
          showgrid: false,
        },
        yaxis: {
          title: "Average age in years",
          showline: false,
          showgrid: true,
          gridcolor: "#d1d1d1",
          zeroline: true,
        },
      },
    };
    return graph;
  }

  buildMap(data: any, year: string, call: string) {
    let legendTitle = "Population";
    let min = 5000;
    let max = 2000000;
    let stops: [number, string][] = [
      [0, "#fbfced"],
      [0.5, "#26686d"],
      [0.9, "#851e5e"],
      [1, "#6a184b"],
    ];
    switch (call) {
      case "census-diff":
        legendTitle = "Total Change";
        min = -5000;
        max = 100000;
        stops = [
          [0, "#fbfced"],
          [0.5, "#26686d"],
          [0.9, "#851e5e"],
          [1, "#6a184b"],
        ];
        break;
      case "census-pct":
        legendTitle = "Percent Change";
        min = -20;
        max = 50;
        stops = stops;
        break;
      case "ag-farms":
        legendTitle = "Farms";
        min = 50;
        max = 7000;
        break;
      case "ag-acres":
        legendTitle = "Farm Acreage";
        min = 1500;
        max = 600000;
        break;
      case "ag-density":
        legendTitle = "Acres per Farm";
        min = 10;
        max = 500;
        break;
      default:
        break;
    }

    let mapChart = new MapChart({
      options: {
        lang: {
          numericSymbols: ["k", "M", "B", "T", "P", "E"],
        },
      },
      chart: {
        backgroundColor: "#00000000",
        width: null,
        height: null,
        renderTo: "mapContainer",

        //@ts-ignore
        map: ohMap,
      },
      credits: {
        enabled: false,
      },
      //colors: colors,

      title: {
        // @ts-ignore
        text: "",
      },

      legend: {
        title: { text: legendTitle },
        align: "left",
        // @ts-ignore
        overflow: "auto",
        verticalAlign: "bottom",
        floating: false,
        layout: "vertical",
        //x: -80,
        margin: 0,
        itemMarginBottom: 0,
        itemMarginTop: 0,
        padding: 0,
        // @ts-ignore
        valueDecimals: 0,
        borderWidth: 0,
        backgroundColor: "none",
        navigation: { enabled: true },
        // floating: false,
        // align: 'center'
        itemStyle: { color: "#ffffff" },
      },
      exporting: { enabled: false },
      // @ts-ignore
      colorAxis: {
        // dataClassColor: 'category',
        // dataClasses: stops
        // minColor: minColor,
        // maxColor: maxColor,
        stops: stops,
        min: min,
        max: max,
      },
      series: [
        {
          animation: {
            duration: 0,
            //easing: 'easeIn'
          },
          //@ts-ignore
          type: "map",
          //@ts-ignore
          name: year,
          data: data,
          //@ts-ignore
          mapData: ohMap,
          //@ts-ignore
          joinBy: ["fips", "fips"],
          //@ts-ignore
          minSize: 0,
          maxSize: "12%",
          cursor: "pointer",
          states: {
            hover: {
              color: "#BA0C2F",
            },
          },
          tooltip: {
            //useHTML: true,
            pointFormat:
              "{point.name}" + " County" + "<br>" + "Value:" + "{point.value}",
          },
        },
      ],
      navigation: {
        buttonOptions: {
          enabled: true,
          //@ts-ignore
          align: "left",
        },
      },
    });
    return mapChart;
  }

  buildChoropleth(data: any, story: string) {
    console.log("CHORO DATA: ", data);
    let minVal = Math.min.apply(
      Math,
      data.data.map(function (o) {
        return o.value;
      })
    );
    let maxVal = Math.max.apply(
      Math,
      data.data.map(function (o) {
        return o.value;
      })
    );
    let curYear = [...new Set(data.data.map((item) => item.year))];
    let charts = [];
    let curData = [];
    curData = data.data;
    curYear.forEach((mapChart) => {
      let yearData = curData.filter((c) => {
        return c.year == mapChart;
      });
      let mc = {
        chart: {
          backgroundColor: "#00000000",
          // width: 650,
          // height: 650,
          // borderWidth: 1,
          // spacingBottom: 25,
          // spacingTop: 10,
          // spacingLeft: 10,
          // spacingRight: 10,
          //@ts-ignore
          map: ohMap,
        },
        credits: {
          enabled: false,
        },

        title: {
          //@ts-ignore
          text: mapChart,
        },

        // subtitle: {
        //     text: 'click a county to view in KX Explorer'
        // },

        legend: {
          layout: "horizontal",
          borderWidth: 0,
          backgroundColor: "none",
          floating: false,
          align: "center",
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            point: {
              events: {
                click: function () {
                  //@ts-ignore
                  window.location.href =
                    "./explore/" + story + "/" + data.name + "/" + this.fips;
                },
              },
            },
          },
        },

        colorAxis: {
          min: minVal,
          max: maxVal,
          type: "",
          minColor: "#d1d1d1",
          maxColor: "#525252",
          // stops: [
          //     [0, '#EFEFFF'],
          //     [0.67, '#4444FF'],
          //     [1, '#000022']
          // ]
        },
        series: [
          {
            animation: {
              duration: 1000,
            },
            //@ts-ignore
            //type: 'map',
            //@ts-ignore
            name: "click to go to county details",
            data: yearData,
            //@ts-ignore
            mapData: ohMap,
            joinBy: ["fips", "fips"],
            minSize: 0,
            maxSize: "12%",
            cursor: "pointer",
            states: {
              hover: {
                color: "#BA0C2F",
              },
            },
            tooltip: {
              useHTML: true,
              pointFormat:
                "{point.name}" +
                " County <br>Average Age:" +
                "{point.value}" +
                "years <br>For year:" +
                "{point.year}",
            },
          },
        ],
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
      };
      charts.push(mc);
    });
    return charts;
    // return mc;
  }

  buildDotPlot() {
    var country = [
      "Switzerland (2011)",
      "Chile (2013)",
      "Japan (2014)",
      "United States (2012)",
      "Slovenia (2014)",
      "Canada (2011)",
      "Poland (2010)",
      "Estonia (2015)",
      "Luxembourg (2013)",
      "Portugal (2011)",
    ];

    var votingPop = [40, 45.7, 52, 53.6, 54.1, 54.2, 54.5, 54.7, 55.1, 56.6];

    var regVoters = [49.1, 42, 52.7, 84.3, 51.7, 61.1, 55.3, 64.2, 91.1, 58.9];

    //var data = [trace1, trace2];

    //Plotly.newPlot('myDiv', data, layout, {showSendToCloud:true});
  }
}
