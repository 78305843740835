import { AfterViewInit, Component, HostListener, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { TitleService } from "src/app/services/title.service";
import {
  CustomPageNavRef,
  CustomPageSection,
  NavigationDestinationSection,
} from "../etc/types";

type HostedCustomPage = {
  title: string;
	name: string;
  customPageId: string;
  navSections?: NavigationDestinationSection[];
};

const breakpointPx = 800;

@Component({
  selector: "app-custom-page-navigator",
  templateUrl: "./custom-page-navigator.component.html",
  styleUrls: ["./custom-page-navigator.component.scss", "../etc/style.scss"],
})
export class CustomPageNavigatorComponent implements OnInit, AfterViewInit {
  @Input() nodeId: string;
  @Input() activePageName: string;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.showSidebar = event.target.innerWidth > breakpointPx;
    this.mobile = event.target.innerWidth <= breakpointPx;
  }
	showSidebar: boolean = false;
	mobile: boolean = false;
	mobileSidebarExpandIndex: number = -1;
  hostedCustomPages: HostedCustomPage[];
  homePagePath: string;
  navigationSections: NavigationDestinationSection[];
  navigationSections$: Subject<NavigationDestinationSection[]>;

  constructor(
    private _api: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _title: TitleService
  ) {
    this.navigationSections$ = new Subject<NavigationDestinationSection[]>();
    this.mobile = window.innerWidth <= breakpointPx;
  }

  ngOnInit(): void {
    this.getCustomPageNavigator();
  }

  ngAfterViewInit(): void {
    if (this.activePageName) {
      this._title.setPageTitle(this.activePageName);
    }
  }

  getCustomPageNavigator(): void {
    this._api.getDrupalNavigatorByNodeId(this.nodeId).subscribe((navigator) => {
      this.homePagePath = navigator[0].homePagePath;
      this._api
        .getDrupalHostedCustomPageByNodeId(navigator[0].hostedPageIds)
        .subscribe((hostedCustomPages) => {
					const hostedPage = hostedCustomPages.find(p => p.name == this.activePageName);
          this.hostedCustomPages = hostedCustomPages;
          if (!this.activePageName)
            this.updateActiveCustomPage(hostedCustomPages[0]);
          else {
            this._title.setPageTitle(hostedPage.title.replace("&#039;", "'"));
          }
        });
    });
  }

  updateActiveCustomPage(page: HostedCustomPage, subElementid?: string): void {
		this.showSidebar = false;
    this._title.setPageTitle(page.title.replace("&#039;", "'"));
    this._router.navigate([`../${page.name}`], { relativeTo: this._route }).then((res) => {
			if (subElementid)  {
				setTimeout(() => {
				this.navigateToElement(subElementid);

				}, 1000)
				console.log("sub id");
				console.log(subElementid);

			}
		});
  }

  updateSections(navRef: CustomPageNavRef): void {
    let pageMatch = this.hostedCustomPages.find(
      (p) => p.customPageId === navRef.customPageId
    );
    if (!pageMatch) return;
    pageMatch.navSections = navRef.navSections;
  }

  navigateToElement(elementId: string): void {
		this.showSidebar = false;
		const headerOffset = this.mobile ? 114 : 64;
		const position = document.getElementById(elementId).getBoundingClientRect().top;
    const offsetPosition = position + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

  }

  goToHomePage() {
    if (!this.homePagePath) return;
    this._router.navigate([this.homePagePath]);
  }

	indicClick(event: any): void {
		console.log("Indic click");
		console.log(this.hostedCustomPages);

	}
}
