<div id="about-us">
  <div class="responsive-page-container-lg">
    <div class="section" wmAnimate="fadeIn" speed="faster" aos>
      <app-generic-callout
        image="https://kx-assets.s3.amazonaws.com/GettyImages-1141509628.png"
        color="primary"
        side="left"
        icon=""
      >
        <app-kx-logo
          size="lg"
          orientation="stacked"
          [light]="true"
        ></app-kx-logo>
        <p>
          A university-community partnership for research integration,
          translation, and communication.
        </p>
        <p>
          <youtube-video
            videoID="TR5zH_UIRBo"
            title="Watch our introduction video."
          ></youtube-video>
        </p>
        <span
          class="material-icons"
          (click)="toggleShowIcons()"
          (keyup.enter)="toggleShowIcons()"
          style="cursor: pointer"
          tabindex="0"
        >
          {{ showIcons ? "expand_less" : "expand_more" }}
        </span>
      </app-generic-callout>
    </div>
    <br /><br />

    <div
      class="section"
      wmAnimate="fadeIn"
      speed="faster"
      aos
      *ngIf="showIcons"
    >
      <content-list [content]="iconSlides" type="icon-slider"></content-list>
    </div>

    <div class="section" wmAnimate="fadeIn" speed="faster" aos>
      <app-generic-callout
        image="https://kx-assets.s3.amazonaws.com/GettyImages-1249627169.png"
        color="tertiary"
        link="/page/our-purpose"
        side="right"
      >
        <h2>Our Purpose</h2>
        <p>
          KX brings together researchers, Extension professionals,
          decision-makers, and communicators to share, explore, and engage with
          the world-class research from the College of Food, Agricultural, and
          Environmental Sciences at The Ohio State University.
        </p>
      </app-generic-callout>
    </div>

    <br /><br />
    <div class="section" wmAnimate="fadeIn" speed="faster" aos>
      <app-generic-callout
        image="https://kx-assets.s3.amazonaws.com/GettyImages-1183383171.png"
        color="secondary"
        link="/page/our-promise"
        side="left"
      >
        <h2>Our Promise</h2>
        <p>
          KX provides a modern and interactive platform for the university and
          decision-makers to identify needs in our communities, exchange ideas,
          and drive meaningful solutions through research.
        </p>
      </app-generic-callout>
    </div>
    <br /><br />
  </div>
</div>
