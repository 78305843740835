<div class="content-highlight-list">
  <div class="item" *ngFor="let item of items | async">
    <div class="img-wrapper" *ngIf="item.imageUrl && item.imageUrl.length > 0">
      <a
        class="img"
        [href]="item.link"
        target="_parent"
        [attr.aria-label]="item.title"
        [ngStyle]="getImgStyle(item.imageUrl)"
      >
      </a>
    </div>
    <div class="text-wrapper">
      <a class="title" [innerHTML]="item.title"> </a>
      <span
        class="description"
        *ngIf="item.description && item.description.length > 0"
        [innerHTML]="item.description"
      >
      </span>
    </div>
  </div>
</div>
