import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";

export type giveUsYourContactInfoFormat = {
  name: string;
  email: string;
  organization: string;
  source: string;
};

@Component({
  selector: "app-acpf-contact-info-form",
  templateUrl: "./acpf-form.component.html",
  styleUrls: ["./acpf-form.component.scss"],
})
export class AcpfContactInfoFormComponent {
  @Output() completed = new EventEmitter();

  responses: giveUsYourContactInfoFormat = {
    name: "",
    email: "",
    organization: "",
    source: "efields",
  };

  recieved = false;

  constructor(private api: ApiService) {}
  submit() {
    this.api.submitContactInfoForm(this.responses).subscribe((res) => {
      console.log("ok")
      this.recieved = true;
      this.completed.emit(true);
    });

    window.scrollTo(0, 0);
  }
}
