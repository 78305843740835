import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import SwiperCore, { A11y, Swiper, Mousewheel } from "swiper/core";
import { contentListItem } from "../util";

@Component({
  selector: "app-highlight",
  templateUrl: "./highlight.component.html",
  styleUrls: ["./highlight.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HighlightComponent {
  @Input() items: Observable<contentListItem[]>;

  constructor(private cdr: ChangeDetectorRef) {}

  getImgStyle(url) {
    return url?.length > 0
      ? getBackgroundImageStyles(0, url)
      : { background: "#ddd" };
  }
}
