<div class="contact-form">
  <h1>{{ recieved ? "Thanks for reaching out to us!" : "Let's Talk!" }}</h1>

  <ng-container *ngIf="recieved == false; else complete">
    <p>
      Hey, thanks for visiting our website. If you landed here, you probably
      have a question, or you'd like to talk to someone on
      <a href="/directory">our team</a>.
    </p>
    <p>
      First, let's find out what you need. Please select the reason you are
      contacting us below. We'd appreciate a little more information so we know
      who can help you. Fill in the comment box with a description of your
      request or question.
    </p>
    <p>
      Our goal is to get back to you within 24 hours, but sometimes we get lost
      in research and data. We promise to let you know we're working on your
      request!
    </p>
    <div class="user-info-section">
      <mat-form-field appearance="outline" class="user-info">
        <mat-label>Full Name</mat-label>
        <input matInput [(ngModel)]="responses.fullName" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="user-info">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="responses.email" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="user-info">
        <mat-label>Department/Unit/Org</mat-label>
        <input matInput [(ngModel)]="responses.department" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="user-info">
        <mat-label>Job title</mat-label>
        <input matInput [(ngModel)]="responses.jobTitle" />
      </mat-form-field>
    </div>

    <div class="subject-section">
      <strong>I'd like to talk about</strong>
      <mat-form-field appearance="outline">
        <mat-label>Reason for contacting</mat-label>
        <mat-select [(ngModel)]="responses.reasonForContacting">
          <mat-option value="data_needed">Data needed</mat-option>
          <mat-option value="data_to_share">Data to share</mat-option>
          <mat-option value="tool_idea">Tool idea</mat-option>
          <mat-option value="research_to_share">Research to share</mat-option>
          <mat-option value="link_this">Link this on KX</mat-option>
          <mat-option value="website_issue">Website issue</mat-option>
          <mat-option value="other">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="message-section">
      <strong>Your Message</strong>
      <mat-form-field appearance="outline">
        <mat-label>Message</mat-label>
        <textarea
          [(ngModel)]="responses.message"
          matInput
          placeholder="I have a question/comment/concern about..."
          rows="6"
        ></textarea>
      </mat-form-field>
    </div>

    <button
      mat-flat-button
      color="primary"
      (click)="submit()"
      [disabled]="responses.message == ''"
    >
      Submit
    </button>
    <br /><br />
  </ng-container>
  <ng-template #complete>
    <br />
    <p>Your response has been submitted.</p>
    <p>
      Our goal is to get back to you within 24 hours, but sometimes we get lost
      in research and data. We promise to let you know we're working on your
      request!
    </p>
    <br />
  </ng-template>

  <hr />
  <br />

  <h3>Get in Touch</h3>

  <p>
    Contact forms aren't for you? We understand. You can email the whole team at
    <a href="mailto:kx@osu.edu">kx@osu.edu</a>. Or reach out to one of the KX
    managers below. We'd love to hear from you!
  </p>

  <div>
    <strong>Tim Haab</strong>, Director -
    <a href="mailto:haab.1@osu.edu">haab.1@osu.edu</a> <br /><br />

    <strong>Kim Winslow</strong>, Assistant Director -
    <a href="mailto:winslow.52@osu.edu">winslow.52@osu.edu</a> <br /><br />

    <strong>Stacy Cochran</strong>, Communications Program Manager -
    <a href="mailto:cochran.442@osu.edu">cochran.442@osu.edu</a><br /><br />

    <strong>Katie Phillips</strong>, Technologies Program Manager -
    <a href="mailto:phillips.1870@osu.edu">phillips.1870@osu.edu</a>
  </div>
</div>
<br /><br /><br />
