import { backgroundStyle } from "../../models/backgroundStyle";
import { CalloutGroupComponent } from "./callout-group/callout-group.component";
import { CalloutComponent } from "./callout/callout.component";
import { GridComponent } from "./grid/grid.component";
import { IconSliderComponent } from "./icon-slider/icon-slider.component";
import { ImageSliderComponent } from "./image-slider/image-slider.component";
import { TextSliderComponent } from "./text-slider/text-slider.component";
import * as dateFormat from "dateformat";
import { VerticalScrollComponent } from "./vertical-scroll/vertical-scroll.component";
import { BasicListComponent } from "./basic-list/basic-list.component";
import { MiniSliderComponent } from "./mini-slider/mini-slider.component";
import { Grid2Component } from "./grid2/grid2.component";
import { ImageSliderWithDescriptionsComponent } from "./image-slider-with-descriptions/image-slider-with-descriptions.component";
import { WhatsNewSwiperComponent } from "./whats-new-swiper/whats-new-swiper.component";
import { HighlightComponent } from "./highlight/highlight.component";
import { environment } from "src/environments/environment";

export function getLink(rawContent: any): string {
  if (!rawContent.type)
    return rawContent.link && rawContent.link.length > 0
      ? rawContent.link
      : null;
  switch (rawContent.type.toUpperCase()) {
    case "QUESTION":
      return "/question/" + encodeURIComponent(rawContent.name);
    case "LINK":
      return rawContent.link ? rawContent.link : rawContent.url;
    case "VIRTUAL TOUR":
      return rawContent.link ? rawContent.link : rawContent.url;
    case "VIDEO":
      return "/video/" + encodeURIComponent(rawContent.name);
    case "FILE":
      return "/file-redirect/" + encodeURIComponent(rawContent.file);
    case "STRUCTURED_STORY":
      return "/story/" + encodeURIComponent(rawContent.name);
    case "IMAGE COLLECTION":
      return "/image-collection/" + encodeURIComponent(rawContent.title);
    case "CUSTOM PAGE":
      return "/page/" + encodeURIComponent(rawContent.name);
    case "CUSTOM LANDING PAGE":
      return "/landing-page/" + encodeURIComponent(rawContent.name);
    case "PODCAST":
      return "/podcast/" + encodeURIComponent(rawContent.name);
    case "DATASET":
      return "/data-set/" + encodeURIComponent(rawContent.title);
    case "DASHBOARD":
      return `/explorer?reportid=${rawContent._id}`;
    default:
      return rawContent.link;
  }
}

export function getIcon(contentType: string) {
  switch (contentType.toUpperCase()) {
    case "QUESTION":
      return "fa fa-question";
    case "LINK":
      return "fa fa-link";
    case "VIDEO":
      return "fa fa-video-camera";
    case "FILE":
      return "fa fa-file-text";
    case "STRUCTURED_STORY":
      return "fa fa-align-left";
    case "IMAGE COLLECTION":
      return "far fa-images";
    case "CUSTOM PAGE":
      return "fas fa-pager";
  }
}
export function getTypeName(contentType: string) {
  if (!contentType) return null;
  switch (contentType.toUpperCase()) {
    case "QUESTION":
      return "Question";
    case "LINK":
      return "Link";
    case "VIDEO":
      return "Video";
    case "FILE":
      return "File";
    case "STRUCTURED_STORY":
      return "Story";
    case "IMAGE COLLECTION":
      return "Gallery";
    case "CUSTOM PAGE":
      return "Page";
    case "PODCAST":
      return "Podcast";
    default:
      return contentType;
  }
}

export function getDate(rawContent: any) {
  let dateStr = rawContent.date;
  if (rawContent.published_at && rawContent.published_at.length > 0)
    dateStr = rawContent.published_at;

  if (
    !environment.production &&
    rawContent.created &&
    rawContent.created.length > 0
  )
    dateStr = rawContent.created;
  let date = new Date(dateStr);
  if (date.toString() == "Invalid Date") return "";
  return dateFormat(date, "shortDate");
}

export function formatDate(str: string) {
  if (new Date(str).toString() == "Invalid Date") {
    console.log("invalid date:", str);
    return "";
  }
  return dateFormat(new Date(str), "shortDate");
}

export function sortByDate(data: any[]): any {
  return data.sort((a, b) => {
    if (!(a.date && b.date)) return 0;
    a = new Date(a.date);
    b = new Date(b.date);
    return a > b ? -1 : a < b ? 1 : 0;
  });
}

export function getBgStyle(img: string): backgroundStyle {
  let darkness = "0.2";
  let someColors = ["#453831", "#484b1c", "#d65828", "#5d1542", "#839ec1"];

  if (img)
    return {
      background: `linear-gradient(rgba(0, 0, 0, ${darkness}),rgba(0, 0, 0, ${darkness})) center center / 430px, url(${img})`,
      "background-position": "center center",
      "background-size": "cover",
    };
  else
    return {
      background:
        someColors[Math.floor(Math.random() * someColors.length)] + "DD",
    };
}
export function removeHtmlEntities(str: string) {
  if (str == "") return "";
  var txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
}

export type contentListItem = {
  link: string;
  date: string;
  type: string;
  title: string;
  display_type?: string;
  imageUrl?: string;
  description?: string;
  kxIcon?: string;
  kxOriginal?: boolean;
};

export enum ListTypes {
  IconSlider = "icon-slider",
  ImageSlider = "image-slider",
  ImageSliderWithDescriptions = "image-slider-with-descriptions",
  TextSlider = "text-slider",
  Callout = "callout",
  CalloutGroup = "callout-group",
  Grid = "grid",
  Grid2 = "grid2",
  VerticalScroll = "vertical-scroll",
  BasicList = "basic-list",
  MiniSlider = "mini-slider",
  WhatsNew = "whats-new",
  Highlight = "highlight",
}

export function getListComponentType(listType: ListTypes): ListComponentTypes {
  switch (listType) {
    case ListTypes.IconSlider:
      return IconSliderComponent;
    case ListTypes.ImageSlider:
      return ImageSliderComponent;
    case ListTypes.ImageSliderWithDescriptions:
      return ImageSliderWithDescriptionsComponent;
    case ListTypes.TextSlider:
      return TextSliderComponent;
    case ListTypes.Callout:
      return CalloutComponent;
    case ListTypes.CalloutGroup:
      return CalloutGroupComponent;
    case ListTypes.Grid:
      return GridComponent;
    case ListTypes.Grid2:
      return Grid2Component;
    case ListTypes.VerticalScroll:
      return VerticalScrollComponent;
    case ListTypes.BasicList:
      return BasicListComponent;
    case ListTypes.MiniSlider:
      return MiniSliderComponent;
    case ListTypes.WhatsNew:
      return WhatsNewSwiperComponent;
    case ListTypes.Highlight:
      return HighlightComponent;
  }
}
export type ListComponentTypes =
  | typeof ImageSliderComponent
  | typeof ImageSliderWithDescriptionsComponent
  | typeof TextSliderComponent
  | typeof IconSliderComponent
  | typeof GridComponent
  | typeof Grid2Component
  | typeof CalloutComponent
  | typeof CalloutGroupComponent
  | typeof VerticalScrollComponent
  | typeof BasicListComponent
  | typeof MiniSliderComponent
  | typeof WhatsNewSwiperComponent
  | typeof HighlightComponent;
