<div class="full-page-image-header" [ngStyle]="getHeaderBackground()">
  <div class="header-content">
    <ng-container *ngFor="let topic of topics; last as last">
      <a class="topic" href="./topic/{{ topic.url }}"
        >{{ topic.name }}<span *ngIf="!last">, </span></a
      >
    </ng-container>
    <h1>{{ title }}</h1>
    <h2>{{ description }}</h2>
  </div>
  <i class="fas fa-chevron-down" *ngIf="!hideArrow"></i>
</div>
