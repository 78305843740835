<div class="page-background light">
  <h1 class="wide-page-header light">Our People</h1>
  <div class="responsive-page-container">
    <app-loading-indicator *ngIf="loading"></app-loading-indicator>
    <mat-tab-group
      [selectedIndex]="selectedIndex"
      (selectedTabChange)="onTabChange($event)"
      [ngClass]="{ ready: !loading }"
      id="directory-tabs"
    >
      <mat-tab label="KX Team">
        <br />
        <content-list
          [content]="kxStaff.other"
          type="grid2"
          [limit]="1000"
        ></content-list>
        <h3 class="m-2 mt-4">Technology</h3>
        <p class="m-2 mt-0 mb-0">
          We specialize in software and app development, data management and
          analysis, as well as geospatial technologies. We provide the structure
          and support for the KX website, as well as data and visualization
          tools.
        </p>
        <content-list
          [content]="kxStaff.technology"
          type="grid2"
          [limit]="1000"
        ></content-list>
        <h3 class="m-2 mt-4">Communications</h3>
        <p class="m-2 mt-0 mb-0">
          We transform research knowledge into accessible and engaging products
          for Extension, decision-makers, and the curious public.
        </p>
        <content-list
          [content]="kxStaff.communication"
          type="grid2"
          [limit]="1000"
        ></content-list>
        <h3 class="m-2 mt-4">Integration</h3>
        <p class="m-2 mt-0 mb-0">
          We build relationships with researchers and educators, consolidate new
          knowledge to amplify, and make connections within the college
          community.
        </p>
        <content-list
          [content]="kxStaff.integration"
          type="grid2"
          [limit]="1000"
        ></content-list>
      </mat-tab>
      <mat-tab label="Contributors" tabindex="0">
        <content-list
          [content]="affiliates"
          type="grid2"
          [limit]="1000"
        ></content-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
