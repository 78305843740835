<div class="content-list-basic">
  <a
    *ngFor="let item of items | async; index as i"
    class="list-item"
    [href]="item.link"
    target="_parent"
  >
    <span class="type">
      {{ !!item.display_type && item.display_type.length>0 ? item.display_type : item.type }}
    </span>
    <h2>{{ item.title }} • {{ item.date }}</h2>
    <span class="desc" [innerHTML]="item.description"></span>
  </a>
</div>
