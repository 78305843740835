import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { HexColorGeneratorsService } from "src/app/services/hex-color-generators.service";
import {
  ChartConfiguration,
  ChartDataSets,
  ChartOptions,
  ChartType,
} from "chart.js";
import { ChartJSComponent, CptWidgetInput } from "../../types/types";
import {
  MULTI_COLORS,
  MULTI_BACKGROUND_COLORS,
} from "../../types/constants";
import { BaseChartDirective } from "ng2-charts";

@Component({
  selector: "app-multi-var-bar-widget",
  templateUrl: "./multi-var-bar-widget.component.html",
  styleUrls: ["./widget.component.scss"],
})
export class MultiVarBarWidgetComponent implements OnInit, ChartJSComponent {
	@ViewChild("chart") chartContainer: ElementRef
  @ViewChild("resizeContainer", {static: false}) resizeContainer: ElementRef;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

	displayChart: boolean = true;
  private _displayImage: string;

  get displayImage(): string {
    return this._displayImage;
  }

  setDisplayImage() {
    if (!this.chart) return;
    this._displayImage = this.chart.toBase64Image();
  }

  updateChartElement(width: number, height: number): void {
		this.displayChart = width > 250;
		if (this.displayChart) return;
    let sizeRatio = height / width;
		width = 400;
		height = width * sizeRatio;
		this.displayChart = false;
		setTimeout(() => {
			let element = this.resizeContainer.nativeElement;
			if (!element) return;
			element.style.width = `${width}px`;
			element.style.height = `${height}px`;
			this.chart.update();
		},100)
  }

  @Input() widgetInput: CptWidgetInput;
  data: ChartDataSets[];
  options: ChartOptions;
  type: ChartType = "bar";

  constructor(private _hex: HexColorGeneratorsService) {}

  ngOnInit(): void {
    this.setConfigurationOptions();
    this.setData()
    console.log(this.widgetInput);
    console.log(this.data);
    console.log(this.labels);
    console.log(this.groups);
  }

  get groups() {
    return this.widgetInput.cptWidget.groups;
  }

  get labels() {
    return this.widgetInput.cptWidget["labels"];
  }

  get label() {
    return this.widgetInput.cptWidget.label;
  }
  private setConfigurationOptions(): void {
    this.options = {
			devicePixelRatio: 2.5,
      responsive: true,
			maintainAspectRatio: false,
      animation: {
        onComplete: () => {
          this.setDisplayImage();
        },
      },
      title: {
        display: true,
        text: `${this.widgetInput.selection.title}: ${this.widgetInput.scopes[0]}`,
				fontFamily: "'Georgia', serif",
				fontColor: '#333'
      },
      legend: {
        position: "bottom",
        labels: {
          fontSize: 8,
					fontFamily: "'Arial', sans-serif",
					fontColor: '#333',
					fontStyle: 'bold'
        },
      },
      scales: {
        xAxes: [
          {
						ticks: {
							fontColor: '#333',
							fontStyle: 'bold'
						},
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
							fontColor: '#333',
							fontStyle: 'bold',
              callback: (label: number) => {
                return label > 1000000000
                  ? label / 1000000000 + "b"
                  : label > 1000000
                  ? label / 1000000 + "m"
                  : label > 10000
                  ? label / 1000 + "k"
                  : label;
              },
              beginAtZero: true,
            },
          },
        ],
      },
    };
  }


	updateScope(newScope: string) {
		this.widgetInput.scopes[0] = newScope;
		this.setData();
		this.setConfigurationOptions();
    console.log(this.chart);
		this.chart.update();
	}

	private updateTitle() {
		const title = this.widgetInput.selection.title;
		const scope = this.widgetInput.scopes[0];
    this.options.title.text = `${title}: ${scope}`;

	}

  private async setData(): Promise<void> {
    this.data = [];
    for (let i = 0; i < this.groups.length; i++) {
      let g = this.groups[i];
      let dataset: ChartDataSets = {
        label: g.groupLabel,
        data: [...Array(this.groups.length).keys()],
        backgroundColor: MULTI_BACKGROUND_COLORS[i],
        borderColor: MULTI_COLORS[i],
        hoverBackgroundColor: MULTI_BACKGROUND_COLORS[i],
        hoverBorderColor: MULTI_COLORS[i],
        borderWidth: 1,
      };
      this.data.push(dataset);
    }
    for (let group of this.groups) {
      let ds = this.data.find((d) => d.label == group.groupLabel);
      let vars = group.valueVarIDs;
      for (let i = 0; i < vars.length; i++) {
        let v = this.widgetInput.variables.find((n) => n._id == vars[i]);
        let data = v.data[this.widgetInput.scopes[0]];
        let label = v["label"];
        ds.data[i] = Number(data?.replace(/,/g, ""));
      }
    }
  }
}
