import {GridsterConfig} from 'angular-gridster2';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import { EFieldsTrial } from 'src/app/shared/models/eFieldTrial';


export type WidgetLog = {
	title: string;
	type: string;
	source: string;
	scopes?: string[];
	time: string;
	environment: 'DEV' | 'STAGE' | 'PROD';
	action: 'ADD' | 'IMAGE_EXPORT' | 'PDF_EXPORT'
}

export type Dashboard = {
	title: string;
	subtitle?: string;
  published: boolean;
  file: any;
  fileName: string;
  type: string;
  tumbnailName?: string;
  thumbnail?: string;
  imageUrl?: string;
}


		export type SelectionMap = {
			value: Array<WidgetSelection>,
			bar: Array<WidgetSelection>,
			line: Array<WidgetSelection>,		
			multi_var_bar: Array<WidgetSelection>,		
			map: Array<WidgetSelection>,
			efields: Array<eFieldsWidgetSelection>,
			header: Array<WidgetSelection>,
			body: Array<WidgetSelection>,
		}


export interface Page {
	widgets: WidgetInput[],
	config: GridsterConfig,
	titlePage: boolean,
	uniqueId: string,
	pageHasTitle: boolean;
	pageTitle?: string,
	thumbnail?: Promise<string>
}


export type ExplorerState = {
	title?: string,
	firstName?: string,
	lastName?: string
	subTitle?: string,
	portrait: boolean,
	pageSize: number,
	pageCount: number,
	activeIndex: number,
	date: string,
	editingTitle: boolean,
	editableTitleState: {
		firstName: string,
		lastName: string,
		title: string,
		subTitle: string;
	}
	downloading: boolean
}

export enum DialogID {
	select,
	create,
	edit,
	delete,
	title,
	reorder
}

export interface ToolContainer {
	tool: string,
	container: string
}


export type Point = {
	x: number,
	y: number
}

// position and Size
export type pAndS = {
	position: Point,
	size: Point		
}

export type ExplorerExport = {
	pages: Page[],
	state: ExplorerState,
	timeStamp: string
}


export type SelectionType = "value" | "bar" | "pie" | "line" | "multi_var_bar" | "map" | "text" | "scatter" | "legend" | "efields";
 
export interface WidgetSelection {
  id: string;
  title: string;
  type: SelectionType,
	source: Source;
	sourceText: string;
	toolContainerRefs: string[];
	geometryType?: string;
	_years?: string[];
	year?: number
}

export interface eFieldsWidget {
  ids: string[];
  title: string;
  years: number[];
}

export interface eFieldsWidgetSelection extends WidgetSelection {
  ids: string[];
  years: number[];
}


export type Source = "cpt_census" | "cpt_nass" | "fsm" | "user" | "efields" | "none";


export interface WidgetInput {
  id: string;
  selection: WidgetSelection;
  cols: number;
  rows: number;
  x: number;
  y: number;
  dragEnabled?: boolean;
	exampleWidget?: boolean;
}

export interface ChartJSComponent {
	displayImage: string,
	updateChartElement(width: number, height: number): void
	setDisplayImage(): void
}

export type multiPageWidgetInput = {
	index: number,
	widget: WidgetInput
}

export interface EfieldsWidgetInput extends WidgetInput {
  datasets: ChartDataSets[];
  labels: string[];
  options: ChartOptions;
  type: string;
}

export interface GeneratedEfieldsWidgetInput extends WidgetInput {
  trials: EFieldsTrial[];
  xAxis?: string;
  yAxis?: string;
}

export interface CptWidgetInput extends WidgetInput {
  cptWidget: CptWidget;
  variables: Variable[];
  scopes: string[];
}

export interface FsmWidgetInput extends WidgetInput {
  maps: LayerWithName[];
	displayTitle?: string;
}

export type LayerWithName = {name: string, layer: Layer }


export type Layer = {
datanodeId : string,
layerGroup : string,
persistent : boolean,
geometryType: "Point" | "Polygon",
//polyOptions: {field: string, min: number, max: number},
popupFields : any[],
published : boolean,
url : string,
year : number,

} 

export interface LegendWidgetInput extends WidgetInput {
  parentMapId: string;
}

export interface UserChartWidgetInput extends WidgetInput {
  xLabel: string;
  yLabel?: string;
  xValues: string[];
  yValues?: string[];
	labels?: string[];
}

export interface UserTextWidgetInput extends WidgetInput {
	header: string;
	body: string;
}

export interface UserWidgetInput {
  xLabel: string;
  yLabel?: string;
  xValues: string[];
  yValues?: string[];
	labels?: string[];
}

export interface CptWidget {
  _id: string;
  label: string;
  dataLevel: string;
  category: string;
  valueVarID: string;
	excludeScope: string[];
  years?: [];
  bars?: [];
  slices?: [];
	groups?: {
		groupLabel: string,
		valueVarIDs: string[]
	}[];
}


export interface Variable {
  _id: string;
  name: string;
  formatter: string;
  scope: string;
  data: Data;
  __v: number;
}

export type EditType = "Default" | "Text" | "Map" | "Line" | "Cpt" | "Efields";

export interface WidgetEditInput {
	widget: WidgetInput,
	editType: EditType,
	currentAltText: string
}

export interface PageReorderOutput {
	submitted: boolean;
	newPageOrder: number[]
}

export interface TitlePageEditOutput {
	submitted: boolean;
	title: string;
	subTitle: string;
	firstName: string;
	lastName: string;
}

export interface DeleteWidgetOutput {
	submit: boolean,
	deleteType: 'widget' | 'page';
	pageIndex: number;
	id: string;
}

export interface WidgetEditOutput {
	id: string,
	pageChange: boolean;
	page: number;
	altTextChange: boolean;
	altText: string;
}

export interface MapWidgetEditOutput extends WidgetEditOutput  {
	layerChange: boolean;
	layers: LayerWithName[]
}

export interface TextWidgetEditOutput extends WidgetEditOutput {
	textChange: boolean;
	headerText: string;
	bodyText: string;
}

export interface TextWidgetEditOutput extends WidgetEditOutput {
	textChange: boolean;
	headerText: string;
	bodyText: string;
}

export interface CptWidgetEditOutput extends WidgetEditOutput {
	scopeChange: boolean;
	scope: string;
}

export interface CptLineWidgetEditOutput extends WidgetEditOutput {
	scopesChanged: boolean;
	scopes: string[];
}
export interface EfieldsWidgetEditOutput extends WidgetEditOutput {
  axisChange: boolean;
	xAxis: string;
	yAxis: string;
}



export interface PdfData {
	orientation: string,
	header: string,
	footer: string,
	pages: PageData[],
	title: string,
	titlePage: string
}
export interface PageData {
  dimensions: { x: number; y: number };
  widgets: WidgetData[];
  header: string;
	footer?: string
}

export type WidgetData = {
	name?: string,
	altText: string,
	actualText: string,
	encodedImage: string,
  position: { x: number; y: number },
  dimensions: { x: number; y: number }
}

export const allScopes = [
  "Gallia",
  "Huron",
  "Athens",
  "Adams",
  "Medina",
  "Union",
  "Champaign",
  "Paulding",
  "Greene",
  "Franklin",
  "Marion",
  "Morgan",
  "Wayne",
  "Wyandot",
  "Butler",
  "Harrison",
  "Knox",
  "Richland",
  "Vinton",
  "Hancock",
  "Fairfield",
  "Defiance",
  "Henry",
  "Jefferson",
  "Putnam",
  "Clinton",
  "Fayette",
  "Pickaway",
  "Logan",
  "Summit",
  "Sandusky",
  "Shelby",
  "Van Wert",
  "Washington",
  "Williams",
  "Auglaize",
  "Columbiana",
  "Darke",
  "Ottawa",
  "Fulton",
  "Hardin",
  "Jackson",
  "Lucas",
  "Morrow",
  "Noble",
  "Preble",
  "Seneca",
  "Stark",
  "Allen",
  "Coshocton",
  "Crawford",
  "Delaware",
  "Guernsey",
  "Hocking",
  "Mercer",
  "Ross",
  "Cuyahoga",
  "Lorain",
  "Miami",
  "Montgomery",
  "Trumbull",
  "Wood",
  "Ashtabula",
  "Madison",
  "Muskingum",
  "Geauga",
  "Monroe",
  "Lake",
  "Clark",
  "Holmes",
  "Warren",
  "Ashland",
  "Mahoning",
  "Scioto",
  "Meigs",
  "Pike",
  "Hamilton",
  "Erie",
  "Lawrence",
  "Tuscarawas",
  "Brown",
  "Licking",
  "Carroll",
  "Clermont",
  "Highland",
  "Portage",
  "Belmont",
  "Perry",
  "Total",
];



export interface Data {
  Adams: string;
  Allen: string;
  Ashland: string;
  Ashtabula: string;
  Athens: string;
  Auglaize: string;
  Belmont: string;
  Brown: string;
  Butler: string;
  Carroll: string;
  Champaign: string;
  Clark: string;
  Clermont: string;
  Clinton: string;
  Columbiana: string;
  Coshocton: string;
  Crawford: string;
  Cuyahoga: string;
  Darke: string;
  Defiance: string;
  Delaware: string;
  Erie: string;
  Fairfield: string;
  Fayette: string;
  Franklin: string;
  Fulton: string;
  Gallia: string;
  Geauga: string;
  Greene: string;
  Guernsey: string;
  Hamilton: string;
  Hancock: string;
  Hardin: string;
  Harrison: string;
  Henry: string;
  Highland: string;
  Hocking: string;
  Holmes: string;
  Huron: string;
  Jackson: string;
  Jefferson: string;
  Knox: string;
  Lake: string;
  Lawrence: string;
  Licking: string;
  Logan: string;
  Lorain: string;
  Lucas: string;
  Madison: string;
  Mahoning: string;
  Marion: string;
  Medina: string;
  Meigs: string;
  Mercer: string;
  Miami: string;
  Monroe: string;
  Montgomery: string;
  Morgan: string;
  Morrow: string;
  Muskingum: string;
  Noble: string;
  Ottawa: string;
  Paulding: string;
  Perry: string;
  Pickaway: string;
  Pike: string;
  Portage: string;
  Preble: string;
  Putnam: string;
  Richland: string;
  Ross: string;
  Sandusky: string;
  Scioto: string;
  Seneca: string;
  Shelby: string;
  Stark: string;
  Summit: string;
  Trumbull: string;
  Tuscarawas: string;
  Union: string;
  "Van Wert": string;
  Vinton: string;
  Warren: string;
  Washington: string;
  Wayne: string;
  Williams: string;
  Wood: string;
  Wyandot: string;
}
