import { Component, HostListener, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { defaultIfEmpty, map, mergeMap, tap } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { TitleService } from "src/app/services/title.service";
import { Infographic } from "./util";

type Hotspot = {
  topPercent: number;
  leftPercent: number;
  title?: string;
  image?: string;
  description?: string;
  link?: string;
};
@Component({
  selector: "app-image-map-infographic",
  templateUrl: "./image-map-infographic.component.html",
  styleUrls: ["./image-map-infographic.component.scss"],
  host: {
    "(window:resize)": "onResize($event)",
    "(window:scroll)": "onScroll($event)",
  },
})
export class ImageMapInfographicComponent implements OnInit {
  @Input() infographicTitle: string;
  @Input() id: string = "";
  backgroundImage: string;
  hotspots: Hotspot[];
  activeHotspotIndex: number;
  clientCursorPosition: { x: number; y: number } = { x: 0, y: 0 };
  imageHeight: number = 0;
  @Input() displayType: "sidebar" | "mouse-popup" | "modal" = "mouse-popup";
  infographic: Observable<any>;
  constructor(private _api: ApiService) {}

  onResize(event) {
    this.getImgHeight();
  }
  onScroll(event) {
    if (this.displayType == "modal") this.deactivateHotspot();
  }

  ngOnInit() {
    let getFn =
      this.infographicTitle && this.infographicTitle.length > 0
        ? this._api.getInfographic(this.infographicTitle)
        : this._api.getInfographicById(this.id);
    this.infographic = getFn.pipe(
      tap((res: Infographic) => {
        if (!res) window.location.href = "/404";
      }),
      mergeMap((infographic) => {
        if (infographic.hotspots.length > 0)
          return forkJoin(
            infographic.hotspots.map((hs) =>
              this._api.getHotspotLayers(infographic._id, hs.title)
            )
          ).pipe(
            map((hotspotLayers: any) => {
              console.log(hotspotLayers);
              hotspotLayers?.forEach((el, i) => {
                if (infographic.hotspots[i])
                  infographic.hotspots[i].layerIds = el?.layerIds;
              });
              return infographic;
            }),
            defaultIfEmpty(infographic)
          );
        else return of(infographic);
      })
    );
  }
  getImgHeight() {
    this.imageHeight =
      document.getElementById("infographic-image").offsetHeight;
    return this.imageHeight > 0 ? this.imageHeight : 64;
  }
  getButtonStyle(hotspot: Hotspot) {
    return {
      top: hotspot.topPercent.toString() + "%",
      left: hotspot.leftPercent.toString() + "%",
    };
  }
  isActive(index: number) {
    return this.activeHotspotIndex == index;
  }
  toggleHotspot(event: any, index: number) {
    this.activeHotspotIndex == index
      ? this.deactivateHotspot()
      : this.activateHotspot(event, index);
  }
  activateHotspot(event: any, index: number) {
    this.updateMousePosition(event);
    this.activeHotspotIndex = index;
  }
  deactivateHotspot() {
    this.activeHotspotIndex = undefined;
  }
  updateMousePosition(event: any) {
    this.clientCursorPosition = { x: event.clientX, y: event.clientY };
  }
  positionRelativeToCursor() {
    if (window.innerHeight - this.clientCursorPosition.y > 256)
      return {
        top: this.clientCursorPosition.y + 32 + "px",
        left: this.clientCursorPosition.x - 128 + "px",
      };
    else
      return {
        bottom: window.innerHeight - this.clientCursorPosition.y + 32 + "px",
        left: this.clientCursorPosition.x - 128 + "px",
      };
  }
  positionRelativeToMyButton(index: number) {
    let rect = document.getElementById("hotspot" + index).getClientRects()[0];

    return {
      top: rect.top + 40 + "px",
      left: rect.left - 128 + "px",
    };
  }
  goTo(link: string) {
    window.open(link, "_blank");
  }
}
