import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-subscribe-dialog",
  templateUrl: "./subscribe-dialog.component.html",
  styleUrls: ["./subscribe-dialog.component.scss"],
})
export class SubscribeDialogComponent implements OnInit {
  @Input() selectedTopics?: string[];

  topics: { name: string; selected: boolean }[] = [];

  allTopics: string[] = [];

  full_name: string = "";
  email_address: string;
  _id: string;

  state: "INITIAL" | "CHECKING" | "READY" = "INITIAL";

  newSubscriber: boolean;

  constructor(private _api: ApiService) {}

  ngOnInit() {
    console.log(this)
    this._api.getDrupalTopics(true).then((res: any[]) => {
      this.topics = res
        .map((el) => {
          return { name: el.name, selected: false };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      this.updateSelection();
    });
  }
  updateSelection() {
    this.topics = this.topics.map((el) => {
      return {
        name: el.name,
        selected:
          el.selected ||
          (this.selectedTopics && this.selectedTopics.includes(el.name)),
      };
    });
  }
  check() {
    this.state = "CHECKING";
    this._api.getSubscriptions(this.email_address).subscribe((res: any) => {
      this.newSubscriber = !(<boolean>res._id);
      if (!this.newSubscriber) {
        this.full_name = res.full_name;
        this.selectedTopics = res.topics;
        this._id = res._id;
      }
      this.updateSelection();
      this.state = "READY";
    });
  }
  getSelected() {
    return this.topics.filter((el) => el.selected).map((el) => el.name);
  }
  submit() {
    if (this.newSubscriber) {
      this._api
        .newSubscription({
          full_name: this.full_name,
          email_address: this.email_address,
          topics: this.getSelected(),
        })
        .subscribe();
    } else {
      this._api
        .updateSubscription(this._id, {
          full_name: this.full_name,
          email_address: this.email_address,
          topics: this.getSelected(),
        })
        .subscribe();
    }
  }
}
