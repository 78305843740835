import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-social-links",
  templateUrl: "./social-links.component.html",
  styleUrls: ["./social-links.component.scss"],
})
export class SocialLinksComponent implements OnInit {
  @Input() title: string;
  @Input() position: "center" | "right" = "center";
  facebookLink: string;
  twitterLink: string;
  emailLink: string;

  constructor() {}

  ngOnInit() {
    this.facebookLink = this.constructFacebookLink();
    this.twitterLink = this.constructTwitterLink();
    this.emailLink = this.constructEmailLink();
  }

  constructFacebookLink(): string {
    return `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}%2F&amp;src=sdkpreparse`;
  }

  constructTwitterLink(): string {
    return `https://twitter.com/intent/tweet?text=${this.title}&url=${window.location.href}`;
  }

  constructEmailLink(): string {
    return `mailto:?subject=${this.title}&body=${window.location.href}`;
  }
}
