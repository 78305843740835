<div class="contributors-container">
  <div class="view-toggle">
    <div
      class="view-button"
      [ngClass]="view == 'List' ? 'active' : 'unactive'"
      (click)="view = 'List'"
    >
		<i class="fas fa-list fa-lg"></i>
      Contact
    </div>
    <div
      class="view-button"
      [ngClass]="view == 'Grid' ? 'active' : 'unactive'"
      (click)="view = 'Grid'"
    >
		<i class="fas fa-th-large fa-lg"></i>
      Bio
    </div>
  </div>
	<ng-template *ngTemplateOutlet="view == 'List' ? list : grid"></ng-template>
</div>

<ng-template #list>
	<div class="list-container">
		<div class="contributor" *ngFor="let contributor of contributors">
			<div class="name-title">
				<div class="name" [innerHTML]="contributor.name"></div>
				<div class="titles">
				<div class="title" *ngFor="let title of contributor.titles" [innerHTML]="title">
				</div></div>
				<div class="unit" *ngFor="let unitName of contributor.unitNames">{{unitName | uppercase}}</div>
			</div>
			<div class="contact-info">
				<div class="email">
					<div>
						<i class="far fa-envelope"></i>
						<span>Email</span>
					</div>
					<a [href]="'mailto: '+ contributor.email">{{contributor.email}}</a>
				</div>
				<div class="phone">
					<div>
					<i class="fas fa-phone-alt"></i>
					<span>Phone</span>
					</div>
					<a [href]="'tel:'+contributor.number">{{contributor.number | phoneNumber}}</a>
				</div>
			</div>
			<div class="image"
          [style.--featured-image]="getCssUrlVar(contributor.image)"
				>
			</div>
		</div>

	</div>
</ng-template>
<ng-template #grid>
	<div class="grid-container">
    <div class="kx-cards">
      <ng-container *ngFor="let card of contributors; index as i">
        <div
          class="kx-card"
          [style.--featured-image]="getCssUrlVar(card.image)"
          [ngClass]="{
            active: activeCard == i,
            noActiveCards: activeCard == null
          }"
          (click)="activeCard != i ? (activeCard = i) : (activeCard = null)"
        >
				<h3 [innerHTML]="card.name"></h3>
        </div>
        <div
          class="kx-card-body body-{{ i }}"
          [ngClass]="{ active: activeCard == i }"
        >
          <app-custom-page-section
            *ngFor="let s of card?.sections"
            [sectionData]="s"
          ></app-custom-page-section>
        </div>
      </ng-container>
    </div>
	</div>
</ng-template>
