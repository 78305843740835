import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { getFieldFilterString } from "./search.service";

export enum Department {
  ACEL = "Department of Agricultural Communication, Education, and Leadership",
  AEDE = "Department of Agricultural, Environmental, and Development Economics",
  ANSCI = "Department of Animal Sciences",
  ENT = "Department of Entomology",
  EXT = "Department of Extension",
  FST = "Department of Food Science and Technology",
  FABE = "Department of Food, Agricultural and Biological Engineering",
  HCS = "Department of Horticulture and Crop Science",
  SENR = "School of Environment and Natural Resources",
  ATI = "Ohio State Agricultural Technical Institute",
  PlantPath = "Department of Plant Pathology",
}

export enum DepartmentURL {
  ACEL = "https://acel.osu.edu/",
  AEDE = "https://aede.osu.edu/",
  ANSCI = "https://ansci.osu.edu/",
  ENT = "https://entomology.osu.edu/home",
  EXT = "https://extension.osu.edu/",
  FST = "https://fst.osu.edu/home",
  FABE = "https://fabe.osu.edu/",
  HCS = "https://hcs.osu.edu/",
  SENR = "https://senr.osu.edu/",
  ATI = "https://ati.osu.edu/",
  PlantPath = "https://plantpath.osu.edu/",
}

export type ExpertFields = {
  name?: string;
  name_dot_number?: string;
  legal_first_name?: string;
  legal_last_name?: string;
  department?: keyof typeof Department;
  position_title?: string;
  email?: string;
  keywords?: string[];
  short_keywords?: string[];
  searchable_blob?: string;
  summary?: string;
};

const searchApiUrl =
  "https://ztaryerdq4.execute-api.us-east-1.amazonaws.com/experts";

@Injectable({
  providedIn: "root",
})
export class ExpertSearchService {
  public logSearches: boolean = true;

  constructor(private _http: HttpClient, private _router: Router) {}

  public search({
    query,
    departments,
    filters,
    size,
  }: {
    query?: string;
    departments?: (keyof typeof Department)[];
    filters?: ExpertFields;
    size?: number;
  }) {
    if (!query) query = "x|-x";
    if (!size) size = 1000;

    let queryParams = `?q=${query}&size=${size}${this.stringifyFilters(
      filters
    )}`;

    if (departments && departments.length > 0)
      queryParams +=
        "&fq=" + getFieldFilterString("department", new Set(departments));

    let requestURI = encodeURI(searchApiUrl + queryParams);

    console.log(requestURI);
    let observable = this._http.get(requestURI).map((res: any) => res.hits.hit);

    if (this.logSearches)
      observable.subscribe((res) =>
        console.log(requestURI + "\nResults: ", res)
      );

    return observable;
  }

  public getFieldOptions(fieldName: string) {
    return new Promise<Set<string>>((resolve, reject) => {
      let options: Set<string> = new Set<string>();
      this.search({}).subscribe((result: any[]) => {
        // console.log(result);
        result.forEach((res: any) => {
          // console.log(res);
          let fieldValues = res.fields[fieldName];
          if (fieldValues) {
            if (fieldName == "authors") {
              console.log(fieldValues);
              fieldValues.forEach((el) => options.add(el));
            } else options.add(fieldValues);
          }
        });

        console.log(fieldName, options);
        if (options.size > 0) resolve(options);
        else reject(`No fields titled ${fieldName}.`);
      });
    });
  }

  private stringifyFilters(filters: ExpertFields): string {
    filters = this.getValidFilters(<ExpertFields>filters);
    if (Object.keys(filters).length == 0) return "";

    let filterString = "&fq=(and";

    for (const filterName in filters)
      filterString += ` ${filterName}:'${filters[filterName]}'`;

    filterString += ")";

    return filterString;
  }

  private getValidFilters(filters: ExpertFields): ExpertFields {
    let validFilters: ExpertFields = {};

    for (const filterName in filters) {
      if (filters[filterName]?.length > 1)
        validFilters[filterName] = filters[filterName];
    }

    return validFilters;
  }
}
