import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap, retry } from "rxjs/operators";
import * as jsonAPI from "./json-api";
import { ContentTypeMachineName } from "../shared/models/contentTypeMachineNames";
import { FipsCode, IGroup, MapLayerGroup } from "../food-system-tool/types";
import { DataSet } from "../shared/models/dataSet";
import { AcpfSessionToken } from "./acpf-auth.service";
import { contactUsResponseBodyFormat } from "../shared/components/contact-us/contact-us-form/contact-us-form.component";
import {
  Dashboard,
  eFieldsWidget,
  eFieldsWidgetSelection,
  PdfData,
  ToolContainer,
  WidgetLog,
  WidgetSelection,
} from "../explorer/types/types";
import { EFieldsTrial } from "../shared/models/eFieldTrial";
import { giveUsYourContactInfoFormat } from "../shared/components/contact-info-solicitation/acpf/acpf-form.component";
import {
  thumbFormFull,
  reactionFormFull,
} from "../shared/components/reactions/app-reactions";

const DRUPAL_URL = "https://kx-make.cfaes.ohio-state.edu/api";
const OSU_FIND_PEOPLE_URL = "https://directory.osu.edu/fpjson.php";
const R_URL = "https://d1muyz2yfpkslp.cloudfront.net";
const LOCAL_DATA_URL = "http://localhost:80";
const KX_DATA_URL =
  environment.envFilter == "localapi"
    ? "http://localhost:80"
    : "https://d161r0goltoavd.cloudfront.net";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private envFilter = environment.envFilter;
  private censusKey = environment.censusKey;

  constructor(private _http: HttpClient) {}

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }

  getDrupalFilteredContent({
    types,
    topics,
  }: {
    types?: ContentTypeMachineName[];
    topics?: string[];
  }) {
    if (topics)
      topics = topics.filter((t) => t).map((t) => encodeURIComponent(t));
    let url =
      DRUPAL_URL +
      "/" +
      this.envFilter +
      "/filtered-content/" +
      (types ?? "any-type") +
      "?_format=json" +
      (topics ? "&topics=" + topics : "");
    return this._http.get(url);
  }

  getCharts() {
    return of(jsonAPI.charts);
  }
  getClusterData() {
    return of(jsonAPI.clusterdata);
  }
  getCommodities() {
    return of(jsonAPI.commodities);
  }

  getCovidStats() {
    return this._http.get(KX_DATA_URL + "/covidStats");
  }
  getPersonInfoFromOSUDirectory(nameDotNumber: String) {
    return this._http.get(OSU_FIND_PEOPLE_URL + "?name_n=" + nameDotNumber);
  }
  getDrupalPeople(team?: KxTeamFilter) {
    return this._http.get(
      `${DRUPAL_URL}/people?_format=json${
        team ? `&department=${team.valueOf()}` : ""
      }`
    );
  }
  getDrupalPerson(username: String) {
    return this._http.get(DRUPAL_URL + "/people/" + username + "?_format=json");
  }
  getDrupalLandingPageHeaders() {
    return this._http
      .get(DRUPAL_URL + "/active-headers?_format=json")
      .pipe(
        mergeMap((res: { active_header_ids: string[] }[]) =>
          this._http.get(
            `${DRUPAL_URL}/landing-page-header/${res[0].active_header_ids.join(
              ","
            )}?_format=json`
          )
        )
      );
  }
  getChartData(name: string) {
    return this._http
      .get(R_URL + "/" + name)
      .pipe(retry(3), catchError(this.handleError()));
  }
  getCensusData(name: string) {
    return this._http.get(R_URL + "/" + name);
  }
  getCensusMetaData(name: string) {
    return this._http.get(
      "https://api.census.gov/data/2018/acs/acs5/variables/" + name + ".json"
    );
  }
  getCensusGroupData(group: string) {
    return this._http.get(
      `https://api.census.gov/data/2018/acs/acs5?get=NAME,group(${group})&for=county:*&in=state:39&key=${this.censusKey}`
    );
  }
  getCensusGroupDataStateLevel(group: string) {
    return this._http.get(
      `https://api.census.gov/data/2018/acs/acs5?get=NAME,group(${group})&for=state:39&key=d9842d679b3e64d0f91ced27cdef9af67c86bf52`
    );
  }
  getCensusVariableStateLevel(variable: string, year: string) {
    return this._http.get(
      "https://api.census.gov/data/" +
        year +
        "/acs/acs5?key=d9842d679b3e64d0f91ced27cdef9af67c86bf52&get=" +
        variable +
        "&for=state:39"
    );
  }
  getCensusVariableCountyLevel(variable: string, year: string) {
    return this._http.get(
      "https://api.census.gov/data/" +
        year +
        "/acs/acs5?key=d9842d679b3e64d0f91ced27cdef9af67c86bf52&get=" +
        variable +
        "&for=county:*&in=state:39"
    );
  }
  //Drupal Endpoints
  getDrupalImageCollections(title?: string) {
    return this.mapSectionIdsToSections(
      `${DRUPAL_URL}/imageCollections/${this.envFilter}${
        title ? "/" + title : ""
      }?_format=json`,
      `${DRUPAL_URL}/imgcolsect`
    );
  }
  /** @param paragraphRoute this and the corresponding drupal view needs to be configured in a specific way for this to work properly.
   * {{paragraphRoute}}/201,202,203,204?_format=json should return a list of objects with desired fields for paragraphs with id 201, 202, etc.
   *
   * @param contentRoute is just the full route to the content array. No changes will be made to that route.
   * It should return objects with property "sectionIds", e.g { "sectionIds": [201,202,203,204] }. That will be mapped to property "sections".
   */
  mapSectionIdsToSections(contentRoute, paragraphRoute) {
    return new Observable<any[]>((subscriber) => {
      this._http.get(contentRoute).subscribe((contentArray: any[]) => {
        if (contentArray.length == 0) {
          subscriber.next([]);
          subscriber.complete();
        }
        forkJoin(
          contentArray.map((contentItem) =>
            this._http.get(
              `${paragraphRoute}/${contentItem.sectionIds.join(
                ","
              )}?_format=json`
            )
          )
        ).subscribe((paragraphArrays: any[][]) => {
          paragraphArrays.forEach(
            (paragraphArray, index) =>
              (contentArray[index].sections = paragraphArray)
          );
          subscriber.next(contentArray);
          subscriber.complete();
        });
      });
    });
  }
  getDrupalContentByContributor(id: string) {
    return this._http.get(
      DRUPAL_URL + `/contentByContributor?contributor=${id}&_format=json`
    );
  }
  getDrupalWidgets(category?: string) {
    if (category)
      return this._http.get(
        DRUPAL_URL +
          `/widgets/${
            this.envFilter
          }?_format=json&category=${encodeURIComponent(category)}`
      );
    else
      return this._http.get(
        DRUPAL_URL + `/widgets/${this.envFilter}&_format=json`
      );
  }
  getDrupalCensusGroups(bucket: string) {
    return this._http.get(
      DRUPAL_URL + "/census-groups?bucket=" + bucket + "&_format=json"
    );
  }
  getDrupalDataBucketCategories(bucket: string) {
    return this._http.get(
      DRUPAL_URL +
        "/categories?bucket=" +
        encodeURIComponent(bucket) +
        "&_format=json"
    );
  }
  getImpactIntroCopy() {
    return this._http.get(DRUPAL_URL + "/impact-intro?_format=json");
  }
  getAnalyticsStats() {
    return this._http.get(KX_DATA_URL + "/analytics-stats");
  }
  getDrupalMapVariablebyCategory(category: string) {
    return this._http.get(
      DRUPAL_URL +
        "/mapData/?category=" +
        encodeURIComponent(category) +
        "&_format=json"
    );
  }
  getDrupalTrendVariablebyCategory(category: string) {
    return this._http.get(
      DRUPAL_URL +
        "/trendData/?category=" +
        encodeURIComponent(category) +
        "&trend=true&_format=json"
    );
  }
  getDrupalDataBuckets() {
    return this._http.get(DRUPAL_URL + "/buckets?_format=json");
  }
  getDrupalStory(topic?: string) {
    let paragraphRoute = DRUPAL_URL + "/body-section-paragraph";
    let storyRoute =
      DRUPAL_URL +
      "/stories/paragraphs/" +
      this.envFilter +
      `?${topic ? "topic=" + topic + "&" : ""}_format=json`;

    return this.mapSectionIdsToSections(storyRoute, paragraphRoute);
  }
  getDrupalStoryByTitle(title: string) {
    return this._http.get(
      DRUPAL_URL +
        "/stories/paragraphs/" +
        this.envFilter +
        `/${title}?_format=json`
    );
  }
  getDrupalCharts(name: string) {
    return this._http.get(
      DRUPAL_URL + "/charts" + "/" + name + "?_format=json"
    );
  }
  getDrupalQuestions(topic?: string) {
    return this.getDrupalFilteredContent({
      types: ["question"],
      topics: [topic],
    });
  }
  getDrupalQuestionsByTitle(title: string) {
    return this._http.get(
      DRUPAL_URL + "/questions/" + this.envFilter + `/${title}?_format=json`
    );
  }
  getAllDrupalQuestions() {
    return this._http.get(
      DRUPAL_URL + "/questions/" + this.envFilter + `?_format=json`
    );
  }
  getDrupalFiles(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/files/" + this.envFilter + "?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/files/" +
          this.envFilter +
          `?topic="${topic}"&_format=json`
      );
    }
  }
  getDrupalKnowChangeNotes(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/know-change-notes/" + this.envFilter + "?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/know-change-notes/" +
          this.envFilter +
          `?topic=${topic}&_format=json`
      );
    }
  }
  getDrupalFilesByTitle(title: string) {
    return this._http.get(
      DRUPAL_URL + "/files/" + this.envFilter + `/${title}?_format=json`
    );
  }
  getDrupalThemes() {
    return this._http.get(DRUPAL_URL + "/themes" + "?_format=json");
  }
  getCovidTopics(): Promise<{ title: string; link: string }[]> {
    return new Promise((resolve) => {
      let t = [];
      this._http
        .get(DRUPAL_URL + "/covid-topics" + "?_format=json")
        .subscribe((result: any) => {
          result.forEach((element) => {
            t.push({
              title: element.name.replace("&amp;", "&"),
              link: urlify(element.name),
            });
          });
          resolve(t);
        });
    });
  }
  getDrupalTopics(includeSubTopics: boolean = false): Promise<any[]> {
    let route = includeSubTopics ? "/topicsAndSubTopics" : "/topics";
    return new Promise((resolve) => {
      let t = [];
      let alias: string;
      let image: string;
      this._http
        .get(DRUPAL_URL + route + "?_format=json")
        .subscribe((result: any) => {
          result.forEach((element) => {
            alias =
              element.field_alias.length > 0
                ? element.field_alias
                : urlify(element.name);
            image = element.image;
            t.push({
              name: element.name.replace("&amp;", "&"),
              url: alias,
              image: image,
              overridden: element.overridden,
              custom_page_node_id: element.custom_page_node_id,
            });
          });
          resolve(t);
        });
    });
  }
  getDrupalTopicsByBucket(name?: string) {
    if (name)
      return this._http.get(`${DRUPAL_URL}/bucketTopics/${name}?_format=json`);
    else return this._http.get(DRUPAL_URL + "/bucketTopics?_format=json");
  }
  getDrupalNewestContentByBucket(bucket: string) {
    return new Observable<any[]>((subscriber) => {
      this.getDrupalTopicsByBucket().subscribe(
        (buckets: { name: string; topics: string }[]) => {
          let topics: string[] = buckets
            .find((el) => el.name == bucket)
            .topics.split(", ")
            .map((s) => encodeURIComponent(s.replace("&amp;", "&")));

          // Chunk into 6-topic groups, bc drupal allows max 7 filter values
          let topicLists: string[][] = [];
          for (let i = 0; i < topics.length; i += 6)
            topicLists.push(topics.slice(i, i + 6));

          forkJoin(
            topicLists.map((list) =>
              this._http.get(
                `${DRUPAL_URL}/${
                  this.envFilter
                }/newestContentByTopicList?topics=${list.join(
                  ","
                )}&_format=json`
              )
            )
          ).subscribe((res: any[][]) => {
            let content = [].concat(...res);
            // remove duplicates, sort, and reduce to 12 results
            if (content.length > 12)
              content = content
                .filter(
                  (item, index, arr) =>
                    arr.findIndex((el) => el.title == item.title) == index
                )
                .sort((a, b) => {
                  a = Date.parse(a.date);
                  b = Date.parse(b.date);
                  return a > b ? -1 : a < b ? 1 : 0;
                });
            subscriber.next(content.slice(0, 12));
            subscriber.complete();
          });
        }
      );
    });
  }
  getDrupalContributorsTaxonomyTerms() {
    return this._http.get(
      DRUPAL_URL + "/contributors/" + this.envFilter + "?_format=json"
    );
  }
  getDrupalFeaturedRecent() {
    return this._http.get(
      DRUPAL_URL + "/recent/" + this.envFilter + "?_format=json"
    );
  }
  getDrupalFeaturedQuestions(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/questions/" + this.envFilter + "/featured?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/questions/" +
          this.envFilter +
          `/featured?covid-19-topic=${topic}&_format=json`
      );
    }
  }
  getDrupalFeaturedVideos(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/videos/" + this.envFilter + "/featured?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/videos/" +
          this.envFilter +
          `/featured?covid-19-topic=${topic}&_format=json`
      );
    }
  }
  getDrupalFeaturedFiles(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/files/" + this.envFilter + "/featured?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/files/" +
          this.envFilter +
          `/featured?covid-19-topic=${topic}&_format=json`
      );
    }
  }
  getDrupalFeaturedLinks(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/links/" + this.envFilter + "/featured?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/links/" +
          this.envFilter +
          `/featured?covid-19-topic=${topic}&_format=json`
      );
    }
  }
  getDrupalLinks(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/links/" + this.envFilter + "?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL + "/links/" + this.envFilter + `?topic=${topic}&_format=json`
      );
    }
  }
  getDrupalVideos(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL +
          "/videos/" +
          this.envFilter +
          "?_format=json&excludeCovid=1"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/videos/" +
          this.envFilter +
          `?topic="${topic}"&_format=json`
      );
    }
  }
  getAllDrupalVideos() {
    return this._http.get(
      DRUPAL_URL + "/videos/" + this.envFilter + "?_format=json"
    );
  }
  getDrupalVideosByTitle(title: string) {
    return this._http.get(
      DRUPAL_URL + "/videos/" + this.envFilter + `/${title}?_format=json`
    );
  }
  getAllDrupalPodcasts() {
    return this._http.get(
      `${DRUPAL_URL}/${this.envFilter}/podcasts?_format=json`
    );
  }
  getDrupalPodcastSeasons() {
    return this._http.get(`${DRUPAL_URL}/podcast-seasons?_format=json`);
  }
  getDrupalProjects(topic?: string) {
    if (topic === undefined) {
      return this._http.get(
        DRUPAL_URL + "/projects/" + this.envFilter + "?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/projects/" +
          this.envFilter +
          `?topic="${topic}"&_format=json`
      );
    }
  }
  getDrupalProjectsByTitle(title: string) {
    return this._http.get(
      DRUPAL_URL + "/projects/" + this.envFilter + `/${title}?_format=json`
    );
  }
  getImpactQuotes(): Observable<
    {
      title: string;
      author: string;
      image: string;
      author_info: string;
      quote: string;
    }[]
  > {
    return <
      Observable<
        {
          title: string;
          author: string;
          image: string;
          author_info: string;
          quote: string;
        }[]
      >
    >this._http.get(`${DRUPAL_URL}/${this.envFilter}/impact-quotes?_format=json`);
  }
  getDrupalCustomLandingPageByName(name: string) {
    return new Observable<any[]>((subscriber) => {
      this.mapSectionIdsToSections(
        `${DRUPAL_URL}/custom-landing-page/${this.envFilter}/all/${name}?_format=json`,
        `${DRUPAL_URL}/landing-page-tab`
      ).subscribe((landingPageArr: any[]) => {
        if (landingPageArr.length == 0) {
          subscriber.next([]);
          subscriber.complete();
        }
        let lp = landingPageArr[0];

        lp.sections.forEach((lpTab) => {
          if (lpTab.sectionIds.length == 0 && Number(lpTab.bodyId))
            lpTab.sectionIds.push(lpTab.bodyId);

          delete lpTab.body;
          delete lpTab.bodyId;
        });
        forkJoin(
          lp.sections.map((el) =>
            this.getDrupalCustomPageParagraphs(el.sectionIds)
          )
        ).subscribe((lpTabSections: any[][]) => {
          lpTabSections.forEach(
            (lpTabSectionArray, index) =>
              (lp.sections[index].sections = lpTabSectionArray)
          );
          subscriber.next([lp]);
          subscriber.complete();
        });
      });
    });
  }

  getDrupalCustomPageByTitle(title: string) {
    return this.mapSectionIdsToSections(
      `${DRUPAL_URL}/custom-page/${this.envFilter}/${title}/all?_format=json`,
      `${DRUPAL_URL}/custom-page-paragraphs`
    );
  }
  getDrupalCustomPageByName(name: string) {
    return this.mapSectionIdsToSections(
      `${DRUPAL_URL}/custom-page/${this.envFilter}/all/${name}?_format=json`,
      `${DRUPAL_URL}/custom-page-paragraphs`
    );
  }
  getDrupalCustomPageByNodeID(nid: string) {
    return this.mapSectionIdsToSections(
      `${DRUPAL_URL}/custom-page/${this.envFilter}?nid=${nid}&_format=json`,
      `${DRUPAL_URL}/custom-page-paragraphs`
    );
  }
  getDrupalCustomPageParagraphs(ids: string[]) {
    return this._http.get(
      `${DRUPAL_URL}/custom-page-paragraphs/${ids.join(",")}?_format=json`
    );
  }
  getDrupalCustomPageTabs(ids: string[]) {
    return this._http.get(`${DRUPAL_URL}/tab/${ids.join(",")}?_format=json`);
  }
  getDrupalCustomPageCards(ids: string[]) {
    return this._http.get(`${DRUPAL_URL}/card/${ids.join(",")}?_format=json`);
  }
  getDrupalCustomPageContributors(ids: string[]) {
    return this._http.get(
      `${DRUPAL_URL}/contributors/${ids.join(",")}?_format=json`
    );
  }
  getDrupalContent(queryParamString: string) {
    return this._http.get(
      `${DRUPAL_URL}/${this.envFilter}/content${queryParamString}`
    );
  }
  getDrupalTaggedContent(tag: string) {
    return this._http.get(
      DRUPAL_URL + "/tagged-content" + `?tags=${tag}&_format=json`
    );
  }
  getDrupalCarousel(name?: string) {
    if (name === undefined) {
      return this._http.get(
        DRUPAL_URL + "/carousel-slide/paragraphs?_format=json"
      );
    } else {
      return this._http.get(
        DRUPAL_URL +
          "/carousel-slide/" +
          this.envFilter +
          `?name="${name}"&_format=json`
      );
    }
  }
  getDrupalContentList() {
    return this._http.get(DRUPAL_URL + "/topics_count?_format=json");
  }
  getDrupalAccouncements(): Observable<any> {
    return this._http.get(
      `${DRUPAL_URL}/announcements/${this.envFilter}?_format=json`
    );
  }
  getDrupalCommunityProfileCategoryLinkTags(category: string) {
    return this._http.get(
      `${DRUPAL_URL}/cpt-cat-tags?category=${category}&_format=json`
    );
  }
  getDrupalMainTopicCarousel(topic: string) {
    return this._http.get(
      `${DRUPAL_URL}/${this.envFilter}/topic-main-carousel?topic=${topic}&_format=json`
    );
  }

  getDrupalNavigatorPages(): Observable<{ route: string; pages: any[] }[]> {
    type navigator = {
      title: string;
      hostedPageIds: string[];
      homePagePath?: string;
      route?: string;
    };
    return this._http
      .get<navigator[]>(`${DRUPAL_URL}/custom-page-navigator?_format=json`)
      .pipe(
        mergeMap((navigators) => {
          const navigatorsObservable = navigators.map((navigator) => {
            return this.getDrupalHostedCustomPageByNodeId(
              navigator.hostedPageIds
            ).pipe(
              map((hostedCustomPages) => {
                return { route: navigator.route, pages: hostedCustomPages };
              })
            );
          });
          return forkJoin(navigatorsObservable);
        })
      );
  }

  getDrupalNavigatorByNodeId(
    nid: string
  ): Observable<
    { title: string; hostedPageIds: string[]; homePagePath?: string }[]
  > {
    return this._http.get<{ title: string; hostedPageIds: string[] }[]>(
      `${DRUPAL_URL}/custom-page-navigator?nid=${nid}&_format=json`
    );
  }
  getDrupalHostedCustomPageByNodeId(nids: string[]): Observable<any> {
    const nidString = nids.join(",");
    return this._http.get<{ title: string; customPageId: string }[]>(
      `${DRUPAL_URL}/hosted-custom-page/${nidString}?_format=json`
    );
  }

  getDrupalNavigationEntries(nids: string[]) {
    const nidString = nids.join(",");
    return this._http.get(`${DRUPAL_URL}/nav-entry/${nidString}?_format=json`);
  }

  getDrupalImageGridEnties(nids: string[]) {
    const nidString = nids.join(",");
    return this._http.get(
      `${DRUPAL_URL}/image-grid-entry/${nidString}?_format=json`
    );
  }

  getDrupalVariableColumnContainerEntries(nids: string[]): Observable<any> {
    type entry = { paragraph_id: string; percent_width: string };
    const nidString = nids.join(",");
    return this._http.get<entry[]>(
      `${DRUPAL_URL}/variable-column-container-entry/${nidString}?_format=json`
    );
  }

  getDrupalCustomPageQaEntries(nids: string[]) {
    const nidString = nids.join(",");
    return this._http.get(
      `${DRUPAL_URL}/question-answers/${nidString}?_format=json`
    );
  }

  getDrupalImageDescriptions(nids: string[]) {
    const nidString = nids.join(",");
    return this._http.get(
      `${DRUPAL_URL}/image-description/${nidString}?_format=json`
    );
  }

  getDrupalImageInfos(nids: string[]) {
    if (!nids.length) return of([]);
    const nidString = nids.join(",");
    return this._http.get(`${DRUPAL_URL}/image-info/${nidString}?_format=json`);
  }

  getInfographicTags(title: string) {
    return this._http.get(
      `${DRUPAL_URL}/infographic-tags/${title}?_format=json`
    );
  }
  getTagsByNodeID(nid: number) {
    return this._http.get(`${DRUPAL_URL}/tags/node/${nid}?_format=json`);
  }

  getMergedPdfs(pdfArray: HttpParams) {
    return this._http.get("https://d161r0goltoavd.cloudfront.net/pdfcombine", {
      params: pdfArray,
    });
  }
  //KX DATA API
  getTaxonomy() {
    return this._http.get(`${KX_DATA_URL}/community-profiles/taxonomy`);
  }
  getBuckets() {
    return this._http.get(`${KX_DATA_URL}/community-profiles/taxonomy/bucket`);
  }
  getCategories() {
    return this._http.get(
      `${KX_DATA_URL}/community-profiles/taxonomy/category`
    );
  }

  // switch back
  getWidgets(categoryID?: string, type?: string) {
    if (!categoryID) return this._http.get(`${KX_DATA_URL}/widget`);
    else if (type)
      return this._http.get(`${KX_DATA_URL}/widget/${categoryID}/${type}`);
    else return this._http.get(`${KX_DATA_URL}/widget/${categoryID}`);
  }
  getVariable(variableId: string) {
    return this._http.get(KX_DATA_URL + "/variables/" + variableId);
  }
  getInfographics() {
    return this._http.get(`${KX_DATA_URL}/infographic`);
  }
  getInfographic(title: string) {
    return this._http.get(
      `${KX_DATA_URL}/infographic?title=${encodeURIComponent(title)}`
    );
  }
  getInfographicById(id: string) {
    return this._http.get(
      `${KX_DATA_URL}/infographic?id=${encodeURIComponent(id)}`
    );
  }
  getHotspotLayers(infographicId: string, hotspotTitle: string) {
    return this._http.get(
      `${KX_DATA_URL}/hotspotLayers?infographicId=${infographicId}&hotspotTitle=${hotspotTitle}`
    );
  }
  getPanorama(id: string) {
    return this._http.get(
      `${KX_DATA_URL}/panorama?id=${encodeURIComponent(id)}`
    );
  }
  getSubscriptions(email?: string) {
    return this._http.get(`${KX_DATA_URL}/subscriptions/${email ? email : ""}`);
  }
  updateSubscription(id: string, subscription: any) {
    return this._http.put(`${KX_DATA_URL}/subscriptions/${id}`, subscription);
  }
  newSubscription(subscription: any) {
    return this._http.post(`${KX_DATA_URL}/subscriptions`, subscription);
  }
  getAcpfUser(email?: string) {
    return this._http.get(
      `${KX_DATA_URL}/acpf-user${email ? "?email=" + email : ""}`
    );
  }
  getAcpfUserById(id: string) {
    return this._http.get(`${KX_DATA_URL}/acpf-user?id=${id}`);
  }
  acpfLoginAttempt(params: { _id: string; password: string }) {
    return this._http.post(`${KX_DATA_URL}/acpf-user/login-attempt`, params);
  }
  acpfCheckToken(params: { _id: string; sessionToken: string }) {
    return this._http.post(`${KX_DATA_URL}/acpf-user/check-token`, params);
  }
  acpfUserRegister(params: {
    name: string;
    email: string;
    organization: string;
    fileUrls: [];
    verified: false;
    password: string;
  }) {
    return this._http.post(`${KX_DATA_URL}/acpf-user`, params);
  }
  acpfFileUpload(body: {
    token: AcpfSessionToken;
    file: {
      filename: string;
      base64EncodedFile: string;
      type: "core" | "result";
    };
    extra_info?: {
      contact_email?: string;
      contact_name?: string;
      contact_org?: string;
      description?: string;
      data_source?: string;
      what_was_done_to_validate_the_results?: string;
    };
  }) {
    return this._http.post(`${KX_DATA_URL}/acpf-user/upload-file`, body);
  }

  acpfInitPasswordReset(id: string) {
    return this._http.post(KX_DATA_URL + "/acpf-user/init-password-reset", {
      id: id,
    });
  }
  acpfGetUserByPasswordResetBlob(blob: string) {
    return this._http.get(
      KX_DATA_URL + `/acpf-user/get-user-by-blob?blob=${blob}`
    );
  }
  acpfResetPassword(body: {
    _id: string;
    newPassword: string;
    passwordResetBlob: string;
  }) {
    return this._http.post(`${KX_DATA_URL}/acpf-user/change-password`, body);
  }
  //CFAES Search
  searchCfaesContent({
    q,
    count = 16,
    start = 0,
    includeImages = false,
  }: {
    q: string;
    count?: number;
    start?: number;
    includeImages?: boolean;
  }) {
    return this._http.get(
      `${KX_DATA_URL}/cfaes-search?q=${q}&count=${count}&start=${start}&includeImages=${includeImages}`
    );
  }
  //CFAES Search
  searchCfaesPeople({
    q,
    count = 16,
    start = 0,
    includeImages = false,
  }: {
    q: string;
    count?: number;
    start?: number;
    includeImages?: boolean;
  }) {
    return this._http.get(
      `${KX_DATA_URL}/cfaes-people?q=${q}&count=${count}&start=${start}&includeImages=${includeImages}`
    );
  }
  //CFAES Search
  searchFactSheets({
    q,
    count = 16,
    start = 0,
    includeImages = false,
  }: {
    q: string;
    count?: number;
    start?: number;
    includeImages?: boolean;
  }) {
    return this._http.get(
      `${KX_DATA_URL}/fact-sheets?q=${q}&count=${count}&start=${start}&includeImages=${includeImages}`
    );
  }

  //Search Terms
  getSearchTerms() {
    return this._http.get(`${KX_DATA_URL}/search-term`);
  }

  newSearchTerm(searchTerm: any) {
    return this._http.post(`${KX_DATA_URL}/search-term`, searchTerm);
  }
  logCptSearchTerm(searchTerm: { term: string; time: Date }) {
    return this._http.post(`${KX_DATA_URL}/cpt-search-term`, searchTerm);
  }
  getAudioBlobs(urls: string[]) {
    return forkJoin(
      urls.map((url) =>
        this._http.get(url, {
          responseType: "arraybuffer",
        })
      )
    ).map((res) => {
      return res.map((data) => new Blob([data], { type: "audio/mp3" }));
    });
  }

  // CPT Data download
  getCptDataForCategory(categoryId: string) {
    return this._http.get(`${KX_DATA_URL}/cpt-data?id=${categoryId}`);
  }
  getAllCptCensusData() {
    return this._http.get(`${KX_DATA_URL}/cpt-all-used-vars`);
  }

  // NASS WIDGET INTEGRATION
  getNassTaxonomy(): Observable<any> {
    return this._http.get(`${KX_DATA_URL}/nass/taxonomy`);
  }

  getNassWidgetsByCommodityID(commodityID: string) {
    console.log(`${KX_DATA_URL}/nass/widget/${commodityID}`);
    return this._http.get(`${KX_DATA_URL}/nass/widget/${commodityID}`);
  }

  getNassVariable(id: string) {
    return this._http.get(`${KX_DATA_URL}/nass/variable/${id}`);
  }

  publishNassWidget(type: string, id: string) {
    return this._http.put(`${KX_DATA_URL}/nass/${type}/publish/${id}`, null);
  }

  unpublishNassWidget(type: string, id: string) {
    return this._http.put(`${KX_DATA_URL}/nass/${type}/unpublish/${id}`, null);
  }

  publishNassCommodity(id: string) {
    return this._http.put(`${KX_DATA_URL}/nass/commodity/publish/${id}`, null);
  }

  unpublishNassCommodity(id: string) {
    return this._http.put(
      `${KX_DATA_URL}/nass/commodity/unpublish/${id}`,
      null
    );
  }

  getGroups(): Observable<IGroup[]> {
    let taxObservable =
      environment.name == "PRODUCTION"
        ? this._http.get<IGroup[]>(`${KX_DATA_URL}/food-system-prod`)
        : this._http.get<IGroup[]>(`${KX_DATA_URL}/food-system-dev`);
    return taxObservable;
  }

  getFSMLayerGroups(): Observable<MapLayerGroup[]> {
    return this._http.get<MapLayerGroup[]>(`${KX_DATA_URL}/fsm/groups`);
  }

  logFsmLayerSelection(logEntry: {
    time: Date;
    layer_id: string;
    all_active_layer_ids: string[];
  }) {
    if (environment.production)
      this._http
        .post(`${KX_DATA_URL}/layer-selection`, logEntry)
        .subscribe((res) => console.log(res));
  }

  setCommodityMap(commodityID: string, mapID: string, mapLabel: string) {
    let body = {
      mapLabel: mapLabel,
      mapVarID: mapID,
    };
    return this._http.put(
      `${KX_DATA_URL}/nass/commodity/map/${commodityID}`,
      body
    );
  }

  getDataSets(): Observable<DataSet[]> {
    return this._http.get<DataSet[]>(
      `${DRUPAL_URL}/datasets/${this.envFilter}?_format=json`
    );
  }

  getDataSet(title: string): Observable<DataSet[]> {
    return this._http.get<DataSet[]>(
      `${DRUPAL_URL}/datasets/${encodeURIComponent(title)}?_format=json`
    );
  }

  getDataSetByNID(id: string): Observable<DataSet[]> {
    console.log(`${DRUPAL_URL}/datasetsByNID/${id}_format=json`);
    return this._http.get<DataSet[]>(
      `${DRUPAL_URL}/datasetsByNID/${id}_format=json`
    );
  }

  getDrainmodSoilDataByLatLong(lat, long): Observable<any> {
    return this._http.get(`${KX_DATA_URL}/soils?lat=${lat}&long=${long}`);
  }
  getPlotlyGraphs(): Observable<any> {
    return this._http.get(`${KX_DATA_URL}/plotly-charts`);
  }

  getPlotlyChartsByParentId(id: string): Observable<any[]> {
    return this._http.get<any[]>(`${KX_DATA_URL}/plotly-chart/${id}`);
  }

  getPlotlyChartByName(name: string): Observable<any> {
    return this._http.get(`${KX_DATA_URL}/plotly-chart/${name}`);
  }

  getCountySummary(countyName: string) {
    return this._http.get(
      `${KX_DATA_URL}/fsm/countyFlowSummaryByName/${countyName}`
    );
  }

  getCountyFips(fipsCode: string): Observable<FipsCode> {
    return this._http.get<FipsCode>(`${KX_DATA_URL}/county-fips/${fipsCode}`);
  }

  getAllCensusWidgets(): Observable<any> {
    return this._http.get(`${KX_DATA_URL}/widget`);
  }

  getAllNassWidgets(): Observable<any> {
    return this._http.get(`${KX_DATA_URL}/nass/widget`);
  }

  getEfieldsTrials(): Observable<EFieldsTrial[]> {
    return this._http.get<EFieldsTrial[]>(`${KX_DATA_URL}/efields/trials`);
  }

  submitContactUsForm(body: contactUsResponseBodyFormat) {
    return this._http.post(`${KX_DATA_URL}/survey-submission`, body);
  }
  submitContactInfoForm(body: giveUsYourContactInfoFormat) {
    return this._http.post(`${KX_DATA_URL}/contact-info`, body);
  }

  submitReactionForm(body: reactionFormFull) {
    return this._http.post(`${KX_DATA_URL}/reactions`, body);
  }

  submitThumb(body: thumbFormFull) {
    return this._http.post(`${KX_DATA_URL}/thumbs`, body);
  }

  logCornSilageToolEvent(event: {
    action:
      | "run_calculation"
      | "download_untouched_spreadsheet"
      | "download_custom_spreadsheet"
      | "download_table_image";
    data?: any;
  }) {
    let fullEvent = {
      action: event.action,
      time: new Date(),
      environment:
        environment.envFilter == "localapi" ? "dev" : environment.envFilter,
      data: event.data ?? {},
    };
    let obs = this._http.post(
      `${KX_DATA_URL}/corn-silage-tool-event`,
      fullEvent
    );
    obs.subscribe();
    return obs;
  }

  getExplorerWidgetSelections(): Observable<WidgetSelection[]> {
    return this._http.get<WidgetSelection[]>(
      `${KX_DATA_URL}/explorer-selections`
    );
  }
  getExplorerMapWidgetSelections(): Observable<WidgetSelection[]> {
    return this._http.get<WidgetSelection[]>(
      `${KX_DATA_URL}/new-explorer-map-selections`
    );
  }
  getExplorer4hWidgetSelections(): Observable<WidgetSelection[]> {
    return this._http.get<WidgetSelection[]>(
      `${KX_DATA_URL}/explorer-selections-4h`
    );
  }
  getEfieldSelections(): Observable<eFieldsWidget[]> {
    return this._http.get<eFieldsWidget[]>(
      `${KX_DATA_URL}/explorer-selections-efields`
    );
  }
  getExplorerToolContainerReferences(): Observable<ToolContainer[]> {
    return this._http.get<ToolContainer[]>(
      `${KX_DATA_URL}/explorer-toolcontainers`
    );
  }

  getExplorerDashboards(reportid?: string): Observable<Dashboard[]> {
    if (this.envFilter == "prod") {
      return this._http.get<Dashboard[]>(
        `${KX_DATA_URL}/explorer-dashboard?published=true${
          reportid ? "&id=" + reportid : ""
        }`
      );
    }
    return this._http.get<Dashboard[]>(
      `${KX_DATA_URL}/explorer-dashboard${reportid ? "?id=" + reportid : ""}`
    );
  }

  logExplorerAction(log: WidgetLog): Observable<any> {
    return this._http.post(`${KX_DATA_URL}/explorer-log`, log);
  }

  explorerPdfCreate(body: PdfData, dev?: boolean): Observable<any> {
    return this._http.post(
      `${dev ? LOCAL_DATA_URL : KX_DATA_URL}/explorer-pdf`,
      body,
      {
        responseType: "blob",
      }
    );
  }

  logFileDownload(filename: string, url: string) {
    let body = { filename: filename, url: url };
    console.log(body);
    return this._http.post(`${KX_DATA_URL}/log-download`, body);
  }

  getNewsletters() {
    return this._http.get(KX_DATA_URL + "/newsletter");
  }
}
// urlify: remove apostrophes, replace non-words with a single '-', go lowercase
export const urlify = (str: string) => {
  return str
    .replace(/’/g, "")
    .replace(/amp|\W/g, "-")
    .replace(/-+/g, "-")
    .toLowerCase();
};
export enum KxTeamFilter {
  Technology = 1,
  Communication = 2,
  Integration = 3,
  None = 4,
}
