<div class="responsive-page-container">
  <div class="dtou-sect">
    <h1>DATA DISCLAIMER AND TERMS OF USE</h1>
    <br />
    <a
      href="https://kx-files-public.s3.us-east-2.amazonaws.com/kx-data-terms-of-use.pdf"
      target="_blank"
      >View PDF</a
    >
    <br />
    Last updated May 2022
    <br />
    <em> (Any data download constitutes acceptance of these terms) </em>
  </div>

  <div class="dtou-sect">
    <strong>USER'S ACCEPTANCE OF THESE TERMS</strong><br />

    The Knowledge Exchange (KX) in the College of Food, Agriculture, and
    Environmental Sciences (CFAES) at The Ohio State University (OSU) consumes
    public (S1) GIS and additional data (hereinafter, “Data”) from public
    sources and makes it available through our data applications and data
    catalog. These data are provided free of charge under the conditions that
    the user who views, consumes (downloads), uses in their own digital tools,
    or otherwise accesses the provided data does so by agreeing to these Terms
    of Use. These terms apply to you and any other end user you transfer the
    data to. If you do not agree to these Terms of Use, you may not consume
    (use) these data.
  </div>
  <div class="dtou-sect">
    <strong>DATA WARRANTY</strong><br />

    Use of the data and content herein assumes your knowledge of the limitations
    of the data and information used on this site, the purpose for which these
    are presented here, and the understanding that any use of, or determinations
    drawn from, your use of these resources is done so at your own risk. The
    Ohio State University cannot be held liable for any conclusions or
    assumptions made, either implicitly or explicitly, from your use of this
    site, especially when such use deviates from the intended use. FURTHER THE
    OHIO STATE UNIVERSITY MAKES NO OTHER WARRANTY OR GUARANTEE OF ANY KIND IN
    CONNECTION WITH THE DATA PROVIDED HEREIN, AND THE OHIO STATE UNIVERSITY
    DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING THOSE OF NON-INFRINGEMENT,
    MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO ANY
    INFORMATION, DESIGN, SPECIFICATION, PROTOTYPE OR ANY OTHER DATA FURNISHED.
  </div>
  <div class="dtou-sect">
    <strong> DATA SCHEDULE </strong><br />

    The data may be updated irregularly. We have provided update schedules of
    the data within the metadata. Please check the “How often are these data
    updated” section to know more about the source update frequency and The Ohio
    State Universities update frequency.
  </div>
  <div class="dtou-sect">
    <strong> TERMINATION OF DATA AVAILABILITY </strong><br />

    The Ohio State University reserves the right to edit the data in any way and
    to terminate services at any time without notice. The Ohio State University
    is not liable for any modification, suspension, or discontinuation of data
    access.
  </div>
  <div class="dtou-sect">
    <strong> THIRD PARTY APPLICATIONS </strong><br />

    The Ohio State University uses third-party applications (ESRI, Drupal, etc.)
    and websites to store/house/reference the data. The Ohio State University is
    not responsible for the maintenance, performance, or reliability of the
    third-party applications. These vendors may (and will) change the design or
    functionality of their products at any time without notice. The performance
    and reliability of these third-party applications is solely the
    responsibility of the vendor and not The Ohio State University.
    <br /><br />
    A note about data security: ArcGIS Online is a secure platform that uses
    best-in-class data protection practices. Some of these practices, as well as
    relevant data protection compliance achievements, are documented by ESRI in
    the following resource:
    <br />
    <a href="https://trust.arcgis.com/en/security/security-overview.htm">
      https://trust.arcgis.com/en/security/security-overview.htm
    </a>
  </div>
</div>
