import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import {
  IReportEmbedConfiguration,
  models,
  Page,
  Report,
  service,
  Embed,
} from "powerbi-client";
import { PowerBIReportEmbedComponent } from "powerbi-client-angular";
import { IHttpPostMessageResponse } from "http-post-message";
// import 'powerbi-report-authoring';

const reportUrl =
  "https://app.powerbi.com/reportEmbed?reportId=ff18e1c8-2478-4be5-93b0-5075e7465eb9&autoAuth=true&ctid=eb095636-1052-4895-952b-1ff9df1d1121";
import { ApiService } from "src/app/services/api.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";



// Handles the embed config response for embedding
export interface ConfigResponse {
  Id: string;
  EmbedUrl: string;
  EmbedToken: {
    Token: string;
  };
}
@Component({
  selector: "app-embedded-power-bi-report",
  templateUrl: "./embedded-power-bi-report.component.html",
  styleUrls: ["./embedded-power-bi-report.component.scss"],
})
export class EmbeddedPowerBiReportComponent implements OnInit {
  // Wrapper object to access report properties
  @ViewChild(PowerBIReportEmbedComponent)
  reportObj!: PowerBIReportEmbedComponent;

  // Track Report embedding status
  isEmbedded = false;

  // Overall status message of embedding
  displayMessage =
    "The report is bootstrapped. Click Embed Report button to set the access token.";

  // CSS Class to be passed to the wrapper
  reportClass = "report-container";

  phasedEmbeddingFlag = false;

  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  reportConfig: IReportEmbedConfiguration = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };

  eventHandlersMap = new Map([
    [
      "loaded",
      () => {
        const report = this.reportObj.getReport();
        report.setComponentTitle("Embedded report");
        console.log("Report has loaded");
      },
    ],
    ["rendered", () => console.log("Report has rendered")],
    [
      "error",
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ["visualClicked", () => console.log("visual clicked")],
    ["pageChanged", (event) => console.log(event)],
  ]) as Map<
    string,
    (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
  >;

  getEmbedConfig(endpoint: string): Observable<ConfigResponse> {
    console.log("getting..."+endpoint);
    return this.httpClient.get<ConfigResponse>(endpoint);
  }

  constructor(
    public api: ApiService,
    private element: ElementRef<HTMLDivElement>,
    private httpClient:HttpClient,
  ) {}

  /**
   * Embeds report
   *
   * @returns Promise<void>
   */
  async embedReport(): Promise<void> {
    let reportConfigResponse: ConfigResponse;

    // Get the embed config from the service and set the reportConfigResponse
    try {
      let configRes = this.getEmbedConfig(reportUrl);
      configRes.subscribe((res) => console.log(res));
      reportConfigResponse = await configRes.toPromise();
    } catch (error: any) {
      this.displayMessage = `Failed to fetch config for report. Status: ${error.status} ${error.statusText}`;
      console.error(this.displayMessage);
      return;
    }

    // Update the reportConfig to embed the PowerBI report
    this.reportConfig = {
      ...this.reportConfig,
      id: reportConfigResponse.Id,
      embedUrl: reportConfigResponse.EmbedUrl,
      accessToken: reportConfigResponse.EmbedToken.Token,
    };

    // Update embed status
    this.isEmbedded = true;

    // Update the display message
    this.displayMessage =
      "Use the buttons above to interact with the report using Power BI Client APIs.";
  }

  // /**
  //  * Change Visual type
  //  *
  //  * @returns Promise<void>
  //  */
  // async changeVisualType(): Promise<void> {
  //   // Get report from the wrapper component
  //   const report: Report = this.reportObj.getReport();

  //   if (!report) {
  //     this.displayMessage = 'Report not available.';
  //     console.log(this.displayMessage);
  //     return;
  //   }

  //   // Get all the pages of the report
  //   const pages: Page[] = await report.getPages();

  //   // Check if the pages are available
  //   if (pages.length === 0) {
  //     this.displayMessage = 'No pages found.';
  //     return;
  //   }

  //   // Get active page of the report
  //   const activePage: Page | undefined = pages.find((page) => page.isActive);

  //   if (!activePage) {
  //     this.displayMessage = 'No Active page found';
  //     return;
  //   }

  //   try {
  //     // Change the visual type using powerbi-report-authoring
  //     // For more information: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/report-authoring-overview
  //     // Get the visual
  //     const visual = await activePage.getVisualByName('VisualContainer6');

  //     const response = await visual.changeType('lineChart');

  //     this.displayMessage = `The ${visual.type} was updated to lineChart.`;

  //     console.log(this.displayMessage);

  //     return response;
  //   } catch (error) {
  //     if (error === 'PowerBIEntityNotFound') {
  //       console.log('No Visual found with that name');
  //     } else {
  //       console.log(error);
  //     }
  //   }
  // }

  async hideFilterPane(): Promise<IHttpPostMessageResponse<void> | undefined> {
    // Get report from the wrapper component
    const report: Report = this.reportObj.getReport();

    if (!report) {
      this.displayMessage = "Report not available.";
      console.log(this.displayMessage);
      return;
    }

    // New settings to hide filter pane
    const settings = {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    };

    try {
      const response = await report.updateSettings(settings);
      this.displayMessage = "Filter pane is hidden.";
      console.log(this.displayMessage);

      return response;
    } catch (error) {
      console.error(error);
      return;
    }
  }


  setDataSelectedEvent(): void {
    this.eventHandlersMap = new Map<
      string,
      (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
    >([
      ...this.eventHandlersMap,
      ["dataSelected", (event) => console.log(event)],
    ]);

    this.displayMessage =
      "Data Selected event set successfully. Select data to see event in console.";
  }

  // config = {
  //   type: "report",
  //   id: "ff18e1c8-2478-4be5-93b0-5075e7465eb9",
  //   embedUrl:

  //   accessToken: undefined,
  //   tokenType: models.TokenType.Embed,
  //   settings: {
  //     panes: {
  //       filters: {
  //         expanded: false,
  //         visible: false,
  //       },
  //     },
  //     background: models.BackgroundType.Transparent,
  //   },
  // };
  // constructor() {}

  ngOnInit(): void {
    this.embedReport();
  }
}
