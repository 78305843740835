import { Component, Input, OnInit } from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { ServerTransferStateModule } from "@angular/platform-server";

@Component({
  selector: "youtube-video",
  templateUrl: "./video-popup.component.html",
  styleUrls: ["./video-popup.component.scss"],
})
export class VideoPopupComponent implements OnInit {
  @Input() videoID: string;
  @Input() title: string;
  @Input() buttonClasses?: string = "";

  source: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}
  
  ngOnInit() {
    this.source = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/" + this.videoID
    );
  }
}
