import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { delay, tap } from "rxjs/operators";
import * as utils from "./util";

@Component({
  selector: "content-list",
  template: `
    <ng-container *ngIf="!empty">
      <a class="content-list-header" *ngIf="head" [attr.href]="link">
        <h2 class="head" [innerHTML]="head"></h2>
        <span class="subhead" [innerHTML]="subhead"></span>
      </a>
      <ng-container *ngIf="loading">
        <app-loading-indicator></app-loading-indicator>
      </ng-container>
    </ng-container>
  `,
})
export class ContentListComponent implements OnChanges {
  @Input() content: Observable<Object>;
  @Input() type: utils.ListTypes;
  @Input() head?: string;
  @Input() subhead?: string;
  @Input() link?: string = null;
  @Input() sortByDate?: boolean = true;
  @Input() limit: number = 12;

  loading = true;

  items: Observable<utils.contentListItem[]>;
  empty: boolean = true;
  componentRef: ComponentRef<any>;

  constructor(
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.content);
    console.log(this.type);
    this.items = this.content.map((response: any[]) => {
      this.empty = response.length == 0;
      let items;
      items = [].concat(...response).map((el) => this.processItem(el));
      items = this.sortByDate ? utils.sortByDate(items) : items;
      items = items.slice(0, this.limit);
      return items;
    });
    this.createListComponentInstance();
  }
  processItem(rawItem: any) {
    let description = rawItem.description
      ? utils.removeHtmlEntities(rawItem.description)
      : null;

    let image =
      rawItem.headerImage && rawItem.headerImage.length > 0
        ? rawItem.headerImage
        : null;

    return {
      link: utils.getLink(rawItem),
      imageUrl: image,
      title: utils.removeHtmlEntities(rawItem.title),
      type: rawItem.typeNameOverride ?? utils.getTypeName(rawItem.type),
      date: utils.getDate(rawItem),
      description: description,
      kxIcon: rawItem.kxIcon ?? null,
      kxOriginal: rawItem.tags?.includes("*kx-original"),
      display_type: rawItem.display_type ?? null,
    };
  }

  createListComponentInstance() {
    this.items = this.items.pipe(tap(() => (this.loading = false)));
    let componentType: any = utils.getListComponentType(this.type);
    let componentFactory = this.cfr.resolveComponentFactory(componentType);
    if (this.componentRef) this.componentRef.destroy();
    this.componentRef = this.vcr.createComponent(componentFactory);
    this.componentRef.instance.items = this.items;
  }
}
