import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "pano-hotspot-dialog",
  templateUrl: "hotspot-popup.component.html",
  styles: [
    `
      .hotspot-popup {
        position: relative;
        width: min-content;
        padding-right: 8px;
      }
      .hotspot-popup .dialog-close-button {
        position: absolute;
        right: -6px;
        top: -6px;
        padding: 0;
        margin: 0;
      }
      ::ng-deep .hotspot-popup p {
        min-width: 512px;
        margin-bottom: 0;
      }
      ::ng-deep .hotspot-popup iframe {
        width: 800px;
        height: 450px;
        margin-top: 15px !important;
        margin-bottom: 20px !important;
      }
      ::ng-deep
        .hotspot-popup
        img:not(.hotspot-icon-example):not(.zoom-icon-example) {
        width: 533.333px;
        margin-top: 15px !important;
        margin-bottom: 20px !important;
      }
      ::ng-deep .help-dialog .hotspot-icon-example {
        width: 32px;
        display: inline-block;
        filter: drop-shadow(0 0 1px #000000);
        margin: 8px 8px 8px 0;
      }
      ::ng-deep .help-dialog .zoom-icon-example {
        width: 32px;
        display: inline-block;
        border: 1px solid #aaa;
        border-radius: 3px;
        margin-right: 8px;
      }
      ::ng-deep .help-dialog {
        letter-spacing: 0.015em;
      }
    `,
  ],
})
export class PanoHotspotPopupDialog {
  html: SafeHtml;
  constructor(
    public dialogRef: MatDialogRef<PanoHotspotPopupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { html: string },
    public sanitizer: DomSanitizer
  ) {
    this.html = this.sanitizer.bypassSecurityTrustHtml(data.html);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
