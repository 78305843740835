import { Component, OnInit, OnChanges, Input, SimpleChanges, HostBinding, HostListener } from '@angular/core';

const Routes: {name: string, url: string, tag: string}[] = [
	{name: "Bite Site", url: "/bite", tag: "*bite-blog"}
];

@Component({
  selector: 'app-home-button',
  templateUrl: './home-button.component.html',
  styleUrls: ['./home-button.component.scss']
})
export class HomeButtonComponent implements OnInit, OnChanges {
	@Input() tags: string[];
	osuNavHidden: boolean = false;
	homeRouteIndex: number = -1;

  constructor() {}
  ngOnInit(): void {}
	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.tags.previousValue && changes.tags.currentValue) {
			this.homeRouteIndex = Routes.findIndex((route) => this.tags.includes(route.tag));
		}
	}

	get homeRoute() {
		return Routes[this.homeRouteIndex]
	}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		const OSU_NAV_HEIGHT = 50;
		this.osuNavHidden = window.pageYOffset > OSU_NAV_HEIGHT;
	}
	@HostBinding('class.fixed') get className() { return this.osuNavHidden; }
}
