<ng-container [ngSwitch]="sectionData.type">
  <ng-container *ngSwitchCase="types.Carousel">
    <content-list
      [content]="sectionData.observable"
      [type]="sectionData.contentListType"
      [head]="sectionData.title"
    ></content-list>
  </ng-container>

  <!-- ACCORDION QA SECTION START -->
  <ng-container *ngSwitchCase="types.Accordion_QA">
    <accordion-qa
      [entryClicked]="sectionData.entryClicked"
      [content]="sectionData.observable"
      [label]="sectionData.label"
      [labelFontSize]="sectionData.labelFontSize"
      [questionsAndAnswers]="sectionData?.questionsAndAnswers"
      [heroCarousel]="sectionData.heroCarousel"
      [autoExpandFirstEntry]="sectionData.autoExpandFirstEntry"
    ></accordion-qa>
  </ng-container>
  <!-- ACCORDION QA SECTION END -->
  <!-- CONTRIBUTOR SECTION START -->
  <ng-container *ngSwitchCase="types.Contributors">
    <app-contributor-grid-list [contributors]="sectionData.contributors">
    </app-contributor-grid-list>
  </ng-container>
  <!-- CONTRIBUTOR SECTION END -->
  <!-- Variable COLUMN CONTAINER SECTION START -->
  <ng-container *ngSwitchCase="types.Variable_Column_Container">
    <app-variable-column-container
      [columnEntries]="sectionData.columnEntries"
    ></app-variable-column-container>
  </ng-container>
  <!-- Variable COLUMN CONTAINER SECTION END -->
  <!-- NAV WITH IMG CAROUSEL SECTION START -->
  <ng-container *ngSwitchCase="types.Nav_With_Image_Carousel">
    <app-nav-with-image-carousel
      [navLinks]="sectionData.navEntries"
      [imagesWithInfo]="sectionData.imagesWithInfo"
    ></app-nav-with-image-carousel>
  </ng-container>
  <!-- NAV WITH IMG CAROUSEL SECTION END -->
  <!-- TEXT LIST SECTION START -->
  <ng-container *ngSwitchCase="types.Bullet_List">
    <app-text-list
      [listItems]="sectionData.entries"
      [listType]="'bullet'"
    ></app-text-list>
  </ng-container>
  <ng-container *ngSwitchCase="types.Numbered_List">
    <app-text-list
      [listItems]="sectionData.entries"
      [listType]="'number'"
    ></app-text-list>
  </ng-container>
  <!-- TEXT LIST SECTION END -->
  <!-- VISUAL LINK SECTION START -->
  <ng-container *ngSwitchCase="types.Visual_Link">
    <app-visual-link
      [imageUrl]="sectionData.imageUrl"
      [linkUrl]="sectionData.linkUrl"
      [linkText]="sectionData.linkText"
    ></app-visual-link>
  </ng-container>
  <!-- VISUAL LINK SECTION END -->
  <!-- CALLOUT SECTION START -->
  <ng-container *ngSwitchCase="types.Callout">
    <app-tick-callout
      [bodyText]="sectionData.body"
      [headerText]="sectionData.header"
    ></app-tick-callout>
  </ng-container>
  <!-- CALLOUT SECTION END -->
  <!-- TICK QUOTE SECTION START -->
  <ng-container *ngSwitchCase="types.Tick_Quote">
    <app-quote
      [quoteText]="sectionData.quoteText"
      [quoteSource]="sectionData.quoteSource"
      [quoteTitle]="sectionData.quoteTitle"
    ></app-quote>
  </ng-container>
  <!-- TICK QUOTE SECTION END -->

  <!-- NAVIGATION DESTINATION SECTION START -->
  <ng-container *ngSwitchCase="types.Navigation_Destination">
    <div [id]="sectionData.elementId" [ngClass]="{ height: '0px' }"></div>
  </ng-container>
  <!-- NAVIGATION DESTINATION SECTION END -->

  <!-- LIFE CYCLE SECTION START -->
  <ng-container *ngSwitchCase="types.Image_Carousel">
    <app-life-cycle-slider
      [items]="sectionData.imageDescriptions"
    ></app-life-cycle-slider>
  </ng-container>
  <!-- LIFE CYCLE SECTION ENDS -->

  <!-- EXPLORER DASHBOARDS SECTION START -->
  <ng-container *ngSwitchCase="types.Explorer_Dashboards">
    <content-list
      [content]="sectionData.observable"
      [type]="sectionData.contentListType"
      [head]="'Dashboards'"
    ></content-list>
  </ng-container>

  <!-- EXPLORER DASHBOARDS SECTION END -->

  <!-- BODY SECTION START -->
  <ng-container *ngSwitchCase="types.Body">
    <div
      class="body-section"
      [ngClass]="{
        collapsed: sectionData.collapsed && bodySectCollapseToggle,
        highlighted: sectionData.highlighted
      }"
    >
      <h2
        *ngIf="sectionData.title.length > 0"
        [innerHTML]="sectionData.title"
      ></h2>
      <div [innerHTML]="sectionData.html"></div>
      <button
        *ngIf="sectionData.collapsed"
        mat-flat-button
        color="primary"
        (click)="toggleBodySectionCollapse()"
      >
        Show {{ bodySectCollapseToggle ? "More" : "Less" }}
      </button>
    </div>
  </ng-container>
  <!-- BODY SECTION END -->

  <!-- QUOTE SECTION START -->
  <ng-container *ngSwitchCase="types.Quote">
    <div class="quote-section">
      <div class="text" [innerHTML]="sectionData.body"></div>
      <div class="author" [innerHTML]="sectionData.author"></div>
    </div>
  </ng-container>
  <!-- QUOTE SECTION END -->

  <!-- REACTIONS SECTION START -->
  <ng-container *ngSwitchCase="types.Reactions">
    <app-reactions></app-reactions>
  </ng-container>
  <!-- REACTIONS SECTION END -->

  <!-- AUDIO CLIP SECTION START -->
  <ng-container *ngSwitchCase="types.Audio_Clip">
    <div class="audio-clip-section">
      <h3 *ngIf="sectionData.title.length > 0">{{ sectionData.title }}</h3>
      <div class="clip">
        <audio controls>
          <source [src]="sectionData.src" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="toggleAudioTranscriptCollapse()"
      >
        {{ audioTranscriptToggle ? "Show" : "Hide" }} Transcript
      </button>
      <div
        class="transcript"
        *ngIf="!audioTranscriptToggle"
        [innerHTML]="sectionData.transcript"
      ></div>
    </div>
  </ng-container>
  <!-- AUDIO CLIP SECTION END -->

  <!-- IFRAME SECTION START -->
  <ng-container *ngSwitchCase="types.Iframe">
    <h3 *ngIf="sectionData.title.length > 0">{{ sectionData.title }}</h3>
    <iframe
      [height]="sectionData.height"
      [src]="cleanIframeUrl"
      class="cp-iframe {{ sectionData.width }}"
    ></iframe>
  </ng-container>
  <!-- IFRAME SECTION END -->

  <!-- SEARCHBAR SECTION START -->
  <ng-container *ngSwitchCase="types.Searchbar">
    <app-searchbar></app-searchbar>
  </ng-container>
  <!-- SEARCHBAR SECTION END -->

  <!-- CONTRIBUTOR HIGHLIGHT SECTION START -->
  <ng-container *ngSwitchCase="types.Contributor_Highlight">
    <a [href]="getPersonLink(sectionData.person)" class="contributor-highlight">
      <h3>{{ sectionData.person.fullname }}</h3>
      <div [innerHTML]="sectionData.text"></div>
    </a>
  </ng-container>
  <!-- CONTRIBUTOR HIGHLIGHT SECTION END -->

  <!-- VIDEO SECTION START -->
  <ng-container *ngSwitchCase="types.Video">
    <div class="cp-video-container">
      <iframe
        class="video"
        [src]="sectionData.url"
        frameborder="0"
        allow="accelerometer; autoplay=0; rel=0; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </div>
  </ng-container>
  <!-- VIDEO SECTION END -->

  <!-- VISUAL LINKS START -->
  <ng-container *ngSwitchCase="types.Visual_Links">
    <div class="visual-links">
      <a
        class="link"
        [href]="l.url"
        *ngFor="let l of sectionData.links"
        [ngStyle]="getVisualLinkStyle(l)"
      >
        {{ l.text }}
      </a>
    </div>
  </ng-container>
  <!-- VISUAL LINKS END -->

  <!-- COLUMN SECTION START -->
  <ng-container *ngSwitchCase="types.Column">
    <app-custom-page-section
      *ngFor="let c of sectionData.sections; first as left"
      class="column"
      [ngStyle]="
        getColumnStyle(
          left,
          sectionData.leftWidth,
          sectionData.leftOffset,
          sectionData.rightOffset
        )
      "
      [sectionData]="c"
    ></app-custom-page-section>
  </ng-container>
  <!-- COLUMN SECTION END -->

  <!-- TABS SECTION START -->
  <ng-container *ngSwitchCase="types.Tabs">
    <h3
      class="tab-title"
      *ngIf="sectionData.title.length > 0"
      [innerHTML]="sectionData.title"
    ></h3>
    <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="tabindex">
      <mat-tab *ngFor="let t of sectionData?.tabs">
        <ng-template mat-tab-label>
          <img [src]="t?.icon" [alt]="t?.name" *ngIf="t?.icon.length > 0" />
          <span [innerHTML]="t?.name"></span>
        </ng-template>
        <app-custom-page-section
          *ngFor="let c of t?.sections"
          [sectionData]="c"
        ></app-custom-page-section>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <!-- TABS SECTION END -->

  <!-- CARDS SECTION START -->
  <ng-container *ngSwitchCase="types.Cards">
    <h3 class="kx-cards-title" *ngIf="sectionData.title.length > 0">
      <span [innerHTML]="sectionData.title"></span>
    </h3>
    <div class="kx-cards">
      <ng-container *ngFor="let card of sectionData.cards; index as i">
        <div
          class="kx-card"
          [style.--featured-image]="getCssUrlVar(card.image)"
          [ngClass]="{
            active: activeCard == i,
            noActiveCards: activeCard == null
          }"
          (click)="activeCard != i ? (activeCard = i) : (activeCard = null)"
        >
          <h3 [innerHTML]="card.title"></h3>
          <h4
            *ngIf="card.subtitle && card.subtitle.length > 0"
            [innerHTML]="card.subtitle"
          ></h4>
        </div>
        <div
          class="kx-card-body body-{{ i }}"
          [ngClass]="{ active: activeCard == i }"
        >
          <app-custom-page-section
            *ngFor="let s of card?.sections"
            [sectionData]="s"
          ></app-custom-page-section>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <!-- CARDS SECTION END -->

  <!-- RELATED CONTENT SECTION START -->
  <ng-container *ngSwitchCase="types.Related_Content">
    <app-related-content
      [source]="sectionData.source.toLowerCase()"
      [parentTitle]="sectionData.parentTitle"
      [tags]="sectionData.tags"
    ></app-related-content>
  </ng-container>
  <!-- RELATED CONTENT SECTION END -->

  <!-- INFOGRAPHIC SECTION START -->
  <ng-container *ngSwitchCase="types.Infographic">
    <h3
      class="tab-title"
      *ngIf="sectionData.title.length > 0"
      [innerHTML]="sectionData.title"
    ></h3>
    <app-image-map-infographic
      [id]="sectionData.id"
      displayType="modal"
    ></app-image-map-infographic>
  </ng-container>
  <!-- INFOGRAPHIC SECTION SECTION END -->

  <!-- IMAGE SLIDER SECTION START -->
  <ng-container *ngSwitchCase="types.Image_Slider">
    <swiper
      (swiper)="onSwiper($event)"
      class="custom-page-image-slider full-width"
      [navigation]="true"
    >
      <ng-template swiperSlide *ngFor="let item of sectionData.items">
        <div class="image-slider-slide">
          <img
            *ngIf="item.type == 'image'"
            [src]="item.src"
            [alt]="item.caption"
          />
          <video *ngIf="item.type == 'video'" [src]="item.src" controls></video>
          <p *ngIf="item.caption.length > 0" [innerHTML]="item.caption"></p>
        </div>
      </ng-template>
    </swiper>
    <div class="pagination primary">
      <div
        *ngFor="let x of sectionData.images; index as i"
        class="indicator"
        [ngClass]="{ on: i == swiper?.activeIndex }"
        (click)="navigateTo(i)"
      ></div>
    </div>
  </ng-container>
  <!-- IMAGE SLIDER SECTION END -->

  <p *ngSwitchDefault>Invalid Section!</p>
</ng-container>
