import { Observable } from "rxjs/Rx";
import { ApiService } from "src/app/services/api.service";

export enum UserType {
  OSUInternal,
  External,
}
export class Person {
  fullname: string;
  jobTitle: string;
  type: UserType;
  id: string;

  //optional
  email: string;
  phone: string;
  city: string;
  college: string;
  university: string;
  enabled: Boolean = true;
  //osu-only informtion
  buildingURL: string;
  office: string;
  //from drupal
  photoURL: string;
  description: string;
  link: string;
  address: any;

  constructor(type: UserType, id: string) {
    this.type = type;
    this.id = id;
  }

  public populateOSUData(fpDirectoryAPIResonse: Observable<any>) {
    fpDirectoryAPIResonse.subscribe((result: any[]) => {
      if (result.length > 0) {
        //fullname
        this.fullname = result[0].display_name;

        //email
        this.email = result[0].email;

        //phone
        if (result[0].phone.formatted) this.phone = result[0].phone.formatted;

        //jobTitle
        try {
          this.jobTitle =
            result[0].appointments[0].working_title
        } catch {
          // if no job title, just use affiliations
          this.jobTitle = "";
          result[0].affiliations.forEach((element) => {
            this.jobTitle += element + ", ";
          });
          if (this.jobTitle.length > 2)
            this.jobTitle = this.jobTitle.substring(
              0,
              this.jobTitle.length - 2
            );
        }

        //college
        try {
          this.college = result[0].appointments[0].vp_college_name;
        } catch {}

        //buildingURL
        try {
          this.buildingURL = result[0].address.building.url;
        } catch {}

        //buildingURL
        try {
          this.address = result[0].address;
        } catch {}

        //office
        try {
          this.office =
            result[0].address.room_number +
            " " +
            result[0].address.building.name;
        } catch {}

        //city
        try {
          this.city = result[0].address.city + ", " + result[0].address.state;
        } catch {}

        //uni
        this.university = "The Ohio State University";
      }
    });
    return fpDirectoryAPIResonse.toPromise();
  }

  public populateDrupalUserData(APIResponse: Observable<any>) {
    APIResponse.subscribe((result) => {
      if (result.length > 0) {
        if (result[0].field_college.length > 0)
          this.college = result[0].field_college;
        if (result[0].field_email_address.length > 0)
          this.email = result[0].field_email_address;
        if (result[0].field_full_name.length > 0)
          this.fullname = result[0].field_full_name;
        if (result[0].field_other_profile_website.length > 0)
          this.link = result[0].field_other_profile_website;
        if (result[0].field_phone_number.length > 0)
          this.phone = result[0].field_phone_number;
        if (result[0].user_picture.length > 0)
          this.photoURL = result[0].user_picture;
        if (result[0].field_title.length > 0)
          this.jobTitle = result[0].field_title;
        if (result[0].field_university.length > 0)
          this.university = result[0].field_university;
        if (result[0].field_description.length > 0)
          this.description = result[0].field_description;
        this.enabled = result[0].field_enabled;
      }
      if (!this.photoURL) this.photoURL = "";
    });
    return APIResponse.toPromise();
  }
  public equals(obj: Object) {
    if (!(obj instanceof Person)) return false;
    return obj.id == this.id;
  }

  public async setup(api: ApiService) {
    if (this.type == UserType.OSUInternal) {
      await this.populateOSUData(api.getPersonInfoFromOSUDirectory(this.id));
      await this.populateDrupalUserData(api.getDrupalPerson(this.id));
    } else {
      await this.populateDrupalUserData(api.getDrupalPerson(this.id));
    }
    return this;
  }

  public formatForContentList() {
    return {
      title: this.fullname,
      type: this.jobTitle,
      date: new Date(),
      headerImage: this.photoURL,
      link:
        "/directory/" +
        (this.type == UserType.OSUInternal ? "" : "external/") +
        this.id,
    };
  }
}
