import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-department-key-popup",
  templateUrl: "./department-key-popup.component.html",
  styleUrls: ["./department-key-popup.component.scss"],
})
export class DepartmentKeyPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DepartmentKeyPopupComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
