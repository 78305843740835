import {
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import Swiper from "swiper";
import { contentListItem } from "../util";

const BREAKPOINTS = [
  { sliderSize: 3, minWidth: 900, maxWidth: 999999 },
  { sliderSize: 1, minWidth: 0, maxWidth: 900 },
];

@Component({
  selector: "app-icon-slider",
  templateUrl: "./icon-slider.component.html",
  styleUrls: ["./icon-slider.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class IconSliderComponent {
  @Input() items: Observable<contentListItem[]>;
  swiper: Swiper;

  constructor(private cdr: ChangeDetectorRef) {}

  slidesPerView() {
    let elWidth = this.swiper?.$el[0].clientWidth ?? 0;

    return BREAKPOINTS.find(
      (bkpt) => bkpt.minWidth <= elWidth && bkpt.maxWidth > elWidth
    ).sliderSize;
  }
  onSwiper(swiper: Swiper) {
    console.log(this.items);
    this.swiper = swiper;
    this.swiper.onAny(() => this.cdr.detectChanges());
  }
  navigateTo(i: number) {
    this.swiper.slideTo(i);
  }
  getImgStyle(url) {
    return url?.length > 0
      ? getBackgroundImageStyles(0, url)
      : { background: "#ddd" };
  }
}
