import { PlotPolygonLastPriceOptions } from "highcharts";
import { DiagnosticCategory } from "typescript";
import { formatType } from "./widget/widget";

export enum TYPES {
  VALUE = "value",
  BAR_GRAPH = "bar_graph",
  PIE_CHART = "pie_chart",
  LINE_CHART = "line_chart",
  MULTI_VAR_BAR_GRAPH = "multi_var_bar_graph",
  WIDGET_TOGGLER = "widget_toggler",
}

export type BREADCRUMBS = {
  type: "commodity" | "category";
  sector?: string;
  group?: string;
  commodity?: string;
  bucket?: string;
  category?: string;
};

export interface BUCKET {
  categories: CATEGORY[];
  name: string;
}
export interface CATEGORY {
  widgets: {
    valueWidgets: IValueWidget[];
    barGraphWidgets: IBarGraphWidget[];
    pieChartWidgets: IPieChartWidget[];
    lineChartWidgets: ILineChartWidget[];
    multiVarBarGraphWidgets: IMultiVarBarGraphWidget[];
    widgetTogglerWidgets: IWidgetToggler[];
  };
  mapData: { fips: string; value: number }[];
  mapVarFormat: formatType;
  bucketID: string;
  mapLabel: string;
  mapVarID: string;
  name: string;
  scope: string;
  published?: boolean;
  __v: number;
  _id: string;
}

export interface SECTOR {
  name: string;
  groups: GROUP[];
}

export interface GROUP {
  name: string;
  commodities: COMMODITY[];
}

export interface COMMODITY {
  _id: string;
  name: string;
  mapData: { fips: string; value: number }[];
  mapVarFormat: formatType;
  mapLabel: string;
  mapVarID: string;
  scope: string;
  published: boolean;
  widgets: {
    valueWidgets: IValueWidget[];
    barGraphWidgets: IBarGraphWidget[];
    lineChartWidgets: ILineChartWidget[];
    multiVarBarGraphWidgets: IMultiVarBarGraphWidget[];
    pieChartWidgets: IPieChartWidget[];
    widgetTogglerWidgets: IWidgetToggler[];
  };
}
export interface IVariable {
  _id: string;
  name: string;
  formatter: formatType;
  scope: "counties";
  data: any;
  __v: number;
}

export interface IValueWidget {
  category: string;
  dataLevel: string;
  label: string;
  valueVarID: string;
  excludeScope: string[];
  published?: boolean;
  __v: number;
  _id: string;
}

export interface Bars {
  label: string;
  valueVarID: string;
}

export interface IBarGraphWidget {
  bars: Bars[];
  category: string;
  dataLevel: string;
  label: string;
  excludeScope: string[];
  published?: boolean;
  __v: number;
  _id: string;
}

export interface Slice {
  label: string;
  valueVarID: string;
  order: number;
}

export interface IPieChartWidget {
  slices: Slice[];
  category: string;
  dataLevel: string;
  label: string;
  excludeScope: string[];
  published: boolean;
  __v: number;
  _id: string;
}

export interface Year {
  year: number;
  valueVarID: string;
}

export interface ILineChartWidget {
  years: Year[];
  category: string;
  dataLevel: string;
  label: string;
  subLabel: string;
  initialActiveScopes?: string[];
  excludeScope: string[];
  published?: boolean;
  __v: number;
  _id: string;
}

export interface IMultiVarBarGraphWidget {
  label: string;
  dataLevel: string;
  category: string;
  excludeScope: string[];
  labels: string[];
  groups: VariableGroup[];
  published?: boolean;
  initialActiveScopes?: string[];
  __v: number;
  _id: string;
}
export type VariableGroup = {
  groupLabel: string;
  valueVarIDs: string[];
};

export interface WidgetRef {
  label: string;
  widgetID: string;
}

export interface IWidgetToggler {
  label: string;
  dataLevel: string;
  category: string;
  type: "Bar" | "Line";
  widgets: WidgetRef[];
  excludeScope: string[];
  __v: number;
  _id: string;
}

export interface ExploreAddItemInput {
  type: exploreItemType;
  widget?: ItemWidget;
  plot?: PlotlyPlot;
}

export interface PlotlyPlot {
  data: any;
  layout: any;
}
export interface ItemWidget {
  widgetType: TYPES;
  scope: SCOPE;
  nassItem: boolean;
  widgetData:
    | IValueWidget
    | IBarGraphWidget
    | IPieChartWidget
    | ILineChartWidget;
}

export enum exploreItemType {
  header = "header",
  body = "body",
  widget = "widget",
  efields = "efields",
}

export enum SCOPE {
  Gallia = "Gallia",
  Huron = "Huron",
  Athens = "Athens",
  Adams = "Adams",
  Medina = "Medina",
  Union = "Union",
  Champaign = "Champaign",
  Paulding = "Paulding",
  Greene = "Greene",
  Franklin = "Franklin",
  Marion = "Marion",
  Morgan = "Morgan",
  Wayne = "Wayne",
  Wyandot = "Wyandot",
  Butler = "Butler",
  Harrison = "Harrison",
  Knox = "Knox",
  Richland = "Richland",
  Vinton = "Vinton",
  Hancock = "Hancock",
  Fairfield = "Fairfield",
  Defiance = "Defiance",
  Henry = "Henry",
  Jefferson = "Jefferson",
  Putnam = "Putnam",
  Clinton = "Clinton",
  Fayette = "Fayette",
  Pickaway = "Pickaway",
  Logan = "Logan",
  Summit = "Summit",
  Sandusky = "Sandusky",
  Shelby = "Shelby",
  "Van Wert" = "Van Wert",
  Washington = "Washington",
  Williams = "Williams",
  Auglaize = "Auglaize",
  Columbiana = "Columbiana",
  Darke = "Darke",
  Ottawa = "Ottawa",
  Fulton = "Fulton",
  Hardin = "Hardin",
  Jackson = "Jackson",
  Lucas = "Lucas",
  Morrow = "Morrow",
  Noble = "Noble",
  Preble = "Preble",
  Seneca = "Seneca",
  Stark = "Stark",
  Allen = "Allen",
  Coshocton = "Coshocton",
  Crawford = "Crawford",
  Delaware = "Delaware",
  Guernsey = "Guernsey",
  Hocking = "Hocking",
  Mercer = "Mercer",
  Ross = "Ross",
  Cuyahoga = "Cuyahoga",
  Lorain = "Lorain",
  Miami = "Miami",
  Montgomery = "Montgomery",
  Trumbull = "Trumbull",
  Wood = "Wood",
  Ashtabula = "Ashtabula",
  Madison = "Madison",
  Muskingum = "Muskingum",
  Geauga = "Geauga",
  Monroe = "Monroe",
  Lake = "Lake",
  Clark = "Clark",
  Holmes = "Holmes",
  Warren = "Warren",
  Ashland = "Ashland",
  Mahoning = "Mahoning",
  Scioto = "Scioto",
  Meigs = "Meigs",
  Pike = "Pike",
  Hamilton = "Hamilton",
  Erie = "Erie",
  Lawrence = "Lawrence",
  Tuscarawas = "Tuscarawas",
  Brown = "Brown",
  Licking = "Licking",
  Carroll = "Carroll",
  Clermont = "Clermont",
  Highland = "Highland",
  Portage = "Portage",
  Belmont = "Belmont",
  Perry = "Perry",
  Total = "Total",
}

export interface navState {
  scope: SCOPE;
  selectedCategory: CATEGORY | COMMODITY;
}

export interface QuickStatsQuery {
  sector_desc: string;
  group_desc: string;
  commodity_desc: string;
  year: string;
  short_desc: string;
}

export interface Variable {
  _id: string;
  name: string;
  domainCategoryDescription: string;
  statisticCategoryDescription: string;
  unitDescription: string;
  commodityID: string;
  formatter: string;
  scope: string;
  year: string;
  data: object;
  __v: number;
}
export interface Commodity {
  _id: string;
  name: string;
  groupDescriptionID: string;
  scope: string;
  __v: number;
  published: boolean;
  mapLabel: string;
  mapVarID: string;
}

export interface Group {
  _id: string;
  name: string;
  sectorDescriptionID: string;
  __v: number;
}

export interface Sector {
  _id: string;
  name: string;
  __v: number;
}

export interface nassVariableAncestors {
  variable: Variable;
  commodity: Commodity;
  group: Group;
  sector: Sector;
}

export interface nassCommodityAncestors {
  commodity: Commodity;
  group: Group;
  sector: Sector;
}
