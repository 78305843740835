import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToSpace'
})
export class CamelCaseToSpacePipe implements PipeTransform {

  transform(value: any): any {
		if (!isNaN(value)) return value;
		return value.split(/(?=[A-Z])/).join(' ');
  }

}
