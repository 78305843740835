import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-visual-link',
  templateUrl: './visual-link.component.html',
  styleUrls: ['./visual-link.component.scss']
})
export class VisualLinkComponent implements OnInit {
	@Input() imageUrl: string;
	@Input() linkUrl: string;
	@Input() linkText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
