import { NavbarRouteList } from "./types";

export const PROD_LINKS: NavbarRouteList = [
  { name: "Home", route: "/" },
  {
    name: "About",
    dropdownMenu: true,
    routes: [
      { route: "/about", name: "About Us" },
      { route: "/directory", name: "Our People" },
      { route: "/contact-us", name: "Contact Us" },
    ],
  },
  { name: "Impacts", route: "/impacts" },
  { customNavItem: true, customNavItemId: "explore" },
  {
    name: "Tools",
    routes: [
      {
        route: `external/${encodeURIComponent(
          "https://experience.arcgis.com/experience/452a09a2c314415fa1bc9d8ec71b05d1/"
        )}`,
        name: "4-H Data Dashboard",
      },
      { route: "/landing-page/profiles", name: "Community Profiles" },
      { route: "/ffmpi/corn-silage", name: "Corn Silage Pricing" },
      // { route: "/data-catalog", name: "Data Catalog" },
      { route: "/expert-directory", name: "Expert Directory" },
      { route: "/food-system-tool", name: "Food System Map" },
      { route: "/landing-page/knowledge-explorer", name: "Knowledge Explorer" },
      {
        name: "Precision Ag",
        routes: [
          { route: "/efields", name: "eFields" },
          { route: "/ebarns", name: "eBarns" },
        ],
        dropdownMenu: true,
      },
      { route: "/landing-page/recipes", name: "RECIPES" },
    ],
    dropdownMenu: true,
  },
  {
    name: `<i class="fa fa-search"></i>`,
    route: "/search",
    label: "search",
  },
];
export const DEV_LINKS: NavbarRouteList = [
  { name: "Home", route: "/" },
  {
    name: "About",
    dropdownMenu: true,
    routes: [
      { route: "/about", name: "About Us" },
      { route: "/directory", name: "Our People" },
      { route: "/contact-us", name: "Contact Us" },
    ],
  },
  { name: "Impacts", route: "/impacts" },
  { customNavItem: true, customNavItemId: "explore" },
  {
    name: "Tools",
    routes: [
      {
        route: `external/${encodeURIComponent(
          "https://experience.arcgis.com/experience/452a09a2c314415fa1bc9d8ec71b05d1/"
        )}`,
        name: "4-H Data Dashboard",
      },
      { route: "/landing-page/profiles", name: "Community Profiles" },
      { route: "/ffmpi/corn-silage", name: "Corn Silage Pricing" },
      { route: "/data-catalog", name: "Data Catalog" },
      { route: "/expert-directory", name: "Expert Directory" },
      { route: "/food-system-tool", name: "Food System Map" },
      { route: "/landing-page/knowledge-explorer", name: "Knowledge Explorer" },
      {
        name: "Precision Ag",
        routes: [
          { route: "/efields", name: "eFields" },
          { route: "/ebarns", name: "eBarns" },
        ],
        dropdownMenu: true,
      },
      { route: "/landing-page/recipes", name: "RECIPES" },
    ],
    dropdownMenu: true,
  },
  {
    name: `<i class="fa fa-search"></i>`,
    route: "/search",
    label: "search",
  },
];

export const ROUTE_CONDITIONAL_LINKS: NavbarRouteList = [
  { customNavItem: true, customNavItemId: "explorer-cart" },
];
