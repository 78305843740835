<div>
  The College of Food, Agricultural, and Environmental Sciences includes
  departments that represent a broad range of disciplines.
</div>
<br>
<ul>
  <li>
    Department of Agricultural Communication, Education, and Leadership (ACEL)
  </li>
  <li>
    Department of Agricultural, Environmental, and Development Economics (AEDE)
  </li>
  <li>Department of Animal Sciences (AnSci)</li>
  <li>Department of Entomology (ENT)</li>
  <li>Department of Extension</li>
  <li>Department of Food Science and Technology (FST)</li>
  <li>Department of Food, Agricultural and Biological Engineering (FABE)</li>
  <li>Department of Horticulture and Crop Science (HCS)</li>
  <li>Department of Plant Pathology</li>
  <li>School of Environment and Natural Resources (SENR)</li>
  <li>Ohio State Agricultural Technical Institute (ATI)</li>
</ul>
