<ng-container *ngIf="questionsAndAnswers?.length > 0">
	<h2 class="label" [ngStyle]="labelFontSize && {'font-size': labelFontSize + 'pt'}" [innerHTML]="label"></h2>
  <mat-accordion #accordion="matAccordion" togglePosition="before">
    <mat-expansion-panel *ngFor="let qa of questionsAndAnswers; index as i" #panel class="question-panel" (afterExpand)="scrollToTop(i)">
      <mat-expansion-panel-header>
        <mat-panel-title>
					<span [ngStyle]="qa.questionFontSize && {'font-size': qa.questionFontSize + 'pt'}">{{ qa.question }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="answer" *ngFor="let answer of qa.answers">
        <app-custom-page-section
          [sectionData]="answer"
        ></app-custom-page-section>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

