import { Environment } from './env';

/**
 * Production environment. 
 * Use this by building with the command: 
 *    'ng build --configuration=production' or 'ng b --prod'
 */
export const environment : Environment = {
  production: true,
  name: "PRODUCTION",
  baseHref: '/',
  enableAuth: false,
  envFilter: "prod",
  censusKey: "d9842d679b3e64d0f91ced27cdef9af67c86bf52"
};
