import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable, from, forkJoin } from "rxjs";
import { SearchService } from "src/app/services/search.service";
import { TYPE } from "../search/etc/type";
import { SearchOptions } from "../search/etc/options";
import { ApiService } from "src/app/services/api.service";
import dateFormat from "dateformat";
import { SearchFilters } from "../search/etc/options";
import { TitleService } from "src/app/services/title.service";
const PAGEVIEW_DISCREPENCY = 20517;
@Component({
  selector: "app-impacts",
  templateUrl: "./impacts.component.html",
  styleUrls: ["./impacts.component.scss"],
})
export class ImpactsComponent implements OnInit, AfterViewInit {
  stats: {
    icon: string;
    type: string;
    count: Observable<any>;
    link: string;
  }[] = [];
  intro;
  impactStories;
  quotes: Observable<
    {
      title: string;

      author: string;

      image: string;

      author_info: string;

      quote: string;
    }[]
  >;
  pageviews;
  date;
  swiper;

  constructor(
    private cdr: ChangeDetectorRef,
    private _search: SearchService,
    private _apiService: ApiService,
    private _titleService: TitleService
  ) {
    this._titleService.setPageTitle("Impacts");
    this.setUpStatsArr();
    this.impactStories = this._apiService.getDrupalContent(
      "?_format=json&tags%5B%5D=1445"
    );
    this.intro = this._apiService.getImpactIntroCopy().map((res: any) => {
      console.log(res);
      return res[0].html;
    });
    this.pageviews = this._apiService.getAnalyticsStats().map((res: any) => {
      console.log(res);
      return Number(res.screenPageViews) + PAGEVIEW_DISCREPENCY;
    });
    this.date = dateFormat(new Date(), "shortDate");
    this.quotes = this._apiService.getImpactQuotes();
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
  openContactUs() {
    document.getElementById("contact-us-overlay").click();
  }

  onSwiper(swiper) {
    console.log(this);
    this.swiper = swiper;
    this.swiper.onAny(() => this.cdr.detectChanges());
  }

  navigateTo(i: number) {
    this.swiper.slideTo(i);
  }

  setUpStatsArr() {
    let basicOptions = new SearchOptions();

    let datasetCount = forkJoin([
      this._search.getResultCountForAFilterValue(
        { type: <TYPE>"Dataset" },
        new SearchOptions({ publishedOnly: false })
      ),
      this._apiService
        .getCategories()
        .map((cptCats: any[]) => cptCats.filter((cat) => cat.published).length),
      this._apiService.getNassTaxonomy().map((nassTax) => {
        const allCommodities = Object.values(nassTax)
          .map((groups) => Object.values(groups))
          .reduce((acc, curr) => acc.concat(curr), [])
          .map((commodities) => commodities)
          .reduce((acc, curr) => acc.concat(curr), []);
        return allCommodities.filter((commodity) => commodity.published).length;
      }),
    ]).map(
      ([drupalDatasets, cptCats, nassCats]) =>
        drupalDatasets + cptCats + nassCats
    );
    let storyCount = forkJoin([
      this._search.getResultCountForAFilterValue(
        { type: TYPE.STORY },
        basicOptions
      ),
      this._search.getResultCountForAFilterValue(
        {},
        new SearchOptions({
          filters: new SearchFilters({ tags: new Set(["*story"]) }),
        })
      ),
    ]).map(([structuredStories, contentTaggedWithStoryTag]) => {
      // console.log("stories", structuredStories, contentTaggedWithStoryTag);
      return structuredStories + contentTaggedWithStoryTag;
    });
    this.stats = [
      {
        icon: "https://contentfilehosting.s3.amazonaws.com/Asset%2011674675395718.svg",
        type: "Stories",
        count: storyCount,
        link: "/search?types=Structured_story",
      },
      {
        icon: "https://contentfilehosting.s3.amazonaws.com/Asset%202.svg",
        type: "Videos",
        count: this._search.getResultCountForAFilterValue(
          { type: TYPE.VIDEO },
          basicOptions
        ),
        link: "/search?types=Video",
      },
      {
        icon: "https://contentfilehosting.s3.amazonaws.com/Asset%203.svg",
        type: "Podcasts",
        count: this._search.getResultCountForAFilterValue(
          { type: TYPE.PODCAST },
          basicOptions
        ),
        link: "/podcast",
      },
      {
        icon: "https://contentfilehosting.s3.amazonaws.com/Asset%204.svg",
        type: "Questions",
        count: this._search.getResultCountForAFilterValue(
          { type: TYPE.QUESTION },
          basicOptions
        ),
        link: "/search?types=Question",
      },
      {
        icon: "https://contentfilehosting.s3.amazonaws.com/Asset%205.svg",
        type: "Tools",
        count: from([9]),
        link: "/page/data-and-tools",
      },
      {
        icon: "https://contentfilehosting.s3.amazonaws.com/Asset%205.svg",
        type: "Datasets",
        count: datasetCount,
        link: "/page/data-and-tools",
      },
    ];
  }
}
