<div class="heading">
  <h1>Data Catalog</h1>
</div>

<div class="grid-container" *ngIf="doneLoading | async">
  <div class="search">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="Data Search"
        [(ngModel)]="searchString"
        (keyup)="search()"
      />
      <button
        mat-button
        *ngIf="searchString"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchString = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="filter">
    <div class="clear" (click)="clearTableFilters()">Clear All Filters</div>
    <mat-accordion
      *ngIf="tableFilterOptions && newMatDataSource"
      [multi]="true"
    >
      <mat-expansion-panel [expanded]="subjectFilterParamExists">
        <mat-expansion-panel-header>
          <mat-panel-title> Subject </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let subject of tableFilterOptions.Subject"
            (click)="
              addTableFilter(
                $event,
                { column: 'Campaign', value: subject.Name },
                true
              )
            "
            [expanded]="
              checkTableFilterExists({
                column: 'Campaign',
                value: subject.Name
              })
            "
            [hideToggle]="true"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                [ngClass]="
                  checkTableFilterExists({
                    column: 'Campaign',
                    value: subject.Name
                  })
                    ? 'selected'
                    : 'not-selected'
                "
              >
                {{ subject.Name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              class="second-level-filter"
              *ngFor="let topic of subject.Topics"
              (click)="
                addTableFilter(
                  $event,
                  {
                    column: 'Campaign_Domain',
                    value: topic,
                    parent: 'Campaign'
                  },
                  false
                )
              "
              [ngClass]="
                checkTableFilterExists({
                  column: 'Campaign_Domain',
                  value: topic
                })
                  ? 'selected'
                  : 'not-selected'
              "
            >
              {{ topic }}
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Geographic Level </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let geoLevel of tableFilterOptions.Geographic_Level"
            (click)="
              addTableFilter(
                $event,
                { column: 'Geographic_Level', value: geoLevel.Name },
                true
              )
            "
            [expanded]="
              checkTableFilterExists({
                column: 'Geographic_Level',
                value: geoLevel.Name
              })
            "
            [hideToggle]="true"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                [ngClass]="
                  checkTableFilterExists({
                    column: 'Geographic_Level',
                    value: geoLevel.Name
                  })
                    ? 'selected'
                    : 'not-selected'
                "
              >
                {{ geoLevel.Name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              class="second-level-filter"
              *ngFor="let geoExtent of geoLevel.Geographic_Extent"
              (click)="
                addTableFilter(
                  $event,
                  {
                    column: 'Geographic_Extent',
                    value: geoExtent,
                    parent: 'Geographic_Level'
                  },
                  false
                )
              "
              [ngClass]="
                checkTableFilterExists({
                  column: 'Geographic_Extent',
                  value: geoExtent
                })
                  ? 'selected'
                  : 'not-selected'
              "
            >
              {{ geoExtent }}
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Status </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          *ngFor="let status of tableFilterOptions.Status"
          (click)="
            addTableFilter($event, { column: 'Status', value: status }, false)
          "
          [ngClass]="
            checkTableFilterExists({ column: 'Status', value: status })
              ? 'selected'
              : 'not-selected'
          "
        >
          {{ status }}
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>



	<!-- TABLE --> 

  <div class="mat-elevation-z8 table">
    <table mat-table matSort  [dataSource]="newMatDataSource">

      <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let dataSet">{{ dataSet.Title }}</td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let dataSet">{{ dataSet.Status }}</td>
      </ng-container>

      <ng-container matColumnDef="lastUpdated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
        <td mat-cell *matCellDef="let dataSet">{{ dataSet.Edited }}</td>
      </ng-container>

      <ng-container matColumnDef="Campaign">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
					Tool
				</th>
        <td class ="tool"(click)="goToTool(dataSet.Campaign)" mat-cell *matCellDef="let dataSet">{{ dataSet.Campaign }}</td>
      </ng-container>


      <ng-container matColumnDef="Hosted_Source">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>View Data</th>
        <td mat-cell *matCellDef="let dataSet">
					<div class="link"
						(click)="goToHostedSource($event, dataSet.Hosted_Source)">
					<i class="fas fa-external-link-alt" ></i>
					</div>
        </td>
      </ng-container>
			

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="goToMetaData(row)"
			>
			test
				<app-data-item>
				</app-data-item>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>






</div>
