import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-bucket-preview",
  templateUrl: "./bucket-preview.component.html",
  styleUrls: ["./bucket-preview.component.scss"],
})
export class BucketPreviewComponent implements OnInit {
  @Input() name: string;
  @Input() observable: Observable<any[]>;
  @Input() description: string;
  constructor(private _api: ApiService) {}

  ngOnInit() {
    this.observable = this._api.getDrupalNewestContentByBucket(this.name);
    this._api
      .getDrupalTopicsByBucket(this.name)
      .subscribe((bucket: any[]) => (this.description = bucket[0].description));
  }
}
