import { Component, OnInit, AfterViewInit } from "@angular/core";
import { TitleService } from "src/app/services/title.service";
@Component({
  selector: "app-scroll-story",
  templateUrl: "./scroll-story.component.html",
  styleUrls: ["./scroll-story.component.scss"],
})
export class ScrollStoryComponent implements OnInit, AfterViewInit {
  s1Data = [
    {
      id: 1,
      imageLink: "assets/img/water/pt-src.svg",
      scenario: "No point source discharge",
      tp: "0.37",
      drp: "0.08",
      description:
        "Point source discharge management involves managing the point source discharge. This scenario calculates the result of 100% point source removal. So, can phosphorus targets be reached by point source management alone? No. Removing point sources entirely from the watershed reduced phosphorus loads, but did not achieve targets. Further, this is not a practical strategy.",
    },
    {
      id: 2,
      imageLink: "assets/img/water/blank.svg",
      scenario: "No manure application",
      tp: "0.35",
      drp: "0.08",
      description:
        "Point source discharge management involves managing the point source discharge. This scenario calculates the result of 100% point source removal. So, can phosphorus targets be reached by point source management alone? No. Removing point sources entirely from the watershed reduced phosphorus loads, but did not achieve targets. Further, this is not a practical strategy.",
    },
    {
      id: 3,
      imageLink: "assets/img/water/blank.svg",
      scenario: "25% P-rate reduction",
      tp: "0.35",
      drp: "0.07",
      description:
        "Point source discharge management involves managing the point source discharge. This scenario calculates the result of 100% point source removal. So, can phosphorus targets be reached by point source management alone? No. Removing point sources entirely from the watershed reduced phosphorus loads, but did not achieve targets. Further, this is not a practical strategy.",
    },
    {
      id: 4,
      imageLink: "assets/img/water/blank.svg",
      scenario: "Broadcast P",
      tp: "0.41",
      drp: "0.09",
      description:
        "Point source discharge management involves managing the point source discharge. This scenario calculates the result of 100% point source removal. So, can phosphorus targets be reached by point source management alone? No. Removing point sources entirely from the watershed reduced phosphorus loads, but did not achieve targets. Further, this is not a practical strategy.",
    },
    {
      id: 5,
      imageLink: "assets/img/water/blank.svg",
      scenario: "Broadcast and incorporated P",
      tp: "0.38",
      drp: "0.08",
      description: "",
    },
    {
      id: 6,
      imageLink: "assets/img/water/subsurface.svg",
      scenario: "Subsurface applied P",
      tp: "0.31",
      drp: "0.06",
      description: "",
    },
    {
      id: 7,
      imageLink: "assets/img/water/fa-manure.svg",
      scenario: "Fall manure",
      tp: "0.38",
      drp: "0.08",
      description: "",
    },
    {
      id: 8,
      imageLink: "assets/img/water/sp-manure.svg",
      scenario: "Spring manure",
      tp: "0.38",
      drp: "0.08",
      description: "",
    },
    {
      id: 9,
      imageLink: "assets/img/water/manure.svg",
      scenario: "Fall and Spring manure",
      tp: "0.38",
      drp: "0.08",
      description: "",
    },
    {
      id: 10,
      imageLink: "assets/img/water/blank.svg",
      scenario: "Rate, placement, and timing",
      tp: "0.29",
      drp: "0.06",
      description: "",
    },
    {
      id: 11,
      imageLink: "assets/img/water/cover-crops.svg",
      scenario: "Cereal rye cover crops",
      tp: "0.25",
      drp: "0.08",
      description: "",
    },
    {
      id: 12,
      imageLink: "assets/img/water/drainage.svg",
      scenario: "Controlled drainage",
      tp: "0.39",
      drp: "0.08",
      description: "",
    },
    {
      id: 13,
      imageLink: "assets/img/water/blank.svg",
      scenario: "Headwater wetlands",
      tp: "0.32",
      drp: "0.08",
      description: "",
    },
  ];
  currentScrollNo: number;
  noptsrc = ".noptsrc-pop";
  wetlands = ".wetlands-pop";
  wcc = ".wcc-pop";

  a1 = ".drainage-pop-ONE";
  b1 = ".no-till-pop-ONE";
  c1 = ".cover-crops-pop-ONE";
  d1 = ".subsurface-placement-pop-ONE";

  a2 = ".wetlands-pop-TWO";
  b2 = ".cover-crops-pop-TWO";
  c2 = ".subsurface-pop-TWO";

  a3 = ".buffer-strips-pop-THREE";
  b3 = ".cover-crops-pop-THREE";
  c3 = ".subsurface-placement-pop-THREE";

  top(el) {
    for (var y = 0; el; el = el.offsetParent)
      y += el.offsetTop - (el.scrollTop || document.documentElement.scrollTop) + el.clientTop;
    return y;
  }

  dotClick(no) {
    document
      .querySelector(".combination.c" + no)
      .scrollIntoView({ behavior: "smooth", block: "center" });
  }

  navDotSet(no) {
    document.querySelectorAll(".nav-dots div").forEach((el) => {
      if (el.classList.contains("current") && !el.classList.contains("dot-" + no)) {
        el.classList.remove("current");
      }
    });
    document.querySelector(".nav-dots .dot-" + no).classList.add("current");
  }

  constructor(private titleService: TitleService) {}

  ngAfterViewInit() {
    document.querySelectorAll(".progress-bar>.tp").forEach((el) => {
      let x = Number(el.parentElement.getAttribute("data-progress")) / 0.23;
      el.setAttribute("style", "width:" + x * 100 + "%");
      el.innerHTML = el.innerHTML + el.parentElement.getAttribute("data-progress");
    });
    document.querySelectorAll(".progress-bar>.drp").forEach((el) => {
      let x = Number(el.parentElement.getAttribute("data-progress")) / 0.05;
      el.setAttribute("style", "width:" + x * 100 + "%");
      el.innerHTML = el.innerHTML + el.parentElement.getAttribute("data-progress");
    });
    document.querySelectorAll(".percent-adoption>div").forEach((el) => {
      el.setAttribute("style", "width:" + el.parentElement.getAttribute("data-percent") + "%");
      el.innerHTML = el.parentElement.getAttribute("data-percent") + "% adoption";
    });
  }

  replacePicturesWith(pictureSelector: String, graphSelector: String) {
    document.querySelectorAll(".picture, .graph").forEach((el) => {
      el.setAttribute("style", "display:none;");
    });
    document
      .querySelectorAll(".picture" + pictureSelector + ", .graph" + graphSelector)
      .forEach((el) => {
        el.setAttribute("style", "display:block;");
      });
  }
  p(pictureSelector: String) {
    document.querySelectorAll(".picture").forEach((el) => {
      el.setAttribute("style", "display:none;");
    });
    document.querySelectorAll(".picture" + pictureSelector).forEach((el) => {
      el.setAttribute("style", "display:block;");
    });
  }
  ngOnInit() {
    document.querySelector(".intro").scrollIntoView({ block: "end" });
    this.titleService.setPageTitle("Management Options to Reduce Lake Erie Algal Blooms");
  }

  update(force: boolean, num:number) {
    let no = num;
    if (this.currentScrollNo != no || force) {
      this.currentScrollNo = no;
      this.replacePicturesWith(".no" + no, ".no" + no);
      this.navDotSet(no);
    }
  }
}
