<mat-form-field class="full-width-chip-list">
    <mat-label>Compare Counties</mat-label>
    <mat-chip-list #chipList aria-label="Scope selection">
      <mat-chip
        *ngFor="let scope of selectedScopes"
        (removed)="remove(scope)">
        {{scope}}
        <mat-icon matChipRemove *ngIf="scope!==initialScope">x</mat-icon>
      </mat-chip>
      <input
				data-html2canvas-ignore
        placeholder="New county..."
        #scopeInput
        [formControl]="scopeCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        (mousedown)="stopPropagation($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let scope of filteredScopes | async" [value]="scope" (click)="add($event)">
        {{scope}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
