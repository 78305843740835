import { NgIf } from "@angular/common";
import { digest } from "@angular/compiler/src/i18n/digest";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { get } from "https";
import { ApiService } from "src/app/services/api.service";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import { environment } from "src/environments/environment";
import { PanoFloorPlanPopupDialog } from "./floor-plan-popup.component";
import { PanoHotspotPopupDialog } from "./hotspot-popup.component";
import { Pannellum, HotSpot, Viewer } from "./typings";
const PannellumLib: Pannellum = (<any>window).pannellum;

@Component({
  selector: "app-panorama",
  templateUrl: "./panorama.component.html",
  styleUrls: ["./panorama.component.scss"],
})
export class PanoramaComponent implements OnInit, AfterViewInit {
  @ViewChild("panorama") panoramaElement: ElementRef;
  @ViewChild("page") pageElement: ElementRef;
  @ViewChild("nav") navElement: ElementRef;
  @Input() id: string;
  @Input() embedded: boolean = false;
  @Output() fullscreenButtonClick = new EventEmitter();

  title: string;
  description: string;
  viewerRef: Viewer;
  pannellumConfig;
  holdNavOpen = true;
  sceneHistory = [];
  currentSceneId = "";
  scenes = [];
  entranceImageURL = "";
  hideEntryOverlay: boolean = false;
  panoId: string;
  visitedHotspotIds = [];
  visitedScenes = [];
  constructor(
    private route: ActivatedRoute,
    private _api: ApiService,
    public dialog: MatDialog,
    public domSanitizer: DomSanitizer,
    private elRef: ElementRef
  ) {}
  getCurrentScene() {
    let sceneId = this.viewerRef.getScene();
    if (!sceneId) return null;
    return this.scenes.find((el) => el.sceneId == sceneId);
  }
  getcoords = () => {
    console.log("Yaw:", this.viewerRef.getYaw());
    console.log("Pitch:", this.viewerRef.getPitch());
    console.log("HFOV:", this.viewerRef.getHfov());
  };

  ngOnInit(): void {
    // if (environment.production) window.location.href = "/404";
  }
  ngAfterViewInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id && params.id.length > 0) {
        this.panoId = params.id;
        this.load();
      }
    });
    if (this.id && this.id.length > 0) {
      this.panoId = this.id;
      this.load();
    }
  }
  load() {
    if (this.panoId && this.panoId.length > 0) {
      this._api.getPanorama(this.panoId).subscribe((res: any) => {
        if (!res.title) window.location.href = "/404";
        [this.title, this.description] = [res.title, res.description];
        this.pannellumConfig = res.pannellumConfig;
        this.scenes = res.scenes;
        this.entranceImageURL = res.entranceImageURL;
        console.log(this.pannellumConfig);
        for (let scene in this.pannellumConfig.scenes) {
          for (let hotspot of this.pannellumConfig.scenes[scene].hotSpots ??
            []) {
            hotspot.clickHandlerFunc = (event, args: any) => {
              if (args && args.html && args.html.length > 0)
                this.openHotspotPopup(args.html);
              event.target.classList.add("visited-hotspot");
              this.visitedHotspotIds.push(event.target.id);
              console.log(args.html);
              console.log(event);
            };
            if (!hotspot.createTooltipArgs) hotspot.createTooltipArgs = {};
            hotspot.createTooltipArgs.tooltip = hotspot.text;
            hotspot.createTooltipFunc = (div, args) => {
              div.id = hotspot.id;
              div.setAttribute("tabindex", 0);
              div.setAttribute("aria-label", hotspot.text);
              if (this.visitedHotspotIds.includes(hotspot.id))
                div.classList.add("visited-hotspot");
              // console.log(hsId);
              div.classList.add("subtype-" + (!!args.type ? args.type : "scene"));
              if (args.tooltip && args.tooltip.length > 0) {
                div.classList.add("custom-tooltip");
                var span = document.createElement("span");
                span.innerHTML = args.tooltip;
                div.appendChild(span);
                span.style.width = span.scrollWidth - 20 + "px";
                span.style.marginLeft =
                  -(span.scrollWidth - div.offsetWidth) / 2 + "px";
                span.style.marginTop = -span.scrollHeight - 12 + "px";
              }
            };
          }
        }
        this.viewerRef = PannellumLib.viewer(
          this.panoramaElement.nativeElement,
          this.pannellumConfig
        );
        this.currentSceneId = this.viewerRef.getScene();
        // this.viewerRef.on("mousedown", () => {
        //   this.holdNavOpen = false;
        // });
        this.viewerRef.on("scenechange", (id) => {
          this.visitedScenes.push(this.currentSceneId);
          this.sceneHistory.push(this.currentSceneId);
          this.currentSceneId = id;
          setTimeout(() => {
            if (document.getElementById(this.currentSceneId))
              this.navElement.nativeElement.scrollTop = document.getElementById(
                this.currentSceneId
              ).offsetTop;
          }, 300);
        });
      });
    }
  }
  goToPreviousScene() {
    this.viewerRef.loadScene(this.sceneHistory.pop());
    this.sceneHistory.pop();
  }
  openHotspotPopup(html: string): void {
    const dialogRef = this.dialog.open(PanoHotspotPopupDialog, {
      data: { html: html },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  openHelpDialog() {
    let dialogBody = `
    <h3>Help! I can’t find my way out</h3>

    <p class="help-dialog">
    Here are a few tips for navigating in the virtual tour.  
    <br><br>
    <strong>Navigate:</strong> Click and drag on the screen to look around an area. For laptops, press and drag your finger on a touchpad. Explore the room to find all the hot spots, or areas of interest. Hot spots may be behind you, above you, or below you. 
    <br><br>
    <strong>
    What are hot spots?
    </strong>
    <br><br>
    Each hot spot fades once you’ve viewed the information.  
    <br>
    <img src="https://contentfilehosting.s3.amazonaws.com/info.svg" class="hotspot-icon-example"> Important features or areas of interest. 
    <br>
    <img src="https://contentfilehosting.s3.amazonaws.com/person.svg" class="hotspot-icon-example"> Interviews with CFAES researchers and staff. 
    <br>
    <img src="https://contentfilehosting.s3.amazonaws.com/scene.svg" class="hotspot-icon-example"> Click to enter or exit a space. 
    <br><br>
    <strong>Sidebar:</strong> Use this menu to travel to specific rooms. The checkmarks indicate you’ve visited that area already. 
    <br><br>
    <strong>Building floor plan:</strong> For each room, there’s a floor plan on the sidebar that pinpoints your current location in the building. Close or open the sidebar by clicking the door icon at the top. 
    <br><br>
    <img src="https://contentfilehosting.s3.amazonaws.com/Screen%20Shot%202022-10-28%20at%201.49.49%20PM.png" class="zoom-icon-example"> Click this to exit the fullscreen mode of the tour.    
    <br>
    </p>
    `;
    this.openHotspotPopup(dialogBody);
  }
  getSceneBtnBg(id) {
    let scene = this.scenes.find((s) => s.sceneId == id);

    return (
      "url('" +
      (scene.backgroundImageURL && scene.backgroundImageURL.length > 0
        ? scene.backgroundImageURL
        : scene.source) +
      "?wtf=cors')"
    );
    // this link explains the issue i was having when pano image was loading in html
    // or css first (no cors headers cached on that type of request),
    // solved by adding a dummy query param to prevent caching.
    // should look into a better solution that doesnt make it load the image twice
    // https://bytemeta.vip/repo/mpetroff/pannellum/issues/1069
  }

  getSafeHtml(html) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  requestFullScreen() {
    if (this.embedded) this.fullscreenButtonClick.emit();
    else {
      // let element = this.pageElement.nativeElement;
      let element = this.elRef.nativeElement;
      // Supports most browsers and their versions.
      var requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen;

      if (requestMethod) {
        // Native full screen.
        requestMethod.call(element);
        //@ts-ignore
      } else if (typeof window.ActiveXObject !== "undefined") {
        // Older IE.
        //@ts-ignore
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript !== null) {
          wscript.SendKeys("{F11}");
        }
      }
    }
  }
  getStarted() {
    this.hideEntryOverlay = true;
    this.requestFullScreen();
  }
  viewFloorPlan() {
    const dialogRef = this.dialog.open(PanoFloorPlanPopupDialog, {
      data: {
        imageURL: this.getCurrentScene().floorPlanImageURL ?? "",
        title: this.getCurrentScene().sceneId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
}
