import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { eBarnsReportFields, eFieldsReportFields } from "src/app/shared/models/eFieldsReportFilters";
import "rxjs/add/operator/map";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Route} from "@angular/compiler/src/core";


const efieldsApiGatewayURL = "https://zvmx43szei.execute-api.us-east-1.amazonaws.com/efields"
const ebarnsApiGatewayURL = "https://j8a9i120hc.execute-api.us-east-1.amazonaws.com/ebarns"

@Injectable({
  providedIn: "root",
})
export class EfieldsSearchService {
	_searchIndex: 'efields' | 'ebarns' = 'efields';
	set searchIndex(index: 'efields' | 'ebarns') {
		this._searchIndex = index;
	}

	get apiGatewayURL() {
		if (this._searchIndex && this._searchIndex == 'ebarns') return ebarnsApiGatewayURL;
		return efieldsApiGatewayURL;
	}

	get reportFolder() {
		if (this._searchIndex && this._searchIndex == 'ebarns') return 'ebarns-reports';
		return 'efields-reports';
	}

	

  public logSearches: boolean = true;

  constructor(private _http: HttpClient, private _router: Router) {
		this._router.events.subscribe((routerEvent) => {
			if (!(routerEvent instanceof NavigationEnd)) return;
			this.searchIndex = (<NavigationEnd>routerEvent).url.includes('ebarns-reports') ?
				'ebarns' :
				'efields'
		});
	}

  public search(query?: string, filters?: eFieldsReportFields | eBarnsReportFields, size?: number) {
    if (!query) query = "x|-x";
    if (!size) size = 1000;

			
    let queryParams = `?q=${query}&size=${size}${this.stringifyFilters(
      filters
    )}`;

    let requestURI = encodeURI(this.apiGatewayURL + queryParams);

    console.log(requestURI);
    let observable = this._http.get(requestURI).map((res: any) => res.hits.hit);
    // let observable = this._http.get(requestURI)

    if (this.logSearches)
      observable.subscribe((res) =>
        console.log(requestURI + "\nResults: ", res)
      );

    return observable;
  }

  public getFieldOptions(fieldName: string) {
    return new Promise<Set<string>>((resolve, reject) => {
      let options: Set<string> = new Set<string>();
      this.search().subscribe((result: any[]) => {
        // console.log(result);
        result.forEach((res: any) => {
          // console.log(res);
          let fieldValues = res.fields[fieldName];
          if (fieldValues) {
            if (fieldName == "authors") {
							console.log(fieldValues);
              fieldValues.forEach((el) => options.add(el));
						}
            else options.add(fieldValues);
          }
        });

        console.log(fieldName, options)
        if (options.size > 0) resolve(options);
        else reject(`No fields titled ${fieldName}.`);
      });
    });
  }

  public navigateToReport(id: string) {
		let bucketUrl = `https://kx-files-public.s3.us-east-2.amazonaws.com/${this.reportFolder}/${id}.pdf` 
    window.open(bucketUrl, "_blank");
  }

  private stringifyFilters(filters: eFieldsReportFields | eBarnsReportFields): string {
    filters = this.searchIndex == 'ebarns' ? 
			this.getValidEbarnsFilters(<eBarnsReportFields>filters) :
			this.getValidFilters(<eFieldsReportFields>filters);
    if (Object.keys(filters).length == 0) return "";

    let filterString = "&fq=(and";

    for (const filterName in filters)
      filterString += ` ${filterName}:'${filters[filterName]}'`;

    filterString += ")";

    return filterString;
  }

  private getValidFilters(filters: eFieldsReportFields): eFieldsReportFields {
    let validFilters: eFieldsReportFields = {};

    for (const filterName in filters) {
      if (filters[filterName]?.length > 1)
        validFilters[filterName] = filters[filterName];
    }

    return validFilters;
  }

	private getValidEbarnsFilters(filters: eBarnsReportFields): eBarnsReportFields{
		let validFilters: eBarnsReportFields = {};
    for (const filterName in filters) {
			let filterVal = filters[filterName];	
			if (!filterVal) continue;
      if (filterVal+"".length > 1)
        validFilters[filterName] = filters[filterName];
    }
    return validFilters;
	}
}
