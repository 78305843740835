<div class="fsr-container">
    <div class="intro">
        <div class="hero-image">
            <img src="https://kx-assets.s3.amazonaws.com/Banner.jpg" alt="Aerial view of Molly Karen Farm." />
        </div>
        <div class="hero-text">
            <p>Welcome to the Agronomic Crops Team site! While the 2020 Farm Science Review is virtual this year, we have worked hard to bring you a unique experience utilizing custom produced digital content for you to explore. 
                As you explore the map, click on any of the text to learn additional information via video (you can view all videos without leaving this page, so make sure your popup blocker is disabled.) Some of the information available this year focuses on the past 150 years of corn genetics and technology improvements, CFAES as the cornerstone college 150 years, utilizing new technology, practices for improving nutrient management and soil health, examining the effects of herbicides on cover crops whether on interseeded cover crops or fall planted cover crop, and forage production.  
                Enjoy your time at FSR and thanks for stopping by!</p>
        </div>
    </div>
    <div class="img-map">
        <!-- Calculations based on an original image (width,height)=(2100,1500) -->
        <!-- Image Map Generated by http://www.image-map.net/ -->
        <img src="https://kx-assets.s3.amazonaws.com/FSR+-+Ag+Plots-7_words.jpg" usemap="#image-map" style="height: 857px; width: 1200px;">

        <map name="image-map">
            <area alt="Cover crop Herbicide" title="Cover crop Herbicide" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/kg1_9V0fBTw")' coords="105,201,296,107" shape="rect">
            <area alt="eFields" title="eFields" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/R4RmKzZ2i4g")' coords="373,195,494,242" shape="rect">
            <area alt="annual forages" title="annual forages" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/SaFMc8bRWKI")' coords="529,44,722,143" shape="rect">
            <area  alt="Alfalfa" title="Alfalfa" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/DS3j85IRGq0")' coords="718,194,42" shape="circle">
            <area  alt="Sheep grazing" title="Sheep grazing" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/GOibX8vd0eE")' coords="827,157,980,243" shape="rect">
            <area  alt="w Zero Nitrogen" title="w Zero Nitrogen" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/AKXnkyh3rr4")' coords="1013,40,1147,161" shape="rect">
            <area  alt="Poultry Litter" title="Poultry Litter" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/i_OZYqg9vc8")' coords="961,336,1109,464" shape="rect">
            <area  alt="Sulfur vid" title="Sulfur vid" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/cm226wr0DBM")' coords="878,316,933,497" shape="rect">
            <area  alt="Interseeding chemical" href="javascript:void(0);" title="Interseeding chemical" (click)='imgClick("https://www.youtube.com/embed/DS3j85IRGq0")' coords="720,334,850,461" shape="rect">
            <area  alt="Mark N, muck mineral" href="javascript:void(0);" title="Mark N, muck mineral" (click)='imgClick("https://www.youtube.com/embed/AYSYs_ZUSX8")' coords="566,339,689,495" shape="rect">
            <area  alt="Mark N, late season" href="javascript:void(0);" title="Mark N, late season" (click)='imgClick("https://www.youtube.com/embed/hu-LsZl7FDc")' coords="921,730,1135,815" shape="rect">
            <area  alt="BMP 1, P filter" href="javascript:void(0);" title="BMP 1, P filter" (click)='imgClick("https://www.youtube.com/embed/dTI_RK1TKXI")' coords="257,765,326,721,418,665,454,717,380,771,299,818" shape="poly">
            <area  alt="BMP 2, drainage control" href="javascript:void(0);" title="BMP 2, drainage control" (click)='imgClick("https://www.youtube.com/embed/_LQ8-S0UBvA")' coords="939,538,1033,518,1125,527,1177,542,1125,575,1049,573,968,563" shape="poly">
            <area  alt="BMP 3, cascading waterfall" href="javascript:void(0);" title="BMP 3, cascading waterfall" (click)='imgClick("https://www.youtube.com/embed/xW1eC6IirR4")' coords="794,26,965,96" shape="rect">
            <area  alt="soil health" title="soil health" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/_Wi8E23FyRQ")' coords="409,358,511,469" shape="rect">
            <area  alt="fertilizer placement" href="javascript:void(0);" title="fertilizer placement" (click)='imgClick("https://www.youtube.com/embed/zrfUYGCg83Q")' coords="303,323,390,501" shape="rect">
            <area  alt="tri-state" title="tri-state" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/smMe70VYSG4")' coords="23,281,185,367" shape="rect">
            <area  alt="manure sidesdress" title="manure sidesdress" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/nPUH2UVHsrk")' coords="21,422,131,397,254,363,266,415,149,445,36,472" shape="poly">
            <area  alt="drone scouting" title="drone scouting" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/BeDqeRo-w6I")' coords="142,605,299,609,298,682,143,680,143,653" shape="poly">
            <area  alt="weather video" title="weather video" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/2jLbQvqKX8U")' coords="499,533,677,594" shape="rect">
            <area  alt="150 Years CFAES" title="150 Years CFAES" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/6LnbQGGiQr0")' coords="673,733,789,798" shape="rect">
            <area  alt="Elizabeth 150 year video" title="Elizabeth 150 year video" href="javascript:void(0);" (click)='imgClick("https://www.youtube.com/embed/VLatT_Ei1GA")' coords="814,598,1089,658" shape="rect">
            <area target="_blank" alt="State yield map tour" title="State yield map tour" href="/state-yield-tour" coords="477,651,521,651,550,659,597,645,617,648,610,709,596,751,571,769,551,787,514,778,497,774,474,760" shape="poly" data-name="State Yield Tour">
        </map>
    </div>
</div>
<div id="imgModal" class="modal">
    <span (click)="spanClick()" (keyup.enter)="spanClick()" class="close">&times;</span>
    <iframe width="853" height="480" src="" id="img01" frameborder="0" allowfullscreen></iframe>
    <!-- <div id="caption"></div> -->
  </div>
