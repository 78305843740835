<button
  *ngIf="buttonClasses.length > 0"
  [class]="buttonClasses"
  [attr.data-target]="'#videoPopup-' + videoID"
  data-toggle="modal"
  tabindex="0"
>
  {{ title }}
</button>
<span
  *ngIf="buttonClasses.length == 0"
  [attr.data-target]="'#videoPopup-' + videoID"
  data-toggle="modal"
  class="span-button"
  tabindex="0"
>
  {{ title }}
</span>

<div
  [id]="'videoPopup-' + videoID"
  [attr.aria-label]="title"
  class="modal fade video-popup"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <iframe
        [src]="source"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
