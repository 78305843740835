<div class="header">
<h2>What's New</h2>
<a href="./bite/site/whats-new" class="view-more">View more posts
<i class="fas fa-chevron-right"></i>

</a>

</div>
<div class="whats-new-container" *ngIf="newestContent">
	<a class="first-item" [href]="newestContent[0].link">
		<div class="first-item-text">
			<div class="item-type">
				{{ !!newestContent[0].display_type && newestContent[0].display_type.length>0 ? newestContent[0].display_type : newestContent[0].type | uppercase}}

			</div>
			<div class="first-item-title">
				{{newestContent[0].title}}
			</div>
			<div class="first-item-description" 
				*ngIf="newestContent[0].description">
				{{newestContent[0].description}}

			</div>
		</div>
		<div class="first-item-image" 
			*ngIf="newestContent[0].imageUrl && newestContent[0].imageUrl.length">
			<img [src]="newestContent[0].imageUrl"/>
		</div>

	</a>
	<div class="other-items">
		<a class="small-item" 
			*ngFor="let item of newestContent.slice(1); index as i"
			[href]="item.link"
			>
			<div class="item-type">
        {{ !!item.display_type && item.display_type.length>0 ? item.display_type : item.type | uppercase}}</div>
			<div class="small-item-title">{{item.title}}</div>

		</a>
	</div>
</div>

