import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-farm-sci-review',
  templateUrl: './farm-sci-review.component.html',
  styleUrls: ['./farm-sci-review.component.scss']
})
export class FarmSciReviewComponent implements OnInit {

  

  constructor() { 

  // Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks on <span> (x), close the modal


  }

  imgClick(src: any) {
    // Get the modal
    var modal = document.getElementById("imgModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01") as HTMLImageElement;
    //var captionText = document.getElementById("caption");
    modal.style.display = "block";
    modalImg.src = src + "?rel=0&autoplay=1&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=1";
    //captionText.innerHTML = this.alt;
  }

  spanClick() { 
    var modalImg = document.getElementById("img01") as HTMLImageElement;
    modalImg.src = ""
    document.getElementById("imgModal").style.display = "none";
  }
  

  ngOnInit() {
  }

}
