import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReadCsvService {

  constructor() { }


  readCsv(file: File, delimiter: string = ','): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          let fileText = event.target.result as string;
          let csvList = [];
          let rowIndex = 0;
          for (let row of fileText.split("\r")) {
            csvList[rowIndex] = [];
            let charInQuote = false;
            let beginIndex = 0;
            let currentIndex = 0;
            for (let char of row) {
              if (char == '"') {
                charInQuote = !charInQuote;
              }
              if (char == "\n") console.log(currentIndex);
              if (char == delimiter && !charInQuote) {
                csvList[rowIndex].push(row.substring(beginIndex, currentIndex));
                beginIndex = currentIndex + 1;
              } else if (currentIndex == row.length - 1) {
                csvList[rowIndex].push(
                  row.substring(beginIndex, currentIndex + 1)
                );
              }
              currentIndex++;
            }
            rowIndex++;

            for (let row of csvList) {
              for (let [index, str] of row.entries()) {
                row[index] = str.replace(/["\n]+/g, "");
              }
            }
          }
          resolve(csvList);
        };
        fileReader.readAsText(file, "UTF-8");
      } catch (error) {
        reject(error);
      }
    });
  }
}
