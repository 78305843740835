import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { PageTitles } from "./title.service";

const DEFAULT_TITLE: string = "Knowledge Exchange";
const DEFAULT_DESCRIPTION: string =
  "KX is a data and communications platform for engaging broader audiences with CFAES research findings.";
const DEFAULT_KEYWORDS: string =
  "research, science, food, agriculture, CFAES, OSU";
const DEFAULT_IMAGE_URL: string =
  "https://kx.osu.edu/assets/img/KX%20logos%20light%20background.png";
const SITE_NAME: string = "Knowledge Exchange";
const TWITTER_HANDLE: string = "@CFAES_KX";
const APPLICATION_NAME: string = "Knowledge Exchange";
const LOCALE: string = "en_US";

@Injectable({
  providedIn: "root",
})
export class MetaTagsService {
  constructor(private angularTitleService: Title, private metaService: Meta) {
    this.initializeDefaultTags();
  }

  initializeDefaultTags(): void {
    // Set default title, description, and keywords
    this.updateTitle(DEFAULT_TITLE);
    this.updateDescription(DEFAULT_DESCRIPTION);
    this.updateKeywords(DEFAULT_KEYWORDS);

    // Set default Open Graph tags
    const defaultOgTags = [
      { property: "title", content: DEFAULT_TITLE },
      { property: "description", content: DEFAULT_DESCRIPTION },
      { property: "image", content: DEFAULT_IMAGE_URL },
      { property: "type", content: "website" },
      { property: "site_name", content: SITE_NAME },
      { property: "locale", content: LOCALE },
    ];
    this.updateOpenGraphTags(defaultOgTags);

    // Set default Twitter Card tags
    const defaultTwitterTags = [
      { name: "title", content: DEFAULT_TITLE },
      { name: "description", content: DEFAULT_DESCRIPTION },
      { name: "image", content: DEFAULT_IMAGE_URL },
      { name: "card", content: "summary_large_image" },
      { name: "site", content: TWITTER_HANDLE },
      { name: "creator", content: TWITTER_HANDLE },
    ];
    this.updateTwitterCardTags(defaultTwitterTags);

    // Set default application name and theme color
    this.metaService.updateTag({
      name: "application-name",
      content: APPLICATION_NAME,
    });

    // Set default viewport
    this.metaService.updateTag({
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    });
  }
  public setDefaultTitle(): void {
    this.angularTitleService.setTitle(PageTitles.Default);
    document
      .getElementById("meta-title")
      .setAttribute("content", PageTitles.Default);
  }

  // Update the page title and Open Graph tag
  updateTitle(title: string): void {
    this.angularTitleService.setTitle("KX | " + title);
    this.metaService.updateTag({ property: "og:title", content: title });
    this.metaService.updateTag({ name: "twitter:title", content: title });
  }

  // Update the meta description and Open Graph and Twitter Card tags
  updateDescription(description: string): void {
    this.metaService.updateTag({ name: "description", content: description });
    this.metaService.updateTag({
      property: "og:description",
      content: description,
    });
    this.metaService.updateTag({
      name: "twitter:description",
      content: description,
    });
  }

  // Update the meta keywords and Open Graph tag
  updateKeywords(keywords: string): void {
    this.metaService.updateTag({ name: "keywords", content: keywords });
    this.metaService.updateTag({ property: "og:keywords", content: keywords });
  }

  // Update Open Graph and Twitter Card image tags
  updateImageTags(imageUrl: string): void {
    // Update Open Graph image tag
    this.metaService.updateTag({ property: "og:image", content: imageUrl });

    // Update Twitter Card image tag
    this.metaService.updateTag({
      name: "twitter:card",
      content: "summary_large_image",
    });
    this.metaService.updateTag({ name: "twitter:image", content: imageUrl });
  }

  // Update Open Graph tags
  updateOpenGraphTags(ogTags: { property: string; content: string }[]): void {
    ogTags.forEach((tag) => {
      this.metaService.updateTag({
        property: `og:${tag.property}`,
        content: tag.content,
      });
    });
  }

  // Update Twitter Card tags
  updateTwitterCardTags(
    twitterTags: { name: string; content: string }[]
  ): void {
    twitterTags.forEach((tag) => {
      this.metaService.updateTag({
        name: `twitter:${tag.name}`,
        content: tag.content,
      });
    });
  }
}
