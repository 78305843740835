<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="23.72px"
  height="44.39px"
  viewBox="0 0 23.72 44.39"
  style="overflow: visible; enable-background: new 0 0 23.72 44.39"
  [ngStyle]="{'transform': direction == 'left' ? 'rotate(180deg)' : 'none'}"
  xml:space="preserve"
  class="slider-arrow-icon"
>
  <!-- <style type="text/css">
    .st0 {
      fill: #231f20;
    }
  </style> -->
  <defs></defs>
  <g>
    <path
      class="st0 slider-arrow-icon-path"
      d="M2.1,44.39c-0.6,0-1.19-0.25-1.62-0.75c-0.74-0.86-0.59-2.18,0.21-2.97l18.39-18.3L0.67,3.71
		c-0.78-0.79-0.92-2.07-0.22-2.93c0.81-0.99,2.28-1.03,3.15-0.14l19.15,19.41c1.28,1.3,1.27,3.39-0.02,4.67L3.6,43.77
		C3.18,44.18,2.64,44.39,2.1,44.39z"
    />
  </g>
</svg>
