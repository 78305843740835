import { Component, Input, OnInit } from "@angular/core";
import { CustomPageSection } from "../../custom-stuff/etc/types";

@Component({
  selector: "app-contributor-grid-list",
  templateUrl: "./contributors.component.html",
  styleUrls: ["./contributors.component.scss"],
})
export class ContributorsComponent implements OnInit {
  @Input() contributors: {
    name: string;
    titles: string[];
    email: string;
    number: string;
    unitNames: string[];
    image: string;
    sections: CustomPageSection[];
  }[];

  view: "List" | "Grid" = "List";
  activeCard = 0;

  constructor() {}

  ngOnInit(): void {}

  getCssUrlVar(url) {
    return `url('${url}')`;
  }
}
