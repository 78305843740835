import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tick-callout',
  templateUrl: './tick-callout.component.html',
  styleUrls: ['./tick-callout.component.scss']
})
export class TickCalloutComponent implements OnInit {
	@Input() bodyText: string;
	@Input() headerText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
