<app-home-button [tags]="tags"></app-home-button>	
<ng-container *ngIf="ready; else loading">
  <!-- HEADER START -->
  <ng-container *ngIf="displayHeader">
    <ng-container [ngSwitch]="headerType">
      <ng-container *ngSwitchCase="'full'">
        <app-full-page-image-header
          [title]="title"
          [description]="description"
          [topics]="topics"
          [headerImage]="headerImage"
        ></app-full-page-image-header>
      </ng-container>
      <div
        class="{{ widthClass }} header title-only"
        *ngSwitchCase="'title_only'"
      >
        <h1 [innerHTML]="title"></h1>
      </div>
      <div
        class="header short"
        *ngSwitchCase="'short'"
        [ngStyle]="getHeaderBackground()"
      >
        <h1 [innerHTML]="title"></h1>
        <p [innerHTML]="description"></p>
        <app-subscribe-dialog
          *ngIf="topicOverride"
          [selectedTopics]="[topicOverride]"
        ></app-subscribe-dialog>
      </div>
      <div class="header campaign {{ title }}" *ngSwitchCase="'campaign'">
        <h1 [innerHTML]="title"></h1>
        <img [src]="headerImage" [alt]="title" />
        <app-subscribe-dialog [selectedTopics]="[title]"></app-subscribe-dialog>
        <a [href]="getCampaignLink()">
          <button mat-flat-button class="campaign-link">
            {{ getCampaignTitle() }}
          </button>
        </a>
      </div>
      <div
        class="journalistic-header {{ widthClass }}"
        *ngSwitchCase="'journalistic'"
      >
        <h1 [innerHTML]="title"></h1>
        <div *ngIf="subtitle" class="subtitle" [innerHTML]="subtitle"></div>
        <hr />
        <div class="attribution">
          <div class="text">
            <div *ngIf="byline" class="byline">By {{ byline }}</div>
            <div>{{ date }} / KNOWLEDGE EXCHANGE</div>
          </div>
          <app-social-links
            *ngIf="title"
            [title]="title"
            position="right"
          ></app-social-links>
        </div>
        <img class="header-img" src="{{ headerImage }}" />
      </div>
      <div class="header hero {{ widthClass }}" *ngSwitchCase="'hero'">
        <div class="header-img-container">
          <img class="header-img" src="{{ headerImage }}" />
        </div>
        <div class="description">
          <h1 class="title" *ngIf="mobile; else split">{{ title }}</h1>
          <ng-template #split>
            <h1 class="title split">{{ splitTitle[0] }}</h1>
            <h1 class="title split">{{ splitTitle[1] }}</h1>
          </ng-template>
          <h2 class="subtitle">{{ subtitle }}</h2>
        </div>
      </div>
      <p *ngSwitchDefault>Invalid header option.</p>
    </ng-container>
  </ng-container>
  <!-- HEADER END -->

  <div *ngIf="showHeroCarouselHeader">
    <app-hero-swiper
      [entryRegistation]="heroEntryRegistration"
      [entryClicked]="heroEntryClicked"
    ></app-hero-swiper>
  </div>

  <!-- CONTENT START -->
  <div class="custom-page {{ widthClass }}">
    <app-custom-page-section
      *ngFor="let s of sections"
			[sectionData]="s"
      [parentNodeId]="nodeID"
    ></app-custom-page-section>
  </div>

  <app-contributor-list [people]="contributors"></app-contributor-list>
  <!-- CONTENT END -->
  <!-- <app-reactions></app-reactions> -->
</ng-container>
<ng-template #loading>
  <div class="p-5 m-5">
    <app-loading-indicator></app-loading-indicator>
  </div>
</ng-template>
