import {
  Directive,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from "@angular/core";

@Directive({
  selector: "[appScroll]",
})
export class ScrollPositionDirective {
  @Output() enterScreen = new EventEmitter();
  @Output() leaveScreen = new EventEmitter();

  @HostListener("window:scroll") onScroll() {
    let wasVisible = this.visible;
    let elRectangle = this.el.nativeElement.getBoundingClientRect();
    let viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    this.visible = !(
      elRectangle.bottom < 0 || elRectangle.top - viewHeight >= 0
    );
    if (this.visible != wasVisible)
      (wasVisible ? this.leaveScreen : this.enterScreen).emit();
  }

  visible: boolean = false;
  
  constructor(private el: ElementRef) {}
}
