<div class="social-links sl-{{ position }}">
  <a
    [href]="facebookLink"
    aria-label="Share on Facebook"
    title="Share on Facebook"
    target="_blank"
    rel="noopener"
  >
    <i class="fab fa-facebook"></i>
  </a>
  <a
    [href]="twitterLink"
    aria-label="Share on Twitter"
    title="Share on Twitter"
    target="_blank"
    rel="noopener"
  >
    <i class="fa-brands fa-x-twitter"></i>
  </a>
  <a
    [href]="emailLink"
    aria-label="Share via email"
    title="Share via email"
    target="_blank"
    rel="noopener"
  >
    <i class="fas fa-envelope"></i>
  </a>
</div>
