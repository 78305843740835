export const charts = [
{
    "title": "Primary Operator Average Age 1997 - 2017",
    "name": "state-age",
    "ytitle": "Average Age (years)",
    "xtitle": "Year",
    "type": "line2",
    "tags": [
    "agriculture",
    "farm-stress"
    ],
    "data": [
    {
        "year": 1997,
        "value": 52.5
    },
    {
        "year": 2002,
        "value": 53.8
    },
    {
        "year": 2007,
        "value": 55.7
    },
    {
        "year": 2012,
        "value": 56.8
    },
    {
        "year": 2017,
        "value": 57.7
    }
    ]
},
{
    "title": "Average Age of Primary Operators by County",
    "name": "average-age",
    "ytitle": "",
    "xtitle": "",
    "type": "map",
    "tags": [
    "agriculture",
    "farm-stress"
    ],
    "data": [
    {
        "fips": 39001,
        "year": 1997,
        "value": 52.7
    },
    {
        "fips": 39003,
        "year": 1997,
        "value": 53.6
    },
    {
        "fips": 39005,
        "year": 1997,
        "value": 52
    },
    {
        "fips": 39007,
        "year": 1997,
        "value": 53.3
    },
    {
        "fips": 39009,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39011,
        "year": 1997,
        "value": 51.2
    },
    {
        "fips": 39013,
        "year": 1997,
        "value": 54.2
    },
    {
        "fips": 39015,
        "year": 1997,
        "value": 52.9
    },
    {
        "fips": 39017,
        "year": 1997,
        "value": 54
    },
    {
        "fips": 39019,
        "year": 1997,
        "value": 52.9
    },
    {
        "fips": 39021,
        "year": 1997,
        "value": 51.8
    },
    {
        "fips": 39023,
        "year": 1997,
        "value": 52.8
    },
    {
        "fips": 39025,
        "year": 1997,
        "value": 54.3
    },
    {
        "fips": 39027,
        "year": 1997,
        "value": 51.8
    },
    {
        "fips": 39029,
        "year": 1997,
        "value": 51.6
    },
    {
        "fips": 39031,
        "year": 1997,
        "value": 53
    },
    {
        "fips": 39033,
        "year": 1997,
        "value": 50.7
    },
    {
        "fips": 39035,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39037,
        "year": 1997,
        "value": 50.6
    },
    {
        "fips": 39039,
        "year": 1997,
        "value": 53.9
    },
    {
        "fips": 39041,
        "year": 1997,
        "value": 53.5
    },
    {
        "fips": 39043,
        "year": 1997,
        "value": 51.7
    },
    {
        "fips": 39045,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39047,
        "year": 1997,
        "value": 52.7
    },
    {
        "fips": 39049,
        "year": 1997,
        "value": 55
    },
    {
        "fips": 39051,
        "year": 1997,
        "value": 50
    },
    {
        "fips": 39053,
        "year": 1997,
        "value": 51.8
    },
    {
        "fips": 39055,
        "year": 1997,
        "value": 51.6
    },
    {
        "fips": 39057,
        "year": 1997,
        "value": 54.1
    },
    {
        "fips": 39059,
        "year": 1997,
        "value": 55.5
    },
    {
        "fips": 39061,
        "year": 1997,
        "value": 55.5
    },
    {
        "fips": 39063,
        "year": 1997,
        "value": 50.8
    },
    {
        "fips": 39065,
        "year": 1997,
        "value": 52.7
    },
    {
        "fips": 39067,
        "year": 1997,
        "value": 55.5
    },
    {
        "fips": 39069,
        "year": 1997,
        "value": 50.5
    },
    {
        "fips": 39071,
        "year": 1997,
        "value": 53.2
    },
    {
        "fips": 39073,
        "year": 1997,
        "value": 53.6
    },
    {
        "fips": 39075,
        "year": 1997,
        "value": 46.5
    },
    {
        "fips": 39077,
        "year": 1997,
        "value": 51.2
    },
    {
        "fips": 39079,
        "year": 1997,
        "value": 54
    },
    {
        "fips": 39081,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39083,
        "year": 1997,
        "value": 51.7
    },
    {
        "fips": 39085,
        "year": 1997,
        "value": 56.2
    },
    {
        "fips": 39087,
        "year": 1997,
        "value": 54.6
    },
    {
        "fips": 39089,
        "year": 1997,
        "value": 53.6
    },
    {
        "fips": 39091,
        "year": 1997,
        "value": 52.6
    },
    {
        "fips": 39093,
        "year": 1997,
        "value": 52.5
    },
    {
        "fips": 39095,
        "year": 1997,
        "value": 53.4
    },
    {
        "fips": 39097,
        "year": 1997,
        "value": 51.3
    },
    {
        "fips": 39099,
        "year": 1997,
        "value": 52.8
    },
    {
        "fips": 39101,
        "year": 1997,
        "value": 52.5
    },
    {
        "fips": 39103,
        "year": 1997,
        "value": 54
    },
    {
        "fips": 39105,
        "year": 1997,
        "value": 52.8
    },
    {
        "fips": 39107,
        "year": 1997,
        "value": 48.8
    },
    {
        "fips": 39109,
        "year": 1997,
        "value": 52.3
    },
    {
        "fips": 39111,
        "year": 1997,
        "value": 55.1
    },
    {
        "fips": 39113,
        "year": 1997,
        "value": 54
    },
    {
        "fips": 39115,
        "year": 1997,
        "value": 54.2
    },
    {
        "fips": 39117,
        "year": 1997,
        "value": 52.8
    },
    {
        "fips": 39119,
        "year": 1997,
        "value": 55.4
    },
    {
        "fips": 39121,
        "year": 1997,
        "value": 53
    },
    {
        "fips": 39123,
        "year": 1997,
        "value": 52.7
    },
    {
        "fips": 39125,
        "year": 1997,
        "value": 51.6
    },
    {
        "fips": 39127,
        "year": 1997,
        "value": 52.2
    },
    {
        "fips": 39129,
        "year": 1997,
        "value": 52.3
    },
    {
        "fips": 39131,
        "year": 1997,
        "value": 53.9
    },
    {
        "fips": 39133,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39135,
        "year": 1997,
        "value": 52.1
    },
    {
        "fips": 39137,
        "year": 1997,
        "value": 49.5
    },
    {
        "fips": 39139,
        "year": 1997,
        "value": 51.4
    },
    {
        "fips": 39141,
        "year": 1997,
        "value": 55.5
    },
    {
        "fips": 39143,
        "year": 1997,
        "value": 50.6
    },
    {
        "fips": 39145,
        "year": 1997,
        "value": 53.1
    },
    {
        "fips": 39147,
        "year": 1997,
        "value": 50.3
    },
    {
        "fips": 39149,
        "year": 1997,
        "value": 50.4
    },
    {
        "fips": 39151,
        "year": 1997,
        "value": 52.8
    },
    {
        "fips": 39153,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39155,
        "year": 1997,
        "value": 54.6
    },
    {
        "fips": 39157,
        "year": 1997,
        "value": 53.2
    },
    {
        "fips": 39159,
        "year": 1997,
        "value": 52.4
    },
    {
        "fips": 39161,
        "year": 1997,
        "value": 51.4
    },
    {
        "fips": 39163,
        "year": 1997,
        "value": 54.5
    },
    {
        "fips": 39165,
        "year": 1997,
        "value": 54.2
    },
    {
        "fips": 39167,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39169,
        "year": 1997,
        "value": 50.2
    },
    {
        "fips": 39171,
        "year": 1997,
        "value": 54.4
    },
    {
        "fips": 39173,
        "year": 1997,
        "value": 50.7
    },
    {
        "fips": 39175,
        "year": 1997,
        "value": 51
    },
    {
        "fips": 39001,
        "year": 2002,
        "value": 54.7
    },
    {
        "fips": 39003,
        "year": 2002,
        "value": 55.2
    },
    {
        "fips": 39005,
        "year": 2002,
        "value": 52.8
    },
    {
        "fips": 39007,
        "year": 2002,
        "value": 55.4
    },
    {
        "fips": 39009,
        "year": 2002,
        "value": 54.9
    },
    {
        "fips": 39011,
        "year": 2002,
        "value": 51.6
    },
    {
        "fips": 39013,
        "year": 2002,
        "value": 55.3
    },
    {
        "fips": 39015,
        "year": 2002,
        "value": 53.7
    },
    {
        "fips": 39017,
        "year": 2002,
        "value": 55.6
    },
    {
        "fips": 39019,
        "year": 2002,
        "value": 53.4
    },
    {
        "fips": 39021,
        "year": 2002,
        "value": 53.2
    },
    {
        "fips": 39023,
        "year": 2002,
        "value": 52.9
    },
    {
        "fips": 39025,
        "year": 2002,
        "value": 55.9
    },
    {
        "fips": 39027,
        "year": 2002,
        "value": 53.9
    },
    {
        "fips": 39029,
        "year": 2002,
        "value": 51.9
    },
    {
        "fips": 39031,
        "year": 2002,
        "value": 53.8
    },
    {
        "fips": 39033,
        "year": 2002,
        "value": 54.4
    },
    {
        "fips": 39035,
        "year": 2002,
        "value": 57
    },
    {
        "fips": 39037,
        "year": 2002,
        "value": 51.4
    },
    {
        "fips": 39039,
        "year": 2002,
        "value": 54.9
    },
    {
        "fips": 39041,
        "year": 2002,
        "value": 55.1
    },
    {
        "fips": 39043,
        "year": 2002,
        "value": 55.3
    },
    {
        "fips": 39045,
        "year": 2002,
        "value": 55.1
    },
    {
        "fips": 39047,
        "year": 2002,
        "value": 53.9
    },
    {
        "fips": 39049,
        "year": 2002,
        "value": 55.7
    },
    {
        "fips": 39051,
        "year": 2002,
        "value": 52
    },
    {
        "fips": 39053,
        "year": 2002,
        "value": 53.3
    },
    {
        "fips": 39055,
        "year": 2002,
        "value": 52.8
    },
    {
        "fips": 39057,
        "year": 2002,
        "value": 54.9
    },
    {
        "fips": 39059,
        "year": 2002,
        "value": 56.5
    },
    {
        "fips": 39061,
        "year": 2002,
        "value": 55.6
    },
    {
        "fips": 39063,
        "year": 2002,
        "value": 53
    },
    {
        "fips": 39065,
        "year": 2002,
        "value": 54
    },
    {
        "fips": 39067,
        "year": 2002,
        "value": 55.5
    },
    {
        "fips": 39069,
        "year": 2002,
        "value": 53.1
    },
    {
        "fips": 39071,
        "year": 2002,
        "value": 55.5
    },
    {
        "fips": 39073,
        "year": 2002,
        "value": 53.6
    },
    {
        "fips": 39075,
        "year": 2002,
        "value": 47.9
    },
    {
        "fips": 39077,
        "year": 2002,
        "value": 53.3
    },
    {
        "fips": 39079,
        "year": 2002,
        "value": 55
    },
    {
        "fips": 39081,
        "year": 2002,
        "value": 55.4
    },
    {
        "fips": 39083,
        "year": 2002,
        "value": 52.5
    },
    {
        "fips": 39085,
        "year": 2002,
        "value": 57.2
    },
    {
        "fips": 39087,
        "year": 2002,
        "value": 54.9
    },
    {
        "fips": 39089,
        "year": 2002,
        "value": 54.3
    },
    {
        "fips": 39091,
        "year": 2002,
        "value": 52.8
    },
    {
        "fips": 39093,
        "year": 2002,
        "value": 54.8
    },
    {
        "fips": 39095,
        "year": 2002,
        "value": 55.6
    },
    {
        "fips": 39097,
        "year": 2002,
        "value": 52.9
    },
    {
        "fips": 39099,
        "year": 2002,
        "value": 54.6
    },
    {
        "fips": 39101,
        "year": 2002,
        "value": 55
    },
    {
        "fips": 39103,
        "year": 2002,
        "value": 53.8
    },
    {
        "fips": 39105,
        "year": 2002,
        "value": 54.5
    },
    {
        "fips": 39107,
        "year": 2002,
        "value": 50.8
    },
    {
        "fips": 39109,
        "year": 2002,
        "value": 54
    },
    {
        "fips": 39111,
        "year": 2002,
        "value": 55.7
    },
    {
        "fips": 39113,
        "year": 2002,
        "value": 54.8
    },
    {
        "fips": 39115,
        "year": 2002,
        "value": 54.8
    },
    {
        "fips": 39117,
        "year": 2002,
        "value": 52.6
    },
    {
        "fips": 39119,
        "year": 2002,
        "value": 55.8
    },
    {
        "fips": 39121,
        "year": 2002,
        "value": 53.5
    },
    {
        "fips": 39123,
        "year": 2002,
        "value": 53.4
    },
    {
        "fips": 39125,
        "year": 2002,
        "value": 53.7
    },
    {
        "fips": 39127,
        "year": 2002,
        "value": 54.5
    },
    {
        "fips": 39129,
        "year": 2002,
        "value": 54
    },
    {
        "fips": 39131,
        "year": 2002,
        "value": 56
    },
    {
        "fips": 39133,
        "year": 2002,
        "value": 55.3
    },
    {
        "fips": 39135,
        "year": 2002,
        "value": 54.1
    },
    {
        "fips": 39137,
        "year": 2002,
        "value": 51.3
    },
    {
        "fips": 39139,
        "year": 2002,
        "value": 52.2
    },
    {
        "fips": 39141,
        "year": 2002,
        "value": 55.2
    },
    {
        "fips": 39143,
        "year": 2002,
        "value": 53.8
    },
    {
        "fips": 39145,
        "year": 2002,
        "value": 53.3
    },
    {
        "fips": 39147,
        "year": 2002,
        "value": 53.2
    },
    {
        "fips": 39149,
        "year": 2002,
        "value": 51.9
    },
    {
        "fips": 39151,
        "year": 2002,
        "value": 53.5
    },
    {
        "fips": 39153,
        "year": 2002,
        "value": 52.2
    },
    {
        "fips": 39155,
        "year": 2002,
        "value": 55.1
    },
    {
        "fips": 39157,
        "year": 2002,
        "value": 54.4
    },
    {
        "fips": 39159,
        "year": 2002,
        "value": 52.8
    },
    {
        "fips": 39161,
        "year": 2002,
        "value": 53.7
    },
    {
        "fips": 39163,
        "year": 2002,
        "value": 53.3
    },
    {
        "fips": 39165,
        "year": 2002,
        "value": 55.4
    },
    {
        "fips": 39167,
        "year": 2002,
        "value": 57
    },
    {
        "fips": 39169,
        "year": 2002,
        "value": 51.1
    },
    {
        "fips": 39171,
        "year": 2002,
        "value": 56.6
    },
    {
        "fips": 39173,
        "year": 2002,
        "value": 53.4
    },
    {
        "fips": 39175,
        "year": 2002,
        "value": 53.7
    },
    {
        "fips": 39001,
        "year": 2007,
        "value": 57.1
    },
    {
        "fips": 39003,
        "year": 2007,
        "value": 56.9
    },
    {
        "fips": 39005,
        "year": 2007,
        "value": 55.1
    },
    {
        "fips": 39007,
        "year": 2007,
        "value": 56.6
    },
    {
        "fips": 39009,
        "year": 2007,
        "value": 57.3
    },
    {
        "fips": 39011,
        "year": 2007,
        "value": 55.4
    },
    {
        "fips": 39013,
        "year": 2007,
        "value": 56.2
    },
    {
        "fips": 39015,
        "year": 2007,
        "value": 56.3
    },
    {
        "fips": 39017,
        "year": 2007,
        "value": 57.2
    },
    {
        "fips": 39019,
        "year": 2007,
        "value": 55.9
    },
    {
        "fips": 39021,
        "year": 2007,
        "value": 55.1
    },
    {
        "fips": 39023,
        "year": 2007,
        "value": 54.7
    },
    {
        "fips": 39025,
        "year": 2007,
        "value": 57.9
    },
    {
        "fips": 39027,
        "year": 2007,
        "value": 56.6
    },
    {
        "fips": 39029,
        "year": 2007,
        "value": 54.9
    },
    {
        "fips": 39031,
        "year": 2007,
        "value": 54.8
    },
    {
        "fips": 39033,
        "year": 2007,
        "value": 55.8
    },
    {
        "fips": 39035,
        "year": 2007,
        "value": 58
    },
    {
        "fips": 39037,
        "year": 2007,
        "value": 53.7
    },
    {
        "fips": 39039,
        "year": 2007,
        "value": 57.6
    },
    {
        "fips": 39041,
        "year": 2007,
        "value": 56.8
    },
    {
        "fips": 39043,
        "year": 2007,
        "value": 56.5
    },
    {
        "fips": 39045,
        "year": 2007,
        "value": 56.6
    },
    {
        "fips": 39047,
        "year": 2007,
        "value": 56.8
    },
    {
        "fips": 39049,
        "year": 2007,
        "value": 56.5
    },
    {
        "fips": 39051,
        "year": 2007,
        "value": 53.4
    },
    {
        "fips": 39053,
        "year": 2007,
        "value": 55
    },
    {
        "fips": 39055,
        "year": 2007,
        "value": 54.8
    },
    {
        "fips": 39057,
        "year": 2007,
        "value": 56.2
    },
    {
        "fips": 39059,
        "year": 2007,
        "value": 56.5
    },
    {
        "fips": 39061,
        "year": 2007,
        "value": 57.1
    },
    {
        "fips": 39063,
        "year": 2007,
        "value": 56
    },
    {
        "fips": 39065,
        "year": 2007,
        "value": 55.9
    },
    {
        "fips": 39067,
        "year": 2007,
        "value": 57.6
    },
    {
        "fips": 39069,
        "year": 2007,
        "value": 55.5
    },
    {
        "fips": 39071,
        "year": 2007,
        "value": 56.7
    },
    {
        "fips": 39073,
        "year": 2007,
        "value": 55.8
    },
    {
        "fips": 39075,
        "year": 2007,
        "value": 49.8
    },
    {
        "fips": 39077,
        "year": 2007,
        "value": 55
    },
    {
        "fips": 39079,
        "year": 2007,
        "value": 57.7
    },
    {
        "fips": 39081,
        "year": 2007,
        "value": 57.5
    },
    {
        "fips": 39083,
        "year": 2007,
        "value": 54.6
    },
    {
        "fips": 39085,
        "year": 2007,
        "value": 58.6
    },
    {
        "fips": 39087,
        "year": 2007,
        "value": 56
    },
    {
        "fips": 39089,
        "year": 2007,
        "value": 56.7
    },
    {
        "fips": 39091,
        "year": 2007,
        "value": 55.6
    },
    {
        "fips": 39093,
        "year": 2007,
        "value": 56.1
    },
    {
        "fips": 39095,
        "year": 2007,
        "value": 56
    },
    {
        "fips": 39097,
        "year": 2007,
        "value": 55.2
    },
    {
        "fips": 39099,
        "year": 2007,
        "value": 56.7
    },
    {
        "fips": 39101,
        "year": 2007,
        "value": 56.9
    },
    {
        "fips": 39103,
        "year": 2007,
        "value": 55.4
    },
    {
        "fips": 39105,
        "year": 2007,
        "value": 56.3
    },
    {
        "fips": 39107,
        "year": 2007,
        "value": 52.7
    },
    {
        "fips": 39109,
        "year": 2007,
        "value": 55.4
    },
    {
        "fips": 39111,
        "year": 2007,
        "value": 57.8
    },
    {
        "fips": 39113,
        "year": 2007,
        "value": 56.7
    },
    {
        "fips": 39115,
        "year": 2007,
        "value": 56.8
    },
    {
        "fips": 39117,
        "year": 2007,
        "value": 54
    },
    {
        "fips": 39119,
        "year": 2007,
        "value": 56.9
    },
    {
        "fips": 39121,
        "year": 2007,
        "value": 54.2
    },
    {
        "fips": 39123,
        "year": 2007,
        "value": 55.9
    },
    {
        "fips": 39125,
        "year": 2007,
        "value": 57.1
    },
    {
        "fips": 39127,
        "year": 2007,
        "value": 55.9
    },
    {
        "fips": 39129,
        "year": 2007,
        "value": 57.9
    },
    {
        "fips": 39131,
        "year": 2007,
        "value": 55.8
    },
    {
        "fips": 39133,
        "year": 2007,
        "value": 56.5
    },
    {
        "fips": 39135,
        "year": 2007,
        "value": 54.8
    },
    {
        "fips": 39137,
        "year": 2007,
        "value": 53.7
    },
    {
        "fips": 39139,
        "year": 2007,
        "value": 54.8
    },
    {
        "fips": 39141,
        "year": 2007,
        "value": 57.8
    },
    {
        "fips": 39143,
        "year": 2007,
        "value": 54.9
    },
    {
        "fips": 39145,
        "year": 2007,
        "value": 55.4
    },
    {
        "fips": 39147,
        "year": 2007,
        "value": 55.3
    },
    {
        "fips": 39149,
        "year": 2007,
        "value": 55
    },
    {
        "fips": 39151,
        "year": 2007,
        "value": 56.4
    },
    {
        "fips": 39153,
        "year": 2007,
        "value": 57.1
    },
    {
        "fips": 39155,
        "year": 2007,
        "value": 56.8
    },
    {
        "fips": 39157,
        "year": 2007,
        "value": 55.8
    },
    {
        "fips": 39159,
        "year": 2007,
        "value": 55.8
    },
    {
        "fips": 39161,
        "year": 2007,
        "value": 55.1
    },
    {
        "fips": 39163,
        "year": 2007,
        "value": 56.5
    },
    {
        "fips": 39165,
        "year": 2007,
        "value": 57.8
    },
    {
        "fips": 39167,
        "year": 2007,
        "value": 57.4
    },
    {
        "fips": 39169,
        "year": 2007,
        "value": 52.3
    },
    {
        "fips": 39171,
        "year": 2007,
        "value": 58.4
    },
    {
        "fips": 39173,
        "year": 2007,
        "value": 55.6
    },
    {
        "fips": 39175,
        "year": 2007,
        "value": 54.4
    },
    {
        "fips": 39001,
        "year": 2012,
        "value": 57.7
    },
    {
        "fips": 39003,
        "year": 2012,
        "value": 58.2
    },
    {
        "fips": 39005,
        "year": 2012,
        "value": 55.5
    },
    {
        "fips": 39007,
        "year": 2012,
        "value": 57.1
    },
    {
        "fips": 39009,
        "year": 2012,
        "value": 58.8
    },
    {
        "fips": 39011,
        "year": 2012,
        "value": 55.8
    },
    {
        "fips": 39013,
        "year": 2012,
        "value": 56.7
    },
    {
        "fips": 39015,
        "year": 2012,
        "value": 57.8
    },
    {
        "fips": 39017,
        "year": 2012,
        "value": 59.2
    },
    {
        "fips": 39019,
        "year": 2012,
        "value": 56.2
    },
    {
        "fips": 39021,
        "year": 2012,
        "value": 57.2
    },
    {
        "fips": 39023,
        "year": 2012,
        "value": 57.5
    },
    {
        "fips": 39025,
        "year": 2012,
        "value": 59.8
    },
    {
        "fips": 39027,
        "year": 2012,
        "value": 57.2
    },
    {
        "fips": 39029,
        "year": 2012,
        "value": 57.4
    },
    {
        "fips": 39031,
        "year": 2012,
        "value": 55.5
    },
    {
        "fips": 39033,
        "year": 2012,
        "value": 58.8
    },
    {
        "fips": 39035,
        "year": 2012,
        "value": 58.2
    },
    {
        "fips": 39037,
        "year": 2012,
        "value": 55.1
    },
    {
        "fips": 39039,
        "year": 2012,
        "value": 57.9
    },
    {
        "fips": 39041,
        "year": 2012,
        "value": 58
    },
    {
        "fips": 39043,
        "year": 2012,
        "value": 58.9
    },
    {
        "fips": 39045,
        "year": 2012,
        "value": 58.1
    },
    {
        "fips": 39047,
        "year": 2012,
        "value": 57.8
    },
    {
        "fips": 39049,
        "year": 2012,
        "value": 57.9
    },
    {
        "fips": 39051,
        "year": 2012,
        "value": 54.7
    },
    {
        "fips": 39053,
        "year": 2012,
        "value": 56.4
    },
    {
        "fips": 39055,
        "year": 2012,
        "value": 55.5
    },
    {
        "fips": 39057,
        "year": 2012,
        "value": 58
    },
    {
        "fips": 39059,
        "year": 2012,
        "value": 58.1
    },
    {
        "fips": 39061,
        "year": 2012,
        "value": 58.8
    },
    {
        "fips": 39063,
        "year": 2012,
        "value": 56.7
    },
    {
        "fips": 39065,
        "year": 2012,
        "value": 58.6
    },
    {
        "fips": 39067,
        "year": 2012,
        "value": 58.7
    },
    {
        "fips": 39069,
        "year": 2012,
        "value": 56.7
    },
    {
        "fips": 39071,
        "year": 2012,
        "value": 57.3
    },
    {
        "fips": 39073,
        "year": 2012,
        "value": 58.5
    },
    {
        "fips": 39075,
        "year": 2012,
        "value": 48.7
    },
    {
        "fips": 39077,
        "year": 2012,
        "value": 53.9
    },
    {
        "fips": 39079,
        "year": 2012,
        "value": 56.7
    },
    {
        "fips": 39081,
        "year": 2012,
        "value": 58.3
    },
    {
        "fips": 39083,
        "year": 2012,
        "value": 55.6
    },
    {
        "fips": 39085,
        "year": 2012,
        "value": 61.4
    },
    {
        "fips": 39087,
        "year": 2012,
        "value": 58.6
    },
    {
        "fips": 39089,
        "year": 2012,
        "value": 58.5
    },
    {
        "fips": 39091,
        "year": 2012,
        "value": 57.6
    },
    {
        "fips": 39093,
        "year": 2012,
        "value": 57.6
    },
    {
        "fips": 39095,
        "year": 2012,
        "value": 58.1
    },
    {
        "fips": 39097,
        "year": 2012,
        "value": 56.2
    },
    {
        "fips": 39099,
        "year": 2012,
        "value": 58
    },
    {
        "fips": 39101,
        "year": 2012,
        "value": 57.8
    },
    {
        "fips": 39103,
        "year": 2012,
        "value": 58.3
    },
    {
        "fips": 39105,
        "year": 2012,
        "value": 58.2
    },
    {
        "fips": 39107,
        "year": 2012,
        "value": 54.1
    },
    {
        "fips": 39109,
        "year": 2012,
        "value": 56.3
    },
    {
        "fips": 39111,
        "year": 2012,
        "value": 58.4
    },
    {
        "fips": 39113,
        "year": 2012,
        "value": 58.7
    },
    {
        "fips": 39115,
        "year": 2012,
        "value": 58.3
    },
    {
        "fips": 39117,
        "year": 2012,
        "value": 54.4
    },
    {
        "fips": 39119,
        "year": 2012,
        "value": 58
    },
    {
        "fips": 39121,
        "year": 2012,
        "value": 54.7
    },
    {
        "fips": 39123,
        "year": 2012,
        "value": 58.3
    },
    {
        "fips": 39125,
        "year": 2012,
        "value": 57.9
    },
    {
        "fips": 39127,
        "year": 2012,
        "value": 56.4
    },
    {
        "fips": 39129,
        "year": 2012,
        "value": 57.9
    },
    {
        "fips": 39131,
        "year": 2012,
        "value": 56.8
    },
    {
        "fips": 39133,
        "year": 2012,
        "value": 59
    },
    {
        "fips": 39135,
        "year": 2012,
        "value": 55.2
    },
    {
        "fips": 39137,
        "year": 2012,
        "value": 55.6
    },
    {
        "fips": 39139,
        "year": 2012,
        "value": 53.8
    },
    {
        "fips": 39141,
        "year": 2012,
        "value": 59.9
    },
    {
        "fips": 39143,
        "year": 2012,
        "value": 56.9
    },
    {
        "fips": 39145,
        "year": 2012,
        "value": 56.9
    },
    {
        "fips": 39147,
        "year": 2012,
        "value": 56.2
    },
    {
        "fips": 39149,
        "year": 2012,
        "value": 55.6
    },
    {
        "fips": 39151,
        "year": 2012,
        "value": 56.5
    },
    {
        "fips": 39153,
        "year": 2012,
        "value": 58.8
    },
    {
        "fips": 39155,
        "year": 2012,
        "value": 57.7
    },
    {
        "fips": 39157,
        "year": 2012,
        "value": 55.1
    },
    {
        "fips": 39159,
        "year": 2012,
        "value": 57
    },
    {
        "fips": 39161,
        "year": 2012,
        "value": 57
    },
    {
        "fips": 39163,
        "year": 2012,
        "value": 57.8
    },
    {
        "fips": 39165,
        "year": 2012,
        "value": 58
    },
    {
        "fips": 39167,
        "year": 2012,
        "value": 58
    },
    {
        "fips": 39169,
        "year": 2012,
        "value": 51.6
    },
    {
        "fips": 39171,
        "year": 2012,
        "value": 61
    },
    {
        "fips": 39173,
        "year": 2012,
        "value": 57.3
    },
    {
        "fips": 39175,
        "year": 2012,
        "value": 57.3
    }
    ]
},
{
    "title": "Number of Farms Raising Different Crops and Livestock (top 10)",
    "name": "number-of-farms",
    "ytitle": "Operations",
    "xtitle": "Year",
    "type": "line2",
    "tags": [
    "agriculture",
    "farm-stress"
    ],
    "data": [
    {
        "All farms": [
        {
            "year": 1997,
            "value": 78737
        },
        {
            "year": 2002,
            "value": 77797
        },
        {
            "year": 2007,
            "value": 75861
        },
        {
            "year": 2012,
            "value": 75462
        },
        {
            "year": 2017,
            "value": 77805
        }
        ],
        "Hay and Haylage": [
        {
            "year": 1997,
            "value": null
        },
        {
            "year": 2002,
            "value": 33939
        },
        {
            "year": 2007,
            "value": 31440
        },
        {
            "year": 2012,
            "value": 32032
        },
        {
            "year": 2017,
            "value": 34230
        }
        ],
        "Soybeans": [
        {
            "year": 1997,
            "value": 29365
        },
        {
            "year": 2002,
            "value": 26327
        },
        {
            "year": 2007,
            "value": 23892
        },
        {
            "year": 2012,
            "value": 24704
        },
        {
            "year": 2017,
            "value": 25636
        }
        ],
        "Corn (grain & silage)": [
        {
            "year": 1997,
            "value": 38446
        },
        {
            "year": 2002,
            "value": 29401
        },
        {
            "year": 2007,
            "value": 28364
        },
        {
            "year": 2012,
            "value": 29017
        },
        {
            "year": 2017,
            "value": 24005
        }
        ],
        "Cow-Calf (Beef)": [
        {
            "year": 1997,
            "value": 19696
        },
        {
            "year": 2002,
            "value": 16104
        },
        {
            "year": 2007,
            "value": 17398
        },
        {
            "year": 2012,
            "value": 16922
        },
        {
            "year": 2017,
            "value": 17733
        }
        ],
        "Poultry (layers & broilers)": [
        {
            "year": 1997,
            "value": 4675
        },
        {
            "year": 2002,
            "value": 5387
        },
        {
            "year": 2007,
            "value": 6046
        },
        {
            "year": 2012,
            "value": 9823
        },
        {
            "year": 2017,
            "value": 11682
        }
        ],
        "Hogs": [
        {
            "year": 1997,
            "value": 6637
        },
        {
            "year": 2002,
            "value": 4286
        },
        {
            "year": 2007,
            "value": 3718
        },
        {
            "year": 2012,
            "value": 3494
        },
        {
            "year": 2017,
            "value": 3484
        }
        ],
        "Wheat": [
        {
            "year": 1997,
            "value": 19134
        },
        {
            "year": 2002,
            "value": 14340
        },
        {
            "year": 2007,
            "value": 11485
        },
        {
            "year": 2012,
            "value": 8639
        },
        {
            "year": 2017,
            "value": 7861
        }
        ],
        "Dairy": [
        {
            "year": 1997,
            "value": 5714
        },
        {
            "year": 2002,
            "value": 4754
        },
        {
            "year": 2007,
            "value": 3650
        },
        {
            "year": 2012,
            "value": 4008
        },
        {
            "year": 2017,
            "value": 3346
        }
        ],
        "Oats": [
        {
            "year": 1997,
            "value": 6186
        },
        {
            "year": 2002,
            "value": 3865
        },
        {
            "year": 2007,
            "value": 2800
        },
        {
            "year": 2012,
            "value": 3160
        },
        {
            "year": 2017,
            "value": 1276
        }
        ]
    }
    ]
},
{
    "title": "Average Daily Lake Surface Temperature 1992-2017 (°C)",
    "name": "daily-temp",
    "ytitle": "Average Surface Temp (°C)",
    "xtitle": "Date",
    "tags": [
    "Water Quality",
    "HABs",
    "cyanobacteria",
    "blue-green algae",
    "toxins",
    "great lakes",
    "habri",
    "habri-multi-model"
    ],
    "type": "line1",
    "data": [
    {
        "Date": [
        "1-Jan",
        "2-Jan",
        "3-Jan",
        "4-Jan",
        "5-Jan",
        "6-Jan",
        "7-Jan",
        "8-Jan",
        "9-Jan",
        "10-Jan",
        "11-Jan",
        "12-Jan",
        "13-Jan",
        "14-Jan",
        "15-Jan",
        "16-Jan",
        "17-Jan",
        "18-Jan",
        "19-Jan",
        "20-Jan",
        "21-Jan",
        "22-Jan",
        "23-Jan",
        "24-Jan",
        "25-Jan",
        "26-Jan",
        "27-Jan",
        "28-Jan",
        "29-Jan",
        "30-Jan",
        "31-Jan",
        "1-Feb",
        "2-Feb",
        "3-Feb",
        "4-Feb",
        "5-Feb",
        "6-Feb",
        "7-Feb",
        "8-Feb",
        "9-Feb",
        "10-Feb",
        "11-Feb",
        "12-Feb",
        "13-Feb",
        "14-Feb",
        "15-Feb",
        "16-Feb",
        "17-Feb",
        "18-Feb",
        "19-Feb",
        "20-Feb",
        "21-Feb",
        "22-Feb",
        "23-Feb",
        "24-Feb",
        "25-Feb",
        "26-Feb",
        "27-Feb",
        "28-Feb",
        "1-Mar",
        "2-Mar",
        "3-Mar",
        "4-Mar",
        "5-Mar",
        "6-Mar",
        "7-Mar",
        "8-Mar",
        "9-Mar",
        "10-Mar",
        "11-Mar",
        "12-Mar",
        "13-Mar",
        "14-Mar",
        "15-Mar",
        "16-Mar",
        "17-Mar",
        "18-Mar",
        "19-Mar",
        "20-Mar",
        "21-Mar",
        "22-Mar",
        "23-Mar",
        "24-Mar",
        "25-Mar",
        "26-Mar",
        "27-Mar",
        "28-Mar",
        "29-Mar",
        "30-Mar",
        "31-Mar",
        "1-Apr",
        "2-Apr",
        "3-Apr",
        "4-Apr",
        "5-Apr",
        "6-Apr",
        "7-Apr",
        "8-Apr",
        "9-Apr",
        "10-Apr",
        "11-Apr",
        "12-Apr",
        "13-Apr",
        "14-Apr",
        "15-Apr",
        "16-Apr",
        "17-Apr",
        "18-Apr",
        "19-Apr",
        "20-Apr",
        "21-Apr",
        "22-Apr",
        "23-Apr",
        "24-Apr",
        "25-Apr",
        "26-Apr",
        "27-Apr",
        "28-Apr",
        "29-Apr",
        "30-Apr",
        "1-May",
        "2-May",
        "3-May",
        "4-May",
        "5-May",
        "6-May",
        "7-May",
        "8-May",
        "9-May",
        "10-May",
        "11-May",
        "12-May",
        "13-May",
        "14-May",
        "15-May",
        "16-May",
        "17-May",
        "18-May",
        "19-May",
        "20-May",
        "21-May",
        "22-May",
        "23-May",
        "24-May",
        "25-May",
        "26-May",
        "27-May",
        "28-May",
        "29-May",
        "30-May",
        "31-May",
        "1-Jun",
        "2-Jun",
        "3-Jun",
        "4-Jun",
        "5-Jun",
        "6-Jun",
        "7-Jun",
        "8-Jun",
        "9-Jun",
        "10-Jun",
        "11-Jun",
        "12-Jun",
        "13-Jun",
        "14-Jun",
        "15-Jun",
        "16-Jun",
        "17-Jun",
        "18-Jun",
        "19-Jun",
        "20-Jun",
        "21-Jun",
        "22-Jun",
        "23-Jun",
        "24-Jun",
        "25-Jun",
        "26-Jun",
        "27-Jun",
        "28-Jun",
        "29-Jun",
        "30-Jun",
        "1-Jul",
        "2-Jul",
        "3-Jul",
        "4-Jul",
        "5-Jul",
        "6-Jul",
        "7-Jul",
        "8-Jul",
        "9-Jul",
        "10-Jul",
        "11-Jul",
        "12-Jul",
        "13-Jul",
        "14-Jul",
        "15-Jul",
        "16-Jul",
        "17-Jul",
        "18-Jul",
        "19-Jul",
        "20-Jul",
        "21-Jul",
        "22-Jul",
        "23-Jul",
        "24-Jul",
        "25-Jul",
        "26-Jul",
        "27-Jul",
        "28-Jul",
        "29-Jul",
        "30-Jul",
        "31-Jul",
        "1-Aug",
        "2-Aug",
        "3-Aug",
        "4-Aug",
        "5-Aug",
        "6-Aug",
        "7-Aug",
        "8-Aug",
        "9-Aug",
        "10-Aug",
        "11-Aug",
        "12-Aug",
        "13-Aug",
        "14-Aug",
        "15-Aug",
        "16-Aug",
        "17-Aug",
        "18-Aug",
        "19-Aug",
        "20-Aug",
        "21-Aug",
        "22-Aug",
        "23-Aug",
        "24-Aug",
        "25-Aug",
        "26-Aug",
        "27-Aug",
        "28-Aug",
        "29-Aug",
        "30-Aug",
        "31-Aug",
        "1-Sep",
        "2-Sep",
        "3-Sep",
        "4-Sep",
        "5-Sep",
        "6-Sep",
        "7-Sep",
        "8-Sep",
        "9-Sep",
        "10-Sep",
        "11-Sep",
        "12-Sep",
        "13-Sep",
        "14-Sep",
        "15-Sep",
        "16-Sep",
        "17-Sep",
        "18-Sep",
        "19-Sep",
        "20-Sep",
        "21-Sep",
        "22-Sep",
        "23-Sep",
        "24-Sep",
        "25-Sep",
        "26-Sep",
        "27-Sep",
        "28-Sep",
        "29-Sep",
        "30-Sep",
        "1-Oct",
        "2-Oct",
        "3-Oct",
        "4-Oct",
        "5-Oct",
        "6-Oct",
        "7-Oct",
        "8-Oct",
        "9-Oct",
        "10-Oct",
        "11-Oct",
        "12-Oct",
        "13-Oct",
        "14-Oct",
        "15-Oct",
        "16-Oct",
        "17-Oct",
        "18-Oct",
        "19-Oct",
        "20-Oct",
        "21-Oct",
        "22-Oct",
        "23-Oct",
        "24-Oct",
        "25-Oct",
        "26-Oct",
        "27-Oct",
        "28-Oct",
        "29-Oct",
        "30-Oct",
        "31-Oct",
        "1-Nov",
        "2-Nov",
        "3-Nov",
        "4-Nov",
        "5-Nov",
        "6-Nov",
        "7-Nov",
        "8-Nov",
        "9-Nov",
        "10-Nov",
        "11-Nov",
        "12-Nov",
        "13-Nov",
        "14-Nov",
        "15-Nov",
        "16-Nov",
        "17-Nov",
        "18-Nov",
        "19-Nov",
        "20-Nov",
        "21-Nov",
        "22-Nov",
        "23-Nov",
        "24-Nov",
        "25-Nov",
        "26-Nov",
        "27-Nov",
        "28-Nov",
        "29-Nov",
        "30-Nov",
        "1-Dec",
        "2-Dec",
        "3-Dec",
        "4-Dec",
        "5-Dec",
        "6-Dec",
        "7-Dec",
        "8-Dec",
        "9-Dec",
        "10-Dec",
        "11-Dec",
        "12-Dec",
        "13-Dec",
        "14-Dec",
        "15-Dec",
        "16-Dec",
        "17-Dec",
        "18-Dec",
        "19-Dec",
        "20-Dec",
        "21-Dec",
        "22-Dec",
        "23-Dec",
        "24-Dec",
        "25-Dec",
        "26-Dec",
        "27-Dec",
        "28-Dec",
        "29-Dec",
        "30-Dec",
        "31-Dec"
        ],
        "Superior": [
        3.135,
        3.0425,
        3.01833,
        2.98208,
        3.00417,
        2.97083,
        2.91083,
        2.89042,
        2.89042,
        2.87042,
        2.84375,
        2.79375,
        2.76,
        2.62292,
        2.57542,
        2.45792,
        2.30667,
        2.28208,
        2.20333,
        2.08625,
        2.015,
        1.93458,
        1.85125,
        1.82167,
        1.78667,
        1.75583,
        1.74375,
        1.68208,
        1.64667,
        1.57792,
        1.55125,
        1.52542,
        1.48375,
        1.47875,
        1.50083,
        1.475,
        1.39042,
        1.4075,
        1.29792,
        1.2975,
        1.26875,
        1.24917,
        1.24167,
        1.21208,
        1.18,
        1.16917,
        1.15625,
        1.11542,
        1.08042,
        1.08667,
        1.05583,
        1.06792,
        1.04875,
        1.05458,
        1.03958,
        1.0275,
        1.02542,
        0.974583,
        0.98625,
        0.97125,
        0.9204,
        0.9024,
        0.9008,
        0.9372,
        0.864,
        0.8592,
        0.848,
        0.8848,
        0.938,
        0.912308,
        0.934615,
        0.978462,
        0.995385,
        0.892692,
        0.887692,
        0.946,
        0.9632,
        0.934231,
        0.934615,
        0.918077,
        0.933077,
        0.957308,
        0.993077,
        1.01308,
        0.947308,
        0.936538,
        0.997692,
        0.979231,
        1.0024,
        0.9552,
        0.9148,
        0.9364,
        0.9716,
        1.0424,
        1.058,
        1.1316,
        1.1624,
        1.156,
        1.116,
        1.15423,
        1.2212,
        1.262,
        1.3275,
        1.34375,
        1.33,
        1.4524,
        1.4732,
        1.5528,
        1.4988,
        1.5712,
        1.52808,
        1.5956,
        1.50538,
        1.63462,
        1.64962,
        1.71,
        1.76038,
        1.81962,
        1.81731,
        1.90962,
        1.92923,
        2.01923,
        2.06615,
        2.10269,
        2.19769,
        2.27038,
        2.29462,
        2.30962,
        2.36462,
        2.43038,
        2.48154,
        2.51038,
        2.555,
        2.66615,
        2.69808,
        2.75462,
        2.81231,
        2.905,
        2.91923,
        2.99077,
        3.05731,
        3.14308,
        3.30846,
        3.42923,
        3.53269,
        3.57462,
        3.73308,
        3.73654,
        3.925,
        4.01654,
        4.01538,
        3.99885,
        4.14346,
        4.20269,
        4.27038,
        4.35385,
        4.41346,
        4.49923,
        4.68462,
        4.78615,
        4.92731,
        5.04731,
        5.22962,
        5.25577,
        5.48654,
        5.55385,
        5.72923,
        5.95539,
        6.03039,
        6.11423,
        6.25769,
        6.28615,
        6.54269,
        6.84962,
        7.10192,
        7.24269,
        7.45269,
        7.42962,
        7.64038,
        7.98,
        8.04423,
        7.91231,
        7.90692,
        8.25539,
        8.30154,
        8.40885,
        8.72077,
        9.09731,
        9.35808,
        9.59462,
        9.78423,
        10.0785,
        10.3608,
        10.7015,
        10.905,
        11.06,
        11.2208,
        11.4731,
        11.7615,
        11.9569,
        12.1738,
        12.4323,
        12.635,
        12.8296,
        13.1254,
        13.1927,
        13.5554,
        13.4369,
        13.5681,
        13.7927,
        13.995,
        14.0662,
        14.2823,
        14.3881,
        14.6908,
        14.9277,
        14.8354,
        14.9842,
        15.315,
        15.4235,
        15.4735,
        15.4965,
        15.6977,
        15.7181,
        15.9312,
        15.8742,
        15.8881,
        15.7492,
        15.6077,
        15.5712,
        15.7081,
        15.7623,
        15.5446,
        15.5673,
        15.5738,
        15.5546,
        15.7408,
        15.8562,
        15.7881,
        15.7923,
        15.7877,
        15.7935,
        15.8354,
        15.92,
        15.8946,
        15.8308,
        15.8423,
        15.7423,
        15.6792,
        15.5312,
        15.4365,
        15.4146,
        15.1846,
        14.9927,
        14.8081,
        14.6358,
        14.4665,
        14.3008,
        14.1954,
        14.0927,
        13.9127,
        13.8381,
        13.6662,
        13.54,
        13.4165,
        13.3012,
        13.1227,
        13.0335,
        12.8619,
        12.7915,
        12.6469,
        12.3669,
        12.2431,
        12.0973,
        11.8569,
        11.7504,
        11.5612,
        11.3992,
        11.2804,
        11.16,
        10.97,
        10.7512,
        10.6027,
        10.4931,
        10.3204,
        10.1804,
        10.0192,
        9.775,
        9.62192,
        9.50077,
        9.37923,
        9.25577,
        9.13231,
        9.00923,
        8.82115,
        8.78039,
        8.695,
        8.51852,
        8.42148,
        8.37815,
        8.22148,
        8.0137,
        7.90333,
        7.92259,
        7.80889,
        7.76593,
        7.70889,
        7.59444,
        7.39481,
        7.38074,
        7.29593,
        7.19074,
        7.01,
        6.92852,
        6.77444,
        6.68704,
        6.65778,
        6.57111,
        6.47148,
        6.47963,
        6.45481,
        6.41074,
        6.34926,
        6.10037,
        5.95222,
        5.86741,
        5.80444,
        5.78889,
        5.6063,
        5.52111,
        5.46444,
        5.4037,
        5.34111,
        5.28333,
        5.07,
        5.02741,
        4.98111,
        4.70889,
        4.64963,
        4.5963,
        4.52963,
        4.51333,
        4.41593,
        4.34,
        4.30259,
        4.28444,
        4.24296,
        4.11074,
        4.09518,
        4.02852,
        3.97185,
        3.93852,
        3.90148,
        3.83778,
        3.77259,
        3.78148,
        3.61889,
        3.62259,
        3.59407,
        3.53037,
        3.47667,
        3.37593,
        3.33111,
        3.20518,
        3.03179
        ],
        "Michigan": [
        3.95417,
        3.8025,
        3.75625,
        3.6975,
        3.68167,
        3.54875,
        3.44625,
        3.42167,
        3.41542,
        3.36042,
        3.29208,
        3.23667,
        3.15667,
        3.01542,
        2.97792,
        2.82625,
        2.81208,
        2.74583,
        2.57167,
        2.51417,
        2.45625,
        2.39875,
        2.34708,
        2.28167,
        2.34833,
        2.26792,
        2.3175,
        2.19875,
        2.16292,
        2.0308,
        1.8924,
        1.8808,
        1.8412,
        1.8432,
        1.8188,
        1.8016,
        1.7116,
        1.6716,
        1.6452,
        1.5884,
        1.5572,
        1.5728,
        1.6248,
        1.5592,
        1.5664,
        1.602,
        1.6328,
        1.4772,
        1.4896,
        1.5372,
        1.6,
        1.5708,
        1.5256,
        1.54,
        1.4988,
        1.5156,
        1.5072,
        1.482,
        1.5,
        1.4676,
        1.432,
        1.4096,
        1.4276,
        1.4668,
        1.4872,
        1.484,
        1.5176,
        1.5192,
        1.5632,
        1.53962,
        1.43462,
        1.49615,
        1.57385,
        1.60385,
        1.595,
        1.64038,
        1.67962,
        1.73077,
        1.72577,
        1.72731,
        1.75654,
        1.85308,
        1.90115,
        1.91654,
        1.915,
        1.85808,
        1.93808,
        1.93,
        1.96462,
        1.97038,
        1.96808,
        2.00231,
        1.96692,
        2.00385,
        2.02846,
        2.16615,
        2.32423,
        2.37154,
        2.42846,
        2.46038,
        2.42385,
        2.44154,
        2.49577,
        2.555,
        2.585,
        2.71192,
        2.79769,
        2.85731,
        2.87577,
        2.97192,
        3.04038,
        3.03654,
        3.16962,
        3.285,
        3.40577,
        3.46692,
        3.48115,
        3.65846,
        3.70423,
        3.77385,
        3.95846,
        3.98385,
        4.01769,
        4.32808,
        4.45692,
        4.52885,
        4.63231,
        4.67538,
        4.66539,
        4.815,
        5.03654,
        5.14962,
        5.24461,
        5.43,
        5.47577,
        5.52923,
        5.74577,
        5.855,
        6.30962,
        6.38731,
        6.64308,
        6.82038,
        6.90539,
        7.10846,
        7.47615,
        7.59962,
        7.86077,
        8.21923,
        8.36154,
        8.59038,
        8.79539,
        8.87731,
        8.92846,
        8.95461,
        9.22,
        9.48385,
        9.83615,
        10.1708,
        10.6223,
        10.8181,
        11.025,
        11.4638,
        11.5262,
        11.995,
        12.3623,
        12.4885,
        12.6415,
        13.0965,
        13.4308,
        13.5208,
        13.9892,
        14.2815,
        14.3512,
        14.7231,
        15.0231,
        15.2158,
        15.4527,
        15.6115,
        15.8096,
        16.0473,
        16.3065,
        16.3715,
        16.2854,
        16.4219,
        16.7492,
        16.9004,
        17.0638,
        17.3054,
        17.6546,
        17.85,
        17.9396,
        18.1196,
        18.4,
        18.6719,
        19.0004,
        19.1865,
        19.4288,
        19.4562,
        19.7104,
        19.7304,
        19.7438,
        19.9158,
        20.0419,
        20.0665,
        20.2985,
        20.1785,
        20.2354,
        20.1788,
        20.2965,
        20.4527,
        20.5819,
        20.7973,
        20.7312,
        20.9308,
        21.0135,
        21.1512,
        20.9131,
        21.0212,
        21.1842,
        21.3242,
        21.3338,
        21.32,
        21.2269,
        21.0827,
        21.2177,
        21.1712,
        21.2042,
        21.1546,
        21.1673,
        20.9981,
        21.0381,
        20.9735,
        20.8485,
        20.8338,
        20.7308,
        20.7008,
        20.6542,
        20.9092,
        20.7892,
        20.7915,
        20.8788,
        20.7765,
        20.735,
        20.6396,
        20.6642,
        20.7285,
        20.7004,
        20.6823,
        20.4931,
        20.2281,
        20.0712,
        19.985,
        19.8077,
        19.6077,
        19.4396,
        19.2335,
        19.1046,
        19.0904,
        18.8565,
        18.5188,
        18.2042,
        18.1627,
        18.0831,
        17.98,
        17.7888,
        17.6,
        17.4677,
        17.3227,
        17.2138,
        17.0704,
        16.7862,
        16.53,
        16.3373,
        16.1373,
        15.9208,
        15.7942,
        15.5104,
        15.3831,
        15.2615,
        15.0458,
        14.8962,
        14.6646,
        14.6615,
        14.3969,
        14.1504,
        13.8962,
        13.6535,
        13.3996,
        13.2304,
        13.1512,
        12.9962,
        12.8935,
        12.6827,
        12.5246,
        12.3435,
        12.1915,
        12.0569,
        11.9215,
        11.843,
        11.7433,
        11.4248,
        11.1707,
        10.9,
        10.8641,
        10.6407,
        10.5337,
        10.4389,
        10.2678,
        10.1711,
        10.0089,
        9.89296,
        9.79,
        9.57407,
        9.48407,
        9.40704,
        9.14148,
        8.93926,
        8.79,
        8.70481,
        8.60407,
        8.48259,
        8.30518,
        8.20555,
        8.05111,
        7.95185,
        7.78704,
        7.68593,
        7.58296,
        7.49,
        7.35074,
        7.26667,
        7.17074,
        6.98519,
        6.80704,
        6.74037,
        6.74444,
        6.64963,
        6.2763,
        6.17667,
        6.07333,
        6.00444,
        5.90889,
        5.78111,
        5.69111,
        5.61148,
        5.51037,
        5.35407,
        5.28111,
        5.14074,
        5.02704,
        4.94037,
        4.88518,
        4.87,
        4.78815,
        4.77963,
        4.73296,
        4.63667,
        4.57296,
        4.5237,
        4.39741,
        4.39259,
        4.30222,
        4.22518,
        4.11481,
        3.88107
        ],
        "Huron": [
        3.76833,
        3.655,
        3.65,
        3.56542,
        3.48667,
        3.41417,
        3.33,
        3.02875,
        2.98042,
        2.77583,
        2.74292,
        2.65083,
        2.615,
        2.54583,
        2.515,
        2.40583,
        2.25333,
        2.22375,
        2.14125,
        2.11458,
        2.00167,
        1.94083,
        1.82042,
        1.79292,
        1.81917,
        1.78083,
        1.7775,
        1.70958,
        1.66833,
        1.47,
        1.436,
        1.4428,
        1.3932,
        1.3576,
        1.2932,
        1.2368,
        1.2252,
        1.2224,
        1.1704,
        1.1184,
        1.1016,
        1.0428,
        1.026,
        0.9608,
        0.9304,
        0.9372,
        0.9344,
        0.9348,
        0.8696,
        0.892,
        0.88,
        0.8956,
        0.8392,
        0.86,
        0.8392,
        0.834,
        0.83,
        0.7844,
        0.7856,
        0.7648,
        0.774,
        0.7532,
        0.746,
        0.7428,
        0.7636,
        0.7732,
        0.7664,
        0.7876,
        0.818,
        0.778462,
        0.785385,
        0.831154,
        0.813462,
        0.818461,
        0.838077,
        0.831923,
        0.852308,
        0.898846,
        0.918462,
        0.948846,
        0.968077,
        0.955385,
        0.976538,
        0.953077,
        0.995385,
        0.953077,
        0.994231,
        1.06192,
        1.05769,
        1.06923,
        0.993461,
        1.01885,
        1.12038,
        1.20538,
        1.22846,
        1.25346,
        1.30077,
        1.33231,
        1.35731,
        1.465,
        1.49462,
        1.52577,
        1.65231,
        1.68423,
        1.76538,
        1.87769,
        1.89192,
        1.96692,
        2.01885,
        2.09423,
        2.16962,
        2.28308,
        2.40077,
        2.40769,
        2.49885,
        2.565,
        2.66923,
        2.76192,
        2.87577,
        2.98385,
        3.12269,
        3.24308,
        3.40538,
        3.55692,
        3.69692,
        3.84308,
        3.82385,
        3.97846,
        4.00731,
        4.16846,
        4.16923,
        4.37462,
        4.49577,
        4.60115,
        5.05538,
        5.09154,
        4.97962,
        5.18385,
        5.545,
        5.67846,
        5.80654,
        5.96846,
        6.08,
        6.23039,
        6.56846,
        6.86269,
        7.10269,
        7.27077,
        7.53192,
        7.78923,
        7.90192,
        7.90269,
        8.07885,
        7.98885,
        8.25346,
        8.43462,
        9.08,
        9.20615,
        9.26038,
        9.56231,
        9.765,
        10.17,
        10.3865,
        10.7619,
        11.175,
        11.4485,
        11.615,
        12.0138,
        12.2958,
        12.3762,
        12.7046,
        12.7496,
        13.2685,
        13.5215,
        13.7623,
        14.2915,
        14.3119,
        14.5154,
        14.8123,
        14.9992,
        15.0946,
        15.2619,
        15.1204,
        15.3342,
        15.5665,
        15.8296,
        15.9877,
        16.1781,
        16.3138,
        16.4231,
        16.515,
        16.7658,
        17.0785,
        17.3708,
        17.76,
        17.8446,
        17.895,
        18.0958,
        18.2846,
        18.3273,
        18.4323,
        18.4435,
        18.7885,
        18.8365,
        18.9069,
        18.8846,
        18.915,
        19.045,
        19.17,
        19.3004,
        19.5562,
        19.7342,
        19.7715,
        19.8581,
        19.8892,
        19.8708,
        19.7396,
        19.7138,
        19.7288,
        19.9296,
        19.9208,
        19.9227,
        19.9192,
        19.9504,
        19.9412,
        19.9892,
        19.9658,
        19.9965,
        19.8238,
        19.9965,
        19.9615,
        19.8385,
        19.7712,
        19.6162,
        19.4142,
        19.4735,
        19.4415,
        19.5785,
        19.6369,
        19.5473,
        19.5546,
        19.4735,
        19.3838,
        19.4242,
        19.4173,
        19.4435,
        19.3692,
        19.2877,
        19.2142,
        19.0135,
        19.0273,
        18.9381,
        18.8423,
        18.4923,
        18.385,
        18.1958,
        18.1196,
        17.9988,
        17.9258,
        17.5688,
        17.4885,
        17.4565,
        17.3177,
        17.2142,
        17.0408,
        16.8377,
        16.7365,
        16.6704,
        16.5777,
        16.4146,
        16.2015,
        15.965,
        15.7212,
        15.4369,
        15.25,
        15.1131,
        14.8569,
        14.6104,
        14.5273,
        14.3038,
        14.2208,
        14.1338,
        14.05,
        13.8465,
        13.6792,
        13.405,
        13.24,
        12.9485,
        12.7462,
        12.4788,
        12.3554,
        12.2,
        11.9923,
        11.8454,
        11.6815,
        11.5604,
        11.3223,
        11.1667,
        10.9389,
        10.8463,
        10.6393,
        10.4993,
        10.3033,
        10.2267,
        10.1374,
        10.0189,
        9.94555,
        9.8463,
        9.66741,
        9.54444,
        9.39148,
        9.27296,
        9.18444,
        9.03296,
        8.87074,
        8.63889,
        8.56407,
        8.46185,
        8.33037,
        8.23148,
        7.95593,
        7.84519,
        7.76667,
        7.66852,
        7.54963,
        7.4,
        7.29185,
        7.2437,
        7.16852,
        7.08778,
        7.01222,
        6.91444,
        6.77407,
        6.66074,
        6.57444,
        6.53222,
        6.44852,
        6.2737,
        6.02444,
        5.91556,
        5.78296,
        5.67519,
        5.52444,
        5.41333,
        5.28667,
        5.23111,
        5.08593,
        5.02519,
        4.89259,
        4.76259,
        4.68556,
        4.5863,
        4.54037,
        4.53296,
        4.44074,
        4.43185,
        4.35815,
        4.31593,
        4.25259,
        4.2037,
        4.11111,
        3.99148,
        3.90074,
        3.80889,
        3.57643
        ],
        "Erie": [
        3.01792,
        2.79708,
        2.76375,
        2.6875,
        2.56125,
        2.4775,
        2.40292,
        2.27375,
        2.25292,
        2.18458,
        2.20333,
        2.20042,
        2.08292,
        2.00083,
        1.96167,
        1.85083,
        1.805,
        1.75708,
        1.68792,
        1.56167,
        1.43667,
        1.38417,
        1.35667,
        1.33458,
        1.36458,
        1.30542,
        1.17333,
        1.13833,
        1.14125,
        1.08542,
        1.09792,
        1.12333,
        1.09542,
        1.08125,
        1.07708,
        0.98875,
        1.01625,
        1.02458,
        1.00292,
        0.936,
        0.9444,
        0.916,
        0.9136,
        0.8592,
        0.8192,
        0.8176,
        0.854,
        0.8864,
        0.8488,
        0.902,
        0.8704,
        0.8875,
        0.890417,
        0.872917,
        0.779583,
        0.804583,
        0.821667,
        0.8425,
        0.840417,
        0.842917,
        0.834583,
        0.832917,
        0.84625,
        0.87125,
        0.90375,
        0.879167,
        0.944167,
        0.959167,
        0.966667,
        0.9924,
        0.8408,
        0.8724,
        0.8892,
        0.8568,
        0.9164,
        0.9788,
        1.0316,
        1.0548,
        1.0888,
        1.1228,
        1.2168,
        1.2408,
        1.2896,
        1.3384,
        1.4472,
        1.4968,
        1.564,
        1.6836,
        1.6824,
        1.828,
        1.9556,
        1.9896,
        2.0488,
        2.1404,
        2.3216,
        2.576,
        2.7776,
        2.8752,
        2.9692,
        3.1032,
        3.1076,
        3.26808,
        3.25423,
        3.405,
        3.65731,
        4.07192,
        4.17538,
        4.37,
        4.54269,
        4.64115,
        4.75,
        4.93115,
        5.11731,
        5.27923,
        5.47,
        5.59923,
        5.70269,
        5.84923,
        6.00269,
        6.27539,
        6.48231,
        6.87654,
        7.06846,
        7.35885,
        7.54692,
        7.98539,
        8.26115,
        8.765,
        8.92192,
        9.03308,
        9.17,
        9.25115,
        9.53423,
        9.81231,
        10.3288,
        10.3819,
        10.4273,
        10.5865,
        10.9327,
        11.1731,
        11.3442,
        11.8292,
        12.0285,
        12.1577,
        12.5058,
        12.8465,
        13.3581,
        13.5669,
        14.0558,
        14.3954,
        14.5765,
        14.4562,
        14.6219,
        14.5942,
        14.9327,
        14.925,
        15.0954,
        15.5573,
        15.7915,
        16.1754,
        16.3662,
        16.5227,
        16.7469,
        16.9327,
        17.2962,
        17.7627,
        18.0146,
        18.2731,
        18.5896,
        18.8162,
        19.1708,
        19.1696,
        19.5673,
        19.6323,
        20.0319,
        20.3127,
        20.345,
        20.4631,
        20.7077,
        20.8969,
        20.9304,
        20.9888,
        21.0862,
        21.1254,
        21.2735,
        21.3288,
        21.4896,
        21.6604,
        21.8869,
        22.0019,
        21.8723,
        21.9708,
        22.1762,
        22.4127,
        22.765,
        22.8585,
        23.1162,
        22.8962,
        23.0042,
        22.9896,
        23.1954,
        23.1408,
        23.1108,
        23.2112,
        23.1615,
        23.1246,
        23.1242,
        23.1335,
        23.1838,
        23.3977,
        23.4815,
        23.6085,
        23.7665,
        23.7546,
        23.8869,
        23.9238,
        23.8827,
        23.6569,
        23.725,
        23.7135,
        23.705,
        23.6827,
        23.7108,
        23.5765,
        23.6585,
        23.7019,
        23.695,
        23.575,
        23.5611,
        23.5181,
        23.505,
        23.3308,
        23.2923,
        23.1942,
        23.2127,
        23.1115,
        23.0077,
        22.9404,
        23.1873,
        22.9996,
        23.1177,
        23.0562,
        22.9242,
        22.9162,
        22.9288,
        22.9515,
        22.8819,
        22.8519,
        22.8212,
        22.6888,
        22.6092,
        22.2415,
        21.9923,
        21.6808,
        21.5781,
        21.3973,
        21.3592,
        21.2858,
        21.3965,
        21.1588,
        20.9677,
        20.64,
        20.6438,
        20.4762,
        20.3246,
        20.0577,
        19.9665,
        19.7627,
        19.6892,
        19.5777,
        19.4,
        19.2185,
        18.9923,
        18.605,
        18.485,
        18.3162,
        17.9408,
        17.9931,
        17.7796,
        17.7012,
        17.5419,
        17.4192,
        17.3862,
        17.2342,
        16.9015,
        16.6331,
        16.5015,
        16.1858,
        15.9354,
        15.7873,
        15.6746,
        15.4492,
        15.1785,
        14.9819,
        14.7388,
        14.5085,
        14.3819,
        14.1967,
        14.0004,
        13.8907,
        13.6111,
        13.4533,
        13.1163,
        12.9359,
        12.74,
        12.5367,
        12.4804,
        12.3233,
        12.0548,
        11.9774,
        11.8741,
        11.7804,
        11.55,
        11.4185,
        11.267,
        11.1256,
        10.9152,
        10.7463,
        10.5133,
        10.2781,
        10.0396,
        9.59185,
        9.46667,
        9.33444,
        9.19889,
        9.00926,
        8.9263,
        8.78815,
        8.65445,
        8.56815,
        8.38482,
        8.22889,
        8.06815,
        7.85519,
        7.64815,
        7.55148,
        7.39296,
        7.11556,
        6.76593,
        6.56926,
        6.39259,
        6.29037,
        6.13185,
        5.99778,
        5.87148,
        5.76222,
        5.60963,
        5.48259,
        5.25482,
        5.06852,
        4.76333,
        4.49222,
        4.45778,
        4.35037,
        4.23333,
        4.17148,
        4.11111,
        4.06296,
        3.85741,
        3.81852,
        3.70815,
        3.56704,
        3.44963,
        3.20333,
        3.0225
        ],
        "Ontario": [
        4.26458,
        4.21333,
        4.24458,
        4.16375,
        4.07,
        4.03167,
        3.83917,
        3.69292,
        3.69458,
        3.58792,
        3.53083,
        3.50125,
        3.44625,
        3.26292,
        3.1425,
        3.04958,
        3.00583,
        2.975,
        2.92458,
        2.80875,
        2.67,
        2.68542,
        2.58042,
        2.52042,
        2.50417,
        2.43792,
        2.40542,
        2.34625,
        2.34458,
        2.222,
        2.1932,
        2.2048,
        2.17,
        2.1652,
        2.188,
        2.1344,
        2.12,
        2.1284,
        2.0676,
        2.1048,
        2.0284,
        1.9968,
        1.9512,
        1.868,
        1.8092,
        1.8008,
        1.7784,
        1.788,
        1.7372,
        1.736,
        1.7584,
        1.7424,
        1.7808,
        1.6816,
        1.6724,
        1.7336,
        1.7308,
        1.702,
        1.6908,
        1.6404,
        1.6316,
        1.6484,
        1.632,
        1.6084,
        1.6092,
        1.6412,
        1.6476,
        1.6728,
        1.7044,
        1.65962,
        1.70769,
        1.72962,
        1.73692,
        1.76769,
        1.77769,
        1.78615,
        1.75962,
        1.83885,
        1.87192,
        1.88462,
        1.86923,
        1.93077,
        1.99923,
        2.02923,
        2.05808,
        2.00077,
        2.01846,
        2.10538,
        2.17654,
        2.20769,
        2.17423,
        2.18538,
        2.26231,
        2.33269,
        2.33769,
        2.27731,
        2.38115,
        2.40154,
        2.51769,
        2.58192,
        2.56962,
        2.69269,
        2.72154,
        2.80269,
        2.85077,
        3.09923,
        3.10269,
        3.07962,
        3.11462,
        3.24423,
        3.36385,
        3.42269,
        3.56846,
        3.69654,
        3.56,
        3.655,
        3.70038,
        3.82115,
        3.97192,
        4.00961,
        4.40308,
        4.55808,
        4.69654,
        4.73846,
        4.89846,
        5.02692,
        5.31692,
        5.48038,
        5.74846,
        5.63615,
        5.75654,
        5.76192,
        5.87038,
        5.97538,
        6.35231,
        6.47231,
        6.51154,
        6.66654,
        6.88308,
        7.12769,
        7.36846,
        7.50808,
        7.83923,
        8.05308,
        8.38731,
        8.71654,
        9.17846,
        9.60385,
        9.915,
        10.4258,
        10.5488,
        10.3623,
        10.3519,
        10.3981,
        10.5704,
        10.6685,
        10.9227,
        11.6892,
        12.07,
        12.1119,
        12.3292,
        12.7627,
        13.0858,
        13.5192,
        13.8058,
        14.2577,
        14.5423,
        14.6496,
        14.8742,
        15.355,
        15.6123,
        15.7804,
        15.9604,
        16.2169,
        16.4485,
        16.9146,
        17.3185,
        17.2573,
        17.7665,
        17.7323,
        17.8242,
        18.0085,
        17.9535,
        18.0758,
        18.5362,
        18.5162,
        18.6581,
        18.7604,
        18.8973,
        19.1004,
        19.1623,
        19.1585,
        19.2835,
        19.7515,
        20.0081,
        20.1146,
        20.3292,
        20.5027,
        20.6638,
        20.6719,
        20.7485,
        20.575,
        21.0115,
        21.1858,
        21.1192,
        21.0635,
        21.3123,
        21.355,
        21.0777,
        21.2842,
        21.4627,
        21.6081,
        21.9488,
        21.6612,
        21.9577,
        22.3065,
        22.2696,
        22.0196,
        21.8481,
        21.9138,
        22.0592,
        22.105,
        22.1073,
        22.0196,
        22.0385,
        22.0062,
        22.0812,
        22.0362,
        22.07,
        21.9565,
        21.7985,
        21.7385,
        21.7127,
        21.6327,
        21.4392,
        21.3873,
        21.365,
        21.2565,
        21.4815,
        21.3812,
        21.3819,
        21.3627,
        21.3823,
        21.3123,
        21.3365,
        21.2162,
        21.3081,
        21.2531,
        21.1858,
        21.1096,
        20.8938,
        20.7862,
        20.5462,
        20.1854,
        19.9462,
        19.7465,
        19.6077,
        19.5927,
        19.5338,
        19.3465,
        19.1681,
        18.9542,
        18.8962,
        18.8412,
        18.715,
        18.3612,
        18.1938,
        18.1469,
        18.0454,
        17.8812,
        17.6896,
        17.4446,
        17.3492,
        16.9631,
        16.8327,
        16.4235,
        16.0538,
        15.9312,
        15.7569,
        15.5492,
        15.305,
        15.1369,
        14.99,
        14.9177,
        14.6358,
        14.5492,
        14.3235,
        14.1135,
        13.8777,
        13.5692,
        13.3012,
        13.0969,
        12.86,
        12.7265,
        12.4185,
        12.3123,
        12.1769,
        12.0226,
        11.8004,
        11.7052,
        11.4948,
        11.2711,
        11.0304,
        10.8448,
        10.7437,
        10.5726,
        10.4296,
        10.2963,
        10.0533,
        9.91407,
        9.65333,
        9.62148,
        9.57704,
        9.41667,
        9.34704,
        9.20222,
        9.04407,
        8.91259,
        8.76296,
        8.61889,
        8.55296,
        8.40778,
        8.33185,
        8.2463,
        8.1637,
        8.01482,
        7.86185,
        7.65074,
        7.56296,
        7.50704,
        7.41296,
        7.29741,
        7.22778,
        7.11185,
        7.03407,
        6.93852,
        6.78556,
        6.35667,
        6.23,
        6.19,
        6.10296,
        6.16074,
        6.06259,
        5.96148,
        5.77852,
        5.76593,
        5.62741,
        5.53741,
        5.46778,
        5.26222,
        5.21963,
        5.09,
        5.05815,
        5.01704,
        4.89222,
        4.85852,
        4.78889,
        4.72,
        4.66815,
        4.56852,
        4.46037,
        4.45852,
        4.42074,
        4.30889,
        4.07893
        ]
    }
    ]
}
]

export const clusterdata = [
{
    "Id": 1,
    "county": 1,
    "fips": 39001,
    "countyName": "Adams",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 20724948.6,
    "employment": 1211,
    "laborIncome": 6278211.6,
    "taxProductionImports": 573585.55,
    "laborOutputPerWorker": 919453.95,
    "laborIncomePerWorker": 120890.77,
    "totalEconomy": 772089382.5,
    "totalEmployment": 11275,
    "totalIncome": 426750073.1
},
{
    "Id": 2,
    "county": 2,
    "fips": 39003,
    "countyName": "Allen",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 58796482.28,
    "employment": 1522,
    "laborIncome": 19989400.61,
    "taxProductionImports": 1368617.22,
    "laborOutputPerWorker": 2251454.53,
    "laborIncomePerWorker": 305395.68,
    "totalEconomy": 6350020522,
    "totalEmployment": 64919,
    "totalIncome": 3399766857
},
{
    "Id": 3,
    "county": 3,
    "fips": 39005,
    "countyName": "Ashland",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 51481257.32,
    "employment": 1305,
    "laborIncome": 15227575.22,
    "taxProductionImports": 1520941.12,
    "laborOutputPerWorker": 3139603.01,
    "laborIncomePerWorker": 580160.08,
    "totalEconomy": 1836074497,
    "totalEmployment": 27170,
    "totalIncome": 1122453349
},
{
    "Id": 4,
    "county": 4,
    "fips": 39007,
    "countyName": "Ashtabula",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 50484676.62,
    "employment": 1450,
    "laborIncome": 26705036.82,
    "taxProductionImports": 52914.17,
    "laborOutputPerWorker": 1675590.64,
    "laborIncomePerWorker": 832697.43,
    "totalEconomy": 2997219359,
    "totalEmployment": 43560,
    "totalIncome": 1935704511
},
{
    "Id": 5,
    "county": 5,
    "fips": 39009,
    "countyName": "Athens",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 8216930.51,
    "employment": 737,
    "laborIncome": 2826023.63,
    "taxProductionImports": 432282.65,
    "laborOutputPerWorker": 687340.11,
    "laborIncomePerWorker": 48205.96,
    "totalEconomy": 2331026444,
    "totalEmployment": 33223,
    "totalIncome": 1502390067
},
{
    "Id": 6,
    "county": 6,
    "fips": 39011,
    "countyName": "Auglaize",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 54951247.02,
    "employment": 1370,
    "laborIncome": 18842787.01,
    "taxProductionImports": 1263600.66,
    "laborOutputPerWorker": 2077949.88,
    "laborIncomePerWorker": 357685.96,
    "totalEconomy": 2247296725,
    "totalEmployment": 29514,
    "totalIncome": 1369993614
},
{
    "Id": 7,
    "county": 7,
    "fips": 39013,
    "countyName": "Belmont",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 51528122.46,
    "employment": 886,
    "laborIncome": 23032868.99,
    "taxProductionImports": 3047858.29,
    "laborOutputPerWorker": 2171413.17,
    "laborIncomePerWorker": 462492.34,
    "totalEconomy": 3015496326,
    "totalEmployment": 29382,
    "totalIncome": 1406956624
},
{
    "Id": 8,
    "county": 8,
    "fips": 39015,
    "countyName": "Brown",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 26886729.63,
    "employment": 1318,
    "laborIncome": -5371948.74,
    "taxProductionImports": -276251.66,
    "laborOutputPerWorker": 922393.28,
    "laborIncomePerWorker": 104224.57,
    "totalEconomy": 1071946185,
    "totalEmployment": 14511,
    "totalIncome": 571744808.5
},
{
    "Id": 9,
    "county": 9,
    "fips": 39017,
    "countyName": "Butler",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 48492308.02,
    "employment": 1493,
    "laborIncome": 30584693.68,
    "taxProductionImports": 1143841,
    "laborOutputPerWorker": 1610938.93,
    "laborIncomePerWorker": 487418.56,
    "totalEconomy": 20552990901,
    "totalEmployment": 199829,
    "totalIncome": 11591941308
},
{
    "Id": 10,
    "county": 10,
    "fips": 39019,
    "countyName": "Carroll",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 23604467.48,
    "employment": 740,
    "laborIncome": 17109904.65,
    "taxProductionImports": 522188.69,
    "laborOutputPerWorker": 2117305.21,
    "laborIncomePerWorker": 1242177.18,
    "totalEconomy": 862990141.3,
    "totalEmployment": 9551,
    "totalIncome": 458153660.6
},
{
    "Id": 11,
    "county": 11,
    "fips": 39021,
    "countyName": "Champaign",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 26840614.18,
    "employment": 1022,
    "laborIncome": 20757483.19,
    "taxProductionImports": -819052.75,
    "laborOutputPerWorker": 1656434.7,
    "laborIncomePerWorker": 704681.4,
    "totalEconomy": 1087178910,
    "totalEmployment": 15593,
    "totalIncome": 667716303.8
},
{
    "Id": 12,
    "county": 12,
    "fips": 39023,
    "countyName": "Clark",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 39385784.86,
    "employment": 1256,
    "laborIncome": 5473200.49,
    "taxProductionImports": -653565.46,
    "laborOutputPerWorker": 1514851.01,
    "laborIncomePerWorker": 222755.39,
    "totalEconomy": 4813529892,
    "totalEmployment": 64071,
    "totalIncome": 2856164845
},
{
    "Id": 13,
    "county": 13,
    "fips": 39025,
    "countyName": "Clermont",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 37029091.12,
    "employment": 1678,
    "laborIncome": 19478693,
    "taxProductionImports": 422080.3,
    "laborOutputPerWorker": 855423.31,
    "laborIncomePerWorker": 299043.72,
    "totalEconomy": 7930048779,
    "totalEmployment": 86826,
    "totalIncome": 5187923395
},
{
    "Id": 14,
    "county": 14,
    "fips": 39027,
    "countyName": "Clinton",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 36888227.74,
    "employment": 979,
    "laborIncome": 8870947.82,
    "taxProductionImports": -1444298.29,
    "laborOutputPerWorker": 2433374.88,
    "laborIncomePerWorker": 993856.36,
    "totalEconomy": 2072033914,
    "totalEmployment": 24537,
    "totalIncome": 1369308036
},
{
    "Id": 15,
    "county": 15,
    "fips": 39029,
    "countyName": "Columbiana",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 41766023.38,
    "employment": 1277,
    "laborIncome": 24757997.98,
    "taxProductionImports": 1219864.17,
    "laborOutputPerWorker": 1724225.25,
    "laborIncomePerWorker": 717388.03,
    "totalEconomy": 2941970375,
    "totalEmployment": 41828,
    "totalIncome": 1769291010
},
{
    "Id": 16,
    "county": 16,
    "fips": 39031,
    "countyName": "Coshocton",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 42140264.72,
    "employment": 1321,
    "laborIncome": 15238828.34,
    "taxProductionImports": 1495992.73,
    "laborOutputPerWorker": 2482025.99,
    "laborIncomePerWorker": 899937.9,
    "totalEconomy": 1227308569,
    "totalEmployment": 15327,
    "totalIncome": 642310756.8
},
{
    "Id": 17,
    "county": 17,
    "fips": 39033,
    "countyName": "Crawford",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 42160878.56,
    "employment": 962,
    "laborIncome": 5052031.58,
    "taxProductionImports": -619577.86,
    "laborOutputPerWorker": 2187579.79,
    "laborIncomePerWorker": 540263.33,
    "totalEconomy": 1280629829,
    "totalEmployment": 18676,
    "totalIncome": 747739350.2
},
{
    "Id": 18,
    "county": 18,
    "fips": 39035,
    "countyName": "Cuyahoga",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 99457926.75,
    "employment": 1512,
    "laborIncome": 86618943.8,
    "taxProductionImports": 2023403.79,
    "laborOutputPerWorker": 2841741.3,
    "laborIncomePerWorker": 1435656.6,
    "totalEconomy": 98230712685,
    "totalEmployment": 932945,
    "totalIncome": 59079357896
},
{
    "Id": 19,
    "county": 19,
    "fips": 39037,
    "countyName": "Darke",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 165249710.7,
    "employment": 2615,
    "laborIncome": 14083942.31,
    "taxProductionImports": 5318047.25,
    "laborOutputPerWorker": 3091373.43,
    "laborIncomePerWorker": 513722.49,
    "totalEconomy": 1974102343,
    "totalEmployment": 28585,
    "totalIncome": 1160664339
},
{
    "Id": 20,
    "county": 20,
    "fips": 39039,
    "countyName": "Defiance",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 26542462.74,
    "employment": 1035,
    "laborIncome": 16588238.72,
    "taxProductionImports": -581367.3,
    "laborOutputPerWorker": 1371711.68,
    "laborIncomePerWorker": 640778.7,
    "totalEconomy": 1680173365,
    "totalEmployment": 22146,
    "totalIncome": 1020365474
},
{
    "Id": 21,
    "county": 21,
    "fips": 39041,
    "countyName": "Delaware",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 56332870.99,
    "employment": 1536,
    "laborIncome": 24304725.64,
    "taxProductionImports": 135160.68,
    "laborOutputPerWorker": 1808369.48,
    "laborIncomePerWorker": 216155.73,
    "totalEconomy": 11318626744,
    "totalEmployment": 129342,
    "totalIncome": 7315658575
},
{
    "Id": 22,
    "county": 22,
    "fips": 39043,
    "countyName": "Erie",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 49151488.19,
    "employment": 1348,
    "laborIncome": 24754504.66,
    "taxProductionImports": 2675447.58,
    "laborOutputPerWorker": 2482392.1,
    "laborIncomePerWorker": 719610.22,
    "totalEconomy": 3708532706,
    "totalEmployment": 48765,
    "totalIncome": 2427949119
},
{
    "Id": 23,
    "county": 23,
    "fips": 39045,
    "countyName": "Fairfield",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 39681335.85,
    "employment": 1645,
    "laborIncome": 17292162.61,
    "taxProductionImports": 289938.79,
    "laborOutputPerWorker": 1135463.14,
    "laborIncomePerWorker": 215286.71,
    "totalEconomy": 4299720609,
    "totalEmployment": 62378,
    "totalIncome": 2655340612
},
{
    "Id": 24,
    "county": 24,
    "fips": 39047,
    "countyName": "Fayette",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 27827821.41,
    "employment": 662,
    "laborIncome": 8020602.82,
    "taxProductionImports": -1504557.71,
    "laborOutputPerWorker": 2472422.52,
    "laborIncomePerWorker": 1331376.62,
    "totalEconomy": 1038213944,
    "totalEmployment": 15528,
    "totalIncome": 613819989
},
{
    "Id": 25,
    "county": 25,
    "fips": 39049,
    "countyName": "Franklin",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 175871793.8,
    "employment": 5730,
    "laborIncome": 139533584,
    "taxProductionImports": 7368995.09,
    "laborOutputPerWorker": 2662977.07,
    "laborIncomePerWorker": 345042.65,
    "totalEconomy": 101631000000,
    "totalEmployment": 985452,
    "totalIncome": 61241000156
},
{
    "Id": 26,
    "county": 26,
    "fips": 39051,
    "countyName": "Fulton",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 60523253.53,
    "employment": 1389,
    "laborIncome": 31916040.17,
    "taxProductionImports": 465189.19,
    "laborOutputPerWorker": 2736481.1,
    "laborIncomePerWorker": 947122.09,
    "totalEconomy": 1911471665,
    "totalEmployment": 27064,
    "totalIncome": 1234435682
},
{
    "Id": 27,
    "county": 27,
    "fips": 39053,
    "countyName": "Gallia",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 9410496.62,
    "employment": 826,
    "laborIncome": 1518135.66,
    "taxProductionImports": 494973.37,
    "laborOutputPerWorker": 1145932.13,
    "laborIncomePerWorker": 352803.97,
    "totalEconomy": 1405262413,
    "totalEmployment": 16585,
    "totalIncome": 666350227.5
},
{
    "Id": 28,
    "county": 28,
    "fips": 39055,
    "countyName": "Geauga",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 88727569.72,
    "employment": 1631,
    "laborIncome": 81159707.26,
    "taxProductionImports": 1637828.89,
    "laborOutputPerWorker": 2969233.72,
    "laborIncomePerWorker": 1745418.66,
    "totalEconomy": 4251446767.41,
    "totalEmployment": 56764,
    "totalIncome": 2759920046.41
},
{
    "Id": 29,
    "county": 29,
    "fips": 39057,
    "countyName": "Greene",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 34127701.02,
    "employment": 1265,
    "laborIncome": 11578101.06,
    "taxProductionImports": -518418.05,
    "laborOutputPerWorker": 1215044.82,
    "laborIncomePerWorker": 348956.58,
    "totalEconomy": 10294610205,
    "totalEmployment": 100744,
    "totalIncome": 6417793082
},
{
    "Id": 30,
    "county": 30,
    "fips": 39059,
    "countyName": "Guernsey",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 26878613.59,
    "employment": 1080,
    "laborIncome": 14719083.68,
    "taxProductionImports": 786416.38,
    "laborOutputPerWorker": 3081747.02,
    "laborIncomePerWorker": 1000602.55,
    "totalEconomy": 1666634109,
    "totalEmployment": 19522,
    "totalIncome": 933574143.5
},
{
    "Id": 31,
    "county": 31,
    "fips": 39061,
    "countyName": "Hamilton",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 118064828.9,
    "employment": 1747,
    "laborIncome": 86434084.31,
    "taxProductionImports": 3907044.72,
    "laborOutputPerWorker": 3567362.54,
    "laborIncomePerWorker": 677022.07,
    "totalEconomy": 76878446431,
    "totalEmployment": 666388,
    "totalIncome": 44702718608
},
{
    "Id": 32,
    "county": 32,
    "fips": 39063,
    "countyName": "Hancock",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 38446179.73,
    "employment": 1216,
    "laborIncome": 10161822.79,
    "taxProductionImports": -1291004.63,
    "laborOutputPerWorker": 1487841.9,
    "laborIncomePerWorker": 249641.93,
    "totalEconomy": 5824095294,
    "totalEmployment": 57110,
    "totalIncome": 4084603099
},
{
    "Id": 33,
    "county": 33,
    "fips": 39065,
    "countyName": "Hardin",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 55259698.82,
    "employment": 1228,
    "laborIncome": 12445585.26,
    "taxProductionImports": 182342.2,
    "laborOutputPerWorker": 1978593.58,
    "laborIncomePerWorker": 365123.76,
    "totalEconomy": 847103726,
    "totalEmployment": 13997,
    "totalIncome": 524322364.7
},
{
    "Id": 34,
    "county": 34,
    "fips": 39067,
    "countyName": "Harrison",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 8575387.17,
    "employment": 382,
    "laborIncome": 2942506.65,
    "taxProductionImports": 354756.04,
    "laborOutputPerWorker": 4344142.84,
    "laborIncomePerWorker": 2815618.32,
    "totalEconomy": 502705042.8,
    "totalEmployment": 5841,
    "totalIncome": 286179102.6
},
{
    "Id": 35,
    "county": 35,
    "fips": 39069,
    "countyName": "Henry",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 34857998.5,
    "employment": 1192,
    "laborIncome": 24009884.81,
    "taxProductionImports": -1554325.98,
    "laborOutputPerWorker": 1605123.3,
    "laborIncomePerWorker": 513536.75,
    "totalEconomy": 1247305512,
    "totalEmployment": 16138,
    "totalIncome": 748819861.5
},
{
    "Id": 36,
    "county": 36,
    "fips": 39071,
    "countyName": "Highland",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 35465498.31,
    "employment": 1513,
    "laborIncome": 12704822.19,
    "taxProductionImports": -450670.03,
    "laborOutputPerWorker": 1339488.76,
    "laborIncomePerWorker": 419843.11,
    "totalEconomy": 1070900775,
    "totalEmployment": 17098,
    "totalIncome": 644200573.1
},
{
    "Id": 37,
    "county": 37,
    "fips": 39073,
    "countyName": "Hocking",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 7757812.61,
    "employment": 518,
    "laborIncome": 1845010.29,
    "taxProductionImports": 358002.57,
    "laborOutputPerWorker": 635490.42,
    "laborIncomePerWorker": 60908.27,
    "totalEconomy": 619360916.6,
    "totalEmployment": 9730,
    "totalIncome": 367045192.2
},
{
    "Id": 38,
    "county": 38,
    "fips": 39075,
    "countyName": "Holmes",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 135353176.7,
    "employment": 2477,
    "laborIncome": 67324372.26,
    "taxProductionImports": 4076883.3,
    "laborOutputPerWorker": 2612779.71,
    "laborIncomePerWorker": 898076.1,
    "totalEconomy": 2287136910,
    "totalEmployment": 29518,
    "totalIncome": 1574698025
},
{
    "Id": 39,
    "county": 39,
    "fips": 39077,
    "countyName": "Huron",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 55563631.5,
    "employment": 1559,
    "laborIncome": 28457299.45,
    "taxProductionImports": -513642.58,
    "laborOutputPerWorker": 2034208.5,
    "laborIncomePerWorker": 541947.57,
    "totalEconomy": 2310117902,
    "totalEmployment": 30071,
    "totalIncome": 1384087245
},
{
    "Id": 40,
    "county": 40,
    "fips": 39079,
    "countyName": "Jackson",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 30205506.47,
    "employment": 496,
    "laborIncome": 14380769.36,
    "taxProductionImports": 595755.39,
    "laborOutputPerWorker": 1882508.61,
    "laborIncomePerWorker": 511454.21,
    "totalEconomy": 1066268771,
    "totalEmployment": 13672,
    "totalIncome": 605102941.2
},
{
    "Id": 41,
    "county": 41,
    "fips": 39081,
    "countyName": "Jefferson",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 7836310.24,
    "employment": 446,
    "laborIncome": 3863495.27,
    "taxProductionImports": 303595.66,
    "laborOutputPerWorker": 1088879.01,
    "laborIncomePerWorker": 238158.93,
    "totalEconomy": 2459146057,
    "totalEmployment": 27836,
    "totalIncome": 1235636377
},
{
    "Id": 42,
    "county": 42,
    "fips": 39083,
    "countyName": "Knox",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 38474578.64,
    "employment": 1858,
    "laborIncome": 7157786.1,
    "taxProductionImports": 696452.11,
    "laborOutputPerWorker": 1155715.42,
    "laborIncomePerWorker": 112787.48,
    "totalEconomy": 2176950488,
    "totalEmployment": 31101,
    "totalIncome": 1399347525
},
{
    "Id": 43,
    "county": 43,
    "fips": 39085,
    "countyName": "Lake",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 81428948.3,
    "employment": 1283,
    "laborIncome": 59521209.98,
    "taxProductionImports": 595010.21,
    "laborOutputPerWorker": 3414857.31,
    "laborIncomePerWorker": 1063299.02,
    "totalEconomy": 10783727637,
    "totalEmployment": 120447,
    "totalIncome": 6118984543
},
{
    "Id": 44,
    "county": 44,
    "fips": 39087,
    "countyName": "Lawrence",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 4829844.53,
    "employment": 624,
    "laborIncome": 1461517.47,
    "taxProductionImports": 305837.15,
    "laborOutputPerWorker": 532697.09,
    "laborIncomePerWorker": 79968.71,
    "totalEconomy": 1181459510,
    "totalEmployment": 18385,
    "totalIncome": 715224830.7
},
{
    "Id": 45,
    "county": 45,
    "fips": 39089,
    "countyName": "Licking",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 63588355.49,
    "employment": 2298,
    "laborIncome": 30423621.98,
    "taxProductionImports": 708677.1,
    "laborOutputPerWorker": 1278676.44,
    "laborIncomePerWorker": 347929.16,
    "totalEconomy": 6315127600,
    "totalEmployment": 75777,
    "totalIncome": 3590380801
},
{
    "Id": 46,
    "county": 46,
    "fips": 39091,
    "countyName": "Logan",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 32892056.33,
    "employment": 1011,
    "laborIncome": 14596538.35,
    "taxProductionImports": -1022129.03,
    "laborOutputPerWorker": 1735198.11,
    "laborIncomePerWorker": 890942.06,
    "totalEconomy": 2144681238,
    "totalEmployment": 27606,
    "totalIncome": 1308083081
},
{
    "Id": 47,
    "county": 47,
    "fips": 39093,
    "countyName": "Lorain",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 119281986.5,
    "employment": 2071,
    "laborIncome": 69697494.52,
    "taxProductionImports": 483317.15,
    "laborOutputPerWorker": 2503739.68,
    "laborIncomePerWorker": 412093.61,
    "totalEconomy": 10824808084,
    "totalEmployment": 131092,
    "totalIncome": 6415398605
},
{
    "Id": 48,
    "county": 48,
    "fips": 39095,
    "countyName": "Lucas",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 79228405.47,
    "employment": 1206,
    "laborIncome": 46568088.96,
    "taxProductionImports": 1052290.13,
    "laborOutputPerWorker": 3233029.14,
    "laborIncomePerWorker": 894019.18,
    "totalEconomy": 25868535825,
    "totalEmployment": 262159,
    "totalIncome": 15037401755
},
{
    "Id": 49,
    "county": 49,
    "fips": 39097,
    "countyName": "Madison",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 51711606.67,
    "employment": 1102,
    "laborIncome": 33778677.43,
    "taxProductionImports": -814149.03,
    "laborOutputPerWorker": 2521465.38,
    "laborIncomePerWorker": 1187467.51,
    "totalEconomy": 1617403686,
    "totalEmployment": 24015,
    "totalIncome": 1104300860
},
{
    "Id": 50,
    "county": 50,
    "fips": 39099,
    "countyName": "Mahoning",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 28891229.97,
    "employment": 863,
    "laborIncome": 13077398.59,
    "taxProductionImports": 743625.43,
    "laborOutputPerWorker": 1341038.08,
    "laborIncomePerWorker": 289711.27,
    "totalEconomy": 9252062834,
    "totalEmployment": 128652,
    "totalIncome": 5901968922
},
{
    "Id": 51,
    "county": 51,
    "fips": 39101,
    "countyName": "Marion",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 68461926.37,
    "employment": 908,
    "laborIncome": 31892454.51,
    "taxProductionImports": 1473263.4,
    "laborOutputPerWorker": 4442339.92,
    "laborIncomePerWorker": 1141572.77,
    "totalEconomy": 2561183084,
    "totalEmployment": 30887,
    "totalIncome": 1514307774
},
{
    "Id": 52,
    "county": 52,
    "fips": 39103,
    "countyName": "Medina",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 51294896.12,
    "employment": 1622,
    "laborIncome": 28893545.54,
    "taxProductionImports": 1710051.53,
    "laborOutputPerWorker": 3127298.09,
    "laborIncomePerWorker": 693170.25,
    "totalEconomy": 6902232322,
    "totalEmployment": 87891,
    "totalIncome": 4102452844
},
{
    "Id": 53,
    "county": 53,
    "fips": 39105,
    "countyName": "Meigs",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 9554216.07,
    "employment": 546,
    "laborIncome": 2495139.34,
    "taxProductionImports": 247331.25,
    "laborOutputPerWorker": 1066482.03,
    "laborIncomePerWorker": 240573.07,
    "totalEconomy": 375095333.3,
    "totalEmployment": 6038,
    "totalIncome": 197384782.6
},
{
    "Id": 54,
    "county": 54,
    "fips": 39107,
    "countyName": "Mercer",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 222427130.1,
    "employment": 2203,
    "laborIncome": 47942084.29,
    "taxProductionImports": 8889968.66,
    "laborOutputPerWorker": 5543915.98,
    "laborIncomePerWorker": 1510389.93,
    "totalEconomy": 2167186984,
    "totalEmployment": 30960,
    "totalIncome": 1307607927
},
{
    "Id": 55,
    "county": 55,
    "fips": 39109,
    "countyName": "Miami",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 31602099.9,
    "employment": 1675,
    "laborIncome": 11861842.16,
    "taxProductionImports": -805429.14,
    "laborOutputPerWorker": 801369.25,
    "laborIncomePerWorker": 186050.44,
    "totalEconomy": 4161975386,
    "totalEmployment": 56906,
    "totalIncome": 2545471788
},
{
    "Id": 56,
    "county": 56,
    "fips": 39111,
    "countyName": "Monroe",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 8314860.16,
    "employment": 690,
    "laborIncome": 2239849.25,
    "taxProductionImports": 516458.93,
    "laborOutputPerWorker": 851835.66,
    "laborIncomePerWorker": 129825.39,
    "totalEconomy": 290990164.5,
    "totalEmployment": 5409,
    "totalIncome": 152715827.6
},
{
    "Id": 57,
    "county": 57,
    "fips": 39113,
    "countyName": "Montgomery",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 56040861.62,
    "employment": 1596,
    "laborIncome": 46727971.31,
    "taxProductionImports": 677692.45,
    "laborOutputPerWorker": 1198310.73,
    "laborIncomePerWorker": 456363.31,
    "totalEconomy": 29088032810,
    "totalEmployment": 325604,
    "totalIncome": 17822734429
},
{
    "Id": 58,
    "county": 58,
    "fips": 39115,
    "countyName": "Morgan",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 8852746.75,
    "employment": 475,
    "laborIncome": 3746200.12,
    "taxProductionImports": 383755.56,
    "laborOutputPerWorker": 1899102.23,
    "laborIncomePerWorker": 991942.06,
    "totalEconomy": 284846059.3,
    "totalEmployment": 4558,
    "totalIncome": 168010389.7
},
{
    "Id": 59,
    "county": 59,
    "fips": 39117,
    "countyName": "Morrow",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 31134491.75,
    "employment": 1145,
    "laborIncome": 4400029.49,
    "taxProductionImports": -182157.28,
    "laborOutputPerWorker": 1325564.15,
    "laborIncomePerWorker": 240642.65,
    "totalEconomy": 609587559.4,
    "totalEmployment": 10407,
    "totalIncome": 363893591
},
{
    "Id": 60,
    "county": 60,
    "fips": 39119,
    "countyName": "Muskingum",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 25554994.05,
    "employment": 1255,
    "laborIncome": 10387372.67,
    "taxProductionImports": 1054823.34,
    "laborOutputPerWorker": 1397162.12,
    "laborIncomePerWorker": 406670.66,
    "totalEconomy": 3780755649,
    "totalEmployment": 46983,
    "totalIncome": 2016031014
},
{
    "Id": 61,
    "county": 61,
    "fips": 39121,
    "countyName": "Noble",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 8945297.84,
    "employment": 465,
    "laborIncome": 3904628.39,
    "taxProductionImports": 369452.33,
    "laborOutputPerWorker": 2092474.17,
    "laborIncomePerWorker": 1304638.75,
    "totalEconomy": 358758386.4,
    "totalEmployment": 4888,
    "totalIncome": 204161956.9
},
{
    "Id": 62,
    "county": 62,
    "fips": 39123,
    "countyName": "Ottawa",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 34811686.77,
    "employment": 862,
    "laborIncome": 12834728.5,
    "taxProductionImports": -269526.75,
    "laborOutputPerWorker": 1510276.73,
    "laborIncomePerWorker": 344979.34,
    "totalEconomy": 1882522665,
    "totalEmployment": 20665,
    "totalIncome": 910078674.8
},
{
    "Id": 63,
    "county": 63,
    "fips": 39125,
    "countyName": "Paulding",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 38206116.68,
    "employment": 1074,
    "laborIncome": 27306263.34,
    "taxProductionImports": -163649.88,
    "laborOutputPerWorker": 1957636.72,
    "laborIncomePerWorker": 1369944.99,
    "totalEconomy": 473398160.2,
    "totalEmployment": 7618,
    "totalIncome": 278671835.9
},
{
    "Id": 64,
    "county": 64,
    "fips": 39127,
    "countyName": "Perry",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 15705761.76,
    "employment": 669,
    "laborIncome": 3693780.25,
    "taxProductionImports": -105498.85,
    "laborOutputPerWorker": 1660628.73,
    "laborIncomePerWorker": 523025.77,
    "totalEconomy": 702597434.6,
    "totalEmployment": 9792,
    "totalIncome": 415826629.5
},
{
    "Id": 65,
    "county": 65,
    "fips": 39129,
    "countyName": "Pickaway",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 50799428.29,
    "employment": 1311,
    "laborIncome": 49469968.75,
    "taxProductionImports": -664763.73,
    "laborOutputPerWorker": 1708618.76,
    "laborIncomePerWorker": 800467.44,
    "totalEconomy": 1591171161,
    "totalEmployment": 21056,
    "totalIncome": 1030079339
},
{
    "Id": 66,
    "county": 66,
    "fips": 39131,
    "countyName": "Pike",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 12259920.82,
    "employment": 505,
    "laborIncome": 7456116.91,
    "taxProductionImports": 6500.01,
    "laborOutputPerWorker": 1159233.76,
    "laborIncomePerWorker": 358198.75,
    "totalEconomy": 1070334939,
    "totalEmployment": 13035,
    "totalIncome": 711041714.1
},
{
    "Id": 67,
    "county": 67,
    "fips": 39133,
    "countyName": "Portage",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 31862729.12,
    "employment": 1262,
    "laborIncome": 17779623.33,
    "taxProductionImports": 382270.25,
    "laborOutputPerWorker": 1455457.41,
    "laborIncomePerWorker": 676341.62,
    "totalEconomy": 6778445655,
    "totalEmployment": 83265,
    "totalIncome": 3923076961
},
{
    "Id": 68,
    "county": 68,
    "fips": 39135,
    "countyName": "Preble",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 43017437.34,
    "employment": 1529,
    "laborIncome": 30158147.29,
    "taxProductionImports": 397682.38,
    "laborOutputPerWorker": 1538448.75,
    "laborIncomePerWorker": 637947.1,
    "totalEconomy": 1120245977,
    "totalEmployment": 18013,
    "totalIncome": 687144010.4
},
{
    "Id": 69,
    "county": 69,
    "fips": 39137,
    "countyName": "Putnam",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 119773035.9,
    "employment": 2066,
    "laborIncome": 63102376.16,
    "taxProductionImports": 2696673.98,
    "laborOutputPerWorker": 2896431.61,
    "laborIncomePerWorker": 1013934.88,
    "totalEconomy": 1271105089,
    "totalEmployment": 19591,
    "totalIncome": 768528314.1
},
{
    "Id": 70,
    "county": 70,
    "fips": 39139,
    "countyName": "Richland",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 50583220.9,
    "employment": 1417,
    "laborIncome": 24464281.92,
    "taxProductionImports": 1005265.08,
    "laborOutputPerWorker": 2050323.92,
    "laborIncomePerWorker": 662271.4,
    "totalEconomy": 4550594470,
    "totalEmployment": 64075,
    "totalIncome": 2871087954
},
{
    "Id": 71,
    "county": 71,
    "fips": 39141,
    "countyName": "Ross",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 30068460.97,
    "employment": 1271,
    "laborIncome": 14595350.54,
    "taxProductionImports": -362498.55,
    "laborOutputPerWorker": 1261540.91,
    "laborIncomePerWorker": 584830.32,
    "totalEconomy": 2866885847,
    "totalEmployment": 35579,
    "totalIncome": 1822260275
},
{
    "Id": 72,
    "county": 72,
    "fips": 39143,
    "countyName": "Sandusky",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 42910322.99,
    "employment": 1075,
    "laborIncome": 12564254.56,
    "taxProductionImports": -1020783.35,
    "laborOutputPerWorker": 2128251.18,
    "laborIncomePerWorker": 468778.14,
    "totalEconomy": 2760828151,
    "totalEmployment": 34118,
    "totalIncome": 1631045090
},
{
    "Id": 73,
    "county": 73,
    "fips": 39145,
    "countyName": "Scioto",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 25738941.7,
    "employment": 838,
    "laborIncome": 5811089.4,
    "taxProductionImports": 596418.06,
    "laborOutputPerWorker": 1212753.75,
    "laborIncomePerWorker": 126234.19,
    "totalEconomy": 2228435683,
    "totalEmployment": 32475,
    "totalIncome": 1448223374
},
{
    "Id": 74,
    "county": 74,
    "fips": 39147,
    "countyName": "Seneca",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 43223730.29,
    "employment": 1333,
    "laborIncome": 6383595.19,
    "taxProductionImports": -779754.01,
    "laborOutputPerWorker": 2228522.92,
    "laborIncomePerWorker": 461203.39,
    "totalEconomy": 1917097420,
    "totalEmployment": 28075,
    "totalIncome": 1159588109
},
{
    "Id": 75,
    "county": 75,
    "fips": 39149,
    "countyName": "Shelby",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 68666683.82,
    "employment": 1326,
    "laborIncome": 28192227.29,
    "taxProductionImports": 1489992.6,
    "laborOutputPerWorker": 3559664.1,
    "laborIncomePerWorker": 1070419.21,
    "totalEconomy": 3010714574,
    "totalEmployment": 35325,
    "totalIncome": 1945896711
},
{
    "Id": 76,
    "county": 76,
    "fips": 39151,
    "countyName": "Stark",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 69681406.26,
    "employment": 1965,
    "laborIncome": 52586512.76,
    "taxProductionImports": 1884998.09,
    "laborOutputPerWorker": 1791941.9,
    "laborIncomePerWorker": 660553.22,
    "totalEconomy": 16828271608,
    "totalEmployment": 204971,
    "totalIncome": 9883919732
},
{
    "Id": 77,
    "county": 77,
    "fips": 39153,
    "countyName": "Summit",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 71311619.72,
    "employment": 1330,
    "laborIncome": 48985772.95,
    "taxProductionImports": 2011227.43,
    "laborOutputPerWorker": 2543305.67,
    "laborIncomePerWorker": 704502.65,
    "totalEconomy": 30391421246,
    "totalEmployment": 345561,
    "totalIncome": 18797876030
},
{
    "Id": 78,
    "county": 78,
    "fips": 39155,
    "countyName": "Trumbull",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 62363157.98,
    "employment": 1350,
    "laborIncome": 37274450.31,
    "taxProductionImports": 712727.35,
    "laborOutputPerWorker": 2827473.55,
    "laborIncomePerWorker": 1081586.04,
    "totalEconomy": 7160996203,
    "totalEmployment": 94922,
    "totalIncome": 4297839867
},
{
    "Id": 79,
    "county": 79,
    "fips": 39157,
    "countyName": "Tuscarawas",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 64331638.51,
    "employment": 1315,
    "laborIncome": 42098084.74,
    "taxProductionImports": 1708612.07,
    "laborOutputPerWorker": 2993550.93,
    "laborIncomePerWorker": 2205324.51,
    "totalEconomy": 4776581038,
    "totalEmployment": 48918,
    "totalIncome": 2565518783
},
{
    "Id": 80,
    "county": 80,
    "fips": 39159,
    "countyName": "Union",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 417213712.3,
    "employment": 2892,
    "laborIncome": 196052521.8,
    "taxProductionImports": 15435952.66,
    "laborOutputPerWorker": 2863710.52,
    "laborIncomePerWorker": 589514.65,
    "totalEconomy": 4766480779,
    "totalEmployment": 41726,
    "totalIncome": 2725568550
},
{
    "Id": 81,
    "county": 81,
    "fips": 39161,
    "countyName": "Van Wert",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 59591787.1,
    "employment": 947,
    "laborIncome": 59033601.38,
    "taxProductionImports": 313107.41,
    "laborOutputPerWorker": 3854896.31,
    "laborIncomePerWorker": 1926818.42,
    "totalEconomy": 1122580674,
    "totalEmployment": 14777,
    "totalIncome": 678450141.6
},
{
    "Id": 82,
    "county": 82,
    "fips": 39163,
    "countyName": "Vinton",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 15339516.9,
    "employment": 302,
    "laborIncome": 4326929,
    "taxProductionImports": 229701.12,
    "laborOutputPerWorker": 939142.69,
    "laborIncomePerWorker": 247139.66,
    "totalEconomy": 224168469.3,
    "totalEmployment": 3335,
    "totalIncome": 143160957.9
},
{
    "Id": 83,
    "county": 83,
    "fips": 39165,
    "countyName": "Warren",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 44851083.08,
    "employment": 1691,
    "laborIncome": 22792851.25,
    "taxProductionImports": 281241.54,
    "laborOutputPerWorker": 884059.91,
    "laborIncomePerWorker": 143681.62,
    "totalEconomy": 11287597988,
    "totalEmployment": 120380,
    "totalIncome": 6753727556
},
{
    "Id": 84,
    "county": 84,
    "fips": 39167,
    "countyName": "Washington",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 22940530.52,
    "employment": 1517,
    "laborIncome": 11434393.59,
    "taxProductionImports": 1136731.67,
    "laborOutputPerWorker": 1415318.4,
    "laborIncomePerWorker": 238430.39,
    "totalEconomy": 4211696286,
    "totalEmployment": 33761,
    "totalIncome": 1707603612
},
{
    "Id": 85,
    "county": 85,
    "fips": 39169,
    "countyName": "Wayne",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 298889100.4,
    "employment": 3331,
    "laborIncome": 128540560.4,
    "taxProductionImports": 10440783.95,
    "laborOutputPerWorker": 4509327.81,
    "laborIncomePerWorker": 1276913.47,
    "totalEconomy": 5986535399,
    "totalEmployment": 70092,
    "totalIncome": 3543366070
},
{
    "Id": 86,
    "county": 86,
    "fips": 39171,
    "countyName": "Williams",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 25999885.47,
    "employment": 1140,
    "laborIncome": 12820259.77,
    "taxProductionImports": -500933.35,
    "laborOutputPerWorker": 1669879.31,
    "laborIncomePerWorker": 927250.19,
    "totalEconomy": 1715547890,
    "totalEmployment": 23696,
    "totalIncome": 1078124701
},
{
    "Id": 87,
    "county": 87,
    "fips": 39173,
    "countyName": "Wood",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 73533152.7,
    "employment": 1930,
    "laborIncome": 41590403.4,
    "taxProductionImports": -1816918.61,
    "laborOutputPerWorker": 2085769.98,
    "laborIncomePerWorker": 559378.19,
    "totalEconomy": 7168113759,
    "totalEmployment": 85611,
    "totalIncome": 4481666605
},
{
    "Id": 88,
    "county": 88,
    "fips": 39175,
    "countyName": "Wyandot",
    "indicator": "agricultural production",
    "year": 2017,
    "valueAdded": 54944413.75,
    "employment": 862,
    "laborIncome": 22213232.56,
    "taxProductionImports": -332013.08,
    "laborOutputPerWorker": 4910098,
    "laborIncomePerWorker": 2133823.86,
    "totalEconomy": 1020010896,
    "totalEmployment": 13535,
    "totalIncome": 607656573.6
},
{
    "Id": 89,
    "county": 1,
    "fips": 39001,
    "countyName": "Adams",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 9123947.28,
    "employment": 211,
    "laborIncome": 6208932.24,
    "taxProductionImports": 1423699.08,
    "laborOutputPerWorker": 3736056.33,
    "laborIncomePerWorker": 253593.68,
    "totalEconomy": 772089382.5,
    "totalEmployment": 11275,
    "totalIncome": 426750073.1
},
{
    "Id": 90,
    "county": 2,
    "fips": 39003,
    "countyName": "Allen",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 163013508.9,
    "employment": 1693,
    "laborIncome": 86968146.26,
    "taxProductionImports": 15280748.57,
    "laborOutputPerWorker": 4684690.93,
    "laborIncomePerWorker": 541467.21,
    "totalEconomy": 6350020522,
    "totalEmployment": 64919,
    "totalIncome": 3399766857
},
{
    "Id": 91,
    "county": 3,
    "fips": 39005,
    "countyName": "Ashland",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 74992928.87,
    "employment": 661,
    "laborIncome": 49668465.27,
    "taxProductionImports": 4162477.15,
    "laborOutputPerWorker": 7020535.62,
    "laborIncomePerWorker": 912935.08,
    "totalEconomy": 1836074497,
    "totalEmployment": 27170,
    "totalIncome": 1122453349
},
{
    "Id": 92,
    "county": 4,
    "fips": 39007,
    "countyName": "Ashtabula",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 76013517.16,
    "employment": 1005,
    "laborIncome": 47983439.12,
    "taxProductionImports": 5754406.25,
    "laborOutputPerWorker": 5997377.45,
    "laborIncomePerWorker": 517622.43,
    "totalEconomy": 2997219359,
    "totalEmployment": 43560,
    "totalIncome": 1935704511
},
{
    "Id": 93,
    "county": 5,
    "fips": 39009,
    "countyName": "Athens",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 6354199.18,
    "employment": 105,
    "laborIncome": 2096220.09,
    "taxProductionImports": 3017973.85,
    "laborOutputPerWorker": 2352474.03,
    "laborIncomePerWorker": 148956.73,
    "totalEconomy": 2331026444,
    "totalEmployment": 33223,
    "totalIncome": 1502390067
},
{
    "Id": 94,
    "county": 6,
    "fips": 39011,
    "countyName": "Auglaize",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 162647928.9,
    "employment": 925,
    "laborIncome": 73853808.52,
    "taxProductionImports": 6903361.97,
    "laborOutputPerWorker": 9305691.68,
    "laborIncomePerWorker": 1011075.65,
    "totalEconomy": 2247296725,
    "totalEmployment": 29514,
    "totalIncome": 1369993614
},
{
    "Id": 95,
    "county": 7,
    "fips": 39013,
    "countyName": "Belmont",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 35336906.11,
    "employment": 398,
    "laborIncome": 18279963.09,
    "taxProductionImports": 4157195.24,
    "laborOutputPerWorker": 4090918.09,
    "laborIncomePerWorker": 399278.19,
    "totalEconomy": 3015496326,
    "totalEmployment": 29382,
    "totalIncome": 1406956624
},
{
    "Id": 96,
    "county": 8,
    "fips": 39015,
    "countyName": "Brown",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 11368553.86,
    "employment": 169,
    "laborIncome": 6722261.76,
    "taxProductionImports": 1558745.21,
    "laborOutputPerWorker": 4421779.05,
    "laborIncomePerWorker": 402133.04,
    "totalEconomy": 1071946185,
    "totalEmployment": 14511,
    "totalIncome": 571744808.5
},
{
    "Id": 97,
    "county": 9,
    "fips": 39017,
    "countyName": "Butler",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 918881763.4,
    "employment": 4340,
    "laborIncome": 319149375,
    "taxProductionImports": 92273513.07,
    "laborOutputPerWorker": 15283341.96,
    "laborIncomePerWorker": 2881265.87,
    "totalEconomy": 20552990901,
    "totalEmployment": 199829,
    "totalIncome": 11591941308
},
{
    "Id": 98,
    "county": 10,
    "fips": 39019,
    "countyName": "Carroll",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 9778298.13,
    "employment": 106,
    "laborIncome": 4309968.98,
    "taxProductionImports": 1921681.7,
    "laborOutputPerWorker": 6334194.75,
    "laborIncomePerWorker": 226592.79,
    "totalEconomy": 862990141.3,
    "totalEmployment": 9551,
    "totalIncome": 458153660.6
},
{
    "Id": 99,
    "county": 11,
    "fips": 39021,
    "countyName": "Champaign",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 45861833.63,
    "employment": 428,
    "laborIncome": 27914817.52,
    "taxProductionImports": 2345817.33,
    "laborOutputPerWorker": 4335872.95,
    "laborIncomePerWorker": 510591.37,
    "totalEconomy": 1087178910,
    "totalEmployment": 15593,
    "totalIncome": 667716303.8
},
{
    "Id": 100,
    "county": 12,
    "fips": 39023,
    "countyName": "Clark",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 138094308.8,
    "employment": 1363,
    "laborIncome": 83773326.21,
    "taxProductionImports": 9990344.61,
    "laborOutputPerWorker": 6001152.66,
    "laborIncomePerWorker": 869395.1,
    "totalEconomy": 4813529892,
    "totalEmployment": 64071,
    "totalIncome": 2856164845
},
{
    "Id": 101,
    "county": 13,
    "fips": 39025,
    "countyName": "Clermont",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 84103855.11,
    "employment": 1170,
    "laborIncome": 60308327.29,
    "taxProductionImports": 9449295.44,
    "laborOutputPerWorker": 4652098.48,
    "laborIncomePerWorker": 566991.01,
    "totalEconomy": 7930048779,
    "totalEmployment": 86826,
    "totalIncome": 5187923395
},
{
    "Id": 102,
    "county": 14,
    "fips": 39027,
    "countyName": "Clinton",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 42185159.08,
    "employment": 385,
    "laborIncome": 25015717.05,
    "taxProductionImports": 2523436.06,
    "laborOutputPerWorker": 2634019.78,
    "laborIncomePerWorker": 311222.82,
    "totalEconomy": 2072033914,
    "totalEmployment": 24537,
    "totalIncome": 1369308036
},
{
    "Id": 103,
    "county": 15,
    "fips": 39029,
    "countyName": "Columbiana",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 145584158.2,
    "employment": 1445,
    "laborIncome": 67531476.05,
    "taxProductionImports": 15423041.19,
    "laborOutputPerWorker": 12706999.97,
    "laborIncomePerWorker": 711528.51,
    "totalEconomy": 2941970375,
    "totalEmployment": 41828,
    "totalIncome": 1769291010
},
{
    "Id": 104,
    "county": 16,
    "fips": 39031,
    "countyName": "Coshocton",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 114820431.9,
    "employment": 1272,
    "laborIncome": 68077075.31,
    "taxProductionImports": 11298988.29,
    "laborOutputPerWorker": 4865868.1,
    "laborIncomePerWorker": 613555.31,
    "totalEconomy": 1227308569,
    "totalEmployment": 15327,
    "totalIncome": 642310756.8
},
{
    "Id": 105,
    "county": 17,
    "fips": 39033,
    "countyName": "Crawford",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 9523882.38,
    "employment": 110,
    "laborIncome": 4447465.29,
    "taxProductionImports": 1097067.9,
    "laborOutputPerWorker": 6186527.07,
    "laborIncomePerWorker": 492067.38,
    "totalEconomy": 1280629829,
    "totalEmployment": 18676,
    "totalIncome": 747739350.2
},
{
    "Id": 106,
    "county": 18,
    "fips": 39035,
    "countyName": "Cuyahoga",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 902601136.5,
    "employment": 7452,
    "laborIncome": 428749753.7,
    "taxProductionImports": 139155944.7,
    "laborOutputPerWorker": 24864628.25,
    "laborIncomePerWorker": 3082810.82,
    "totalEconomy": 98230712685,
    "totalEmployment": 932945,
    "totalIncome": 59079357896
},
{
    "Id": 107,
    "county": 19,
    "fips": 39037,
    "countyName": "Darke",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 21464281.6,
    "employment": 292,
    "laborIncome": 12650861.27,
    "taxProductionImports": 1917151.63,
    "laborOutputPerWorker": 5325347.35,
    "laborIncomePerWorker": 493528.97,
    "totalEconomy": 1974102343,
    "totalEmployment": 28585,
    "totalIncome": 1160664339
},
{
    "Id": 108,
    "county": 20,
    "fips": 39039,
    "countyName": "Defiance",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 25829390.62,
    "employment": 277,
    "laborIncome": 12819597.22,
    "taxProductionImports": 1943282.74,
    "laborOutputPerWorker": 5170849.88,
    "laborIncomePerWorker": 355821.65,
    "totalEconomy": 1680173365,
    "totalEmployment": 22146,
    "totalIncome": 1020365474
},
{
    "Id": 109,
    "county": 21,
    "fips": 39041,
    "countyName": "Delaware",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 114544509.3,
    "employment": 1186,
    "laborIncome": 59833378.3,
    "taxProductionImports": 8317671.95,
    "laborOutputPerWorker": 4887338.23,
    "laborIncomePerWorker": 663649.99,
    "totalEconomy": 11318626744,
    "totalEmployment": 129342,
    "totalIncome": 7315658575
},
{
    "Id": 110,
    "county": 22,
    "fips": 39043,
    "countyName": "Erie",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 62079681.81,
    "employment": 629,
    "laborIncome": 27517201.16,
    "taxProductionImports": 9770138.06,
    "laborOutputPerWorker": 10329898.88,
    "laborIncomePerWorker": 684728.06,
    "totalEconomy": 3708532706,
    "totalEmployment": 48765,
    "totalIncome": 2427949119
},
{
    "Id": 111,
    "county": 23,
    "fips": 39045,
    "countyName": "Fairfield",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 144509137.6,
    "employment": 796,
    "laborIncome": 51109552.44,
    "taxProductionImports": 9760737.35,
    "laborOutputPerWorker": 7359014.24,
    "laborIncomePerWorker": 701505.46,
    "totalEconomy": 4299720609,
    "totalEmployment": 62378,
    "totalIncome": 2655340612
},
{
    "Id": 112,
    "county": 24,
    "fips": 39047,
    "countyName": "Fayette",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 135134194.4,
    "employment": 886,
    "laborIncome": 52907298.11,
    "taxProductionImports": 6110750.52,
    "laborOutputPerWorker": 4812041.64,
    "laborIncomePerWorker": 532637.23,
    "totalEconomy": 1038213944,
    "totalEmployment": 15528,
    "totalIncome": 613819989
},
{
    "Id": 113,
    "county": 25,
    "fips": 39049,
    "countyName": "Franklin",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 2468122210,
    "employment": 11361,
    "laborIncome": 897683260.9,
    "taxProductionImports": 269416318.6,
    "laborOutputPerWorker": 24313054.88,
    "laborIncomePerWorker": 2445801.07,
    "totalEconomy": 101631000000,
    "totalEmployment": 985452,
    "totalIncome": 61241000156
},
{
    "Id": 114,
    "county": 26,
    "fips": 39051,
    "countyName": "Fulton",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 94465415.02,
    "employment": 698,
    "laborIncome": 49266790.51,
    "taxProductionImports": 6159327.07,
    "laborOutputPerWorker": 6211035.56,
    "laborIncomePerWorker": 1268876.38,
    "totalEconomy": 1911471665,
    "totalEmployment": 27064,
    "totalIncome": 1234435682
},
{
    "Id": 115,
    "county": 27,
    "fips": 39053,
    "countyName": "Gallia",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 7195165.75,
    "employment": 107,
    "laborIncome": 4383541.46,
    "taxProductionImports": 1068019.48,
    "laborOutputPerWorker": 3025474.55,
    "laborIncomePerWorker": 280678.09,
    "totalEconomy": 1405262413,
    "totalEmployment": 16585,
    "totalIncome": 666350227.5
},
{
    "Id": 116,
    "county": 28,
    "fips": 39055,
    "countyName": "Geauga",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 59021183.33,
    "employment": 732,
    "laborIncome": 41470683.05,
    "taxProductionImports": 4375701.32,
    "laborOutputPerWorker": 8822042.17,
    "laborIncomePerWorker": 835527.69,
    "totalEconomy": 4251446767.41,
    "totalEmployment": 56764,
    "totalIncome": 2759920046.41
},
{
    "Id": 117,
    "county": 29,
    "fips": 39057,
    "countyName": "Greene",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 62860143.43,
    "employment": 621,
    "laborIncome": 32742276.77,
    "taxProductionImports": 9515434.67,
    "laborOutputPerWorker": 6009833.64,
    "laborIncomePerWorker": 641918.24,
    "totalEconomy": 10294610205,
    "totalEmployment": 100744,
    "totalIncome": 6417793082
},
{
    "Id": 118,
    "county": 30,
    "fips": 39059,
    "countyName": "Guernsey",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 28725779.66,
    "employment": 371,
    "laborIncome": 18543786.72,
    "taxProductionImports": 2278382.17,
    "laborOutputPerWorker": 3705395.19,
    "laborIncomePerWorker": 421862.01,
    "totalEconomy": 1666634109,
    "totalEmployment": 19522,
    "totalIncome": 933574143.5
},
{
    "Id": 119,
    "county": 31,
    "fips": 39061,
    "countyName": "Hamilton",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 2143875947,
    "employment": 10021,
    "laborIncome": 695966738.9,
    "taxProductionImports": 187268561,
    "laborOutputPerWorker": 28656504.08,
    "laborIncomePerWorker": 2981535.72,
    "totalEconomy": 76878446431,
    "totalEmployment": 666388,
    "totalIncome": 44702718608
},
{
    "Id": 120,
    "county": 32,
    "fips": 39063,
    "countyName": "Hancock",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 220976321.3,
    "employment": 2015,
    "laborIncome": 101315975.5,
    "taxProductionImports": 14895039.4,
    "laborOutputPerWorker": 10341027.38,
    "laborIncomePerWorker": 623154.42,
    "totalEconomy": 5824095294,
    "totalEmployment": 57110,
    "totalIncome": 4084603099
},
{
    "Id": 121,
    "county": 33,
    "fips": 39065,
    "countyName": "Hardin",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 46947228.24,
    "employment": 613,
    "laborIncome": 33355056.98,
    "taxProductionImports": 3739145.27,
    "laborOutputPerWorker": 2607741.84,
    "laborIncomePerWorker": 275984.58,
    "totalEconomy": 847103726,
    "totalEmployment": 13997,
    "totalIncome": 524322364.7
},
{
    "Id": 122,
    "county": 34,
    "fips": 39067,
    "countyName": "Harrison",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 11520160.78,
    "employment": 206,
    "laborIncome": 9572512.87,
    "taxProductionImports": 746294.89,
    "laborOutputPerWorker": 1277014.09,
    "laborIncomePerWorker": 81213.93,
    "totalEconomy": 502705042.8,
    "totalEmployment": 5841,
    "totalIncome": 286179102.6
},
{
    "Id": 123,
    "county": 35,
    "fips": 39069,
    "countyName": "Henry",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 293344227.1,
    "employment": 1660,
    "laborIncome": 130587146.1,
    "taxProductionImports": 18937046.61,
    "laborOutputPerWorker": 3855796.79,
    "laborIncomePerWorker": 338096.3,
    "totalEconomy": 1247305512,
    "totalEmployment": 16138,
    "totalIncome": 748819861.5
},
{
    "Id": 124,
    "county": 36,
    "fips": 39071,
    "countyName": "Highland",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 20655551.93,
    "employment": 238,
    "laborIncome": 13866817.97,
    "taxProductionImports": 1652507.51,
    "laborOutputPerWorker": 2941076.53,
    "laborIncomePerWorker": 323117.95,
    "totalEconomy": 1070900775,
    "totalEmployment": 17098,
    "totalIncome": 644200573.1
},
{
    "Id": 125,
    "county": 37,
    "fips": 39073,
    "countyName": "Hocking",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 35244381.82,
    "employment": 368,
    "laborIncome": 17938692.08,
    "taxProductionImports": 5686915.17,
    "laborOutputPerWorker": 5408539.18,
    "laborIncomePerWorker": 501498.9,
    "totalEconomy": 619360916.6,
    "totalEmployment": 9730,
    "totalIncome": 367045192.2
},
{
    "Id": 126,
    "county": 38,
    "fips": 39075,
    "countyName": "Holmes",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 222875802.8,
    "employment": 3123,
    "laborIncome": 180180444.7,
    "taxProductionImports": 9664294.35,
    "laborOutputPerWorker": 12255432.87,
    "laborIncomePerWorker": 2167990.06,
    "totalEconomy": 2287136910,
    "totalEmployment": 29518,
    "totalIncome": 1574698025
},
{
    "Id": 127,
    "county": 39,
    "fips": 39077,
    "countyName": "Huron",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 179713862.8,
    "employment": 1116,
    "laborIncome": 80091607.77,
    "taxProductionImports": 13429951.99,
    "laborOutputPerWorker": 7185050.97,
    "laborIncomePerWorker": 615275.69,
    "totalEconomy": 2310117902,
    "totalEmployment": 30071,
    "totalIncome": 1384087245
},
{
    "Id": 128,
    "county": 40,
    "fips": 39079,
    "countyName": "Jackson",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 190144692.6,
    "employment": 2804,
    "laborIncome": 134745766.3,
    "taxProductionImports": 10100204.54,
    "laborOutputPerWorker": 2869474.85,
    "laborIncomePerWorker": 335059.68,
    "totalEconomy": 1066268771,
    "totalEmployment": 13672,
    "totalIncome": 605102941.2
},
{
    "Id": 129,
    "county": 41,
    "fips": 39081,
    "countyName": "Jefferson",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 15096566.06,
    "employment": 262,
    "laborIncome": 11430599.55,
    "taxProductionImports": 911370.85,
    "laborOutputPerWorker": 1893151.7,
    "laborIncomePerWorker": 272502.17,
    "totalEconomy": 2459146057,
    "totalEmployment": 27836,
    "totalIncome": 1235636377
},
{
    "Id": 130,
    "county": 42,
    "fips": 39083,
    "countyName": "Knox",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 54576269.1,
    "employment": 837,
    "laborIncome": 35507329.95,
    "taxProductionImports": 6106921.11,
    "laborOutputPerWorker": 4473363.48,
    "laborIncomePerWorker": 553897.68,
    "totalEconomy": 2176950488,
    "totalEmployment": 31101,
    "totalIncome": 1399347525
},
{
    "Id": 131,
    "county": 43,
    "fips": 39085,
    "countyName": "Lake",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 309487916.4,
    "employment": 2007,
    "laborIncome": 212186854.4,
    "taxProductionImports": 7622038.2,
    "laborOutputPerWorker": 6566194.56,
    "laborIncomePerWorker": 910365.74,
    "totalEconomy": 10783727637,
    "totalEmployment": 120447,
    "totalIncome": 6118984543
},
{
    "Id": 132,
    "county": 44,
    "fips": 39087,
    "countyName": "Lawrence",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 5450103.74,
    "employment": 52,
    "laborIncome": 1861870.11,
    "taxProductionImports": 2036164.54,
    "laborOutputPerWorker": 1437069.77,
    "laborIncomePerWorker": 154154.86,
    "totalEconomy": 1181459510,
    "totalEmployment": 18385,
    "totalIncome": 715224830.7
},
{
    "Id": 133,
    "county": 45,
    "fips": 39089,
    "countyName": "Licking",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 95589217.12,
    "employment": 1161,
    "laborIncome": 61645928.29,
    "taxProductionImports": 9807884.67,
    "laborOutputPerWorker": 7795452.48,
    "laborIncomePerWorker": 948178,
    "totalEconomy": 6315127600,
    "totalEmployment": 75777,
    "totalIncome": 3590380801
},
{
    "Id": 134,
    "county": 46,
    "fips": 39091,
    "countyName": "Logan",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 18870224.9,
    "employment": 271,
    "laborIncome": 11922176.25,
    "taxProductionImports": 1911551.9,
    "laborOutputPerWorker": 3104963.41,
    "laborIncomePerWorker": 275603.33,
    "totalEconomy": 2144681238,
    "totalEmployment": 27606,
    "totalIncome": 1308083081
},
{
    "Id": 135,
    "county": 47,
    "fips": 39093,
    "countyName": "Lorain",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 81876604.18,
    "employment": 1050,
    "laborIncome": 39958357.39,
    "taxProductionImports": 8360955.09,
    "laborOutputPerWorker": 6444852.49,
    "laborIncomePerWorker": 687084.91,
    "totalEconomy": 10824808084,
    "totalEmployment": 131092,
    "totalIncome": 6415398605
},
{
    "Id": 136,
    "county": 48,
    "fips": 39095,
    "countyName": "Lucas",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 247698002.6,
    "employment": 1878,
    "laborIncome": 102275181.2,
    "taxProductionImports": 34698354.35,
    "laborOutputPerWorker": 11992065.88,
    "laborIncomePerWorker": 1195771.44,
    "totalEconomy": 25868535825,
    "totalEmployment": 262159,
    "totalIncome": 15037401755
},
{
    "Id": 137,
    "county": 49,
    "fips": 39097,
    "countyName": "Madison",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 6535331.59,
    "employment": 88,
    "laborIncome": 4027242.8,
    "taxProductionImports": 813836.17,
    "laborOutputPerWorker": 4189501.09,
    "laborIncomePerWorker": 440158.29,
    "totalEconomy": 1617403686,
    "totalEmployment": 24015,
    "totalIncome": 1104300860
},
{
    "Id": 138,
    "county": 50,
    "fips": 39099,
    "countyName": "Mahoning",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 121549741.4,
    "employment": 1570,
    "laborIncome": 72400665.63,
    "taxProductionImports": 10931200.27,
    "laborOutputPerWorker": 6191920.18,
    "laborIncomePerWorker": 693313.28,
    "totalEconomy": 9252062834,
    "totalEmployment": 128652,
    "totalIncome": 5901968922
},
{
    "Id": 139,
    "county": 51,
    "fips": 39101,
    "countyName": "Marion",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 157095740.5,
    "employment": 1126,
    "laborIncome": 66500883.77,
    "taxProductionImports": 8331223.89,
    "laborOutputPerWorker": 4986053.58,
    "laborIncomePerWorker": 448401.33,
    "totalEconomy": 2561183084,
    "totalEmployment": 30887,
    "totalIncome": 1514307774
},
{
    "Id": 140,
    "county": 52,
    "fips": 39103,
    "countyName": "Medina",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 302475730.4,
    "employment": 1506,
    "laborIncome": 87964782.43,
    "taxProductionImports": 11670157.55,
    "laborOutputPerWorker": 10829326.37,
    "laborIncomePerWorker": 1265843.47,
    "totalEconomy": 6902232322,
    "totalEmployment": 87891,
    "totalIncome": 4102452844
},
{
    "Id": 141,
    "county": 53,
    "fips": 39105,
    "countyName": "Meigs",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 5586491.5,
    "employment": 75,
    "laborIncome": 2989540.59,
    "taxProductionImports": 502248.45,
    "laborOutputPerWorker": 2159139.94,
    "laborIncomePerWorker": 165407.43,
    "totalEconomy": 375095333.3,
    "totalEmployment": 6038,
    "totalIncome": 197384782.6
},
{
    "Id": 142,
    "county": 54,
    "fips": 39107,
    "countyName": "Mercer",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 142298868.1,
    "employment": 1933,
    "laborIncome": 81311929.06,
    "taxProductionImports": 6350172.87,
    "laborOutputPerWorker": 4903153.19,
    "laborIncomePerWorker": 560042.11,
    "totalEconomy": 2167186984,
    "totalEmployment": 30960,
    "totalIncome": 1307607927
},
{
    "Id": 143,
    "county": 55,
    "fips": 39109,
    "countyName": "Miami",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 189887705.2,
    "employment": 2011,
    "laborIncome": 117601962,
    "taxProductionImports": 7550142.63,
    "laborOutputPerWorker": 6184223.39,
    "laborIncomePerWorker": 836769.85,
    "totalEconomy": 4161975386,
    "totalEmployment": 56906,
    "totalIncome": 2545471788
},
{
    "Id": 144,
    "county": 56,
    "fips": 39111,
    "countyName": "Monroe",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 682339.46,
    "employment": 15,
    "laborIncome": 261292.89,
    "taxProductionImports": 331411.81,
    "laborOutputPerWorker": 2101481.37,
    "laborIncomePerWorker": 143912.79,
    "totalEconomy": 290990164.5,
    "totalEmployment": 5409,
    "totalIncome": 152715827.6
},
{
    "Id": 145,
    "county": 57,
    "fips": 39113,
    "countyName": "Montgomery",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 346107421.3,
    "employment": 2614,
    "laborIncome": 143772575.3,
    "taxProductionImports": 43235522.18,
    "laborOutputPerWorker": 16343556.26,
    "laborIncomePerWorker": 1490466.36,
    "totalEconomy": 29088032810,
    "totalEmployment": 325604,
    "totalIncome": 17822734429
},
{
    "Id": 146,
    "county": 58,
    "fips": 39115,
    "countyName": "Morgan",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 735237.92,
    "employment": 22,
    "laborIncome": 271620.03,
    "taxProductionImports": 428278.49,
    "laborOutputPerWorker": 1216517.8,
    "laborIncomePerWorker": 61496.31,
    "totalEconomy": 284846059.3,
    "totalEmployment": 4558,
    "totalIncome": 168010389.7
},
{
    "Id": 147,
    "county": 59,
    "fips": 39117,
    "countyName": "Morrow",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 6887184.25,
    "employment": 90,
    "laborIncome": 4706071.63,
    "taxProductionImports": 872016.1,
    "laborOutputPerWorker": 2241524.45,
    "laborIncomePerWorker": 274586.05,
    "totalEconomy": 609587559.4,
    "totalEmployment": 10407,
    "totalIncome": 363893591
},
{
    "Id": 148,
    "county": 60,
    "fips": 39119,
    "countyName": "Muskingum",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 170379435.8,
    "employment": 1242,
    "laborIncome": 74849205.76,
    "taxProductionImports": 7590307.95,
    "laborOutputPerWorker": 6948160.22,
    "laborIncomePerWorker": 732931.4,
    "totalEconomy": 3780755649,
    "totalEmployment": 46983,
    "totalIncome": 2016031014
},
{
    "Id": 149,
    "county": 61,
    "fips": 39121,
    "countyName": "Noble",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 12239887.84,
    "employment": 144,
    "laborIncome": 7933982.93,
    "taxProductionImports": 1138661.24,
    "laborOutputPerWorker": 2344233.88,
    "laborIncomePerWorker": 274352.46,
    "totalEconomy": 358758386.4,
    "totalEmployment": 4888,
    "totalIncome": 204161956.9
},
{
    "Id": 150,
    "county": 62,
    "fips": 39123,
    "countyName": "Ottawa",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 31960134.11,
    "employment": 204,
    "laborIncome": 10773672.76,
    "taxProductionImports": 12996654.99,
    "laborOutputPerWorker": 4100380.51,
    "laborIncomePerWorker": 354075.49,
    "totalEconomy": 1882522665,
    "totalEmployment": 20665,
    "totalIncome": 910078674.8
},
{
    "Id": 151,
    "county": 63,
    "fips": 39125,
    "countyName": "Paulding",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 4538397.24,
    "employment": 54,
    "laborIncome": 1968828.55,
    "taxProductionImports": 964580.54,
    "laborOutputPerWorker": 4269072.98,
    "laborIncomePerWorker": 236281.04,
    "totalEconomy": 473398160.2,
    "totalEmployment": 7618,
    "totalIncome": 278671835.9
},
{
    "Id": 152,
    "county": 64,
    "fips": 39127,
    "countyName": "Perry",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 2406835.06,
    "employment": 57,
    "laborIncome": 1530190.95,
    "taxProductionImports": 627360.38,
    "laborOutputPerWorker": 1739602.27,
    "laborIncomePerWorker": 143945.8,
    "totalEconomy": 702597434.6,
    "totalEmployment": 9792,
    "totalIncome": 415826629.5
},
{
    "Id": 153,
    "county": 65,
    "fips": 39129,
    "countyName": "Pickaway",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 43331020.58,
    "employment": 383,
    "laborIncome": 28308103.36,
    "taxProductionImports": 2809856.14,
    "laborOutputPerWorker": 3550163.43,
    "laborIncomePerWorker": 418250.28,
    "totalEconomy": 1591171161,
    "totalEmployment": 21056,
    "totalIncome": 1030079339
},
{
    "Id": 154,
    "county": 66,
    "fips": 39131,
    "countyName": "Pike",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 37885839.83,
    "employment": 839,
    "laborIncome": 30186861.81,
    "taxProductionImports": 2299869.95,
    "laborOutputPerWorker": 2527800.36,
    "laborIncomePerWorker": 599136.3,
    "totalEconomy": 1070334939,
    "totalEmployment": 13035,
    "totalIncome": 711041714.1
},
{
    "Id": 155,
    "county": 67,
    "fips": 39133,
    "countyName": "Portage",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 96828119.85,
    "employment": 860,
    "laborIncome": 62349613.29,
    "taxProductionImports": 9008273.51,
    "laborOutputPerWorker": 9373638.46,
    "laborIncomePerWorker": 1771745.95,
    "totalEconomy": 6778445655,
    "totalEmployment": 83265,
    "totalIncome": 3923076961
},
{
    "Id": 156,
    "county": 68,
    "fips": 39135,
    "countyName": "Preble",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 93588719.82,
    "employment": 669,
    "laborIncome": 40468522.48,
    "taxProductionImports": 3996288.63,
    "laborOutputPerWorker": 5396716.18,
    "laborIncomePerWorker": 444400.21,
    "totalEconomy": 1120245977,
    "totalEmployment": 18013,
    "totalIncome": 687144010.4
},
{
    "Id": 157,
    "county": 69,
    "fips": 39137,
    "countyName": "Putnam",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 65958330.39,
    "employment": 194,
    "laborIncome": 16519746.83,
    "taxProductionImports": 1098587.03,
    "laborOutputPerWorker": 6144989.36,
    "laborIncomePerWorker": 654423.84,
    "totalEconomy": 1271105089,
    "totalEmployment": 19591,
    "totalIncome": 768528314.1
},
{
    "Id": 158,
    "county": 70,
    "fips": 39139,
    "countyName": "Richland",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 71182506.08,
    "employment": 775,
    "laborIncome": 40516804.75,
    "taxProductionImports": 7853124.32,
    "laborOutputPerWorker": 4639741.77,
    "laborIncomePerWorker": 664169.99,
    "totalEconomy": 4550594470,
    "totalEmployment": 64075,
    "totalIncome": 2871087954
},
{
    "Id": 159,
    "county": 71,
    "fips": 39141,
    "countyName": "Ross",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 198671498.3,
    "employment": 1072,
    "laborIncome": 104150791,
    "taxProductionImports": 11412508.99,
    "laborOutputPerWorker": 8833920.82,
    "laborIncomePerWorker": 537619.39,
    "totalEconomy": 2866885847,
    "totalEmployment": 35579,
    "totalIncome": 1822260275
},
{
    "Id": 160,
    "county": 72,
    "fips": 39143,
    "countyName": "Sandusky",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 86080571.62,
    "employment": 748,
    "laborIncome": 57126547.68,
    "taxProductionImports": 5252707.66,
    "laborOutputPerWorker": 4462453.4,
    "laborIncomePerWorker": 853009.99,
    "totalEconomy": 2760828151,
    "totalEmployment": 34118,
    "totalIncome": 1631045090
},
{
    "Id": 161,
    "county": 73,
    "fips": 39145,
    "countyName": "Scioto",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 43642183.18,
    "employment": 397,
    "laborIncome": 20710402.86,
    "taxProductionImports": 6206081.07,
    "laborOutputPerWorker": 4885901.78,
    "laborIncomePerWorker": 693287.45,
    "totalEconomy": 2228435683,
    "totalEmployment": 32475,
    "totalIncome": 1448223374
},
{
    "Id": 162,
    "county": 74,
    "fips": 39147,
    "countyName": "Seneca",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 53006321.06,
    "employment": 485,
    "laborIncome": 35583330.56,
    "taxProductionImports": 3007988.89,
    "laborOutputPerWorker": 4607699.36,
    "laborIncomePerWorker": 604470.59,
    "totalEconomy": 1917097420,
    "totalEmployment": 28075,
    "totalIncome": 1159588109
},
{
    "Id": 163,
    "county": 75,
    "fips": 39149,
    "countyName": "Shelby",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 186056165.8,
    "employment": 1589,
    "laborIncome": 89568224.49,
    "taxProductionImports": 22041343.06,
    "laborOutputPerWorker": 10388233.29,
    "laborIncomePerWorker": 664804.45,
    "totalEconomy": 3010714574,
    "totalEmployment": 35325,
    "totalIncome": 1945896711
},
{
    "Id": 164,
    "county": 76,
    "fips": 39151,
    "countyName": "Stark",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 741555836.5,
    "employment": 6533,
    "laborIncome": 365485743.1,
    "taxProductionImports": 55912009.01,
    "laborOutputPerWorker": 15579279.06,
    "laborIncomePerWorker": 1628194.8,
    "totalEconomy": 16828271608,
    "totalEmployment": 204971,
    "totalIncome": 9883919732
},
{
    "Id": 165,
    "county": 77,
    "fips": 39153,
    "countyName": "Summit",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 416362590.7,
    "employment": 3357,
    "laborIncome": 190094101.6,
    "taxProductionImports": 47767616.37,
    "laborOutputPerWorker": 13699486.64,
    "laborIncomePerWorker": 1584660.51,
    "totalEconomy": 30391421246,
    "totalEmployment": 345561,
    "totalIncome": 18797876030
},
{
    "Id": 166,
    "county": 78,
    "fips": 39155,
    "countyName": "Trumbull",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 69014957.04,
    "employment": 826,
    "laborIncome": 32590136.91,
    "taxProductionImports": 7969984.23,
    "laborOutputPerWorker": 6803719.94,
    "laborIncomePerWorker": 641087.31,
    "totalEconomy": 7160996203,
    "totalEmployment": 94922,
    "totalIncome": 4297839867
},
{
    "Id": 167,
    "county": 79,
    "fips": 39157,
    "countyName": "Tuscarawas",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 97361785.17,
    "employment": 1187,
    "laborIncome": 71759943.89,
    "taxProductionImports": 5282551.34,
    "laborOutputPerWorker": 7599683.32,
    "laborIncomePerWorker": 1105086.43,
    "totalEconomy": 4776581038,
    "totalEmployment": 48918,
    "totalIncome": 2565518783
},
{
    "Id": 168,
    "county": 80,
    "fips": 39159,
    "countyName": "Union",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 35452118.97,
    "employment": 263,
    "laborIncome": 15496602.83,
    "taxProductionImports": 6439174.64,
    "laborOutputPerWorker": 7036398.55,
    "laborIncomePerWorker": 489749.6,
    "totalEconomy": 4766480779,
    "totalEmployment": 41726,
    "totalIncome": 2725568550
},
{
    "Id": 169,
    "county": 81,
    "fips": 39161,
    "countyName": "Van Wert",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 20702191.98,
    "employment": 214,
    "laborIncome": 12104245.2,
    "taxProductionImports": 1750378.91,
    "laborOutputPerWorker": 7446251.94,
    "laborIncomePerWorker": 416628.14,
    "totalEconomy": 1122580674,
    "totalEmployment": 14777,
    "totalIncome": 678450141.6
},
{
    "Id": 170,
    "county": 82,
    "fips": 39163,
    "countyName": "Vinton",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 11567761.38,
    "employment": 199,
    "laborIncome": 10056333.63,
    "taxProductionImports": 340817.5,
    "laborOutputPerWorker": 1082066.46,
    "laborIncomePerWorker": 141786.05,
    "totalEconomy": 224168469.3,
    "totalEmployment": 3335,
    "totalIncome": 143160957.9
},
{
    "Id": 171,
    "county": 83,
    "fips": 39165,
    "countyName": "Warren",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 245157798.7,
    "employment": 2221,
    "laborIncome": 149726229.9,
    "taxProductionImports": 17061301.25,
    "laborOutputPerWorker": 8356699.6,
    "laborIncomePerWorker": 1114365.84,
    "totalEconomy": 11287597988,
    "totalEmployment": 120380,
    "totalIncome": 6753727556
},
{
    "Id": 172,
    "county": 84,
    "fips": 39167,
    "countyName": "Washington",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 40503037.41,
    "employment": 462,
    "laborIncome": 22964022.7,
    "taxProductionImports": 2630315.94,
    "laborOutputPerWorker": 5276577.42,
    "laborIncomePerWorker": 532167.18,
    "totalEconomy": 4211696286,
    "totalEmployment": 33761,
    "totalIncome": 1707603612
},
{
    "Id": 173,
    "county": 85,
    "fips": 39169,
    "countyName": "Wayne",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 683498279.7,
    "employment": 5887,
    "laborIncome": 417870385.7,
    "taxProductionImports": 31053946.59,
    "laborOutputPerWorker": 14685251.04,
    "laborIncomePerWorker": 1100879.05,
    "totalEconomy": 5986535399,
    "totalEmployment": 70092,
    "totalIncome": 3543366070
},
{
    "Id": 174,
    "county": 86,
    "fips": 39171,
    "countyName": "Williams",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 104042247.2,
    "employment": 1036,
    "laborIncome": 52533937.53,
    "taxProductionImports": 8493240.25,
    "laborOutputPerWorker": 3940730.43,
    "laborIncomePerWorker": 520009.57,
    "totalEconomy": 1715547890,
    "totalEmployment": 23696,
    "totalIncome": 1078124701
},
{
    "Id": 175,
    "county": 87,
    "fips": 39173,
    "countyName": "Wood",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 168733961.5,
    "employment": 1479,
    "laborIncome": 82337071.87,
    "taxProductionImports": 10580170.49,
    "laborOutputPerWorker": 7543302.05,
    "laborIncomePerWorker": 762638.38,
    "totalEconomy": 7168113759,
    "totalEmployment": 85611,
    "totalIncome": 4481666605
},
{
    "Id": 176,
    "county": 88,
    "fips": 39175,
    "countyName": "Wyandot",
    "indicator": "food processing",
    "year": 2017,
    "valueAdded": 98886515.23,
    "employment": 569,
    "laborIncome": 37266880.61,
    "taxProductionImports": 3582830.78,
    "laborOutputPerWorker": 1980178.79,
    "laborIncomePerWorker": 178955.1,
    "totalEconomy": 1020010896,
    "totalEmployment": 13535,
    "totalIncome": 607656573.6
},
{
    "Id": 177,
    "county": 1,
    "fips": 39001,
    "countyName": "Adams",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 15037384.06,
    "employment": 312,
    "laborIncome": 8581371.95,
    "taxProductionImports": 4061364.14,
    "laborOutputPerWorker": 133791.32,
    "laborIncomePerWorker": 45232.16,
    "totalEconomy": 772089382.5,
    "totalEmployment": 11275,
    "totalIncome": 426750073.1
},
{
    "Id": 178,
    "county": 2,
    "fips": 39003,
    "countyName": "Allen",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 99111023.32,
    "employment": 1612,
    "laborIncome": 56085218.49,
    "taxProductionImports": 22870863.53,
    "laborOutputPerWorker": 140290.2,
    "laborIncomePerWorker": 49951.94,
    "totalEconomy": 6350020522,
    "totalEmployment": 64919,
    "totalIncome": 3399766857
},
{
    "Id": 179,
    "county": 3,
    "fips": 39005,
    "countyName": "Ashland",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 35577534.33,
    "employment": 689,
    "laborIncome": 20595426.95,
    "taxProductionImports": 8613402.53,
    "laborOutputPerWorker": 140917.99,
    "laborIncomePerWorker": 50867.89,
    "totalEconomy": 1836074497,
    "totalEmployment": 27170,
    "totalIncome": 1122453349
},
{
    "Id": 180,
    "county": 4,
    "fips": 39007,
    "countyName": "Ashtabula",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 50882925.22,
    "employment": 1113,
    "laborIncome": 29477787.66,
    "taxProductionImports": 12820444.08,
    "laborOutputPerWorker": 136386.09,
    "laborIncomePerWorker": 46862.46,
    "totalEconomy": 2997219359,
    "totalEmployment": 43560,
    "totalIncome": 1935704511
},
{
    "Id": 181,
    "county": 5,
    "fips": 39009,
    "countyName": "Athens",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 39080844.96,
    "employment": 769,
    "laborIncome": 23480970.65,
    "taxProductionImports": 8937502.91,
    "laborOutputPerWorker": 158744.4,
    "laborIncomePerWorker": 68012.81,
    "totalEconomy": 2331026444,
    "totalEmployment": 33223,
    "totalIncome": 1502390067
},
{
    "Id": 182,
    "county": 6,
    "fips": 39011,
    "countyName": "Auglaize",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 47242600.21,
    "employment": 826,
    "laborIncome": 26864120.29,
    "taxProductionImports": 10315203.98,
    "laborOutputPerWorker": 141712.93,
    "laborIncomePerWorker": 50037.1,
    "totalEconomy": 2247296725,
    "totalEmployment": 29514,
    "totalIncome": 1369993614
},
{
    "Id": 183,
    "county": 7,
    "fips": 39013,
    "countyName": "Belmont",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 61376945.35,
    "employment": 1230,
    "laborIncome": 37653546.81,
    "taxProductionImports": 13721699.56,
    "laborOutputPerWorker": 147530.49,
    "laborIncomePerWorker": 55645.48,
    "totalEconomy": 3015496326,
    "totalEmployment": 29382,
    "totalIncome": 1406956624
},
{
    "Id": 184,
    "county": 8,
    "fips": 39015,
    "countyName": "Brown",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 19955155.28,
    "employment": 449,
    "laborIncome": 12534958.04,
    "taxProductionImports": 4509082.36,
    "laborOutputPerWorker": 140265.05,
    "laborIncomePerWorker": 51448.57,
    "totalEconomy": 1071946185,
    "totalEmployment": 14511,
    "totalIncome": 571744808.5
},
{
    "Id": 185,
    "county": 9,
    "fips": 39017,
    "countyName": "Butler",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 603349365.5,
    "employment": 6730,
    "laborIncome": 351081531.1,
    "taxProductionImports": 96669413.87,
    "laborOutputPerWorker": 167787.48,
    "laborIncomePerWorker": 70837.36,
    "totalEconomy": 20552990901,
    "totalEmployment": 199829,
    "totalIncome": 11591941308
},
{
    "Id": 186,
    "county": 10,
    "fips": 39019,
    "countyName": "Carroll",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 8991198.8,
    "employment": 178,
    "laborIncome": 5166988.58,
    "taxProductionImports": 2280274.52,
    "laborOutputPerWorker": 139966.26,
    "laborIncomePerWorker": 50980.17,
    "totalEconomy": 862990141.3,
    "totalEmployment": 9551,
    "totalIncome": 458153660.6
},
{
    "Id": 187,
    "county": 11,
    "fips": 39021,
    "countyName": "Champaign",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 19003045.88,
    "employment": 435,
    "laborIncome": 10953079.23,
    "taxProductionImports": 5087050.08,
    "laborOutputPerWorker": 131890.83,
    "laborIncomePerWorker": 43864.01,
    "totalEconomy": 1087178910,
    "totalEmployment": 15593,
    "totalIncome": 667716303.8
},
{
    "Id": 188,
    "county": 12,
    "fips": 39023,
    "countyName": "Clark",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 119656622.9,
    "employment": 2354,
    "laborIncome": 63313489.4,
    "taxProductionImports": 36367970.52,
    "laborOutputPerWorker": 142140.07,
    "laborIncomePerWorker": 52934.31,
    "totalEconomy": 4813529892,
    "totalEmployment": 64071,
    "totalIncome": 2856164845
},
{
    "Id": 189,
    "county": 13,
    "fips": 39025,
    "countyName": "Clermont",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 161103868.7,
    "employment": 2694,
    "laborIncome": 97562509.79,
    "taxProductionImports": 33525185.98,
    "laborOutputPerWorker": 168818.16,
    "laborIncomePerWorker": 74655.78,
    "totalEconomy": 7930048779,
    "totalEmployment": 86826,
    "totalIncome": 5187923395
},
{
    "Id": 190,
    "county": 14,
    "fips": 39027,
    "countyName": "Clinton",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 45730652.13,
    "employment": 726,
    "laborIncome": 26274913.32,
    "taxProductionImports": 9958560.05,
    "laborOutputPerWorker": 146335.96,
    "laborIncomePerWorker": 55479.59,
    "totalEconomy": 2072033914,
    "totalEmployment": 24537,
    "totalIncome": 1369308036
},
{
    "Id": 191,
    "county": 15,
    "fips": 39029,
    "countyName": "Columbiana",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 63099928.84,
    "employment": 1331,
    "laborIncome": 36838721.9,
    "taxProductionImports": 15679625.12,
    "laborOutputPerWorker": 140920.51,
    "laborIncomePerWorker": 50982.8,
    "totalEconomy": 2941970375,
    "totalEmployment": 41828,
    "totalIncome": 1769291010
},
{
    "Id": 192,
    "county": 16,
    "fips": 39031,
    "countyName": "Coshocton",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 14175344.76,
    "employment": 340,
    "laborIncome": 7994698.57,
    "taxProductionImports": 4244057.06,
    "laborOutputPerWorker": 127270.14,
    "laborIncomePerWorker": 40103.09,
    "totalEconomy": 1227308569,
    "totalEmployment": 15327,
    "totalIncome": 642310756.8
},
{
    "Id": 193,
    "county": 17,
    "fips": 39033,
    "countyName": "Crawford",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 25070918.87,
    "employment": 502,
    "laborIncome": 14592013.55,
    "taxProductionImports": 6008482.05,
    "laborOutputPerWorker": 143855.77,
    "laborIncomePerWorker": 53234.41,
    "totalEconomy": 1280629829,
    "totalEmployment": 18676,
    "totalIncome": 747739350.2
},
{
    "Id": 194,
    "county": 18,
    "fips": 39035,
    "countyName": "Cuyahoga",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 1771239822,
    "employment": 22352,
    "laborIncome": 1048407641,
    "taxProductionImports": 306349788,
    "laborOutputPerWorker": 162095.88,
    "laborIncomePerWorker": 66480.92,
    "totalEconomy": 98230712685,
    "totalEmployment": 932945,
    "totalIncome": 59079357896
},
{
    "Id": 195,
    "county": 19,
    "fips": 39037,
    "countyName": "Darke",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 45266799.72,
    "employment": 731,
    "laborIncome": 26535710.17,
    "taxProductionImports": 9636622.82,
    "laborOutputPerWorker": 143837.95,
    "laborIncomePerWorker": 53265.32,
    "totalEconomy": 1974102343,
    "totalEmployment": 28585,
    "totalIncome": 1160664339
},
{
    "Id": 196,
    "county": 20,
    "fips": 39039,
    "countyName": "Defiance",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 33617168.15,
    "employment": 628,
    "laborIncome": 19513836.94,
    "taxProductionImports": 8541435.6,
    "laborOutputPerWorker": 146733.82,
    "laborIncomePerWorker": 56674.9,
    "totalEconomy": 1680173365,
    "totalEmployment": 22146,
    "totalIncome": 1020365474
},
{
    "Id": 197,
    "county": 21,
    "fips": 39041,
    "countyName": "Delaware",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 210715664.7,
    "employment": 3421,
    "laborIncome": 134689691.4,
    "taxProductionImports": 39276011.94,
    "laborOutputPerWorker": 160659.58,
    "laborIncomePerWorker": 67209.88,
    "totalEconomy": 11318626744,
    "totalEmployment": 129342,
    "totalIncome": 7315658575
},
{
    "Id": 198,
    "county": 22,
    "fips": 39043,
    "countyName": "Erie",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 68747973.52,
    "employment": 1236,
    "laborIncome": 40991911.84,
    "taxProductionImports": 15314039.19,
    "laborOutputPerWorker": 149901.25,
    "laborIncomePerWorker": 57454.21,
    "totalEconomy": 3708532706,
    "totalEmployment": 48765,
    "totalIncome": 2427949119
},
{
    "Id": 199,
    "county": 23,
    "fips": 39045,
    "countyName": "Fairfield",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 94272876,
    "employment": 1862,
    "laborIncome": 57250802.79,
    "taxProductionImports": 22751658.31,
    "laborOutputPerWorker": 145889.18,
    "laborIncomePerWorker": 55153.05,
    "totalEconomy": 4299720609,
    "totalEmployment": 62378,
    "totalIncome": 2655340612
},
{
    "Id": 200,
    "county": 24,
    "fips": 39047,
    "countyName": "Fayette",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 33421453.42,
    "employment": 557,
    "laborIncome": 21487684.88,
    "taxProductionImports": 6007926.84,
    "laborOutputPerWorker": 150528.15,
    "laborIncomePerWorker": 58107.85,
    "totalEconomy": 1038213944,
    "totalEmployment": 15528,
    "totalIncome": 613819989
},
{
    "Id": 201,
    "county": 25,
    "fips": 39049,
    "countyName": "Franklin",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 1689284979,
    "employment": 22154,
    "laborIncome": 1012779814,
    "taxProductionImports": 276929579.8,
    "laborOutputPerWorker": 163120.6,
    "laborIncomePerWorker": 66432.6,
    "totalEconomy": 101631000000,
    "totalEmployment": 985452,
    "totalIncome": 61241000156
},
{
    "Id": 202,
    "county": 26,
    "fips": 39051,
    "countyName": "Fulton",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 42396504.94,
    "employment": 707,
    "laborIncome": 24264542.91,
    "taxProductionImports": 9268912.35,
    "laborOutputPerWorker": 142935.39,
    "laborIncomePerWorker": 52235.55,
    "totalEconomy": 1911471665,
    "totalEmployment": 27064,
    "totalIncome": 1234435682
},
{
    "Id": 203,
    "county": 27,
    "fips": 39053,
    "countyName": "Gallia",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 15074700.16,
    "employment": 352,
    "laborIncome": 8885857.13,
    "taxProductionImports": 4348090.38,
    "laborOutputPerWorker": 137114.24,
    "laborIncomePerWorker": 49598.12,
    "totalEconomy": 1405262413,
    "totalEmployment": 16585,
    "totalIncome": 666350227.5
},
{
    "Id": 204,
    "county": 28,
    "fips": 39055,
    "countyName": "Geauga",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 110804386.69,
    "employment": 1715,
    "laborIncome": 65481075.28,
    "taxProductionImports": 22545436.29,
    "laborOutputPerWorker": 156428.55,
    "laborIncomePerWorker": 63451.42,
    "totalEconomy": 4251446767.41,
    "totalEmployment": 56764,
    "totalIncome": 2759920046.41
},
{
    "Id": 205,
    "county": 29,
    "fips": 39057,
    "countyName": "Greene",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 131662317.5,
    "employment": 2654,
    "laborIncome": 80403815.55,
    "taxProductionImports": 29939468.98,
    "laborOutputPerWorker": 148707.09,
    "laborIncomePerWorker": 55937.19,
    "totalEconomy": 10294610205,
    "totalEmployment": 100744,
    "totalIncome": 6417793082
},
{
    "Id": 206,
    "county": 30,
    "fips": 39059,
    "countyName": "Guernsey",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 27329273.8,
    "employment": 524,
    "laborIncome": 16096253.68,
    "taxProductionImports": 6314845.29,
    "laborOutputPerWorker": 145259.07,
    "laborIncomePerWorker": 53267.68,
    "totalEconomy": 1666634109,
    "totalEmployment": 19522,
    "totalIncome": 933574143.5
},
{
    "Id": 207,
    "county": 31,
    "fips": 39061,
    "countyName": "Hamilton",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 1217862357,
    "employment": 14072,
    "laborIncome": 740204656.9,
    "taxProductionImports": 190913180.9,
    "laborOutputPerWorker": 170035.38,
    "laborIncomePerWorker": 72857.33,
    "totalEconomy": 76878446431,
    "totalEmployment": 666388,
    "totalIncome": 44702718608
},
{
    "Id": 208,
    "county": 32,
    "fips": 39063,
    "countyName": "Hancock",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 93618691.21,
    "employment": 1500,
    "laborIncome": 58853494.63,
    "taxProductionImports": 17224367.7,
    "laborOutputPerWorker": 150086.19,
    "laborIncomePerWorker": 58228.94,
    "totalEconomy": 5824095294,
    "totalEmployment": 57110,
    "totalIncome": 4084603099
},
{
    "Id": 209,
    "county": 33,
    "fips": 39065,
    "countyName": "Hardin",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 16219583.68,
    "employment": 318,
    "laborIncome": 9655217.59,
    "taxProductionImports": 3834392.07,
    "laborOutputPerWorker": 141344.78,
    "laborIncomePerWorker": 51426.49,
    "totalEconomy": 847103726,
    "totalEmployment": 13997,
    "totalIncome": 524322364.7
},
{
    "Id": 210,
    "county": 34,
    "fips": 39067,
    "countyName": "Harrison",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 7310948.11,
    "employment": 131,
    "laborIncome": 3930038.73,
    "taxProductionImports": 1814467.32,
    "laborOutputPerWorker": 132851.21,
    "laborIncomePerWorker": 44197.64,
    "totalEconomy": 502705042.8,
    "totalEmployment": 5841,
    "totalIncome": 286179102.6
},
{
    "Id": 211,
    "county": 35,
    "fips": 39069,
    "countyName": "Henry",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 16705790.3,
    "employment": 324,
    "laborIncome": 10162459.79,
    "taxProductionImports": 3831251.52,
    "laborOutputPerWorker": 140135.31,
    "laborIncomePerWorker": 50484.35,
    "totalEconomy": 1247305512,
    "totalEmployment": 16138,
    "totalIncome": 748819861.5
},
{
    "Id": 212,
    "county": 36,
    "fips": 39071,
    "countyName": "Highland",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 21397916.47,
    "employment": 501,
    "laborIncome": 12506911.76,
    "taxProductionImports": 5897521.72,
    "laborOutputPerWorker": 132691.01,
    "laborIncomePerWorker": 45165.61,
    "totalEconomy": 1070900775,
    "totalEmployment": 17098,
    "totalIncome": 644200573.1
},
{
    "Id": 213,
    "county": 37,
    "fips": 39073,
    "countyName": "Hocking",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 13624709.69,
    "employment": 301,
    "laborIncome": 8551823.39,
    "taxProductionImports": 3374448.15,
    "laborOutputPerWorker": 145698.81,
    "laborIncomePerWorker": 56374.31,
    "totalEconomy": 619360916.6,
    "totalEmployment": 9730,
    "totalIncome": 367045192.2
},
{
    "Id": 214,
    "county": 38,
    "fips": 39075,
    "countyName": "Holmes",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 48049868.54,
    "employment": 752,
    "laborIncome": 30998156.56,
    "taxProductionImports": 9504515.87,
    "laborOutputPerWorker": 161953.24,
    "laborIncomePerWorker": 72673.56,
    "totalEconomy": 2287136910,
    "totalEmployment": 29518,
    "totalIncome": 1574698025
},
{
    "Id": 215,
    "county": 39,
    "fips": 39077,
    "countyName": "Huron",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 38302876.24,
    "employment": 817,
    "laborIncome": 19446902.43,
    "taxProductionImports": 12215565.66,
    "laborOutputPerWorker": 127962.77,
    "laborIncomePerWorker": 41146.83,
    "totalEconomy": 2310117902,
    "totalEmployment": 30071,
    "totalIncome": 1384087245
},
{
    "Id": 216,
    "county": 40,
    "fips": 39079,
    "countyName": "Jackson",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 19634114.89,
    "employment": 452,
    "laborIncome": 11802528.48,
    "taxProductionImports": 5232162.31,
    "laborOutputPerWorker": 135647.63,
    "laborIncomePerWorker": 47935.08,
    "totalEconomy": 1066268771,
    "totalEmployment": 13672,
    "totalIncome": 605102941.2
},
{
    "Id": 217,
    "county": 41,
    "fips": 39081,
    "countyName": "Jefferson",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 42470543.69,
    "employment": 881,
    "laborIncome": 26733371.05,
    "taxProductionImports": 9172616.1,
    "laborOutputPerWorker": 142488.47,
    "laborIncomePerWorker": 52100.47,
    "totalEconomy": 2459146057,
    "totalEmployment": 27836,
    "totalIncome": 1235636377
},
{
    "Id": 218,
    "county": 42,
    "fips": 39083,
    "countyName": "Knox",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 32180002.1,
    "employment": 698,
    "laborIncome": 18739193.38,
    "taxProductionImports": 8125102.54,
    "laborOutputPerWorker": 136362.72,
    "laborIncomePerWorker": 47185.63,
    "totalEconomy": 2176950488,
    "totalEmployment": 31101,
    "totalIncome": 1399347525
},
{
    "Id": 219,
    "county": 43,
    "fips": 39085,
    "countyName": "Lake",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 219879638.7,
    "employment": 3641,
    "laborIncome": 130528167.5,
    "taxProductionImports": 45385440.88,
    "laborOutputPerWorker": 150104.89,
    "laborIncomePerWorker": 57202.13,
    "totalEconomy": 10783727637,
    "totalEmployment": 120447,
    "totalIncome": 6118984543
},
{
    "Id": 220,
    "county": 44,
    "fips": 39087,
    "countyName": "Lawrence",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 28769651.95,
    "employment": 622,
    "laborIncome": 17574531.74,
    "taxProductionImports": 7193900.36,
    "laborOutputPerWorker": 140970.14,
    "laborIncomePerWorker": 51763.34,
    "totalEconomy": 1181459510,
    "totalEmployment": 18385,
    "totalIncome": 715224830.7
},
{
    "Id": 221,
    "county": 45,
    "fips": 39089,
    "countyName": "Licking",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 118501108.2,
    "employment": 2258,
    "laborIncome": 72812516.36,
    "taxProductionImports": 25327998.22,
    "laborOutputPerWorker": 150245.79,
    "laborIncomePerWorker": 58795.88,
    "totalEconomy": 6315127600,
    "totalEmployment": 75777,
    "totalIncome": 3590380801
},
{
    "Id": 222,
    "county": 46,
    "fips": 39091,
    "countyName": "Logan",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 40833045.55,
    "employment": 737,
    "laborIncome": 27064187.29,
    "taxProductionImports": 6766777.52,
    "laborOutputPerWorker": 145512.63,
    "laborIncomePerWorker": 54166.13,
    "totalEconomy": 2144681238,
    "totalEmployment": 27606,
    "totalIncome": 1308083081
},
{
    "Id": 223,
    "county": 47,
    "fips": 39093,
    "countyName": "Lorain",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 232087691.4,
    "employment": 3922,
    "laborIncome": 140568936.8,
    "taxProductionImports": 48243412.98,
    "laborOutputPerWorker": 150401.73,
    "laborIncomePerWorker": 57958.19,
    "totalEconomy": 10824808084,
    "totalEmployment": 131092,
    "totalIncome": 6415398605
},
{
    "Id": 224,
    "county": 48,
    "fips": 39095,
    "countyName": "Lucas",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 429012422.9,
    "employment": 6632,
    "laborIncome": 265364629.7,
    "taxProductionImports": 85074175.77,
    "laborOutputPerWorker": 159608.58,
    "laborIncomePerWorker": 66822.91,
    "totalEconomy": 25868535825,
    "totalEmployment": 262159,
    "totalIncome": 15037401755
},
{
    "Id": 225,
    "county": 49,
    "fips": 39097,
    "countyName": "Madison",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 46670159.21,
    "employment": 846,
    "laborIncome": 31156900.01,
    "taxProductionImports": 8771603.13,
    "laborOutputPerWorker": 146198.75,
    "laborIncomePerWorker": 55471.79,
    "totalEconomy": 1617403686,
    "totalEmployment": 24015,
    "totalIncome": 1104300860
},
{
    "Id": 226,
    "county": 50,
    "fips": 39099,
    "countyName": "Mahoning",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 222347100.6,
    "employment": 3748,
    "laborIncome": 131693902.8,
    "taxProductionImports": 47379840.63,
    "laborOutputPerWorker": 147595.19,
    "laborIncomePerWorker": 56267.41,
    "totalEconomy": 9252062834,
    "totalEmployment": 128652,
    "totalIncome": 5901968922
},
{
    "Id": 227,
    "county": 51,
    "fips": 39101,
    "countyName": "Marion",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 46521196.86,
    "employment": 800,
    "laborIncome": 28146516.82,
    "taxProductionImports": 9844732.07,
    "laborOutputPerWorker": 152553.46,
    "laborIncomePerWorker": 59565.99,
    "totalEconomy": 2561183084,
    "totalEmployment": 30887,
    "totalIncome": 1514307774
},
{
    "Id": 228,
    "county": 52,
    "fips": 39103,
    "countyName": "Medina",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 174680460.3,
    "employment": 2651,
    "laborIncome": 102762031.1,
    "taxProductionImports": 35676365.69,
    "laborOutputPerWorker": 149434.01,
    "laborIncomePerWorker": 57307.97,
    "totalEconomy": 6902232322,
    "totalEmployment": 87891,
    "totalIncome": 4102452844
},
{
    "Id": 229,
    "county": 53,
    "fips": 39105,
    "countyName": "Meigs",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 5660078.2,
    "employment": 139,
    "laborIncome": 3499244.83,
    "taxProductionImports": 1483144.95,
    "laborOutputPerWorker": 129446.7,
    "laborIncomePerWorker": 43817.89,
    "totalEconomy": 375095333.3,
    "totalEmployment": 6038,
    "totalIncome": 197384782.6
},
{
    "Id": 230,
    "county": 54,
    "fips": 39107,
    "countyName": "Mercer",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 38584651.14,
    "employment": 693,
    "laborIncome": 21421383.3,
    "taxProductionImports": 9596482.2,
    "laborOutputPerWorker": 137917.31,
    "laborIncomePerWorker": 48535.32,
    "totalEconomy": 2167186984,
    "totalEmployment": 30960,
    "totalIncome": 1307607927
},
{
    "Id": 231,
    "county": 55,
    "fips": 39109,
    "countyName": "Miami",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 114866351.4,
    "employment": 1909,
    "laborIncome": 64999036.44,
    "taxProductionImports": 25918996.56,
    "laborOutputPerWorker": 143882.76,
    "laborIncomePerWorker": 52341.9,
    "totalEconomy": 4161975386,
    "totalEmployment": 56906,
    "totalIncome": 2545471788
},
{
    "Id": 232,
    "county": 56,
    "fips": 39111,
    "countyName": "Monroe",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 4614996.68,
    "employment": 119,
    "laborIncome": 2541127.9,
    "taxProductionImports": 1301745.23,
    "laborOutputPerWorker": 128938.06,
    "laborIncomePerWorker": 40106.58,
    "totalEconomy": 290990164.5,
    "totalEmployment": 5409,
    "totalIncome": 152715827.6
},
{
    "Id": 233,
    "county": 57,
    "fips": 39113,
    "countyName": "Montgomery",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 513027568.4,
    "employment": 7942,
    "laborIncome": 307245909.9,
    "taxProductionImports": 99487219.19,
    "laborOutputPerWorker": 153995.79,
    "laborIncomePerWorker": 60690.64,
    "totalEconomy": 29088032810,
    "totalEmployment": 325604,
    "totalIncome": 17822734429
},
{
    "Id": 234,
    "county": 58,
    "fips": 39115,
    "countyName": "Morgan",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 8155274.46,
    "employment": 150,
    "laborIncome": 4599257.22,
    "taxProductionImports": 1894965.09,
    "laborOutputPerWorker": 134613.42,
    "laborIncomePerWorker": 46356.86,
    "totalEconomy": 284846059.3,
    "totalEmployment": 4558,
    "totalIncome": 168010389.7
},
{
    "Id": 235,
    "county": 59,
    "fips": 39117,
    "countyName": "Morrow",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 9974276.11,
    "employment": 225,
    "laborIncome": 5844183.27,
    "taxProductionImports": 2481424.66,
    "laborOutputPerWorker": 135028.42,
    "laborIncomePerWorker": 46236.32,
    "totalEconomy": 609587559.4,
    "totalEmployment": 10407,
    "totalIncome": 363893591
},
{
    "Id": 236,
    "county": 60,
    "fips": 39119,
    "countyName": "Muskingum",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 71296614.6,
    "employment": 1357,
    "laborIncome": 42939431.74,
    "taxProductionImports": 15725164.1,
    "laborOutputPerWorker": 146513.33,
    "laborIncomePerWorker": 54506.11,
    "totalEconomy": 3780755649,
    "totalEmployment": 46983,
    "totalIncome": 2016031014
},
{
    "Id": 237,
    "county": 61,
    "fips": 39121,
    "countyName": "Noble",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 4392548.96,
    "employment": 98,
    "laborIncome": 2381751.55,
    "taxProductionImports": 1230623.35,
    "laborOutputPerWorker": 126392.48,
    "laborIncomePerWorker": 38946.6,
    "totalEconomy": 358758386.4,
    "totalEmployment": 4888,
    "totalIncome": 204161956.9
},
{
    "Id": 238,
    "county": 62,
    "fips": 39123,
    "countyName": "Ottawa",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 26999910.59,
    "employment": 661,
    "laborIncome": 14749287.99,
    "taxProductionImports": 8672034.57,
    "laborOutputPerWorker": 131671.13,
    "laborIncomePerWorker": 45116.01,
    "totalEconomy": 1882522665,
    "totalEmployment": 20665,
    "totalIncome": 910078674.8
},
{
    "Id": 239,
    "county": 63,
    "fips": 39125,
    "countyName": "Paulding",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 7018493.55,
    "employment": 145,
    "laborIncome": 3974156.11,
    "taxProductionImports": 1793210.26,
    "laborOutputPerWorker": 129171.31,
    "laborIncomePerWorker": 42009.48,
    "totalEconomy": 473398160.2,
    "totalEmployment": 7618,
    "totalIncome": 278671835.9
},
{
    "Id": 240,
    "county": 64,
    "fips": 39127,
    "countyName": "Perry",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 13410177.15,
    "employment": 312,
    "laborIncome": 7852186.22,
    "taxProductionImports": 3494650.48,
    "laborOutputPerWorker": 135886.75,
    "laborIncomePerWorker": 49090.18,
    "totalEconomy": 702597434.6,
    "totalEmployment": 9792,
    "totalIncome": 415826629.5
},
{
    "Id": 241,
    "county": 65,
    "fips": 39129,
    "countyName": "Pickaway",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 26719134.16,
    "employment": 516,
    "laborIncome": 16074614.84,
    "taxProductionImports": 6235996.43,
    "laborOutputPerWorker": 145913.58,
    "laborIncomePerWorker": 55224.36,
    "totalEconomy": 1591171161,
    "totalEmployment": 21056,
    "totalIncome": 1030079339
},
{
    "Id": 242,
    "county": 66,
    "fips": 39131,
    "countyName": "Pike",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 15219798.5,
    "employment": 369,
    "laborIncome": 9235566.96,
    "taxProductionImports": 3981307.52,
    "laborOutputPerWorker": 131567.19,
    "laborIncomePerWorker": 44277.02,
    "totalEconomy": 1070334939,
    "totalEmployment": 13035,
    "totalIncome": 711041714.1
},
{
    "Id": 243,
    "county": 67,
    "fips": 39133,
    "countyName": "Portage",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 171730502.8,
    "employment": 2365,
    "laborIncome": 101138074.6,
    "taxProductionImports": 31595321.87,
    "laborOutputPerWorker": 151302.1,
    "laborIncomePerWorker": 57970.75,
    "totalEconomy": 6778445655,
    "totalEmployment": 83265,
    "totalIncome": 3923076961
},
{
    "Id": 244,
    "county": 68,
    "fips": 39135,
    "countyName": "Preble",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 22007990.31,
    "employment": 608,
    "laborIncome": 11789399.24,
    "taxProductionImports": 6882521.67,
    "laborOutputPerWorker": 124285.8,
    "laborIncomePerWorker": 37261.09,
    "totalEconomy": 1120245977,
    "totalEmployment": 18013,
    "totalIncome": 687144010.4
},
{
    "Id": 245,
    "county": 69,
    "fips": 39137,
    "countyName": "Putnam",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 21584205.24,
    "employment": 401,
    "laborIncome": 12152042.88,
    "taxProductionImports": 5238518.24,
    "laborOutputPerWorker": 135034.32,
    "laborIncomePerWorker": 46252.15,
    "totalEconomy": 1271105089,
    "totalEmployment": 19591,
    "totalIncome": 768528314.1
},
{
    "Id": 246,
    "county": 70,
    "fips": 39139,
    "countyName": "Richland",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 100031999.9,
    "employment": 1737,
    "laborIncome": 60297006.59,
    "taxProductionImports": 22145414.95,
    "laborOutputPerWorker": 152002.8,
    "laborIncomePerWorker": 59795.62,
    "totalEconomy": 4550594470,
    "totalEmployment": 64075,
    "totalIncome": 2871087954
},
{
    "Id": 247,
    "county": 71,
    "fips": 39141,
    "countyName": "Ross",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 47219599.14,
    "employment": 1017,
    "laborIncome": 28434902.69,
    "taxProductionImports": 11848786.58,
    "laborOutputPerWorker": 143335.77,
    "laborIncomePerWorker": 53046,
    "totalEconomy": 2866885847,
    "totalEmployment": 35579,
    "totalIncome": 1822260275
},
{
    "Id": 248,
    "county": 72,
    "fips": 39143,
    "countyName": "Sandusky",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 43734880.11,
    "employment": 840,
    "laborIncome": 26069532.06,
    "taxProductionImports": 9457260.94,
    "laborOutputPerWorker": 146939.3,
    "laborIncomePerWorker": 54412.49,
    "totalEconomy": 2760828151,
    "totalEmployment": 34118,
    "totalIncome": 1631045090
},
{
    "Id": 249,
    "county": 73,
    "fips": 39145,
    "countyName": "Scioto",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 39451325.17,
    "employment": 898,
    "laborIncome": 24066799.14,
    "taxProductionImports": 9584465.25,
    "laborOutputPerWorker": 138885.97,
    "laborIncomePerWorker": 49331.76,
    "totalEconomy": 2228435683,
    "totalEmployment": 32475,
    "totalIncome": 1448223374
},
{
    "Id": 250,
    "county": 74,
    "fips": 39147,
    "countyName": "Seneca",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 39558159.46,
    "employment": 695,
    "laborIncome": 22497375.57,
    "taxProductionImports": 8707565.97,
    "laborOutputPerWorker": 146103.61,
    "laborIncomePerWorker": 53499.52,
    "totalEconomy": 1917097420,
    "totalEmployment": 28075,
    "totalIncome": 1159588109
},
{
    "Id": 251,
    "county": 75,
    "fips": 39149,
    "countyName": "Shelby",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 49441617.67,
    "employment": 769,
    "laborIncome": 28059672.76,
    "taxProductionImports": 10811885.17,
    "laborOutputPerWorker": 144217.9,
    "laborIncomePerWorker": 53128.32,
    "totalEconomy": 3010714574,
    "totalEmployment": 35325,
    "totalIncome": 1945896711
},
{
    "Id": 252,
    "county": 76,
    "fips": 39151,
    "countyName": "Stark",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 343086006.3,
    "employment": 5915,
    "laborIncome": 203369622.8,
    "taxProductionImports": 73373600.66,
    "laborOutputPerWorker": 150079.65,
    "laborIncomePerWorker": 57860.17,
    "totalEconomy": 16828271608,
    "totalEmployment": 204971,
    "totalIncome": 9883919732
},
{
    "Id": 253,
    "county": 77,
    "fips": 39153,
    "countyName": "Summit",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 692017760.4,
    "employment": 9289,
    "laborIncome": 415476738.2,
    "taxProductionImports": 122426549.8,
    "laborOutputPerWorker": 163617.9,
    "laborIncomePerWorker": 68529.11,
    "totalEconomy": 30391421246,
    "totalEmployment": 345561,
    "totalIncome": 18797876030
},
{
    "Id": 254,
    "county": 78,
    "fips": 39155,
    "countyName": "Trumbull",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 173727043.2,
    "employment": 3766,
    "laborIncome": 88830487.22,
    "taxProductionImports": 56307079.53,
    "laborOutputPerWorker": 138525.11,
    "laborIncomePerWorker": 49176.41,
    "totalEconomy": 7160996203,
    "totalEmployment": 94922,
    "totalIncome": 4297839867
},
{
    "Id": 255,
    "county": 79,
    "fips": 39157,
    "countyName": "Tuscarawas",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 75635785.56,
    "employment": 1345,
    "laborIncome": 44514165.35,
    "taxProductionImports": 17614466.63,
    "laborOutputPerWorker": 145971.91,
    "laborIncomePerWorker": 55603.47,
    "totalEconomy": 4776581038,
    "totalEmployment": 48918,
    "totalIncome": 2565518783
},
{
    "Id": 256,
    "county": 80,
    "fips": 39159,
    "countyName": "Union",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 58907457.82,
    "employment": 774,
    "laborIncome": 34561589.43,
    "taxProductionImports": 10493896.12,
    "laborOutputPerWorker": 156575.45,
    "laborIncomePerWorker": 61863.9,
    "totalEconomy": 4766480779,
    "totalEmployment": 41726,
    "totalIncome": 2725568550
},
{
    "Id": 257,
    "county": 81,
    "fips": 39161,
    "countyName": "Van Wert",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 16702275.08,
    "employment": 302,
    "laborIncome": 9586316.28,
    "taxProductionImports": 4106140.99,
    "laborOutputPerWorker": 138412.08,
    "laborIncomePerWorker": 48463.29,
    "totalEconomy": 1122580674,
    "totalEmployment": 14777,
    "totalIncome": 678450141.6
},
{
    "Id": 258,
    "county": 82,
    "fips": 39163,
    "countyName": "Vinton",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 2269562.62,
    "employment": 55,
    "laborIncome": 1261472.54,
    "taxProductionImports": 636288.54,
    "laborOutputPerWorker": 127178.54,
    "laborIncomePerWorker": 39709.92,
    "totalEconomy": 224168469.3,
    "totalEmployment": 3335,
    "totalIncome": 143160957.9
},
{
    "Id": 259,
    "county": 83,
    "fips": 39165,
    "countyName": "Warren",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 253639814.8,
    "employment": 3372,
    "laborIncome": 151976418.2,
    "taxProductionImports": 43433845.13,
    "laborOutputPerWorker": 164539.45,
    "laborIncomePerWorker": 67689.46,
    "totalEconomy": 11287597988,
    "totalEmployment": 120380,
    "totalIncome": 6753727556
},
{
    "Id": 260,
    "county": 84,
    "fips": 39167,
    "countyName": "Washington",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 47467171.37,
    "employment": 919,
    "laborIncome": 27985379.98,
    "taxProductionImports": 10929614.49,
    "laborOutputPerWorker": 144331.4,
    "laborIncomePerWorker": 53390.96,
    "totalEconomy": 4211696286,
    "totalEmployment": 33761,
    "totalIncome": 1707603612
},
{
    "Id": 261,
    "county": 85,
    "fips": 39169,
    "countyName": "Wayne",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 105421027.6,
    "employment": 1638,
    "laborIncome": 60858922.74,
    "taxProductionImports": 23052043.95,
    "laborOutputPerWorker": 147590.04,
    "laborIncomePerWorker": 56663.94,
    "totalEconomy": 5986535399,
    "totalEmployment": 70092,
    "totalIncome": 3543366070
},
{
    "Id": 262,
    "county": 86,
    "fips": 39171,
    "countyName": "Williams",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 29073723.84,
    "employment": 493,
    "laborIncome": 16137077.88,
    "taxProductionImports": 6828863.64,
    "laborOutputPerWorker": 134329.76,
    "laborIncomePerWorker": 44799.39,
    "totalEconomy": 1715547890,
    "totalEmployment": 23696,
    "totalIncome": 1078124701
},
{
    "Id": 263,
    "county": 87,
    "fips": 39173,
    "countyName": "Wood",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 148841281.6,
    "employment": 2340,
    "laborIncome": 87839441.6,
    "taxProductionImports": 30787271.79,
    "laborOutputPerWorker": 145099.31,
    "laborIncomePerWorker": 53925.44,
    "totalEconomy": 7168113759,
    "totalEmployment": 85611,
    "totalIncome": 4481666605
},
{
    "Id": 264,
    "county": 88,
    "fips": 39175,
    "countyName": "Wyandot",
    "indicator": "wholesale/retail",
    "year": 2017,
    "valueAdded": 13220932.23,
    "employment": 269,
    "laborIncome": 7275461.47,
    "taxProductionImports": 3401701.81,
    "laborOutputPerWorker": 131555.66,
    "laborIncomePerWorker": 41973.65,
    "totalEconomy": 1020010896,
    "totalEmployment": 13535,
    "totalIncome": 607656573.6
},
{
    "Id": 265,
    "county": 1,
    "fips": 39001,
    "countyName": "Adams",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 26132407.72,
    "employment": 1200,
    "laborIncome": 14914498.65,
    "taxProductionImports": -1207169.58,
    "laborOutputPerWorker": 1599065.66,
    "laborIncomePerWorker": 593001.07,
    "totalEconomy": 754367053.85,
    "totalEmployment": 12178,
    "totalIncome": 390608771.14
},
{
    "Id": 266,
    "county": 2,
    "fips": 39003,
    "countyName": "Allen",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 78125934.03,
    "employment": 1312,
    "laborIncome": 39739086.25,
    "taxProductionImports": 1426846.58,
    "laborOutputPerWorker": 2854361.39,
    "laborIncomePerWorker": 815758.87,
    "totalEconomy": 6642666490.57,
    "totalEmployment": 64290,
    "totalIncome": 3164228430.99
},
{
    "Id": 267,
    "county": 3,
    "fips": 39005,
    "countyName": "Ashland",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 74074340.39,
    "employment": 1278,
    "laborIncome": 31429966.73,
    "taxProductionImports": 1333883.84,
    "laborOutputPerWorker": 4869829.92,
    "laborIncomePerWorker": 685051.98,
    "totalEconomy": 1750415277.16,
    "totalEmployment": 27521,
    "totalIncome": 1073760708.12
},
{
    "Id": 268,
    "county": 4,
    "fips": 39007,
    "countyName": "Ashtabula",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 46313267.28,
    "employment": 1294,
    "laborIncome": 19527477.11,
    "taxProductionImports": -120054.04,
    "laborOutputPerWorker": 1442656.74,
    "laborIncomePerWorker": 260540.1,
    "totalEconomy": 2925812407.17,
    "totalEmployment": 45743,
    "totalIncome": 1745974966.57
},
{
    "Id": 269,
    "county": 5,
    "fips": 39009,
    "countyName": "Athens",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 10832931.71,
    "employment": 687,
    "laborIncome": 4898961.91,
    "taxProductionImports": -301864.63,
    "laborOutputPerWorker": 1178051.89,
    "laborIncomePerWorker": 154418.32,
    "totalEconomy": 1849309886.62,
    "totalEmployment": 28016,
    "totalIncome": 1103726245.03
},
{
    "Id": 270,
    "county": 6,
    "fips": 39011,
    "countyName": "Auglaize",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 71793513.67,
    "employment": 1251,
    "laborIncome": 55805475.98,
    "taxProductionImports": -374122.65,
    "laborOutputPerWorker": 2516208.38,
    "laborIncomePerWorker": 930665.66,
    "totalEconomy": 2154621733.27,
    "totalEmployment": 28804,
    "totalIncome": 1285209420.75
},
{
    "Id": 271,
    "county": 7,
    "fips": 39013,
    "countyName": "Belmont",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 48773321.95,
    "employment": 889,
    "laborIncome": 22073329.25,
    "taxProductionImports": 1407195.44,
    "laborOutputPerWorker": 2942896.31,
    "laborIncomePerWorker": 387790.8,
    "totalEconomy": 2898814500.42,
    "totalEmployment": 32407,
    "totalIncome": 1505487126.74
},
{
    "Id": 272,
    "county": 8,
    "fips": 39015,
    "countyName": "Brown",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 32761777.87,
    "employment": 1257,
    "laborIncome": 9346438.04,
    "taxProductionImports": -787202.48,
    "laborOutputPerWorker": 1266165.52,
    "laborIncomePerWorker": 213619.25,
    "totalEconomy": 1070841039.23,
    "totalEmployment": 17945,
    "totalIncome": 528537502.56
},
{
    "Id": 273,
    "county": 9,
    "fips": 39017,
    "countyName": "Butler",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 49768789.85,
    "employment": 1345,
    "laborIncome": 39675145,
    "taxProductionImports": 223524.45,
    "laborOutputPerWorker": 1937121.6,
    "laborIncomePerWorker": 619500.83,
    "totalEconomy": 18726031720.08,
    "totalEmployment": 197699,
    "totalIncome": 10681823514.74
},
{
    "Id": 274,
    "county": 10,
    "fips": 39019,
    "countyName": "Carroll",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 27087761.9,
    "employment": 767,
    "laborIncome": 20531113.89,
    "taxProductionImports": -535643.98,
    "laborOutputPerWorker": 1955227.87,
    "laborIncomePerWorker": 604593.68,
    "totalEconomy": 960757509.69,
    "totalEmployment": 11841,
    "totalIncome": 437176134.8
},
{
    "Id": 275,
    "county": 11,
    "fips": 39021,
    "countyName": "Champaign",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 41533589.84,
    "employment": 951,
    "laborIncome": 37341249.85,
    "taxProductionImports": -150119.24,
    "laborOutputPerWorker": 1838796.4,
    "laborIncomePerWorker": 891983.44,
    "totalEconomy": 1079244909.63,
    "totalEmployment": 17332,
    "totalIncome": 683251618.79
},
{
    "Id": 276,
    "county": 12,
    "fips": 39023,
    "countyName": "Clark",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 49500983.52,
    "employment": 1113,
    "laborIncome": 25414428.63,
    "taxProductionImports": -359788.98,
    "laborOutputPerWorker": 1688365.48,
    "laborIncomePerWorker": 454724.04,
    "totalEconomy": 4453629622.16,
    "totalEmployment": 65386,
    "totalIncome": 2738321110.23
},
{
    "Id": 277,
    "county": 13,
    "fips": 39025,
    "countyName": "Clermont",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 38145667.62,
    "employment": 1532,
    "laborIncome": 21269038.76,
    "taxProductionImports": 696494.55,
    "laborOutputPerWorker": 1028580.34,
    "laborIncomePerWorker": 313122.76,
    "totalEconomy": 6960640326.21,
    "totalEmployment": 91602,
    "totalIncome": 4053746481.18
},
{
    "Id": 278,
    "county": 14,
    "fips": 39027,
    "countyName": "Clinton",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 53920762.32,
    "employment": 894,
    "laborIncome": 30740007.97,
    "taxProductionImports": 62789.45,
    "laborOutputPerWorker": 4304368.4,
    "laborIncomePerWorker": 621439.77,
    "totalEconomy": 1776180932.68,
    "totalEmployment": 22779,
    "totalIncome": 1110264741.55
},
{
    "Id": 279,
    "county": 15,
    "fips": 39029,
    "countyName": "Columbiana",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 56804685.99,
    "employment": 1285,
    "laborIncome": 46227509.58,
    "taxProductionImports": -352454.9,
    "laborOutputPerWorker": 1867830.93,
    "laborIncomePerWorker": 728262.11,
    "totalEconomy": 2955699822.04,
    "totalEmployment": 42949,
    "totalIncome": 1754260547.36
},
{
    "Id": 280,
    "county": 16,
    "fips": 39031,
    "countyName": "Coshocton",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 60830386.58,
    "employment": 1370,
    "laborIncome": 32249495.12,
    "taxProductionImports": 66722.42,
    "laborOutputPerWorker": 2767699.89,
    "laborIncomePerWorker": 543440.91,
    "totalEconomy": 1601216920.08,
    "totalEmployment": 18917,
    "totalIncome": 821372840.96
},
{
    "Id": 281,
    "county": 17,
    "fips": 39033,
    "countyName": "Crawford",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 56705986.08,
    "employment": 840,
    "laborIncome": 24123565.39,
    "taxProductionImports": 129747.8,
    "laborOutputPerWorker": 2297169.77,
    "laborIncomePerWorker": 552076.81,
    "totalEconomy": 1277601900.83,
    "totalEmployment": 19475,
    "totalIncome": 772936440.41
},
{
    "Id": 282,
    "county": 18,
    "fips": 39035,
    "countyName": "Cuyahoga",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 97944901.94,
    "employment": 2972,
    "laborIncome": 84559096.06,
    "taxProductionImports": 4430094.58,
    "laborOutputPerWorker": 2096998.97,
    "laborIncomePerWorker": 528051.66,
    "totalEconomy": 95196520915.12,
    "totalEmployment": 920704,
    "totalIncome": 58226951917.04
},
{
    "Id": 283,
    "county": 19,
    "fips": 39037,
    "countyName": "Darke",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 239806461.9,
    "employment": 2431,
    "laborIncome": 142468117.7,
    "taxProductionImports": 725835.86,
    "laborOutputPerWorker": 4185804.75,
    "laborIncomePerWorker": 1035372.86,
    "totalEconomy": 1936506432.24,
    "totalEmployment": 29450,
    "totalIncome": 1199093130.68
},
{
    "Id": 284,
    "county": 20,
    "fips": 39039,
    "countyName": "Defiance",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 74738470.54,
    "employment": 1111,
    "laborIncome": 63199747.64,
    "taxProductionImports": 1549121.15,
    "laborOutputPerWorker": 3202832.18,
    "laborIncomePerWorker": 956606.91,
    "totalEconomy": 1655173998.11,
    "totalEmployment": 22448,
    "totalIncome": 1011570926.19
},
{
    "Id": 285,
    "county": 21,
    "fips": 39041,
    "countyName": "Delaware",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 71115273.48,
    "employment": 1445,
    "laborIncome": 36342980.8,
    "taxProductionImports": 1737757.13,
    "laborOutputPerWorker": 2045526.71,
    "laborIncomePerWorker": 442343.98,
    "totalEconomy": 10843610770.17,
    "totalEmployment": 136964,
    "totalIncome": 6910714850.97
},
{
    "Id": 286,
    "county": 22,
    "fips": 39043,
    "countyName": "Erie",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 40597271.76,
    "employment": 1237,
    "laborIncome": 28161439.69,
    "taxProductionImports": 2855003.42,
    "laborOutputPerWorker": 2808144.3,
    "laborIncomePerWorker": 674354.38,
    "totalEconomy": 3655066143.06,
    "totalEmployment": 49835,
    "totalIncome": 2200419858.53
},
{
    "Id": 287,
    "county": 23,
    "fips": 39045,
    "countyName": "Fairfield",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 57092509.32,
    "employment": 1510,
    "laborIncome": 31921385.33,
    "taxProductionImports": 192901.88,
    "laborOutputPerWorker": 2338595.58,
    "laborIncomePerWorker": 510146.72,
    "totalEconomy": 4154705032.25,
    "totalEmployment": 64573,
    "totalIncome": 2430262447.52
},
{
    "Id": 288,
    "county": 24,
    "fips": 39047,
    "countyName": "Fayette",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 41430653.47,
    "employment": 646,
    "laborIncome": 27567037.31,
    "taxProductionImports": 178353.33,
    "laborOutputPerWorker": 1843785.08,
    "laborIncomePerWorker": 694008.83,
    "totalEconomy": 1039406462.1,
    "totalEmployment": 17332,
    "totalIncome": 615157312.99
},
{
    "Id": 289,
    "county": 25,
    "fips": 39049,
    "countyName": "Franklin",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 163203968.6,
    "employment": 2723,
    "laborIncome": 120733325.1,
    "taxProductionImports": 4369630.26,
    "laborOutputPerWorker": 3432491.49,
    "laborIncomePerWorker": 469833.82,
    "totalEconomy": 93118197636.6,
    "totalEmployment": 918222,
    "totalIncome": 56769200094.53
},
{
    "Id": 290,
    "county": 26,
    "fips": 39051,
    "countyName": "Fulton",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 74574695.49,
    "employment": 1315,
    "laborIncome": 39398771.43,
    "taxProductionImports": -1784204.27,
    "laborOutputPerWorker": 3645732.26,
    "laborIncomePerWorker": 690430.13,
    "totalEconomy": 1773401942.35,
    "totalEmployment": 26929,
    "totalIncome": 1136244367.14
},
{
    "Id": 291,
    "county": 27,
    "fips": 39053,
    "countyName": "Gallia",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 15592444.54,
    "employment": 893,
    "laborIncome": 7696911.11,
    "taxProductionImports": -828897.93,
    "laborOutputPerWorker": 1345467.69,
    "laborIncomePerWorker": 185253.01,
    "totalEconomy": 1506358279.89,
    "totalEmployment": 16209,
    "totalIncome": 629125908.79
},
{
    "Id": 292,
    "county": 28,
    "fips": 39055,
    "countyName": "Geauga",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 56208056.68,
    "employment": 1359,
    "laborIncome": 37942086.69,
    "taxProductionImports": 425294.83,
    "laborOutputPerWorker": 2288372.69,
    "laborIncomePerWorker": 353799.88,
    "totalEconomy": 3742816889.03,
    "totalEmployment": 53684,
    "totalIncome": 2228093395.43
},
{
    "Id": 293,
    "county": 29,
    "fips": 39057,
    "countyName": "Greene",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 43688591.8,
    "employment": 1198,
    "laborIncome": 25237667.14,
    "taxProductionImports": 362235.74,
    "laborOutputPerWorker": 1358769.11,
    "laborIncomePerWorker": 445035.36,
    "totalEconomy": 9642560583.37,
    "totalEmployment": 105110,
    "totalIncome": 6058681306.54
},
{
    "Id": 294,
    "county": 30,
    "fips": 39059,
    "countyName": "Guernsey",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 20965101.3,
    "employment": 1159,
    "laborIncome": 10545551.53,
    "taxProductionImports": -788970.34,
    "laborOutputPerWorker": 1970317.75,
    "laborIncomePerWorker": 255377.6,
    "totalEconomy": 1595054307.57,
    "totalEmployment": 20987,
    "totalIncome": 909744860.5
},
{
    "Id": 295,
    "county": 31,
    "fips": 39061,
    "countyName": "Hamilton",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 111589093.3,
    "employment": 1630,
    "laborIncome": 74529745.41,
    "taxProductionImports": 4255624.86,
    "laborOutputPerWorker": 5978373.82,
    "laborIncomePerWorker": 958242.82,
    "totalEconomy": 70482980740.81,
    "totalEmployment": 648027,
    "totalIncome": 42590000667.83
},
{
    "Id": 296,
    "county": 32,
    "fips": 39063,
    "countyName": "Hancock",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 56118896.19,
    "employment": 1121,
    "laborIncome": 31484743.64,
    "taxProductionImports": 582781.19,
    "laborOutputPerWorker": 1717299.06,
    "laborIncomePerWorker": 507924.24,
    "totalEconomy": 4931274710.82,
    "totalEmployment": 56506,
    "totalIncome": 2996273249.86
},
{
    "Id": 297,
    "county": 33,
    "fips": 39065,
    "countyName": "Hardin",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 82800471.61,
    "employment": 1092,
    "laborIncome": 83055079.69,
    "taxProductionImports": 655794.56,
    "laborOutputPerWorker": 2561909.57,
    "laborIncomePerWorker": 1282153.12,
    "totalEconomy": 857016266.58,
    "totalEmployment": 13449,
    "totalIncome": 540393309.33
},
{
    "Id": 298,
    "county": 34,
    "fips": 39067,
    "countyName": "Harrison",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 10621659.65,
    "employment": 396,
    "laborIncome": 5723206.45,
    "taxProductionImports": -573518.81,
    "laborOutputPerWorker": 2075329.45,
    "laborIncomePerWorker": 533188.22,
    "totalEconomy": 487184291.94,
    "totalEmployment": 5517,
    "totalIncome": 238109478.52
},
{
    "Id": 299,
    "county": 35,
    "fips": 39069,
    "countyName": "Henry",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 54239652.3,
    "employment": 990,
    "laborIncome": 40313362.28,
    "taxProductionImports": 115596.02,
    "laborOutputPerWorker": 2461921.26,
    "laborIncomePerWorker": 849405.36,
    "totalEconomy": 1163234414.16,
    "totalEmployment": 16253,
    "totalIncome": 688006614.55
},
{
    "Id": 300,
    "county": 36,
    "fips": 39071,
    "countyName": "Highland",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 45616926.64,
    "employment": 1404,
    "laborIncome": 27218005.19,
    "taxProductionImports": -909299.96,
    "laborOutputPerWorker": 1898658.03,
    "laborIncomePerWorker": 922500.76,
    "totalEconomy": 961520442.89,
    "totalEmployment": 15927,
    "totalIncome": 545085085.53
},
{
    "Id": 301,
    "county": 37,
    "fips": 39073,
    "countyName": "Hocking",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 6799259.6,
    "employment": 481,
    "laborIncome": 2816152.58,
    "taxProductionImports": 128851.36,
    "laborOutputPerWorker": 852454.17,
    "laborIncomePerWorker": 132621.2,
    "totalEconomy": 648428103.41,
    "totalEmployment": 9690,
    "totalIncome": 340386907.29
},
{
    "Id": 302,
    "county": 38,
    "fips": 39075,
    "countyName": "Holmes",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 132411907.6,
    "employment": 2376,
    "laborIncome": 104699903,
    "taxProductionImports": -946128.1,
    "laborOutputPerWorker": 2747727.1,
    "laborIncomePerWorker": 1055216.08,
    "totalEconomy": 1968317546.63,
    "totalEmployment": 32350,
    "totalIncome": 1340411219.12
},
{
    "Id": 303,
    "county": 39,
    "fips": 39077,
    "countyName": "Huron",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 89862265.08,
    "employment": 1327,
    "laborIncome": 47061778.3,
    "taxProductionImports": -280397.26,
    "laborOutputPerWorker": 2707013.09,
    "laborIncomePerWorker": 753334.54,
    "totalEconomy": 2226553149.69,
    "totalEmployment": 28683,
    "totalIncome": 1311308031.21
},
{
    "Id": 304,
    "county": 40,
    "fips": 39079,
    "countyName": "Jackson",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 22937105.99,
    "employment": 536,
    "laborIncome": 9957280.98,
    "taxProductionImports": -347841.98,
    "laborOutputPerWorker": 1904551.22,
    "laborIncomePerWorker": 329212.25,
    "totalEconomy": 938064693.66,
    "totalEmployment": 14675,
    "totalIncome": 562574305.69
},
{
    "Id": 305,
    "county": 41,
    "fips": 39081,
    "countyName": "Jefferson",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 9879886.72,
    "employment": 457,
    "laborIncome": 3891672.94,
    "taxProductionImports": -346303.98,
    "laborOutputPerWorker": 1724295.32,
    "laborIncomePerWorker": 339518.17,
    "totalEconomy": 2566153991.9,
    "totalEmployment": 27416,
    "totalIncome": 1217919020.49
},
{
    "Id": 306,
    "county": 42,
    "fips": 39083,
    "countyName": "Knox",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 50521887.39,
    "employment": 1667,
    "laborIncome": 26272374.89,
    "taxProductionImports": -167102.44,
    "laborOutputPerWorker": 1501038.84,
    "laborIncomePerWorker": 387114.18,
    "totalEconomy": 2136062215.67,
    "totalEmployment": 30625,
    "totalIncome": 1312392515.79
},
{
    "Id": 307,
    "county": 43,
    "fips": 39085,
    "countyName": "Lake",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 82942001.26,
    "employment": 988,
    "laborIncome": 42108755.7,
    "taxProductionImports": 747946.69,
    "laborOutputPerWorker": 3373813.78,
    "laborIncomePerWorker": 492551.38,
    "totalEconomy": 10617995628.95,
    "totalEmployment": 117716,
    "totalIncome": 5944295728.24
},
{
    "Id": 308,
    "county": 44,
    "fips": 39087,
    "countyName": "Lawrence",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 6325381.84,
    "employment": 749,
    "laborIncome": 2575745.78,
    "taxProductionImports": -36257.84,
    "laborOutputPerWorker": 721204.13,
    "laborIncomePerWorker": 117011.2,
    "totalEconomy": 1144051784.96,
    "totalEmployment": 18640,
    "totalIncome": 670865912.96
},
{
    "Id": 309,
    "county": 45,
    "fips": 39089,
    "countyName": "Licking",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 80959369.19,
    "employment": 2105,
    "laborIncome": 74896636.23,
    "taxProductionImports": -563294.4,
    "laborOutputPerWorker": 1510513.03,
    "laborIncomePerWorker": 665744.84,
    "totalEconomy": 5705629059.02,
    "totalEmployment": 74399,
    "totalIncome": 3327378965.39
},
{
    "Id": 310,
    "county": 46,
    "fips": 39091,
    "countyName": "Logan",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 47525570.46,
    "employment": 911,
    "laborIncome": 28441092.18,
    "taxProductionImports": -44330.49,
    "laborOutputPerWorker": 1998786.73,
    "laborIncomePerWorker": 647809.75,
    "totalEconomy": 2054685543.96,
    "totalEmployment": 24585,
    "totalIncome": 1256558936.6
},
{
    "Id": 311,
    "county": 47,
    "fips": 39093,
    "countyName": "Lorain",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 75209443.13,
    "employment": 1735,
    "laborIncome": 61256199.95,
    "taxProductionImports": 878658.81,
    "laborOutputPerWorker": 1949050.5,
    "laborIncomePerWorker": 627592.42,
    "totalEconomy": 10263339652.71,
    "totalEmployment": 126142,
    "totalIncome": 6050242026.28
},
{
    "Id": 312,
    "county": 48,
    "fips": 39095,
    "countyName": "Lucas",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 65557760.37,
    "employment": 1073,
    "laborIncome": 35337380.27,
    "taxProductionImports": 1702307.71,
    "laborOutputPerWorker": 2451907.86,
    "laborIncomePerWorker": 521119.86,
    "totalEconomy": 27821667781.16,
    "totalEmployment": 261164,
    "totalIncome": 14481328383.98
},
{
    "Id": 313,
    "county": 49,
    "fips": 39097,
    "countyName": "Madison",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 69435722.83,
    "employment": 989,
    "laborIncome": 42326826.68,
    "taxProductionImports": 108156.22,
    "laborOutputPerWorker": 2338587.94,
    "laborIncomePerWorker": 861419.89,
    "totalEconomy": 1546109233.43,
    "totalEmployment": 22433,
    "totalIncome": 1038857652.61
},
{
    "Id": 314,
    "county": 50,
    "fips": 39099,
    "countyName": "Mahoning",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 43627477.04,
    "employment": 814,
    "laborIncome": 20413000.93,
    "taxProductionImports": 61358.97,
    "laborOutputPerWorker": 1942907.91,
    "laborIncomePerWorker": 441806.85,
    "totalEconomy": 9051758806.74,
    "totalEmployment": 128199,
    "totalIncome": 5872683349.78
},
{
    "Id": 315,
    "county": 51,
    "fips": 39101,
    "countyName": "Marion",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 82089222.94,
    "employment": 1112,
    "laborIncome": 55833172.18,
    "taxProductionImports": 1768191.44,
    "laborOutputPerWorker": 5057024.94,
    "laborIncomePerWorker": 1428443.78,
    "totalEconomy": 2664768172.7,
    "totalEmployment": 33146,
    "totalIncome": 1607268443.56
},
{
    "Id": 316,
    "county": 52,
    "fips": 39103,
    "countyName": "Medina",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 67362783.8,
    "employment": 1377,
    "laborIncome": 37473795.41,
    "taxProductionImports": 2094453.36,
    "laborOutputPerWorker": 4014269.34,
    "laborIncomePerWorker": 730201.16,
    "totalEconomy": 6481248228.04,
    "totalEmployment": 86106,
    "totalIncome": 3630185361.68
},
{
    "Id": 317,
    "county": 53,
    "fips": 39105,
    "countyName": "Meigs",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 12179096.73,
    "employment": 556,
    "laborIncome": 5657965.88,
    "taxProductionImports": -367474.62,
    "laborOutputPerWorker": 1325228.19,
    "laborIncomePerWorker": 351469.8,
    "totalEconomy": 340474070.72,
    "totalEmployment": 6033,
    "totalIncome": 185093558.77
},
{
    "Id": 318,
    "county": 54,
    "fips": 39107,
    "countyName": "Mercer",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 287020607.2,
    "employment": 2128,
    "laborIncome": 179354227.9,
    "taxProductionImports": 1054113.55,
    "laborOutputPerWorker": 7007115.58,
    "laborIncomePerWorker": 2889721.93,
    "totalEconomy": 2086448826.14,
    "totalEmployment": 28571,
    "totalIncome": 1252954790.05
},
{
    "Id": 319,
    "county": 55,
    "fips": 39109,
    "countyName": "Miami",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 41628715.89,
    "employment": 1474,
    "laborIncome": 22903397.69,
    "taxProductionImports": 216755.96,
    "laborOutputPerWorker": 899696.58,
    "laborIncomePerWorker": 275044.5,
    "totalEconomy": 4017206101.04,
    "totalEmployment": 57058,
    "totalIncome": 2469996868.78
},
{
    "Id": 320,
    "county": 56,
    "fips": 39111,
    "countyName": "Monroe",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 13856148.76,
    "employment": 719,
    "laborIncome": 6666618.14,
    "taxProductionImports": -749470.91,
    "laborOutputPerWorker": 1490003.85,
    "laborIncomePerWorker": 394020.74,
    "totalEconomy": 306914893.02,
    "totalEmployment": 6187,
    "totalIncome": 167730784.7
},
{
    "Id": 321,
    "county": 57,
    "fips": 39113,
    "countyName": "Montgomery",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 53434618.8,
    "employment": 1325,
    "laborIncome": 47669713.67,
    "taxProductionImports": 291521.17,
    "laborOutputPerWorker": 1473687.16,
    "laborIncomePerWorker": 663162.83,
    "totalEconomy": 27735849459.23,
    "totalEmployment": 309959,
    "totalIncome": 17013344003.36
},
{
    "Id": 322,
    "county": 58,
    "fips": 39115,
    "countyName": "Morgan",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 13011573.63,
    "employment": 472,
    "laborIncome": 3204903.92,
    "taxProductionImports": -679193.31,
    "laborOutputPerWorker": 2328982.65,
    "laborIncomePerWorker": 679980.17,
    "totalEconomy": 266257427.25,
    "totalEmployment": 5334,
    "totalIncome": 153284219.46
},
{
    "Id": 323,
    "county": 59,
    "fips": 39117,
    "countyName": "Morrow",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 42743432.93,
    "employment": 1021,
    "laborIncome": 22138788.39,
    "taxProductionImports": -99304.01,
    "laborOutputPerWorker": 1623866.91,
    "laborIncomePerWorker": 489757.54,
    "totalEconomy": 1055850097.01,
    "totalEmployment": 11135,
    "totalIncome": 371932527.91
},
{
    "Id": 324,
    "county": 60,
    "fips": 39119,
    "countyName": "Muskingum",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 30721596.6,
    "employment": 1232,
    "laborIncome": 16742090.98,
    "taxProductionImports": -1811678.6,
    "laborOutputPerWorker": 1854847.73,
    "laborIncomePerWorker": 525884.15,
    "totalEconomy": 3078496866.31,
    "totalEmployment": 43379,
    "totalIncome": 1859152215.05
},
{
    "Id": 325,
    "county": 61,
    "fips": 39121,
    "countyName": "Noble",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 8823935.2,
    "employment": 512,
    "laborIncome": 3327182.74,
    "taxProductionImports": -723889.82,
    "laborOutputPerWorker": 1384886.51,
    "laborIncomePerWorker": 312638.67,
    "totalEconomy": 381929828.05,
    "totalEmployment": 5194,
    "totalIncome": 195580028.7
},
{
    "Id": 326,
    "county": 62,
    "fips": 39123,
    "countyName": "Ottawa",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 42511100.66,
    "employment": 779,
    "laborIncome": 18706323.27,
    "taxProductionImports": 598904.43,
    "laborOutputPerWorker": 1729556.61,
    "laborIncomePerWorker": 466769.74,
    "totalEconomy": 1882586058.87,
    "totalEmployment": 20084,
    "totalIncome": 852123909.18
},
{
    "Id": 327,
    "county": 63,
    "fips": 39125,
    "countyName": "Paulding",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 54709543.01,
    "employment": 921,
    "laborIncome": 75559461.33,
    "taxProductionImports": 393484.53,
    "laborOutputPerWorker": 1633512.45,
    "laborIncomePerWorker": 1259788.7,
    "totalEconomy": 483420907.84,
    "totalEmployment": 7567,
    "totalIncome": 309158032.79
},
{
    "Id": 328,
    "county": 64,
    "fips": 39127,
    "countyName": "Perry",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 14860292.92,
    "employment": 658,
    "laborIncome": 3791828.3,
    "taxProductionImports": -462813.21,
    "laborOutputPerWorker": 1357584.87,
    "laborIncomePerWorker": 276173.06,
    "totalEconomy": 536805451.96,
    "totalEmployment": 8424,
    "totalIncome": 288305553.36
},
{
    "Id": 329,
    "county": 65,
    "fips": 39129,
    "countyName": "Pickaway",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 58449378.45,
    "employment": 1096,
    "laborIncome": 62415625.71,
    "taxProductionImports": 135558.1,
    "laborOutputPerWorker": 1961078.91,
    "laborIncomePerWorker": 1196705.3,
    "totalEconomy": 1469763605.64,
    "totalEmployment": 20078,
    "totalIncome": 943957661.01
},
{
    "Id": 330,
    "county": 66,
    "fips": 39131,
    "countyName": "Pike",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 20526229.72,
    "employment": 474,
    "laborIncome": 18047240.87,
    "taxProductionImports": -265675.31,
    "laborOutputPerWorker": 2394420.73,
    "laborIncomePerWorker": 1460255.11,
    "totalEconomy": 1080780724.81,
    "totalEmployment": 13181,
    "totalIncome": 676630642.49
},
{
    "Id": 331,
    "county": 67,
    "fips": 39133,
    "countyName": "Portage",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 33902467.22,
    "employment": 1122,
    "laborIncome": 16819051.94,
    "taxProductionImports": 25245.01,
    "laborOutputPerWorker": 1182212.45,
    "laborIncomePerWorker": 269573.15,
    "totalEconomy": 6153477202.51,
    "totalEmployment": 82453,
    "totalIncome": 3810308568.74
},
{
    "Id": 332,
    "county": 68,
    "fips": 39135,
    "countyName": "Preble",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 52009567.82,
    "employment": 1409,
    "laborIncome": 55034636.04,
    "taxProductionImports": -896220.65,
    "laborOutputPerWorker": 1587288.83,
    "laborIncomePerWorker": 931340.93,
    "totalEconomy": 1031965637.09,
    "totalEmployment": 16433,
    "totalIncome": 607456742.47
},
{
    "Id": 333,
    "county": 69,
    "fips": 39137,
    "countyName": "Putnam",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 145093953.1,
    "employment": 1912,
    "laborIncome": 93533148.71,
    "taxProductionImports": 4098100.03,
    "laborOutputPerWorker": 3157321.18,
    "laborIncomePerWorker": 1243256.67,
    "totalEconomy": 1324877177.53,
    "totalEmployment": 18676,
    "totalIncome": 739234658.39
},
{
    "Id": 334,
    "county": 70,
    "fips": 39139,
    "countyName": "Richland",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 60865434.6,
    "employment": 1265,
    "laborIncome": 47858282.69,
    "taxProductionImports": -293817.52,
    "laborOutputPerWorker": 2263656.47,
    "laborIncomePerWorker": 940230.86,
    "totalEconomy": 4385743452.01,
    "totalEmployment": 62487,
    "totalIncome": 2684050747.09
},
{
    "Id": 335,
    "county": 71,
    "fips": 39141,
    "countyName": "Ross",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 33267855.42,
    "employment": 1116,
    "laborIncome": 24938958.1,
    "taxProductionImports": -303943.91,
    "laborOutputPerWorker": 1242989.02,
    "laborIncomePerWorker": 599354.83,
    "totalEconomy": 2711634375.21,
    "totalEmployment": 34919,
    "totalIncome": 1722690534.03
},
{
    "Id": 336,
    "county": 72,
    "fips": 39143,
    "countyName": "Sandusky",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 74346534.43,
    "employment": 1117,
    "laborIncome": 35342075.02,
    "taxProductionImports": 1288556.08,
    "laborOutputPerWorker": 2141920.14,
    "laborIncomePerWorker": 549091.61,
    "totalEconomy": 2866233783.01,
    "totalEmployment": 35809,
    "totalIncome": 1668233888.12
},
{
    "Id": 337,
    "county": 73,
    "fips": 39145,
    "countyName": "Scioto",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 24304785.32,
    "employment": 1018,
    "laborIncome": 7183311.22,
    "taxProductionImports": 182756.79,
    "laborOutputPerWorker": 1366560.43,
    "laborIncomePerWorker": 130501.84,
    "totalEconomy": 2307993397.19,
    "totalEmployment": 31488,
    "totalIncome": 1356182385.87
},
{
    "Id": 338,
    "county": 74,
    "fips": 39147,
    "countyName": "Seneca",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 56748780.39,
    "employment": 1441,
    "laborIncome": 24323881.67,
    "taxProductionImports": 57258.26,
    "laborOutputPerWorker": 2258854.11,
    "laborIncomePerWorker": 496221.59,
    "totalEconomy": 1793111677.46,
    "totalEmployment": 25442,
    "totalIncome": 1023140494.4
},
{
    "Id": 339,
    "county": 75,
    "fips": 39149,
    "countyName": "Shelby",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 80446033.55,
    "employment": 1197,
    "laborIncome": 58187054.61,
    "taxProductionImports": -410670.24,
    "laborOutputPerWorker": 4903407.24,
    "laborIncomePerWorker": 1265896.49,
    "totalEconomy": 3177680488.8,
    "totalEmployment": 35010,
    "totalIncome": 1828292144.85
},
{
    "Id": 340,
    "county": 76,
    "fips": 39151,
    "countyName": "Stark",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 82669192.46,
    "employment": 1782,
    "laborIncome": 73088952.07,
    "taxProductionImports": 286571.13,
    "laborOutputPerWorker": 2318445.91,
    "laborIncomePerWorker": 868537.99,
    "totalEconomy": 17621076554.15,
    "totalEmployment": 205504,
    "totalIncome": 9699356422.44
},
{
    "Id": 341,
    "county": 77,
    "fips": 39153,
    "countyName": "Summit",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 53052421.3,
    "employment": 1141,
    "laborIncome": 39530590.21,
    "taxProductionImports": 1312979.85,
    "laborOutputPerWorker": 2521857.54,
    "laborIncomePerWorker": 451993.76,
    "totalEconomy": 29332130164.83,
    "totalEmployment": 337387,
    "totalIncome": 18452440621.06
},
{
    "Id": 342,
    "county": 78,
    "fips": 39155,
    "countyName": "Trumbull",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 46191278.77,
    "employment": 1213,
    "laborIncome": 20583586.07,
    "taxProductionImports": 215602.65,
    "laborOutputPerWorker": 2690487.01,
    "laborIncomePerWorker": 444330.24,
    "totalEconomy": 6979874771.89,
    "totalEmployment": 88293,
    "totalIncome": 4055841366.2
},
{
    "Id": 343,
    "county": 79,
    "fips": 39157,
    "countyName": "Tuscarawas",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 52437891.41,
    "employment": 1184,
    "laborIncome": 41597638.7,
    "taxProductionImports": -522814.36,
    "laborOutputPerWorker": 2199485.72,
    "laborIncomePerWorker": 879442.48,
    "totalEconomy": 3514031100.51,
    "totalEmployment": 46549,
    "totalIncome": 2081487962.11
},
{
    "Id": 344,
    "county": 80,
    "fips": 39159,
    "countyName": "Union",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 459647601.4,
    "employment": 2565,
    "laborIncome": 189210740.8,
    "taxProductionImports": 16914086.17,
    "laborOutputPerWorker": 3529203.17,
    "laborIncomePerWorker": 722596.83,
    "totalEconomy": 4225350827.57,
    "totalEmployment": 38043,
    "totalIncome": 2391128078.99
},
{
    "Id": 345,
    "county": 81,
    "fips": 39161,
    "countyName": "Van Wert",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 89276531.35,
    "employment": 1007,
    "laborIncome": 73231131.01,
    "taxProductionImports": 1238307.76,
    "laborOutputPerWorker": 3629947.41,
    "laborIncomePerWorker": 1484033.06,
    "totalEconomy": 1045798993.33,
    "totalEmployment": 14036,
    "totalIncome": 618945547.79
},
{
    "Id": 346,
    "county": 82,
    "fips": 39163,
    "countyName": "Vinton",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 8559330.84,
    "employment": 284,
    "laborIncome": 7467834.24,
    "taxProductionImports": -9971.3,
    "laborOutputPerWorker": 982550.39,
    "laborIncomePerWorker": 304886.51,
    "totalEconomy": 209604097.09,
    "totalEmployment": 3358,
    "totalIncome": 126466634.18
},
{
    "Id": 347,
    "county": 83,
    "fips": 39165,
    "countyName": "Warren",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 46224334.82,
    "employment": 1448,
    "laborIncome": 25569583.4,
    "taxProductionImports": 460537.92,
    "laborOutputPerWorker": 943467.84,
    "laborIncomePerWorker": 213764.2,
    "totalEconomy": 10460071517.5,
    "totalEmployment": 114084,
    "totalIncome": 5895730256.97
},
{
    "Id": 348,
    "county": 84,
    "fips": 39167,
    "countyName": "Washington",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 30966222.92,
    "employment": 1214,
    "laborIncome": 17350581.38,
    "taxProductionImports": -531567.21,
    "laborOutputPerWorker": 2041337.84,
    "laborIncomePerWorker": 547455.33,
    "totalEconomy": 3073221267.81,
    "totalEmployment": 33087,
    "totalIncome": 1687618381.22
},
{
    "Id": 349,
    "county": 85,
    "fips": 39169,
    "countyName": "Wayne",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 283008467.8,
    "employment": 3111,
    "laborIncome": 164285819,
    "taxProductionImports": 5476318.86,
    "laborOutputPerWorker": 5247062.12,
    "laborIncomePerWorker": 1343864.67,
    "totalEconomy": 4778824579.2,
    "totalEmployment": 62079,
    "totalIncome": 2939036730.22
},
{
    "Id": 350,
    "county": 86,
    "fips": 39171,
    "countyName": "Williams",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 43262519.11,
    "employment": 1116,
    "laborIncome": 38640274.37,
    "taxProductionImports": -375224.42,
    "laborOutputPerWorker": 1994946.57,
    "laborIncomePerWorker": 783318.92,
    "totalEconomy": 1678123592.19,
    "totalEmployment": 23079,
    "totalIncome": 1017457079.48
},
{
    "Id": 351,
    "county": 87,
    "fips": 39173,
    "countyName": "Wood",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 97920671.62,
    "employment": 1576,
    "laborIncome": 64356520.64,
    "taxProductionImports": 381293.2,
    "laborOutputPerWorker": 2249519.15,
    "laborIncomePerWorker": 673974.23,
    "totalEconomy": 6637779808.79,
    "totalEmployment": 81563,
    "totalIncome": 3990971789.81
},
{
    "Id": 352,
    "county": 88,
    "fips": 39175,
    "countyName": "Wyandot",
    "indicator": "agricultural production",
    "year": 2015,
    "valueAdded": 60516234.84,
    "employment": 763,
    "laborIncome": 62562240.28,
    "taxProductionImports": 702250.23,
    "laborOutputPerWorker": 5819193.11,
    "laborIncomePerWorker": 4266735.66,
    "totalEconomy": 960396796.31,
    "totalEmployment": 11898,
    "totalIncome": 569726183.18
},
{
    "Id": 353,
    "county": 1,
    "fips": 39001,
    "countyName": "Adams",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 16469733.22,
    "employment": 207,
    "laborIncome": 7636581.01,
    "taxProductionImports": 1310489.52,
    "laborOutputPerWorker": 3316958.4,
    "laborIncomePerWorker": 299068.69,
    "totalEconomy": 754367053.85,
    "totalEmployment": 12178,
    "totalIncome": 390608771.14
},
{
    "Id": 354,
    "county": 2,
    "fips": 39003,
    "countyName": "Allen",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 146324866.9,
    "employment": 1595,
    "laborIncome": 73093827.52,
    "taxProductionImports": 16138705.91,
    "laborOutputPerWorker": 4714189.12,
    "laborIncomePerWorker": 500128.83,
    "totalEconomy": 6642666490.57,
    "totalEmployment": 64290,
    "totalIncome": 3164228430.99
},
{
    "Id": 355,
    "county": 3,
    "fips": 39005,
    "countyName": "Ashland",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 72996068.16,
    "employment": 655,
    "laborIncome": 45039636.18,
    "taxProductionImports": 4479994.14,
    "laborOutputPerWorker": 7596436.62,
    "laborIncomePerWorker": 791359.45,
    "totalEconomy": 1750415277.16,
    "totalEmployment": 27521,
    "totalIncome": 1073760708.12
},
{
    "Id": 356,
    "county": 4,
    "fips": 39007,
    "countyName": "Ashtabula",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 72286958.42,
    "employment": 965,
    "laborIncome": 42940129.25,
    "taxProductionImports": 5140698.87,
    "laborOutputPerWorker": 6090245.22,
    "laborIncomePerWorker": 452999.37,
    "totalEconomy": 2925812407.17,
    "totalEmployment": 45743,
    "totalIncome": 1745974966.57
},
{
    "Id": 357,
    "county": 5,
    "fips": 39009,
    "countyName": "Athens",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 37473888.57,
    "employment": 188,
    "laborIncome": 8384070.75,
    "taxProductionImports": 15989120.95,
    "laborOutputPerWorker": 2834674.32,
    "laborIncomePerWorker": 262923.25,
    "totalEconomy": 1849309886.62,
    "totalEmployment": 28016,
    "totalIncome": 1103726245.03
},
{
    "Id": 358,
    "county": 6,
    "fips": 39011,
    "countyName": "Auglaize",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 149662784,
    "employment": 848,
    "laborIncome": 62425679.03,
    "taxProductionImports": 7214860.95,
    "laborOutputPerWorker": 9529309.05,
    "laborIncomePerWorker": 768991.29,
    "totalEconomy": 2154621733.27,
    "totalEmployment": 28804,
    "totalIncome": 1285209420.75
},
{
    "Id": 359,
    "county": 7,
    "fips": 39013,
    "countyName": "Belmont",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 33077962.76,
    "employment": 359,
    "laborIncome": 16468458.36,
    "taxProductionImports": 3292160.48,
    "laborOutputPerWorker": 4264710.84,
    "laborIncomePerWorker": 426096.8,
    "totalEconomy": 2898814500.42,
    "totalEmployment": 32407,
    "totalIncome": 1505487126.74
},
{
    "Id": 360,
    "county": 8,
    "fips": 39015,
    "countyName": "Brown",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 9041840.2,
    "employment": 191,
    "laborIncome": 5121676.3,
    "taxProductionImports": 922763.65,
    "laborOutputPerWorker": 4430645.63,
    "laborIncomePerWorker": 319151.82,
    "totalEconomy": 1070841039.23,
    "totalEmployment": 17945,
    "totalIncome": 528537502.56
},
{
    "Id": 361,
    "county": 9,
    "fips": 39017,
    "countyName": "Butler",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 724989364.7,
    "employment": 4009,
    "laborIncome": 281226617.7,
    "taxProductionImports": 146369972.8,
    "laborOutputPerWorker": 13426721.98,
    "laborIncomePerWorker": 1500584.64,
    "totalEconomy": 18726031720.08,
    "totalEmployment": 197699,
    "totalIncome": 10681823514.74
},
{
    "Id": 362,
    "county": 10,
    "fips": 39019,
    "countyName": "Carroll",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 13419341.17,
    "employment": 130,
    "laborIncome": 5267252.33,
    "taxProductionImports": 2840260.74,
    "laborOutputPerWorker": 6427267.54,
    "laborIncomePerWorker": 225012.11,
    "totalEconomy": 960757509.69,
    "totalEmployment": 11841,
    "totalIncome": 437176134.8
},
{
    "Id": 363,
    "county": 11,
    "fips": 39021,
    "countyName": "Champaign",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 43934576.09,
    "employment": 396,
    "laborIncome": 25564120.87,
    "taxProductionImports": 2051503.7,
    "laborOutputPerWorker": 3862561.45,
    "laborIncomePerWorker": 485605.18,
    "totalEconomy": 1079244909.63,
    "totalEmployment": 17332,
    "totalIncome": 683251618.79
},
{
    "Id": 364,
    "county": 12,
    "fips": 39023,
    "countyName": "Clark",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 122774069.2,
    "employment": 1256,
    "laborIncome": 74433968.54,
    "taxProductionImports": 8500693.83,
    "laborOutputPerWorker": 5946031.62,
    "laborIncomePerWorker": 759644.17,
    "totalEconomy": 4453629622.16,
    "totalEmployment": 65386,
    "totalIncome": 2738321110.23
},
{
    "Id": 365,
    "county": 13,
    "fips": 39025,
    "countyName": "Clermont",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 75356234.14,
    "employment": 1005,
    "laborIncome": 50023652.09,
    "taxProductionImports": 9673018.93,
    "laborOutputPerWorker": 4882097.65,
    "laborIncomePerWorker": 525435.15,
    "totalEconomy": 6960640326.21,
    "totalEmployment": 91602,
    "totalIncome": 4053746481.18
},
{
    "Id": 366,
    "county": 14,
    "fips": 39027,
    "countyName": "Clinton",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 37333577.91,
    "employment": 341,
    "laborIncome": 20840640.66,
    "taxProductionImports": 2045646.15,
    "laborOutputPerWorker": 2399174.82,
    "laborIncomePerWorker": 213169.23,
    "totalEconomy": 1776180932.68,
    "totalEmployment": 22779,
    "totalIncome": 1110264741.55
},
{
    "Id": 367,
    "county": 15,
    "fips": 39029,
    "countyName": "Columbiana",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 140295148.4,
    "employment": 1305,
    "laborIncome": 64285614.2,
    "taxProductionImports": 14994884.82,
    "laborOutputPerWorker": 14159273.88,
    "laborIncomePerWorker": 1040267.32,
    "totalEconomy": 2955699822.04,
    "totalEmployment": 42949,
    "totalIncome": 1754260547.36
},
{
    "Id": 368,
    "county": 16,
    "fips": 39031,
    "countyName": "Coshocton",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 110549871.5,
    "employment": 1077,
    "laborIncome": 61583191.41,
    "taxProductionImports": 9973156.73,
    "laborOutputPerWorker": 5080067.31,
    "laborIncomePerWorker": 634507.85,
    "totalEconomy": 1601216920.08,
    "totalEmployment": 18917,
    "totalIncome": 821372840.96
},
{
    "Id": 369,
    "county": 17,
    "fips": 39033,
    "countyName": "Crawford",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 11161856.19,
    "employment": 136,
    "laborIncome": 4971495.15,
    "taxProductionImports": 904995.94,
    "laborOutputPerWorker": 6208051.12,
    "laborIncomePerWorker": 442030.62,
    "totalEconomy": 1277601900.83,
    "totalEmployment": 19475,
    "totalIncome": 772936440.41
},
{
    "Id": 370,
    "county": 18,
    "fips": 39035,
    "countyName": "Cuyahoga",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 1036308602,
    "employment": 8088,
    "laborIncome": 470424262.2,
    "taxProductionImports": 195685007.6,
    "laborOutputPerWorker": 27795151.86,
    "laborIncomePerWorker": 2776434.99,
    "totalEconomy": 95196520915.12,
    "totalEmployment": 920704,
    "totalIncome": 58226951917.04
},
{
    "Id": 371,
    "county": 19,
    "fips": 39037,
    "countyName": "Darke",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 20750351.68,
    "employment": 304,
    "laborIncome": 11984371.83,
    "taxProductionImports": 2304437.18,
    "laborOutputPerWorker": 5418128.16,
    "laborIncomePerWorker": 385476.57,
    "totalEconomy": 1936506432.24,
    "totalEmployment": 29450,
    "totalIncome": 1199093130.68
},
{
    "Id": 372,
    "county": 20,
    "fips": 39039,
    "countyName": "Defiance",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 24498302.81,
    "employment": 293,
    "laborIncome": 11746929.47,
    "taxProductionImports": 2115096.79,
    "laborOutputPerWorker": 3627755.22,
    "laborIncomePerWorker": 284154.43,
    "totalEconomy": 1655173998.11,
    "totalEmployment": 22448,
    "totalIncome": 1011570926.19
},
{
    "Id": 373,
    "county": 21,
    "fips": 39041,
    "countyName": "Delaware",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 128033128.4,
    "employment": 1403,
    "laborIncome": 61282300.77,
    "taxProductionImports": 15923326.39,
    "laborOutputPerWorker": 6999026.09,
    "laborIncomePerWorker": 576631.61,
    "totalEconomy": 10843610770.17,
    "totalEmployment": 136964,
    "totalIncome": 6910714850.97
},
{
    "Id": 374,
    "county": 22,
    "fips": 39043,
    "countyName": "Erie",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 62494158.52,
    "employment": 725,
    "laborIncome": 24642003.1,
    "taxProductionImports": 10598485.46,
    "laborOutputPerWorker": 5593309.06,
    "laborIncomePerWorker": 411808.33,
    "totalEconomy": 3655066143.06,
    "totalEmployment": 49835,
    "totalIncome": 2200419858.53
},
{
    "Id": 375,
    "county": 23,
    "fips": 39045,
    "countyName": "Fairfield",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 134445414,
    "employment": 895,
    "laborIncome": 52030967.98,
    "taxProductionImports": 6352832.14,
    "laborOutputPerWorker": 8234591.94,
    "laborIncomePerWorker": 766829.24,
    "totalEconomy": 4154705032.25,
    "totalEmployment": 64573,
    "totalIncome": 2430262447.52
},
{
    "Id": 376,
    "county": 24,
    "fips": 39047,
    "countyName": "Fayette",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 131274953.3,
    "employment": 1010,
    "laborIncome": 49381356.75,
    "taxProductionImports": 7615818.84,
    "laborOutputPerWorker": 5293067.84,
    "laborIncomePerWorker": 455857.44,
    "totalEconomy": 1039406462.1,
    "totalEmployment": 17332,
    "totalIncome": 615157312.99
},
{
    "Id": 377,
    "county": 25,
    "fips": 39049,
    "countyName": "Franklin",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 2437302599,
    "employment": 10653,
    "laborIncome": 817120344.5,
    "taxProductionImports": 313533686.9,
    "laborOutputPerWorker": 25658317.56,
    "laborIncomePerWorker": 2133732.1,
    "totalEconomy": 93118197636.6,
    "totalEmployment": 918222,
    "totalIncome": 56769200094.53
},
{
    "Id": 378,
    "county": 26,
    "fips": 39051,
    "countyName": "Fulton",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 82915332.07,
    "employment": 683,
    "laborIncome": 44301842.25,
    "taxProductionImports": 5349885.27,
    "laborOutputPerWorker": 4909094.65,
    "laborIncomePerWorker": 438194.9,
    "totalEconomy": 1773401942.35,
    "totalEmployment": 26929,
    "totalIncome": 1136244367.14
},
{
    "Id": 379,
    "county": 27,
    "fips": 39053,
    "countyName": "Gallia",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 6012937.07,
    "employment": 91,
    "laborIncome": 3433482.22,
    "taxProductionImports": 1003610.2,
    "laborOutputPerWorker": 1875812.12,
    "laborIncomePerWorker": 187780.52,
    "totalEconomy": 1506358279.89,
    "totalEmployment": 16209,
    "totalIncome": 629125908.79
},
{
    "Id": 380,
    "county": 28,
    "fips": 39055,
    "countyName": "Geauga",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 75588560.53,
    "employment": 773,
    "laborIncome": 40387774.63,
    "taxProductionImports": 8916853.1,
    "laborOutputPerWorker": 10154520.42,
    "laborIncomePerWorker": 1009267.06,
    "totalEconomy": 3742816889.03,
    "totalEmployment": 53684,
    "totalIncome": 2228093395.43
},
{
    "Id": 381,
    "county": 29,
    "fips": 39057,
    "countyName": "Greene",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 52833284.24,
    "employment": 494,
    "laborIncome": 31240679.11,
    "taxProductionImports": 6980495.26,
    "laborOutputPerWorker": 7090371.64,
    "laborIncomePerWorker": 683076.1,
    "totalEconomy": 9642560583.37,
    "totalEmployment": 105110,
    "totalIncome": 6058681306.54
},
{
    "Id": 382,
    "county": 30,
    "fips": 39059,
    "countyName": "Guernsey",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 24636927.58,
    "employment": 334,
    "laborIncome": 15468737.89,
    "taxProductionImports": 2223129.73,
    "laborOutputPerWorker": 3858491.54,
    "laborIncomePerWorker": 431399.44,
    "totalEconomy": 1595054307.57,
    "totalEmployment": 20987,
    "totalIncome": 909744860.5
},
{
    "Id": 383,
    "county": 31,
    "fips": 39061,
    "countyName": "Hamilton",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 1773096107,
    "employment": 9300,
    "laborIncome": 646556742.4,
    "taxProductionImports": 185988189.2,
    "laborOutputPerWorker": 28929349.66,
    "laborIncomePerWorker": 2982348.13,
    "totalEconomy": 70482980740.81,
    "totalEmployment": 648027,
    "totalIncome": 42590000667.83
},
{
    "Id": 384,
    "county": 32,
    "fips": 39063,
    "countyName": "Hancock",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 242385046.4,
    "employment": 2328,
    "laborIncome": 103256536.9,
    "taxProductionImports": 20225647.07,
    "laborOutputPerWorker": 11517757.05,
    "laborIncomePerWorker": 514352.19,
    "totalEconomy": 4931274710.82,
    "totalEmployment": 56506,
    "totalIncome": 2996273249.86
},
{
    "Id": 385,
    "county": 33,
    "fips": 39065,
    "countyName": "Hardin",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 39993063.94,
    "employment": 582,
    "laborIncome": 28012423.55,
    "taxProductionImports": 3328562.47,
    "laborOutputPerWorker": 3478034.64,
    "laborIncomePerWorker": 249431.71,
    "totalEconomy": 857016266.58,
    "totalEmployment": 13449,
    "totalIncome": 540393309.33
},
{
    "Id": 386,
    "county": 34,
    "fips": 39067,
    "countyName": "Harrison",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 9453663.2,
    "employment": 154,
    "laborIncome": 6316451.97,
    "taxProductionImports": 310188.72,
    "laborOutputPerWorker": 460691.75,
    "laborIncomePerWorker": 74471.83,
    "totalEconomy": 487184291.94,
    "totalEmployment": 5517,
    "totalIncome": 238109478.52
},
{
    "Id": 387,
    "county": 35,
    "fips": 39069,
    "countyName": "Henry",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 264241573.7,
    "employment": 1627,
    "laborIncome": 113407205.7,
    "taxProductionImports": 26886452.47,
    "laborOutputPerWorker": 4346075.56,
    "laborIncomePerWorker": 412150.12,
    "totalEconomy": 1163234414.16,
    "totalEmployment": 16253,
    "totalIncome": 688006614.55
},
{
    "Id": 388,
    "county": 36,
    "fips": 39071,
    "countyName": "Highland",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 10834940.03,
    "employment": 159,
    "laborIncome": 7639206.24,
    "taxProductionImports": 916964.39,
    "laborOutputPerWorker": 2068632.37,
    "laborIncomePerWorker": 252851.58,
    "totalEconomy": 961520442.89,
    "totalEmployment": 15927,
    "totalIncome": 545085085.53
},
{
    "Id": 389,
    "county": 37,
    "fips": 39073,
    "countyName": "Hocking",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 27613751.1,
    "employment": 388,
    "laborIncome": 15669988.69,
    "taxProductionImports": 2285787.64,
    "laborOutputPerWorker": 3964838.87,
    "laborIncomePerWorker": 385169.38,
    "totalEconomy": 648428103.41,
    "totalEmployment": 9690,
    "totalIncome": 340386907.29
},
{
    "Id": 390,
    "county": 38,
    "fips": 39075,
    "countyName": "Holmes",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 208720160.4,
    "employment": 3773,
    "laborIncome": 136326706.7,
    "taxProductionImports": 12872724.03,
    "laborOutputPerWorker": 11982674.83,
    "laborIncomePerWorker": 1018451.41,
    "totalEconomy": 1968317546.63,
    "totalEmployment": 32350,
    "totalIncome": 1340411219.12
},
{
    "Id": 391,
    "county": 39,
    "fips": 39077,
    "countyName": "Huron",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 200079248.1,
    "employment": 1178,
    "laborIncome": 79391700.2,
    "taxProductionImports": 18396309.92,
    "laborOutputPerWorker": 9774244.45,
    "laborIncomePerWorker": 632953.6,
    "totalEconomy": 2226553149.69,
    "totalEmployment": 28683,
    "totalIncome": 1311308031.21
},
{
    "Id": 392,
    "county": 40,
    "fips": 39079,
    "countyName": "Jackson",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 196282577.6,
    "employment": 2630,
    "laborIncome": 137091135.4,
    "taxProductionImports": 10555078.16,
    "laborOutputPerWorker": 4481578.02,
    "laborIncomePerWorker": 469639.55,
    "totalEconomy": 938064693.66,
    "totalEmployment": 14675,
    "totalIncome": 562574305.69
},
{
    "Id": 393,
    "county": 41,
    "fips": 39081,
    "countyName": "Jefferson",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 19665332.48,
    "employment": 288,
    "laborIncome": 14117375.4,
    "taxProductionImports": 1442956.69,
    "laborOutputPerWorker": 2044526.93,
    "laborIncomePerWorker": 354903.84,
    "totalEconomy": 2566153991.9,
    "totalEmployment": 27416,
    "totalIncome": 1217919020.49
},
{
    "Id": 394,
    "county": 42,
    "fips": 39083,
    "countyName": "Knox",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 39222989.84,
    "employment": 701,
    "laborIncome": 25173325.13,
    "taxProductionImports": 3528254.2,
    "laborOutputPerWorker": 4213335.29,
    "laborIncomePerWorker": 433674.14,
    "totalEconomy": 2136062215.67,
    "totalEmployment": 30625,
    "totalIncome": 1312392515.79
},
{
    "Id": 395,
    "county": 43,
    "fips": 39085,
    "countyName": "Lake",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 316599809.6,
    "employment": 2017,
    "laborIncome": 219380996.2,
    "taxProductionImports": 7873790.78,
    "laborOutputPerWorker": 7174479.22,
    "laborIncomePerWorker": 906854.95,
    "totalEconomy": 10617995628.95,
    "totalEmployment": 117716,
    "totalIncome": 5944295728.24
},
{
    "Id": 396,
    "county": 44,
    "fips": 39087,
    "countyName": "Lawrence",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 5936022.29,
    "employment": 51,
    "laborIncome": 1399665.63,
    "taxProductionImports": 2875113.76,
    "laborOutputPerWorker": 1468678.75,
    "laborIncomePerWorker": 112108.04,
    "totalEconomy": 1144051784.96,
    "totalEmployment": 18640,
    "totalIncome": 670865912.96
},
{
    "Id": 397,
    "county": 45,
    "fips": 39089,
    "countyName": "Licking",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 91601676.3,
    "employment": 1124,
    "laborIncome": 56413825.23,
    "taxProductionImports": 8334472.68,
    "laborOutputPerWorker": 9470015.1,
    "laborIncomePerWorker": 801124.59,
    "totalEconomy": 5705629059.02,
    "totalEmployment": 74399,
    "totalIncome": 3327378965.39
},
{
    "Id": 398,
    "county": 46,
    "fips": 39091,
    "countyName": "Logan",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 43340585.28,
    "employment": 292,
    "laborIncome": 31066746.9,
    "taxProductionImports": 2166300.26,
    "laborOutputPerWorker": 5666504.46,
    "laborIncomePerWorker": 566084.71,
    "totalEconomy": 2054685543.96,
    "totalEmployment": 24585,
    "totalIncome": 1256558936.6
},
{
    "Id": 399,
    "county": 47,
    "fips": 39093,
    "countyName": "Lorain",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 77962651.21,
    "employment": 937,
    "laborIncome": 37067367.33,
    "taxProductionImports": 7448676.07,
    "laborOutputPerWorker": 6974411.45,
    "laborIncomePerWorker": 616523.29,
    "totalEconomy": 10263339652.71,
    "totalEmployment": 126142,
    "totalIncome": 6050242026.28
},
{
    "Id": 400,
    "county": 48,
    "fips": 39095,
    "countyName": "Lucas",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 246258278.2,
    "employment": 1639,
    "laborIncome": 114511266.9,
    "taxProductionImports": 18444994.07,
    "laborOutputPerWorker": 11421593.45,
    "laborIncomePerWorker": 1815519.95,
    "totalEconomy": 27821667781.16,
    "totalEmployment": 261164,
    "totalIncome": 14481328383.98
},
{
    "Id": 401,
    "county": 49,
    "fips": 39097,
    "countyName": "Madison",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 8507702.9,
    "employment": 128,
    "laborIncome": 4987137.5,
    "taxProductionImports": 662293.49,
    "laborOutputPerWorker": 4413639.52,
    "laborIncomePerWorker": 417194.79,
    "totalEconomy": 1546109233.43,
    "totalEmployment": 22433,
    "totalIncome": 1038857652.61
},
{
    "Id": 402,
    "county": 50,
    "fips": 39099,
    "countyName": "Mahoning",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 138311016.5,
    "employment": 1587,
    "laborIncome": 82401825.14,
    "taxProductionImports": 10593164.06,
    "laborOutputPerWorker": 5870143.29,
    "laborIncomePerWorker": 871345.32,
    "totalEconomy": 9051758806.74,
    "totalEmployment": 128199,
    "totalIncome": 5872683349.78
},
{
    "Id": 403,
    "county": 51,
    "fips": 39101,
    "countyName": "Marion",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 133176988.3,
    "employment": 1334,
    "laborIncome": 63426264.66,
    "taxProductionImports": 8270865.87,
    "laborOutputPerWorker": 3593594.16,
    "laborIncomePerWorker": 358953.57,
    "totalEconomy": 2664768172.7,
    "totalEmployment": 33146,
    "totalIncome": 1607268443.56
},
{
    "Id": 404,
    "county": 52,
    "fips": 39103,
    "countyName": "Medina",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 259897761.8,
    "employment": 1450,
    "laborIncome": 81712764.06,
    "taxProductionImports": 14563867.39,
    "laborOutputPerWorker": 11023845.15,
    "laborIncomePerWorker": 1036250.38,
    "totalEconomy": 6481248228.04,
    "totalEmployment": 86106,
    "totalIncome": 3630185361.68
},
{
    "Id": 405,
    "county": 53,
    "fips": 39105,
    "countyName": "Meigs",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 4974778.98,
    "employment": 67,
    "laborIncome": 2389322.56,
    "taxProductionImports": 451986.27,
    "laborOutputPerWorker": 1322286.61,
    "laborIncomePerWorker": 101223.11,
    "totalEconomy": 340474070.72,
    "totalEmployment": 6033,
    "totalIncome": 185093558.77
},
{
    "Id": 406,
    "county": 54,
    "fips": 39107,
    "countyName": "Mercer",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 162305867.4,
    "employment": 1699,
    "laborIncome": 85015595.51,
    "taxProductionImports": 7568547,
    "laborOutputPerWorker": 6501101.64,
    "laborIncomePerWorker": 874277.12,
    "totalEconomy": 2086448826.14,
    "totalEmployment": 28571,
    "totalIncome": 1252954790.05
},
{
    "Id": 407,
    "county": 55,
    "fips": 39109,
    "countyName": "Miami",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 170165451.4,
    "employment": 1910,
    "laborIncome": 105279391,
    "taxProductionImports": 7653456.95,
    "laborOutputPerWorker": 6168774.2,
    "laborIncomePerWorker": 717086.19,
    "totalEconomy": 4017206101.04,
    "totalEmployment": 57058,
    "totalIncome": 2469996868.78
},
{
    "Id": 408,
    "county": 56,
    "fips": 39111,
    "countyName": "Monroe",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 754884.61,
    "employment": 21,
    "laborIncome": 461413.11,
    "taxProductionImports": 134373.89,
    "laborOutputPerWorker": 1271823.95,
    "laborIncomePerWorker": 129311.97,
    "totalEconomy": 306914893.02,
    "totalEmployment": 6187,
    "totalIncome": 167730784.7
},
{
    "Id": 409,
    "county": 57,
    "fips": 39113,
    "countyName": "Montgomery",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 404388519.4,
    "employment": 2700,
    "laborIncome": 164581520.4,
    "taxProductionImports": 46356886.9,
    "laborOutputPerWorker": 20385098.66,
    "laborIncomePerWorker": 2126260.5,
    "totalEconomy": 27735849459.23,
    "totalEmployment": 309959,
    "totalIncome": 17013344003.36
},
{
    "Id": 410,
    "county": 58,
    "fips": 39115,
    "countyName": "Morgan",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 3486783.14,
    "employment": 89,
    "laborIncome": 2470311.8,
    "taxProductionImports": 521310.2,
    "laborOutputPerWorker": 931416.9,
    "laborIncomePerWorker": 179912.83,
    "totalEconomy": 266257427.25,
    "totalEmployment": 5334,
    "totalIncome": 153284219.46
},
{
    "Id": 411,
    "county": 59,
    "fips": 39117,
    "countyName": "Morrow",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 17622353.29,
    "employment": 103,
    "laborIncome": 4008427.4,
    "taxProductionImports": 7552271.71,
    "laborOutputPerWorker": 2326874.87,
    "laborIncomePerWorker": 197552.65,
    "totalEconomy": 1055850097.01,
    "totalEmployment": 11135,
    "totalIncome": 371932527.91
},
{
    "Id": 412,
    "county": 60,
    "fips": 39119,
    "countyName": "Muskingum",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 174361175.5,
    "employment": 1133,
    "laborIncome": 73095765.34,
    "taxProductionImports": 7208631.23,
    "laborOutputPerWorker": 7588666.87,
    "laborIncomePerWorker": 780496.43,
    "totalEconomy": 3078496866.31,
    "totalEmployment": 43379,
    "totalIncome": 1859152215.05
},
{
    "Id": 413,
    "county": 61,
    "fips": 39121,
    "countyName": "Noble",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 15363588.7,
    "employment": 185,
    "laborIncome": 8583233.53,
    "taxProductionImports": 915748.61,
    "laborOutputPerWorker": 3606963.39,
    "laborIncomePerWorker": 355822.55,
    "totalEconomy": 381929828.05,
    "totalEmployment": 5194,
    "totalIncome": 195580028.7
},
{
    "Id": 414,
    "county": 62,
    "fips": 39123,
    "countyName": "Ottawa",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 22504629.48,
    "employment": 213,
    "laborIncome": 11691506.74,
    "taxProductionImports": 3702889.58,
    "laborOutputPerWorker": 3333619.22,
    "laborIncomePerWorker": 334308.3,
    "totalEconomy": 1882586058.87,
    "totalEmployment": 20084,
    "totalIncome": 852123909.18
},
{
    "Id": 415,
    "county": 63,
    "fips": 39125,
    "countyName": "Paulding",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 4339218.79,
    "employment": 42,
    "laborIncome": 1650442.87,
    "taxProductionImports": 733436.77,
    "laborOutputPerWorker": 4357974.53,
    "laborIncomePerWorker": 245826.59,
    "totalEconomy": 483420907.84,
    "totalEmployment": 7567,
    "totalIncome": 309158032.79
},
{
    "Id": 416,
    "county": 64,
    "fips": 39127,
    "countyName": "Perry",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 3082164.72,
    "employment": 59,
    "laborIncome": 2183348.46,
    "taxProductionImports": 286883.92,
    "laborOutputPerWorker": 1145830.75,
    "laborIncomePerWorker": 211888.61,
    "totalEconomy": 536805451.96,
    "totalEmployment": 8424,
    "totalIncome": 288305553.36
},
{
    "Id": 417,
    "county": 65,
    "fips": 39129,
    "countyName": "Pickaway",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 19991907.65,
    "employment": 253,
    "laborIncome": 14995888.24,
    "taxProductionImports": 1336773.28,
    "laborOutputPerWorker": 2399400.92,
    "laborIncomePerWorker": 331065.08,
    "totalEconomy": 1469763605.64,
    "totalEmployment": 20078,
    "totalIncome": 943957661.01
},
{
    "Id": 418,
    "county": 66,
    "fips": 39131,
    "countyName": "Pike",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 31748041.18,
    "employment": 690,
    "laborIncome": 22861674.45,
    "taxProductionImports": 1367581.49,
    "laborOutputPerWorker": 1749419.6,
    "laborIncomePerWorker": 233549.31,
    "totalEconomy": 1080780724.81,
    "totalEmployment": 13181,
    "totalIncome": 676630642.49
},
{
    "Id": 419,
    "county": 67,
    "fips": 39133,
    "countyName": "Portage",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 93303414.57,
    "employment": 850,
    "laborIncome": 52815690.22,
    "taxProductionImports": 9170692.08,
    "laborOutputPerWorker": 10001639.44,
    "laborIncomePerWorker": 1015830.24,
    "totalEconomy": 6153477202.51,
    "totalEmployment": 82453,
    "totalIncome": 3810308568.74
},
{
    "Id": 420,
    "county": 68,
    "fips": 39135,
    "countyName": "Preble",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 100566412.8,
    "employment": 671,
    "laborIncome": 37602672.42,
    "taxProductionImports": 4135824.99,
    "laborOutputPerWorker": 5128350.65,
    "laborIncomePerWorker": 379080.22,
    "totalEconomy": 1031965637.09,
    "totalEmployment": 16433,
    "totalIncome": 607456742.47
},
{
    "Id": 421,
    "county": 69,
    "fips": 39137,
    "countyName": "Putnam",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 134486284.1,
    "employment": 230,
    "laborIncome": 29304296.77,
    "taxProductionImports": 1386849.05,
    "laborOutputPerWorker": 6569928,
    "laborIncomePerWorker": 815298.39,
    "totalEconomy": 1324877177.53,
    "totalEmployment": 18676,
    "totalIncome": 739234658.39
},
{
    "Id": 422,
    "county": 70,
    "fips": 39139,
    "countyName": "Richland",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 82704760.3,
    "employment": 800,
    "laborIncome": 39880633.39,
    "taxProductionImports": 12479966.12,
    "laborOutputPerWorker": 4798920.96,
    "laborIncomePerWorker": 562530.63,
    "totalEconomy": 4385743452.01,
    "totalEmployment": 62487,
    "totalIncome": 2684050747.09
},
{
    "Id": 423,
    "county": 71,
    "fips": 39141,
    "countyName": "Ross",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 201545376.5,
    "employment": 1213,
    "laborIncome": 112520122.6,
    "taxProductionImports": 10779251.97,
    "laborOutputPerWorker": 3934519.87,
    "laborIncomePerWorker": 512012.77,
    "totalEconomy": 2711634375.21,
    "totalEmployment": 34919,
    "totalIncome": 1722690534.03
},
{
    "Id": 424,
    "county": 72,
    "fips": 39143,
    "countyName": "Sandusky",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 95643126.18,
    "employment": 1024,
    "laborIncome": 62362493.14,
    "taxProductionImports": 7053538.46,
    "laborOutputPerWorker": 4145971.73,
    "laborIncomePerWorker": 437926.27,
    "totalEconomy": 2866233783.01,
    "totalEmployment": 35809,
    "totalIncome": 1668233888.12
},
{
    "Id": 425,
    "county": 73,
    "fips": 39145,
    "countyName": "Scioto",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 54777647.26,
    "employment": 456,
    "laborIncome": 21201730.45,
    "taxProductionImports": 7690984.22,
    "laborOutputPerWorker": 4406076.87,
    "laborIncomePerWorker": 409915.28,
    "totalEconomy": 2307993397.19,
    "totalEmployment": 31488,
    "totalIncome": 1356182385.87
},
{
    "Id": 426,
    "county": 74,
    "fips": 39147,
    "countyName": "Seneca",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 57573626.71,
    "employment": 477,
    "laborIncome": 29665948.89,
    "taxProductionImports": 3456233.64,
    "laborOutputPerWorker": 4851909.44,
    "laborIncomePerWorker": 554000.2,
    "totalEconomy": 1793111677.46,
    "totalEmployment": 25442,
    "totalIncome": 1023140494.4
},
{
    "Id": 427,
    "county": 75,
    "fips": 39149,
    "countyName": "Shelby",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 88417720.61,
    "employment": 1064,
    "laborIncome": 58846892.72,
    "taxProductionImports": 4385110.49,
    "laborOutputPerWorker": 5688904.59,
    "laborIncomePerWorker": 752367.93,
    "totalEconomy": 3177680488.8,
    "totalEmployment": 35010,
    "totalIncome": 1828292144.85
},
{
    "Id": 428,
    "county": 76,
    "fips": 39151,
    "countyName": "Stark",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 810474745.8,
    "employment": 6346,
    "laborIncome": 362522674.9,
    "taxProductionImports": 63544099.92,
    "laborOutputPerWorker": 17445525.66,
    "laborIncomePerWorker": 1724935.63,
    "totalEconomy": 17621076554.15,
    "totalEmployment": 205504,
    "totalIncome": 9699356422.44
},
{
    "Id": 429,
    "county": 77,
    "fips": 39153,
    "countyName": "Summit",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 358481090.5,
    "employment": 3137,
    "laborIncome": 173514566.4,
    "taxProductionImports": 42596820.86,
    "laborOutputPerWorker": 12736999.55,
    "laborIncomePerWorker": 1279795.82,
    "totalEconomy": 29332130164.83,
    "totalEmployment": 337387,
    "totalIncome": 18452440621.06
},
{
    "Id": 430,
    "county": 78,
    "fips": 39155,
    "countyName": "Trumbull",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 57609042.28,
    "employment": 762,
    "laborIncome": 29205383.93,
    "taxProductionImports": 6396059.86,
    "laborOutputPerWorker": 6263123.18,
    "laborIncomePerWorker": 553009.01,
    "totalEconomy": 6979874771.89,
    "totalEmployment": 88293,
    "totalIncome": 4055841366.2
},
{
    "Id": 431,
    "county": 79,
    "fips": 39157,
    "countyName": "Tuscarawas",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 97786261.49,
    "employment": 1313,
    "laborIncome": 64457406.25,
    "taxProductionImports": 4875879.44,
    "laborOutputPerWorker": 7205125.86,
    "laborIncomePerWorker": 817818.59,
    "totalEconomy": 3514031100.51,
    "totalEmployment": 46549,
    "totalIncome": 2081487962.11
},
{
    "Id": 432,
    "county": 80,
    "fips": 39159,
    "countyName": "Union",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 32830526.43,
    "employment": 310,
    "laborIncome": 15099121.74,
    "taxProductionImports": 8114976.95,
    "laborOutputPerWorker": 7956752.53,
    "laborIncomePerWorker": 423421.13,
    "totalEconomy": 4225350827.57,
    "totalEmployment": 38043,
    "totalIncome": 2391128078.99
},
{
    "Id": 433,
    "county": 81,
    "fips": 39161,
    "countyName": "Van Wert",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 26720429.28,
    "employment": 267,
    "laborIncome": 14558317.47,
    "taxProductionImports": 2833309.19,
    "laborOutputPerWorker": 8111265.52,
    "laborIncomePerWorker": 397838.18,
    "totalEconomy": 1045798993.33,
    "totalEmployment": 14036,
    "totalIncome": 618945547.79
},
{
    "Id": 434,
    "county": 82,
    "fips": 39163,
    "countyName": "Vinton",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 14188349.27,
    "employment": 202,
    "laborIncome": 10354049.42,
    "taxProductionImports": 313538.07,
    "laborOutputPerWorker": 1061907.98,
    "laborIncomePerWorker": 163777.49,
    "totalEconomy": 209604097.09,
    "totalEmployment": 3358,
    "totalIncome": 126466634.18
},
{
    "Id": 435,
    "county": 83,
    "fips": 39165,
    "countyName": "Warren",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 273867459.5,
    "employment": 2438,
    "laborIncome": 155960683.5,
    "taxProductionImports": 20796907.35,
    "laborOutputPerWorker": 9410104.91,
    "laborIncomePerWorker": 1045908.74,
    "totalEconomy": 10460071517.5,
    "totalEmployment": 114084,
    "totalIncome": 5895730256.97
},
{
    "Id": 436,
    "county": 84,
    "fips": 39167,
    "countyName": "Washington",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 52336913.03,
    "employment": 595,
    "laborIncome": 30521200.96,
    "taxProductionImports": 3808116.46,
    "laborOutputPerWorker": 4628368.08,
    "laborIncomePerWorker": 479499.33,
    "totalEconomy": 3073221267.81,
    "totalEmployment": 33087,
    "totalIncome": 1687618381.22
},
{
    "Id": 437,
    "county": 85,
    "fips": 39169,
    "countyName": "Wayne",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 619618971.1,
    "employment": 4812,
    "laborIncome": 358034344.6,
    "taxProductionImports": 29015731.68,
    "laborOutputPerWorker": 16613782.78,
    "laborIncomePerWorker": 1295222.77,
    "totalEconomy": 4778824579.2,
    "totalEmployment": 62079,
    "totalIncome": 2939036730.22
},
{
    "Id": 438,
    "county": 86,
    "fips": 39171,
    "countyName": "Williams",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 77518756.74,
    "employment": 849,
    "laborIncome": 38344681.77,
    "taxProductionImports": 7745303.6,
    "laborOutputPerWorker": 4401769.86,
    "laborIncomePerWorker": 436501.74,
    "totalEconomy": 1678123592.19,
    "totalEmployment": 23079,
    "totalIncome": 1017457079.48
},
{
    "Id": 439,
    "county": 87,
    "fips": 39173,
    "countyName": "Wood",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 157466780,
    "employment": 1413,
    "laborIncome": 79466098.79,
    "taxProductionImports": 10066893.61,
    "laborOutputPerWorker": 7782093.95,
    "laborIncomePerWorker": 768732.09,
    "totalEconomy": 6637779808.79,
    "totalEmployment": 81563,
    "totalIncome": 3990971789.81
},
{
    "Id": 440,
    "county": 88,
    "fips": 39175,
    "countyName": "Wyandot",
    "indicator": "food processing",
    "year": 2015,
    "valueAdded": 75433033.62,
    "employment": 422,
    "laborIncome": 26962124.17,
    "taxProductionImports": 3083959.74,
    "laborOutputPerWorker": 2444132.94,
    "laborIncomePerWorker": 233560.94,
    "totalEconomy": 960396796.31,
    "totalEmployment": 11898,
    "totalIncome": 569726183.18
},
{
    "Id": 441,
    "county": 1,
    "fips": 39001,
    "countyName": "Adams",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 14171095.04,
    "employment": 304,
    "laborIncome": 8461963.25,
    "taxProductionImports": 3630665.12,
    "laborOutputPerWorker": 130666.13,
    "laborIncomePerWorker": 46796.33,
    "totalEconomy": 754367053.85,
    "totalEmployment": 12178,
    "totalIncome": 390608771.14
},
{
    "Id": 442,
    "county": 2,
    "fips": 39003,
    "countyName": "Allen",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 94675738.41,
    "employment": 1657,
    "laborIncome": 54725744.62,
    "taxProductionImports": 21819231.54,
    "laborOutputPerWorker": 136023.74,
    "laborIncomePerWorker": 50250.72,
    "totalEconomy": 6642666490.57,
    "totalEmployment": 64290,
    "totalIncome": 3164228430.99
},
{
    "Id": 443,
    "county": 3,
    "fips": 39005,
    "countyName": "Ashland",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 37832082.26,
    "employment": 732,
    "laborIncome": 22262558.39,
    "taxProductionImports": 8609133.19,
    "laborOutputPerWorker": 133856.98,
    "laborIncomePerWorker": 48313.89,
    "totalEconomy": 1750415277.16,
    "totalEmployment": 27521,
    "totalIncome": 1073760708.12
},
{
    "Id": 444,
    "county": 4,
    "fips": 39007,
    "countyName": "Ashtabula",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 51468845.47,
    "employment": 1182,
    "laborIncome": 30538312.41,
    "taxProductionImports": 12478005.84,
    "laborOutputPerWorker": 132627.83,
    "laborIncomePerWorker": 47157.74,
    "totalEconomy": 2925812407.17,
    "totalEmployment": 45743,
    "totalIncome": 1745974966.57
},
{
    "Id": 445,
    "county": 5,
    "fips": 39009,
    "countyName": "Athens",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 36167723.92,
    "employment": 768,
    "laborIncome": 21863667.61,
    "taxProductionImports": 7878313.91,
    "laborOutputPerWorker": 138618.96,
    "laborIncomePerWorker": 51287.68,
    "totalEconomy": 1849309886.62,
    "totalEmployment": 28016,
    "totalIncome": 1103726245.03
},
{
    "Id": 446,
    "county": 6,
    "fips": 39011,
    "countyName": "Auglaize",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 43851231.01,
    "employment": 794,
    "laborIncome": 25020837.85,
    "taxProductionImports": 9330080.23,
    "laborOutputPerWorker": 134974.73,
    "laborIncomePerWorker": 47737.19,
    "totalEconomy": 2154621733.27,
    "totalEmployment": 28804,
    "totalIncome": 1285209420.75
},
{
    "Id": 447,
    "county": 7,
    "fips": 39013,
    "countyName": "Belmont",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 63352265.52,
    "employment": 1263,
    "laborIncome": 39307050.9,
    "taxProductionImports": 13264860.5,
    "laborOutputPerWorker": 144916.66,
    "laborIncomePerWorker": 57276.41,
    "totalEconomy": 2898814500.42,
    "totalEmployment": 32407,
    "totalIncome": 1505487126.74
},
{
    "Id": 448,
    "county": 8,
    "fips": 39015,
    "countyName": "Brown",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 19911849.41,
    "employment": 507,
    "laborIncome": 12114774.33,
    "taxProductionImports": 4723338.06,
    "laborOutputPerWorker": 134347.77,
    "laborIncomePerWorker": 49827.85,
    "totalEconomy": 1070841039.23,
    "totalEmployment": 17945,
    "totalIncome": 528537502.56
},
{
    "Id": 449,
    "county": 9,
    "fips": 39017,
    "countyName": "Butler",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 548673420.3,
    "employment": 6513,
    "laborIncome": 322509049.1,
    "taxProductionImports": 89439879.79,
    "laborOutputPerWorker": 160161.25,
    "laborIncomePerWorker": 68024.53,
    "totalEconomy": 18726031720.08,
    "totalEmployment": 197699,
    "totalIncome": 10681823514.74
},
{
    "Id": 450,
    "county": 10,
    "fips": 39019,
    "countyName": "Carroll",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 11608109.15,
    "employment": 273,
    "laborIncome": 6616096.23,
    "taxProductionImports": 2998302.63,
    "laborOutputPerWorker": 125564.41,
    "laborIncomePerWorker": 41736.86,
    "totalEconomy": 960757509.69,
    "totalEmployment": 11841,
    "totalIncome": 437176134.8
},
{
    "Id": 451,
    "county": 11,
    "fips": 39021,
    "countyName": "Champaign",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 21095016.42,
    "employment": 475,
    "laborIncome": 12542502.76,
    "taxProductionImports": 4933451.68,
    "laborOutputPerWorker": 134816.32,
    "laborIncomePerWorker": 49353.95,
    "totalEconomy": 1079244909.63,
    "totalEmployment": 17332,
    "totalIncome": 683251618.79
},
{
    "Id": 452,
    "county": 12,
    "fips": 39023,
    "countyName": "Clark",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 115172768.7,
    "employment": 2360,
    "laborIncome": 62465310.12,
    "taxProductionImports": 33640009.54,
    "laborOutputPerWorker": 136582.6,
    "laborIncomePerWorker": 51677.21,
    "totalEconomy": 4453629622.16,
    "totalEmployment": 65386,
    "totalIncome": 2738321110.23
},
{
    "Id": 453,
    "county": 13,
    "fips": 39025,
    "countyName": "Clermont",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 177244652.2,
    "employment": 2819,
    "laborIncome": 106160885.6,
    "taxProductionImports": 33892118.55,
    "laborOutputPerWorker": 152149.68,
    "laborIncomePerWorker": 61441.19,
    "totalEconomy": 6960640326.21,
    "totalEmployment": 91602,
    "totalIncome": 4053746481.18
},
{
    "Id": 454,
    "county": 14,
    "fips": 39027,
    "countyName": "Clinton",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 41418805.64,
    "employment": 642,
    "laborIncome": 25295717.78,
    "taxProductionImports": 7800866.86,
    "laborOutputPerWorker": 152315.94,
    "laborIncomePerWorker": 64227.7,
    "totalEconomy": 1776180932.68,
    "totalEmployment": 22779,
    "totalIncome": 1110264741.55
},
{
    "Id": 455,
    "county": 15,
    "fips": 39029,
    "countyName": "Columbiana",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 67637338.02,
    "employment": 1386,
    "laborIncome": 40722012.7,
    "taxProductionImports": 15341351.7,
    "laborOutputPerWorker": 142882.52,
    "laborIncomePerWorker": 56452.66,
    "totalEconomy": 2955699822.04,
    "totalEmployment": 42949,
    "totalIncome": 1754260547.36
},
{
    "Id": 456,
    "county": 16,
    "fips": 39031,
    "countyName": "Coshocton",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 16209132.25,
    "employment": 426,
    "laborIncome": 9542680.26,
    "taxProductionImports": 4651677,
    "laborOutputPerWorker": 130386.23,
    "laborIncomePerWorker": 48576.15,
    "totalEconomy": 1601216920.08,
    "totalEmployment": 18917,
    "totalIncome": 821372840.96
},
{
    "Id": 457,
    "county": 17,
    "fips": 39033,
    "countyName": "Crawford",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 26917483.07,
    "employment": 524,
    "laborIncome": 15874094.7,
    "taxProductionImports": 5991059.94,
    "laborOutputPerWorker": 140784.41,
    "laborIncomePerWorker": 53818.87,
    "totalEconomy": 1277601900.83,
    "totalEmployment": 19475,
    "totalIncome": 772936440.41
},
{
    "Id": 458,
    "county": 18,
    "fips": 39035,
    "countyName": "Cuyahoga",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 1735976791,
    "employment": 22308,
    "laborIncome": 1052295930,
    "taxProductionImports": 288374948.5,
    "laborOutputPerWorker": 158655.57,
    "laborIncomePerWorker": 67686.71,
    "totalEconomy": 95196520915.12,
    "totalEmployment": 920704,
    "totalIncome": 58226951917.04
},
{
    "Id": 459,
    "county": 19,
    "fips": 39037,
    "countyName": "Darke",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 41243179.13,
    "employment": 768,
    "laborIncome": 24305269.18,
    "taxProductionImports": 8893035.68,
    "laborOutputPerWorker": 137151.49,
    "laborIncomePerWorker": 50773.27,
    "totalEconomy": 1936506432.24,
    "totalEmployment": 29450,
    "totalIncome": 1199093130.68
},
{
    "Id": 460,
    "county": 20,
    "fips": 39039,
    "countyName": "Defiance",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 32966143.56,
    "employment": 640,
    "laborIncome": 19811907.83,
    "taxProductionImports": 7951528.47,
    "laborOutputPerWorker": 145976.89,
    "laborIncomePerWorker": 59960.25,
    "totalEconomy": 1655173998.11,
    "totalEmployment": 22448,
    "totalIncome": 1011570926.19
},
{
    "Id": 461,
    "county": 21,
    "fips": 39041,
    "countyName": "Delaware",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 215508539.9,
    "employment": 3618,
    "laborIncome": 137856381.9,
    "taxProductionImports": 39284235.52,
    "laborOutputPerWorker": 152439.91,
    "laborIncomePerWorker": 63276.86,
    "totalEconomy": 10843610770.17,
    "totalEmployment": 136964,
    "totalIncome": 6910714850.97
},
{
    "Id": 462,
    "county": 22,
    "fips": 39043,
    "countyName": "Erie",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 65051589.42,
    "employment": 1231,
    "laborIncome": 38521224.61,
    "taxProductionImports": 14515973.29,
    "laborOutputPerWorker": 143427.54,
    "laborIncomePerWorker": 54966.31,
    "totalEconomy": 3655066143.06,
    "totalEmployment": 49835,
    "totalIncome": 2200419858.53
},
{
    "Id": 463,
    "county": 23,
    "fips": 39045,
    "countyName": "Fairfield",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 94945462.75,
    "employment": 2033,
    "laborIncome": 57290729.92,
    "taxProductionImports": 22347180.39,
    "laborOutputPerWorker": 136350.63,
    "laborIncomePerWorker": 49932.69,
    "totalEconomy": 4154705032.25,
    "totalEmployment": 64573,
    "totalIncome": 2430262447.52
},
{
    "Id": 464,
    "county": 24,
    "fips": 39047,
    "countyName": "Fayette",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 30460344.25,
    "employment": 535,
    "laborIncome": 19148214.89,
    "taxProductionImports": 5843155.09,
    "laborOutputPerWorker": 145751.53,
    "laborIncomePerWorker": 58360.76,
    "totalEconomy": 1039406462.1,
    "totalEmployment": 17332,
    "totalIncome": 615157312.99
},
{
    "Id": 465,
    "county": 25,
    "fips": 39049,
    "countyName": "Franklin",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 1633406818,
    "employment": 21781,
    "laborIncome": 994778931.6,
    "taxProductionImports": 262595663.4,
    "laborOutputPerWorker": 159805,
    "laborIncomePerWorker": 67241.34,
    "totalEconomy": 93118197636.6,
    "totalEmployment": 918222,
    "totalIncome": 56769200094.53
},
{
    "Id": 466,
    "county": 26,
    "fips": 39051,
    "countyName": "Fulton",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 38339009.51,
    "employment": 715,
    "laborIncome": 21843000.78,
    "taxProductionImports": 8682531.04,
    "laborOutputPerWorker": 133477.63,
    "laborIncomePerWorker": 47427.77,
    "totalEconomy": 1773401942.35,
    "totalEmployment": 26929,
    "totalIncome": 1136244367.14
},
{
    "Id": 467,
    "county": 27,
    "fips": 39053,
    "countyName": "Gallia",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 15628574.91,
    "employment": 368,
    "laborIncome": 9653042.03,
    "taxProductionImports": 4168834.37,
    "laborOutputPerWorker": 140395.31,
    "laborIncomePerWorker": 56760.86,
    "totalEconomy": 1506358279.89,
    "totalEmployment": 16209,
    "totalIncome": 629125908.79
},
{
    "Id": 468,
    "county": 28,
    "fips": 39055,
    "countyName": "Geauga",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 105757750.1,
    "employment": 1683,
    "laborIncome": 63024883.58,
    "taxProductionImports": 20412749.81,
    "laborOutputPerWorker": 148247.62,
    "laborIncomePerWorker": 59151.47,
    "totalEconomy": 3742816889.03,
    "totalEmployment": 53684,
    "totalIncome": 2228093395.43
},
{
    "Id": 469,
    "county": 29,
    "fips": 39057,
    "countyName": "Greene",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 118930753.2,
    "employment": 2520,
    "laborIncome": 72375230,
    "taxProductionImports": 26915283.7,
    "laborOutputPerWorker": 141728.8,
    "laborIncomePerWorker": 54061.09,
    "totalEconomy": 9642560583.37,
    "totalEmployment": 105110,
    "totalIncome": 6058681306.54
},
{
    "Id": 470,
    "county": 30,
    "fips": 39059,
    "countyName": "Guernsey",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 28928482.41,
    "employment": 600,
    "laborIncome": 17227154.47,
    "taxProductionImports": 6636383.04,
    "laborOutputPerWorker": 138866.9,
    "laborIncomePerWorker": 52162.13,
    "totalEconomy": 1595054307.57,
    "totalEmployment": 20987,
    "totalIncome": 909744860.5
},
{
    "Id": 471,
    "county": 31,
    "fips": 39061,
    "countyName": "Hamilton",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 1210016706,
    "employment": 14390,
    "laborIncome": 738069552.5,
    "taxProductionImports": 189755149.3,
    "laborOutputPerWorker": 164425.62,
    "laborIncomePerWorker": 71912.19,
    "totalEconomy": 70482980740.81,
    "totalEmployment": 648027,
    "totalIncome": 42590000667.83
},
{
    "Id": 472,
    "county": 32,
    "fips": 39063,
    "countyName": "Hancock",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 87458406.26,
    "employment": 1487,
    "laborIncome": 54064851.15,
    "taxProductionImports": 16057657.83,
    "laborOutputPerWorker": 143119.19,
    "laborIncomePerWorker": 55506.57,
    "totalEconomy": 4931274710.82,
    "totalEmployment": 56506,
    "totalIncome": 2996273249.86
},
{
    "Id": 473,
    "county": 33,
    "fips": 39065,
    "countyName": "Hardin",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 15872819.03,
    "employment": 319,
    "laborIncome": 9382165.39,
    "taxProductionImports": 3606081.42,
    "laborOutputPerWorker": 142184.94,
    "laborIncomePerWorker": 54502.95,
    "totalEconomy": 857016266.58,
    "totalEmployment": 13449,
    "totalIncome": 540393309.33
},
{
    "Id": 474,
    "county": 34,
    "fips": 39067,
    "countyName": "Harrison",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 8899723.86,
    "employment": 154,
    "laborIncome": 4884186.59,
    "taxProductionImports": 2072020.29,
    "laborOutputPerWorker": 127359.53,
    "laborIncomePerWorker": 42996.61,
    "totalEconomy": 487184291.94,
    "totalEmployment": 5517,
    "totalIncome": 238109478.52
},
{
    "Id": 475,
    "county": 35,
    "fips": 39069,
    "countyName": "Henry",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 16285547.67,
    "employment": 345,
    "laborIncome": 9682708.21,
    "taxProductionImports": 3933228.51,
    "laborOutputPerWorker": 131544.23,
    "laborIncomePerWorker": 46645.64,
    "totalEconomy": 1163234414.16,
    "totalEmployment": 16253,
    "totalIncome": 688006614.55
},
{
    "Id": 476,
    "county": 36,
    "fips": 39071,
    "countyName": "Highland",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 20366664.17,
    "employment": 458,
    "laborIncome": 12447933.17,
    "taxProductionImports": 4898571.39,
    "laborOutputPerWorker": 136142.16,
    "laborIncomePerWorker": 51142.24,
    "totalEconomy": 961520442.89,
    "totalEmployment": 15927,
    "totalIncome": 545085085.53
},
{
    "Id": 477,
    "county": 37,
    "fips": 39073,
    "countyName": "Hocking",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 13125382.24,
    "employment": 296,
    "laborIncome": 8157593.67,
    "taxProductionImports": 3131866.7,
    "laborOutputPerWorker": 139179.24,
    "laborIncomePerWorker": 53602.5,
    "totalEconomy": 648428103.41,
    "totalEmployment": 9690,
    "totalIncome": 340386907.29
},
{
    "Id": 478,
    "county": 38,
    "fips": 39075,
    "countyName": "Holmes",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 44744001.1,
    "employment": 819,
    "laborIncome": 27773314.03,
    "taxProductionImports": 9865370.49,
    "laborOutputPerWorker": 145436.65,
    "laborIncomePerWorker": 61307.18,
    "totalEconomy": 1968317546.63,
    "totalEmployment": 32350,
    "totalIncome": 1340411219.12
},
{
    "Id": 479,
    "county": 39,
    "fips": 39077,
    "countyName": "Huron",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 34548947.34,
    "employment": 718,
    "laborIncome": 19093259.62,
    "taxProductionImports": 9251059.5,
    "laborOutputPerWorker": 126431.84,
    "laborIncomePerWorker": 42715.24,
    "totalEconomy": 2226553149.69,
    "totalEmployment": 28683,
    "totalIncome": 1311308031.21
},
{
    "Id": 480,
    "county": 40,
    "fips": 39079,
    "countyName": "Jackson",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 20757985.77,
    "employment": 465,
    "laborIncome": 13113414.84,
    "taxProductionImports": 4814600.84,
    "laborOutputPerWorker": 136877.33,
    "laborIncomePerWorker": 51958.99,
    "totalEconomy": 938064693.66,
    "totalEmployment": 14675,
    "totalIncome": 562574305.69
},
{
    "Id": 481,
    "county": 41,
    "fips": 39081,
    "countyName": "Jefferson",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 49846566.04,
    "employment": 994,
    "laborIncome": 32029972.98,
    "taxProductionImports": 9815857.87,
    "laborOutputPerWorker": 145195.89,
    "laborIncomePerWorker": 57973.39,
    "totalEconomy": 2566153991.9,
    "totalEmployment": 27416,
    "totalIncome": 1217919020.49
},
{
    "Id": 482,
    "county": 42,
    "fips": 39083,
    "countyName": "Knox",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 32336745.12,
    "employment": 695,
    "laborIncome": 19585654.79,
    "taxProductionImports": 7456780.21,
    "laborOutputPerWorker": 135693.17,
    "laborIncomePerWorker": 50500.82,
    "totalEconomy": 2136062215.67,
    "totalEmployment": 30625,
    "totalIncome": 1312392515.79
},
{
    "Id": 483,
    "county": 43,
    "fips": 39085,
    "countyName": "Lake",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 231050441,
    "employment": 3831,
    "laborIncome": 137743569.3,
    "taxProductionImports": 45836995.96,
    "laborOutputPerWorker": 146608.56,
    "laborIncomePerWorker": 57586.77,
    "totalEconomy": 10617995628.95,
    "totalEmployment": 117716,
    "totalIncome": 5944295728.24
},
{
    "Id": 484,
    "county": 44,
    "fips": 39087,
    "countyName": "Lawrence",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 28355551.48,
    "employment": 640,
    "laborIncome": 17124630.32,
    "taxProductionImports": 6655455.01,
    "laborOutputPerWorker": 137675.82,
    "laborIncomePerWorker": 50595.55,
    "totalEconomy": 1144051784.96,
    "totalEmployment": 18640,
    "totalIncome": 670865912.96
},
{
    "Id": 485,
    "county": 45,
    "fips": 39089,
    "countyName": "Licking",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 117615945,
    "employment": 2220,
    "laborIncome": 71346898.97,
    "taxProductionImports": 23705164.81,
    "laborOutputPerWorker": 145104.81,
    "laborIncomePerWorker": 56718.99,
    "totalEconomy": 5705629059.02,
    "totalEmployment": 74399,
    "totalIncome": 3327378965.39
},
{
    "Id": 486,
    "county": 46,
    "fips": 39091,
    "countyName": "Logan",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 38298715.34,
    "employment": 652,
    "laborIncome": 24950432.7,
    "taxProductionImports": 5996696.41,
    "laborOutputPerWorker": 148468.19,
    "laborIncomePerWorker": 60206.55,
    "totalEconomy": 2054685543.96,
    "totalEmployment": 24585,
    "totalIncome": 1256558936.6
},
{
    "Id": 487,
    "county": 47,
    "fips": 39093,
    "countyName": "Lorain",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 223162572.2,
    "employment": 3975,
    "laborIncome": 134107878.6,
    "taxProductionImports": 46181509.02,
    "laborOutputPerWorker": 146512.39,
    "laborIncomePerWorker": 58152.73,
    "totalEconomy": 10263339652.71,
    "totalEmployment": 126142,
    "totalIncome": 6050242026.28
},
{
    "Id": 488,
    "county": 48,
    "fips": 39095,
    "countyName": "Lucas",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 433585188.7,
    "employment": 6940,
    "laborIncome": 268561895.4,
    "taxProductionImports": 85325196.4,
    "laborOutputPerWorker": 153036.57,
    "laborIncomePerWorker": 64721.3,
    "totalEconomy": 27821667781.16,
    "totalEmployment": 261164,
    "totalIncome": 14481328383.98
},
{
    "Id": 489,
    "county": 49,
    "fips": 39097,
    "countyName": "Madison",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 35563700.19,
    "employment": 631,
    "laborIncome": 23746806.26,
    "taxProductionImports": 6132996.98,
    "laborOutputPerWorker": 148201.71,
    "laborIncomePerWorker": 61028.36,
    "totalEconomy": 1546109233.43,
    "totalEmployment": 22433,
    "totalIncome": 1038857652.61
},
{
    "Id": 490,
    "county": 50,
    "fips": 39099,
    "countyName": "Mahoning",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 231063910.3,
    "employment": 3888,
    "laborIncome": 138819576.7,
    "taxProductionImports": 46864336.24,
    "laborOutputPerWorker": 143059.16,
    "laborIncomePerWorker": 55783.46,
    "totalEconomy": 9051758806.74,
    "totalEmployment": 128199,
    "totalIncome": 5872683349.78
},
{
    "Id": 491,
    "county": 51,
    "fips": 39101,
    "countyName": "Marion",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 46636555.32,
    "employment": 841,
    "laborIncome": 28067240.27,
    "taxProductionImports": 9931730.1,
    "laborOutputPerWorker": 147443.9,
    "laborIncomePerWorker": 58955.84,
    "totalEconomy": 2664768172.7,
    "totalEmployment": 33146,
    "totalIncome": 1607268443.56
},
{
    "Id": 492,
    "county": 52,
    "fips": 39103,
    "countyName": "Medina",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 165589084.5,
    "employment": 2745,
    "laborIncome": 99407346.89,
    "taxProductionImports": 33572581.52,
    "laborOutputPerWorker": 144119.23,
    "laborIncomePerWorker": 56735.95,
    "totalEconomy": 6481248228.04,
    "totalEmployment": 86106,
    "totalIncome": 3630185361.68
},
{
    "Id": 493,
    "county": 53,
    "fips": 39105,
    "countyName": "Meigs",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 5325101.26,
    "employment": 146,
    "laborIncome": 3460761.38,
    "taxProductionImports": 1401422.66,
    "laborOutputPerWorker": 129448.43,
    "laborIncomePerWorker": 47593.4,
    "totalEconomy": 340474070.72,
    "totalEmployment": 6033,
    "totalIncome": 185093558.77
},
{
    "Id": 494,
    "county": 54,
    "fips": 39107,
    "countyName": "Mercer",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 37102014.89,
    "employment": 655,
    "laborIncome": 21413782.6,
    "taxProductionImports": 8298195.26,
    "laborOutputPerWorker": 135320.69,
    "laborIncomePerWorker": 49401.9,
    "totalEconomy": 2086448826.14,
    "totalEmployment": 28571,
    "totalIncome": 1252954790.05
},
{
    "Id": 495,
    "county": 55,
    "fips": 39109,
    "countyName": "Miami",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 108695995.8,
    "employment": 1741,
    "laborIncome": 63246015.73,
    "taxProductionImports": 21557510.64,
    "laborOutputPerWorker": 143470.81,
    "laborIncomePerWorker": 54349.93,
    "totalEconomy": 4017206101.04,
    "totalEmployment": 57058,
    "totalIncome": 2469996868.78
},
{
    "Id": 496,
    "county": 56,
    "fips": 39111,
    "countyName": "Monroe",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 7368509.67,
    "employment": 176,
    "laborIncome": 4006374.19,
    "taxProductionImports": 2129524.76,
    "laborOutputPerWorker": 126723.65,
    "laborIncomePerWorker": 43516.61,
    "totalEconomy": 306914893.02,
    "totalEmployment": 6187,
    "totalIncome": 167730784.7
},
{
    "Id": 497,
    "county": 57,
    "fips": 39113,
    "countyName": "Montgomery",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 507279687.4,
    "employment": 8197,
    "laborIncome": 304743737.8,
    "taxProductionImports": 96972484.76,
    "laborOutputPerWorker": 146679.26,
    "laborIncomePerWorker": 57853.16,
    "totalEconomy": 27735849459.23,
    "totalEmployment": 309959,
    "totalIncome": 17013344003.36
},
{
    "Id": 498,
    "county": 58,
    "fips": 39115,
    "countyName": "Morgan",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 7403656.67,
    "employment": 182,
    "laborIncome": 4290910.21,
    "taxProductionImports": 1856724.83,
    "laborOutputPerWorker": 126015.02,
    "laborIncomePerWorker": 43355.24,
    "totalEconomy": 266257427.25,
    "totalEmployment": 5334,
    "totalIncome": 153284219.46
},
{
    "Id": 499,
    "county": 59,
    "fips": 39117,
    "countyName": "Morrow",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 10128507.32,
    "employment": 253,
    "laborIncome": 5990287.33,
    "taxProductionImports": 2514217.9,
    "laborOutputPerWorker": 145024.66,
    "laborIncomePerWorker": 60160.84,
    "totalEconomy": 1055850097.01,
    "totalEmployment": 11135,
    "totalIncome": 371932527.91
},
{
    "Id": 500,
    "county": 60,
    "fips": 39119,
    "countyName": "Muskingum",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 67643877.07,
    "employment": 1315,
    "laborIncome": 41386217.62,
    "taxProductionImports": 14390528.11,
    "laborOutputPerWorker": 143902.46,
    "laborIncomePerWorker": 56143.99,
    "totalEconomy": 3078496866.31,
    "totalEmployment": 43379,
    "totalIncome": 1859152215.05
},
{
    "Id": 501,
    "county": 61,
    "fips": 39121,
    "countyName": "Noble",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 5055460.83,
    "employment": 118,
    "laborIncome": 2996736.77,
    "taxProductionImports": 1272386.96,
    "laborOutputPerWorker": 124807.06,
    "laborIncomePerWorker": 41477.22,
    "totalEconomy": 381929828.05,
    "totalEmployment": 5194,
    "totalIncome": 195580028.7
},
{
    "Id": 502,
    "county": 62,
    "fips": 39123,
    "countyName": "Ottawa",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 24854243.09,
    "employment": 558,
    "laborIncome": 15263655.96,
    "taxProductionImports": 5742526.23,
    "laborOutputPerWorker": 133834.15,
    "laborIncomePerWorker": 49591.77,
    "totalEconomy": 1882586058.87,
    "totalEmployment": 20084,
    "totalIncome": 852123909.18
},
{
    "Id": 503,
    "county": 63,
    "fips": 39125,
    "countyName": "Paulding",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 7512912.48,
    "employment": 175,
    "laborIncome": 4354765.06,
    "taxProductionImports": 1852964.84,
    "laborOutputPerWorker": 124407.82,
    "laborIncomePerWorker": 40835.69,
    "totalEconomy": 483420907.84,
    "totalEmployment": 7567,
    "totalIncome": 309158032.79
},
{
    "Id": 504,
    "county": 64,
    "fips": 39127,
    "countyName": "Perry",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 12704021.13,
    "employment": 284,
    "laborIncome": 7629087.72,
    "taxProductionImports": 2933504.55,
    "laborOutputPerWorker": 129964.25,
    "laborIncomePerWorker": 46030.09,
    "totalEconomy": 536805451.96,
    "totalEmployment": 8424,
    "totalIncome": 288305553.36
},
{
    "Id": 505,
    "county": 65,
    "fips": 39129,
    "countyName": "Pickaway",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 26496049.15,
    "employment": 506,
    "laborIncome": 16735795.05,
    "taxProductionImports": 5377691.92,
    "laborOutputPerWorker": 149474.96,
    "laborIncomePerWorker": 61683.51,
    "totalEconomy": 1469763605.64,
    "totalEmployment": 20078,
    "totalIncome": 943957661.01
},
{
    "Id": 506,
    "county": 66,
    "fips": 39131,
    "countyName": "Pike",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 16762647.14,
    "employment": 352,
    "laborIncome": 10524244.75,
    "taxProductionImports": 3782584.35,
    "laborOutputPerWorker": 139064.25,
    "laborIncomePerWorker": 53148.01,
    "totalEconomy": 1080780724.81,
    "totalEmployment": 13181,
    "totalIncome": 676630642.49
},
{
    "Id": 507,
    "county": 67,
    "fips": 39133,
    "countyName": "Portage",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 155733127.4,
    "employment": 2324,
    "laborIncome": 91661757.06,
    "taxProductionImports": 29215887.19,
    "laborOutputPerWorker": 142685.25,
    "laborIncomePerWorker": 54690.35,
    "totalEconomy": 6153477202.51,
    "totalEmployment": 82453,
    "totalIncome": 3810308568.74
},
{
    "Id": 508,
    "county": 68,
    "fips": 39135,
    "countyName": "Preble",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 18910961.98,
    "employment": 387,
    "laborIncome": 11076545.8,
    "taxProductionImports": 4569565.94,
    "laborOutputPerWorker": 134013.59,
    "laborIncomePerWorker": 47626.04,
    "totalEconomy": 1031965637.09,
    "totalEmployment": 16433,
    "totalIncome": 607456742.47
},
{
    "Id": 509,
    "county": 69,
    "fips": 39137,
    "countyName": "Putnam",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 19442059.74,
    "employment": 370,
    "laborIncome": 11211272.51,
    "taxProductionImports": 4456590.83,
    "laborOutputPerWorker": 132144.79,
    "laborIncomePerWorker": 46840.86,
    "totalEconomy": 1324877177.53,
    "totalEmployment": 18676,
    "totalIncome": 739234658.39
},
{
    "Id": 510,
    "county": 70,
    "fips": 39139,
    "countyName": "Richland",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 97979103.93,
    "employment": 1810,
    "laborIncome": 57879935.74,
    "taxProductionImports": 21927798.19,
    "laborOutputPerWorker": 140348.79,
    "laborIncomePerWorker": 53191.07,
    "totalEconomy": 4385743452.01,
    "totalEmployment": 62487,
    "totalIncome": 2684050747.09
},
{
    "Id": 511,
    "county": 71,
    "fips": 39141,
    "countyName": "Ross",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 46787559.26,
    "employment": 989,
    "laborIncome": 28440277.64,
    "taxProductionImports": 10997734.96,
    "laborOutputPerWorker": 141894.92,
    "laborIncomePerWorker": 54817.49,
    "totalEconomy": 2711634375.21,
    "totalEmployment": 34919,
    "totalIncome": 1722690534.03
},
{
    "Id": 512,
    "county": 72,
    "fips": 39143,
    "countyName": "Sandusky",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 42853594.18,
    "employment": 882,
    "laborIncome": 25287388.4,
    "taxProductionImports": 9362355.99,
    "laborOutputPerWorker": 138692.21,
    "laborIncomePerWorker": 50912.52,
    "totalEconomy": 2866233783.01,
    "totalEmployment": 35809,
    "totalIncome": 1668233888.12
},
{
    "Id": 513,
    "county": 73,
    "fips": 39145,
    "countyName": "Scioto",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 38362784.08,
    "employment": 840,
    "laborIncome": 24085225.6,
    "taxProductionImports": 8417034.84,
    "laborOutputPerWorker": 140819.42,
    "laborIncomePerWorker": 54442.49,
    "totalEconomy": 2307993397.19,
    "totalEmployment": 31488,
    "totalIncome": 1356182385.87
},
{
    "Id": 514,
    "county": 74,
    "fips": 39147,
    "countyName": "Seneca",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 39028332.94,
    "employment": 665,
    "laborIncome": 22519184.38,
    "taxProductionImports": 8099247.16,
    "laborOutputPerWorker": 146421.74,
    "laborIncomePerWorker": 56925.77,
    "totalEconomy": 1793111677.46,
    "totalEmployment": 25442,
    "totalIncome": 1023140494.4
},
{
    "Id": 515,
    "county": 75,
    "fips": 39149,
    "countyName": "Shelby",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 57598680.87,
    "employment": 834,
    "laborIncome": 32945191.77,
    "taxProductionImports": 11364110.71,
    "laborOutputPerWorker": 143338.43,
    "laborIncomePerWorker": 54938.7,
    "totalEconomy": 3177680488.8,
    "totalEmployment": 35010,
    "totalIncome": 1828292144.85
},
{
    "Id": 516,
    "county": 76,
    "fips": 39151,
    "countyName": "Stark",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 332719259.7,
    "employment": 6099,
    "laborIncome": 196019014.4,
    "taxProductionImports": 71923598.8,
    "laborOutputPerWorker": 141300.43,
    "laborIncomePerWorker": 54111.9,
    "totalEconomy": 17621076554.15,
    "totalEmployment": 205504,
    "totalIncome": 9699356422.44
},
{
    "Id": 517,
    "county": 77,
    "fips": 39153,
    "countyName": "Summit",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 674712011.2,
    "employment": 9502,
    "laborIncome": 400861212.3,
    "taxProductionImports": 121224428.5,
    "laborOutputPerWorker": 151371.8,
    "laborIncomePerWorker": 60461,
    "totalEconomy": 29332130164.83,
    "totalEmployment": 337387,
    "totalIncome": 18452440621.06
},
{
    "Id": 518,
    "county": 78,
    "fips": 39155,
    "countyName": "Trumbull",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 152291746.2,
    "employment": 2910,
    "laborIncome": 91789388.63,
    "taxProductionImports": 31713338.33,
    "laborOutputPerWorker": 145746.46,
    "laborIncomePerWorker": 56465.02,
    "totalEconomy": 6979874771.89,
    "totalEmployment": 88293,
    "totalIncome": 4055841366.2
},
{
    "Id": 519,
    "county": 79,
    "fips": 39157,
    "countyName": "Tuscarawas",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 77771754.07,
    "employment": 1333,
    "laborIncome": 45720726.87,
    "taxProductionImports": 17183585.11,
    "laborOutputPerWorker": 141033.02,
    "laborIncomePerWorker": 54299.79,
    "totalEconomy": 3514031100.51,
    "totalEmployment": 46549,
    "totalIncome": 2081487962.11
},
{
    "Id": 520,
    "county": 80,
    "fips": 39159,
    "countyName": "Union",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 57161602.44,
    "employment": 679,
    "laborIncome": 33468314.04,
    "taxProductionImports": 9553753,
    "laborOutputPerWorker": 152016.08,
    "laborIncomePerWorker": 60991.1,
    "totalEconomy": 4225350827.57,
    "totalEmployment": 38043,
    "totalIncome": 2391128078.99
},
{
    "Id": 521,
    "county": 81,
    "fips": 39161,
    "countyName": "Van Wert",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 15851365.4,
    "employment": 300,
    "laborIncome": 9039627.84,
    "taxProductionImports": 3934169.4,
    "laborOutputPerWorker": 130015.37,
    "laborIncomePerWorker": 44895.37,
    "totalEconomy": 1045798993.33,
    "totalEmployment": 14036,
    "totalIncome": 618945547.79
},
{
    "Id": 522,
    "county": 82,
    "fips": 39163,
    "countyName": "Vinton",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 4360291.65,
    "employment": 77,
    "laborIncome": 2331499.89,
    "taxProductionImports": 1123217.49,
    "laborOutputPerWorker": 127128.49,
    "laborIncomePerWorker": 43072.88,
    "totalEconomy": 209604097.09,
    "totalEmployment": 3358,
    "totalIncome": 126466634.18
},
{
    "Id": 523,
    "county": 83,
    "fips": 39165,
    "countyName": "Warren",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 232338917,
    "employment": 3302,
    "laborIncome": 137982168.4,
    "taxProductionImports": 40378924.11,
    "laborOutputPerWorker": 154812.43,
    "laborIncomePerWorker": 62210.31,
    "totalEconomy": 10460071517.5,
    "totalEmployment": 114084,
    "totalIncome": 5895730256.97
},
{
    "Id": 524,
    "county": 84,
    "fips": 39167,
    "countyName": "Washington",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 48816448.25,
    "employment": 941,
    "laborIncome": 29598268.54,
    "taxProductionImports": 10536004.97,
    "laborOutputPerWorker": 142111.13,
    "laborIncomePerWorker": 55302.24,
    "totalEconomy": 3073221267.81,
    "totalEmployment": 33087,
    "totalIncome": 1687618381.22
},
{
    "Id": 525,
    "county": 85,
    "fips": 39169,
    "countyName": "Wayne",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 99648385.94,
    "employment": 1498,
    "laborIncome": 57994925.73,
    "taxProductionImports": 20203435.82,
    "laborOutputPerWorker": 146430.73,
    "laborIncomePerWorker": 58223.29,
    "totalEconomy": 4778824579.2,
    "totalEmployment": 62079,
    "totalIncome": 2939036730.22
},
{
    "Id": 526,
    "county": 86,
    "fips": 39171,
    "countyName": "Williams",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 24626540.37,
    "employment": 496,
    "laborIncome": 13734417.59,
    "taxProductionImports": 6379446.87,
    "laborOutputPerWorker": 130248.6,
    "laborIncomePerWorker": 45911.81,
    "totalEconomy": 1678123592.19,
    "totalEmployment": 23079,
    "totalIncome": 1017457079.48
},
{
    "Id": 527,
    "county": 87,
    "fips": 39173,
    "countyName": "Wood",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 131806183.1,
    "employment": 2095,
    "laborIncome": 78506162.76,
    "taxProductionImports": 26418072.87,
    "laborOutputPerWorker": 142820.74,
    "laborIncomePerWorker": 55259.88,
    "totalEconomy": 6637779808.79,
    "totalEmployment": 81563,
    "totalIncome": 3990971789.81
},
{
    "Id": 528,
    "county": 88,
    "fips": 39175,
    "countyName": "Wyandot",
    "indicator": "wholesale/retail",
    "year": 2015,
    "valueAdded": 13103388.2,
    "employment": 255,
    "laborIncome": 7329336.7,
    "taxProductionImports": 3169401.52,
    "laborOutputPerWorker": 135454.23,
    "laborIncomePerWorker": 48367.01,
    "totalEconomy": 960396796.31,
    "totalEmployment": 11898,
    "totalIncome": 569726183.18
}
]

export const commodities = [
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1041582.031
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 983624.8383
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Beef Cattle Production",
    "value": 4035587.907
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Poultry & Egg Production",
    "value": 20305.63692
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1237278.447
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Grain Production",
    "value": 196619.2722
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2923631.456
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 4101434.445
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Fruit & Vegetable Production",
    "value": 562539.5464
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 557598.602
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 5064746.419
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 636812.1173
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Processed Food & Kindred Products",
    "value": 2233376.103
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Beverage Processing",
    "value": 741520.1458
},
{
    "county": "Adams",
    "fips": 39001,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 5512238.909
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 19360148.04
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 288585.471
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Beef Cattle Production",
    "value": 1238968.961
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Poultry & Egg Production",
    "value": 2787250.727
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Hogs & Other Farm Animals",
    "value": 17342426.48
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Grain Production",
    "value": 1225617.185
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Soybean & Other Oil Crops",
    "value": 13822084.38
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 592197.2063
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Fruit & Vegetable Production",
    "value": 825870.7398
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 961299.614
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 352033.4754
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 17710782.5
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Dairy Processing",
    "value": 43449929.84
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Processed Food & Kindred Products",
    "value": 36850985.13
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Beverage Processing",
    "value": 54287501.01
},
{
    "county": "Allen",
    "fips": 39003,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 10714310.41
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 14413787.28
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 7538134.217
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Beef Cattle Production",
    "value": 3369438.276
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Poultry & Egg Production",
    "value": 3641934.991
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Hogs & Other Farm Animals",
    "value": 5385362.804
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Grain Production",
    "value": 644398.9724
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Soybean & Other Oil Crops",
    "value": 5410253.979
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1489086.078
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Fruit & Vegetable Production",
    "value": 2717006.799
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1897042.786
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4974811.134
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 3972544.061
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Processed Food & Kindred Products",
    "value": 32595277.55
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Grain Milling & Flour",
    "value": 2432205.254
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Beverage Processing",
    "value": 243053.2849
},
{
    "county": "Ashland",
    "fips": 39005,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 35749848.71
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 15621651.29
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 5234255.135
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Beef Cattle Production",
    "value": 1825540.2
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Poultry & Egg Production",
    "value": 51842.26995
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Hogs & Other Farm Animals",
    "value": 663844.2948
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Grain Production",
    "value": 573215.0078
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Soybean & Other Oil Crops",
    "value": 7158537.358
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2483291.713
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Fruit & Vegetable Production",
    "value": 2825640.27
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1632994.872
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 12413864.21
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 692315.3512
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Dairy Processing",
    "value": 92536.1868
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Processed Food & Kindred Products",
    "value": 3418118.13
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Beverage Processing",
    "value": 7724260.736
},
{
    "county": "Ashtabula",
    "fips": 39007,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 64086286.75
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 2926874.828
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 458341.6432
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Beef Cattle Production",
    "value": 1323371.44
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Poultry & Egg Production",
    "value": 18725.10219
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Hogs & Other Farm Animals",
    "value": 481726.6352
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Grain Production",
    "value": 31233.23806
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Soybean & Other Oil Crops",
    "value": 265733.4739
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1452966.112
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Fruit & Vegetable Production",
    "value": 677075.1255
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 234566.7697
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 346316.1419
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Processed Food & Kindred Products",
    "value": 2451910.289
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Beverage Processing",
    "value": 3845137.086
},
{
    "county": "Athens",
    "fips": 39009,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 57151.802
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 3244316.664
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 5060975.581
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Beef Cattle Production",
    "value": 4067492.783
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Poultry & Egg Production",
    "value": 3637345.433
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Hogs & Other Farm Animals",
    "value": 20756925.11
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Grain Production",
    "value": 1261248.976
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Soybean & Other Oil Crops",
    "value": 14943905.16
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 859650.0964
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Fruit & Vegetable Production",
    "value": 170973.6239
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 432262.9797
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 516150.6136
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 19510077.42
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Dairy Processing",
    "value": 89149829.52
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Processed Food & Kindred Products",
    "value": 35864355
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Beverage Processing",
    "value": 3749960.174
},
{
    "county": "Auglaize",
    "fips": 39011,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 14373706.82
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 42097021.82
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 653401.0507
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Beef Cattle Production",
    "value": 3231607.325
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Poultry & Egg Production",
    "value": 17091.2054
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Hogs & Other Farm Animals",
    "value": 428817.4864
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Grain Production",
    "value": 5374.788307
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Soybean & Other Oil Crops",
    "value": 7479.263506
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2585079.855
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Fruit & Vegetable Production",
    "value": 492730.2276
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 332263.021
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1677256.413
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 714387.5901
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Dairy Processing",
    "value": 19561010.65
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Processed Food & Kindred Products",
    "value": 12110402.44
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Beverage Processing",
    "value": 652960.6633
},
{
    "county": "Belmont",
    "fips": 39013,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 2298144.764
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1452385.532
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 527204.9829
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Beef Cattle Production",
    "value": 2847409.904
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Poultry & Egg Production",
    "value": 35245.52693
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1761991.203
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Grain Production",
    "value": 442298.3378
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Soybean & Other Oil Crops",
    "value": 12641177.79
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 3607205.409
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Fruit & Vegetable Production",
    "value": 487518.1306
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 194853.2682
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2889439.547
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 408045.5804
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Processed Food & Kindred Products",
    "value": 4198529.851
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Beverage Processing",
    "value": 1496048.694
},
{
    "county": "Brown",
    "fips": 39015,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 5265929.732
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 29798810.76
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 378588.2592
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Beef Cattle Production",
    "value": 2778099.595
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Poultry & Egg Production",
    "value": 20533.81375
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Hogs & Other Farm Animals",
    "value": 4468378.488
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Grain Production",
    "value": 578423.8316
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Soybean & Other Oil Crops",
    "value": 5860722.408
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1224611.181
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Fruit & Vegetable Production",
    "value": 450092.1679
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1606827.547
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1327219.966
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 13698038.55
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Processed Food & Kindred Products",
    "value": 65436062.73
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Beverage Processing",
    "value": 617483861.2
},
{
    "county": "Butler",
    "fips": 39017,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 222263800.9
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 4425225.343
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3535412.177
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Beef Cattle Production",
    "value": 2415962.055
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Poultry & Egg Production",
    "value": 1522944
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Hogs & Other Farm Animals",
    "value": 643888.7045
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Grain Production",
    "value": 170894.3322
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Soybean & Other Oil Crops",
    "value": 1227519.62
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2268455.433
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Fruit & Vegetable Production",
    "value": 353508.8131
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 2917344.064
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4123312.937
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Processed Food & Kindred Products",
    "value": 439701.173
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Fats & Oils Processing",
    "value": 5327047.467
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Beverage Processing",
    "value": 243053.2849
},
{
    "county": "Carroll",
    "fips": 39019,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 3768496.204
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 2115300.136
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1291107.714
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Beef Cattle Production",
    "value": 1452217.609
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Poultry & Egg Production",
    "value": 32261.16474
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Hogs & Other Farm Animals",
    "value": 5132444.68
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Grain Production",
    "value": 1380606.532
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Soybean & Other Oil Crops",
    "value": 10083044.2
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1254478.591
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Fruit & Vegetable Production",
    "value": 3222530.795
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 168554.8079
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 708067.945
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Processed Food & Kindred Products",
    "value": 739286.2063
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Beverage Processing",
    "value": 11677703.47
},
{
    "county": "Champaign",
    "fips": 39021,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 33444843.95
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 9382998.765
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1617546.201
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Beef Cattle Production",
    "value": 2717826.337
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Poultry & Egg Production",
    "value": 20589.45247
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Hogs & Other Farm Animals",
    "value": 3351506.65
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Grain Production",
    "value": 1476619.959
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Soybean & Other Oil Crops",
    "value": 9673154.712
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 417975.0326
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Fruit & Vegetable Production",
    "value": 892078.8537
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 9044475.783
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 791013.1145
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 854840.5468
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Dairy Processing",
    "value": 25674759.57
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Processed Food & Kindred Products",
    "value": 19368534.13
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Beverage Processing",
    "value": 51121712.68
},
{
    "county": "Clark",
    "fips": 39023,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 41074461.92
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 20177608.06
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 462156.0536
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Beef Cattle Production",
    "value": 817789.1672
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Poultry & Egg Production",
    "value": 36845.8014
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1891466.886
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Grain Production",
    "value": 478852.4508
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Soybean & Other Oil Crops",
    "value": 7753883.719
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 878249.7654
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Fruit & Vegetable Production",
    "value": 834654.0468
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1248459.246
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2449125.923
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Processed Food & Kindred Products",
    "value": 8829135.932
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Beverage Processing",
    "value": 6185823.444
},
{
    "county": "Clermont",
    "fips": 39025,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 69088895.74
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 4414908.983
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 376666.5049
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Beef Cattle Production",
    "value": 1097414.464
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Poultry & Egg Production",
    "value": 21146.49833
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Hogs & Other Farm Animals",
    "value": 6535554.051
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Grain Production",
    "value": 1851663.411
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Soybean & Other Oil Crops",
    "value": 17178943.69
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 321679.966
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Fruit & Vegetable Production",
    "value": 354768.9479
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 370457.9072
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4365023.312
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Processed Food & Kindred Products",
    "value": 18094232.55
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Beverage Processing",
    "value": 410262.1451
},
{
    "county": "Clinton",
    "fips": 39027,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 23680664.38
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 6028203.536
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 10845817.03
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Beef Cattle Production",
    "value": 3644846.022
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Poultry & Egg Production",
    "value": 1892948.426
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Hogs & Other Farm Animals",
    "value": 2464668.438
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Grain Production",
    "value": 376088.8875
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Soybean & Other Oil Crops",
    "value": 3209111.359
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 3780875.577
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Fruit & Vegetable Production",
    "value": 2996718.992
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1452656.42
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 5074088.694
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 63606763.79
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Dairy Processing",
    "value": 20208597.08
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Processed Food & Kindred Products",
    "value": 33965446.99
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Fats & Oils Processing",
    "value": 9491576.877
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Beverage Processing",
    "value": 2903128.686
},
{
    "county": "Columbiana",
    "fips": 39029,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 15408644.78
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 3566389.325
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3311205.909
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Beef Cattle Production",
    "value": 3591854.811
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Poultry & Egg Production",
    "value": 10114289.19
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Hogs & Other Farm Animals",
    "value": 7774164.081
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Grain Production",
    "value": 408620.0371
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2127542.656
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1813499.188
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Fruit & Vegetable Production",
    "value": 1595258.466
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1413364.697
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 6424076.358
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 63396573.9
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Dairy Processing",
    "value": 17385523.71
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Processed Food & Kindred Products",
    "value": 9333144.058
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Beverage Processing",
    "value": 4028681.701
},
{
    "county": "Coshocton",
    "fips": 39031,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 20676508.57
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 3067526.307
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 2231492.683
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Beef Cattle Production",
    "value": 1512543.291
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Poultry & Egg Production",
    "value": 859996.8255
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Hogs & Other Farm Animals",
    "value": 13404624.94
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Grain Production",
    "value": 1840210.319
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Soybean & Other Oil Crops",
    "value": 15996729.73
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 534487.4302
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Fruit & Vegetable Production",
    "value": 382219.4701
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 429036.6606
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1902010.904
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1440213.177
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Processed Food & Kindred Products",
    "value": 2913904.881
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Grain Milling & Flour",
    "value": 835989.0878
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Beverage Processing",
    "value": 242546.336
},
{
    "county": "Crawford",
    "fips": 39033,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 4091228.894
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 78223044.1
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 0
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Beef Cattle Production",
    "value": 11382.06362
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Poultry & Egg Production",
    "value": 29493.30548
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Hogs & Other Farm Animals",
    "value": 166837.0734
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Grain Production",
    "value": 0
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Soybean & Other Oil Crops",
    "value": 42444.58547
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 16762.08665
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Fruit & Vegetable Production",
    "value": 252780.3804
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 6228196.453
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 14486986.7
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 3575234.029
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Dairy Processing",
    "value": 43776420.26
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Processed Food & Kindred Products",
    "value": 364819789.9
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Grain Milling & Flour",
    "value": 4616805.136
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Beverage Processing",
    "value": 275095610
},
{
    "county": "Cuyahoga",
    "fips": 39035,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 210717277.1
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 12522330
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 9451574.326
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Beef Cattle Production",
    "value": 7391273.975
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Poultry & Egg Production",
    "value": 54955518.01
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Hogs & Other Farm Animals",
    "value": 49075206.28
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Grain Production",
    "value": 2124734.402
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Soybean & Other Oil Crops",
    "value": 21761313.59
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1430442.853
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Fruit & Vegetable Production",
    "value": 2110810.75
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 792828.1273
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3633678.419
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 7813837.676
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Processed Food & Kindred Products",
    "value": 6567929.138
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Beverage Processing",
    "value": 2131218.284
},
{
    "county": "Darke",
    "fips": 39037,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 4951296.501
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1675560.066
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 5030227.274
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Beef Cattle Production",
    "value": 1298173.353
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Poultry & Egg Production",
    "value": 155102.5566
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1214760.49
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Grain Production",
    "value": 774194.8813
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Soybean & Other Oil Crops",
    "value": 13348057.63
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 711728.6596
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Fruit & Vegetable Production",
    "value": 205722.2441
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 319357.7675
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1809577.818
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Dairy Processing",
    "value": 18745559.37
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Processed Food & Kindred Products",
    "value": 2959815.346
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Beverage Processing",
    "value": 378619.5422
},
{
    "county": "Defiance",
    "fips": 39039,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 3745396.364
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 18305756.45
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 502222.0016
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Beef Cattle Production",
    "value": 384324.2601
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Poultry & Egg Production",
    "value": 16388.73102
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Hogs & Other Farm Animals",
    "value": 7429661.512
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Grain Production",
    "value": 956439.9719
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Soybean & Other Oil Crops",
    "value": 10407296.6
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 638122.7685
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Fruit & Vegetable Production",
    "value": 1044167.146
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 16137681.9
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 510809.6469
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 2178320.676
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Processed Food & Kindred Products",
    "value": 52286240.99
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Beverage Processing",
    "value": 2974195.436
},
{
    "county": "Delaware",
    "fips": 39041,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 57105752.14
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 10885002.29
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 323497.5692
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Beef Cattle Production",
    "value": 439149.1283
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Poultry & Egg Production",
    "value": 487371.9988
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Hogs & Other Farm Animals",
    "value": 2450148.478
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Grain Production",
    "value": 650834.9739
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Soybean & Other Oil Crops",
    "value": 4451883.256
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 363585.3774
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Fruit & Vegetable Production",
    "value": 5937730.752
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1932278.244
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 21230006.13
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 27329981.38
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Dairy Processing",
    "value": 6329726.419
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Processed Food & Kindred Products",
    "value": 7162545.438
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Grain Milling & Flour",
    "value": 1205258.31
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Fats & Oils Processing",
    "value": 9362307.549
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Beverage Processing",
    "value": 3116600.513
},
{
    "county": "Erie",
    "fips": 39043,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 7573262.203
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 14304522.35
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1154662.162
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Beef Cattle Production",
    "value": 2316763.751
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Poultry & Egg Production",
    "value": 36680.11652
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Hogs & Other Farm Animals",
    "value": 8052229.375
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Grain Production",
    "value": 1032647.535
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Soybean & Other Oil Crops",
    "value": 9283096.82
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 858626.2316
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Fruit & Vegetable Production",
    "value": 1341603.094
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 790220.8664
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 510283.5447
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1267275.723
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Dairy Processing",
    "value": 14664398.34
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Processed Food & Kindred Products",
    "value": 7679191.446
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Grain Milling & Flour",
    "value": 74979960.35
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Beverage Processing",
    "value": 9816778.213
},
{
    "county": "Fairfield",
    "fips": 39045,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 36101533.48
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1094622.214
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3670576.602
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Beef Cattle Production",
    "value": 535230.2026
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Poultry & Egg Production",
    "value": 10247.44395
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1259649.426
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Grain Production",
    "value": 1522067.726
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Soybean & Other Oil Crops",
    "value": 15108878.43
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 473891.5766
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Fruit & Vegetable Production",
    "value": 171603.271
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 425810.3538
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3555244.164
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 28108620.46
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Processed Food & Kindred Products",
    "value": 97930455.15
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Beverage Processing",
    "value": 0
},
{
    "county": "Fayette",
    "fips": 39047,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 9095118.742
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 152556493.9
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 462156.0425
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Beef Cattle Production",
    "value": 145166.2416
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Poultry & Egg Production",
    "value": 23469.00606
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Hogs & Other Farm Animals",
    "value": 914056.2937
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Grain Production",
    "value": 312176.2574
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Soybean & Other Oil Crops",
    "value": 3342087.373
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 152489.0996
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Fruit & Vegetable Production",
    "value": 860358.0419
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 12254511.48
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4848830.044
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 41880038.83
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Dairy Processing",
    "value": 787054167.9
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Processed Food & Kindred Products",
    "value": 427319069.1
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Grain Milling & Flour",
    "value": 90244211.21
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Beverage Processing",
    "value": 1018738268
},
{
    "county": "Franklin",
    "fips": 39049,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 102886455.6
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 22709622.54
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3570644.669
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Beef Cattle Production",
    "value": 8497306.556
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Poultry & Egg Production",
    "value": 11317.91645
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Hogs & Other Farm Animals",
    "value": 4740505.71
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Grain Production",
    "value": 1551646.262
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Soybean & Other Oil Crops",
    "value": 12616898.09
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 851292.7477
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Fruit & Vegetable Production",
    "value": 1945376.043
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1606077.422
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2422565.572
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 195748.63
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Processed Food & Kindred Products",
    "value": 76077300.58
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Beverage Processing",
    "value": 1244583.949
},
{
    "county": "Fulton",
    "fips": 39051,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 16947781.85
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1082307.222
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 286343.3901
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Beef Cattle Production",
    "value": 2930655.748
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Poultry & Egg Production",
    "value": 16162.75084
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Hogs & Other Farm Animals",
    "value": 901241.9581
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Grain Production",
    "value": 61551.40139
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Soybean & Other Oil Crops",
    "value": 453298.6642
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1996303.458
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Fruit & Vegetable Production",
    "value": 460158.1456
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 113503.5746
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1108970.303
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 3282812.848
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Processed Food & Kindred Products",
    "value": 2134873.688
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Beverage Processing",
    "value": 785099.3153
},
{
    "county": "Gallia",
    "fips": 39053,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 992379.9038
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 33390856.84
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 5443087.041
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Beef Cattle Production",
    "value": 1611924.872
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Poultry & Egg Production",
    "value": 44522.91
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Hogs & Other Farm Animals",
    "value": 4826847.37
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Grain Production",
    "value": 119676.58
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Soybean & Other Oil Crops",
    "value": 555248.85
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1530311.7
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Fruit & Vegetable Production",
    "value": 2644989.37
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 3673597.61
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 34886506.58
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Dairy Processing",
    "value": 18666196.26
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Processed Food & Kindred Products",
    "value": 8028207.37
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Grain Milling & Flour",
    "value": 345527.08
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Beverage Processing",
    "value": 2536857.66
},
{
    "county": "Geauga",
    "fips": 39055,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 29444394.96
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 4290463.552
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 676462.2964
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Beef Cattle Production",
    "value": 3306728.825
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Poultry & Egg Production",
    "value": 18962.13181
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1357143.313
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Grain Production",
    "value": 44053.58527
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Soybean & Other Oil Crops",
    "value": 352228.4306
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2663473.799
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Fruit & Vegetable Production",
    "value": 430980.249
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 268534.0829
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 13469583.33
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Processed Food & Kindred Products",
    "value": 18628738.06
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Beverage Processing",
    "value": 923517.7283
},
{
    "county": "Greene",
    "fips": 39057,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 9173523.869
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 4290463.552
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 676462.2964
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Beef Cattle Production",
    "value": 3306728.825
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Poultry & Egg Production",
    "value": 18962.13181
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1357143.313
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Grain Production",
    "value": 44053.58527
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Soybean & Other Oil Crops",
    "value": 352228.4306
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2663473.799
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Fruit & Vegetable Production",
    "value": 430980.249
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 268534.0829
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 13469583.33
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Processed Food & Kindred Products",
    "value": 18628738.06
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Beverage Processing",
    "value": 923517.7283
},
{
    "county": "Guernsey",
    "fips": 39059,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 9173523.869
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 108725508.9
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 462156.0387
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Beef Cattle Production",
    "value": 245504.8598
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Poultry & Egg Production",
    "value": 118202.0451
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Hogs & Other Farm Animals",
    "value": 659227.3787
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Grain Production",
    "value": 53369.68228
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Soybean & Other Oil Crops",
    "value": 355551.1832
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 246686.4492
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Fruit & Vegetable Production",
    "value": 817528.5705
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 6326894.067
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 54199.73657
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 196331416.6
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Dairy Processing",
    "value": 137553792
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Processed Food & Kindred Products",
    "value": 321809927.6
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Grain Milling & Flour",
    "value": 96777736.36
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Fats & Oils Processing",
    "value": 39871898.81
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Beverage Processing",
    "value": 1207837212
},
{
    "county": "Hamilton",
    "fips": 39061,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 143693963.7
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 7744019.441
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1151459.232
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Beef Cattle Production",
    "value": 420446.3493
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Poultry & Egg Production",
    "value": 2181357.659
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Hogs & Other Farm Animals",
    "value": 3598062.232
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Grain Production",
    "value": 1719830.006
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Soybean & Other Oil Crops",
    "value": 18565774.98
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 457759.3358
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Fruit & Vegetable Production",
    "value": 474706.0928
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 98078.44838
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2034685.954
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1995366.362
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Processed Food & Kindred Products",
    "value": 176366971.3
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Grain Milling & Flour",
    "value": 18860618.95
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Fats & Oils Processing",
    "value": 7610469.937
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Beverage Processing",
    "value": 1066095.352
},
{
    "county": "Hancock",
    "fips": 39063,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 15076799.38
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 4379114.028
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 8780964.613
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Beef Cattle Production",
    "value": 1140320.845
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Poultry & Egg Production",
    "value": 3560446.59
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Hogs & Other Farm Animals",
    "value": 16526251.67
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Grain Production",
    "value": 1754632.711
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Soybean & Other Oil Crops",
    "value": 17528332.47
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 213523.9929
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Fruit & Vegetable Production",
    "value": 726746.0276
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 535145.5291
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 114220.3415
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Processed Food & Kindred Products",
    "value": 569435.2044
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Fats & Oils Processing",
    "value": 7597299.586
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Beverage Processing",
    "value": 0
},
{
    "county": "Hardin",
    "fips": 39065,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 38780493.44
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": -14960.43328
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 474356.3589
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Beef Cattle Production",
    "value": 1895273.715
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Poultry & Egg Production",
    "value": 249075.504
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1550896.868
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Grain Production",
    "value": 69568.79236
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Soybean & Other Oil Crops",
    "value": 370227.7741
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2193793.973
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Fruit & Vegetable Production",
    "value": 374528.3392
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 325810.3877
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1086815.888
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Processed Food & Kindred Products",
    "value": 356765.3708
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Beverage Processing",
    "value": 243053.2849
},
{
    "county": "Harrison",
    "fips": 39067,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 10920342.12
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 4417380.092
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1594426.714
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Beef Cattle Production",
    "value": 1256388.262
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Poultry & Egg Production",
    "value": 66689.57858
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1121813.774
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Grain Production",
    "value": 1511923.723
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Soybean & Other Oil Crops",
    "value": 17762134.28
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1228092.004
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Fruit & Vegetable Production",
    "value": 3454732.285
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 212905.1783
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2231512.609
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1060730.493
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Processed Food & Kindred Products",
    "value": 289737016.5
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Beverage Processing",
    "value": 1276034.995
},
{
    "county": "Henry",
    "fips": 39069,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 1270445.011
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 3121750.591
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1330824.226
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Beef Cattle Production",
    "value": 3706455.32
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Poultry & Egg Production",
    "value": 31226.83463
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Hogs & Other Farm Animals",
    "value": 4531982.243
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Grain Production",
    "value": 1017081.454
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Soybean & Other Oil Crops",
    "value": 15065555.1
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1662655.294
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Fruit & Vegetable Production",
    "value": 1573156.527
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 750019.392
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2674791.334
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 553602.1888
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Processed Food & Kindred Products",
    "value": 12210058.03
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Beverage Processing",
    "value": 252010.4999
},
{
    "county": "Highland",
    "fips": 39071,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 7639881.212
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1017426.03
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 462156.0536
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Beef Cattle Production",
    "value": 429120.7008
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Poultry & Egg Production",
    "value": 14409.58143
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Hogs & Other Farm Animals",
    "value": 366886.0178
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Grain Production",
    "value": 32880.27924
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Soybean & Other Oil Crops",
    "value": 392533.1873
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 420766.0583
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Fruit & Vegetable Production",
    "value": 288506.6957
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 357618.188
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3975509.822
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Processed Food & Kindred Products",
    "value": 841952.6424
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Grain Milling & Flour",
    "value": 14653533.91
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Beverage Processing",
    "value": 4729214.605
},
{
    "county": "Hocking",
    "fips": 39073,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 15019680.67
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 25492303.97
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 24961842.57
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Beef Cattle Production",
    "value": 9183442.943
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Poultry & Egg Production",
    "value": 11120127.45
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Hogs & Other Farm Animals",
    "value": 7813517.816
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Grain Production",
    "value": 587348.6437
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2712494.582
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2755649.132
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Fruit & Vegetable Production",
    "value": 2328359.443
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 4496819.315
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 43901270.86
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 42894575.02
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Dairy Processing",
    "value": 15553858.56
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Processed Food & Kindred Products",
    "value": 22283731.48
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Grain Milling & Flour",
    "value": 236072.9724
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Beverage Processing",
    "value": 6086072.251
},
{
    "county": "Holmes",
    "fips": 39075,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 135821492.5
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 7654854.044
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 4683988.631
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Beef Cattle Production",
    "value": 3081378.43
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Poultry & Egg Production",
    "value": 1872864.328
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Hogs & Other Farm Animals",
    "value": 5255089.849
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Grain Production",
    "value": 1699912.488
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Soybean & Other Oil Crops",
    "value": 14115804.55
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 651075.09
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Fruit & Vegetable Production",
    "value": 14701706.1
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 698063.3214
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1148894.661
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Processed Food & Kindred Products",
    "value": 129284501.1
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Fats & Oils Processing",
    "value": 46785414.22
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Beverage Processing",
    "value": 139465.9448
},
{
    "county": "Huron",
    "fips": 39077,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 3504481.55
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1139499.25
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3697248.31
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Beef Cattle Production",
    "value": 1840759.151
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Poultry & Egg Production",
    "value": 57811.26535
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Hogs & Other Farm Animals",
    "value": 375316.2026
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Grain Production",
    "value": 21474.1854
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Soybean & Other Oil Crops",
    "value": 418893.2148
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1397357.324
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Fruit & Vegetable Production",
    "value": 536705.5524
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 749654.3787
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 19970787.63
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Processed Food & Kindred Products",
    "value": 170351042.9
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Fats & Oils Processing",
    "value": 7448608.361
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Beverage Processing",
    "value": 0
},
{
    "county": "Jackson",
    "fips": 39079,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 12345041.29
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 2135618.021
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 421187.4567
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Beef Cattle Production",
    "value": 1288659.759
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Poultry & Egg Production",
    "value": 161264.1481
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Hogs & Other Farm Animals",
    "value": 270296.1508
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Grain Production",
    "value": 12838.91872
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Soybean & Other Oil Crops",
    "value": 22947.07816
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1857948.887
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Fruit & Vegetable Production",
    "value": 116971.03
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 237217.7314
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1311361.057
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Processed Food & Kindred Products",
    "value": 2412974.543
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Beverage Processing",
    "value": 160629.116
},
{
    "county": "Jefferson",
    "fips": 39081,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 12522962.4
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 3502544.079
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 2983864.725
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Beef Cattle Production",
    "value": 2734276.339
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Poultry & Egg Production",
    "value": 1738609.441
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Hogs & Other Farm Animals",
    "value": 8832363.963
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Grain Production",
    "value": 957701.5042
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Soybean & Other Oil Crops",
    "value": 7772198.29
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1850557.597
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Fruit & Vegetable Production",
    "value": 1637335.7
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1583420.363
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4881706.64
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 16975573.66
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Processed Food & Kindred Products",
    "value": 1167321.705
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Beverage Processing",
    "value": 4667085.733
},
{
    "county": "Knox",
    "fips": 39083,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 31766288
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 28076354.82
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 231078.0175
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Beef Cattle Production",
    "value": 86479.33509
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Poultry & Egg Production",
    "value": 134092.4772
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Hogs & Other Farm Animals",
    "value": 260609.9509
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Grain Production",
    "value": 4798.087873
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Soybean & Other Oil Crops",
    "value": 68559.45743
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 177388.4696
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Fruit & Vegetable Production",
    "value": 1319292.875
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 50790113.84
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 280180.972
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 355029.2552
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Processed Food & Kindred Products",
    "value": 15455875
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Beverage Processing",
    "value": 317776.7899
},
{
    "county": "Lake",
    "fips": 39085,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 293359235.3
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 856700.7938
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 462156.0425
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Beef Cattle Production",
    "value": 1011097.454
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Poultry & Egg Production",
    "value": 35214.55231
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Hogs & Other Farm Animals",
    "value": 661749.6908
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Grain Production",
    "value": 13801.60078
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Soybean & Other Oil Crops",
    "value": 121586.6843
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 792411.0474
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Fruit & Vegetable Production",
    "value": 470654.2832
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 134587.4913
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 269884.8941
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Processed Food & Kindred Products",
    "value": 1431885.676
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Beverage Processing",
    "value": 3638687.039
},
{
    "county": "Lawrence",
    "fips": 39087,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 379531.025
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 15132227.77
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 2486767.62
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Beef Cattle Production",
    "value": 4521494.269
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Poultry & Egg Production",
    "value": 11630481.96
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Hogs & Other Farm Animals",
    "value": 5393016.577
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Grain Production",
    "value": 1152079.582
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Soybean & Other Oil Crops",
    "value": 9154908.001
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2163974.706
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Fruit & Vegetable Production",
    "value": 2584341.806
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 6721683.927
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2647379.264
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 863380.7341
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Dairy Processing",
    "value": 20755600.81
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Processed Food & Kindred Products",
    "value": 16870182.41
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Beverage Processing",
    "value": 8801646.208
},
{
    "county": "Licking",
    "fips": 39089,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 48298406.95
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 3138972.953
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3345797.762
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Beef Cattle Production",
    "value": 1325515.27
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Poultry & Egg Production",
    "value": 1169856.302
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Hogs & Other Farm Animals",
    "value": 3576651.663
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Grain Production",
    "value": 1493084.162
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Soybean & Other Oil Crops",
    "value": 14082404.46
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1163840.558
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Fruit & Vegetable Production",
    "value": 735539.1954
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 654096.9633
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2206297.035
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1589308.118
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Dairy Processing",
    "value": 1541631.234
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Processed Food & Kindred Products",
    "value": 3919838.237
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Beverage Processing",
    "value": 1164501.297
},
{
    "county": "Logan",
    "fips": 39091,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 10654946.01
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 32577339.18
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 2940304.682
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Beef Cattle Production",
    "value": 1170575.395
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Poultry & Egg Production",
    "value": 22899.6583
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Hogs & Other Farm Animals",
    "value": 4490020.037
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Grain Production",
    "value": 588633.813
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Soybean & Other Oil Crops",
    "value": 8814569.011
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 545861.429
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Fruit & Vegetable Production",
    "value": 3577718.989
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 62942725.45
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1611338.837
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Dairy Processing",
    "value": 92551.0743
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Processed Food & Kindred Products",
    "value": 26115888.75
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Beverage Processing",
    "value": 45022148.62
},
{
    "county": "Lorain",
    "fips": 39093,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 10646015.73
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 45417413.28
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 0
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Beef Cattle Production",
    "value": 99619.09056
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Poultry & Egg Production",
    "value": 7236.246704
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Hogs & Other Farm Animals",
    "value": 3013152.272
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Grain Production",
    "value": 491665.9594
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Soybean & Other Oil Crops",
    "value": 5393383.443
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 101920.8662
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Fruit & Vegetable Production",
    "value": 2318714.467
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 11314431
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 11070868.85
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Dairy Processing",
    "value": 27085097.59
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Processed Food & Kindred Products",
    "value": 98058158.79
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Grain Milling & Flour",
    "value": 7246615.052
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Beverage Processing",
    "value": 76617597.28
},
{
    "county": "Lucas",
    "fips": 39095,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 38690533.87
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 9529903.475
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 6719461.262
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Beef Cattle Production",
    "value": 2092513.472
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Poultry & Egg Production",
    "value": 19661.30227
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Hogs & Other Farm Animals",
    "value": 7265129.864
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Grain Production",
    "value": 1993434.34
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Soybean & Other Oil Crops",
    "value": 16884917.14
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 715688.8243
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Fruit & Vegetable Production",
    "value": 579898.2137
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 162786.7707
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 5748212.007
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Dairy Processing",
    "value": 2003063.88
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Processed Food & Kindred Products",
    "value": 1725438.17
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Beverage Processing",
    "value": 746219.9028
},
{
    "county": "Madison",
    "fips": 39097,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 2060609.634
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 7359145.23
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 7295991.629
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Beef Cattle Production",
    "value": 1738810.539
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Poultry & Egg Production",
    "value": 1723983.72
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1291578.829
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Grain Production",
    "value": 291415.2145
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2760211.229
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 940658.642
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Fruit & Vegetable Production",
    "value": 3154010.175
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 919681.2031
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1415743.558
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 715334.3596
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Processed Food & Kindred Products",
    "value": 66729954.54
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Beverage Processing",
    "value": 4777612.373
},
{
    "county": "Mahoning",
    "fips": 39099,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 49326840.12
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 31511611.99
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3905032.188
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Beef Cattle Production",
    "value": 545865.1297
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Poultry & Egg Production",
    "value": 9854.412347
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Hogs & Other Farm Animals",
    "value": 14148502.23
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Grain Production",
    "value": 1290718.853
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Soybean & Other Oil Crops",
    "value": 14397925.38
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 357098.0531
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Fruit & Vegetable Production",
    "value": 265205.3589
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 429036.6624
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1601076.109
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Processed Food & Kindred Products",
    "value": 107292995.5
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Beverage Processing",
    "value": 381688.1105
},
{
    "county": "Marion",
    "fips": 39101,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 49421056.91
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 31399630.95
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3128317.639
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Beef Cattle Production",
    "value": 1050657.406
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Poultry & Egg Production",
    "value": 35024.77903
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1480933.562
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Grain Production",
    "value": 345803.4992
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Soybean & Other Oil Crops",
    "value": 4243673.906
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1891388.518
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Fruit & Vegetable Production",
    "value": 2304538.363
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 4338684.002
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1076243.493
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 5016323.417
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Processed Food & Kindred Products",
    "value": 249278867.5
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Beverage Processing",
    "value": 10997713.03
},
{
    "county": "Medina",
    "fips": 39103,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 37182826.37
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 577950.4739
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 763902.7312
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Beef Cattle Production",
    "value": 1309379.481
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Poultry & Egg Production",
    "value": 36830.06868
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Hogs & Other Farm Animals",
    "value": 340286.2046
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Grain Production",
    "value": 42544.57913
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Soybean & Other Oil Crops",
    "value": 122215.515
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1607961.524
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Fruit & Vegetable Production",
    "value": 1462636.803
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1872710.593
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1417798.097
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Dairy Processing",
    "value": 4784582.41
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Processed Food & Kindred Products",
    "value": 164633.6308
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Beverage Processing",
    "value": 170897.7241
},
{
    "county": "Meigs",
    "fips": 39105,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 466377.7333
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 61940847.79
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 19488966.94
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Beef Cattle Production",
    "value": 12989463.45
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Poultry & Egg Production",
    "value": 45533090.71
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Hogs & Other Farm Animals",
    "value": 53919416.43
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Grain Production",
    "value": 1862343.043
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Soybean & Other Oil Crops",
    "value": 17799666.26
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1332756.207
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Fruit & Vegetable Production",
    "value": 326632.0049
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 311517.5995
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 6922429.665
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 69010637.16
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Processed Food & Kindred Products",
    "value": 61189143.7
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Beverage Processing",
    "value": 4311630.577
},
{
    "county": "Mercer",
    "fips": 39107,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 7787456.646
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 8660386.406
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 923089.1243
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Beef Cattle Production",
    "value": 875731.2037
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Poultry & Egg Production",
    "value": 224791.8528
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Hogs & Other Farm Animals",
    "value": 2948171.526
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Grain Production",
    "value": 1284080.684
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Soybean & Other Oil Crops",
    "value": 10849455.03
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 915142.6975
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Fruit & Vegetable Production",
    "value": 2096726.436
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1515972
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1308552.94
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Dairy Processing",
    "value": 9565840.557
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Processed Food & Kindred Products",
    "value": 123650309.3
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Beverage Processing",
    "value": 280756.1979
},
{
    "county": "Miami",
    "fips": 39109,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 56390799.13
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 20097.1684
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1316090.712
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Beef Cattle Production",
    "value": 2486372.594
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Poultry & Egg Production",
    "value": 17851.73535
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Hogs & Other Farm Animals",
    "value": 781728.5303
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Grain Production",
    "value": 11156.88746
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Soybean & Other Oil Crops",
    "value": 21222.7625
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2346207.112
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Fruit & Vegetable Production",
    "value": 237508.2806
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 270478.6208
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 806145.7518
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 97887.77423
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Processed Food & Kindred Products",
    "value": 322335.9205
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Beverage Processing",
    "value": 243053.2849
},
{
    "county": "Monroe",
    "fips": 39111,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 19062.4833
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 34672483.29
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 518877.3088
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Beef Cattle Production",
    "value": 1927855.551
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Poultry & Egg Production",
    "value": 36148.12833
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Hogs & Other Farm Animals",
    "value": 2000241.429
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Grain Production",
    "value": 640744.1944
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Soybean & Other Oil Crops",
    "value": 5871616.14
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 826031.11
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Fruit & Vegetable Production",
    "value": 833926.0316
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 8239536.457
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 473401.98
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 41396051.02
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Processed Food & Kindred Products",
    "value": 52325562.96
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Grain Milling & Flour",
    "value": 101829061.4
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Beverage Processing",
    "value": 100200458
},
{
    "county": "Montgomery",
    "fips": 39113,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 50356287.94
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1942253.134
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 848780.7624
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Beef Cattle Production",
    "value": 2264322.653
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Poultry & Egg Production",
    "value": 39972.75012
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Hogs & Other Farm Animals",
    "value": 221049.8732
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Grain Production",
    "value": 41166.59425
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Soybean & Other Oil Crops",
    "value": 99606.76043
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1290196.069
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Fruit & Vegetable Production",
    "value": 364998.4548
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 442856.9337
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1297542.768
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Processed Food & Kindred Products",
    "value": 355643.2021
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Beverage Processing",
    "value": 243053.2849
},
{
    "county": "Morgan",
    "fips": 39115,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 136541.4297
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 3133231.055
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1747527.547
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Beef Cattle Production",
    "value": 1635578.424
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Poultry & Egg Production",
    "value": 44712.59913
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Hogs & Other Farm Animals",
    "value": 9259988.01
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Grain Production",
    "value": 1102185.369
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Soybean & Other Oil Crops",
    "value": 11109368.89
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1313972.286
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Fruit & Vegetable Production",
    "value": 689420.4868
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 225106.3208
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 873400.7645
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 956623.2659
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Processed Food & Kindred Products",
    "value": 2710909.833
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Beverage Processing",
    "value": 605133.4739
},
{
    "county": "Morrow",
    "fips": 39117,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 2614517.679
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 2919457.342
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1226087.898
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Beef Cattle Production",
    "value": 6366837.204
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Poultry & Egg Production",
    "value": 843962.8109
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Hogs & Other Farm Animals",
    "value": 3626496.628
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Grain Production",
    "value": 355334.6172
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2165714.81
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 3010264.663
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Fruit & Vegetable Production",
    "value": 829776.8699
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 779325.6412
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3431735.571
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 6843085.899
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Processed Food & Kindred Products",
    "value": 123739094.9
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Beverage Processing",
    "value": 25149369.37
},
{
    "county": "Muskingum",
    "fips": 39119,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 14647885.62
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 617251.4671
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 231078.025
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Beef Cattle Production",
    "value": 2266762.704
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Poultry & Egg Production",
    "value": 13464.10764
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Hogs & Other Farm Animals",
    "value": 568565.4581
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Grain Production",
    "value": 8607.735974
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Soybean & Other Oil Crops",
    "value": 0
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2195163.191
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Fruit & Vegetable Production",
    "value": 273869.2121
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 354606.8785
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2415929.065
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 5968030.201
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Processed Food & Kindred Products",
    "value": 104027.991
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Beverage Processing",
    "value": 243053.2849
},
{
    "county": "Noble",
    "fips": 39121,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 5924776.359
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 14381064.94
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 0
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Beef Cattle Production",
    "value": 265873.1695
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Poultry & Egg Production",
    "value": 31738.59173
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Hogs & Other Farm Animals",
    "value": 513339.4077
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Grain Production",
    "value": 585003.7634
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Soybean & Other Oil Crops",
    "value": 11042962.7
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 748599.4962
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Fruit & Vegetable Production",
    "value": 5232291.952
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 858073.3472
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1152739.4
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1778741.007
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Processed Food & Kindred Products",
    "value": 1212025.006
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Beverage Processing",
    "value": 18556386.71
},
{
    "county": "Ottawa",
    "fips": 39123,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 10412981.39
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 14381064.94
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 0
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Beef Cattle Production",
    "value": 265873.1695
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Poultry & Egg Production",
    "value": 31738.59173
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Hogs & Other Farm Animals",
    "value": 513339.4077
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Grain Production",
    "value": 585003.7634
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Soybean & Other Oil Crops",
    "value": 11042962.7
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 748599.4962
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Fruit & Vegetable Production",
    "value": 5232291.952
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 858073.3472
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1152739.4
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1778741.007
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Processed Food & Kindred Products",
    "value": 1212025.006
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Beverage Processing",
    "value": 18556386.71
},
{
    "county": "Paulding",
    "fips": 39125,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 10412981.39
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1575138.458
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 10598229.29
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Beef Cattle Production",
    "value": 791935.347
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Poultry & Egg Production",
    "value": 233140.5431
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Hogs & Other Farm Animals",
    "value": 5756143.63
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Grain Production",
    "value": 1031544.328
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Soybean & Other Oil Crops",
    "value": 15017960.31
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 510773.252
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Fruit & Vegetable Production",
    "value": 63685.95836
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 322584.036
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2304981.526
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 941874.1912
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Processed Food & Kindred Products",
    "value": 785840.1926
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Grain Milling & Flour",
    "value": 1899559.81
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Beverage Processing",
    "value": 381673.032
},
{
    "county": "Perry",
    "fips": 39127,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 529450.0168
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 8629526.85
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1767065.488
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Beef Cattle Production",
    "value": 1476787.94
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Poultry & Egg Production",
    "value": 10211.15194
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Hogs & Other Farm Animals",
    "value": 9508691.37
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Grain Production",
    "value": 1642950.177
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Soybean & Other Oil Crops",
    "value": 16152196.47
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 457073.9325
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Fruit & Vegetable Production",
    "value": 985259.146
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 778684.7851
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 9390980.982
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 301492.7283
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Processed Food & Kindred Products",
    "value": 1838340.258
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Beverage Processing",
    "value": 243053.2849
},
{
    "county": "Pickaway",
    "fips": 39129,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 40948134.31
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 890835.9692
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 350082.0361
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Beef Cattle Production",
    "value": 1132069.625
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Poultry & Egg Production",
    "value": 12335.19978
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Hogs & Other Farm Animals",
    "value": 382344.3204
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Grain Production",
    "value": 244408.9055
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2371360.913
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 954121.5169
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Fruit & Vegetable Production",
    "value": 887933.5774
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 299318.7867
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4735109.974
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Processed Food & Kindred Products",
    "value": 2241138.604
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Beverage Processing",
    "value": 886909.6488
},
{
    "county": "Pike",
    "fips": 39131,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 34757791.57
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 12734809.74
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1867958.322
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Beef Cattle Production",
    "value": 1248687.088
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Poultry & Egg Production",
    "value": 27515.71708
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Hogs & Other Farm Animals",
    "value": 2778970.256
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Grain Production",
    "value": 285405.1739
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2984556.921
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1579196.42
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Fruit & Vegetable Production",
    "value": 2792020.111
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 2536012.806
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3027596.564
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 6090930.98
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Dairy Processing",
    "value": 619836.2368
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Processed Food & Kindred Products",
    "value": 11397928.52
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Grain Milling & Flour",
    "value": 25135341.12
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Beverage Processing",
    "value": 2520402.737
},
{
    "county": "Portage",
    "fips": 39133,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 51063680.25
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 9152640.186
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1617546.171
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Beef Cattle Production",
    "value": 4704121.47
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Poultry & Egg Production",
    "value": 191065.46
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Hogs & Other Farm Animals",
    "value": 10931843.04
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Grain Production",
    "value": 1253018.26
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Soybean & Other Oil Crops",
    "value": 11743469.12
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 711408.278
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Fruit & Vegetable Production",
    "value": 696485.3541
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1287110.016
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 728729.9866
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 709271.051
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Processed Food & Kindred Products",
    "value": 57366253.23
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Beverage Processing",
    "value": 0
},
{
    "county": "Preble",
    "fips": 39135,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 35513195.54
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 57460873.49
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 5814308.435
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Beef Cattle Production",
    "value": 2191895.097
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Poultry & Egg Production",
    "value": 1244153.447
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Hogs & Other Farm Animals",
    "value": 16170428.04
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Grain Production",
    "value": 1874535.382
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Soybean & Other Oil Crops",
    "value": 24491773.69
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1300631.419
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Fruit & Vegetable Production",
    "value": 2246255.443
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 624273.3099
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 6353908.175
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Processed Food & Kindred Products",
    "value": 58214064.41
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Beverage Processing",
    "value": 6411912.868
},
{
    "county": "Putnam",
    "fips": 39137,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 1332353.115
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 5594579.495
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 9028785.586
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Beef Cattle Production",
    "value": 3605056.807
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Poultry & Egg Production",
    "value": 2359211.907
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Hogs & Other Farm Animals",
    "value": 5834066.57
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Grain Production",
    "value": 800706.8336
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Soybean & Other Oil Crops",
    "value": 6661757.246
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1171656.788
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Fruit & Vegetable Production",
    "value": 2049942.71
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 2065641.252
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 11411815.71
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1087926.321
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Processed Food & Kindred Products",
    "value": 16588119.17
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Beverage Processing",
    "value": 25260357.51
},
{
    "county": "Richland",
    "fips": 39139,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 28246103.07
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 7208989.464
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1301997.699
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Beef Cattle Production",
    "value": 1978829.816
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Poultry & Egg Production",
    "value": 13346.35436
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Hogs & Other Farm Animals",
    "value": 876845.3673
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Grain Production",
    "value": 856821.2539
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Soybean & Other Oil Crops",
    "value": 7764740.497
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1231120.936
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Fruit & Vegetable Production",
    "value": 820613.733
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 256378.9607
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 7758776.891
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Processed Food & Kindred Products",
    "value": 18552249.44
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Fats & Oils Processing",
    "value": 11119164.59
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Beverage Processing",
    "value": 465852.0287
},
{
    "county": "Ross",
    "fips": 39141,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 168534232.3
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 14149319.86
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 402290.0593
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Beef Cattle Production",
    "value": 912953.4662
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Poultry & Egg Production",
    "value": 12517.75119
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1704255.462
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Grain Production",
    "value": 1397633.433
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Soybean & Other Oil Crops",
    "value": 14490406.57
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 730780.3368
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Fruit & Vegetable Production",
    "value": 6127684.996
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 538715.5744
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 2443765.484
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1056840.576
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Processed Food & Kindred Products",
    "value": 64568460.2
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Beverage Processing",
    "value": 1872567.467
},
{
    "county": "Sandusky",
    "fips": 39143,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 18582703.38
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 4170678.953
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 693234.0749
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Beef Cattle Production",
    "value": 1486012.444
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Poultry & Egg Production",
    "value": 5217542.827
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1613449.007
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Grain Production",
    "value": 114439.2192
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Soybean & Other Oil Crops",
    "value": 1866943.57
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 987460.7459
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Fruit & Vegetable Production",
    "value": 970887.349
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 638715.5647
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 7979577.95
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1926315.996
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Processed Food & Kindred Products",
    "value": 4101447.47
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Beverage Processing",
    "value": 30584139.9
},
{
    "county": "Scioto",
    "fips": 39145,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 7030279.807
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 8144490.659
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 389157.9807
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Beef Cattle Production",
    "value": 2239568.934
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Poultry & Egg Production",
    "value": 20081.54179
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Hogs & Other Farm Animals",
    "value": 10923180.4
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Grain Production",
    "value": 1855520.785
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Soybean & Other Oil Crops",
    "value": 17112215.79
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 368682.868
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Fruit & Vegetable Production",
    "value": 658389.081
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 532262.9327
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 980179.3191
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Processed Food & Kindred Products",
    "value": 16427543.26
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Grain Milling & Flour",
    "value": 593244.072
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Beverage Processing",
    "value": 3614604.119
},
{
    "county": "Seneca",
    "fips": 39147,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 32370929.61
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 12219863.57
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 7102853.894
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Beef Cattle Production",
    "value": 4882715.017
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Poultry & Egg Production",
    "value": 2634600.498
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Hogs & Other Farm Animals",
    "value": 19996483.09
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Grain Production",
    "value": 1556262.732
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Soybean & Other Oil Crops",
    "value": 13964226.98
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1258764.505
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Fruit & Vegetable Production",
    "value": 551024.5793
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 759908.6589
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3739980.304
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1116552.088
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Processed Food & Kindred Products",
    "value": 59585179.95
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Fats & Oils Processing",
    "value": 87673165.65
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Beverage Processing",
    "value": 11721922.74
},
{
    "county": "Shelby",
    "fips": 39149,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 25959345.39
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 27559365.69
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 7975336.313
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Beef Cattle Production",
    "value": 2742160.827
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Poultry & Egg Production",
    "value": 5967234.015
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Hogs & Other Farm Animals",
    "value": 4874793.649
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Grain Production",
    "value": 632112.0858
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Soybean & Other Oil Crops",
    "value": 4619265.065
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2136378.719
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Fruit & Vegetable Production",
    "value": 5402441.282
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 2871840.725
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4900477.894
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 194776709.2
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Dairy Processing",
    "value": 79284749.76
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Processed Food & Kindred Products",
    "value": 344984464.5
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Beverage Processing",
    "value": 26035948.38
},
{
    "county": "Stark",
    "fips": 39151,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 96473964.66
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 61018448.98
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 231078.0178
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Beef Cattle Production",
    "value": 206355.6688
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Poultry & Egg Production",
    "value": 25823.87159
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Hogs & Other Farm Animals",
    "value": 609748.0301
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Grain Production",
    "value": 26419.46889
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Soybean & Other Oil Crops",
    "value": 253161.7539
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 312240.5012
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Fruit & Vegetable Production",
    "value": 2483151.642
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 2490509.518
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3654682.264
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 888171.3003
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Dairy Processing",
    "value": 24743201.11
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Processed Food & Kindred Products",
    "value": 121230842.1
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Beverage Processing",
    "value": 198734614.4
},
{
    "county": "Summit",
    "fips": 39153,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 70765761.88
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 25671188.4
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 3381350.547
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Beef Cattle Production",
    "value": 1773465.693
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Poultry & Egg Production",
    "value": 950642.1536
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Hogs & Other Farm Animals",
    "value": 2158640.265
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Grain Production",
    "value": 495952.8744
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Soybean & Other Oil Crops",
    "value": 5489060.819
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1890088.097
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Fruit & Vegetable Production",
    "value": 1176758.796
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 1142711.902
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 18233298.43
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 8948051.975
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Processed Food & Kindred Products",
    "value": 40867862.46
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Beverage Processing",
    "value": 4258584.963
},
{
    "county": "Trumbull",
    "fips": 39155,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 14940457.65
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 15732203.03
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 11295831.26
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Beef Cattle Production",
    "value": 3942073.941
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Poultry & Egg Production",
    "value": 2645444.632
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Hogs & Other Farm Animals",
    "value": 4165897.071
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Grain Production",
    "value": 267277.047
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Soybean & Other Oil Crops",
    "value": 2116921.328
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 2182621.643
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Fruit & Vegetable Production",
    "value": 607402.5525
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 273020.343
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 21102945.66
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1352164.466
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Dairy Processing",
    "value": 7033451.832
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Processed Food & Kindred Products",
    "value": 18432605.86
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Beverage Processing",
    "value": 5147164.396
},
{
    "county": "Tuscarawas",
    "fips": 39157,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 65396398.61
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 377843782
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1151779.547
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Beef Cattle Production",
    "value": 1820406.064
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Poultry & Egg Production",
    "value": 3446541.846
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Hogs & Other Farm Animals",
    "value": 7514011.085
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Grain Production",
    "value": 1538679.719
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Soybean & Other Oil Crops",
    "value": 18468093.51
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 597315.0002
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Fruit & Vegetable Production",
    "value": 818499.2411
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 2475768.972
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1538835.242
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Processed Food & Kindred Products",
    "value": 3312192.557
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Fats & Oils Processing",
    "value": 15736656.88
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Beverage Processing",
    "value": 7306368.034
},
{
    "county": "Union",
    "fips": 39159,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 9096901.499
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 10972566.05
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 2310780.242
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Beef Cattle Production",
    "value": 2733359.501
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Poultry & Egg Production",
    "value": 1563989.468
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Hogs & Other Farm Animals",
    "value": 14830473.96
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Grain Production",
    "value": 1490412.533
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Soybean & Other Oil Crops",
    "value": 20774308.2
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 348469.2228
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Fruit & Vegetable Production",
    "value": 241704.8716
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 212905.182
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 4112817.871
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Processed Food & Kindred Products",
    "value": 4142876.586
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Fats & Oils Processing",
    "value": 5082385.123
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Beverage Processing",
    "value": 1890942.268
},
{
    "county": "Van Wert",
    "fips": 39161,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 9585988.006
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 854427.9849
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 19217.67776
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Beef Cattle Production",
    "value": 451801.0244
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Poultry & Egg Production",
    "value": 107314.2127
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Hogs & Other Farm Animals",
    "value": 99290.62519
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Grain Production",
    "value": 24153.28287
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Soybean & Other Oil Crops",
    "value": 129255.4846
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 592366.4878
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Fruit & Vegetable Production",
    "value": 54714.49738
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 535489.246
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 12471486.37
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Processed Food & Kindred Products",
    "value": 72748.47431
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Beverage Processing",
    "value": 0
},
{
    "county": "Vinton",
    "fips": 39163,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 11495012.9
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 23789408.79
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 279296.9309
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Beef Cattle Production",
    "value": 727575.7194
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Poultry & Egg Production",
    "value": 31653.8444
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Hogs & Other Farm Animals",
    "value": 3435364.783
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Grain Production",
    "value": 541285.6638
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Soybean & Other Oil Crops",
    "value": 6052681.834
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 715944.3225
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Fruit & Vegetable Production",
    "value": 1291667.774
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 7815047.044
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 171156.3779
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 1420862.421
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Processed Food & Kindred Products",
    "value": 62600705.11
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Beverage Processing",
    "value": 97599643.22
},
{
    "county": "Warren",
    "fips": 39165,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 83536587.93
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 8294570.279
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1587059.952
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Beef Cattle Production",
    "value": 3415095.091
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Poultry & Egg Production",
    "value": 21931.13748
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1379555.21
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Grain Production",
    "value": 162143.6775
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Soybean & Other Oil Crops",
    "value": 1270400.042
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 1963267.94
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Fruit & Vegetable Production",
    "value": 2366625.833
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 551808.7018
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 1928072.66
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 762973.9419
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Dairy Processing",
    "value": 16031156.37
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Processed Food & Kindred Products",
    "value": 14647723.97
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Beverage Processing",
    "value": 233187.9735
},
{
    "county": "Washington",
    "fips": 39167,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 8827995.152
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 141269549.8
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 42684705.5
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Beef Cattle Production",
    "value": 13570350.05
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Poultry & Egg Production",
    "value": 32453060.63
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Hogs & Other Farm Animals",
    "value": 16580004.57
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Grain Production",
    "value": 1263581.991
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Soybean & Other Oil Crops",
    "value": 8137932.643
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 5332199.746
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Fruit & Vegetable Production",
    "value": 4626509.944
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 4065823.957
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 28905381.56
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 44286469.79
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Dairy Processing",
    "value": 39370207.86
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Processed Food & Kindred Products",
    "value": 457832691.3
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Fats & Oils Processing",
    "value": 794995.3433
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Beverage Processing",
    "value": 31473175.88
},
{
    "county": "Wayne",
    "fips": 39169,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 109740739.4
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 1786154.386
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 1155390.099
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Beef Cattle Production",
    "value": 2705637.023
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Poultry & Egg Production",
    "value": 35150.28162
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Hogs & Other Farm Animals",
    "value": 2358316.69
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Grain Production",
    "value": 979238.9125
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Soybean & Other Oil Crops",
    "value": 9874373.168
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 745685.593
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Fruit & Vegetable Production",
    "value": 2498354.275
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 532262.9458
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 3329322.096
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 2652489.377
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Processed Food & Kindred Products",
    "value": 69988366.98
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Beverage Processing",
    "value": 10040730.8
},
{
    "county": "Williams",
    "fips": 39171,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 21360659.99
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 27594446.06
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 2541217.64
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Beef Cattle Production",
    "value": 1029735.535
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Poultry & Egg Production",
    "value": 3452377.401
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Hogs & Other Farm Animals",
    "value": 1927936.39
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Grain Production",
    "value": 2113678.932
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Soybean & Other Oil Crops",
    "value": 22156388.94
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 615747.4039
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Fruit & Vegetable Production",
    "value": 5810104.995
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 5477711.063
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 813808.3436
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 49023726.41
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Processed Food & Kindred Products",
    "value": 29373953.96
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Beverage Processing",
    "value": 10245791.92
},
{
    "county": "Wood",
    "fips": 39173,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 80090489.2
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Farm Inputs, Equipment & Professional Services",
    "value": 16580960.7
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Dairy Cattle & Milk Production",
    "value": 389478.2886
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Beef Cattle Production",
    "value": 489756.7108
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Poultry & Egg Production",
    "value": 1582554.84
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Hogs & Other Farm Animals",
    "value": 12156503.44
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Grain Production",
    "value": 1638486.385
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Soybean & Other Oil Crops",
    "value": 15456214.49
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Misc. Crops, Hay, Sugar, Tobacco, & Nuts",
    "value": 343786.2992
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Fruit & Vegetable Production",
    "value": 353959.288
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Greenhouse, Nursery & Floriculture Production",
    "value": 217391.4202
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Forestry, Hunting and Fishing",
    "value": 5735321.885
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Processed meat Fish Poultry & Eggs",
    "value": 0
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Dairy Processing",
    "value": 0
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Processed Food & Kindred Products",
    "value": 98544518.65
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Grain Milling & Flour",
    "value": 0
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Fats & Oils Processing",
    "value": 0
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Beverage Processing",
    "value": 0
},
{
    "county": "Wyandot",
    "fips": 39175,
    "commodity": "Wood/ Paper/ Furniture Manufacturing",
    "value": 341996.5846
}
]

