import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ApiService, KxTeamFilter } from "src/app/services/api.service";
import { Person, UserType } from "src/app/shared/models/person";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "src/app/services/title.service";
import { mergeMap, map, tap, delay, catchError } from "rxjs/operators";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { MatTabChangeEvent } from "@angular/material/tabs";

class ObservableWithLoadingIndicator {
  loaded = false;
  constructor(public value: Observable<any>) {
    this.value = this.value.pipe(
      delay(700),
      tap(() => (this.loaded = true))
    );
  }
}

@Component({
  selector: "app-people-directory",
  templateUrl: "./people-directory.component.html",
  styleUrls: ["./people-directory.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PeopleDirectoryComponent implements OnInit {
  loading = true;
  kxStaff;
  affiliates;
  selectedIndex: number = 0;

  constructor(
    private api: ApiService,
    private router: Router,
    private titleService: TitleService,
    private route: ActivatedRoute
  ) {
    this.kxStaff = {
      technology: this.loadDrupalPeopleList(
        this.api.getDrupalPeople(KxTeamFilter.Technology)
      ),
      integration: this.loadDrupalPeopleList(
        this.api.getDrupalPeople(KxTeamFilter.Integration)
      ),
      communication: this.loadDrupalPeopleList(
        this.api.getDrupalPeople(KxTeamFilter.Communication)
      ),
      other: this.loadDrupalPeopleList(
        this.api.getDrupalPeople(KxTeamFilter.None)
      ),
    };

    // Get Affiliates
    this.affiliates = this.api.getDrupalContributorsTaxonomyTerms().pipe(
      mergeMap((rawPeople: any[]) =>
        forkJoin(
          rawPeople.map((p) =>
            this.api.getPersonInfoFromOSUDirectory(p.name).pipe(
              map(([osuPerson]: any) => osuPerson),
              catchError((e) => of(false))
            )
          )
        )
      ),
      map((people) =>
        people
          .filter((el) => !!el)
          .map((el) => this.formatPersonForContentList(el))
      ),
      tap(() => (this.loading = false))
    );
  }

  handleError(error) {
    if (error.status == 404 || error.status == 400) {
      return of(false);
    }
  }

  goTo(person: Person) {
    let link =
      "directory/" +
      (person.type == UserType.OSUInternal ? "" : "external/") +
      person.id;
    console.log(person, link);
    this.router.navigate([link]);
  }

  formatPersonForContentList(person: any) {
    Object.entries(person).forEach(([key, value]) => {
      if (value === "") person[key] = null;
    });
    // console.log(person);
    person.title = person.field_full_name ?? person.display_name;
    person.link =
      "/directory/" +
        (!!person.username ? "" : "external/") +
        person.username ?? person.name;
    person.headerImage =
      person.user_picture ??
      `https://opic.osu.edu/${person.username ?? "brutus.1"}?width=512`;
    person.type =
      person.field_title ??
      (person.appointments[0]
        ? `${person.appointments[0]?.job_title} - ${person.appointments[0]?.organization}`
        : "");
    person.description = person.field_description ?? "";
    return person;
  }

  loadDrupalPeopleList(rawPeople: Observable<any>) {
    return rawPeople.pipe(
      mergeMap((drupalPeople: any[]) =>
        forkJoin(
          drupalPeople
            .filter((drupalPerson) => drupalPerson.field_enabled == "true")
            .map((drupalPerson) =>
              this.api
                .getPersonInfoFromOSUDirectory(drupalPerson.name)
                .map(([osuPerson]: any) =>
                  this.formatPersonForContentList({
                    ...osuPerson,
                    ...drupalPerson,
                  })
                )
            )
        )
      )
    );
  }
  ngOnInit() {
    this.titleService.setPageTitle(this.titleService.PageTitles.Directory);

    // set the current tab based on the query parameter
    this.route.queryParams.subscribe(params => {
      const tab = params['tab'];
      this.selectedIndex = tab === 'kx-team' ? 0 : tab === 'contributors' ? 1 : 0;
    });
  }

  onTabChange(event: MatTabChangeEvent) {
    const tab = event.index === 0 ? 'kx-team' : 'contributors';
    this.router.navigate([], { queryParams: { tab: tab } });
  }
}
