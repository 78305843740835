import { Person, UserType } from './person';
import { Observable } from 'rxjs/Rx';
import { ApiService } from 'src/app/services/api.service';
import { observable } from 'rxjs';
import { urlify } from 'src/app/services/api.service';
export const enum ContentType { 
    QUESTION = "QUESTION", 
    PODCAST = "PODCAST", 
    STORY = "STORY", 
    VIDEO = "VIDEO", 
    LINK = "LINK", 
    FILE = "FILE" 
};

const URLPrefixes = new Map <ContentType, string>([
    [ContentType.STORY,     "/story/"],
    [ContentType.QUESTION,  "/question/"],
    [ContentType.VIDEO,     "/video/"]
]);


export class Content {
    public fullURL      : string;
    public title        : string;
    public id           : string;
    public description  : string;
    public image        : string;
    public tags         : string[];
    public topics       : {name, url}[] = [];
    public contributors : Person[];
    public type         : ContentType;
    public date         : string;
    public content      : any;

    private contributorIDs : string[];

    constructor(type:ContentType, obs?: Observable<any>, obj?: Object){ 
        this.type = type;
        if (obs) this.populateDataFromObservable(obs);
        if (obj) this.populateDataFromObject(obj)
    }

    /**
     * Populate the content's data from an API call.
     * 
     * @param drupalResponse 
     * The json-formatted observable response from the drupal endpoint for this content
     * 
     * @returns a promise that resolves when the data has been collected.
     */
    public populateDataFromObservable(drupalResponse: Observable<any>){
        drupalResponse.subscribe((result)=>{
            this.populateDataFromObject(result);
        })
        return drupalResponse.toPromise();
    }    
    /**
     * Populate the content's data from an object.
     * 
     * @param obj
     * The object returned from the drupal endpoint for this content.
     */
    public populateDataFromObject(obj: any){
        //Utility function to check if a variable is a valid candidate to be used
        const c = (src) => { return ((src) && (src.length > 0))? src : undefined }

        // Things that all content may have, with a consistently named field
        this.contributorIDs = c(obj.field_contributors)? obj.field_contributors.split(", ") : undefined; 
        this.tags = c(obj.tags)? obj.tags.split(", ") : undefined; 
  
        this.title = c(obj.title);
        this.date = c(obj.created)
        this.id = c(obj.name)
        this.image = c(obj.headerImage)

        if ((obj.topics) && (obj.topics.length > 0)){
            let t = obj.topics.split(", "); 
            t.forEach(element => {
                this.topics.push({
                    name: element.replace('&amp;','&'),
                    url: urlify(element),
                })
            });
        }

        if (URLPrefixes.get(this.type)) this.fullURL = URLPrefixes.get(this.type).toString() + this.id;

        // Content-type specific fields
        switch (this.type){
            case ContentType.STORY:
                this.id = c(obj.name);
                break;
            case ContentType.QUESTION:
                break;
            case ContentType.VIDEO:
                break;
            case ContentType.LINK:
                this.fullURL = c(obj.url)
                break;
            case ContentType.FILE:
                this.fullURL = c(obj.url)
                break;
            case ContentType.PODCAST:
                this.fullURL = c(obj.url)
                break;
        }

    }

    /**
     * Fill in the contributors field with Person objects by making calls 
     * to the OSU find people directory.
     * 
     * @param api The ApiService
     * 
     * @returns a Promise that resolves when all the data has been collected 
     * from the OSU find people API.
     */
    loadContributors(api: ApiService){
        if (!this.contributorIDs) return Promise.all([Promise.resolve(true)]);
        this.contributors = [];
        let promises = [];
        this.contributorIDs.forEach((id, index)=>{
            this.contributors[index] = new Person(UserType.OSUInternal,id)
            promises.push(this.contributors[index].populateOSUData(api.getPersonInfoFromOSUDirectory(id)))
        })
        
        return Promise.all(promises)
    }
}
