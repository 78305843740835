<div *ngIf="listType == 'bullet'; else ordered">
<ul>
  <li *ngFor="let item of listItems">
    {{ item }}
  </li>
</ul>
</div>

<ng-template #ordered>
	<div>
  <ol>
  <li *ngFor="let item of listItems">
    {{ item }}
  </li>
	</ol>
	</div>

</ng-template>
