import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { MainModule } from "./main/main.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./site-organization/navbar/navbar.component";
import { FooterComponent } from "./site-organization/footer/footer.component";
import { MAT_CHECKBOX_CLICK_ACTION } from "@angular/material/checkbox";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CommonModule } from "@angular/common";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { HttpClientModule } from "@angular/common/http";
import { NgtUniversalModule } from "@ng-toolkit/universal";
import { NgsRevealModule } from "ngx-scrollreveal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ThousandSuffPipe } from "./shared/pipes/ThousandSuffPipe";
import { ReactiveFormsModule } from "@angular/forms";
import { AnnouncementsHeaderComponent } from "./site-organization/announcements-header/announcements-header.component";
import { CookieModule } from "ngx-cookie";
import { AnimateModule, AnimateService } from "@wizdm/animate";
import { NotFoundComponent } from "./site-organization/not-found/not-found.component";
import { BrandingComponent } from "./site-organization/branding/branding.component";
import { TitleService } from "./services/title.service";
import { AuthService } from "./services/auth.service";
import { SearchService } from "./services/search.service";
import { EfieldsSearchService } from "./services/efields-search.service";
import { GraphingService } from "./services/graphing.service";
import { RelatedContentService } from "./services/related-content.service";
import { SharedModule } from "./shared/shared.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { OsuNavbarComponent } from "./site-organization/osu-navbar/osu-navbar.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { JoyrideModule } from "ngx-joyride";
import { SwiperModule } from "swiper/angular";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { BuckeyeAlertComponent } from "./site-organization/buckeye-alert/buckeye-alert.component";
import { ScrollToTopComponent } from "./shared/components/scroll-to-top/scroll-to-top.component";
import { ReadCsvService } from "./services/read-csv.service";
import { SafeStylePipe } from "./pipes/safe-style.pipe";
import { ExpertSearchService } from "./services/expert-search.service";
import {AppService} from './services/app.service';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    OsuNavbarComponent,
    ThousandSuffPipe,
    AnnouncementsHeaderComponent,
    NotFoundComponent,
    BrandingComponent,
    BuckeyeAlertComponent,
    ScrollToTopComponent,
    SafeStylePipe,
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: "serverApp" }),
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    TransferHttpCacheModule,
    HttpClientModule,
    NgtUniversalModule,
    NgsRevealModule,
    MainModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    CookieModule.forRoot(),
    AnimateModule,
    SharedModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    JoyrideModule.forRoot(),
    SwiperModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    TitleService,
    ReadCsvService,
    ThousandSuffPipe,
    AuthService,
    EfieldsSearchService,
    ExpertSearchService,
    SearchService,
    GraphingService,
    RelatedContentService,
    AnimateService,
    SafeStylePipe,
		AppService,
    { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: "noop" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
