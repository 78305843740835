<ng-template [ngIf]="showMobileView" [ngIfElse]="desktop">
  <div class="mobile-nav-container">
    <h1>What's Bugging You?</h1>

    <div class="mobile-nav-dropdown" *ngIf="navLinks && mobileNavLink">
      <mat-form-field>
        <mat-label>Bugs found in Ohio</mat-label>
        <mat-select #navSelect [(value)]="mobileNavLink" (selectionChange)="test($event)">
          <mat-option *ngFor="let link of navLinks; index as i" [value]="link">
            {{ link.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mobile-swiper-container">
      <swiper #swiper [config]="mobileConfig" class="full-width">
        <ng-template swiperSlide *ngFor="let image of flattenedImageEntries">
          <div class="slide">
            <div class="image-container">
              <a
                [routerLink]="
                  image.relative_url ? '.' + image.relative_url : null
                "
                [fragment]="image.question_fragment"
                ><img [src]="image.image" />
              </a>
              <div
                class="info-button"
                (click)="
                  $event.stopPropagation(); image.showInfo = !image.showInfo
                "
              >
                <i class="fas fa-info fa-xs"></i>
              </div>

              <div class="info" [ngClass]="image.showInfo ? 'shown' : 'hidden'">
                {{ image.info }}
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
      <button class="slide-button right" (click)="slideNext()">
        <i class="fas fa-chevron-right"></i>
      </button>
      <button class="slide-button left" (click)="slidePrev()">
        <i class="fas fa-chevron-left"></i>
      </button>
    </div>
    <div class="mobile-learn-more" *ngIf="mobileNavLink">
      <div class="mobile-learn-more-inner-container">
        <div class="mobile-learn-more-label">{{ mobileNavLink.label }}</div>
        <div class="mobile-learn-more-description" [innerHTML]="mobileNavLink.description">
        </div>
        <a class="learn-more-link" [routerLink]="mobileNavLink.link.url">
          <div class="learn-more-text">{{ mobileNavLink.link.title }}</div>
          <i class="fas fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #desktop>
  <div class="nav-container" *ngIf="navLinks">
    <div class="accordion-and-images">
      <div class="accordion-container">
        <h1>What's<br />Bugging<br />You?</h1>
        <mat-accordion togglePosition="before">
          <mat-expansion-panel
            *ngFor="let link of navLinks; index as i"
            #panel
            [expanded]="i === activeNavIndex"
						(opened)="changeActiveNavIndex(i)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ link.label }}
              </mat-panel-title>
            </mat-expansion-panel-header>
						<div [innerHTML]="link.description"></div>

            <br />
            <a class="learn-more-link" [routerLink]="link.link.url">
              <div class="learn-more-text">{{ link.link.title }}</div>
              <i class="fas fa-chevron-right"></i>
            </a>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="swiper-container">
        <swiper #swiper [config]="config" class="full-width">
          <ng-template swiperSlide *ngFor="let imageEntry of navLinks[activeNavIndex].image_entries">
            <div class="slide">
              <div
                class="image-container"
                [ngClass]="imageEntry.images.length > 1 ? 'stacked' : 'single'"
                *ngFor="let image of imageEntry.images"
              >
                <a
                  [routerLink]="
                    image.relative_url ? '.' + image.relative_url : null
                  "
                  [fragment]="image.question_fragment"
                  ><img [src]="image.image" />
                </a>
                <div
                  class="info-button"
                  (click)="
                    $event.stopPropagation(); image.showInfo = !image.showInfo
                  "
                >
                  <i class="fas fa-info fa-xs"></i>
                </div>

                <div
                  class="info"
                  [ngClass]="image.showInfo ? 'shown' : 'hidden'"
									[innerHTML]="image.info"
                >
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
        <button class="slide-button right" (click)="slideNext()">
					<kx-icon id="chevron_right_large" size="smp"></kx-icon>
        </button>
        <button class="slide-button left" (click)="slidePrev()">
					<kx-icon id="chevron_left_large" size="smp"></kx-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
