import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";
import { getBackgroundImageStyles } from "src/app/shared/functions/ng-style-utils";
import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
  Swiper,
} from "swiper/core";
SwiperCore.use([A11y, Pagination, Navigation, Mousewheel, Autoplay]);
import { contentListItem } from "../util";

@Component({
  selector: "app-mini-slider",
  templateUrl: "./mini-slider.component.html",
  styleUrls: ["./mini-slider.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MiniSliderComponent {
  @Input() items: Observable<contentListItem[]>;
  swiper: Swiper;

  constructor(private cdr: ChangeDetectorRef) {}

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
    this.swiper.onAny(() => this.cdr.detectChanges());
  }
  navigateTo(i: number) {
    this.swiper.slideTo(i);
  }
  getImgStyle(url) {
    return url?.length > 0
      ? getBackgroundImageStyles(0.1, url)
      : { background: "#ddd" };
  }
}
