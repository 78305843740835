import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopEventPropagation]'
})
export class StopEventPropagationDirective {
  constructor() { }

	@HostListener("mousedown", ["$event"])
	public onClick(event: any): void {
		event.stopPropagation();
	}

}
